import { useMemo } from 'react'
import { Button } from 'components'
import { useTranslation } from 'react-i18next'
import { find } from 'lodash'

import {
  TExtensionDeadline,
  TTaxOrganizersParams,
  TTaxSummaryParams,
  useDashboard,
  useDashboardActions,
} from 'context/Dashboard'
import useLocale from 'hooks/useLocale'
import { getExtendedCount } from 'pages/Dashboard/Dashboard/helpers'

import { DEFAULT_TAX_PARAMS } from 'pages/Dashboard/Dashboard/constants'
import { format } from 'date-fns'
import styles from '../../../../dashboard.module.scss'

type TProps = {
  setSummaryParams: (
    value: TTaxSummaryParams | ((prevState: TTaxSummaryParams) => TTaxSummaryParams)
  ) => void
  setTaxParams: (
    value: TTaxOrganizersParams | ((prevState: TTaxOrganizersParams) => TTaxOrganizersParams)
  ) => void
  setShowTable: (state: boolean) => void
}

const Header = ({ setTaxParams, setSummaryParams, setShowTable }: TProps) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'info' })
  const { summary, deadlines, deadline } = useDashboard()
  const { setDeadline } = useDashboardActions()
  const locale = useLocale()
  const extendedCount = useMemo(() => getExtendedCount(summary), [summary])

  const handleDeadlineChange = (deadlineGuid: string) => {
    const currentDeadline = find(deadlines, { guid: deadlineGuid })
    setShowTable(false)
    if (currentDeadline) {
      setDeadline(deadlineGuid)
      setTaxParams((prevParams: TTaxOrganizersParams) => ({
        ...prevParams,
        taxYear: currentDeadline.taxYear,
        deadline: deadlineGuid,
        phase: undefined,
        page: DEFAULT_TAX_PARAMS.page,
        pageSize: DEFAULT_TAX_PARAMS.pageSize,
      }))
      setSummaryParams((prevParams: TTaxSummaryParams) => ({
        ...prevParams,
        taxYear: currentDeadline.taxYear,
        deadline: deadlineGuid,
        phase: undefined,
        page: DEFAULT_TAX_PARAMS.page,
        pageSize: DEFAULT_TAX_PARAMS.pageSize,
      }))
    } else {
      try {
        throw new Error(`There is no deadline with guid: ${deadlineGuid} in the selected tax year`)
      } catch (error: any) {
        console.log(error?.message)
      }
    }
  }

  return (
    <div className={styles.info_header}>
      <div>
        {deadline.all && (
          <>
            <span className="text-bold">{extendedCount}</span> {t('extended.of')}{' '}
            <span className="text-bold">{summary.total}</span> {t('extended.total')}
          </>
        )}
      </div>

      <div>
        {summary.extensionDeadlines?.map((extensionDeadline: TExtensionDeadline) => {
          return (
            <div key={extensionDeadline.deadlineGuid}>
              <span className="text-bold">{extensionDeadline.count}</span>{' '}
              <span>{t('extendedDeadlines')}</span>{' '}
              <Button
                type="link"
                className={styles.info_extension_deadline_button}
                onClick={() => handleDeadlineChange(extensionDeadline.deadlineGuid)}
              >
                {format(new Date(extensionDeadline.date), 'MMM dd, yyyy', {
                  locale,
                })}
              </Button>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Header
