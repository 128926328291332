import { Tooltip, Row, Col, Modal as antdModal, Form } from 'antd'
import Icon, {
  SearchOutlined,
  ShareAltOutlined,
  UserAddOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
  FilterOutlined,
} from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { ChangeEvent, SetStateAction, useEffect, useMemo, useState } from 'react'
import { Box, Flex } from 'reflexbox'
import { debounce, intersection, isEmpty, keys, pick } from 'lodash'

import {
  TLoadDocumentsParams,
  TTaxDocument,
  useDocumentsActions,
  useTaxOrganizer,
} from 'context/TaxOrganizer'
import { ALLOWED_DOCUMENT_FILE_TYPES } from 'utils/taxOrganizer'

import { Button, TextField, Upload, Modal, Badge } from 'components'
import { ReactComponent as Reposidocs } from 'assets/images/reposidocs.svg'
import ShareModalContent from './ShareModalContent'
import RequestModalContent from './RequestModalContent'
import FiltersModalContent from './FiltersModalContent'

import { DEFAULT_PARAM_FILTERS, DEFAULT_FILTERS, FILTERS } from '../contstants'

import styles from '../../tax.documents.module.scss'
import { TFilters } from '../types'

const { confirm } = antdModal

type VisibleModalKey = 'share' | 'request'

type Props = {
  onUpload: ({ fileList }: any) => void
  onDeletedDocuments: () => void
  handleSearch: (event: ChangeEvent<HTMLInputElement>) => void
  params: TLoadDocumentsParams
  setParams: (params: SetStateAction<TLoadDocumentsParams>) => void
  setCurrentPage: (page?: number) => void
}

const Header = ({
  onUpload,
  onDeletedDocuments,
  handleSearch,
  params,
  setParams,
  setCurrentPage,
}: Props) => {
  const { setSelectedTableDocumentIds } = useDocumentsActions()
  const { bulkDeteleDocuments } = useDocumentsActions()

  const { t } = useTranslation(['common', 'taxOrganizer'])
  const { t: tDcocuments } = useTranslation('taxOrganizer', { keyPrefix: 'documents' })
  const {
    documents: { selectedDocumentIds, data },
    taxOrganizer: {
      data: { guid: id },
    },
  } = useTaxOrganizer()
  const [modalVisible, setModalVisible] = useState<VisibleModalKey | null>(null)
  const [modalFiltersVisible, setModalFiltersVisible] = useState<boolean>(false)
  const [filters, setFilters] = useState<TFilters>(DEFAULT_FILTERS)
  const [form] = Form.useForm()

  const filtersCount = useMemo(() => keys(filters).length, [filters])
  const activeFiltersCount = useMemo(() => intersection(keys(params), FILTERS).length, [params])

  const handleDelete = async () => {
    confirm({
      maskClosable: true,
      title: tDcocuments('bulkDeleteConfirmation', { number: selectedDocumentIds.length }),
      icon: <ExclamationCircleOutlined />,
      okText: t('switcher.yes'),
      okType: 'danger',
      cancelText: t('switcher.no'),
      onOk() {
        return new Promise((resolve) => {
          bulkDeteleDocuments(id, selectedDocumentIds).then(() => {
            onDeletedDocuments()
            setSelectedTableDocumentIds([])
            resolve(true)
          })
        })
      },
    })
  }

  // TODO: Temporary decision. Waiting for backend zip archive functionality
  const handleDownload = () => {
    data
      .filter(({ guid }: TTaxDocument) => selectedDocumentIds.includes(guid))
      .forEach(({ file }: TTaxDocument) => {
        if (file) {
          window.open(file, '_blank')
        }
      })
  }

  const hasSeletectedDocs = selectedDocumentIds.length > 0

  const handleVisibleChange = (isVisible: boolean) => {
    if (isVisible) {
      const newFilters = pick(params, FILTERS)
      setFilters(newFilters)
      if (isEmpty(newFilters)) {
        form.setFieldsValue(DEFAULT_PARAM_FILTERS)
      } else {
        form.setFieldsValue(newFilters)
      }
    }
    setModalFiltersVisible(isVisible)
  }

  const debouncedTextChangeHandler = useMemo(() => debounce(handleSearch, 300), [])

  useEffect(() => {
    return () => {
      debouncedTextChangeHandler.cancel()
    }
  }, [])

  return (
    <Box mb={[3]}>
      <Row gutter={[12, 12]}>
        <Col flex={1}>
          <Flex className={styles.header_flex}>
            <Modal
              placement="leftBottom"
              content={
                <FiltersModalContent
                  form={form}
                  setParams={setParams}
                  setModalFiltersVisible={setModalFiltersVisible}
                  disableButton={!filtersCount && !activeFiltersCount}
                  filters={filters}
                  setFilters={setFilters}
                  setCurrentPage={setCurrentPage}
                />
              }
              trigger="click"
              visible={modalFiltersVisible}
              onVisibleChange={handleVisibleChange}
              showCloseIcon={false}
            >
              <Badge count={activeFiltersCount} scheme="purple" size="small">
                <Button icon={<FilterOutlined />} />
              </Badge>
            </Modal>

            <TextField
              placeholder={t('taxOrganizer:documents.search')}
              size="middle"
              suffix={<SearchOutlined />}
              className={styles.header_search}
              onChange={debouncedTextChangeHandler}
              allowClear
            />
          </Flex>
        </Col>
        <Col>
          <Flex className={styles.header_flex}>
            {!hasSeletectedDocs ? (
              <Modal
                placement="bottom"
                content={<RequestModalContent hideShareModal={() => setModalVisible(null)} />}
                trigger="click"
                destroyTooltipOnHide
                visible={modalVisible === 'request'}
                onVisibleChange={(isVisible) => setModalVisible(isVisible ? 'request' : null)}
              >
                <Button type="primary" size="large">
                  <UserAddOutlined style={{ fontSize: 18 }} />
                  {t('buttons.request')}
                </Button>
              </Modal>
            ) : (
              <Modal
                placement="bottom"
                content={
                  <ShareModalContent
                    documentGuids={selectedDocumentIds}
                    hideShareModal={() => setModalVisible(null)}
                  />
                }
                trigger={hasSeletectedDocs ? 'click' : ''}
                destroyTooltipOnHide
                visible={modalVisible === 'share'}
                onVisibleChange={(isVisible) => setModalVisible(isVisible ? 'share' : null)}
              >
                <Tooltip title={!hasSeletectedDocs ? tDcocuments('selectToShare') : undefined}>
                  <div
                    style={{
                      cursor: !hasSeletectedDocs ? 'not-allowed' : 'pointer',
                      borderRadius: '24px',
                    }}
                  >
                    <Button
                      type="primary"
                      size="large"
                      disabled={!hasSeletectedDocs}
                      style={{ pointerEvents: !hasSeletectedDocs ? 'none' : 'auto' }}
                    >
                      <ShareAltOutlined style={{ fontSize: 18 }} />
                      {t('buttons.share')}
                    </Button>
                  </div>
                </Tooltip>
              </Modal>
            )}
            {hasSeletectedDocs && (
              <>
                <Button type="primary" size="large" onClick={handleDelete}>
                  {tDcocuments('deleteSelected', { number: selectedDocumentIds.length })}
                </Button>
                <Button type="primary" size="large" onClick={handleDownload}>
                  {tDcocuments('downloadSelected', { number: selectedDocumentIds.length })}
                </Button>
                <Button type="default" size="large" onClick={() => setSelectedTableDocumentIds([])}>
                  {tDcocuments('clearSelection')}
                </Button>
              </>
            )}
            {!hasSeletectedDocs && (
              <>
                <Tooltip title={t('comingSoon')}>
                  <Button type="primary" size="large" className={styles.header_item} disabled>
                    <Icon component={Reposidocs} />
                    {t('buttons.reposidocs')}
                  </Button>
                </Tooltip>
                <Upload
                  accept={ALLOWED_DOCUMENT_FILE_TYPES.join(',')}
                  fileList={[]}
                  onChange={onUpload}
                >
                  <Button type="primary" size="large" className={styles.header_item}>
                    <DownloadOutlined style={{ fontSize: 18 }} />
                    {t('buttons.addFile')}
                  </Button>
                </Upload>
              </>
            )}
          </Flex>
        </Col>
      </Row>
    </Box>
  )
}

export default Header
