import { TCPAFirmAssociatedUsersParams } from 'context/Dashboard'
import { PAGE_SIZE } from 'constants/table'

export const DEFAULT_CONTACTS_PARAMS: Readonly<TParams> = {
  page: 1,
  pageSize: PAGE_SIZE,
  ordering: undefined,
  search: undefined,
}

export const DEFAULT_USERS_PARAMS: Readonly<TCPAFirmAssociatedUsersParams> = {
  page: 1,
  pageSize: PAGE_SIZE,
  ordering: undefined,
  search: undefined,
  excludeContactsOf: undefined,
}

export const DEFAULT_TAX_TEAM_COUNT = 4
