import { useTranslation } from 'react-i18next'
import { Box } from 'reflexbox'
import { ColumnsType } from 'antd/lib/table'

import { User } from 'components'
import { TEntityInvite } from 'context/Invites/types'
import { useInviteActions } from 'context/Invites'
import { useAuthActions } from 'context/Auth'

import colors from 'assets/styles/variables.module.scss'
import ActionsCell from '../components/ActionsCell'

export default () => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'clients.table' })
  const { t: t2 } = useTranslation('common', { keyPrefix: 'user.roles' })
  const { acceptCPAUserInvitation, response: responseAccept } = useInviteActions()
  const { declineCPAUserInvitation, response: responseDecline } = useInviteActions()
  const { loadCPAUserInvitations } = useInviteActions()
  const { setOnboardingFlowStatus } = useAuthActions()

  const columns: ColumnsType<TEntityInvite> = [
    {
      title: <Box fontWeight="bold">{t('taxFirm')}</Box>,
      dataIndex: ['cpaFirm', 'name'],
      key: 'name',
      ellipsis: true,
      render: (name: string) => (
        <Box fontWeight="bold" className="text-black">
          {name}
        </Box>
      ),
    },
    {
      title: t('inviteBy'),
      dataIndex: ['invitedBy'],
      key: 'inviteBy',
      render: (cpaContact: IContact) => {
        if (!cpaContact) return null
        const { firstName, lastName, avatar } = cpaContact
        return (
          <User username={`${firstName} ${lastName}`} bgColor={colors.tag5} avatarLink={avatar} />
        )
      },
    },
    {
      title: t('accessLevel'),
      dataIndex: 'role',
      key: 'accessLevel',
      render: (role?: string) => {
        return <div style={{ whiteSpace: 'nowrap' }}>{role && t2(role)}</div>
      },
    },
    {
      title: t('actions'),
      key: 'actions',
      render: (invitation: TEntityInvite) => (
        <ActionsCell
          acceptAction={async () => {
            await acceptCPAUserInvitation(invitation.cpaFirm.guid, invitation.guid)
            if (responseAccept.ok) {
              setOnboardingFlowStatus()
            }
          }}
          declineAction={async () => {
            await declineCPAUserInvitation(invitation.cpaFirm.guid, invitation.guid)
            loadCPAUserInvitations()
            if (responseDecline.ok) {
              // loadCPAUserInvitations()
            }
          }}
        />
      ),
    },
  ]

  return { columns }
}
