import { PAGE_SIZE } from 'constants/table'
import { TTaxOrganizersParams } from 'context/Dashboard'
import { TFilters } from '../types'

export const FILTERS = ['phase', 'primaryCpaContact', 'taxYear'] as const

export const DEFAULT_PARAMS: Readonly<TTaxOrganizersParams> = {
  page: 1,
  pageSize: PAGE_SIZE,
  ordering: undefined,
  search: undefined,
  primaryEntityContact: undefined,
}

export const DEFAULT_FILTERS: Readonly<TFilters> = {}
export const DEFAULT_PARAM_FILTERS: Readonly<TFilters> = {
  phase: undefined,
  primaryCpaContact: undefined,
  taxYear: undefined,
}
