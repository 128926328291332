import { Avatar, Pagination, Typography } from 'antd'
import { getInitials, getPagination } from 'utils'
import { Section, TextField } from 'components'
import cn from 'classnames'
import { debounce } from 'lodash'
import { ChangeEvent, useEffect, useState } from 'react'
import {
  TDocumentPageIdentifier,
  TTaxReturn,
  useTaxReturns,
  useTaxReturnsActions,
} from 'context/TaxReturns'
import { SearchOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { formatDistance } from 'date-fns'
import useLocale from 'hooks/useLocale'
import { THREADS_PAGE_SIZE } from 'context/TaxReturns/actions'
import { DocumentThread } from 'context/Document'
import styles from '../../../dashboard.tax_return.module.scss'

const { Title } = Typography

type TProps = {
  taxReturn: TTaxReturn
  setSelectedPage: (t: TDocumentPageIdentifier) => void
  setCurrentThread: (referenceNumber: string) => void
}

const CommentList = ({ taxReturn, setSelectedPage, setCurrentThread }: TProps) => {
  const { t: t2 } = useTranslation('common')
  const [search, setSearch] = useState<string>('')
  const [page, setPage] = useState<number>(1)
  const { loadThreads } = useTaxReturnsActions()
  const locale = useLocale()
  const {
    state: { threads, filteredTaxReturnPages },
  } = useTaxReturns()

  const handleChange = debounce(async ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setSearch(value)
    await loadThreads({
      taxReturn: taxReturn.guid,
      page,
      search: value,
    })
  }, 300)

  useEffect(() => {
    loadThreads({
      taxReturn: taxReturn.guid,
      page,
      search,
    })
  }, [page])

  const onSelectThread = (thread: DocumentThread) => {
    const targetPageIndex = filteredTaxReturnPages.findIndex(
      (filteredPage) => filteredPage.guid === thread?.taxReturnContext?.taxReturnPage?.guid
    )
    if (targetPageIndex === -1) return
    const targetPage = filteredTaxReturnPages[targetPageIndex]
    setSelectedPage({
      documentGuid: targetPage.documentGuid,
      pageId: Number(targetPage.pageId),
      uiIndex: targetPageIndex,
      guid: targetPage.guid,
    })
    setCurrentThread(String(thread.referenceNumber))
  }

  return (
    <Section className={styles.document_comments_view}>
      <div className={styles.comments_header}>
        <Title className={styles.comments_header_title}>Comments</Title>
        <TextField
          suffix={<SearchOutlined />}
          className={styles.comment_search}
          placeholder={t2('buttons.search')}
          onChange={handleChange}
        />
      </div>
      <div className={styles.comments_list}>
        {threads.results.map((thread) => (
          <div onClick={() => onSelectThread(thread)} className={styles.comments_list_item}>
            <div className={styles.comments_list_item_person}>
              {thread.createdBy?.avatar ? (
                <Avatar size={18} src={thread.createdBy?.avatar} />
              ) : (
                <Avatar size={18}>{getInitials(thread.createdBy)}</Avatar>
              )}
            </div>
            <div>
              <div className={styles.comments_list_item_status_row}>
                <div className={styles.comments_list_item_reference}>#{thread.referenceNumber}</div>
                <div className={styles.comments_list_item_status}>
                  {thread.unreadMessageCount ? (
                    <span className={styles.comments_list_item_status_unread}>Unread</span>
                  ) : thread.isResolved ? (
                    <span className={styles.comments_list_item_status_resolved}>Resolved</span>
                  ) : (
                    <span>You</span>
                  )}
                </div>
                <div className={styles.comments_list_item_time}>
                  •{' '}
                  {formatDistance(new Date(thread.lastReplyOn), new Date(), {
                    addSuffix: true,
                    locale,
                  })}
                </div>
              </div>
              <div
                className={cn(styles.comments_list_item_preview, {
                  [styles.comments_list_item_status_resolved]: thread.unreadMessageCount,
                })}
              >
                {thread.summary}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.comments_footer}>
        <Pagination
          pageSize={THREADS_PAGE_SIZE}
          itemRender={getPagination(THREADS_PAGE_SIZE, threads.count)}
          size="small"
          total={threads.count}
          showSizeChanger={false}
          onChange={(newPage) => setPage(newPage)}
        />
      </div>
    </Section>
  )
}

export default CommentList
