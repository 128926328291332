import { useEffect, useState } from 'react'
import { Typography } from 'antd'
import { Link } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import { Button } from 'components'

import { ShareInvites, useDocumentsActions } from 'context/TaxOrganizer'

import styles from './shared.documents.module.scss'

const { Title, Paragraph } = Typography

const SharedDocuments = () => {
  const { t } = useTranslation('common', { keyPrefix: 'shared' })
  const { loadDocumentShareInvites, response } = useDocumentsActions()
  const { resendShareInvites } = useDocumentsActions()
  const [queryParams, setQueryParams] = useState<{ invitationGuid: string; token: string } | null>(
    null
  )
  const [isExpiredToken, setIsExpiredToken] = useState<boolean>(false)
  const [documentShareInvites, setDocumentShareInvites] = useState<ShareInvites | null>(null)

  const handleResendInvites = async () => {
    if (queryParams && queryParams.invitationGuid) {
      await resendShareInvites(queryParams.invitationGuid)
    }
  }

  const handleLoadDocumentShareInvites = async () => {
    if (!queryParams) return

    const loadedDocumentShareInvites = await loadDocumentShareInvites(
      queryParams?.invitationGuid,
      queryParams?.token
    )

    if (response.ok) {
      setDocumentShareInvites(loadedDocumentShareInvites)
    } else {
      setIsExpiredToken(true)
    }
  }

  useEffect(() => {
    handleLoadDocumentShareInvites()
  }, [queryParams])

  useEffect(() => {
    const { search } = window.location
    const params = new URLSearchParams(search)
    setQueryParams({
      invitationGuid: params.get('invitation_guid') as string,
      token: params.get('token') as string,
    })
  }, [])

  return (
    <div className={styles.shared_container}>
      {isExpiredToken ? (
        <>
          <Paragraph>{t('expiredToken')}</Paragraph>

          <Paragraph>{t('expiredTokenAfter')}</Paragraph>

          <Button type="primary" size="large" onClick={handleResendInvites}>
            {t('buttonResendLink')}
          </Button>
        </>
      ) : (
        <>
          {documentShareInvites && (
            <>
              <Title level={3} style={{ margin: 0 }}>
                {documentShareInvites.entityName}
              </Title>

              <Title level={3} style={{ margin: 0 }}>
                {t('taxOrganizerYear')} {documentShareInvites.taxYear}
              </Title>

              <Paragraph style={{ marginTop: '1rem' }}>
                {`${t('messageFrom')} ${documentShareInvites.invitedBy.firstName} ${
                  documentShareInvites.invitedBy.lastName
                }:`}
              </Paragraph>

              <div className={styles.alert_container}>
                <Paragraph className={styles.alert_text}>{documentShareInvites.message}</Paragraph>
              </div>

              {documentShareInvites.zipFile && (
                <Button type="primary" size="large">
                  <Link to={{ pathname: documentShareInvites.zipFile }} target="_blank" download>
                    {t('buttonDownload')}
                  </Link>
                </Button>
              )}

              <div className={styles.file_container}>
                <div className={styles.file_row}>
                  <div className={styles.file_item_count}>{`${
                    documentShareInvites.taxDocuments.length
                  } ${documentShareInvites.taxDocuments.length > 1 ? 'files' : 'file'}`}</div>
                </div>

                {documentShareInvites.taxDocuments.map((taxDoc) => (
                  <div key={taxDoc.taxDocument.guid} className={styles.file_row}>
                    <Link to={{ pathname: taxDoc.taxDocument.file! }} target="_blank" download>
                      {taxDoc?.taxDocument?.name}
                    </Link>
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default SharedDocuments
