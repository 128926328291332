import { Form } from 'antd'
import { Box, Flex } from 'reflexbox'

import { Button, TextField } from 'components'
import { useTranslation } from 'react-i18next'
import { useEntity, useEntityDocumentsActions } from 'context/Entity'

type TProps = {
  documentName: string
  documentGuid: string
  setPopoverVisible: (value: boolean) => void
  setDocumentName: (value: string) => void
}

const FormComponent = ({
  documentName,
  documentGuid,
  setPopoverVisible,
  setDocumentName,
}: TProps) => {
  const [form] = Form.useForm()
  const { t } = useTranslation('common', { keyPrefix: 'buttons' })
  const { t: t2 } = useTranslation('common', { keyPrefix: 'form' })
  const { updateDocument, response, loading } = useEntityDocumentsActions()
  const {
    entity: {
      currentEntity: { guid },
    },
  } = useEntity()

  const onSubmit = async ({ name }: { name: string }) => {
    const doc = await updateDocument(guid, documentGuid, { name })
    if (response.ok) {
      setDocumentName(doc.name)
      setPopoverVisible(false)
    }
  }
  return (
    <>
      <Box mt={[2]}>
        <Form form={form} initialValues={{ name: documentName }} onFinish={onSubmit}>
          <Flex justifyContent={['space-between']} alignItems={['baseline']}>
            <Form.Item
              key="name"
              name="name"
              rules={[{ required: true, message: t2('requiredField', { field: t2('name') }) }]}
            >
              <TextField />
            </Form.Item>
            <Box fontSize={['16px']} fontWeight={['600']} mt={[2]} ml={[2]}>
              <Button
                htmlType="submit"
                type="primary"
                size="large"
                loading={loading}
                disabled={loading}
              >
                {t('save')}
              </Button>
            </Box>
          </Flex>
        </Form>
      </Box>
    </>
  )
}

export default FormComponent
