import { useTranslation } from 'react-i18next'
import { EllipsisOutlined } from '@ant-design/icons'
import { useCPAFirmActions } from 'context/CPAFirm'
import { Dropdown, Menu } from 'antd'
import { cloneDeep } from 'lodash'
import styles from '../../dashboard.firm.module.scss'
import { DEFAULT_PARAMS } from '../constants'

type TProps = {
  cpaFirmGuid: string
  inviteGuid: string
  setParams: (state: TParams | ((state: TParams) => TParams)) => void
}

const Actions = ({ cpaFirmGuid, inviteGuid, setParams }: TProps) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'clients.table' })
  const {
    resendFirmInvitation,
    loading: loadingResend,
    response: responseResend,
  } = useCPAFirmActions()
  const {
    revokeFirmInvitation,
    loading: loadingRevoke,
    response: responseRevoke,
  } = useCPAFirmActions()

  const onReSend = async () => {
    await resendFirmInvitation(cpaFirmGuid, inviteGuid)
    if (responseResend.ok) {
      setParams(cloneDeep(DEFAULT_PARAMS))
    }
  }
  const onRevoke = async () => {
    await revokeFirmInvitation(cpaFirmGuid, inviteGuid)
    if (responseRevoke.ok) {
      setParams(cloneDeep(DEFAULT_PARAMS))
    }
  }

  return (
    <Dropdown
      placement="bottomRight"
      trigger={['click']}
      overlay={
        <Menu theme="dark" className={styles.actions_menu}>
          <Menu.Item key="resend" disabled={loadingResend} onClick={onReSend}>
            {t('resend')}
          </Menu.Item>
          <Menu.Item key="revoke" disabled={loadingRevoke} onClick={onRevoke}>
            {t('revoke')}
          </Menu.Item>
        </Menu>
      }
    >
      <EllipsisOutlined className={styles.actions_icon} />
    </Dropdown>
  )
}

export default Actions
