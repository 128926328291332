import { Select, QuestionIcon } from 'components'
import { useEffect, useState } from 'react'
import {
  useTaxReturns,
  useTaxReturnsActions,
  TFilingStatus,
  TTaxReturn,
  FilingStatusEnum,
  IInviteContact,
} from 'context/TaxReturns'
import Title from 'pages/TaxOrganizer/TaxReturn/components/Title'
import { Flex } from 'reflexbox'
import { useTranslation } from 'react-i18next'
import { useTaxOrganizer } from 'context/TaxOrganizer'
import styles from '../authorize.module.scss'
import RadioCardItem from '../components/RadioCardItem'

const FilingStatus = () => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'taxReturns.authorize' })

  const [selectedFilingStatusID, selectFilingStatusID] = useState<FilingStatusEnum>()
  const [additionalSigner, setAdditionalSigner] = useState(null)
  const {
    updateTaxReturn,
    loadFilingStatus,
    loadEntitySigners,
    loadEntityInviteList,
    assignAuthorizedSigner,
  } = useTaxReturnsActions()

  const {
    state: {
      filingStatus,
      entitySigners,
      entityInvites,
      taxReturns: { latest: latestTaxReturn },
    },
  } = useTaxReturns()

  const {
    taxOrganizer: {
      data: {
        entity: {
          primaryContact: { guid: primaryGuid, email: primaryEmail },
        },
      },
    },
  } = useTaxOrganizer()

  const {
    taxOrganizer: {
      data: {
        entity: { guid: entityguid },
      },
    },
  } = useTaxOrganizer()

  useEffect(() => {
    if (filingStatus.length === 0) loadFilingStatus()
    if (entitySigners.length === 0) loadEntitySigners(entityguid, false)
    if (entityInvites.length === 0) loadEntityInviteList(entityguid)
  }, [])

  const updateAuthorizeStep = async () => {
    await assignAuthorizedSigner(primaryEmail, latestTaxReturn?.guid!, 0)
    if (additionalSigner) {
      await assignAuthorizedSigner(additionalSigner, latestTaxReturn?.guid!, 1)
    }
    const patchTaxReturnBody: Partial<TTaxReturn> = {
      ...latestTaxReturn,
      authorizeStep: 'E_SIGN',
      filingStatus: selectedFilingStatusID,
    }
    await updateTaxReturn(latestTaxReturn?.guid!, patchTaxReturnBody)
  }

  const optionsFStatusCode = 'MARRIED_JOINT'
  const entitySignerOptions = entitySigners
    .filter((s: ICpaContact) => primaryGuid !== s.user.guid)
    .map((s: ICpaContact) => {
      return { label: `${s.user.email}`, value: s.user.email }
    })

  const entityInviteOptions = entityInvites.map((s: IInviteContact) => {
    return { label: `${s.email}`, value: s.email }
  })

  const options = entitySignerOptions.concat(entityInviteOptions)

  return (
    <>
      <Flex>
        <Title text={t('filingStatus.title')} />
        <QuestionIcon className={styles.question_icon} />
      </Flex>
      {filingStatus.map((i: TFilingStatus) => {
        return (
          <RadioCardItem
            key={i.code}
            title={i.description}
            buttonText={t('confirm')}
            selected={selectedFilingStatusID === i.code}
            itemClick={() => selectFilingStatusID(i.code)}
            buttonClick={updateAuthorizeStep}
            content={
              i.code === optionsFStatusCode && (
                <Flex>
                  <Select
                    key={i.code}
                    size="large"
                    disabled={options.length < 1}
                    className={styles.selectopt}
                    options={options}
                    placeholder={t('filingStatus.selectUser')}
                    onChange={(selectedEmail: any) => setAdditionalSigner(selectedEmail)}
                  />
                  <QuestionIcon className={styles.question_icon_select} />
                </Flex>
              )
            }
          />
        )
      })}
    </>
  )
}

export default FilingStatus
