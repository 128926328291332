import { useState } from 'react'
import { TAlert } from 'components/Alert'

const DEFAULT_SETTINGS: Readonly<TAlert> = {
  status: false,
  message: '',
  type: undefined,
}

export default ({ status, message, type }: TAlert = DEFAULT_SETTINGS) => {
  const [alert, setAlert] = useState<TAlert>({ status, message, type })
  const resetAlert = () => setAlert(DEFAULT_SETTINGS)

  return { alert, setAlert, resetAlert }
}
