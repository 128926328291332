import { useState } from 'react'
import { Box, Flex } from 'reflexbox'
import { Form, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { startCase, toLower } from 'lodash'

import { Button, TextField, Textarea } from 'components'

import { useEntityCPAsActions } from 'context/EntityCPAs'

const { Title, Text } = Typography

type TProps = {
  setVisiblePopover: (visible: boolean) => void
}

const ForwardPopoverContent = ({ setVisiblePopover }: TProps) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'clients.popover' })
  const { t: t2 } = useTranslation('dashboard', { keyPrefix: 'clients.table' })
  const { sendInvitations } = useEntityCPAsActions()
  const [fakeLoading, setFakeLoading] = useState(false)

  const onSubmit = async ({ email, message, entities = [] }: any) => {
    await sendInvitations('guid', { email, message, entities })
    setFakeLoading(true)
    setTimeout(() => {
      setVisiblePopover(false)
    }, 1000)
  }
  return (
    <>
      <Flex width={['300px', '520px']} p={[1, 3]}>
        <Form onFinish={onSubmit} autoComplete="off">
          <Flex flexDirection="column" justifyContent="flex-start" alignItems="inherit">
            <Box fontSize={['20px']}>
              <Title level={4}>{t2('popoverForward.title')}</Title>
            </Box>
            <Box>
              <Text>{t2('popoverForward.text1')}</Text>
            </Box>
            <Box mt={[3]}>
              <Box>
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: `${t('email')} ${t('required')}` },
                    { type: 'email', message: `${t('email')} ${t('notValid')}` },
                  ]}
                >
                  <TextField type="email" placeholder={t('email')} />
                </Form.Item>
              </Box>
              <Box mt={[2]}>
                <Form.Item
                  name="note"
                  rules={[
                    {
                      required: true,
                      message: `${startCase(toLower(t2('popoverForward.note')))} ${t('required')}`,
                    },
                  ]}
                >
                  <Textarea
                    autoSize={{ minRows: 3 }}
                    style={{ borderRadius: '18px' }}
                    placeholder={t('textAreaPlaceholder')}
                  />
                </Form.Item>
              </Box>
              <Flex
                my={[0, 2]}
                alignSelf={['stretch', 'flex-end']}
                flexShrink={[0]}
                flexGrow={[1]}
                justifyContent={['flex-end']}
              >
                <Button type="primary" size="large" htmlType="submit" loading={fakeLoading}>
                  {t('submitButton')}
                </Button>
              </Flex>
            </Box>
          </Flex>
        </Form>
      </Flex>
    </>
  )
}

export default ForwardPopoverContent
