import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import localeEn from 'locales/en'
import localeEs from 'locales/es'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    defaultNS: 'common',
    resources: {
      en: localeEn,
      es: localeEs,
      es_US: localeEs,
    },
  })

export default i18n
