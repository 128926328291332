import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Flex } from 'reflexbox'
import { EditOutlined } from '@ant-design/icons'
import { Popover } from 'antd'

import { useEntity } from 'context/Entity'

import { Button } from 'components'

import styles from '../../dashboard.document.module.scss'
import Form from './components/Form'

type TProps = {
  disabled: boolean
}

const Name = ({ disabled }: TProps) => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'document' })
  const {
    entity: { currentEntityDocument: document },
  } = useEntity()
  const [popoverVisible, setPopoverVisible] = useState(false)
  const [documentName, setDocumentName] = useState('')

  useEffect(() => {
    setDocumentName(document!.name)
  }, [document])

  return (
    <div className={styles.right_section}>
      <div className={styles.right_section_top}>
        <span className={styles.right_section_title}>{t('name')}</span>
      </div>
      <Flex justifyContent={['space-between']} alignItems={['baseline']}>
        <Box fontSize={['16px']}>{documentName}</Box>
        <Popover
          content={
            <Form
              documentName={documentName}
              documentGuid={document!.guid}
              setPopoverVisible={setPopoverVisible}
              setDocumentName={setDocumentName}
            />
          }
          trigger="click"
          destroyTooltipOnHide
          visible={popoverVisible}
          placement="left"
          onVisibleChange={(visibility: boolean) => setPopoverVisible(visibility)}
        >
          <Button type="link" size="middle" icon={<EditOutlined />} disabled={disabled} />
        </Popover>
      </Flex>
    </div>
  )
}

export default Name
