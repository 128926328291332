import { createContext, useContext, Dispatch, FC, useReducer } from 'react'

import { useReducerLogger } from 'utils'

import { reducer, initialState } from './reducer'
import { ActionTypes } from './action-types'
import { TAction, TState } from './types'

interface IContextProps {
  state: TState
  dispatch: Dispatch<TAction>
}

const BillingContext = createContext<IContextProps>({} as IContextProps)

const BillingProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(useReducerLogger<TState, TAction>(reducer), initialState)

  return <BillingContext.Provider value={{ state, dispatch }}>{children}</BillingContext.Provider>
}

const useBilling = () => {
  const context = useContext(BillingContext)
  if (context === undefined) {
    throw new Error('useBilling must be used within a BillingProvider')
  }
  return context
}

export { BillingProvider, useBilling, ActionTypes }
