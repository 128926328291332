import { isFunction } from 'lodash'
import { TFile } from 'context/TaxOrganizer'
import { TFileExtended, TFileProgressSettings } from '../types'

export const setTotalProgressHelper = (
  prevData: TFileProgressSettings[],
  file: TFileExtended,
  event: ProgressEvent
) => {
  return prevData.map((data: TFileProgressSettings) => {
    if (file.uid === data.uid) {
      if (!data.start) {
        return {
          ...data,
          total: data.total + event.total,
          loaded: data.loaded + event.loaded,
          start: true,
        }
      }
      return {
        ...data,
        total: event.total,
        loaded: event.loaded,
      }
    }
    return data
  })
}

export const getProgress = (
  totalProgress: TFileProgressSettings[],
  files: TFile[] | TFileExtended[],
  onFinishUploading?: () => void
) => {
  return totalProgress.reduce(
    (
      acc: { total: number; loaded: number; width: number; filesLeft: number; isFinished: boolean },
      cur: TFileProgressSettings,
      index: number,
      arr: TFileProgressSettings[]
    ) => {
      acc.total += cur.total
      acc.loaded += cur.loaded
      if (acc.total !== 0 && acc.loaded !== 0) {
        acc.width += (cur.loaded * 100) / cur.total / arr.length
      }
      if (acc.filesLeft > 0) {
        acc.filesLeft -= cur.loaded !== 0 && cur.loaded === cur.total ? 1 : 0
      }
      if (acc.total && acc.loaded >= acc.total && index === arr.length - 1) {
        acc.isFinished = true
        if (onFinishUploading && isFunction(onFinishUploading)) {
          onFinishUploading()
        }
      }
      return acc
    },
    { total: 0, loaded: 0, width: 0, filesLeft: files?.length ?? 0, isFinished: false }
  )
}
