import { useEffect, useState } from 'react'
import { div, mod } from 'utils'
import { IThread } from 'context/Messages'

export default ({ sidebarCollapsed, params, chunk, count }: any) => {
  const [more, setMore] = useState(true)
  const [threads, setThreads] = useState<IThread[]>([])
  const [nextStep, setNextStep] = useState(false)

  useEffect(() => {
    if (sidebarCollapsed) {
      setMore(true)
      setThreads([])
      setNextStep(false)
    }
  }, [sidebarCollapsed])

  useEffect(() => {
    if (params.page === 1) {
      setThreads(chunk)
    } else {
      setThreads((currentThreads) => [...currentThreads, ...chunk])
    }
  }, [chunk])

  useEffect(() => {
    setNextStep(
      div(count, threads.length) > 1 ||
        (div(count, threads.length) === 1 && mod(count, threads.length) > 0)
    )
    setMore(threads.length < count)
  }, [threads])

  return { more, setMore, threads, setThreads, nextStep, setNextStep }
}
