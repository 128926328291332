import { SyntheticEvent, useMemo, useState } from 'react'
import { Select } from 'antd'
import { Box, Flex } from 'reflexbox'
import { CloseOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import { states as statesList } from 'constants/address'

import { Button } from 'components'

import styles from '../../../categorize.module.scss'

const formatlabel = (label: string) => {
  return (
    <Flex fontSize={['16px']} key={label}>
      <Box fontWeight={[600]}>{label.split(' ').shift()}</Box>&nbsp;
      <Box>{label.split(' ').pop()}</Box>
    </Flex>
  )
}

type TProps = {
  states: string[]
  setSelectedTags: (selectedTags: string[]) => void
  loading: boolean
}

const ModalContent = ({ states, setSelectedTags, loading }: TProps) => {
  const { t } = useTranslation('taxOrganizer')
  const [tags, setTags] = useState<string[]>(states)
  const [stateValue, setStateValue] = useState<string | null>()
  const options = useMemo(
    () => statesList.filter((state: TOption) => !tags.includes(state.value as string)),
    [states, tags]
  )

  const handleSelect = (selectedLabel: string) => {
    const findTag = statesList.find((state) => state.label === selectedLabel)
    if (findTag) {
      setTags([...tags, findTag.value])
    }
    setStateValue(null)
  }

  const handleClose = (closeTag: string) => {
    setTags(tags.filter((tag) => tag !== closeTag))
  }

  const addStateHandler = () => {
    // if (tags.length) {
    setSelectedTags(tags.map((tag) => tag.toLowerCase()))
    setTags([])
    // }
  }

  return (
    <Flex
      px={[2]}
      py={[3]}
      flexDirection={['column']}
      maxWidth={['100%', '100%', '550px']}
      onClick={(event: SyntheticEvent) => {
        event.stopPropagation()
      }}
    >
      <Box lineHeight={['24px']} fontSize={['20px']} fontWeight={[600]} mb={[3]}>
        {t('statesModal.selectState')}
      </Box>
      <Flex
        flexWrap={['wrap']}
        pt={[2]}
        px={[2]}
        style={{ borderRadius: '8px', border: '1px solid lightgray' }}
      >
        {tags.map((tag: string) => {
          return (
            <Box mb={[2]} mr={[2]} key={`tag-${tag}`} className={styles.tag}>
              <Button size="large" flex>
                <Box fontSize={['16px']} fontWeight={[400]} mr={[1]}>
                  {tag}
                </Box>
                <CloseOutlined onClick={() => handleClose(tag)} />
              </Button>
            </Box>
          )
        })}
        <Box width={['auto']} minWidth={['120px']} mb={[2]}>
          <Select
            style={{ width: '100%' }}
            dropdownMatchSelectWidth={false}
            showSearch
            showArrow
            placeholder="Search"
            onSelect={handleSelect}
            value={stateValue}
          >
            {options.map((state: TOption) => {
              return <Select.Option key={state.label}>{formatlabel(state.label)}</Select.Option>
            })}
          </Select>
        </Box>
      </Flex>
      <Flex
        style={{ borderRadius: '8px', border: '1px solid lightgray' }}
        my={['16px']}
        p={[2]}
        flexWrap={['wrap']}
      >
        {statesList.map((tag: TOption) => {
          return (
            <Box m={[2]} key={`button-${tag.label}`} width={['35px']}>
              <Button
                type="primary"
                ghost
                block
                flex
                disabled={tags.includes(tag.value as string)}
                onClick={() => handleSelect(tag.label)}
                style={{ height: '27px' }}
              >
                {tag.value}
              </Button>
            </Box>
          )
        })}
      </Flex>
      <Flex justifyContent={['flex-end']}>
        <Button
          type="primary"
          size="large"
          onClick={() => addStateHandler()}
          loading={loading}
          disabled={loading}
        >
          {t('states.edit')}
        </Button>
      </Flex>
    </Flex>
  )
}

export default ModalContent
