import { useEffect, useMemo, useState, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Row, Col, Form, Button } from 'antd'
import { CheckCircleOutlined, FileOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { debounce } from 'lodash'
import cn from 'classnames'

import { useQuestionsActions, TQQuestionType, TQDocumentType } from 'context/Questions'
import { useTaxOrganizer, useTaxOrganizerActions } from 'context/TaxOrganizer'

import { Textarea, Upload, UploadModal } from 'components'

import useMediaQuery from 'hooks/useMediaQuery'

import { ReactComponent as CommentIcon } from 'assets/images/comment.svg'

import styles from '../../../tax.question.module.scss'

import Help from './Help'

type TProps = {
  question: TQQuestionType
  index: number
  activeCategory: string
  taxIsLocked: boolean
}

const Question = ({ question, index, activeCategory, taxIsLocked }: TProps) => {
  const { pathname } = useLocation()
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'questionnaire' })
  const { t: t2 } = useTranslation('common')
  const [form] = Form.useForm()
  const isMediumScreen = useMediaQuery('md')
  const {
    loadQuestions,
    loadTQCategories,
    updateQuestion,
    updateQuestionValueSync,
    updateQuestionTextSync,
  } = useQuestionsActions()
  const { loadTaxOrganizer } = useTaxOrganizerActions()
  const {
    taxOrganizer: {
      data: {
        guid: taxOrganizerGuid,
        entity: { kind },
        taxYear,
      },
    },
  } = useTaxOrganizer()

  const [modal, setModal] = useState(false)
  const [files, setFiles] = useState([])
  const [checkedValue, setCheckedValue] = useState(question.response?.value)
  const timerRef = useRef<number>()

  const handleUpload = ({ fileList }: any) => {
    setFiles(fileList)
    setModal(true)
  }

  const handleHide = () => {
    setModal(false)
    setFiles([])
    loadQuestions({
      category: activeCategory,
      taxOrganizer: taxOrganizerGuid,
      entityKind: kind.label,
      taxYear,
    })
  }

  const handleSwitch = async (value: boolean) => {
    setCheckedValue(value)
    updateQuestionValueSync(question.guid, value)
    const params = {
      taxOrganizer: taxOrganizerGuid,
      guid: question.guid,
      response: {
        value,
      },
    }
    await updateQuestion(params)
    loadTQCategories(taxOrganizerGuid, {
      entityKind: kind.label,
      taxYear,
    })
    loadTaxOrganizer(taxOrganizerGuid)
  }

  const onValuesChange = async ({ text }: { text: string }) => {
    clearTimeout(timerRef.current)
    timerRef.current = window.setTimeout(async () => {
      updateQuestionTextSync(question.guid, text)
      const params = {
        taxOrganizer: taxOrganizerGuid,
        guid: question.guid,
        response: {
          response: text,
        },
      }
      await updateQuestion(params)
      loadTQCategories(taxOrganizerGuid, {
        entityKind: kind.label,
        taxYear,
      })
      loadTaxOrganizer(taxOrganizerGuid)
    }, 300)
  }

  const debouncedTextChangeHandler = useMemo(() => debounce(onValuesChange, 300), [])

  useEffect(() => {
    return () => {
      debouncedTextChangeHandler.cancel()
    }
  }, [])

  return (
    <div className={styles.item} key={question.guid}>
      <Form form={form} onValuesChange={debouncedTextChangeHandler}>
        <div className={styles.item_head}>
          <Row align="middle" gutter={[25, 14]}>
            <Col flex="1" style={{ display: 'flex' }}>
              <div className={styles.item_circle}>{index + 1}</div>
              <div className={styles.item_title}>
                {question.title}
                <Help info="stub" />
              </div>
            </Col>
            <Col>
              <Form.Item>
                <Button
                  type={checkedValue === true ? 'primary' : 'default'}
                  icon={checkedValue ? <CheckCircleOutlined /> : <MinusCircleOutlined />}
                  style={{ marginLeft: '10px' }}
                  onClick={() => {
                    handleSwitch(true)
                  }}
                  disabled={taxIsLocked}
                >
                  Yes
                </Button>
                <Button
                  disabled={taxIsLocked}
                  type={checkedValue === false ? 'primary' : 'default'}
                  icon={
                    checkedValue === undefined ? (
                      <MinusCircleOutlined />
                    ) : checkedValue === true ? (
                      <MinusCircleOutlined />
                    ) : (
                      <CheckCircleOutlined />
                    )
                  }
                  style={{ marginLeft: '10px' }}
                  onClick={() => {
                    handleSwitch(false)
                  }}
                >
                  {t2('switcher.no')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className={styles.item_content}>
          <Row gutter={[24, 24]}>
            <Col flex={isMediumScreen ? '1' : 'auto'}>
              <Form.Item
                name="text"
                initialValue={question.response?.response}
                style={{ marginBottom: 0 }}
              >
                <Textarea
                  disabled={taxIsLocked}
                  placeholder={t('textareaPlaceholder')}
                  icon={<CommentIcon />}
                  style={{ height: '188px' }}
                  className={styles.textarea}
                />
              </Form.Item>
            </Col>
            <Col>
              <div className={styles.box_upload}>
                <Upload onChange={handleUpload} fileList={[]} disabled={taxIsLocked} />
              </div>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col
              flex={isMediumScreen ? '1' : 'auto'}
              className={taxIsLocked ? 'disabled-wrapper' : ''}
            >
              <div className={cn(styles.item_files, taxIsLocked ? 'disabled' : '')}>
                {question.response &&
                  question.response?.documents?.map((document: TQDocumentType) => (
                    <div key={document.guid} className={styles.item_file} title={document.name}>
                      <FileOutlined />
                      <Link
                        to={{
                          pathname: `/tax-organizer/${taxOrganizerGuid}/documents/${document.guid}`,
                          state: {
                            prevPath: pathname,
                          },
                        }}
                      >
                        {document.name}
                      </Link>
                    </div>
                  ))}
              </div>
            </Col>
          </Row>
        </div>
      </Form>
      {modal && <UploadModal fileList={files} tqQuestion={question.guid} onHide={handleHide} />}
    </div>
  )
}

export default Question
