import { Key } from 'react'
import { useTranslation } from 'react-i18next'
import { ColumnsType } from 'antd/lib/table'
import { Typography } from 'antd'
import { PaperClipOutlined } from '@ant-design/icons'

import { TClientInviteParams } from 'context/EntityCPAs'

import { TEntity } from '../../components/types'
import Actions from '../components/Actions'

const { Paragraph, Text } = Typography

type Props = {
  dataOnPageCount: number
  setParams: (
    value: TClientInviteParams | ((prevState: TClientInviteParams) => TClientInviteParams)
  ) => void
  setUninvitedSelectedRows: (keys: Key[]) => void
}

export default ({ dataOnPageCount, setUninvitedSelectedRows, setParams }: Props) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'clients.table' })

  const entityNameColumnWidth = 300

  const textComma = (entity: TEntity, isLastRecord: boolean) => {
    const { name, kind, guid, documents } = entity
    const numDocuments = documents ? documents.length : 0

    return (
      <>
        <Text key={guid} className="text-black">{`${name} (${kind}`}</Text>
        {numDocuments > 0 ? (
          <>
            <Text>, {numDocuments}</Text>
            <PaperClipOutlined />
          </>
        ) : null}
        <Text>)</Text>
        {!isLastRecord && <>{', '}</>}
      </>
    )
  }

  const renderEntities = (entities: TEntity[]) => {
    if (entities.length === 1) {
      return textComma(entities[0], true)
    }

    return (
      <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}>
        {entities.map((entity: TEntity, index: number) => {
          const isLastRecord = index === entities.length - 1
          return textComma(entity, isLastRecord)
        })}
      </Paragraph>
    )
  }

  const actionColumn = {
    title: t('actions'),
    dataIndex: 'actions',
    key: 'actions',
    render: (_: any, { guid: inviteGuid, cpaFirm: { guid: cpaFirmGuid } }: IClientInvite) => {
      return (
        <Actions
          inviteGuid={inviteGuid}
          cpaFirmGuid={cpaFirmGuid}
          dataOnPageCount={dataOnPageCount}
          setParams={setParams}
          setUninvitedSelectedRows={setUninvitedSelectedRows}
        />
      )
    },
  }

  const columns: ColumnsType<IClientInvite> = [
    {
      title: t('recipient'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('personalNote'),
      dataIndex: 'message',
      key: 'message',
    },
    {
      title: t('entities'),
      dataIndex: ['entities'],
      key: 'entities',
      width: entityNameColumnWidth,
      render: (entities: TEntity[]) => renderEntities(entities),
    },
    actionColumn,
  ]

  return columns
}
