import { Form } from 'antd'
import Icon from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import { useDocument, useDocumentActions } from 'context/Document'

import { Button, TextField } from 'components'

import { ReactComponent as Telegram } from 'assets/images/telegram.svg'

import styles from '../../documentview.module.scss'

type TProps = {
  documentGuid: string
  taxOrganizerId: string
  contextKey: string
  taxReturnId?: string
  taxReturnPageId?: string
  setReloadThreads: (flag: boolean | ((newFlag: boolean) => boolean)) => void
}

const NewThreadForm = ({
  documentGuid,
  taxOrganizerId,
  contextKey,
  taxReturnId,
  taxReturnPageId,
  setReloadThreads,
}: TProps) => {
  const { newThread } = useDocument()
  const { createThread, setNewThread, loading, response } = useDocumentActions()
  const { t } = useTranslation('common', { keyPrefix: 'form' })

  const onSubmit = async (values: { message: string }) => {
    await createThread(
      taxOrganizerId,
      documentGuid,
      {
        page: newThread.page,
        x: newThread.x,
        y: newThread.y,
        message: values.message,
        taxReturnGuid: taxReturnId,
        taxReturnPageGuid: taxReturnPageId,
      },
      contextKey
    )
    if (response.ok) {
      setNewThread()
      setReloadThreads((flag) => !flag)
    }
  }

  return (
    <Form
      onFinish={onSubmit}
      onClick={(e) => e.stopPropagation()}
      className={styles.new_thread_form}
    >
      <div className="me-4">
        <Form.Item
          name="message"
          rules={[{ required: true, message: t('requiredField', { field: t('message') }) }]}
          style={{ marginBottom: 0 }}
        >
          <TextField
            placeholder="Comment"
            size="large"
            className={styles.thread_submit}
            suffix={
              <Button
                type="primary"
                ghost
                size="large"
                htmlType="submit"
                className={styles.submit}
                loading={loading}
              >
                <Icon component={Telegram} />
              </Button>
            }
          />
        </Form.Item>
      </div>
    </Form>
  )
}

export default NewThreadForm
