import { Avatar } from 'antd'
import cn from 'classnames'
import { Button } from 'components'
import { ReactNode } from 'react'
import { Flex } from 'reflexbox'
import { getInitials } from 'utils'
import styles from '../../authorize.module.scss'

type TProps = {
  title: string
  buttonText: string
  avatar?: string | null
  selected: boolean
  hideRadio?: boolean
  showAvatar?: boolean
  itemClick?: () => void
  buttonClick?: () => void
  content?: ReactNode
}

const RadioCardItem = ({
  title,
  buttonText,
  avatar,
  selected,
  hideRadio = false,
  showAvatar = false,
  itemClick,
  buttonClick,
  content,
}: TProps) => {
  const userNames = title.split(' ')
  return (
    <Flex
      className={cn(styles.filing_card, selected === true ? styles.selected : '')}
      margin="16px 0px"
      onClick={itemClick}
    >
      {hideRadio === false && <div className={styles.filing_card_indicator} />}
      {showAvatar && avatar && <Avatar size={24} src={avatar} className={styles.icon} />}
      {showAvatar && !avatar && (
        <Avatar size={24} style={{ fontSize: '10px' }} className={styles.icon}>
          {getInitials({ firstName: userNames[0], lastName: userNames[1] })}
        </Avatar>
      )}
      <div className={styles.filing_info}>
        <span className={styles.filing_title}>{title}</span>
        {content && selected && <div className={styles.filing_card_content}>{content}</div>}
      </div>
      {selected && (
        <Button
          size="large"
          type="primary"
          className={styles.filing_action_button}
          onClick={buttonClick}
        >
          {buttonText}
        </Button>
      )}
    </Flex>
  )
}

export default RadioCardItem
