import { Button } from 'components'

import { ReactComponent as Plus } from 'assets/images/plus-white.svg'
import Icon from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import styles from '../../../dashboard.entity.management.module.scss'

const Integrations = () => {
  const { t } = useTranslation('entity')
  const { t: t2 } = useTranslation('common')

  return (
    <>
      <div className={styles.section_header}>
        <div className={styles.section_title}>{t('integrations.integrations')}</div>
        <Button type="link" size="large" className={styles.section_action}>
          {t2('buttons.manage')}
        </Button>
      </div>
      <div className={styles.section_description}>{t('integrations.emptyMessage')}</div>
      <Button type="primary" size="large" htmlType="submit" loading={false}>
        <Icon component={Plus} style={{ fontSize: 18 }} /> {t('integrations.addIntegration')}
      </Button>
    </>
  )
}

export default Integrations
