import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import { SizeMe } from 'react-sizeme'

import { SpinnerBackdrop } from 'components'

import styles from './pdfdocument.module.scss'

type Props = {
  file: any
  page: number
  onLoadSuccess?: (pdf: { numPages: number }) => void
}

const PDFDocument = ({ file, page, onLoadSuccess }: Props) => {
  return (
    <SizeMe>
      {({ size }) => (
        <Document
          file={file}
          className={styles.document}
          loading={<SpinnerBackdrop size="large" />}
          onLoadSuccess={onLoadSuccess}
        >
          <Page
            pageNumber={page}
            renderMode="canvas"
            className={styles.page}
            loading={<SpinnerBackdrop size="large" style={{ marginTop: 200 }} />}
            width={size.width!}
          />
        </Document>
      )}
    </SizeMe>
  )
}

export default PDFDocument
