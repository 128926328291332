import { Link, useLocation } from 'react-router-dom'
import cn from 'classnames'
import {
  getTaxOrganizerPhaseNumber,
  NUMBER_OF_PHASES_IN_PROGRESS,
  TAX_ORGANIZER_PHASES_ORDER,
} from 'utils/taxOrganizer'
import { PhaseName } from 'context/TaxOrganizer'
import { RightOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { getTaxOrganizerPathById } from 'utils/routes'
import { useTranslation } from 'react-i18next'

import styles from '../../../../dashboard.entity.management.module.scss'

type Props = {
  guid: string
  phase: PhaseName
}

const TaxOrganizerPhaseWidget = ({ phase, guid }: Props) => {
  const { t } = useTranslation('common')
  const phaseNumber = getTaxOrganizerPhaseNumber(phase)
  const { pathname } = useLocation()
  const filed = phaseNumber >= NUMBER_OF_PHASES_IN_PROGRESS
  return (
    <div className={styles.phase}>
      {[...Array(NUMBER_OF_PHASES_IN_PROGRESS)].map((item, index) => (
        <div
          key={TAX_ORGANIZER_PHASES_ORDER[index]}
          className={cn(
            styles.phaseBead,
            {
              [styles.activePhaseBead]: index <= phaseNumber && !filed,
            },
            filed && [styles.filedPhaseBead]
          )}
        />
      ))}
      <Link
        to={{
          pathname: getTaxOrganizerPathById(guid),
          state: {
            taxPrevPath: pathname,
          },
        }}
      >
        <Button className={styles.link} size="small" type="ghost">
          {t('buttons.view')} <RightOutlined className={styles.user_arrow} size={10} />
        </Button>
      </Link>
    </div>
  )
}

export default TaxOrganizerPhaseWidget
