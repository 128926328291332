import { Avatar } from 'antd'
import cn from 'classnames'

import styles from './user.module.scss'

type Props = {
  username: string
  bgColor?: string
  className?: string
  avatarLink?: string | null
  hideAvatar?: boolean
}

const User = ({ bgColor, className, username, avatarLink, hideAvatar = false }: Props) => {
  const userNames = username.split(' ')
  return (
    <div className={cn(styles.user, className)}>
      {hideAvatar ? null : avatarLink ? (
        <Avatar size="small" className={styles.icon} src={avatarLink} />
      ) : (
        <Avatar
          size="small"
          className={styles.icon}
          style={{
            backgroundColor: bgColor,
          }}
        >
          {userNames[0]?.charAt(0)}
          {userNames[1]?.charAt(0)}
        </Avatar>
      )}
      <div className="text-ellipsis">{username}</div>
    </div>
  )
}

export default User
