import { FC, Dispatch, createContext, useReducer, useContext } from 'react'
import { useReducerLogger } from 'utils'
import { TAction, TState } from './types'

import { initialState, DashboardReducer } from './reducer'

const DashboardContext = createContext<TState>({} as TState)
const DashboardDispatchContext = createContext<Dispatch<TAction>>(() => null)

export function useDashboard() {
  const context = useContext(DashboardContext)
  if (context === undefined) {
    throw new Error('useDashboard must be used within a DashboardProvider')
  }
  return context
}

export function useDashboardDispatch() {
  const context = useContext(DashboardDispatchContext)
  if (context === undefined) {
    throw new Error('useDashboardDispatch must be used within a DashboardProvider')
  }
  return context
}

export const DashboardProvider: FC = ({ children }) => {
  const [data, dispatch] = useReducer(
    useReducerLogger<TState, TAction>(DashboardReducer),
    initialState
  )

  return (
    <DashboardContext.Provider value={data}>
      <DashboardDispatchContext.Provider value={dispatch}>
        {children}
      </DashboardDispatchContext.Provider>
    </DashboardContext.Provider>
  )
}
