import { Button, Row, Col, Alert } from 'antd'
import { useAuth, useAuthActions } from 'context/Auth'
import { useTranslation } from 'react-i18next'

import colors from 'assets/styles/variables.module.scss'
import styles from '../../dashboard.header.module.scss'

const MaskBanner = () => {
  const { user } = useAuth()
  const { maskOff } = useAuthActions()
  const { t } = useTranslation('dashboard', { keyPrefix: 'maskBanner' })

  const maskBanner = () => (
    <span style={{ color: 'white' }}>
      {t('behalfEmail', { email: user?.email })}
      <Button className={styles.stop_mask} type="link" onClick={() => maskOff()}>
        {t('exit')}
      </Button>
    </span>
  )

  return (
    <Row>
      <Col span={24} className={styles.mask}>
        <Alert
          style={{ borderRadius: 0, backgroundColor: colors.error }}
          type="error"
          showIcon={false}
          message={maskBanner()}
          banner
        />
      </Col>
    </Row>
  )
}

export default MaskBanner
