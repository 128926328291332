import { Box, Flex } from 'reflexbox'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { message } from 'antd'

import { PAGE_SIZE } from 'constants/table'
import { MESSAGE_DURATION } from 'constants/message'

import { useInvite, useInviteActions } from 'context/Invites'

import usePayment from 'hooks/usePayment'

import { Table } from 'components'
import BillingModal from 'components/BillingModal'

import useColumns from './hooks/useColumns'

const DEFAULT_PARAMS: Readonly<TParams> = {
  page: 1,
  pageSize: PAGE_SIZE,
}

const Entity = () => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'clients.titles' })
  const { t: t2 } = useTranslation('common')
  const { loadCPAEntityInvitations, loading } = useInviteActions()
  const { cpaEntityInvites } = useInvite()
  const [params, setParams] = useState<TLoadEntitiesParams>(DEFAULT_PARAMS)
  const [currentPage, setCurrentPage] = useState<number | undefined>(DEFAULT_PARAMS.page)
  const [billingModalVisible, setBillingModalVisible] = useState(false)
  const { stripePromise, paymentStatus, setPaymentStatus } = usePayment()
  const { columns } = useColumns({ setBillingModalVisible })

  useEffect(() => {
    loadCPAEntityInvitations(params)
  }, [params])

  const handlePageChange = (page: number) => {
    setParams((prevParams: TLoadEntitiesParams) => {
      setCurrentPage(page)
      return { ...prevParams, page }
    })
  }

  useEffect(() => {
    if (paymentStatus === 'success') {
      setBillingModalVisible(false)
      message.success(t2('billing.billingStatusSuccess'), MESSAGE_DURATION)
    }
  }, [paymentStatus])

  return (
    <>
      <Box>
        <Flex
          my={[2]}
          justifyContent={['space-between']}
          alignItems={['center']}
          flexWrap={['wrap']}
          flexDirection={['row']}
        >
          <Box fontSize={['20px']} fontWeight={[600]} my={[3]} width={['100%', 'auto']}>
            {t('invitationsForYourTaxFirm')}
          </Box>
        </Flex>
        <Table
          table={{
            dataSource: cpaEntityInvites.results,
            columns,
            rowKey: 'guid',
            loading,
          }}
          pagination={{
            total: cpaEntityInvites.count,
            onChange: handlePageChange,
            current: currentPage,
          }}
        />
      </Box>
      {billingModalVisible && (
        <BillingModal
          modalVisible={billingModalVisible}
          setModalVisible={setBillingModalVisible}
          stripePromise={stripePromise}
          setPaymentStatus={setPaymentStatus}
          alertMessage={t2('billing.acceptExceeded')}
        />
      )}
    </>
  )
}

export default Entity
