import { useEffect, useState } from 'react'
import { div, mod } from 'utils'

const DEFAULT_PAGE = 1

export default ({ page, chunk, total }: any) => {
  const [list, setList] = useState<any[]>([])
  const [nextStep, setNextStep] = useState(false)

  useEffect(() => {
    if (page === DEFAULT_PAGE) {
      setList(chunk)
    } else {
      setList((currentList) => [...currentList, ...chunk])
    }
  }, [chunk])

  useEffect(() => {
    setNextStep(
      div(total, list.length) > 1 || (div(total, list.length) === 1 && mod(total, list.length) > 0)
    )
  }, [list])

  return { list, nextStep }
}
