import { Link, useLocation } from 'react-router-dom'
import { LeftOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import { Button } from 'components'
import { useTranslation } from 'react-i18next'

import styles from '../../dashboard.document.module.scss'

type TProps = {
  backPath: string
}

const Navigation = ({ backPath }: TProps) => {
  const { t } = useTranslation('common')
  const { state }: any = useLocation()
  return (
    <Row gutter={[20, 0]}>
      <Col xs={24} lg={15}>
        <div className={styles.nav}>
          <Link to={state?.prevPath ?? backPath}>
            <Button type="text" size="large">
              <LeftOutlined style={{ fontSize: 14 }} />
              {t('buttons.back')}
            </Button>
          </Link>
        </div>
      </Col>
    </Row>
  )
}

export default Navigation
