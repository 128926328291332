import { Menu } from 'antd'
import SubMenu from 'antd/lib/menu/SubMenu'
import { TextField } from 'components'

import { SearchOutlined } from '@ant-design/icons'
import { TTaxReturnCategory, TRequestDocument } from 'context/TaxReturns'
import { useEffect, useMemo, useState } from 'react'
import { getGenerateStatesCategory } from 'utils/categorize'
import styles from '../categorize.module.scss'

type TProps = {
  categories: TTaxReturnCategory[]
  selectedTags: string[]
  handleSelectMenu: (contentMenu: Omit<TRequestDocument, 'guid' | 'categoryPageNumber'>) => void
}

const CategorizeMenu = ({ categories, selectedTags, handleSelectMenu }: TProps) => {
  const [searchTextField, setSearchTextField] = useState<string>('')
  const [states, setStates] = useState<TTaxReturnCategory[]>([])
  const [searchStates, setSearchStates] = useState<TTaxReturnCategory[]>([])

  const federal = useMemo(
    () => categories.find((category) => category.slug === 'federal'),
    [categories]
  )

  const other = useMemo(
    () => categories.find((category) => category.slug === 'other'),
    [categories]
  )

  const handleSearchChange = (value: string) => {
    setSearchTextField(value)

    const searchedStates = states.filter((state) => {
      return state.label.toLowerCase().indexOf(value.toString().toLowerCase()) !== -1
    })

    setSearchStates(searchedStates)
  }

  useEffect(() => {
    const generatedStates = getGenerateStatesCategory(categories, selectedTags)

    if (generatedStates?.length) {
      setStates(generatedStates)
      setSearchStates(generatedStates)
    }
  }, [selectedTags])

  return (
    <Menu className={styles.categorize_menu}>
      {!!federal && federal.childCategories.length && (
        <SubMenu
          key={federal.slug}
          className={styles.categorize_sub_menu}
          title={federal.label}
          popupClassName={styles.popup_sub_menu}
        >
          {federal.childCategories.map((childCategory) => (
            <Menu.Item
              key={`${childCategory.slug}-${childCategory.label.toLowerCase()}`}
              className={styles.categorize_item}
              onClick={() =>
                handleSelectMenu({
                  parentCategory: federal.slug,
                  childCategory: childCategory.slug,
                  state: null,
                })
              }
            >
              <div className={styles.categorize_item_text}>{childCategory.label}</div>
            </Menu.Item>
          ))}
        </SubMenu>
      )}

      {!!other && other.childCategories.length && (
        <SubMenu
          key={other.slug}
          className={styles.categorize_sub_menu}
          title={other.label}
          popupClassName={styles.popup_sub_menu}
        >
          {other.childCategories.map((childCategory) => (
            <Menu.Item
              key={`${childCategory.slug}-${childCategory.label.toLowerCase()}`}
              className={styles.categorize_item}
              onClick={() =>
                handleSelectMenu({
                  parentCategory: other.slug,
                  childCategory: childCategory.slug,
                  state: null,
                })
              }
            >
              <div className={styles.categorize_item_text}>{childCategory.label}</div>
            </Menu.Item>
          ))}
        </SubMenu>
      )}

      {states.length ? (
        <div className={styles.search_box}>
          <TextField
            placeholder="Search States"
            size="small"
            suffix={<SearchOutlined />}
            className={styles.menu_search}
            onChange={(event) => handleSearchChange(event.target.value)}
            value={searchTextField}
          />
        </div>
      ) : (
        <div />
      )}

      {searchStates.map((searchState) => (
        <SubMenu
          key={searchState.slug}
          className={styles.categorize_sub_menu}
          title={searchState.label}
          popupClassName={styles.popup_sub_menu}
        >
          {searchState.childCategories.map((childCategory) => (
            <Menu.Item
              key={`${searchState.slug}-${childCategory.label.toLowerCase()}`}
              className={styles.categorize_item}
              onClick={() =>
                handleSelectMenu({
                  parentCategory: 'state',
                  childCategory: childCategory.slug,
                  state: searchState.slug.toLowerCase(),
                })
              }
            >
              <div className={styles.categorize_item_text}>{childCategory.label}</div>
            </Menu.Item>
          ))}
        </SubMenu>
      ))}
    </Menu>
  )
}

export default CategorizeMenu
