import { FC } from 'react'
import { Provider, IncomingOptions, CachePolicies, Res, RetryOpts } from 'use-http'
import jwtDecode from 'jwt-decode'
import { toCamel } from 'convert-keys'
import { message } from 'antd'

import { getMessageFromResponse, ignoredUrl } from 'utils/http'

import { MESSAGE_DURATION } from 'constants/message'
import { useAuth, useAuthDispatch, useAuthActions, ActionTypes } from 'context/Auth'

const HttpProvider: FC = ({ children }) => {
  const { token: userToken, maskedToken } = useAuth()
  const dispatch = useAuthDispatch()
  const { refreshToken, response } = useAuthActions()

  const token = maskedToken || userToken
  const providerOptions: IncomingOptions = {
    interceptors: {
      request: async ({ options }: any) => {
        if (token) {
          let accessToken = token.access
          let exp
          try {
            const decoded: any = jwtDecode(accessToken)
            exp = decoded.exp
          } catch (err) {
            console.error(err)
            dispatch({ type: ActionTypes.LOGOUT })
            return false
          }

          if (Date.now() >= exp * 1000) {
            await refreshToken(token.refresh)
            if (response.ok) {
              accessToken = response.data.access
            } else {
              return false
            }
          }

          options.headers.Authorization = `Bearer ${accessToken}`
        }
        return options
      },
      response: async ({
        response: httpResponse,
      }: {
        response: Res<any>
        request: RequestInit
      }) => {
        if (httpResponse.data) {
          httpResponse.data = toCamel(httpResponse.data)
        }
        if (process.env.NODE_ENV === 'development') {
          if (/^[4|5]\d{2}$/.test(String(httpResponse?.status)) && !ignoredUrl(httpResponse.url)) {
            message.error(getMessageFromResponse(httpResponse), MESSAGE_DURATION)
          }
        }
        return httpResponse
      },
    },
    cachePolicy: CachePolicies.NO_CACHE,
    retries: 1,
    retryOn: async ({ response: httpResponse }: RetryOpts) => {
      if (token && httpResponse?.status === 401) {
        await refreshToken(token.refresh)
        return true
      }
      return false
    },
  }

  return (
    <Provider url={process.env.REACT_APP_API_URL} options={providerOptions}>
      {children}
    </Provider>
  )
}

export default HttpProvider
