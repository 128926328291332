import { useEffect, useState } from 'react'
import { Box, Flex } from 'reflexbox'
import { useTranslation } from 'react-i18next'
import {
  CloseOutlined,
  ExclamationCircleOutlined,
  FileOutlined,
  LockOutlined,
} from '@ant-design/icons'
import { Modal, Typography } from 'antd'

import { ALLOWED_DOCUMENT_FILE_TYPES } from 'utils/taxOrganizer'
import { fieldIsLocked } from 'utils'

import { useTaxOrganizer, TFile } from 'context/TaxOrganizer'
import { TTaxReturn, useTaxReturns, useTaxReturnsActions } from 'context/TaxReturns'

import useUserType from 'hooks/useUserType'

import { Upload, SpinnerBackdrop, Section, Button, Modal as ModalComponent } from 'components'
import Title from '../../components/Title'
import UploadSoftware from './components/UploadSoftware'
import FilesReturnModal from './components/FilesReturnModal'

import { ESteps } from '../../steps'

import styles from '../../dashboard.tax_return.module.scss'

const { confirm, error } = Modal
const { Paragraph } = Typography

const UploadTab = () => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'taxReturns.upload' })
  const { t: t2 } = useTranslation('common')
  const { t: t3 } = useTranslation('taxOrganizer', { keyPrefix: 'documents' })
  const { t: t4 } = useTranslation('taxOrganizer', { keyPrefix: 'locked' })
  const [files, setFiles] = useState<any>([])
  const [filesUploaded, setFilesUploaded] = useState(false)
  const [showDropzone, setShowDropzone] = useState(false)
  const { loadTaxReturnDocuments, loadingDocuments } = useTaxReturnsActions()
  const { setStep, deleteTaxReturnDocument } = useTaxReturnsActions()
  const { updateTaxReturn, loading: loadingUpd } = useTaxReturnsActions()
  const {
    taxOrganizer: {
      data: { isLocked },
    },
  } = useTaxOrganizer()
  const userType = useUserType()

  const {
    state: {
      taxReturns,
      taxReturnDocuments: { data: taxReturnDocuments, count: taxReturnDocumentsCount },
      taxReturns: { latest: latestTaxReturn },
    },
  } = useTaxReturns()

  const handleUpload = ({ fileList }: any) => {
    const noEmptyFiles = (fileList as TFile[]).every((file) => file.size && file.size > 0)
    if (!noEmptyFiles) {
      error({ title: t2('upload.fileSizeError') })
      return
    }
    setFiles(fileList)
    setFilesUploaded(!!fileList.length)
  }

  const handleHideModal = async () => {
    setFiles([])
    loadTaxReturnDocuments(latestTaxReturn!)
  }

  const handleNext = async () => {
    const patchTaxReturnBody: Partial<TTaxReturn> = {
      ...latestTaxReturn,
      phase: 'CATEGORIZE',
    }
    await updateTaxReturn(latestTaxReturn?.guid!, patchTaxReturnBody)
    setStep(ESteps.CATEGORIZE, 'push')
  }

  const handleDocumentDelete = (guid: string) => {
    confirm({
      maskClosable: true,
      title: t3('deleteConfirmation'),
      icon: <ExclamationCircleOutlined />,
      okText: t2('switcher.yes'),
      okType: 'danger',
      cancelText: t2('switcher.no'),
      onOk: () => deleteTaxReturnDocument(latestTaxReturn?.guid!, guid),
    })
  }

  useEffect(() => {
    loadTaxReturnDocuments(latestTaxReturn!)
  }, [])

  return (
    <Section>
      {loadingDocuments && <SpinnerBackdrop />}
      <Flex justifyContent={['space-between']} alignItems={['baseline']}>
        <Title text={t('title')} />
        <Button
          type="primary"
          size="large"
          disabled={
            (!filesUploaded && !taxReturnDocumentsCount) ||
            !taxReturnDocuments.length ||
            !taxReturns.data.length ||
            loadingUpd
          }
          onClick={handleNext}
        >
          {t2('buttons.next')}
        </Button>
      </Flex>

      <Flex mt={['22px']} flexDirection={['column', 'row']}>
        <Box width={['100%', '50%']}>
          {userType === 'ENTITY' ? (
            <>
              <Flex flexDirection={['column']}>
                <Box fontSize={['16px']}>{t('instruction1')}</Box>
                {!showDropzone && (
                  <Box fontSize={['16px']}>
                    <Button
                      style={{ padding: '15px 5px' }}
                      type="link"
                      onClick={() => setShowDropzone(true)}
                    >
                      {t('instruction2')}
                    </Button>
                  </Box>
                )}
              </Flex>
            </>
          ) : (
            <UploadSoftware />
          )}
        </Box>

        {userType === 'ENTITY' ? (
          showDropzone ? (
            <Box mb={[0, 3]} minWidth={['50px']}>
              <Flex
                width={['100%']}
                justifyContent={['center']}
                alignItems={['start']}
                my={[3, 0]}
                fontWeight={[600]}
                fontSize={['16px']}
              >
                {t('or')}
              </Flex>
            </Box>
          ) : null
        ) : (
          <Box mb={[0, 3]} minWidth={['50px']}>
            <Flex
              width={['100%']}
              justifyContent={['center']}
              alignItems={['start']}
              my={[3, 0]}
              fontWeight={[600]}
              fontSize={['16px']}
            >
              {t('or')}
            </Flex>
          </Box>
        )}

        <Box width={['100%', '50%']}>
          {userType === 'ENTITY' ? (
            showDropzone ? (
              <Box mb={[3]}>
                <Upload
                  accept={ALLOWED_DOCUMENT_FILE_TYPES.join(',')}
                  fileList={[]}
                  onChange={handleUpload}
                />
              </Box>
            ) : null
          ) : (
            <Box mb={[3]}>
              <Upload
                accept={ALLOWED_DOCUMENT_FILE_TYPES.join(',')}
                fileList={[]}
                onChange={handleUpload}
              />
            </Box>
          )}

          {files.length > 0 && <FilesReturnModal fileList={files} onHide={handleHideModal} />}
          {taxReturnDocumentsCount > 0 && (
            <Flex
              p={[2]}
              flexDirection="column"
              style={{
                opacity: '0.5',
                borderRadius: '8px',
                border: '1px solid lightgray',
              }}
            >
              {taxReturnDocuments.map((taxReturnDocument) => (
                <Flex
                  key={taxReturnDocument.taxDocument.guid}
                  alignItems={['center']}
                  mr={[3]}
                  py={[1]}
                  justifyContent={['space-between']}
                  className={
                    fieldIsLocked(isLocked, taxReturnDocument.afterTaxOrganizerLock)
                      ? 'disabled-wrapper'
                      : ''
                  }
                >
                  <Flex alignItems={['center']} width={['90%']}>
                    <FileOutlined />
                    <Box ml={[1]}>
                      <Paragraph
                        ellipsis={{
                          rows: 1,
                        }}
                        style={{ marginBottom: 0, width: '100%' }}
                      >
                        {taxReturnDocument?.taxDocument?.name}
                      </Paragraph>
                    </Box>
                  </Flex>
                  <Flex
                    className={styles['delete-file']}
                    onClick={() => {
                      if (fieldIsLocked(isLocked, taxReturnDocument.afterTaxOrganizerLock))
                        return false
                      handleDocumentDelete(taxReturnDocument.taxDocument.guid)
                      return true
                    }}
                    p={[1]}
                  >
                    {fieldIsLocked(isLocked, taxReturnDocument.afterTaxOrganizerLock) ? (
                      <ModalComponent content={t4('document.deleted')}>
                        <LockOutlined className="disabled-wrapper" />
                      </ModalComponent>
                    ) : (
                      <CloseOutlined className={styles.delete_icon} />
                    )}
                  </Flex>
                </Flex>
              ))}
            </Flex>
          )}
        </Box>
      </Flex>
    </Section>
  )
}

export default UploadTab
