import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { ArrowsAltOutlined } from '@ant-design/icons'
import { Button, Table } from 'components'
import { getEntityPathById } from 'utils/routes'
import { useTranslation } from 'react-i18next'
import { Box, Flex } from 'reflexbox'

import { TEntityDocumentParams, useEntity, useEntityActions } from 'context/Entity'

import useColumns from './hooks/useColumns'

const WIDGET_PAGE_SIZE = 4
const DEFAULT_PARAMS: TEntityDocumentParams = { page: 1, pageSize: WIDGET_PAGE_SIZE } as const

type TProps = { entityGuid: string }

const DocumentsWidget = ({ entityGuid }: TProps) => {
  const { t } = useTranslation('entity')
  const { getCurrentEntityDocuments, loading } = useEntityActions()
  const {
    entity: {
      documents: { data },
    },
  } = useEntity()
  const { columns } = useColumns()

  useEffect(() => {
    getCurrentEntityDocuments(entityGuid, DEFAULT_PARAMS)
  }, [])

  return (
    <>
      <Flex mb={['30px']} justifyContent={['space-between']} alignItems={['center']}>
        <Box fontSize={['20px']} fontWeight={['600']}>
          {t('documents.recentDocuments')}
        </Box>
        <Box>
          <Link to={`${getEntityPathById(entityGuid)}/documents`}>
            <Button type="link" icon={<ArrowsAltOutlined />} style={{ paddingRight: 0 }}>
              {t('documents.viewAll')}
            </Button>
          </Link>{' '}
        </Box>
      </Flex>
      <Table
        table={{ loading, columns, dataSource: data, rowKey: 'guid', size: 'small' }}
        pagination={{}}
      />
    </>
  )
}

export default DocumentsWidget
