import { Switch, Route, Redirect } from 'react-router-dom'
import { Box } from 'reflexbox'

import List from '../../List'
import Pending from '../../Pending'
import Uninvited from '../../Uninvited'

type TProps = {
  inviteClientPopoverStatus: boolean
}

const Router = ({ inviteClientPopoverStatus }: TProps) => {
  return (
    <>
      <Box mt={[1, 1, 3]}>
        <Switch>
          <Route key="inactive" path="/dashboard/clients/inactive" exact>
            <List isActive={false} />
          </Route>
          <Route key="invitations" path="/dashboard/clients/pending" exact>
            <Pending inviteClientPopoverStatus={inviteClientPopoverStatus} />
          </Route>
          <Route key="list" path="/dashboard/clients" exact>
            <List isActive />
          </Route>
          <Route key="uninvited" path="/dashboard/clients/uninvited" exact>
            <Uninvited />
          </Route>
          <Route path="/dashboard/clients/list">
            <Redirect to="/dashboard/clients" />
          </Route>
          <Route path="*">
            <Redirect to="/dashboard" />
          </Route>
        </Switch>
      </Box>
    </>
  )
}

export default Router
