import cn from 'classnames'

import styles from './title.module.scss'

type TProps = {
  title: string
  subtitles?: string[]
}

const Title = ({ title, subtitles }: TProps) => {
  return (
    <div
      className={cn([
        styles.container,
        subtitles?.length ? styles.container_md_subtitles : styles.container_md_no_subtitles,
      ])}
    >
      <div className={styles.container_title}>{title}</div>
      {!!subtitles?.length && (
        <div className={styles.container_subtitle}>
          {subtitles.map((subtitle: string, i: number) => (
            <div
              className={cn(i === 0 ? styles.container_subtitle : styles.container_subtitle_add)}
              key={subtitle}
            >
              {subtitle}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default Title
