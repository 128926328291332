import { useCallback, useState } from 'react'
import useFetch from 'use-http'
import parseHTML from 'html-react-parser'
import { debounce, filter, isEmpty, first } from 'lodash'
import { AutoComplete, Input, Spin } from 'antd'
import { getEntityPathById } from 'utils/routes'

import { useTranslation } from 'react-i18next'
import { SearchOutlined } from '@ant-design/icons'

import colors from 'assets/styles/variables.module.scss'
import styles from '../../dashboard.header.module.scss'

type ISearchResult = {
  index: string
  results: Array<any>
}

type ISearchItem = {
  displayKey: string
  record: any
  url?: string
}

const SearchOption = ({ search }: { search: ISearchItem }) => {
  const { record, displayKey } = search
  const { highlight } = record

  if (!highlight) return <>{record[displayKey]}</>

  if (displayKey in highlight) {
    const highlightValue: string = first(highlight[displayKey]) || ''
    return <>{parseHTML(`<div className=${styles.highlight}>${highlightValue}</div>`)}</>
  }

  const highlightValue: string = first(Object.values(highlight)) || ''
  return (
    <>
      {record[displayKey]}
      {` `}({parseHTML(`<div className=${styles.highlight}>${highlightValue}</div>`)})
    </>
  )
}

const Search = () => {
  const { get, response } = useFetch()
  const { t } = useTranslation()

  const [data, setData] = useState<any[]>([])
  const [loading, setLoading] = useState(false)

  const loadData = async (keyword: string) => {
    setLoading(true)
    setData([])

    const responseData = await get(`search?search=${keyword}`)
    setLoading(false)

    if (response.ok) {
      const validData = filter(responseData, (item) => !isEmpty(item.results))
      setData(validData)
    }
  }

  const debounceSearch = useCallback(
    debounce((value) => loadData(value), 200),
    []
  )

  const renderLabel = (label: string) => (
    <span style={{ fontSize: '16px', fontWeight: 'bold', color: colors.black }}>{label}</span>
  )

  const createLabel = (search: ISearchItem) => {
    const { record, displayKey } = search
    if (!(displayKey in record)) return null

    const searchOption = <SearchOption search={search} />

    return {
      label: (
        <div>
          {search.url ? (
            <a href={search.url} className={styles.title_item}>
              {searchOption}
            </a>
          ) : (
            <div className={styles.title_item}>{searchOption}</div>
          )}
        </div>
      ),
    }
  }

  const getGroupOptions = (item: ISearchResult) => {
    if (isEmpty(item.results)) return {}

    let label = ''
    let options = null

    switch (item.index) {
      case 'entities':
        label = 'Entities'
        options = item.results.map((record) =>
          createLabel({
            record,
            displayKey: 'title',
            url: `${getEntityPathById(record.guid)}`,
          })
        )
        break
      case 'tax_organizers':
        label = 'Tax Organizers'
        options = item.results.map((record) =>
          createLabel({
            record: {
              ...record,
              title: `${record.entityTitle} (${record.taxYear})`,
            },
            displayKey: 'title',
            url: `/tax-organizer/${record.guid}`,
          })
        )
        break
      case 'tax_documents':
        label = 'Tax Documents'
        options = item.results.map((record) =>
          createLabel({
            record,
            displayKey: 'name',
          })
        )
        break
      case 'thread_replies':
        label = 'Thread Replies'
        options = item.results.map((record) =>
          createLabel({
            record,
            displayKey: 'message',
          })
        )
        break
      default:
        return {}
    }

    options = filter(options)
    if (isEmpty(options)) return {}

    return { label: renderLabel(label), options }
  }

  const groupOptions = data && data.map((item: ISearchResult) => getGroupOptions(item))

  return (
    <AutoComplete
      dropdownClassName="certain-category-search-dropdown"
      dropdownMatchSelectWidth={500}
      style={{ width: 250 }}
      options={groupOptions}
      onSearch={(value) => debounceSearch(value)}
      notFoundContent={
        <div className={styles.search_spin}>{loading ? <Spin /> : <span>No Results</span>}</div>
      }
    >
      <Input
        placeholder={t('searchPlaceholder')}
        suffix={<SearchOutlined />}
        className={styles.search}
      />
    </AutoComplete>
  )
}

export default Search
