import { useTranslation } from 'react-i18next'
import { getPhaseColorByName } from 'utils'
import { Link, useLocation } from 'react-router-dom'
import { ColumnsType } from 'antd/lib/table'
import { Flex } from 'reflexbox'

import { TTaxOrganizersListItem } from 'context/Dashboard'
import { PhaseName } from 'context/TaxOrganizer'

import { User, Tag } from 'components'

import { ReactComponent as CommentIcon } from 'assets/images/comment.svg'

import colors from 'assets/styles/variables.module.scss'

import styles from '../../dashboard.tax.module.scss'

export default () => {
  const { pathname } = useLocation()
  const { t } = useTranslation('dashboard', { keyPrefix: 'clients.table' })
  const { t: t2 } = useTranslation('common', { keyPrefix: 'phases' })
  const columns: ColumnsType<TTaxOrganizersListItem> = [
    {
      title: t('entity'),
      dataIndex: 'entity',
      key: 'entity__title',
      sorter: true,
      ellipsis: true,
      render: (entity: any, record: TTaxOrganizersListItem) => {
        const { title } = entity
        return (
          <Link
            to={{
              pathname: `/tax-organizer/${record.guid}`,
              state: {
                taxPrevPath: pathname,
              },
            }}
            className="text-black text-bold text-nowrap"
          >
            {title}
          </Link>
        )
      },
    },
    {
      title: t('type'),
      dataIndex: ['entity', 'kind', 'label'],
      key: 'entity__kind',
      sorter: true,
    },
    {
      title: t('year'),
      dataIndex: 'taxYear',
      key: 'tax_year',
      sorter: true,
    },
    {
      title: t('due'),
      dataIndex: ['deadline', 'dueDate'],
      key: 'due_date',
      sorter: true,
      render: (date: string) => <Flex className="text-nowrap">{date}</Flex>,
    },
    {
      title: t('taxPreparer'),
      dataIndex: 'primaryCpaContact',
      key: 'primary_cpa_contact',
      sorter: true,
      render: (primaryCpaContact: { role: ICPAUserRole; user: IContact }) => {
        if (primaryCpaContact) {
          const {
            user: { firstName, lastName, avatar },
          } = primaryCpaContact
          return (
            <User username={`${firstName} ${lastName}`} avatarLink={avatar} bgColor={colors.tag5} />
          )
        }
        return <> </>
      },
    },
    {
      title: t('messages'),
      dataIndex: 'unresolvedMessages',
      key: 'unresolved_messages',
      sorter: true,
      render: (messages: number) => (
        <Flex alignItems={['center']}>
          <CommentIcon className={styles.icon} />
          {messages}
        </Flex>
      ),
    },
    {
      title: t('phase'),
      dataIndex: 'phase',
      key: 'phase',
      sorter: true,
      render: (phase: PhaseName) => <Tag color={getPhaseColorByName(phase)}>{t2(phase)}</Tag>,
    },
  ]

  return { columns }
}
