import { useEffect, useState } from 'react'
import { Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { Box, Flex } from 'reflexbox'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Button } from 'components'

import {
  useEntityDocumentsActions,
  useEntity,
  TMultipleDocumentParams,
  TTaxDocumentExtended,
  TFile,
} from 'context/Entity'

import File from './components/File'
import Title from './components/Title'
import StatusBar from './components/StatusBar'

import styles from './uploadmodal.module.scss'
import './style.scss'
import useUploadDocument from './hooks/useUploadDocument'
import { TFileExtended } from './types'

const { confirm } = Modal

type Props = {
  fileList?: TFile[] | TFileExtended[]
  tqQuestion?: string
  onHide: () => void
  hideFileEditing?: boolean
  hideFilesEditing?: boolean
  setFilesUploaded?: (value: boolean) => void
  onFinishUploading?: () => void
}

const UploadEntityModal = ({
  fileList,
  tqQuestion,
  onHide,
  setFilesUploaded,
  hideFileEditing,
  hideFilesEditing,
  onFinishUploading,
}: Props) => {
  const { t } = useTranslation('common', { keyPrefix: 'upload.modal' })
  const { t: t2 } = useTranslation('common', { keyPrefix: 'switcher' })
  const {
    entityDocuments: { mulptipleDocumentParams, temporaryUploaded },
    entity: { currentEntity },
  } = useEntity()
  const entityGuid: string = String(currentEntity?.guid)
  const { clearMulptipleDocumentParams, clearUploaded } = useEntityDocumentsActions()
  const {
    updateDocuments,
    loading: updateLoading,
    removeMulptipleDocumentParam,
  } = useEntityDocumentsActions()
  const { response: deleteResponse, deleteDocument } = useEntityDocumentsActions()
  const [files, setFiles] = useState(fileList?.slice() || [])
  const { progress, uploadStatus } = useUploadDocument({
    files,
    tqQuestion,
    onFinishUploading,
  })

  const checkValidation = () =>
    !mulptipleDocumentParams.find(
      (param: TMultipleDocumentParams) => param.validateStatus === 'error'
    )

  const handleRemove = async (
    fileUploadedGuid: string | undefined,
    fileUploadingUid: string | undefined = undefined
  ) => {
    if (fileUploadedGuid) {
      await deleteDocument(entityGuid, fileUploadedGuid)
      if (deleteResponse.ok) {
        removeMulptipleDocumentParam(fileUploadedGuid)
        if (fileUploadingUid) {
          setFiles((prevFiles: any) =>
            prevFiles.filter((file: any) => file.uid !== fileUploadingUid)
          )
        }
      }
    }
  }

  const handleSaveAll = async () => {
    if (!mulptipleDocumentParams.length) {
      onHide()
      return
    }
    if (checkValidation()) {
      await updateDocuments(entityGuid, mulptipleDocumentParams)
      clearMulptipleDocumentParams()
      onHide()
    }
  }

  const handleCancell = () => {
    if (progress.isFinished || uploadStatus === 'error') {
      onHide()
      clearUploaded()
      clearMulptipleDocumentParams()
    } else {
      confirm({
        maskClosable: true,
        title: t('interruptionWarning'),
        icon: <ExclamationCircleOutlined />,
        okText: t2('yes'),
        okType: 'danger',
        cancelText: t2('no'),
        onOk: () => {
          onHide()
          clearUploaded()
          clearMulptipleDocumentParams()
        },
      })
    }
  }

  useEffect(() => {
    if (!files?.length) {
      onHide()
    }
    if (setFilesUploaded) {
      setFilesUploaded(!!files?.length)
    }
  }, [files])

  return (
    <Modal
      visible
      title={<Title progress={progress} />}
      className={styles.modal}
      width={1180}
      destroyOnClose
      closable={uploadStatus === 'error'}
      keyboard={false}
      maskClosable={false}
      onCancel={handleCancell}
      footer={[
        <Box mr={[3]} mb={[1]} key="submit-block">
          <Button
            key="submit"
            type="primary"
            ghost
            size="large"
            disabled={!progress.isFinished}
            onClick={handleSaveAll}
            loading={updateLoading}
          >
            {t('done')}
          </Button>
        </Box>,
      ]}
    >
      <StatusBar progress={progress} totalFiles={files?.length ?? 0} />
      {!hideFilesEditing && (
        <Flex flexDirection={['column']} mt={['10px']}>
          {files?.map((file: TFile) => {
            const fileUploaded = temporaryUploaded.find(
              (uploadedFile: TTaxDocumentExtended) => uploadedFile.uid === file.uid
            )
            return (
              <File
                hideFileEditing={hideFileEditing}
                key={file.uid}
                fileUploading={file}
                fileUploaded={fileUploaded}
                onRemove={handleRemove}
                loading={fileUploaded === undefined ? true : fileUploaded.loading}
              />
            )
          })}
        </Flex>
      )}
    </Modal>
  )
}

export default UploadEntityModal
