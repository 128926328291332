import { useTranslation } from 'react-i18next'
import prependHttp from 'prepend-http'
import { parsePhoneNumber } from 'awesome-phonenumber'
import { ColumnsType } from 'antd/lib/table'
import { states } from 'constants/address'

import { PTINType } from 'context/CPAFirm'

export default () => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'clients.table' })

  const columns: ColumnsType<PTINType> = [
    {
      title: <div className="text-bold">{t('firstName')}</div>,
      dataIndex: 'firstName',
      key: 'first_name',
      ellipsis: true,
      sorter: true,
      render: (firstName: string) => {
        return <div>{firstName}</div>
      },
    },
    {
      title: <div className="text-bold">{t('lastName')}</div>,
      dataIndex: 'lastName',
      key: 'last_name',
      sorter: true,
      ellipsis: true,
      render: (lastName: string) => {
        return <div>{lastName}</div>
      },
    },
    {
      title: <div className="text-bold">{t('businessName')}</div>,
      dataIndex: 'businessName',
      key: 'business_name',
      sorter: true,
      ellipsis: true,
      render: (businessName: string) => {
        return <div>{businessName}</div>
      },
    },
    {
      title: <div className="text-bold">{t('city')}</div>,
      dataIndex: 'busAddrCity',
      key: 'bus_addr_city',
      ellipsis: true,
      render: (busAddrCity: string) => {
        return <div>{busAddrCity}</div>
      },
    },
    {
      title: <div className="text-bold">{t('state')}</div>,
      dataIndex: 'busStateCode',
      key: 'bus_state_code',
      ellipsis: true,
      render: (busStateCode: string) => {
        return (
          <div>
            {busStateCode && states.find((state: TOption) => state.value === busStateCode)?.label}
          </div>
        )
      },
    },
    {
      title: <div className="text-bold">{t('phone')}</div>,
      dataIndex: 'busPhoneNumber',
      key: 'bus_phone_number',
      ellipsis: true,
      render: (busPhoneNumber: string, { busCountryCode }: PTINType) => {
        let regionCode = 'US'
        if (busCountryCode && typeof busCountryCode === 'string' && busCountryCode.length === 2) {
          regionCode = busCountryCode
        }
        const phone = parsePhoneNumber(busPhoneNumber, { regionCode })
        return (
          <div>
            {phone.valid && <a href={`tel:${phone?.number?.e164}`}>{phone?.number?.national}</a>}
          </div>
        )
      },
    },
    {
      title: <div className="text-bold">{t('website')}</div>,
      dataIndex: 'website',
      key: 'website',
      ellipsis: true,
      render: (website: string) => {
        return (
          <div>
            {website && (
              <a href={prependHttp(website)} target="_blank" rel="noreferrer">
                {website
                  .replace(/^https?:\/\//i, '')
                  .replace(/\/$/i, '')
                  .replace(/\s/g, '')}
              </a>
            )}
          </div>
        )
      },
    },
  ]

  return { columns }
}
