import { PAGE_SIZE } from 'constants/table'
import { TLoadDocumentsParams } from 'context/TaxOrganizer'
import { TFilters } from '../types'

export const FILTERS = ['category', 'tags'] as const

export const DEFAULT_PARAMS: Readonly<TLoadDocumentsParams> = {
  page: 1,
  pageSize: PAGE_SIZE,
  ordering: undefined,
  search: undefined,
  isVisible: true,
}

export const DEFAULT_FILTERS: Readonly<TFilters> = {}
export const DEFAULT_PARAM_FILTERS: Readonly<TFilters> = {
  tags: undefined,
  category: undefined,
}
