import { Redirect, Route, Switch } from 'react-router-dom'

import UserProfile from './Profile'
import Invitations from './Invitations'
import UserSettings from './Settings'

const UserLayout = () => {
  return (
    <Switch>
      <Route path="/dashboard/user/profile" exact>
        <UserProfile />
      </Route>
      <Route path="/dashboard/user/invitations" exact>
        <Invitations />
      </Route>
      <Route path="/dashboard/user/settings" exact>
        <UserSettings />
      </Route>
      <Route path="/dashboard/user/:slug?">
        <Redirect to="/dashboard" />
      </Route>
    </Switch>
  )
}

export default UserLayout
