import { ActionTypes } from './actions'
import { TEntityInvite, TEntityUserInvite } from './types'

export type IInviteState = {
  cpaEntityInvites: {
    page: number
    count: number
    results: TEntityUserInvite[]
  }
  cpaUserInvites: {
    total: number | undefined
    data: TEntityInvite[]
  }
  entityUserInvites: {
    page: number
    count: number
    results: TEntityUserInvite[]
  }
  entityCpaInvites: {
    page: number
    count: number
    results: TEntityInvite[]
  }
}

export type IAction = { type: keyof typeof ActionTypes; payload?: any }

export const initialState: IInviteState = {
  cpaEntityInvites: {
    page: 0,
    count: 0,
    results: [],
  },
  cpaUserInvites: {
    total: undefined,
    data: [],
  },
  entityCpaInvites: {
    page: 0,
    count: 0,
    results: [],
  },
  entityUserInvites: {
    page: 0,
    count: 0,
    results: [],
  },
}

export const inviteReducer = (state: IInviteState = initialState, action: IAction) => {
  switch (action.type) {
    case ActionTypes.SET_ENTITY_CPA_INVITES:
      return {
        ...state,
        entityCpaInvites: action.payload,
      }
    case ActionTypes.SET_CPA_ENTITY_INVITES:
      return {
        ...state,
        cpaEntityInvites: action.payload,
      }
    case ActionTypes.SET_ENTITY_USER_INVITES:
      return {
        ...state,
        entityUserInvites: action.payload,
      }
    case ActionTypes.SET_CPA_USER_INVITES:
      return {
        ...state,
        cpaUserInvites: {
          data: action.payload.results ?? [],
          total: action.payload.count,
        },
      }
    default:
      return state
  }
}
