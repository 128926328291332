import { useEffect, useRef } from 'react'

import { useTaxOrganizer } from 'context/TaxOrganizer'
import { useQuestions, useQuestionsActions } from 'context/Questions'

export default () => {
  const {
    taxOrganizer: {
      data: {
        guid,
        entity: { kind },
        taxYear,
      },
    },
  } = useTaxOrganizer()
  const { loadTQCategories, loadQuestions, loading: loadingQuestions } = useQuestionsActions()

  const firstRequest = useRef<boolean>(true)
  const {
    state: { categories },
  } = useQuestions()

  useEffect(() => {
    if (guid) {
      loadTQCategories(guid, {
        entityKind: kind.label,
        taxYear,
      })
    }
  }, [guid])

  useEffect(() => {
    if (categories && categories.length && firstRequest.current) {
      firstRequest.current = false
      loadQuestions({
        category: categories[0].guid,
        taxOrganizer: guid,
        entityKind: kind.label,
        taxYear,
      })
      loadQuestions({
        taxOrganizer: guid,
        entityKind: kind.label,
        taxYear,
      })
    }
  }, [categories])

  return { loadingQuestions }
}
