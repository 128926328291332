import cn from 'classnames'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useTaxOrganizer } from 'context/TaxOrganizer'
import useQuestionsHook from './hooks/useQuestionsHook'
import useDocumentsHook from './hooks/useDocumentsHook'

import Questionnaire from '../Questionnaire'
import Documents from '../Documents'
import Tabs from './Tabs'

import './styles.scss'
import styles from './tax.collection.module.scss'

const Collection = () => {
  const {
    taxOrganizer: {
      data: { guid },
    },
  } = useTaxOrganizer()
  useDocumentsHook()
  const { loadingQuestions } = useQuestionsHook()

  return (
    <div className={styles.collection_container}>
      <div className={cn('collection_collapse', styles.collection_sidebar)}>
        <Tabs />
      </div>

      <div className={styles.collection_primary}>
        <Switch>
          <Route path={`/tax-organizer/${guid}/collection/documents`} exact>
            <Documents />
          </Route>
          <Route path={`/tax-organizer/${guid}/collection/questionnaire`} exact>
            <Questionnaire loading={loadingQuestions} />
          </Route>
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </div>
    </div>
  )
}

export default Collection
