import { ActionTypes } from './actions/action-types'
import { TState, TAction } from './types'

export const initialState: TState = {
  count: 0,
  threads: [],
  threadFilter: 'all',
  currentThreadMessages: [],
  currentThreadDocument: undefined!,
  currentThreadIsLocked: false,
  totalUnreadThreads: 0,
  totalUnreadMessages: 0,
}

export const reducer = (state: TState = initialState, action: TAction) => {
  const { payload } = action

  switch (action.type) {
    case ActionTypes.LOAD_MESSAGE_THREADS:
      return {
        ...state,
        count: payload.count,
        threads: payload.results,
        filteredThreads: payload.results,
      }
    case ActionTypes.LOAD_MESSAGE_DETAILS:
      return {
        ...state,
        currentThreadMessages: payload,
      }
    case ActionTypes.LOAD_MESSAGE_DOCUMENT:
      return {
        ...state,
        currentThreadDocument: payload,
      }
    case ActionTypes.ADD_MESSAGE:
      return {
        ...state,
        currentThreadMessages: [...state.currentThreadMessages, payload],
      }
    case ActionTypes.LOAD_UNREAD_MESSAGES:
      return {
        ...state,
        totalUnreadThreads: payload.totalUnreadThreads ?? 0,
        totalUnreadMessages: payload.totalUnreadMessages ?? 0,
      }
    case ActionTypes.FILTER_THREADS:
      return {
        ...state,
        threadFilter: payload,
      }
    case ActionTypes.ADD_NEW_MESSAGE:
      state.threads.shift()
      return {
        ...state,
        currentThreadMessages: [],
        currentThreadDocument: undefined!,
        threads: [payload, ...state.threads],
      }
    case ActionTypes.ADD_THREAD:
      return {
        ...state,
        currentThreadMessages: [],
        currentThreadDocument: undefined!,
        threads: [
          {
            guid: '0',
            unreadMessageCount: 0,
            lastReplyOn: new Date().toISOString(),
            isResolved: false,
          },
          ...state.threads,
        ],
      }
    case ActionTypes.CLEAR_MESSAGE_DETAILS:
      return {
        ...state,
        currentThreadMessages: [],
        currentThreadDocument: undefined!,
      }
    case ActionTypes.LOAD_THREAD:
      return {
        ...state,
        threads: state.threads.map((item) => {
          if (item.guid === payload.guid) {
            return {
              ...payload,
            }
          }
          return {
            ...item,
          }
        }),
      }
    case ActionTypes.SET_CURRENT_THREAD_STATUS:
      return {
        ...state,
        currentThreadIsLocked: payload,
      }

    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}
