import { useState } from 'react'
import { Form, Input, notification } from 'antd'
import { useTranslation } from 'react-i18next'

import { useAuthActions, ChangePassword } from 'context/Auth'

import { Button } from 'components'

import styles from '../../user.profile.module.scss'

const ProfilePassword = () => {
  const { loading, response, changePassword } = useAuthActions()
  const [errors, setErrors] = useState<Partial<ChangePassword>>({})
  const [form] = Form.useForm()
  const { t } = useTranslation('dashboard', { keyPrefix: 'user.password' })
  const { t: t2 } = useTranslation('common', { keyPrefix: 'form' })
  const required = {
    required: true,
    message: t2('required'),
  }

  const onSubmit = async ({ oldPassword, newPassword }: ChangePassword) => {
    const result = await changePassword({ oldPassword, newPassword })
    if (response.ok) {
      setErrors({})
      form.resetFields()
      notification.success({
        message: t('notification.message'),
        description: t('notification.description'),
      })
    } else {
      setErrors(result)
    }
  }

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <div className={styles.title} style={{ marginBottom: 16 }}>
        {t('title')}
      </div>
      <Form.Item
        name="oldPassword"
        label={t('oldPassword')}
        rules={[required]}
        validateStatus={errors?.oldPassword && 'error'}
        help={errors?.oldPassword}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="newPassword"
        label={t('newPassword')}
        rules={[required]}
        validateStatus={errors?.newPassword && 'error'}
        help={errors?.newPassword}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="repeatNewPassword"
        label={t('repeatNewPassword')}
        rules={[
          required,
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve()
              }
              return Promise.reject(new Error(t('passwordMatch')))
            },
          }),
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>
      <Button
        htmlType="submit"
        type="primary"
        size="large"
        className={styles.submit}
        loading={loading}
        disabled={loading}
      >
        {t('submit')}
      </Button>
    </Form>
  )
}

export default ProfilePassword
