import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { Image } from 'antd'

import { TDocumentPageIdentifier, TTaxReturnMenuCategory } from 'context/TaxReturns'

import { SpinnerBackdrop } from 'components'

import styles from '../../../dashboard.tax_return.module.scss'

type TProps = {
  selectedPage: TDocumentPageIdentifier | null
  setSelectedPage: (page: TDocumentPageIdentifier) => void
  category: TTaxReturnMenuCategory
}

const Previews = ({ selectedPage, setSelectedPage, category }: TProps) => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'taxReturns.review' })

  if (!category.pages) return null

  return (
    <div className={styles.tax_return_file_list}>
      {category.pages.map((page, index) => (
        <div
          onClick={() => {
            setSelectedPage({
              pageId: page.pageId,
              documentGuid: page.documentGuid,
              uiIndex: page.uiIndex,
              guid: page.guid,
            })
          }}
          key={`${page.documentGuid}-${page.pageId}`}
          className={styles.file_preview}
        >
          <Image
            className={cn(
              styles.thumbnail,
              (selectedPage?.uiIndex === 0 && page.uiIndex === 1) ||
                selectedPage?.uiIndex === page.uiIndex
                ? styles.active_thumbnail
                : ''
            )}
            alt={`Thumbnail preview ${index}`}
            src={page.thumbnail}
            preview={false}
            placeholder={<SpinnerBackdrop mode="local" transparent={false} />}
          />
          <div>
            {t('page')} {page.uiIndex}
          </div>
        </div>
      ))}
    </div>
  )
}

export default Previews
