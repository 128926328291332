import { useFetch, CachePolicies } from 'use-http'

import { ExternalApplication } from '.'

const useSwitchApplicationActions = () => {
  const { get, response, loading } = useFetch(process.env.REACT_APP_API_URL, {
    cachePolicy: CachePolicies.NO_CACHE,
    credentials: 'include',
  })

  const switchApplication = async (app: ExternalApplication) => {
    const res: { url: string } = await get(`/auth/switch-application?app=${app}`)
    window.location.href = res.url
  }

  return {
    switchApplication,
    loading,
    response,
  }
}

export default useSwitchApplicationActions
