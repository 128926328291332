import { useTranslation } from 'react-i18next'

import styles from 'pages/Dashboard/Firm/Team/dashboard.firm.module.scss'
import Form from '../Form'

type TProps = {
  setVisiblePopover: (visible: boolean) => void
}

const InviteMembers = ({ setVisiblePopover }: TProps) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'firm.team' })

  return (
    <>
      <div className={styles.invite_title}>{t('inviteMember')}</div>
      <div className={styles.invite_description}>{t('description')}</div>
      <Form setVisiblePopover={setVisiblePopover} />
    </>
  )
}

export default InviteMembers
