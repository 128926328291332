import { useState } from 'react'
import { FormInstance } from 'antd'
import { Flex } from 'reflexbox'
import { useTranslation } from 'react-i18next'
import { NavLink, useRouteMatch, useHistory } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import cn from 'classnames'

import { formatApiErrorResponse } from 'utils'
import { getEntityPathById } from 'utils/routes'

import { EntityEditForm, useEntityActions } from 'context/Entity'
import { useAuthActions } from 'context/Auth'
import { ReactComponent as Plus } from 'assets/images/plus-white.svg'
import Icon from '@ant-design/icons'

import useDate from 'hooks/useDate'

import { Button, Modal } from 'components'
import EntityDetailsForm from '../../../EntityManagement/Overview/components/Details/components/EntityDetailsForm'

import styles from '../../dashboard.clients.module.scss'

const Header = () => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'clients.head' })
  const { t: t2 } = useTranslation('common')
  const isMobile = useMediaQuery({ query: '(max-width: 40em)' })
  const {
    params: { slug },
  } = useRouteMatch<any>()
  const [createEntityVisible, setCreateEntityVisible] = useState(false)
  const { createEntity, loading } = useEntityActions()
  const { setEntityStatus, response } = useAuthActions()
  const history = useHistory()
  const { getDate } = useDate()

  const onCreateEntity = async (payload: EntityEditForm, form: FormInstance) => {
    const parsedForm = {
      ...payload,
      businessStartedDate: payload.businessStartedDate
        ? getDate(payload.businessStartedDate, 'yyyy-MM-dd')
        : null,
    }

    const createdEntityResponse = await createEntity(parsedForm)
    if (!createdEntityResponse?.guid) {
      const errors = formatApiErrorResponse(createdEntityResponse)
      // @ts-ignore
      form.setFields(errors)
    } else {
      setCreateEntityVisible(false)
      if (createdEntityResponse?.kind.label === 'INDV') {
        const invitations = await setEntityStatus()
        if (response.ok) {
          const pendingInvitations = invitations.results.filter(
            ({ status }: { status: string }) => status === 'pen'
          ).length
          if (pendingInvitations) {
            history.push('/dashboard/user/invitations')
          } else {
            history.push(getEntityPathById(createdEntityResponse?.guid))
          }
        }
      } else {
        history.push(getEntityPathById(createdEntityResponse?.guid))
      }
    }
  }

  return (
    <>
      <Flex flexWrap="wrap" justifyContent="space-between">
        <Flex className={styles.nav} width={['100%', '70%', '60%']}>
          <NavLink
            to="/dashboard/entities"
            exact
            className={cn(!slug && styles.nav_is_active)}
            activeClassName={styles.nav_is_active}
          >
            {t('entityList')}
          </NavLink>
        </Flex>
        <Flex
          justifyContent={['flex-start', 'flex-end']}
          width={['100%', 'auto', 'auto']}
          mt={[3, 0]}
        >
          <Modal
            placement={isMobile ? 'bottom' : 'leftBottom'}
            content={
              <EntityDetailsForm
                onSubmit={onCreateEntity}
                action={t2('buttons.add')}
                upsertLoading={loading}
              />
            }
            trigger="click"
            destroyTooltipOnHide
            visible={createEntityVisible}
            onVisibleChange={(visible) => setCreateEntityVisible(visible)}
          >
            <Button type="primary" size="large" block>
              <Icon component={Plus} style={{ fontSize: 18 }} /> {t2('buttons.add')} {t2('entity')}
            </Button>
          </Modal>
        </Flex>
      </Flex>
    </>
  )
}

export default Header
