import { FC, Dispatch, createContext, useReducer, useContext } from 'react'
import { useReducerLogger } from 'utils'

import { initialState, firmReducer } from './reducer'
import { IAction, IFirmState } from './types'

const FirmContext = createContext<IFirmState>({} as IFirmState)
const FirmDispatchContext = createContext<Dispatch<IAction>>(() => null)

export function useCPAFirm() {
  const context = useContext(FirmContext)
  if (context === undefined) {
    throw new Error('useCPAFirm must be used within a CPAFirmProvider')
  }
  return context
}

export function useCPAFirmDispatch() {
  const context = useContext(FirmDispatchContext)
  if (context === undefined) {
    throw new Error('useCPAFirmDispatch must be used within a CPAFirmProvider')
  }
  return context
}

export const CPAFirmProvider: FC = ({ children }) => {
  const [data, dispatch] = useReducer(
    useReducerLogger<IFirmState, IAction>(firmReducer),
    initialState
  )

  return (
    <FirmContext.Provider value={data}>
      <FirmDispatchContext.Provider value={dispatch}>{children}</FirmDispatchContext.Provider>
    </FirmContext.Provider>
  )
}
