import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { Alert } from 'components'

import { useEntityDocumentsActions, useEntity } from 'context/Entity'

import styles from '../../../dashboard.entity.management.module.scss'

type TProps = {
  setShowCategorizeModal: (value: boolean) => void
}

const AlertCategorize = ({ setShowCategorizeModal }: TProps) => {
  const { t } = useTranslation('common', { keyPrefix: 'upload.modal' })
  const {
    entityDocuments: { documentsSummary },
    entity: {
      currentEntity: { guid: entityGuid },
    },
  } = useEntity()
  const { loadDocuments, response, loading } = useEntityDocumentsActions()

  const onShow = async () => {
    await loadDocuments(entityGuid, {
      pageSize: documentsSummary?.requiresAction,
      requiresAction: true,
    })
    if (response.ok) {
      setShowCategorizeModal(true)
    }
  }

  return (
    <>
      {documentsSummary?.requiresAction > 0 && (
        <Alert
          className={styles.alert_documents}
          message={
            <div className={styles.wrapper}>
              <div className={styles.content_alert}>
                <div>
                  <div className={styles.title}>
                    {documentsSummary?.requiresAction === 1
                      ? `1 ${t('fileBeenUploaded')}`
                      : `${documentsSummary?.requiresAction} ${t('filesBeenUploaded')}`}
                  </div>

                  <div>{t('reviewEndCategorize')}</div>
                </div>

                <div>
                  <Button
                    style={{ width: '100px' }}
                    type="primary"
                    size="small"
                    onClick={onShow}
                    disabled={loading}
                  >
                    {t('categorize')}
                  </Button>
                </div>
              </div>
            </div>
          }
          type="info"
        />
      )}
    </>
  )
}

export default AlertCategorize
