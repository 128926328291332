import { Box, Flex } from 'reflexbox'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'
import { useBilling } from 'context/Billing'

import { Button } from 'components'
import styles from '../../dashboard.firm.billing.module.scss'

const Plan = () => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'firm.billing' })
  const isMobile = useMediaQuery({ query: '(max-width: 40em)' })
  const {
    state: { subscription },
  } = useBilling()

  return (
    <Flex flexDirection={['column']} pb={['32px']} className={styles.block}>
      <Flex justifyContent={['space-between']} alignItems={['center']}>
        <Box lineHeight={['24px']} fontSize={['20px']} fontWeight={[700]}>
          {t('plan')}
        </Box>
        <Box>
          <Button
            type="primary"
            ghost
            size={isMobile ? 'middle' : 'large'}
            disabled={true || !subscription?.active}
          >
            {t('cancel')}
          </Button>{' '}
        </Box>
      </Flex>
      <Box lineHeight={['23px']} fontSize={['16px']} mt={['10px']}>
        {subscription?.description}
      </Box>
    </Flex>
  )
}

export default Plan
