import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { Box } from 'reflexbox'
import { ColumnsType } from 'antd/lib/table'

import { TTaxOrganizersListItem } from 'context/Dashboard'

import { User } from 'components'

import { ReactComponent as CommentIcon } from 'assets/images/comment.svg'

import colors from 'assets/styles/variables.module.scss'

export default () => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'clients.table' })
  const { pathname } = useLocation()
  const columns: ColumnsType<TTaxOrganizersListItem> = [
    {
      title: <Box fontWeight="bold">{t('name')}</Box>,
      dataIndex: 'entity',
      key: 'entity',
      ellipsis: true,
      sorter: (a: TTaxOrganizersListItem, b: TTaxOrganizersListItem) =>
        a.entity.title.localeCompare(b.entity.title),
      sortDirections: ['ascend', 'descend'],
      render: (entity: any, record: TTaxOrganizersListItem) => {
        const { title } = entity
        return (
          <Link
            to={{
              pathname: `/tax-organizer/${record.guid}`,
              state: {
                taxPrevPath: pathname,
              },
            }}
            className="text-black"
          >
            <Box fontWeight="bold">{title}</Box>
          </Link>
        )
      },
    },
    {
      title: t('assignedTaxPreparer'),
      dataIndex: 'primaryCpaContact',
      key: 'primaryCpaContact',
      render: (primaryCpaContact: any) => {
        if (primaryCpaContact) {
          const {
            user: { firstName, lastName },
          } = primaryCpaContact
          return <User username={`${firstName} ${lastName}`} bgColor={colors.tag5} />
        }
        return <> </>
      },
    },
    {
      title: t('messages'),
      dataIndex: 'unresolvedMessages',
      key: 'unresolvedMessages',
      render: (messages: number) =>
        messages > 0 && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CommentIcon
              style={{ width: 16, height: 16, marginRight: 8, color: colors.outlines }}
            />
            {messages}
          </div>
        ),
    },
  ]

  return { columns }
}
