import { Box } from 'reflexbox'
import { useDrop } from 'react-dnd'

import { Button } from 'components'

import { TChildCategories } from 'context/TaxReturns'

import colors from 'assets/styles/variables.module.scss'

type TProps = {
  slagCategory: string
  subCategory: TChildCategories
  state: string | null
}
const SubCategory = ({ slagCategory, subCategory, state }: TProps) => {
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: 'categorize',
      drop: () => ({
        parentCategory: slagCategory,
        childCategory: subCategory.slug,
        state,
      }),
      collect: (monitor: any) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [subCategory]
  )

  const isActive = canDrop && isOver

  return (
    <Box ref={drop} ml={[0, 0, 3]} mr={[2, 2, 0]} mt={[2, 0]}>
      <Button
        type="dashed"
        style={{
          opacity: canDrop ? '1' : '0',
          background: isActive ? colors.primaryHover : 'transparent',
          color: isActive ? 'white' : '',
          border: isActive ? `1px solid ${colors.primaryHover}` : '',
        }}
        size="large"
      >
        {subCategory.label}
      </Button>
    </Box>
  )
}

export default SubCategory
