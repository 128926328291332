import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { Form } from 'antd'
import { CloseOutlined, LoadingOutlined, SearchOutlined } from '@ant-design/icons'
import TextField from 'components/TextField'
import { debounce } from 'lodash'
import { useTranslation } from 'react-i18next'

type PropsType = {
  loading?: boolean
  searchHandler: (search: string | undefined) => void
  placeholder?: string
  clear?: boolean
  callback?: (value: string | undefined, handlerType: 'clear' | 'search') => void
}

const Search = ({ loading, searchHandler, placeholder, clear, callback }: PropsType) => {
  const [focus, setFocus] = useState(false)
  const [form] = Form.useForm()
  const [value, setValue] = useState<string | undefined>()
  const { t } = useTranslation('common', { keyPrefix: 'buttons' })
  const debouncedTextChangeHandler = useMemo(() => debounce(searchHandler, 300), [])

  const handleClear = () => {
    debouncedTextChangeHandler(undefined)
    setValue(undefined)
    form.setFieldsValue({ search: undefined })
    if (callback && typeof callback === 'function') {
      callback(undefined, 'clear')
    }
  }

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const v = event.target.value ? event.target.value.trim() : undefined
    setValue(v)
    debouncedTextChangeHandler(v)
    if (callback && typeof callback === 'function') {
      callback(v, 'search')
    }
  }

  useEffect(() => {
    return () => {
      debouncedTextChangeHandler.cancel()
    }
  }, [])

  return (
    <Form form={form}>
      <Form.Item name="search" key="seacrh">
        <TextField
          placeholder={placeholder || t('search')}
          size="middle"
          suffix={
            focus && loading ? (
              <LoadingOutlined />
            ) : clear && value ? (
              <CloseOutlined onClick={handleClear} />
            ) : (
              <SearchOutlined />
            )
          }
          onChange={handleSearch}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
      </Form.Item>
    </Form>
  )
}

export default Search
