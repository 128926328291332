import { map, sum } from 'lodash'
import { TQCategoryType, TQQuestionType } from '.'
import { ActionTypes } from './action-types'

export type State = {
  questions: TQQuestionType[]
  categories: TQCategoryType[]
  questionsCount: {
    total: number
    unanswered: number
  }
}

export const initialState: State = {
  questions: [],
  categories: [],
  questionsCount: {
    total: 0,
    unanswered: 0,
  },
}

export const reducer = (state: State = initialState, action: { type: string; payload?: any }) => {
  const { payload } = action

  switch (action.type) {
    case ActionTypes.LOAD_TQ_CATEGORIES:
      return {
        ...state,
        categories: payload,
        questionsCount: {
          ...state.questionsCount,
          unanswered: sum(map(payload ?? [], 'unansweredQuestions')),
        },
      }

    case ActionTypes.LOAD_QUESTIONS_BY_CATEGORY:
      return {
        ...state,
        questions: payload,
      }

    case ActionTypes.LOAD_QUESTIONS:
      return {
        ...state,
        questionsCount: {
          ...state.questionsCount,
          total: (payload ?? []).length,
        },
      }

    case ActionTypes.UPDATE_QUESTION_VALUE: {
      return {
        ...state,
        questions: state.questions.map((question) => {
          if (payload.questionGuid === question.guid) {
            const response = question.response ?? {}
            return {
              ...question,
              response: {
                ...response,
                value: payload.value,
              },
            }
          }
          return question
        }),
      }
    }

    case ActionTypes.UPDATE_QUESTION_TEXT: {
      return {
        ...state,
        questions: state.questions.map((question) => {
          if (payload.questionGuid === question.guid) {
            const response = question.response ?? {}
            return {
              ...question,
              response: {
                ...response,
                response: payload.text,
              },
            }
          }
          return question
        }),
      }
    }

    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}
