import { useEffect } from 'react'
import { Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useDashboard } from 'context/Dashboard'
import { useEntity, useEntityDocumentsActions } from 'context/Entity'

import { Select } from 'components'

import styles from '../../dashboard.document.module.scss'

type TProps = {
  disabled: boolean
}

const Category = ({ disabled }: TProps) => {
  const { documentGuid }: { id: string; documentGuid: string } = useParams()
  const { categories, subCategories } = useDashboard()
  const { updateDocument, response } = useEntityDocumentsActions()
  const {
    entity: {
      currentEntity: { guid: id },
      currentEntityDocument: document,
    },
  } = useEntity()
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'document' })
  const [form] = Form.useForm()
  const subCategoriesOptions = subCategories.filter(
    (sub) => sub.parent.slug === form.getFieldValue('category')
  )

  const getInitialCategory = () => {
    const subCategory = subCategories.find(({ value }) => String(value) === document!.category)
    return subCategory ? subCategory.parent.slug : document!.category
  }

  useEffect(() => {
    if (categories.length && subCategories.length) {
      const category = getInitialCategory()
      form.setFieldsValue({
        category,
        subCategory: subCategories.filter((cat) => cat.value === document!.category)[0]?.value,
      })
    }
  }, [categories, subCategories])

  const handleValuesChange = async ({ category, subCategory }: any) => {
    await updateDocument(id, documentGuid, {
      category: subCategory || category,
    })
    if (response.ok) {
      if (category) {
        form.setFieldsValue({ subCategory: undefined })
      }
    }
  }

  return (
    <div className={styles.right_section}>
      <div className={styles.right_section_top}>
        <span className={styles.right_section_title}>{t('category')}</span>
      </div>
      <div className={styles.lis_comment_wrapper}>
        <Form
          onValuesChange={handleValuesChange}
          initialValues={{ category: getInitialCategory() }}
          form={form}
        >
          {() => (
            <>
              <Form.Item name="category">
                <Select options={categories} style={{ width: '100%' }} disabled={disabled} />
              </Form.Item>
              <Form.Item name="subCategory">
                <Select
                  options={subCategoriesOptions}
                  style={{ width: '100%' }}
                  disabled={disabled || subCategoriesOptions.length === 0}
                />
              </Form.Item>
            </>
          )}
        </Form>
      </div>
    </div>
  )
}

export default Category
