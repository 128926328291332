import { TTaxOrganizersParams, TTaxSummaryParams } from 'context/Dashboard'

export const PAGE_SIZE = 5

export const DEFAULT_TAX_PARAMS: Pick<
  TTaxOrganizersParams,
  'page' | 'pageSize' | 'taxYear' | 'primaryCpaContact' | 'phase' | 'deadline'
> = {
  page: 1,
  pageSize: PAGE_SIZE,
  taxYear: undefined,
  primaryCpaContact: undefined,
  phase: undefined,
  deadline: undefined,
}

export const DEFAULT_SUMMARY_PARAMS: Pick<
  TTaxSummaryParams,
  'deadline' | 'сpaContact' | 'taxYear'
> = {
  deadline: undefined,
  сpaContact: undefined,
  taxYear: undefined,
}

export const DEFAULT_TAX_DEADLINES_PARAMS: TParams = { ordering: 'due_date' }
