import { ActionTypes } from './actions'
import { IAction, IFirmState } from './types'

export const initialState: IFirmState = {
  members: {
    data: [],
    total: 0,
  },
  invitations: {
    data: [],
    total: 0,
  },
  tradeShow: undefined,
  jobTitles: [],
  ptins: {
    data: [],
    total: 0,
  },
  cpaUserRoles: [],
}

export const firmReducer = (state: IFirmState = initialState, action: IAction) => {
  const { payload } = action

  switch (action.type) {
    case ActionTypes.LOAD_FIRM:
      return {
        ...state,
        firm: payload,
      }

    case ActionTypes.LOAD_MEMBERS:
      return {
        ...state,
        members: {
          data: payload.results,
          total: payload.count,
        },
      }

    case ActionTypes.LOAD_FIRM_INVITES: {
      const { data, total } = payload
      return {
        ...state,
        invitations: {
          data,
          total,
        },
      }
    }

    case ActionTypes.LOAD_TRADE_SHOW: {
      return {
        ...state,
        tradeShow: payload,
      }
    }

    case ActionTypes.LOAD_CPA_JOB_TITLES: {
      return {
        ...state,
        jobTitles: payload,
      }
    }

    case ActionTypes.LOAD_PTIN: {
      return {
        ...state,
        ptins: {
          data: payload?.results ?? [],
          total: payload?.count ?? 0,
        },
      }
    }

    case ActionTypes.CLEAR_PTIN: {
      return {
        ...state,
        ptins: {
          data: [],
          total: 0,
        },
      }
    }

    case ActionTypes.LOAD_CPA_FIRM_USER_ROLES: {
      const roles = payload?.[0]
      return {
        ...state,
        cpaUserRoles: roles ? roles.roles : [],
      }
    }

    default:
      return state
  }
}
