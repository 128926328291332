import { Modal, Tag } from 'components'
import { getTagColorByName } from 'utils'
import { Typography } from 'antd'
import { Link } from 'react-router-dom'
import { getEntityPathById } from 'utils/routes'

const { Paragraph } = Typography

export const Tags = ({ tags }: { tags: TOption[] }) => (
  <div>
    {tags.map((tag) => {
      return (
        <Tag key={tag.value} style={getTagColorByName(tag.label)}>
          {tag.label}
        </Tag>
      )
    })}
  </div>
)

type TProps = {
  name: string
  guid: string
  showTooltip: boolean
  entityGuid: string
}

export const DocName = ({ name, guid, showTooltip, entityGuid }: TProps) => {
  return (
    <Modal content={name} placement="top" overlayClassName={!showTooltip ? 'd-none' : ''}>
      <Paragraph
        ellipsis={{
          rows: 1,
          expandable: false,
          symbol: '>>',
        }}
        style={{ marginBottom: 0, width: '150px' }}
      >
        <Link to={`${getEntityPathById(entityGuid)}/documents/${guid}`}>{name}</Link>
      </Paragraph>
    </Modal>
  )
}
