import { EnumPhaseTypes } from 'context/TaxOrganizer'
import { TFunction } from 'react-i18next'

export const TAX_ORGANIZER_PHASES_ORDER = [
  EnumPhaseTypes.COLLECTING,
  EnumPhaseTypes.PREPARING,
  EnumPhaseTypes.REVIEWING,
  EnumPhaseTypes.FILING,
  EnumPhaseTypes.FILED,
]

export const getTaxOrganizerPhaseNumber = (phase: EnumPhaseTypes) => {
  return TAX_ORGANIZER_PHASES_ORDER.indexOf(phase)
}

export const NUMBER_OF_PHASES_IN_PROGRESS = getTaxOrganizerPhaseNumber(EnumPhaseTypes.FILED)

export const isTaxOrganizerInProgress = (phase: EnumPhaseTypes) => {
  return getTaxOrganizerPhaseNumber(phase) < NUMBER_OF_PHASES_IN_PROGRESS
}

export const ALLOWED_DOCUMENT_FILE_TYPES = [
  'image/*',
  '.pdf',
  '.PDF',
  '.doc',
  '.DOC',
  '.docx',
  '.DOCX',
  '.xls',
  '.XLS',
  '.xlsx',
  '.XLSX',
  '.QBW',
  '.QBM',
  '.QBB',
  '.TXT',
  '.txt',
  '.CSV',
  '.csv',
]

export const getPhaseSubmitLabel = (phase: EnumPhaseTypes, t: TFunction<'taxOrganizer'>) => {
  return t(
    `overview.phaseSubmitLabel.${phase.toLowerCase()}`,
    t('overview.phaseSubmitLabel.default')
  )
}

export const IsValidEmail = (email: string) => {
  const pattern = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,10})$/

  return pattern.test(email)
}

export const defaultYear = () => {
  return String(new Date().getFullYear() - 1)
}
