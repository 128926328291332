import { Box, Flex } from 'reflexbox'

import { useTranslation } from 'react-i18next'

const Title = () => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'sidebar.title' })
  return (
    <Flex
      justifyContent={['space-between']}
      alignItems={['center']}
      pl={[3]}
      pr={[3]}
      pt={['20px']}
    >
      <Box fontSize="20px" fontWeight="bold">
        {t('messages')}
      </Box>
    </Flex>
  )
}

export default Title
