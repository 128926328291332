import { MouseEvent, useEffect, useState } from 'react'
import { closest } from 'utils'
import cn from 'classnames'

import { useTaxOrganizer } from 'context/TaxOrganizer'
import {
  TDocumentPageIdentifier,
  TTaxReturn,
  useTaxReturns,
  useTaxReturnsActions,
} from 'context/TaxReturns'
import { ThreadContextType, useDocumentActions } from 'context/Document'

import usePrintPDF from 'hooks/usePrintPDF'

import { SpinnerBackdrop } from 'components'
import Header from 'pages/TaxOrganizer/Document/components/DocumentView/components/Header'
import PDFdocument from 'pages/TaxOrganizer/Document/components/DocumentView/components/PDFDocument'
import Threads from 'pages/TaxOrganizer/Document/components/DocumentView/components/Threads'
import CommentNavigator from './CommentNavigator'

import styles from '../../../dashboard.tax_return.module.scss'

import useCreatePDFPreview from '../hooks/useCreatePDFPreview'

type TProps = {
  commentsEnabled: boolean
  selectedPage: TDocumentPageIdentifier
  setSelectedPage: (t: TDocumentPageIdentifier) => void
  taxReturn: TTaxReturn
}

const DocumentPreview = ({ selectedPage, setSelectedPage, commentsEnabled, taxReturn }: TProps) => {
  const {
    state: { filteredTaxReturnPages, threads },
  } = useTaxReturns()
  const {
    taxOrganizer: {
      data: { guid },
    },
  } = useTaxOrganizer()
  const { setNewThread } = useDocumentActions()
  const { loadThreads } = useTaxReturnsActions()
  const [currentThread, setCurrentThread] = useState<string | undefined>(undefined)
  const [reloadThreads, setReloadThreads] = useState(false)
  const {
    height,
    documentRef,
    documentWrapperHeight,
    setDocumentWrapperHeight,
    pdfError,
    setPDFError,
    documentLoaded,
    setDocumentLoaded,
    activePage,
    setActivePage,
    scale,
    setScale,
    printPluginInstance,
    pageNavigationPluginInstance,
    jumpToPage,
  } = usePrintPDF()
  const { fileUrl, creatingPDF } = useCreatePDFPreview()

  const pageThreads = threads?.results?.filter(
    (thread) =>
      thread[ThreadContextType.TAX_RETURN_CONTEXT]?.taxReturnPage?.guid === selectedPage.guid
  )

  const getThreads = () => {
    loadThreads({
      taxReturn: taxReturn.guid,
    })
  }

  const handleDocumentClick = (e: MouseEvent<HTMLDivElement>) => {
    const { current } = documentRef
    if (current && commentsEnabled && closest(e.target, (el: any) => el.isEqualNode(current))) {
      const rect = current.getBoundingClientRect()
      const x = ((e.clientX - rect.left) / rect.width) * 100
      const y = ((e.clientY - rect.top) / rect.height) * 100
      setNewThread({ x, y, page: Number(selectedPage.pageId) })
    }
  }

  useEffect(() => {
    getThreads()
  }, [reloadThreads])

  useEffect(() => {
    const page = filteredTaxReturnPages[activePage - 1]
    setSelectedPage({
      documentGuid: page.documentGuid,
      pageId: Number(page.pageId),
      uiIndex: activePage,
      guid: page.guid,
    })
  }, [activePage])

  useEffect(() => {
    setDocumentWrapperHeight(document.querySelector('.rpv-core__canvas-layer')?.clientHeight)
    if (selectedPage?.uiIndex) {
      jumpToPage(selectedPage.uiIndex - 1)
    }
  }, [selectedPage, scale, height])

  if (creatingPDF) return <SpinnerBackdrop />

  return (
    <div className={styles.tax_return_document_preview}>
      <Header
        activePage={selectedPage.uiIndex || 1}
        total={filteredTaxReturnPages?.length}
        setActivePage={setActivePage}
        pdfError={pdfError}
        documentLoaded={documentLoaded}
        printPluginInstance={printPluginInstance}
        downloadLink={fileUrl}
        fileIsImage={false}
        fileUrl={null}
      />
      <div
        ref={documentRef}
        onClick={handleDocumentClick}
        style={{ position: 'relative' }}
        className={cn({
          [styles.tax_return_document_preview_comment]: commentsEnabled,
        })}
      >
        {fileUrl && (
          <PDFdocument
            fileUrl={fileUrl}
            printPluginInstance={printPluginInstance}
            setPDFLoaded={setDocumentLoaded}
            pageNavigationPluginInstance={pageNavigationPluginInstance}
            setPDFError={setPDFError}
            setScale={setScale}
            documentWrapperHeight={documentWrapperHeight}
          />
        )}
        {commentsEnabled && (
          <Threads
            currentThreadReferenceNumber={currentThread}
            taxReturnId={taxReturn.guid}
            taxReturnPageId={selectedPage.guid}
            contextKey={ThreadContextType.TAX_RETURN_CONTEXT}
            taxOrganizerId={guid}
            documentGuid={selectedPage.documentGuid}
            threads={pageThreads}
            setReloadThreads={setReloadThreads}
          />
        )}
      </div>
      {commentsEnabled && (
        <CommentNavigator
          currentThread={currentThread}
          setCurrentThread={setCurrentThread}
          setSelectedPage={setSelectedPage}
          taxReturn={taxReturn}
        />
      )}
    </div>
  )
}

export default DocumentPreview
