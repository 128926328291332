import { useTranslation } from 'react-i18next'
import { Box } from 'reflexbox'
import { ColumnsType } from 'antd/lib/table'
import { clientsLimitExceeded } from 'utils/http'

import { TEntityInvite, TEntityUserInvite } from 'context/Invites/types'
import { useInviteActions } from 'context/Invites'
import { User } from 'components'

import colors from 'assets/styles/variables.module.scss'

import { message } from 'antd'
import ActionsCell from '../../ActionsCell'

type TProps = {
  setBillingModalVisible: (state: boolean) => void
}

export default ({ setBillingModalVisible }: TProps) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'clients.table' })
  const { t: t2 } = useTranslation('common', { keyPrefix: 'invitations.invitationStatus' })
  const { acceptCPAEntityInvitation, response: responseAccept } = useInviteActions()
  const { declineCPAEntityInvitation, response: responseDecline } = useInviteActions()

  const columns: ColumnsType<TEntityUserInvite> = [
    {
      title: <Box fontWeight="bold">{t('inviteBy')}</Box>,
      dataIndex: ['entity', 'title'],
      key: 'entity',
      ellipsis: true,
      render: (title: string) => (
        <Box fontWeight="bold" className="text-black">
          {title}
        </Box>
      ),
    },
    {
      title: t('tin'),
      dataIndex: ['entity', 'tin'],
      key: 'tin',
      render: (tin) => <div style={{ whiteSpace: 'nowrap' }}>{tin}</div>,
    },
    {
      title: t('inviteBy'),
      dataIndex: ['invitedBy'],
      key: 'inviteBy',
      render: (cpaContact: IContact) => {
        if (!cpaContact) return null
        const { firstName, lastName, avatar } = cpaContact
        return (
          <User username={`${firstName} ${lastName}`} bgColor={colors.tag5} avatarLink={avatar} />
        )
      },
    },
    {
      title: t('actions'),
      key: 'actions',
      render: (invitation: TEntityInvite) => (
        <ActionsCell
          acceptAction={async () => {
            await acceptCPAEntityInvitation(invitation.entity.guid, invitation.guid)
            if (responseAccept.ok) {
              message.success(t2('acc'))
            } else if (clientsLimitExceeded(responseAccept.status, responseAccept.data)) {
              setBillingModalVisible(true)
            }
          }}
          declineAction={async () => {
            await declineCPAEntityInvitation(invitation.entity.guid, invitation.guid)
            if (responseDecline.ok) {
              message.success(t2('dec'))
            }
          }}
        />
      ),
    },
  ]

  return { columns }
}
