import { Button, Form, message, Select, Typography } from 'antd'
import { getFirstError } from 'utils'
import { useState } from 'react'
import { ErrorResponse, useEntity, useEntityActions } from 'context/Entity'
import { useTranslation } from 'react-i18next'
import { getEntityUserRolesByPermission } from 'utils/entity'

import styles from '../../../Firm/Team/dashboard.firm.module.scss'

const { Paragraph } = Typography

type TProps = {
  contact: ICpaContact
  onSubmit: () => void
}

const ChangeEntityRoleForm = ({ contact, onSubmit }: TProps) => {
  const [form] = Form.useForm()
  const { updateEntityRole, response } = useEntityActions()
  const { loadEntityUsers } = useEntityActions()
  const {
    entity: { currentUserPermissions, currentEntity },
  } = useEntity()
  const [errorMap, setErrorMap] = useState<ErrorResponse>({})
  const { t } = useTranslation('common')
  const [loading, setLoading] = useState(false)
  const roleOptions = getEntityUserRolesByPermission(currentUserPermissions)

  const handleRoleChange = async ({ role }: { role: string }) => {
    setLoading(true)
    const editResponse = await updateEntityRole(currentEntity.guid, contact.user.guid, role)
    if (response.ok) {
      onSubmit()
      message.success(t('messages.success.roleChanged'))
      await loadEntityUsers(currentEntity.guid)
    } else {
      setErrorMap({ role: [editResponse?.detail] })
    }
    setLoading(false)
  }

  return (
    <div className={styles.role_form}>
      <Form onFinish={handleRoleChange} form={form}>
        <div className={styles.form_fields}>
          <Paragraph className={styles.form_label}>{t('form.role')}</Paragraph>
          <Form.Item
            rules={[{ required: true, message: `${t('form.role')} is required` }]}
            className={styles.users_role}
            initialValue={contact.role}
            name="role"
            key="role"
            extra={
              errorMap?.role && (
                <Paragraph className={styles.form_error}>{getFirstError(errorMap.role)}</Paragraph>
              )
            }
            validateStatus={errorMap?.role && 'error'}
          >
            <Select placeholder={t('form.role')} options={roleOptions} />
          </Form.Item>
          <Button htmlType="submit" className={styles.form_submit} type="primary" loading={loading}>
            {t('buttons.confirm')}
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default ChangeEntityRoleForm
