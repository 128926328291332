import { useEffect } from 'react'
import { Row, Col } from 'antd'

import { useDashboardActions } from 'context/Dashboard'

import Info from '../components/Info'
import ActivityFeed from '../components/ActivityFeed'
import MostRecent from '../components/MostRecent'
import Filters from '../components/Filters'

import { DEFAULT_TAX_DEADLINES_PARAMS } from '../constants'
import useParams from '../hooks/useParams'
import InvitationBanner from '../components/InvitationBanner'

const Dashboard = () => {
  const prevYear = new Date().getFullYear() - 1
  const { summaryParams, setSummaryParams, taxParams, setTaxParams, showTable, setShowTable } =
    useParams()
  const { loadTaxDeadlines, loading: loadingDeadlines } = useDashboardActions()

  useEffect(() => {
    loadTaxDeadlines({ ...DEFAULT_TAX_DEADLINES_PARAMS, taxYear: prevYear })
  }, [])

  return (
    <>
      <div className="container-xxl">
        <Filters
          setSummaryParams={setSummaryParams}
          setTaxParams={setTaxParams}
          setShowTable={setShowTable}
          loading={loadingDeadlines}
        />
      </div>
      <div className="container">
        <InvitationBanner />
        <Row gutter={[20, 20]}>
          <Col xs={24}>
            <Info
              taxParams={taxParams}
              showTable={showTable}
              summaryParams={summaryParams}
              setSummaryParams={setSummaryParams}
              setShowTable={setShowTable}
              setTaxParams={setTaxParams}
              loadingDeadlines={loadingDeadlines}
            />
          </Col>
          <Col xs={24} lg={14}>
            <MostRecent />
          </Col>
          <Col xs={24} lg={10}>
            <ActivityFeed />
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Dashboard
