import { SyntheticEvent, useState } from 'react'
import { Flex } from 'reflexbox'
import { Form, message, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import { Button, Textarea } from 'components'

import { getFirstError } from 'utils'
import { ErrorResponse } from 'context/Entity'
import { RequestInvitees, useTaxOrganizer } from 'context/TaxOrganizer'
import useDocumentsActions from 'context/TaxOrganizer/actions/documents'
import Invitees from './Invitees'

import styles from '../../tax.documents.module.scss'

const { Title, Paragraph } = Typography

type Props = {
  hideShareModal: () => void
}
const RequestModalContent = ({ hideShareModal }: Props) => {
  const { t } = useTranslation(['taxOrganizer'])
  const { t: t2 } = useTranslation('common')
  const [form] = Form.useForm()
  const { createDocumentUploadInvites, loading } = useDocumentsActions()
  const {
    taxOrganizer: {
      data: { guid },
    },
  } = useTaxOrganizer()
  const [emails, setEmails] = useState<string[]>([])
  const [errorInvitees, setErrorInvitees] = useState<boolean>(false)
  const [errorMap, setErrorMap] = useState<ErrorResponse>({})

  const onSubmitForm = async (formValue: { message: string }) => {
    if (!emails.length) {
      setErrorInvitees(true)

      return
    }

    const body: RequestInvitees = {
      emails,
      message: formValue.message,
    }

    const error = await createDocumentUploadInvites(guid, body)

    if (error) {
      setErrorMap(error)
    } else {
      message.success(t2('messages.success.requestsSent'))
      hideShareModal()
    }
  }

  return (
    <Form form={form} onFinish={onSubmitForm}>
      <Flex
        px={[2]}
        py={[3]}
        flexDirection={['column']}
        maxWidth={['100%', '100%', '550px']}
        onClick={(event: SyntheticEvent) => {
          event.stopPropagation()
        }}
      >
        <Title level={4}>{t('requestModal.title')}</Title>

        <Paragraph style={{ fontSize: '16px' }}>{t('requestModal.description')}</Paragraph>

        <Paragraph style={{ fontSize: '16px' }}>{t('requestModal.subDescription')}</Paragraph>

        <Paragraph className={styles.form_label}>{t('requestModal.invitees')}</Paragraph>

        <Form.Item
          extra={
            errorMap?.emails ? (
              <Paragraph className={styles.form_error}>{getFirstError(errorMap.emails)}</Paragraph>
            ) : (
              errorInvitees &&
              !emails.length && (
                <Paragraph className={styles.form_error}>
                  {t('requestModal.errorTextInvitees')}
                </Paragraph>
              )
            )
          }
        >
          <Invitees emails={emails} setEmails={setEmails} errorInvitees={errorInvitees} />
        </Form.Item>

        <Form.Item
          initialValue={null}
          name="message"
          key="message"
          rules={[{ required: true, message: t('requestModal.errorTextMessage') }]}
          extra={
            errorMap?.message && (
              <Paragraph className={styles.form_error}>{getFirstError(errorMap.message)}</Paragraph>
            )
          }
          validateStatus={errorMap?.message && 'error'}
        >
          <Textarea autoSize={{ minRows: 3 }} placeholder={t('requestModal.placeholderMessage')} />
        </Form.Item>

        <Button
          className={styles.form_submit}
          type="primary"
          size="large"
          htmlType="submit"
          block
          disabled={loading}
        >
          {t('requestModal.modalButton')}
        </Button>
      </Flex>
    </Form>
  )
}

export default RequestModalContent
