import { TEntity } from 'context/Entity'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Box } from 'reflexbox'
import { getEntityPathById } from 'utils/routes'

import { DocumentProvider } from 'context/Document'

import Documents from '../../Documents'
import Overview from '../../Overview'
import TaxeOrganizers from '../../TaxeOrganizers'
import Users from '../../Users'
import Document from '../../Document'

type TProps = {
  currentEntity: TEntity
  toggleNavigation: (show: boolean) => void
}

const Router = ({ currentEntity, toggleNavigation }: TProps) => {
  return (
    <Box mt={[1, 1, 3]}>
      <Switch>
        <Route path={`${getEntityPathById(currentEntity?.guid)}/documents/:documentGuid`}>
          <DocumentProvider>
            <Document toggleNavigation={toggleNavigation} />
          </DocumentProvider>
        </Route>
        <Route path={`${getEntityPathById(currentEntity?.guid)}/documents`}>
          <Documents entity={currentEntity?.guid} />
        </Route>
        <Route path={`${getEntityPathById(currentEntity?.guid)}`} exact>
          <Overview entity={currentEntity?.guid} />
        </Route>
        <Route path={`${getEntityPathById(currentEntity?.guid)}/overview`}>
          <Redirect to={`${getEntityPathById(currentEntity?.guid)}`} />
        </Route>
        <Route path={`${getEntityPathById(currentEntity?.guid)}/taxes`} exact>
          <TaxeOrganizers entity={currentEntity?.guid} />
        </Route>
        <Route path={`${getEntityPathById(currentEntity?.guid)}/users`} exact>
          <Users entity={currentEntity} />
        </Route>
      </Switch>
    </Box>
  )
}

export default Router
