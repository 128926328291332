import { round } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Flex, Box } from 'reflexbox'

import styles from '../uploadmodal.module.scss'

type Props = {
  progress: { total: number; loaded: number; width: number; filesLeft: number }
  totalFiles: number
}

const StatusBar = ({ progress, totalFiles }: Props) => {
  const { t } = useTranslation('common', { keyPrefix: 'upload.modal' })
  const { t: tStatus } = useTranslation('common', { keyPrefix: 'upload.modal.status' })
  const uploadTitle = progress.filesLeft ? tStatus('uploading') : tStatus('uploaded')

  return (
    <Flex px={[2, 4]} py={['15px']} className={styles.upload_status_bar} flexDirection={['column']}>
      <Flex
        justifyContent={['space-between']}
        flexDirection={['column', 'row']}
        alignItems={['center']}
        width={['100%']}
      >
        <Box lineHeight={['20px']} fontSize={['16px']}>
          {t('title', { upload: uploadTitle, count: totalFiles })}
        </Box>
        <Flex
          flexDirection={['column', 'row']}
          alignItems={['center']}
          lineHeight={['18px']}
          fontSize={['12px']}
          my={[3, 0]}
          className={styles.text_gray}
        >
          <Box>
            {uploadTitle} {round(progress.loaded / 1000000, 2)}MB of{' '}
            {round(progress.total / 1000000, 2)}MB
          </Box>
        </Flex>
      </Flex>
      <Flex height={['3px']} mt={['10px']} className={styles.upload_status_bar_indicator}>
        <div className={styles.upload_status_bar_indicator_bg}> </div>
        <div
          className={styles.upload_status_bar_indicator_line}
          style={{ width: `${progress.width}%` }}
        >
          {' '}
        </div>
      </Flex>
    </Flex>
  )
}

export default StatusBar
