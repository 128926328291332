import { useTranslation } from 'react-i18next'

import { useDocumentActions } from 'context/Document'

import { Button, Modal } from 'components'

import styles from '../../dashboard.document.module.scss'

type TProps = {
  disabled: boolean
}

const Comments = ({ disabled }: TProps) => {
  const { t } = useTranslation(['common', 'taxOrganizer'])
  const { t: t2 } = useTranslation('taxOrganizer', { keyPrefix: 'locked' })
  const { setCommentMode } = useDocumentActions()

  const handleAddComment = () => setCommentMode(true)

  const childContent = (
    <div className={styles.right_section}>
      <div className={styles.right_section_top}>
        <span className={styles.right_section_title}>{t('taxOrganizer:document.comments')}</span>
      </div>
      <Button type="primary" size="middle" onClick={handleAddComment} disabled={disabled}>
        {t('buttons.addComment')}
      </Button>
    </div>
  )

  return disabled ? <Modal content={t2('document.modified')}>{childContent}</Modal> : childContent
}

export default Comments
