import { Avatar, notification } from 'antd'
import { useTranslation } from 'react-i18next'

import { useAuth, useAuthActions } from 'context/Auth'

import { Button, Upload } from 'components'

import styles from '../../user.profile.module.scss'

const ProfileAvatar = () => {
  const { user } = useAuth()
  const { updateAvatar, loading, response } = useAuthActions()
  const { t } = useTranslation('dashboard', { keyPrefix: 'user.avatar' })

  const onSubmit = async (file: File) => {
    await updateAvatar(file)
    if (response.ok) {
      notification.success({
        message: t('notification.message'),
        description: t('notification.description'),
      })
    }
  }

  return (
    <div className={styles.avatar}>
      <div className={styles.title}>{t('title')}</div>
      <div className={styles.description}>{t('description')}</div>
      {user?.avatar ? (
        <>
          <Avatar src={user.avatar} className={styles.avatar_image} size={150} />
          <div>
            <Upload
              fileList={[]}
              accept="image/png,image/jpeg"
              multiple={false}
              onChange={(info: any) => onSubmit(info.file)}
            >
              <Button type="primary" size="large" ghost loading={loading} style={{ marginTop: 20 }}>
                {t('submit')}
              </Button>
            </Upload>
          </div>
        </>
      ) : (
        <Upload
          className={styles.avatar_upload}
          fileList={[]}
          accept="image/png,image/jpeg"
          multiple={false}
          hint={t('hint')}
          loading={loading}
          disabled={loading}
          onChange={(info: any) => onSubmit(info.file)}
        />
      )}
    </div>
  )
}

export default ProfileAvatar
