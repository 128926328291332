import { Form, FormItemProps } from 'antd'
import PhoneInput, { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input'

import 'react-phone-number-input/style.css'
import './styles.scss'

export interface PhoneInputProps extends FormItemProps {
  initialValue: string | undefined
}

const PhoneInputWrapper = ({
  initialValue = '',
  name,
  label,
  validateStatus,
  help,
}: PhoneInputProps) => {
  return (
    <Form.Item
      name={name}
      label={label}
      validateStatus={validateStatus}
      help={help}
      rules={[
        { required: true, message: 'Please input your phone number!' },
        () => ({
          validator(_, value) {
            if (!value) {
              return Promise.resolve()
            }
            if (isValidPhoneNumber(value) && isPossiblePhoneNumber(value)) {
              return Promise.resolve()
            }
            return Promise.reject(new Error('Please enter a valid phone number!'))
          },
        }),
      ]}
    >
      <PhoneInput
        countryCallingCodeEditable={false}
        countrySelectProps={{ unicodeFlags: true }}
        defaultCountry="US"
        value={initialValue}
        onChange={() => {}}
      />
    </Form.Item>
  )
}

export default PhoneInputWrapper
