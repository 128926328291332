import { useEffect, useState, MouseEvent } from 'react'
import { Image } from 'antd'
import { useParams } from 'react-router-dom'
import cn from 'classnames'
import { closest } from 'utils'
import { isImage, isPDF } from 'utils/files'
import usePrintPDF from 'hooks/usePrintPDF'

import { ThreadContextType, useDocument, useDocumentActions } from 'context/Document'

import { SpinnerBackdrop } from 'components'
import Unsupported from 'components/Unsupported'
import Header from './components/Header'
import PDFDocument from './components/PDFDocument'
import Threads from './components/Threads'

import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/print/lib/styles/index.css'
import styles from './documentview.module.scss'

const DocumentView = () => {
  const { document: taxDocument, threads, isCommentMode } = useDocument()
  const { loadThreads, setNewThread } = useDocumentActions()
  const [reloadThreads, setReloadThreads] = useState(false)
  const {
    height,
    documentRef,
    documentWrapperHeight,
    setDocumentWrapperHeight,
    pdfError,
    setPDFError,
    documentLoaded,
    setDocumentLoaded,
    activePage,
    setActivePage,
    scale,
    setScale,
    printPluginInstance,
    pageNavigationPluginInstance,
    jumpToPage,
  } = usePrintPDF()

  const fileIsPdf = isPDF(taxDocument.presignFilePath)
  const fileIsImage = isImage(taxDocument.presignFilePath)

  const { taxOrganizerGuid, documentGuid }: { taxOrganizerGuid: string; documentGuid: string } =
    useParams()

  const pageThreads = threads.filter((thread) => thread.context.pageNumber === activePage)

  const getThreads = () =>
    loadThreads({
      taxOrganizerGuid,
      documentGuid: taxDocument.guid,
    })

  const handleDocumentClick = (e: MouseEvent<HTMLDivElement>) => {
    const { current } = documentRef
    if (current && isCommentMode && closest(e.target, (el: any) => el.isEqualNode(current))) {
      const rect = current.getBoundingClientRect()
      const x = ((e.clientX - rect.left) / rect.width) * 100
      const y = ((e.clientY - rect.top) / rect.height) * 100
      setNewThread({ x, y, page: activePage })
    }
  }

  useEffect(() => {
    if (documentLoaded) {
      getThreads()
    }
  }, [reloadThreads, documentLoaded])

  useEffect(() => {
    setDocumentWrapperHeight(document.querySelector('.rpv-core__canvas-layer')?.clientHeight)
    jumpToPage(activePage - 1)
  }, [activePage, scale, height])

  return (
    <div className={styles.document_view}>
      {!documentLoaded && <SpinnerBackdrop transparent={false} />}
      {(fileIsPdf || fileIsImage) && (
        <Header
          activePage={activePage}
          total={taxDocument.pageCount}
          setActivePage={setActivePage}
          printPluginInstance={printPluginInstance}
          pdfError={pdfError}
          documentLoaded={documentLoaded}
          fileIsImage={fileIsImage}
          fileUrl={taxDocument.file!}
        />
      )}
      <div
        className={cn(styles.document, {
          [styles.document_comment_mode]: isCommentMode,
          [styles.document_image]: !fileIsPdf,
        })}
        ref={documentRef}
        onClick={handleDocumentClick}
      >
        {fileIsPdf ? (
          <PDFDocument
            fileUrl={taxDocument.file!}
            printPluginInstance={printPluginInstance}
            setPDFLoaded={setDocumentLoaded}
            pageNavigationPluginInstance={pageNavigationPluginInstance}
            setPDFError={setPDFError}
            setScale={setScale}
            documentWrapperHeight={documentWrapperHeight}
          />
        ) : fileIsImage ? (
          <Image
            src={taxDocument.file!}
            preview={false}
            width="100%"
            onLoad={() => setDocumentLoaded(true)}
          />
        ) : (
          <Unsupported
            setDocumentLoaded={setDocumentLoaded}
            fileUrl={taxDocument.file}
            fileName={taxDocument.name}
          />
        )}

        {!pdfError && documentLoaded && (
          <Threads
            contextKey={ThreadContextType.CONTEXT}
            taxOrganizerId={taxOrganizerGuid}
            documentGuid={documentGuid}
            threads={pageThreads}
            setReloadThreads={setReloadThreads}
          />
        )}
      </div>
    </div>
  )
}

export default DocumentView
