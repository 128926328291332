export enum ESteps {
  INFO = 0,
  UPLOAD = 1,
  CATEGORIZE = 2,
  REVIEW = 3,
  AUTHORIZE = 4,
  FILING = 5,
  FILED = 6,
  CHANGES_REQUESTED = 7,
  CLOSED_WITHOUT_FILING = 8,
  RETURN_FILED = 9,
}
