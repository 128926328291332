import { useFetch } from 'use-http'
import axios from 'axios'
import qs from 'qs'
import { omit } from 'lodash'

import { toSnake } from 'utils'

import { Key } from 'antd/lib/table/interface'
import { RcFile } from 'antd/lib/upload'
import { ActionTypes } from './action-types'
import {
  TLoadDocumentsParams,
  TUploadDocumentParams,
  TUploadDocumentResponse,
  UpdateDocumentParams,
  TFileUploadOverrideProps,
  TMultipleDocumentParams,
} from '../types'

import { useEntityDispatch, BodyUploadInvites } from '..'

const useEntityDocumentsActions = ({
  uploadUrl,
  deleteUrl,
  updateUrl,
}: TFileUploadOverrideProps = {}) => {
  const options = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }
  const dispatch = useEntityDispatch()
  const { get, post, del, patch, response, loading, error } = useFetch('', options)

  const loadDocuments = async (id: string, params?: TLoadDocumentsParams) => {
    const query = qs.stringify(toSnake({ ...params }), { addQueryPrefix: !!params })
    const documents = await get(`/entities/${id}/documents${query}`)
    if (response.ok) {
      dispatch({ type: ActionTypes.LOAD_CURRENT_ENTITY_DOCS, payload: documents })
    }
  }

  const loadDocumentsSummary = async (id: string) => {
    const documentsSummary = await get(`/tax-organizers/${id}/documents/summary`)

    if (response.ok) {
      dispatch({ type: ActionTypes.LOAD_DOCUMENTS_SUMMARY, payload: documentsSummary })
    }
  }

  const loadDocument = async (entityGuid: string, documentGuid: string) => {
    const document = await get(`/entities/${entityGuid}/documents/${documentGuid}`)
    if (response.ok) {
      dispatch({ type: ActionTypes.LOAD_ENTITY_DOCUMENT, payload: document })
    }
  }

  const deleteDocument = async (id: string, documentGuid: string) => {
    const deleteDocumentUrl = deleteUrl
      ? deleteUrl.replace(':taxDocumentId', documentGuid)
      : `/entities/${id}/documents/${documentGuid}`
    await del(deleteDocumentUrl)
    if (response.ok) {
      dispatch({ type: ActionTypes.DELETE_DOCUMENT, payload: { documentGuid } })
    }
  }

  const bulkDeteleDocuments = async (id: string, documentGuids: string[]) => {
    const deleteDocumentUrl = `/entities/${id}/documents/batch`
    await del(deleteDocumentUrl, { document_guids: documentGuids })
    if (response.ok) {
      dispatch({ type: ActionTypes.BULK_DELETE_DOCUMENTS, payload: { documentGuids } })
    }
  }

  const uploadEntityDocument = async (
    entityGuid: string,
    params: TUploadDocumentParams,
    {
      file,
      onUploadProgress,
    }: { file: RcFile | undefined; onUploadProgress?: (event: ProgressEvent) => void },
    meta?: any
  ) => {
    const documentUploadUrl = uploadUrl || `/entities/${entityGuid}/documents`
    const document: TUploadDocumentResponse = await post(documentUploadUrl, toSnake(params))

    if (response.ok) {
      try {
        const axiosResponse = await axios.put(document.uploadUrl, file, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress,
        })
        if (axiosResponse.status === 200) {
          dispatch({
            type: ActionTypes.UPLOAD_DOCUMENT,
            payload: {
              ...document,
              uid: meta.uid,
              responseOk: response.ok,
              loading,
            },
          })
        }
      } catch (err) {
        deleteDocument(entityGuid, document.guid)
        return null
      }
    }
    return document
  }

  const updateDocument = async (id: string, documentGuid: string, params: UpdateDocumentParams) => {
    const updateDocumentUrl = updateUrl || `/entities/${id}/documents/${documentGuid}`
    const updated = await patch(updateDocumentUrl, params)
    return updated
  }

  const setSelectedTableDocumentIds = (documentGuids: Key[]) => {
    dispatch({ type: ActionTypes.SET_SELECTED_TABLE_DOCUMENT_IDS, payload: documentGuids })
  }
  const uploadRequestDocument = async (
    invitationGuid: string,
    body: BodyUploadInvites,
    { file, onUploadProgress }: { file: File; onUploadProgress?: (event: ProgressEvent) => void }
  ) => {
    const documentUploadUrl = `/entities/document-upload-invites/${invitationGuid}`
    const document: TUploadDocumentResponse = await post(documentUploadUrl, toSnake(body))
    if (response.ok) {
      try {
        await axios.put(document.uploadUrl, file, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress,
        })
      } catch (err) {
        return null
      }
    }
    return document
  }

  const setMulptipleDocumentParams = (params: TMultipleDocumentParams | null) => {
    dispatch({ type: ActionTypes.SET_MULTIPLE_DOCUMENT_PARAMS, payload: params })
  }

  const removeMulptipleDocumentParam = (documentGuid: string) => {
    dispatch({ type: ActionTypes.REMOVE_MULTIPLE_DOCUMENT_PARAM, payload: documentGuid })
  }

  const clearMulptipleDocumentParams = () => {
    dispatch({ type: ActionTypes.CLEAR_MULTIPLE_DOCUMENT_PARAMS, payload: [] })
  }

  const clearUploaded = () => {
    dispatch({ type: ActionTypes.CLEAR_UPLOADED, payload: [] })
  }

  const updateDocuments = (entityGuid: string, params: TMultipleDocumentParams[]) => {
    return Promise.all([
      ...params.map((docParam: TMultipleDocumentParams) => {
        const updateDocumentParams: UpdateDocumentParams = omit(docParam, [
          'guid',
          'validateStatus',
        ])
        return patch(`/entities/${entityGuid}/documents/${docParam.guid}`, updateDocumentParams)
      }),
    ])
  }

  const addDocumentTaxOrganizer = async (taxOrganizerGuid: string, documentGuid: string) => {
    const updatedEntityResponse = await post(
      `/tax-organizers/${taxOrganizerGuid}/documents/entity-document`,
      {
        document: documentGuid,
      }
    )
    return updatedEntityResponse
  }

  const removeDocumentTaxOrganizer = async (taxOrganizerGuid: string, documentGuid: string) => {
    const updatedEntityResponse = await del(
      `/tax-organizers/${taxOrganizerGuid}/documents/entity-document/${documentGuid}`
    )
    return updatedEntityResponse
  }

  return {
    loadDocuments,
    loadDocument,
    uploadEntityDocument,
    updateDocument,
    updateDocuments,
    deleteDocument,
    bulkDeteleDocuments,
    uploadRequestDocument,
    loadDocumentsSummary,
    setSelectedTableDocumentIds,
    setMulptipleDocumentParams,
    removeMulptipleDocumentParam,
    clearMulptipleDocumentParams,
    clearUploaded,
    addDocumentTaxOrganizer,
    removeDocumentTaxOrganizer,
    loading,
    response,
    error,
  }
}

export default useEntityDocumentsActions
export { ActionTypes, useEntityDocumentsActions }
