import { intersection, keys } from 'lodash'
import { useState, useMemo } from 'react'
import { DEFAULT_FILTERS, FILTERS } from '../constatnts'
import { TFilters } from '../types'

export default <T>(params: T) => {
  const [filters, setFilters] = useState<TFilters>(DEFAULT_FILTERS)
  const filtersCount = useMemo(() => keys(filters).length, [filters])
  const activeFiltersCount = useMemo(() => intersection(keys(params), FILTERS).length, [params])
  return { filters, setFilters, filtersCount, activeFiltersCount }
}
