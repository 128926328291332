import { MarkerSdk } from '@marker.io/browser'

import { ActionTypes } from './action-types'

export type IMarkerContext = {
  marker: MarkerSdk | null
  reporter: {
    email: string
    fullName: string
  } | null
}

export type IAction = { type: keyof typeof ActionTypes; payload?: any }

export const initialState: IMarkerContext = {
  marker: null,
  reporter: null,
}

export const MarkerReducer = (state: IMarkerContext = initialState, action: IAction) => {
  const { type, payload } = action

  if (state.marker && state.reporter) {
    state.marker.setReporter(state.reporter)
  }

  switch (type) {
    case ActionTypes.WIDGET_LOADED:
      if (state.reporter) {
        payload.setReporter(state.reporter)
      }
      return {
        ...state,
        marker: payload,
      }

    case ActionTypes.SET_REPORTER:
      if (state.marker) {
        state.marker.setReporter(payload)
      }
      return {
        ...state,
        reporter: payload,
      }

    default:
      throw new Error(`Unhandled action type: ${type}`)
  }
}
