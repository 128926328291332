import { useEffect } from 'react'
import { Section, SpinnerBackdrop } from 'components'

import { useTaxOrganizer, useTaxOrganizerActions } from 'context/TaxOrganizer'

import useUserType from 'hooks/useUserType'
import useUserEntityPermissions from 'hooks/useUserEntityPermissions'

import InviteTaxFirm from 'pages/Dashboard/EntityManagement/components/InviteTaxFirm'
import TaxTeam from 'pages/Dashboard/EntityManagement/Overview/components/TaxTeamEntityWrapper/components/TaxTeam'

const TaxTeamTaxOrganizerWrapper = () => {
  const { userHasInvitePermissions } = useUserEntityPermissions()
  const { loadCPAContacts, loading } = useTaxOrganizerActions()
  const {
    taxOrganizer: { data, cpaContacts },
  } = useTaxOrganizer()
  const userType = useUserType()

  useEffect(() => {
    loadCPAContacts(data.guid)
  }, [data.guid])

  if (userType === 'CPA') {
    return (
      <Section style={{ padding: '0' }}>
        {loading && <SpinnerBackdrop />}
        <TaxTeam
          data={cpaContacts.data}
          count={cpaContacts.count}
          cpaFirm={data.cpaFirm!}
          entity={data.entity}
          editable={false}
        />
      </Section>
    )
  }

  if (userType === 'ENTITY') {
    if (!data.cpaFirm) {
      return userHasInvitePermissions ? <InviteTaxFirm entity={data.entity} /> : null
    }
    return (
      <Section style={{ padding: '0' }}>
        {loading && <SpinnerBackdrop />}
        <TaxTeam
          data={cpaContacts.data}
          count={cpaContacts.count}
          cpaFirm={data.cpaFirm!}
          entity={data.entity}
          editable={false}
        />
      </Section>
    )
  }

  return null
}

export default TaxTeamTaxOrganizerWrapper
