import { useParams, Redirect } from 'react-router-dom'
import { useEffect, FC } from 'react'

import { useEntityCPAsActions, useEntityCPAs } from 'context/EntityCPAs'
import { SpinnerBackdrop } from 'components'
import { LOGIN_PATH } from 'utils/routes'

import styles from './taxFirm.layout.module.scss'

const TaxFirmLayout: FC = ({ children }) => {
  const { slug } = useParams<{ slug: string }>()
  const { loadCPAFirmProfile, loading, error } = useEntityCPAsActions()

  const {
    state: {
      CPAFirmProfile: { letterheadImage, name },
    },
  } = useEntityCPAs()

  useEffect(() => {
    loadCPAFirmProfile(slug)
  }, [])

  if (loading) {
    return <SpinnerBackdrop />
  }

  if (error?.name === '404') {
    return <Redirect to={LOGIN_PATH} />
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.name}>
          <p>{name}</p>
        </div>
        <div className={styles.logo}>
          <img src={letterheadImage!} alt="firm logo" />
        </div>
      </div>
      {children}
      <div className={styles.footer} />
    </div>
  )
}

export default TaxFirmLayout
