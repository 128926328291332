import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, message } from 'antd'
import { Flex, Box } from 'reflexbox'
import { useTranslation } from 'react-i18next'

import { getCPAOnboardingPath } from 'utils/routes'
import { browserSession } from 'utils/session'
import { initForm, saveFormToSession } from 'utils/form'
import { clientsLimitExceeded } from 'utils/http'

import { MESSAGE_DURATION } from 'constants/message'

import usePayment from 'hooks/usePayment'

import { TClientInviteParams, useEntityCPAsActions } from 'context/EntityCPAs'
import { useAuth, useAuthActions } from 'context/Auth'

import { Button, BillingModal } from 'components'
import EmailAndMessage from 'pages/Dashboard/EntityCPAs/Uninvited/components/EmailAndMessage'
import ClientInvitationEntity from 'pages/Dashboard/EntityCPAs/components/ClientInvitationEntity'
import { TInviteClient } from 'pages/Dashboard/EntityCPAs/components/types'
import { cloneDeep } from 'lodash'
import { DEFAULT_INVITATION_PARAMS } from '../constants'

type TProps = {
  setParams: (
    value: TClientInviteParams | ((prevState: TClientInviteParams) => TClientInviteParams)
  ) => void
  anyInvitation: boolean
}

const Invite = ({ setParams, anyInvitation }: TProps) => {
  const [form] = Form.useForm()
  const { t } = useTranslation('common')
  const { t: t2 } = useTranslation('dashboard', { keyPrefix: 'clients.popover' })
  const [errors, setErrors] = useState<Partial<TInviteClient>>({})
  const { sendInvitations, response: sendResponse, loading: sendLoading } = useEntityCPAsActions()
  const { updateCpaFirm, response: updateResponse, loading: updateLoading } = useAuthActions()
  const { setOnboardingStep } = useAuthActions()
  const history = useHistory()
  const [modalVisible, setModalVisible] = useState(false)
  const {
    CPA: { cpaFirm },
  } = useAuth()
  const { stripePromise, paymentStatus, setPaymentStatus } = usePayment()

  const onSubmit = async ({ entities = [], ...rest }: any) => {
    const params = { entities, ...rest }
    const result = await sendInvitations(cpaFirm!.guid, params)
    browserSession.removeItem('form:inviteClient.onboarding')
    if (sendResponse.ok) {
      setErrors({})
      form.resetFields()
      message.success(t('messages.success.invitationsSent'))
      setParams(cloneDeep(DEFAULT_INVITATION_PARAMS))
    } else if (clientsLimitExceeded(sendResponse.status, sendResponse.data)) {
      setModalVisible(true)
    } else {
      setErrors(result)
    }
  }

  const handleNext = async () => {
    await updateCpaFirm(cpaFirm!.guid, { onboardingStatus: 'COMPLETE' })
    if (updateResponse.ok) {
      setOnboardingStep()
      history.replace(getCPAOnboardingPath('COMPLETE'))
    }
  }

  useEffect(() => {
    if (paymentStatus === 'success') {
      setModalVisible(false)
      message.success(t('billing.billingStatusSuccess'), MESSAGE_DURATION)
    }
  }, [paymentStatus])

  return (
    <>
      {modalVisible && (
        <BillingModal
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          stripePromise={stripePromise}
          setPaymentStatus={setPaymentStatus}
          alertMessage={t('billing.inviteExceeded')}
        />
      )}
      <Form
        onFinish={onSubmit}
        form={form}
        autoComplete="off"
        onValuesChange={(changedValues: any, allValues: any) =>
          saveFormToSession('form:inviteClient.onboarding', allValues)
        }
        initialValues={{
          ...initForm('form:inviteClient.onboarding'),
          entities: [{ name: '', tin: '', kind: null }],
        }}
      >
        <Flex
          pt={2}
          px={[1, 2]}
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="inherit"
        >
          <Box>
            <EmailAndMessage errors={errors} />
          </Box>
          <Box mt={[0, 3]}>
            <ClientInvitationEntity title={t2('title2')} fieldName="entities" errors={errors} />
          </Box>
          <Flex
            width={['100%']}
            justifyContent={['space-between']}
            flexDirection={['column', 'row']}
            my={[4]}
          >
            <Box width={['100%', '190px']}>
              <Button
                type="ghost"
                size="large"
                block
                onClick={handleNext}
                loading={updateLoading}
                disabled={updateLoading}
              >
                {anyInvitation ? t('buttons.continue') : t('buttons.skip')}
              </Button>
            </Box>

            <Box width={['100%', '190px']} my={[2, 0]}>
              <Button
                type="primary"
                size="large"
                block
                htmlType="submit"
                disabled={sendLoading}
                loading={sendLoading}
              >
                {t2('submitButton')}
              </Button>
            </Box>
          </Flex>
        </Flex>
      </Form>
    </>
  )
}

export default Invite
