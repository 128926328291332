import logo1 from 'assets/images/financials_logo_1.png'
import logo2 from 'assets/images/financials_logo_2.png'
import logo3 from 'assets/images/financials_logo_3.png'
import logo4 from 'assets/images/financials_logo_4.png'

import { EnumFinSoftware } from '../types'

export default [
  {
    id: '1',
    slug: EnumFinSoftware.QB_ONLINE,
    leftPart: {
      title: 'Quickbooks Online',
      description: 'quickbooksOnline.description',
      logo: logo1,
    },
    rightPart: {
      title: 'quickbooksOnline.stepsTitle',
      steps: ['quickbooksOnline.steps.1', 'quickbooksOnline.steps.2', 'quickbooksOnline.steps.3'],
      upload: false,
    },
    status: true,
  },
  {
    id: '2',
    slug: EnumFinSoftware.QB_DESKTOP,
    leftPart: {
      title: 'Quickbooks Desktop',
      description: 'quickbooksSoftware.description',
      logo: logo2,
    },
    rightPart: {
      title: 'quickbooksSoftware.stepsTitle',
      steps: [
        'quickbooksSoftware.steps.1',
        'quickbooksSoftware.steps.2',
        'quickbooksSoftware.steps.3',
      ],
      upload: true,
    },
    status: false,
  },
  {
    id: '3',
    slug: EnumFinSoftware.XERO,
    leftPart: {
      title: 'Xero',
      description: 'xero.description',
      logo: logo3,
    },
    rightPart: {
      title: 'xero.stepsTitle',
      steps: ['xero.steps.1', 'xero.steps.2', 'xero.steps.3'],
      upload: false,
    },
    status: false,
  },
  {
    id: '4',
    slug: EnumFinSoftware.NETSUITE,
    leftPart: {
      title: 'Netsuite',
      description: 'netsuite.description',
      logo: logo4,
    },
    rightPart: {
      title: 'netsuite.stepsTitle',
      steps: ['netsuite.steps.1', 'netsuite.steps.2', 'netsuite.steps.3'],
      upload: false,
    },
    status: false,
  },
]
