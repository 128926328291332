import { NavLink, useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useAuth } from 'context/Auth'

import styles from './firm.navigation.module.scss'

const Navigation = () => {
  const { url } = useRouteMatch()
  const { t } = useTranslation('dashboard', { keyPrefix: 'firm.navigation' })
  const {
    CPA: { userRole },
  } = useAuth()

  return (
    <div className="container-xxl">
      <div className={styles.container}>
        <div className="container">
          <div className={styles.box}>
            <div className={styles.item}>
              <NavLink to={`${url}/team`} className={styles.link} activeClassName={styles.active}>
                {t('team')}
              </NavLink>
            </div>
            {userRole !== 'MEMBER' && (
              <div className={styles.item}>
                <NavLink
                  to={`${url}/billing`}
                  exact
                  className={styles.link}
                  activeClassName={styles.active}
                >
                  {t('billing')}
                </NavLink>
              </div>
            )}
            <div className={styles.item}>
              <NavLink
                to={`${url}/profile`}
                exact
                className={styles.link}
                activeClassName={styles.active}
              >
                {t('profile')}
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navigation
