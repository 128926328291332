import { ActionTypes } from './action-types'
import { TPagesRequest, TState, TTaxReturn } from './types'

export const initialState: TState = {
  steps: [],
  step: {
    current: undefined,
    prev: null,
    next: 1,
  },
  maxStep: 0,
  process: {
    status: undefined,
  },
  taxReturns: {
    data: [],
    latest: undefined,
    response: undefined,
  },
  taxReturnDocuments: {
    data: [],
    count: 0,
    response: undefined,
  },
  filteredTaxReturnPages: [],
  valid: true,
  total: 9999,
  status: 'process',
  categories: [],
  threads: {
    count: 0,
    next: undefined!,
    previous: undefined!,
    results: [],
  },
  pages: [],
  selectedTags: [],
  document: undefined!,
  dragDropDocumentIds: [],
  eFileAuthDoc: undefined!,
  filingStatus: [],
  entitySigners: [],
  entityInvites: [],
  authorizationDocumentDetails: undefined!,
  isPressShift: false,
  loading: false,
}

export const reducer = (state: TState = initialState, action: { type: string; payload?: any }) => {
  const { payload } = action

  switch (action.type) {
    case ActionTypes.SET_LOADING:
      return {
        ...state,
        loading: payload,
      }

    case ActionTypes.LOAD_STEPS:
      return {
        ...state,
        steps: payload?.steps,
        total: payload?.total,
      }

    case ActionTypes.SET_TAX_RETURN_STATUS:
      return {
        ...state,
        process: {
          status: payload,
        },
      }

    case ActionTypes.LOAD_TAX_RETURNS: {
      return {
        ...state,
        taxReturns: {
          data: payload?.taxReturns ?? [],
          latest:
            payload?.taxReturns?.find(({ isLatest }: TTaxReturn) => isLatest) ??
            payload?.taxReturns?.[0],
          response: payload?.taxReturnsResponse,
        },
      }
    }

    case ActionTypes.LOAD_FILING_STATUS:
      return {
        ...state,
        filingStatus: payload,
      }

    case ActionTypes.LOAD_ENTITY_SIGNERS:
      return {
        ...state,
        entitySigners: payload,
      }

    case ActionTypes.LOAD_TAX_RETURN_DOCUMENTS: {
      return {
        ...state,
        taxReturnDocuments: {
          data: payload?.documents?.results,
          count: payload?.documents?.count,
          response: payload?.response,
        },
      }
    }

    case ActionTypes.LOAD_TAX_RETURN_PAGES:
      return {
        ...state,
        pages: payload,
      }

    case ActionTypes.LOAD_TAX_RETURN_CATEGORIES:
      return {
        ...state,
        categories: payload.results,
      }

    case ActionTypes.LOAD_TAX_RETURN_THREADS:
      return {
        ...state,
        threads: payload,
      }

    case ActionTypes.FILTER_TAX_RETURN_PAGES:
      return {
        ...state,
        filteredTaxReturnPages: payload,
      }

    case ActionTypes.SET_STEP:
      return {
        ...state,
        step: payload.step,
        maxStep: payload.maxStep,
      }

    case ActionTypes.SET_DRAG_DROP_DOCUMENT_IDS:
      return {
        ...state,
        dragDropDocumentIds: payload,
      }

    case ActionTypes.UPDATE_TAX_RETURN_PAGES:
      return {
        ...state,
        pages: state.pages.map((page) => {
          const updatedPage = (payload as TPagesRequest).pages.find(
            (findPage) => findPage.guid === page.guid
          )

          if (updatedPage) {
            return {
              ...page,
              parentCategory: updatedPage.parentCategory,
              childCategory: updatedPage.childCategory,
              state: updatedPage.state,
              categoryPageNumber: updatedPage.categoryPageNumber,
            }
          }

          return page
        }),
      }

    case ActionTypes.SET_SELECTED_TAGS:
      return {
        ...state,
        selectedTags: payload,
      }

    case ActionTypes.LOAD_TAX_RETURN_DOCUMENT:
      return {
        ...state,
        document: payload,
      }

    case ActionTypes.DELETE_TAX_RETURN_DOCUMENT:
      return {
        ...state,
        taxReturnDocuments: {
          ...state.taxReturnDocuments,
          count: state.taxReturnDocuments.count - 1,
          data: state.taxReturnDocuments.data.filter(
            (taxReturnDocument) => taxReturnDocument.taxDocument.guid !== payload
          ),
        },
      }

    case ActionTypes.SET_EFILE_AUTH_DOC:
      return {
        ...state,
        eFileAuthDoc: payload.unsignedFileUrl,
        eFileAuthDocId: payload,
      }
    case ActionTypes.LOAD_AUTH_SIGN_DETAILS:
      return {
        ...state,
        authorizationDocumentDetails: payload,
      }

    case ActionTypes.SET_PRESS_SHIFT:
      return {
        ...state,
        isPressShift: payload,
      }

    case ActionTypes.LOAD_LATEST_TAX_RETURN:
      return {
        ...state,
        taxReturns: {
          ...state.taxReturns,
          latest: payload,
        },
      }

    case ActionTypes.LOAD_ENTITY_INVITES:
      return {
        ...state,
        entityInvites: payload,
      }

    default:
      return state
  }
}
