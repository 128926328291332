import { states } from 'constants/address'
import { TTaxReturnCategory, TTaxReturnPage, TRequestDocument } from '../context/TaxReturns'

export const getGenerateCategories = (
  categories: TTaxReturnCategory[],
  pages: TTaxReturnPage[]
) => {
  const transformCategory = categories.reduce<
    Array<{ category: TTaxReturnCategory; categoryPages: TTaxReturnPage[] }>
  >((accumulator, currentValue) => {
    const filteredPages =
      currentValue.slug !== 'state'
        ? pages.filter(
            (page) =>
              page.parentCategory === currentValue.slug &&
              currentValue.childCategories.some(
                (childCategory) => childCategory.slug === page.childCategory
              )
          )
        : pages.filter((page) => {
            return states
              .map(({ value }: TOption) => value.toString().toLowerCase())
              .includes(page.state?.toLowerCase())
          })
    return accumulator.concat({ category: currentValue, categoryPages: filteredPages })
  }, [])

  const uncategorizedCategory: { category: TTaxReturnCategory; categoryPages: TTaxReturnPage[] } = {
    category: {
      label: 'Uncategorized',
      slug: 'uncategorized',
      childCategories: [],
    },
    categoryPages: pages.filter((page) => !page.parentCategory && !page.childCategory),
  }
  return transformCategory.concat(uncategorizedCategory)
}

export const getGenerateStatesCategory = (
  categories: TTaxReturnCategory[],
  selectedTags: string[]
) => {
  const categoryState = categories.find((category) => category.slug === 'state')
  if (categoryState) {
    return states.reduce<Array<TTaxReturnCategory>>((accumulator, nextValue) => {
      if (selectedTags.some((selectedTag) => selectedTag === nextValue.value)) {
        const transformationValue = nextValue.label.split(' ')

        return transformationValue.length
          ? accumulator.concat({
              childCategories: categoryState.childCategories,
              slug: nextValue.value,
              label: transformationValue[transformationValue.length - 1],
            })
          : accumulator
      }

      return accumulator
    }, [])
  }
  return null
}

export const getSortedTags = (tags: string[], pages: TTaxReturnPage[]) => {
  return [...tags].sort((a, b) => {
    const aCount = pages.filter((page) => page.state === a.toLowerCase()).length
    const bCount = pages.filter((page) => page.state === b.toLowerCase()).length

    if (aCount === bCount) {
      return a > b ? 1 : -1
    }

    return bCount - aCount
  })
}

export const sortPageByCategoryPageNumber = (pages: TTaxReturnPage[]) => {
  return [...pages].sort((a, b) => (a.categoryPageNumber ?? 0) - (b.categoryPageNumber ?? 0))
}

export const getGeneratePagesBody = (
  pageIds: string[],
  pages: TTaxReturnPage[],
  data: Omit<TRequestDocument, 'guid' | 'categoryPageNumber'>,
  isUncategorized: boolean
) => {
  const countDocuments = pages.filter(
    (filteredPage) =>
      filteredPage.parentCategory === data.parentCategory &&
      filteredPage.childCategory === data.childCategory &&
      filteredPage.state === data.state
  ).length

  if (pageIds.length) {
    const oldCategoryPage = pages.find((page) => page.guid === pageIds[0])
    const oldCategoryPages = pages.filter(
      (page) =>
        page.parentCategory === oldCategoryPage?.parentCategory &&
        page.childCategory === oldCategoryPage?.childCategory &&
        page.state === oldCategoryPage?.state &&
        !pageIds.includes(page.guid)
    )
    return {
      pages: pageIds
        .map<TRequestDocument>((pageId, i) => ({
          guid: pageId,
          parentCategory: data.parentCategory,
          childCategory: data.childCategory,
          state: data.state,
          categoryPageNumber: countDocuments + (i + 1),
        }))
        .concat(
          !isUncategorized
            ? sortPageByCategoryPageNumber(oldCategoryPages).map<TRequestDocument>(
                (oldPage, i) => ({
                  guid: oldPage.guid,
                  parentCategory: oldPage.parentCategory,
                  childCategory: oldPage.childCategory,
                  state: oldPage.state,
                  categoryPageNumber: i + 1,
                })
              )
            : []
        ),
    }
  }

  return null
}

export const getPagesRequestBodyForResetUncategorized = (pages: TTaxReturnPage[]) => {
  return {
    pages: pages.map<TRequestDocument>((page) => ({
      guid: page.guid,
      state: null,
      categoryPageNumber: null,
      parentCategory: null,
      childCategory: null,
    })),
  }
}

export const getBodyForResetSelectedPage = (
  selectedPage: TTaxReturnPage,
  pages: TTaxReturnPage[]
) => {
  const categorySelectedPage = pages.filter(
    (page) =>
      page.parentCategory === selectedPage?.parentCategory &&
      page.childCategory === selectedPage?.childCategory &&
      page.state === selectedPage?.state &&
      page.guid !== selectedPage.guid
  )

  let counter = 0

  return {
    pages: categorySelectedPage
      .reduce<TRequestDocument[]>((accumulator, nextPage) => {
        if ((nextPage.categoryPageNumber ?? 0) > (selectedPage.categoryPageNumber ?? 0)) {
          accumulator.push({
            guid: nextPage.guid,
            parentCategory: nextPage.parentCategory,
            childCategory: nextPage.childCategory,
            state: nextPage.state,
            categoryPageNumber: (selectedPage.categoryPageNumber ?? 0) + counter,
          })

          counter++
        }

        return accumulator
      }, [])
      .concat({
        guid: selectedPage.guid,
        state: null,
        categoryPageNumber: null,
        parentCategory: null,
        childCategory: null,
      }),
  }
}

export const canUpdateSortDocument = (
  documents: TTaxReturnPage[],
  sortDocuments: TTaxReturnPage[]
) => {
  const notSameCategoryPageNumber = documents.filter((document, i) => {
    if (!document.categoryPageNumber || !sortDocuments[i].categoryPageNumber) return true

    return document.categoryPageNumber !== sortDocuments[i].categoryPageNumber
  })

  return notSameCategoryPageNumber.length
}
