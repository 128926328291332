import { useEffect } from 'react'

import { useBilling, useBillingActions } from 'context/Billing'
import { useAuth } from 'context/Auth'

import { Section } from 'components'
import Plan from './components/Plan'
import Payment from './components/Payment'
import Description from './components/Description'
import Table from './components/Table'

const Billing = () => {
  const { loadSubscription } = useBillingActions()
  const {
    CPA: { cpaFirm },
  } = useAuth()
  const {
    state: { subscription },
  } = useBilling()

  useEffect(() => {
    loadSubscription(cpaFirm!.guid)
  }, [])

  return (
    <div className="container">
      <Section>
        {subscription && <Plan />}
        <Payment />
        <Description />
        <Table cpaFirmGuid={cpaFirm!.guid} />
      </Section>
    </div>
  )
}

export default Billing
