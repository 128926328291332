import { Button, Modal } from 'components'
import { FormInstance, Typography } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatApiErrorResponse } from 'utils'
import { formatTin, serializeAddress } from 'utils/entity'

import { EntityEditForm, useEntityActions, TEntity } from 'context/Entity'

import useDate from 'hooks/useDate'
import useUserType from 'hooks/useUserType'

import styles from '../../../dashboard.entity.management.module.scss'
import EntityDetailsForm from './components/EntityDetailsForm'

const { Text, Title } = Typography

type Props = {
  entity: TEntity
}

const Details = ({ entity }: Props) => {
  const [popoverVisible, setPopoverVisible] = useState(false)
  const { t } = useTranslation('entity')
  const { t: t2 } = useTranslation('common')
  const { updateEntity, loading } = useEntityActions()
  const userType = useUserType()
  const { getDate } = useDate()

  const onSubmit = async (payload: EntityEditForm, form: FormInstance) => {
    const parsedForm = {
      ...payload,
      businessStartedDate: payload.businessStartedDate
        ? getDate(payload.businessStartedDate, 'yyyy-MM-dd')
        : null,
    }
    const updatedEntityResponse = await updateEntity(entity.guid, parsedForm)
    if (!updatedEntityResponse?.guid) {
      const errors = formatApiErrorResponse(updatedEntityResponse)
      // @ts-ignore
      form.setFields(errors)
    } else {
      setPopoverVisible(false)
    }
  }

  return (
    <div>
      <Title level={4} className={styles.section_header}>
        <div className={styles.section_title}>{t('details.details')}</div>
        {userType === 'ENTITY' && (
          <Modal
            placement="left"
            content={
              <EntityDetailsForm
                onSubmit={onSubmit}
                entity={entity}
                action={t2('buttons.update')}
                upsertLoading={loading}
              />
            }
            destroyTooltipOnHide
            trigger="click"
            visible={popoverVisible}
            onVisibleChange={(visible) => setPopoverVisible(visible)}
          >
            <Button type="link" size="large" className={styles.section_action}>
              {t2('buttons.edit')}
            </Button>
          </Modal>
        )}
      </Title>
      <div className={styles.section_row}>
        <Text>{t('common.name')}</Text>
        <Text className={styles.section_value}>{entity.title}</Text>
      </div>
      <div className={styles.section_row}>
        <Text>
          {t('common.tin')} ({entity.tinKind})
        </Text>
        <Text className={styles.section_value}>{formatTin(entity.tin, entity.tinKind)}</Text>
      </div>
      {entity.kind && (
        <div className={styles.section_row}>
          <Text>{t('common.kind')}</Text>
          <Text className={styles.section_value}>{entity.kind.title}</Text>
        </div>
      )}
      {entity.address && (
        <div className={styles.section_row_multiline}>
          <Text>{t('common.address')}</Text>
          <Text className={styles.section_value_address}>{serializeAddress(entity.address)}</Text>
        </div>
      )}
      {entity.naicsCode && (
        <div className={styles.section_row}>
          <Text>{t('common.naicsCode')}</Text>
          <Text className={styles.section_value}>{entity.naicsCode}</Text>
        </div>
      )}
      {entity.businessStartedDate && (
        <div className={styles.section_row}>
          <Text>{t('common.startDate')}</Text>
          <Text className={styles.section_value}>{entity.businessStartedDate}</Text>
        </div>
      )}
      {entity.totalAssets && (
        <div className={styles.section_row}>
          <Text>{t('common.totalAssets')}</Text>
          <Text className={styles.section_value}>{entity.totalAssets}</Text>
        </div>
      )}
    </div>
  )
}

export default Details
