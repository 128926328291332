import { useCallback, useEffect, useRef, useMemo } from 'react'
import { Col, Dropdown, Form, Menu, Row, Select } from 'antd'
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import {
  TTaxDeadline,
  TTaxOrganizersParams,
  TTaxSummaryParams,
  useDashboard,
  useDashboardActions,
} from 'context/Dashboard'

import { Button } from 'components'

import useDate from 'hooks/useDate'
import useUserType from 'hooks/useUserType'
import useMediaQuery from 'hooks/useMediaQuery'

import {
  DEFAULT_SUMMARY_PARAMS,
  DEFAULT_TAX_DEADLINES_PARAMS,
  DEFAULT_TAX_PARAMS,
} from '../../constants'

import styles from '../../dashboard.module.scss'
import Contacts from './components/Contacts'

type TProps = {
  loading: boolean
  setSummaryParams: (
    value: TTaxSummaryParams | ((prevState: TTaxSummaryParams) => TTaxSummaryParams)
  ) => void
  setTaxParams: (
    value: TTaxOrganizersParams | ((prevState: TTaxOrganizersParams) => TTaxOrganizersParams)
  ) => void
  setShowTable: (state: boolean) => void
}

const Filters = ({ loading, setSummaryParams, setTaxParams, setShowTable }: TProps) => {
  const setUpStatus = useRef<'START' | 'END'>('START')
  const { t } = useTranslation('entity', { keyPrefix: 'common' })
  const { t: t2 } = useTranslation('dashboard', { keyPrefix: 'info' })
  const minWidthMd = useMediaQuery('md')
  const minWidthLg = useMediaQuery('lg')
  const [form] = Form.useForm()
  const userType = useUserType()
  const { getDate } = useDate()
  const {
    deadlines,
    deadline: deadlineGlobal,
    taxYear: selectedYear,
    taxYears: { range: taxYears },
  } = useDashboard()
  const { setDeadline, setTaxYear, loadTaxDeadlines } = useDashboardActions()
  const summaryTotal = useMemo(
    () =>
      deadlines.reduce((acc, cur) => {
        acc += Number(cur.taxOrganizerCount)
        return acc
      }, 0),
    [deadlines]
  )

  const marginBottom = useCallback(
    (margin: string) => {
      if (userType === 'ENTITY' && minWidthMd) {
        return 0
      }
      if (userType === 'CPA' && minWidthLg) {
        return 0
      }
      return margin
    },
    [userType, minWidthMd, minWidthLg]
  )

  const handleDeadlineChange = (value: string) => {
    const deadline = value === 'default' ? undefined : value
    setShowTable(false)
    setDeadline(value === 'default' ? undefined : value)
    setTaxParams((prevParams: TTaxOrganizersParams) => ({
      ...prevParams,
      deadline,
      phase: undefined,
      page: DEFAULT_TAX_PARAMS.page,
      pageSize: DEFAULT_TAX_PARAMS.pageSize,
    }))
    setSummaryParams((prevParams: TTaxSummaryParams) => ({
      ...prevParams,
      deadline,
      phase: undefined,
      page: DEFAULT_TAX_PARAMS.page,
      pageSize: DEFAULT_TAX_PARAMS.pageSize,
    }))
  }

  const handleYearChange = (taxYear: number) => {
    form.setFieldsValue({ deadline: 'default', primaryCpaContact: 'default' })
    setShowTable(false)
    setTaxYear(taxYear)
    setDeadline()
    loadTaxDeadlines({ ...DEFAULT_TAX_DEADLINES_PARAMS, taxYear })
    setTaxParams((prevParams: TTaxOrganizersParams) => ({
      ...prevParams,
      taxYear,
      deadline: undefined,
      primaryCpaContact: undefined,
      phase: undefined,
      page: DEFAULT_TAX_PARAMS.page,
      pageSize: DEFAULT_TAX_PARAMS.pageSize,
    }))
    setSummaryParams((prevParams: TTaxSummaryParams) => ({
      ...prevParams,
      taxYear,
      deadline: undefined,
      cpaContact: undefined,
      phase: undefined,
      page: DEFAULT_TAX_PARAMS.page,
      pageSize: DEFAULT_TAX_PARAMS.pageSize,
    }))
  }

  useEffect(() => {
    form.setFieldsValue({ deadline: deadlineGlobal.selected?.guid ?? 'default' })
  }, [deadlineGlobal])

  useEffect(() => {
    if (setUpStatus.current === 'START') {
      const prevYear = new Date().getFullYear() - 1
      setDeadline()
      setTaxYear(prevYear)
      setTaxParams({ ...DEFAULT_TAX_PARAMS, taxYear: prevYear })
      setSummaryParams({
        ...DEFAULT_SUMMARY_PARAMS,
        taxYear: prevYear,
      })
      setUpStatus.current = 'END'
    }
  }, [deadlines])

  return (
    <>
      <Row className={styles.filters}>
        <Col
          xs={{ span: 24 }}
          md={{ span: userType === 'CPA' ? 12 : 8 }}
          lg={{ span: userType === 'CPA' ? 8 : 8 }}
          xl={{ span: userType === 'CPA' ? 8 : 8 }}
          className={styles.filters_total}
          style={{ marginBottom: marginBottom('10px') }}
        >
          <div>
            {t2('totalReturns')}: {summaryTotal}
          </div>
        </Col>
        <Col
          xs={{ span: 24 }}
          md={{ span: userType === 'CPA' ? 12 : 8 }}
          lg={{ span: userType === 'CPA' ? 8 : 8 }}
          xl={{ span: userType === 'CPA' ? 8 : 8 }}
          className={styles.filters_title}
          style={{ marginBottom: marginBottom('10px') }}
        >
          <div className={styles.filters_icons}>
            <Button
              type="text"
              icon={<CaretLeftOutlined />}
              onClick={() => handleYearChange(selectedYear! - 1)}
              disabled={selectedYear === taxYears[0]}
            />
            <div className={styles.filters_label}>
              <Dropdown
                placement="bottomCenter"
                trigger={['click']}
                overlay={
                  <Menu theme="light">
                    {taxYears.map((taxYear) => {
                      return (
                        <Menu.Item
                          onClick={() => handleYearChange(taxYear)}
                          key={taxYear}
                          disabled={selectedYear === taxYear}
                          className={
                            selectedYear === taxYear
                              ? 'ant-dropdown-menu-item-active text-center'
                              : 'text-center'
                          }
                        >
                          {taxYear}
                        </Menu.Item>
                      )
                    })}
                  </Menu>
                }
              >
                <Button type="text" disabled={loading}>
                  <span>{t('taxYear')}</span>&nbsp;{selectedYear}
                </Button>
              </Dropdown>
            </div>
            <Button
              type="text"
              icon={<CaretRightOutlined />}
              onClick={() => handleYearChange(selectedYear! + 1)}
              disabled={selectedYear === taxYears[taxYears.length - 1]}
            />
          </div>
        </Col>
        <Col
          xs={24}
          md={userType === 'CPA' ? 24 : 8}
          lg={userType === 'CPA' ? 8 : 8}
          xl={{ span: userType === 'CPA' ? 8 : 4, offset: userType === 'CPA' ? 0 : 4 }}
          className={styles.filters_form}
        >
          <Form form={form} style={{ width: '100%' }}>
            <Row
              gutter={[
                { xs: 0, sm: 4, md: 8, lg: 16 },
                { xs: 4, sm: 0 },
              ]}
            >
              <Col xs={24} sm={userType === 'CPA' ? 12 : 24}>
                <Form.Item
                  style={{ marginBottom: 0 }}
                  name="deadline"
                  key="deadline"
                  initialValue="default"
                >
                  <Select onChange={handleDeadlineChange}>
                    <Select.Option key="default">
                      <span>{t2('allDeadlines')}</span>
                    </Select.Option>
                    {deadlines.map((_: TTaxDeadline) => (
                      <Select.Option key={_.guid}>
                        <span style={{ fontWeight: _.taxOrganizerCount ? '600' : '400' }}>
                          {getDate(_?.dueDate, 'MMM d, yyyy')} ({_.taxOrganizerCount})
                        </span>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {userType === 'CPA' && (
                <Contacts
                  setShowTable={setShowTable}
                  setTaxParams={setTaxParams}
                  setSummaryParams={setSummaryParams}
                />
              )}
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  )
}

export default Filters
