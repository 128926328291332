import { IAction } from '../types'

import { entityReducer, initialEntityState } from './entity'
import { documentsReducer, initialDocumentsState } from './documents'
import { IEntityState, IDocumentsState } from '..'

export type IEntityContext = {
  entity: IEntityState
  entityDocuments: IDocumentsState
}

const initialState = {
  entity: initialEntityState,
  entityDocuments: initialDocumentsState,
}

const mainReducer = ({ entity, entityDocuments }: IEntityContext, action: IAction) => ({
  entity: entityReducer(entity, action),
  entityDocuments: documentsReducer(entityDocuments, action),
})

export { initialState, mainReducer }
