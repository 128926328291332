import { Avatar } from 'antd'
import { useTranslation } from 'react-i18next'
import { TCPAEntityContact } from 'context/TaxOrganizer'

import styles from '../../taxteam.module.scss'

const CPAEntityContact = ({ user, jobTitle }: Omit<TCPAEntityContact, 'isPrimary'>) => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'overview.contacts' })

  if (!user) return null

  return (
    <div className={styles.contact} key={user.guid}>
      <div className={styles.contact_avatar}>
        {user.avatar ? (
          <Avatar size={40} src={user.avatar}>
            {user}
          </Avatar>
        ) : (
          <Avatar size={40}>
            {user?.firstName?.[0]}
            {user?.lastName?.[0]}
          </Avatar>
        )}
      </div>
      <div className={styles.contact_description}>
        <div className={styles.contact_name}>
          {user.firstName} {user.lastName}
        </div>
        <div className={styles.contact_title}>{jobTitle?.label ?? t('noJobTitle')}</div>
      </div>
    </div>
  )
}

export default CPAEntityContact
