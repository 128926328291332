import { useTranslation } from 'react-i18next'
import { Box, Flex } from 'reflexbox'
import cn from 'classnames'
import { enableTaxReturn } from 'utils/featureFlags'

import { useTaxReturnsActions, useTaxReturns } from 'context/TaxReturns'
import { useTaxOrganizer } from 'context/TaxOrganizer'

import { ReactComponent as PlayButton } from 'assets/images/play_button.svg'
import { ReactComponent as Logo } from 'assets/images/repositax_logo_white.svg'

import { Badge, Button, SpinnerBackdrop, Section } from 'components'
import Cards from './components/Cards'
import Title from '../../components/Title'

import { ESteps } from '../../steps'

import styles from '../../dashboard.tax_return.module.scss'

const steps: Readonly<string[]> = ['1', '2', '3', '4', '5', '6']

const Info = () => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'taxReturns.info' })
  const { setStep, createTaxReturn, loading: createLoading } = useTaxReturnsActions()
  const { updateTaxReturn, loading: updateLoading } = useTaxReturnsActions()
  const { loadTaxReturns, loading: loadLoading } = useTaxReturnsActions()
  const {
    taxOrganizer: {
      data: { guid },
    },
  } = useTaxOrganizer()
  const {
    state: {
      taxReturns,
      taxReturns: { latest: latestTaxReturn },
    },
  } = useTaxReturns()

  const onGetStartedClick = async () => {
    if (!taxReturns.data.length) {
      await createTaxReturn(guid)
      await loadTaxReturns(guid)
    } else {
      await updateTaxReturn(latestTaxReturn!.guid, { ...latestTaxReturn, phase: 'UPLOAD' })
    }
    setStep(ESteps.UPLOAD, 'push')
  }

  const systemStep = (text: string, index: number) => {
    return (
      <div className={styles.tax_return_step}>
        <span className={styles.tax_return_step_badge}>
          <Badge count={index + 1} scheme="llgray" />
        </span>
        <span className={styles.tax_return_step_text}>{t(`steps.${text}`)}</span>
      </div>
    )
  }

  return (
    <Section>
      {(createLoading || updateLoading || loadLoading) && <SpinnerBackdrop />}
      <Flex
        justifyContent={['space-between']}
        alignItems={['baseline']}
        flexDirection={['column', 'row']}
      >
        <Title text={t('title')} />
        <Box width={['100%', '228px']} mt={[2, 0]}>
          {enableTaxReturn ? (
            <Button
              type="primary"
              size="large"
              loading={createLoading}
              block
              onClick={onGetStartedClick}
            >
              {t('getStarted')}
            </Button>
          ) : (
            <Button type="primary" size="large" block disabled>
              {t('comingSoon')}
            </Button>
          )}
        </Box>
      </Flex>
      <Flex flexWrap="wrap">
        <Box width={['100%', '100%', '60%']}>
          <div className={cn(styles.tax_return_steps_list)}>
            <div key={steps[0]}>{systemStep(steps[0], 0)}</div>
            <Cards />
            {steps.reduce((acc: any, cur: any, index: number) => {
              if (index !== 0) {
                acc.push(<div key={cur}>{systemStep(cur, index)}</div>)
              }
              return acc
            }, [])}
          </div>
        </Box>
        <Box width={['100%', '100%', '40%']} my={[4]}>
          <Flex justifyContent={['center']} alignItems="flex-start" px={[3]}>
            <div className={styles.tax_return_video}>
              <PlayButton />
              <Logo className={styles.tax_return_logo} />
            </div>
          </Flex>
        </Box>
      </Flex>
    </Section>
  )
}

export default Info
