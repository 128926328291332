export enum ActionTypes {
  LOAD_FIRM = 'LOAD_FIRM',
  LOAD_MEMBERS = 'LOAD_MEMBERS',
  INVITE_MEMBERS = 'INVITE_MEMBERS',
  LOAD_FIRM_INVITES = 'LOAD_FIRM_INVITES',
  LOAD_PTIN = 'LOAD_PTIN',
  CLEAR_PTIN = 'CLEAR_PTIN',
  LOAD_TRADE_SHOW = 'LOAD_TRADE_SHOW',
  LOAD_CPA_JOB_TITLES = 'LOAD_CPA_JOB_TITLES',
  LOAD_CPA_FIRM_USER_ROLES = 'LOAD_CPA_FIRM_USER_ROLES',
}
