import { Modal } from 'antd'
import { useInterval } from 'hooks/useInterval'
import useUserType from 'hooks/useUserType'
import { useTaxOrganizer, useTaxOrganizerActions } from 'context/TaxOrganizer'
import { isEngagementLetterSigned } from 'utils/engagementLetter'
import { useTranslation } from 'react-i18next'
import styles from '../tax.timeline.module.scss'

type Props = {
  source: string
  setSource: (value: string | null) => void
  setError: (value: string | null) => void
}

const EngagementLetterSigningWidget = ({ source, setSource, setError }: Props) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'engagementLetter' })
  const { loadEngagementLetterSigningInformation, response } = useTaxOrganizerActions()
  const {
    taxOrganizer: {
      data: { guid },
    },
  } = useTaxOrganizer()
  const userType = useUserType()

  useInterval(async () => {
    const letterSigningInformation = await loadEngagementLetterSigningInformation(guid)
    if (response.ok) {
      if (isEngagementLetterSigned(letterSigningInformation, userType)) {
        setSource(null)
      }
    } else {
      setError(letterSigningInformation.msg)
      setSource(null)
    }
  }, 2000)

  return (
    <Modal
      title={null}
      centered
      visible
      onCancel={() => setSource(null)}
      className={styles.engagement_letter_wrapper}
      bodyStyle={{ padding: 0 }}
      closable
      footer={null}
    >
      <div className={styles.engagement_letter_widget}>
        <iframe frameBorder={0} title={t('title')} src={source} />
      </div>
    </Modal>
  )
}

export default EngagementLetterSigningWidget
