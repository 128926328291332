import { Section } from 'components'
import { Tabs } from 'antd'
import { Box } from 'reflexbox'
import { useTranslation } from 'react-i18next'

import { TEntity } from 'context/Entity'

import useUserEntityPermissions from 'hooks/useUserEntityPermissions'
import useUserType from 'hooks/useUserType'

import List from './List'
import Pending from './Pending'

import styles from '../dashboard.entity.management.module.scss'

type TProps = { entity: TEntity }

const Users = ({ entity }: TProps) => {
  const { t } = useTranslation('entity')
  const { userHasInvitePermissions } = useUserEntityPermissions()
  const userType = useUserType()

  return (
    <Section>
      <Tabs defaultActiveKey="list" className={styles.tabs} destroyInactiveTabPane>
        <Tabs.TabPane
          tab={
            <Box fontSize={['18px']} fontWeight={['600']}>
              {t('navigation.users')}
            </Box>
          }
          key="list"
        >
          <List entity={entity} />
        </Tabs.TabPane>
        {userType === 'ENTITY' && userHasInvitePermissions && (
          <Tabs.TabPane
            tab={
              <Box fontSize={['18px']} fontWeight={['600']}>
                {t('navigation.pending')}
              </Box>
            }
            key="pending"
          >
            <Pending entity={entity} />
          </Tabs.TabPane>
        )}
      </Tabs>
    </Section>
  )
}

export default Users
