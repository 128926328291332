import { useTranslation } from 'react-i18next'
import { ColumnsType } from 'antd/lib/table'

import { getEntityUserRolesLocal } from 'context/Entity/types'
import { Avatar, Typography } from 'antd'
import { getEntityUserRole } from 'utils/entity'
import { getInitials } from 'utils'
import styles from '../../../../dashboard.entity.management.module.scss'

const { Text } = Typography

export default () => {
  const { t } = useTranslation('entity')

  const entityUserRoles = getEntityUserRolesLocal(t)

  const columns: ColumnsType<IClientInvite> = [
    {
      title: t('common.firstName'),
      key: 'user.firstName',
      render: ({ user }: ICpaContact) => (
        <div className="text-nowrap">
          {user.avatar ? (
            <Avatar size={24} src={user.avatar} />
          ) : (
            <Avatar size={24}>{getInitials(user)}</Avatar>
          )}
          <Text className={styles.section_name}>{user.firstName}</Text>
        </div>
      ),
    },
    {
      title: t('common.lastName'),
      key: 'user.lastName',
      render: ({ user }: ICpaContact) => <Text>{user.lastName}</Text>,
    },
    {
      title: t('common.email'),
      key: 'user.email',
      render: ({ user }: ICpaContact) => <Text>{user.email}</Text>,
    },
    {
      title: t('common.role'),
      key: 'role',
      render: ({ role }: ICpaContact) => (
        <Text className={styles.section_value}>
          {getEntityUserRole(entityUserRoles, role)?.label}
        </Text>
      ),
    },
  ]

  return { columns }
}
