import { ChangeEvent, SetStateAction, useEffect, useMemo, useState } from 'react'
import { FilterOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { debounce, intersection, isEmpty, keys, pick } from 'lodash'
import { Form } from 'antd'
import { Box, Flex } from 'reflexbox'

import { Button, TextField, Modal, Badge } from 'components'

import {
  TPrimaryContact,
  TTaxOrganizerPhaseSummary,
  TTaxOrganizersParams,
  useDashboard,
  useDashboardActions,
} from 'context/Dashboard'
import { useEntity } from 'context/Entity'

import useUserType from 'hooks/useUserType'

import AddTaxOrganizerForm from '../AddTaxOrganizerForm'
import FiltersModalContent from './FiltersModalContent'

import { DEFAULT_PARAM_FILTERS, DEFAULT_FILTERS, FILTERS } from '../constants'
import { TFilters } from '../types'

type TProps = {
  handleSearch: (event: ChangeEvent<HTMLInputElement>) => void
  reloadTaxOrganizers: () => void
  params: TTaxOrganizersParams
  setParams: (params: SetStateAction<TTaxOrganizersParams>) => void
  setCurrentPage: (page?: number) => void
}
const Head = ({ handleSearch, reloadTaxOrganizers, params, setParams, setCurrentPage }: TProps) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'taxOrganizers.head' })
  const { t: t3 } = useTranslation('common')
  const isMobile = useMediaQuery({ query: '(max-width: 40em)' })
  const {
    entity: { currentEntity },
  } = useEntity()
  const {
    primaryContacts: { data: primaryContacts },
    summary: { phases },
    taxYears: { options: taxYearsOptions },
  } = useDashboard()
  const { getPrimaryContacts, getSummary } = useDashboardActions()
  const [addTaxOrganizerVisible, setAddTaxOrganizerVisible] = useState(false)
  const [modalFiltersVisible, setModalFiltersVisible] = useState<boolean>(false)
  const [filters, setFilters] = useState<TFilters>(DEFAULT_FILTERS)
  const [form] = Form.useForm()
  const userType = useUserType()

  const filtersCount = useMemo(() => keys(filters).length, [filters])
  const activeFiltersCount = useMemo(() => intersection(keys(params), FILTERS).length, [params])

  const phaseOptions = useMemo(() => {
    return phases.map((paseOption: TTaxOrganizerPhaseSummary): TOption => {
      return {
        label: t3(`phases.${paseOption.phase}`),
        value: paseOption.phase,
      }
    })
  }, [phases])

  const primaryContactsOptions = useMemo(() => {
    return primaryContacts?.map(({ firstName, lastName, guid }: TPrimaryContact) => ({
      label: `${firstName} ${lastName}`,
      value: guid,
    }))
  }, [primaryContacts])

  const handleVisibleChange = (isVisible: boolean) => {
    if (isVisible) {
      const newFilters = pick(params, FILTERS)
      setFilters(newFilters)
      if (isEmpty(newFilters)) {
        form.setFieldsValue(DEFAULT_PARAM_FILTERS)
      } else {
        form.setFieldsValue(newFilters)
      }
    }
    setModalFiltersVisible(isVisible)
  }

  const debouncedTextChangeHandler = useMemo(() => debounce(handleSearch, 300), [])

  useEffect(() => {
    return () => {
      debouncedTextChangeHandler.cancel()
    }
  }, [])

  useEffect(() => {
    if (userType === 'CPA') {
      getPrimaryContacts()
    }
    getSummary()
  }, [userType])

  return (
    <>
      <Flex
        flexDirection={['column', 'row']}
        width={['100%']}
        justifyContent={['space-between']}
        mb={[3]}
        alignItems={['center']}
      >
        <Flex width={['100%']} my={[1, 0]} justifyContent="flex-start" mr={[1]}>
          <Box mr={[2]}>
            <Modal
              placement="leftBottom"
              content={
                <FiltersModalContent
                  form={form}
                  setParams={setParams}
                  setModalFiltersVisible={setModalFiltersVisible}
                  disableButton={!filtersCount && !activeFiltersCount}
                  filters={filters}
                  setFilters={setFilters}
                  setCurrentPage={setCurrentPage}
                  taxYearsOptions={taxYearsOptions}
                  phaseOptions={phaseOptions}
                  primaryContactsOptions={primaryContactsOptions}
                />
              }
              trigger="click"
              visible={modalFiltersVisible}
              onVisibleChange={handleVisibleChange}
              showCloseIcon={false}
            >
              <Badge count={activeFiltersCount} scheme="purple" size="small">
                <Button icon={<FilterOutlined />} />
              </Badge>
            </Modal>
          </Box>{' '}
          <Box width={['100%', '100%', '250px']}>
            {' '}
            <TextField
              placeholder={t('search')}
              size="middle"
              suffix={<SearchOutlined />}
              onChange={debouncedTextChangeHandler}
              allowClear
            />
          </Box>
        </Flex>
        <Box width={['100%', '184px']} flexShrink="0">
          <Modal
            placement={isMobile ? 'bottom' : 'leftBottom'}
            content={
              <AddTaxOrganizerForm
                setVisible={setAddTaxOrganizerVisible}
                reloadTaxOrganizers={reloadTaxOrganizers}
                currentEntity={currentEntity}
              />
            }
            trigger="click"
            destroyTooltipOnHide
            visible={addTaxOrganizerVisible}
            onVisibleChange={(visible) => setAddTaxOrganizerVisible(visible)}
          >
            <Button type="primary" block size="large">
              <PlusOutlined />
              {t('add')}
            </Button>
          </Modal>
        </Box>
      </Flex>
    </>
  )
}

export default Head
