import { useTranslation } from 'react-i18next'

import { Button, QuestionIcon } from 'components'

import styles from '../../../tax.question.module.scss'

type Props = {
  info: string
}

const Help = ({ info }: Props) => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'questionnaire.help' })
  return (
    <QuestionIcon
      className={styles.item_question}
      popoverProps={{
        placement: 'bottom',
        content: (
          <div>
            {info}
            <div style={{ marginTop: 30 }}>
              <Button type="primary" size="large" style={{ minWidth: 152 }}>
                {t('contactCpa')}
              </Button>
            </div>
          </div>
        ),
        arrowPointAtCenter: false,
        title: (
          <div className={styles.item_question_title}>
            <QuestionIcon /> {t('info')}
          </div>
        ),
      }}
    />
  )
}

export default Help
