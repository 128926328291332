import { useState } from 'react'
import { Form, message, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import { getFirstError } from 'utils'

import { useEntityActions, TEntity } from 'context/Entity'
import { ErrorResponse, TaxPreparerInviteForm } from 'context/Entity/types'

import { Button, TextField, Textarea } from 'components'

import styles from '../../dashboard.entity.management.module.scss'

const { Title, Paragraph } = Typography

type Props = {
  entity: TEntity
  setVisible: (visible: boolean) => void
  setReloadFlag: (flag: boolean | ((flag: boolean) => boolean)) => void
}

const InviteTaxFirmForm = ({ entity, setVisible, setReloadFlag }: Props) => {
  const [form] = Form.useForm()
  const [errorMap, setErrorMap] = useState<ErrorResponse>({})
  const { inviteEntityTaxFirm, loading, response } = useEntityActions()
  const { t } = useTranslation('entity')
  const { t: t2 } = useTranslation('common')

  const onSubmit = async (payload: TaxPreparerInviteForm) => {
    await inviteEntityTaxFirm(entity?.guid, payload)
    if (!response.ok) {
      setErrorMap(response.data)
    } else {
      message.success(t2('messages.success.invitationsSent'))
      setVisible(false)
      setReloadFlag((flag: boolean) => !flag)
    }
  }

  return (
    <Form form={form} onFinish={onSubmit} className={styles.form_invite}>
      <Title className={styles.form_title} level={4}>
        {t('taxFirm.inviteTaxPreparer')}
      </Title>
      <div className={styles.form_fields}>
        <Paragraph className={styles.form_label}>Email</Paragraph>
        <Form.Item
          rules={[
            { required: true, message: t2('form.requiredField', { field: t2('form.email') }) },
            { type: 'email', message: t2('form.invalidField', { field: t2('form.email') }) },
          ]}
          name="email"
          key="email"
          extra={
            errorMap?.email && (
              <Paragraph className={styles.form_error}>{getFirstError(errorMap.email)}</Paragraph>
            )
          }
          validateStatus={errorMap?.email && 'error'}
        >
          <TextField placeholder={t('common.email')} size="middle" />
        </Form.Item>
        <Paragraph className={styles.form_label}>{t('common.message')}</Paragraph>
        <Form.Item
          initialValue={null}
          name="message"
          key="message"
          rules={[
            { required: true, message: t2('form.requiredField', { field: t2('form.message') }) },
          ]}
          extra={
            errorMap?.message && (
              <Paragraph className={styles.form_error}>{getFirstError(errorMap.message)}</Paragraph>
            )
          }
          validateStatus={errorMap?.message && 'error'}
        >
          <Textarea autoSize={{ minRows: 3 }} placeholder={t('common.addPersonalNote')} />
        </Form.Item>
        <div className={styles.form_action_row}>
          <Button
            className={styles.form_submit}
            type="primary"
            size="large"
            htmlType="submit"
            loading={loading}
            disabled={loading}
            block
          >
            {t2('buttons.invite')}
          </Button>
        </div>
      </div>
    </Form>
  )
}

export default InviteTaxFirmForm
