import { Col, Row } from 'antd'
import { useEffect } from 'react'

import { CPAFirmProvider } from 'context/CPAFirm'
import { useEntity } from 'context/Entity'

import { TTaxOrganizersParams, useDashboard, useDashboardActions } from 'context/Dashboard'

import { Section, SpinnerBackdrop } from 'components'
import TaxTeamEntityWrapper from './components/TaxTeamEntityWrapper'
import Details from './components/Details'
import Integrations from './components/Integrations'
import Users from './components/Users'
import CapitalHolders from './components/CapitalHolders'
import TaxOrganizers from './components/TaxOrganizers'
import DocumentsWidget from './components/DocumentsWidget'

import styles from '../dashboard.entity.management.module.scss'

type TProps = { entity: string }

// TODO: [REFACTORING]: SPLIT TO ENTITY AND CPA COMPONENTS
const Overview = ({ entity }: TProps) => {
  const DEFAULT_PARAMS: TTaxOrganizersParams = { entity, ordering: '-tax_year' }
  const {
    entity: { currentEntity },
  } = useEntity()
  const { loadTaxOrganizers, loading } = useDashboardActions()
  const { taxOrganizersList } = useDashboard()
  const reloadTaxOrganizers = () => loadTaxOrganizers(DEFAULT_PARAMS)

  useEffect(() => {
    loadTaxOrganizers(DEFAULT_PARAMS)
  }, [])

  if (!currentEntity) return <SpinnerBackdrop />

  return (
    <Row gutter={[20, 20]}>
      <Col xs={24} lg={14} className={styles.col_flex}>
        <Section>
          {loading && <SpinnerBackdrop />}
          <TaxOrganizers
            reloadTaxOrganizers={reloadTaxOrganizers}
            entity={currentEntity}
            taxOrganizers={taxOrganizersList}
          />
        </Section>
        <Section className={styles.section}>
          {loading && <SpinnerBackdrop />}
          <DocumentsWidget entityGuid={currentEntity.guid} />
        </Section>
      </Col>
      <Col xs={24} lg={10} className={styles.col_flex}>
        <CPAFirmProvider>
          <TaxTeamEntityWrapper entity={currentEntity} />
        </CPAFirmProvider>
        <Section className={styles.section}>
          {loading && <SpinnerBackdrop />}
          <Details entity={currentEntity} />
        </Section>
        <Section className={styles.section}>
          {loading && <SpinnerBackdrop />}
          <Users entity={currentEntity} />
        </Section>
        <Section disabled comingSoon>
          {loading && <SpinnerBackdrop />}
          <CapitalHolders />
        </Section>
        <Section disabled comingSoon className="d-none">
          {loading && <SpinnerBackdrop />}
          <Integrations />
        </Section>
      </Col>
    </Row>
  )
}

export default Overview
