import { Input, InputProps } from 'antd'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'

const PasswordField = (props: InputProps) => {
  return (
    <Input.Password
      {...props}
      iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
    />
  )
}

export default PasswordField
