import { Dropdown, Menu, Modal } from 'antd'
import { Link } from 'react-router-dom'
import { EllipsisOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { getEntityPathById } from 'utils/routes'

import { TTaxDocument, useEntity, useEntityDocumentsActions } from 'context/Entity'
import { Box } from 'reflexbox'

const { confirm } = Modal

type Props = {
  guid: string
  document: Partial<TTaxDocument>
}

const Actions = ({ document, guid }: Props) => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'documents' })
  const { deleteDocument } = useEntityDocumentsActions()
  const {
    entity: {
      currentEntity: { guid: currentEntityGuid },
    },
  } = useEntity()
  const { t: t2 } = useTranslation('common')

  const handleDelete = async () => {
    confirm({
      maskClosable: true,
      title: t('deleteConfirmation'),
      icon: <ExclamationCircleOutlined />,
      okText: t2('switcher.yes'),
      okType: 'danger',
      cancelText: t2('switcher.no'),
      onOk() {
        return new Promise((resolve) => {
          deleteDocument(currentEntityGuid, guid).then(() => resolve(true))
        })
      },
    })
  }

  return (
    <Dropdown
      placement="bottomRight"
      trigger={['click']}
      overlay={
        <Menu theme="dark">
          <Menu.Item key="Edit">
            <Link to={`${getEntityPathById(currentEntityGuid)}/documents/${document.guid}`}>
              <Box fontSize={['14px']}>{t('columns.actions.edit')}</Box>
            </Link>
          </Menu.Item>
          <Menu.Item key="Download">
            <Link to={{ pathname: document.file! }} target="_blank" download>
              {t('columns.actions.download')}
            </Link>
          </Menu.Item>
          <Menu.Item key="Delete" onClick={handleDelete}>
            {t('columns.actions.delete')}
          </Menu.Item>
        </Menu>
      }
    >
      <EllipsisOutlined style={{ transform: 'rotate(90deg)' }} />
    </Dropdown>
  )
}

export default Actions
