import { forwardRef } from 'react'
import { TPrintResume, TQuestionToPrint } from 'pages/TaxOrganizer/Questionnaire/types'
import logo from 'assets/images/logo.png'
import { useTranslation } from 'react-i18next'
import PrintQuestion from './PrintQuestion'
import PrintQuestionDocuments from './PrintQuestionDocuments'

type TProps = {
  dataToPrint: TPrintResume | undefined
}

const Print = forwardRef<HTMLDivElement, TProps>(({ dataToPrint }, ref) => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'questionnaire' })
  return (
    <div id="printID" ref={ref}>
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
          #printID {
            font-family: 'Inter', sans-serif;
          }    
        `}
      </style>
      <table>
        <thead>
          <tr>
            <th align="left">
              <img alt="Logo" src={logo} width="120" />
              <br />
              <br />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th align="center" key={dataToPrint?.entityName}>
              <div className="print-header">{dataToPrint?.entityName}</div>
            </th>
          </tr>
          <tr>
            <th align="center" key="Tax Organizer Questionnaire">
              <div className="print-header">{t('printTitle')}</div>
            </th>
          </tr>
          <tr>
            <th align="center" key={dataToPrint?.taxYear}>
              <div className="print-header">{dataToPrint?.taxYear}</div>
              <br />
            </th>
          </tr>
          {dataToPrint?.questions?.length &&
            dataToPrint?.questions?.map((question: TQuestionToPrint, l) => {
              return (
                <>
                  {/* eslint-disable-next-line */}
                  <tr key={`${question.category}tr${l}`}>
                    <td>
                      <br />
                      <br />
                      <div className="print-cat">{question?.category}</div>
                      {question?.questions?.[0] && (
                        <PrintQuestion question={question?.questions?.[0]} i={1} />
                      )}
                      {question?.questions?.[0]?.response &&
                        question?.questions?.[0]?.response.documents.length > 0 && (
                          <PrintQuestionDocuments
                            documents={question?.questions?.[0].response.documents}
                          />
                        )}
                    </td>
                  </tr>
                  {question?.questions?.length > 1 ? (
                    <>
                      {question?.questions?.map((_, i) => {
                        if (i === 0) return null
                        return (
                          <>
                            <tr>
                              <th>
                                <PrintQuestion question={_} i={i + 1} />
                              </th>
                            </tr>
                            <tr>
                              <th>
                                {_.response && _.response.documents.length > 0 && (
                                  <PrintQuestionDocuments documents={_.response.documents} />
                                )}
                              </th>
                            </tr>
                          </>
                        )
                      })}
                    </>
                  ) : (
                    <tr>
                      <th>
                        <i className="print-no-qst">{t('noQuestions')}</i>
                      </th>
                    </tr>
                  )}
                </>
              )
            })}
        </tbody>
      </table>
    </div>
  )
})

export default Print
