import { TFile } from 'context/TaxOrganizer'

export const getFileExtensionFromName = (fileName: string = '') => {
  return `.${fileName.split('.').pop()}`
}

export const checkIfFileTypeIsSupported = (file: TFile, allowedDocumentFileTypes: string[]) => {
  return allowedDocumentFileTypes.some((allowedFileType) => {
    return (
      (file?.type?.split('/')[0] === 'image' && allowedFileType === 'image/*') ||
      getFileExtensionFromName(file.name) === allowedFileType
    )
  })
}

export const convertFileToBase64 = (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      resolve(fileReader.result as string)
    }
    fileReader.onerror = (error) => {
      reject(error)
    }
  })
}

export const isPDF = (fileUrl: string | null) => /\.pdf$/i.test(fileUrl ?? '')
export const isImage = (fileUrl: string | null) => /\.(jpe?g)|(png)$/i.test(fileUrl ?? '')

export const splitFileName = (fileName: string) => {
  const matches = fileName.match(/^(.+?)(\.\w{2,4})$/i)
  if (!matches) {
    return {
      fileName,
      extension: '',
    }
  }
  return {
    fileName: matches[1],
    extension: matches[2],
  }
}
