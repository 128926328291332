import { Button, Modal } from 'components'
import { useState } from 'react'

import { useDocument, useDocumentActions } from 'context/Document'

import NewThreadForm from './NewThreadForm'
import styles from '../../documentview.module.scss'

type TProps = {
  number: number
  taxOrganizerId: string
  documentGuid: string
  contextKey: string
  taxReturnId?: string
  taxReturnPageId?: string
  setReloadThreads: (flag: boolean | ((newFlag: boolean) => boolean)) => void
}

const NewThread = ({
  number,
  taxOrganizerId,
  documentGuid,
  contextKey,
  taxReturnId,
  taxReturnPageId,
  setReloadThreads,
}: TProps) => {
  const {
    newThread: { x, y },
  } = useDocument()
  const { setNewThread } = useDocumentActions()
  const [visible, setVisible] = useState(true)

  return (
    <Modal
      content={
        <NewThreadForm
          contextKey={contextKey}
          taxOrganizerId={taxOrganizerId}
          documentGuid={documentGuid}
          taxReturnId={taxReturnId}
          taxReturnPageId={taxReturnPageId}
          setReloadThreads={setReloadThreads}
        />
      }
      visible={visible}
      placement="bottomLeft"
      overlayClassName={styles.popover}
      trigger="click"
      align={{ targetOffset: [30, 0] }}
      onVisibleChange={(nextVisible) => {
        setVisible(nextVisible)
        if (!nextVisible) setNewThread()
      }}
    >
      <div
        className={styles.thread}
        style={{ top: `${y}%`, left: `${x}%` }}
        onClick={(e) => e.stopPropagation()}
      >
        <Button type="primary" shape="circle" size="large">
          {number}
        </Button>
      </div>
    </Modal>
  )
}

export default NewThread
