import { useFetch } from 'use-http'

import { useFinancials } from './context'

import { ActionTypes } from './action-types'
import { EnumFinSoftware } from './types'

import data from './static'

const useFinancialsActions = () => {
  const { dispatch } = useFinancials()
  const { loading, error, response } = useFetch()

  const loadFinancialsSoftware = (slug: EnumFinSoftware | void) => {
    dispatch({
      type: ActionTypes.LOAD_FINANCIALS_SOFTWARE,
      payload: {
        data,
        financialSoftware: slug,
      },
    })
  }

  return {
    loadFinancialsSoftware,
    response,
    loading,
    error,
  }
}

export default useFinancialsActions
export { ActionTypes, useFinancialsActions }
