import { Button, ButtonProps } from 'antd'
import cn from 'classnames'

import './styles.scss'

type TProps = ButtonProps & {
  flex?: boolean
}

const Btn = ({ flex, ...rest }: TProps) => {
  return <Button {...rest} className={cn(rest?.className, { 'flex-button': flex })} />
}

export default Btn
