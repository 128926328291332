import { InviteProvider } from 'context/Invites'
import useUserType from 'hooks/useUserType'

import { Section } from 'components'

import You from './components/You'
import TaxFirm from './components/TaxFirm'
import Entities from './components/Entities'

import styles from './user.invitations.module.scss'

const Invitations = () => {
  const userType = useUserType()
  return (
    <InviteProvider>
      <div className="container">
        <Section className={styles.section}>
          <You />
        </Section>
        <Section className={styles.section}>
          {userType === 'CPA' ? <TaxFirm /> : <Entities />}
        </Section>
      </div>
    </InviteProvider>
  )
}

export default Invitations
