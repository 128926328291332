import { useEffect, useState } from 'react'
import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { Box, Flex } from 'reflexbox'
import { useParams } from 'react-router-dom'

import { TRightPart } from 'context/Financials'
import { useDocumentsActions, useTaxOrganizer } from 'context/TaxOrganizer'

import { Badge, Upload, UploadModal, SpinnerBackdrop } from 'components'

import styles from '../../dashboard.financials.module.scss'

import { defaultSettings } from '../default'
import File from '../File'

type TProps = {
  currentSteps: TRightPart
}

const SystemSteps = ({ currentSteps }: TProps) => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'financials' })
  const { id }: { id: string } = useParams()
  const {
    documents: { data, count },
  } = useTaxOrganizer()
  const { loadDocuments, loading: docsLoading } = useDocumentsActions()
  const { deleteDocument, loading } = useDocumentsActions()
  const [modal, setModal] = useState(false)
  const [files, setFiles] = useState([])
  const [fileId, setFileId] = useState<null | string>(null)
  const { tags, subCategory, category } = defaultSettings

  const handleUpload = ({ fileList }: any) => {
    setFiles(
      fileList.map((file: any) => ({
        ...file,
        defaultSettings,
      }))
    )
    setModal(true)
  }

  const handleChange = async (fileList: any) => {
    handleUpload(fileList)
  }

  const handleHide = () => {
    setModal(false)
    setFiles([])
    loadDocuments(id, { tags: tags?.[0], category: subCategory || category })
  }

  const handleDelete = async (documentGuid: string) => {
    setFileId(documentGuid)
    await deleteDocument(id, documentGuid)
    setFileId(null)
  }

  useEffect(() => {
    loadDocuments(id, { tags: tags?.[0], category: subCategory || category })
  }, [])

  return (
    <Box width={['100%', '100%', '100%', '50%']} mt={[4, 3]} pl={[2, 0]}>
      <Typography.Text className={styles.system_steps_title}>
        {t(`options.${currentSteps.title}`)}
      </Typography.Text>
      <Flex flexDirection={['column']} mt={[3]}>
        {currentSteps.steps.map((step: string, i: number) => {
          return (
            <Flex
              my={[2, 2, 2, '2%']}
              alignItems={['center']}
              flex="nowrap"
              flexShrink={[1]}
              key={step}
            >
              <Box className={styles.system_step_badge} mr={[2]}>
                <Badge count={i + 1} scheme="llgray" />
              </Box>
              <Typography.Text className={styles.system_step_text}>
                {t(`options.${currentSteps.steps[i]}`)}
              </Typography.Text>
            </Flex>
          )
        })}

        {currentSteps.upload && (
          <Box mt={[4]} style={{ position: 'relative' }}>
            <Box>
              {' '}
              <Typography.Text className={styles.system_steps_title}>
                {t('fileUpload')}
              </Typography.Text>
              <Box mt={[3]}>
                <Upload fileList={[]} onChange={handleChange} />
              </Box>
            </Box>
            {docsLoading && <SpinnerBackdrop />}
            {!!count && (
              <Flex my={[4]} flexDirection="column">
                <Typography.Text className={styles.system_steps_title}>
                  {t('filesUploaded')}
                </Typography.Text>
                <Flex flexWrap="wrap" mt="1">
                  {data.map((document) => (
                    <File
                      key={document.guid}
                      document={document}
                      loading={loading && fileId === document.guid}
                      handleDelete={handleDelete}
                    />
                  ))}
                </Flex>
              </Flex>
            )}
          </Box>
        )}
      </Flex>
      {modal && <UploadModal fileList={files} onHide={handleHide} hideFileEditing />}
    </Box>
  )
}

export default SystemSteps
