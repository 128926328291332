import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Row, Col } from 'antd'

import { useDashboardActions } from 'context/Dashboard'
import { useEntity } from 'context/Entity'
import { getEntityPathById } from 'utils/routes'

import { Alert, SpinnerBackdrop } from 'components'

import Navigation from 'pages/TaxOrganizer/Document/components/Navigation'
import useDocument from './hooks/useDocument'

import DocumentView from './components/DocumentView'
import Category from './components/Category'
import Tags from './components/Tags'
import DocumentModal from './components/ModalBody'
import Name from './components/Name'

import styles from './dashboard.document.module.scss'
import TaxOrganizerSelectView from './components/TaxOrganizerSelectView'

type Props = {
  toggleNavigation: (show: boolean) => void
}

const Document = ({ toggleNavigation }: Props) => {
  const { documentGuid } = useParams<{ documentGuid: string }>()
  const [modal, setModal] = useState(false)
  const { getDocument, loading, alert } = useDocument()
  const {
    entity: {
      currentEntity: { guid: currentEntityGuid },
    },
  } = useEntity()

  const { loadDocumentsTags, loadDocumentsCategories } = useDashboardActions()

  useEffect(() => {
    getDocument(currentEntityGuid, documentGuid)
    loadDocumentsTags()
    loadDocumentsCategories()
    toggleNavigation(false)
    return () => toggleNavigation(true)
  }, [])

  if (loading) return <SpinnerBackdrop size="large" mode="global" />

  return (
    <div className="container">
      <div className={styles.document}>
        <Navigation backPath={`${getEntityPathById(currentEntityGuid)}/documents`} />
        <Row gutter={[20, 0]}>
          <Col xs={24} lg={15}>
            {alert.status ? <Alert message={alert.message} type="error" /> : <DocumentView />}
          </Col>
          <Col xs={24} lg={9}>
            <Name disabled={false} />
            <TaxOrganizerSelectView disabled={false} />
            <Category disabled={false} />
            <Tags disabled={false} />
          </Col>
        </Row>
      </div>
      {modal && (
        <DocumentModal
          onHide={() => {
            setModal(false)
          }}
        />
      )}
    </div>
  )
}

export default Document
