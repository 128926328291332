import { useState } from 'react'
import { Section } from 'components'
import Header from './components/Header'
import Router from './components/Router'

const FirmTeam = () => {
  const [popoverStatus, setPopoverStatus] = useState(true)
  return (
    <div className="container">
      <Section>
        <Header setPopoverStatus={setPopoverStatus} />
        <Router popoverStatus={popoverStatus} />
      </Section>
    </div>
  )
}

export default FirmTeam
