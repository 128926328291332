import { ActionTypes } from './action-types'
import { TState, TAction } from './types'

export const initialState: TState = {
  stripePublishableKey: null,
  paymentMethod: null,
  subscription: null,
  invoices: [],
}

export const reducer = (state: TState = initialState, action: TAction) => {
  const {
    payload: { publicKey, subscription },
  } = action

  switch (action.type) {
    case ActionTypes.LOAD_BILLING_CONFIG:
      return {
        ...state,
        stripePublishableKey: publicKey,
      }

    case ActionTypes.LOAD_SUBSCRIPTION:
      return {
        ...state,
        paymentMethod: subscription?.paymentMethod,
        subscription: subscription?.subscription,
      }

    case ActionTypes.LOAD_INVOICES:
      return {
        ...state,
        invoices: action?.payload?.data ?? [],
      }

    default:
      return state
  }
}
