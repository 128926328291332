import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Elements } from '@stripe/react-stripe-js'
import { Box } from 'reflexbox'
import cn from 'classnames'
import { getCPAOnboardingPath } from 'utils/routes'

import { useAuth, useAuthActions } from 'context/Auth'

import usePayment from 'hooks/usePayment'

import { SpinnerBackdrop } from 'components'
import BillingForm from 'components/BillingForm'
import Title from '../components/Title'

import styles from './onboarding.billing.module.scss'

const Billing = () => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'firm' })
  const {
    CPA: { cpaFirm },
  } = useAuth()
  const { updateCpaFirm, response, loading: updateLoading } = useAuthActions()
  const { setOnboardingStep } = useAuthActions()
  const history = useHistory()
  const { stripePromise, paymentStatus, setPaymentStatus, loadingConfig } = usePayment()

  const handleNext = async () => {
    await updateCpaFirm(cpaFirm!.guid, { onboardingStatus: 'INVITE_USERS' })
    if (response.ok) {
      setOnboardingStep('INVITE_USERS')
      history.replace(getCPAOnboardingPath('INVITE_USERS'))
    }
  }

  useEffect(() => {
    if (paymentStatus === 'success') {
      handleNext()
    }
  }, [paymentStatus])

  return (
    <>
      {(loadingConfig || updateLoading) && <SpinnerBackdrop />}
      <Box mb={[3, 5]}>
        <Title title={t('onboarding.billing.title')}>
          <div className={styles.subtitles}>
            <div className={styles.subtitles_1}>{t('onboarding.billing.subtitle1')}</div>
            <div className={styles.subtitles_2}>{t('onboarding.billing.subtitle2')}</div>
          </div>
        </Title>
        <div className={cn(['container-xs', 'position-relative'])}>
          <Elements stripe={stripePromise}>
            <BillingForm setPaymentStatus={setPaymentStatus} handleSkip={handleNext} />
          </Elements>
        </div>
      </Box>
    </>
  )
}

export default Billing
