import { FC } from 'react'
import { Popover, PopoverProps } from 'antd'

import cn from 'classnames'
import './styles.scss'

type Props = {
  className?: string
  popoverProps?: PopoverProps
}

const QuestionIcon: FC<Props> = ({ className, popoverProps }) => {
  const icon = <div className={cn('question-icon', className)}>?</div>
  return popoverProps ? (
    <Popover {...popoverProps} overlayClassName="question-icon-popover">
      {icon}
    </Popover>
  ) : (
    icon
  )
}

export default QuestionIcon
