import { useState, useEffect } from 'react'

import { TFile } from 'context/TaxOrganizer'
import { useTaxReturnsActions, useTaxReturns } from 'context/TaxReturns'

import { TFileProgressSettings, TFileExtended } from 'components/UploadModal/types'
import { getProgress, setTotalProgressHelper } from 'components/UploadModal/helpers'

type TProps = {
  files: TFile[]
}

export default ({ files }: TProps) => {
  const [totalProgress, setTotalProgress] = useState<TFileProgressSettings[]>(
    files?.map(({ uid }: TFile) => ({
      uid,
      total: 0,
      loaded: 0,
      start: false,
    })) ?? []
  )
  const { createTaxReturnDocument } = useTaxReturnsActions()
  const {
    state: {
      taxReturns: { latest: latestTaxReturn },
    },
  } = useTaxReturns()
  useEffect(() => {
    if (latestTaxReturn) {
      const startUploadDocument = async (file: TFile) => {
        const fileParams = {
          file: file.name,
          name: file.name,
        }
        return createTaxReturnDocument(latestTaxReturn, fileParams, {
          file: file.originFileObj,
          onUploadProgress: (event: ProgressEvent) => {
            setTotalProgress((prevData: TFileProgressSettings[]) =>
              setTotalProgressHelper(prevData, file, event)
            )
          },
        })
      }
      if (files?.length) {
        files.forEach((file: TFileExtended) => {
          startUploadDocument(file)
        })
      }
    }
  }, [latestTaxReturn])
  const progress = getProgress(totalProgress, files)
  return { progress }
}
