import { useTranslation } from 'react-i18next'

import styles from '../../dashboard.financials.module.scss'

const Title = () => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'financials' })
  return <div className={styles.title}>{t('title')}</div>
}

export default Title
