import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { Flex } from 'reflexbox'

import Invitations from 'pages/Onboarding/Invitations'
import Logo from 'pages/Onboarding/Logo'
import Setup from 'pages/Onboarding/Setup'
import InviteClients from 'pages/Onboarding/InviteClients'
import InviteUsers from 'pages/Onboarding/InviteUsers'
import Billing from 'pages/Onboarding/Billing'
import Steps from 'pages/Onboarding/components/Steps'

const CPALayout = () => {
  const { path } = useRouteMatch()

  return (
    <>
      <Steps />
      <Flex
        className="container-md"
        flexGrow={[1]}
        flexDirection={['column']}
        justifyContent={['flex-start']}
        mx={['auto']}
        py={[1, 3]}
        minHeight={['auto', 'auto', '400px']}
      >
        <Switch>
          <Route path={`${path}/cpa/invitations`} exact>
            <Invitations />
          </Route>
          <Route path={`${path}/cpa/setup`} exact>
            <Setup />
          </Route>
          <Route path={`${path}/cpa/logo`} exact>
            <Logo />
          </Route>
          <Route path={`${path}/cpa/billing`} exact>
            <Billing />
          </Route>
          <Route path={`${path}/cpa/invite-users`} exact>
            <InviteUsers />
          </Route>
          <Route path={`${path}/cpa/invite-clients`} exact>
            <InviteClients />
          </Route>
        </Switch>
      </Flex>
    </>
  )
}

export default CPALayout
