import { useAuth } from 'context/Auth'
import { useEffect, useMemo } from 'react'
import { useDashboard, useDashboardActions } from 'context/Dashboard'
import { DefaultOptionType } from 'antd/lib/select'
import { taxPreparersOptions } from '../helpers'

const DEFAULT_PARAMS: Readonly<TParams> = { pageSize: 1000, page: 1 }

export default () => {
  const {
    CPA: { cpaFirm },
  } = useAuth()
  const { cpaAssocitatedUsers } = useDashboard()
  const { loadCPAFirmAssociatedUsers } = useDashboardActions()

  const cpaContactsOptions: DefaultOptionType[] = useMemo(() => {
    return cpaAssocitatedUsers.count ? taxPreparersOptions(cpaAssocitatedUsers.data) : []
  }, [cpaAssocitatedUsers])

  useEffect(() => {
    loadCPAFirmAssociatedUsers(cpaFirm!.guid, DEFAULT_PARAMS)
  }, [])

  return { cpaContactsOptions }
}
