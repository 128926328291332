import { useState, useEffect } from 'react'
import { Checkbox, Form, message, Tooltip } from 'antd'
import Icon, { MinusCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import { initForm, saveFormToSession } from 'utils/form'
import { browserSession } from 'utils/session'
import { getTaxFirmParams, getTaxFirmUserRoles } from 'utils/taxFirm'

import { useAuth } from 'context/Auth'
import { useCPAFirmActions, InviteMember, useCPAFirm } from 'context/CPAFirm'

import { Button, Select, TextField } from 'components'

import { ReactComponent as Plus } from 'assets/images/plus-1.svg'
import styles from '../../../../dashboard.firm.module.scss'

type TProps = {
  setVisiblePopover?: (visible: boolean) => void
}

const FormComponent = ({ setVisiblePopover }: TProps) => {
  const [form] = Form.useForm()
  const {
    CPA: { cpaFirm, userRole },
  } = useAuth()
  const { cpaUserRoles } = useCPAFirm()
  const { inviteMembers, loading, response } = useCPAFirmActions()
  const { t } = useTranslation('dashboard', { keyPrefix: 'firm.team' })
  const { t: t2 } = useTranslation('common')
  const { t: t3 } = useTranslation('entity')
  const [errors, setErrors] = useState<Partial<InviteMember>[]>([])
  const [transferOwnerships, showTransferOwnerships] = useState<InviteMember[]>([])
  const roleOptions = getTaxFirmUserRoles(cpaUserRoles, t2)

  const onSubmit = async ({
    dynamicFields,
    staticField,
  }: {
    dynamicFields: InviteMember[] | undefined
    staticField: InviteMember
  }) => {
    const params = getTaxFirmParams(dynamicFields, staticField)
    const result = await inviteMembers(cpaFirm!.guid, params)
    if (response.ok) {
      browserSession.removeItem('form:addTeamMember')
      setErrors([])
      message.success(t2('messages.success.invitationsSent'))
      if (setVisiblePopover && typeof setVisiblePopover === 'function') {
        setTimeout(() => setVisiblePopover(false), 500)
      }
    } else {
      setErrors(result)
    }
  }

  const handleValuesChange = (changedValues: any, allValues: any) => {
    saveFormToSession('form:addTeamMember', allValues)
    showTransferOwnerships(getTaxFirmParams(allValues?.dynamicFields, allValues?.staticField))
  }

  const handleRoleChange = (role: ICPAUserRole, i: number) => {
    if (role !== 'OWNER') {
      const savedSession = initForm('form:addTeamMember')
      if (i === 0) {
        const staticField = { staticField: { transferOwnership: undefined } }
        form.setFieldsValue(staticField)
        saveFormToSession('form:addTeamMember', {
          ...savedSession,
          ...staticField,
        })
      } else {
        const values = form.getFieldsValue()
        const updatedMembers = values.dynamicFields.map((member: any, j: number) => {
          if (j + 1 === i) {
            return {
              ...member,
              transferOwnership: undefined,
            }
          }

          return member
        })
        const dynamicFields = { dynamicFields: updatedMembers }
        form.setFieldsValue(dynamicFields)
        saveFormToSession('form:addTeamMember', { ...savedSession, ...dynamicFields })
      }
    }
  }

  useEffect(() => {
    const allValues = initForm('form:addTeamMember')
    showTransferOwnerships(getTaxFirmParams(allValues?.dynamicFields, allValues?.staticField))
  }, [])

  return (
    <>
      <Form
        onFinish={onSubmit}
        autoComplete="off"
        form={form}
        onValuesChange={handleValuesChange}
        initialValues={initForm('form:addTeamMember')}
      >
        <div className={styles.invite_fieldset}>
          <div className={styles.invite_fieldsubset}>
            <div className={styles.invite_field_email}>
              <div className={styles.invite_label}>{t('email')}</div>
              <Form.Item
                name={['staticField', 'email']}
                fieldKey={['staticField', 'email']}
                rules={[
                  {
                    required: true,
                    message: t2('form.requiredField', { field: t('email') }),
                  },
                  { type: 'email', message: t2('form.invalidField', { field: t('email') }) },
                ]}
                validateStatus={errors[0]?.email && 'error'}
                help={errors[0]?.email}
                style={{ width: '100%', margin: 0 }}
              >
                <TextField placeholder={t2('form.email')} />
              </Form.Item>
            </div>
            <div className={styles.invite_field_role}>
              <div className={styles.invite_label}>{t('role')}</div>
              <div className={styles.invite_flex}>
                <Form.Item
                  name={['staticField', 'role']}
                  fieldKey={['staticField', 'role']}
                  rules={[
                    {
                      required: true,
                      message: t2('form.requiredField', { field: t('role') }),
                    },
                  ]}
                  validateStatus={errors[0]?.role && 'error'}
                  help={errors[0]?.role}
                  style={{ width: '100%', margin: 0 }}
                >
                  <Select
                    placeholder={t2('form.choose')}
                    options={roleOptions}
                    style={{ width: '100%' }}
                    onChange={(role) => handleRoleChange(role, 0)}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          {transferOwnerships[0]?.role === 'OWNER' && userRole === 'OWNER' && (
            <div>
              <Tooltip title={t3('invitations.tooltipFirm')}>
                <Form.Item
                  name={['staticField', 'transferOwnership']}
                  fieldKey={['staticField', 'transferOwnership']}
                  valuePropName="checked"
                >
                  <Checkbox>{t3('invitations.checkbox')}</Checkbox>
                </Form.Item>
              </Tooltip>
            </div>
          )}
        </div>

        <Form.List name="dynamicFields">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }: any, index) => (
                <div key={key} className={styles.invite_fieldset}>
                  <div className={styles.invite_fieldsubset_child}>
                    <div className={styles.invite_fieldsubset}>
                      <div className={styles.invite_field_email}>
                        <Form.Item
                          {...restField}
                          name={[name, 'email']}
                          fieldKey={[fieldKey, 'email']}
                          rules={[
                            {
                              required: true,
                              message: t2('form.requiredField', { field: t('email') }),
                            },
                            {
                              type: 'email',
                              message: t2('form.invalidField', { field: t('email') }),
                            },
                          ]}
                          validateStatus={errors[index + 1]?.email && 'error'}
                          help={errors[index + 1]?.email}
                          style={{ width: '100%', margin: 0 }}
                        >
                          <TextField placeholder={t2('form.email')} />
                        </Form.Item>
                      </div>
                      <div className={styles.invite_field_role}>
                        <Form.Item
                          {...restField}
                          name={[name, 'role']}
                          fieldKey={[fieldKey, 'role']}
                          rules={[
                            {
                              required: true,
                              message: t2('form.requiredField', { field: t('role') }),
                            },
                          ]}
                          validateStatus={errors[index + 1]?.role && 'error'}
                          help={errors[index + 1]?.role}
                          style={{ width: '100%', margin: 0 }}
                        >
                          <Select
                            placeholder={t2('form.choose')}
                            options={roleOptions}
                            style={{ width: '100%' }}
                            onChange={(role) => handleRoleChange(role, index + 1)}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className={styles.invite_field_icon}>
                      <MinusCircleOutlined
                        onClick={() => {
                          remove(name)
                          setErrors([])
                        }}
                      />
                    </div>
                  </div>
                  {transferOwnerships[index + 1]?.role === 'OWNER' && userRole === 'OWNER' && (
                    <div>
                      <Tooltip title={t3('invitations.tooltipFirm')}>
                        <Form.Item
                          {...restField}
                          name={[name, 'transferOwnership']}
                          fieldKey={[fieldKey, 'transferOwnership']}
                          valuePropName="checked"
                        >
                          <Checkbox>{t3('invitations.checkbox')}</Checkbox>
                        </Form.Item>
                      </Tooltip>
                    </div>
                  )}
                </div>
              ))}
              <Form.Item style={{ width: '100%', margin: 0 }}>
                <Button
                  className={styles.invite_button_add}
                  type="ghost"
                  size="large"
                  onClick={() => add()}
                  icon={<Icon component={Plus} />}
                >
                  {t('add')}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <div className={styles.invite_button_submit}>
          <Button type="primary" size="large" htmlType="submit" loading={loading}>
            {t('invite')}
          </Button>
        </div>
      </Form>
    </>
  )
}

export default FormComponent
