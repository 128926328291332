import { FC, Dispatch, createContext, useReducer, useContext } from 'react'
import { useReducerLogger } from 'utils'

import { initialState, inviteReducer, IInviteState, IAction } from './reducer'

const InviteContext = createContext<IInviteState>({} as IInviteState)
const InviteDispatchContext = createContext<Dispatch<IAction>>(() => null)

export function useInvite() {
  const context = useContext(InviteContext)
  if (context === undefined) {
    throw new Error('useInvite must be used within an InviteProvider')
  }
  return context
}

export function useInviteDispatch() {
  const context = useContext(InviteDispatchContext)
  if (context === undefined) {
    throw new Error('useInviteDispatch must be used within an InviteProvider')
  }
  return context
}

export const InviteProvider: FC = ({ children }) => {
  const [data, dispatch] = useReducer(
    useReducerLogger<IInviteState, IAction>(inviteReducer),
    initialState
  )

  return (
    <InviteContext.Provider value={data}>
      <InviteDispatchContext.Provider value={dispatch}>{children}</InviteDispatchContext.Provider>
    </InviteContext.Provider>
  )
}
