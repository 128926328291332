import { format, isValid } from 'date-fns'
import useLocale from './useLocale'

export default () => {
  const locale = useLocale()

  const getDate = (date: string | number | Date | undefined, outputFormat: string) => {
    const dateIsValid = date && isValid(new Date(date))
    return dateIsValid
      ? format(new Date(date), outputFormat, {
          locale,
        })
      : ''
  }

  const isTodayDate = (date: string) =>
    getDate(date, 'yyyy-MM-dd') === getDate(new Date(), 'yyyy-MM-dd')

  return { getDate, isTodayDate }
}
