import { useEffect, useMemo, useState } from 'react'
import { Button } from 'components'
import { Form, Select, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { debounce, findIndex } from 'lodash'

import { useEntityActions, TEntity } from 'context/Entity'
import {
  TCPAFirmAssociatedUser,
  TCPAFirmAssociatedUsersParams,
  useDashboard,
  useDashboardActions,
} from 'context/Dashboard'

import { ReactComponent as Plus } from 'assets/images/big-cross.svg'
import Icon from '@ant-design/icons'

import styles from 'pages/Dashboard/EntityManagement/dashboard.entity.management.module.scss'
import { DEFAULT_USERS_PARAMS } from '../../constants'

const { Title } = Typography

type Props = {
  entity: TEntity
  cpaFirm: TCpaFirm
  setVisible: (visible: boolean) => void
  setReloadContactsFlag: (flag: boolean | ((prevFlag: boolean) => boolean)) => void
  reloadUsersFlag: boolean
}

const AddEntityCPAUsersForm = ({
  entity,
  cpaFirm,
  setVisible,
  setReloadContactsFlag,
  reloadUsersFlag,
}: Props) => {
  const [form] = Form.useForm()
  const { t } = useTranslation('entity')
  const { t: t2 } = useTranslation('common')
  const [selectedUsers, setSelectedUsers] = useState<TOption[]>([])
  const [usersParams, setUsersParams] = useState<TCPAFirmAssociatedUsersParams>({
    ...DEFAULT_USERS_PARAMS,
    excludeContactsOf: entity.guid,
  })
  const [options, setOptions] = useState<TOption[]>([])

  const {
    cpaAssocitatedUsers: { data: users },
  } = useDashboard()
  const { loadCPAFirmAssociatedUsers, response, loading: loadingUsers } = useDashboardActions()
  const { assignCPAUser, loading: loadingUser } = useEntityActions()

  const handleSearch = async (search: string) => {
    setUsersParams((prevParams: TParams) => ({ ...prevParams, search }))
  }

  const debouncedUserSearchHandler = useMemo(() => debounce(handleSearch, 300), [])

  const onSubmit = async () => {
    await Promise.all(
      selectedUsers.map((user) => assignCPAUser(entity?.guid, cpaFirm.guid, user.value))
    )
    setVisible(false)
    setReloadContactsFlag((flag: boolean) => !flag)
  }

  const handleChange = (value: string, option?: TOption | TOption[]) => {
    if (option && findIndex(selectedUsers, ['value', (option as TOption).value]) === -1) {
      setSelectedUsers((prevUsers) => [...prevUsers, option as TOption])
    }
  }

  const handleDeleteUser = (index: number) => {
    const newSelectedUsers = [...selectedUsers]
    newSelectedUsers.splice(index, 1)
    setSelectedUsers(newSelectedUsers)
  }

  const handleClear = () => {
    setUsersParams((prevParams: TParams) => ({ ...prevParams, search: undefined }))
  }

  useEffect(() => {
    loadCPAFirmAssociatedUsers(cpaFirm.guid, usersParams)
  }, [usersParams, reloadUsersFlag])

  useEffect(() => {
    if (users.length && response.ok) {
      setOptions(
        users.map(({ user }: TCPAFirmAssociatedUser) => ({
          label: `${user.firstName} ${user.lastName}`,
          value: user.guid,
        }))
      )
    }
  }, [users, response])

  useEffect(() => {
    return () => {
      debouncedUserSearchHandler.cancel()
    }
  }, [])

  return (
    <Form form={form} onFinish={onSubmit} className={styles.users_invite_wrapper}>
      <Title className={styles.form_title} level={5}>
        {t('taxFirm.addMembers')}
      </Title>
      <div>
        <div className={styles.users_search_team_wrapper}>
          <Select
            showSearch
            filterOption={false}
            className={styles.users_search_team}
            options={options}
            value=""
            onChange={handleChange}
            onSearch={debouncedUserSearchHandler}
            onClear={handleClear}
            allowClear
            loading={loadingUsers}
          />
          <Button
            disabled={selectedUsers.length === 0 || loadingUser}
            type="primary"
            onClick={onSubmit}
          >
            {t2('buttons.add')} {selectedUsers.length > 0 && `(${selectedUsers.length})`}
          </Button>
        </div>
        <div className={styles.users_selected_users}>
          {selectedUsers.map((user, index) => (
            <div
              key={user.value}
              onClick={() => handleDeleteUser(index)}
              className={styles.user_item}
            >
              {user.label} <Icon component={Plus} style={{ fontSize: 10 }} />
            </div>
          ))}
        </div>
      </div>
    </Form>
  )
}

export default AddEntityCPAUsersForm
