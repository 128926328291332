import { maxBy } from 'lodash'
import { useDocument, DocumentThread, ThreadContextType } from 'context/Document'

import NewThread from '../NewThread'
import Thread from '../Thread'

type Props = {
  threads: DocumentThread[]
  taxOrganizerId: string
  taxReturnId?: string
  taxReturnPageId?: string
  currentThreadReferenceNumber?: string
  documentGuid: string
  contextKey: ThreadContextType
  setReloadThreads: (flag: boolean | ((newFlag: boolean) => boolean)) => void
}

const Threads = ({
  threads,
  taxOrganizerId,
  documentGuid,
  contextKey,
  taxReturnId,
  taxReturnPageId,
  currentThreadReferenceNumber,
  setReloadThreads,
}: Props) => {
  const { newThread } = useDocument()
  const nextNumber =
    (maxBy(threads, (thread) => thread.referenceNumber || 0)?.referenceNumber || 0) + 1
  return (
    <div>
      {threads.map((thread) => (
        <Thread
          isVisible={currentThreadReferenceNumber === String(thread.referenceNumber)}
          contextKey={contextKey}
          key={thread.guid}
          thread={thread}
          setReloadThreads={setReloadThreads}
        />
      ))}
      {newThread && (
        <NewThread
          contextKey={contextKey}
          taxOrganizerId={taxOrganizerId}
          taxReturnId={taxReturnId}
          taxReturnPageId={taxReturnPageId}
          documentGuid={documentGuid}
          number={nextNumber}
          setReloadThreads={setReloadThreads}
        />
      )}
    </div>
  )
}

export default Threads
