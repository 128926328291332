import { ChangeEvent, useEffect, useState } from 'react'
import { Box } from 'reflexbox'
import { Modal } from 'antd'
import { useTranslation } from 'react-i18next'

import { Key } from 'antd/lib/table/interface'

import { ALLOWED_DOCUMENT_FILE_TYPES } from 'utils/taxOrganizer'
import { checkIfFileTypeIsSupported } from 'utils/files'
import { getOrderingParams } from 'utils/table'

import { TFile } from 'context/TaxOrganizer'
import {
  useEntityActions,
  useEntity,
  TEntityDocumentParams,
  useEntityDocumentsActions,
} from 'context/Entity'

import { Section, Table, Upload, UploadEntityModal } from 'components'

import { useDashboardActions } from 'context/Dashboard'
import { DEFAULT_PARAMS } from './constants'

import useColumns from './hooks/useColumns'

import styles from '../dashboard.entity.management.module.scss'
import Header from './components/Header'
import AlertCategorize from './components/Alert'
import CategorizeModal from './components/Alert/components/CategorizeModal'

const { error } = Modal

type TProps = { entity: string }

const Documents = ({ entity }: TProps) => {
  const [showCategorizeModal, setShowCategorizeModal] = useState<boolean>(false)
  const { t: t2 } = useTranslation('common', { keyPrefix: 'upload' })
  const { setSelectedTableDocumentIds, loadDocumentsSummary } = useEntityDocumentsActions()
  const { loadDocumentsTags, loadDocumentsCategories } = useDashboardActions()
  const {
    entity: {
      documents: { data, total },
      currentEntity: { guid: entityGuid },
    },
    entityDocuments,
  } = useEntity()
  const { getCurrentEntityDocuments, loading } = useEntityActions()
  const { columns, dataSource } = useColumns(data)
  const [params, setParams] = useState<TEntityDocumentParams>(DEFAULT_PARAMS)
  const [currentPage, setCurrentPage] = useState<number | undefined>()

  const [files, setFiles] = useState<TFile[]>([])

  const handlePageChange = (page: number) => {
    setParams((prevParams: TEntityDocumentParams) => {
      setCurrentPage(page)
      return { ...prevParams, page }
    })
  }

  const handleHideModalCategorized = () => {
    setShowCategorizeModal(false)
    loadDocumentsSummary(entityGuid)
    getCurrentEntityDocuments(entity, params)
  }

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setParams((prevParams: TEntityDocumentParams) => {
      setCurrentPage(DEFAULT_PARAMS.page)
      return {
        ...prevParams,
        page: DEFAULT_PARAMS.page,
        search: event.target.value ? event.target.value.trim() : undefined,
      }
    })
  }

  const handleUpload = ({ fileList }: any) => {
    const fileTypeSupported = (fileList as TFile[]).every((file) =>
      checkIfFileTypeIsSupported(file, ALLOWED_DOCUMENT_FILE_TYPES)
    )
    if (!fileTypeSupported) {
      error({ title: t2('formatError') })
      return
    }
    setFiles(fileList)
  }

  useEffect(() => {
    loadDocumentsTags()
    loadDocumentsCategories()
  }, [])

  useEffect(() => {
    getCurrentEntityDocuments(entity, params)
  }, [params])

  const getDocuments = () => {
    getCurrentEntityDocuments(entity, params)
    loadDocumentsSummary(entity)
  }
  const handleHideModal = () => {
    setFiles([])
    getDocuments()
  }

  return (
    <>
      <div className="container">
        <AlertCategorize setShowCategorizeModal={setShowCategorizeModal} />
        <Section>
          <Header
            onUpload={handleUpload}
            onDeletedDocuments={getDocuments}
            handleSearch={handleSearch}
            params={params}
            setParams={setParams}
            setCurrentPage={setCurrentPage}
          />
          <Table
            table={{
              dataSource: dataSource.reverse(),
              columns,
              rowKey: 'guid',
              loading,
              onChange: (p, f, sorter) => {
                setParams((prevParams) => ({ ...prevParams, ordering: getOrderingParams(sorter) }))
              },
              rowSelection: {
                selectedRowKeys: entityDocuments.selectedDocumentIds,
                onChange: (values: Key[]) => setSelectedTableDocumentIds(values),
              },
            }}
            pagination={{ total, onChange: handlePageChange, current: currentPage }}
          />
          <Box mt={[4]} className={styles.upload}>
            <Upload fileList={[]} onChange={handleUpload} />
          </Box>
        </Section>
        {files.length > 0 && <UploadEntityModal fileList={files} onHide={handleHideModal} />}
        {showCategorizeModal && <CategorizeModal onHide={handleHideModalCategorized} />}
      </div>
    </>
  )
}

export default Documents
