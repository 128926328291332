import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Alert } from 'antd'
import { Box } from 'reflexbox'

import { useAuth } from 'context/Auth'
import styles from './invitationbanner.module.scss'

const InvitationBanner = () => {
  const { invitations } = useAuth()
  const { t } = useTranslation('dashboard')

  if (invitations?.previouslyAcceptedAnInvitation || invitations?.pendingInvitations < 1) {
    return null
  }

  return (
    <Alert
      className={styles.signAlert}
      message={
        <Box fontSize={['16px']}>
          {t('user.invitation.pending')}{' '}
          <Link to="/dashboard/user/invitations"> {t('user.invitation.here')}</Link>{' '}
          {t('user.invitation.view')}
        </Box>
      }
      type="warning"
    />
  )
}

export default InvitationBanner
