import { useState, useEffect } from 'react'
import { useAuth } from 'context/Auth'
import { EnumEntityUserRoles, useEntity } from 'context/Entity'
import { ENTITY_INVITATION_PERMISSIONS } from 'constants/permissions'

export default () => {
  const { user } = useAuth()
  const {
    entity: {
      users: { data: entityUsers },
    },
  } = useEntity()
  const [userHasInvitePermissions, setUserHasInvitatePermissions] = useState<boolean>(false)
  const [userEntityRole, setUserEntityRole] = useState<EnumEntityUserRoles>()
  useEffect(() => {
    if (user && entityUsers.length) {
      const userRole: EnumEntityUserRoles | undefined = entityUsers.find(
        (entityUser) => entityUser.user.guid === user.guid
      )?.role
      if (userRole) {
        setUserHasInvitatePermissions(ENTITY_INVITATION_PERMISSIONS.includes(userRole))
        setUserEntityRole(userRole)
      }
    }
  }, [user, entityUsers])
  return { userHasInvitePermissions, userEntityRole }
}
