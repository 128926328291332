import { Flex } from 'reflexbox'
import update from 'immutability-helper'
import { uniq } from 'lodash'

import {
  TTaxReturnPage,
  TPagesRequest,
  useTaxReturns,
  useTaxReturnsActions,
} from 'context/TaxReturns'
import { useCallback, useEffect, useState } from 'react'
import { canUpdateSortDocument } from 'utils/categorize'
import { getBetweenSelectedIds } from 'utils/taxReturn'

import { SpinnerBackdrop } from 'components'

import DocumentPage from './DocumentPage'

type TProps = {
  documents: TTaxReturnPage[]
  showResetIcon?: boolean
  uncategorized?: boolean
}

const DocumentsList = ({ documents, showResetIcon = false, uncategorized }: TProps) => {
  const {
    state: { dragDropDocumentIds },
  } = useTaxReturns()
  const { updateTaxReturnPages, loading, setDragDropDocumentIds } = useTaxReturnsActions()
  const [dragPageId, setDragPageId] = useState<string | null>(null)
  const [isDraggingPages, setIsDraggingPages] = useState<boolean>(false)
  const [sortDocuments, setSortDocuments] = useState<TTaxReturnPage[]>(documents)
  const [isDropSortedDocuments, setIsDropSortedDocuments] = useState<boolean>(false)

  const moveDocument = useCallback((dragIndex: number, hoverIndex: number) => {
    setSortDocuments((prevDocument: TTaxReturnPage[]) =>
      update(prevDocument, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevDocument[dragIndex] as TTaxReturnPage],
        ],
      })
    )
  }, [])

  const isDraggingPage = (documentGuid: string) => {
    if (isDraggingPages && dragPageId === documentGuid) {
      return true
    }

    return isDraggingPages && dragDropDocumentIds.includes(documentGuid)
  }

  const handleSetDragPageIdsFotPressShift = (pageId: string) => {
    setDragDropDocumentIds(uniq(getBetweenSelectedIds(sortDocuments, dragDropDocumentIds, pageId)))
  }

  useEffect(() => {
    if (isDropSortedDocuments && canUpdateSortDocument(documents, sortDocuments)) {
      const generatePageBody: TPagesRequest = {
        pages: sortDocuments.map((sortDocument, index) => ({
          ...sortDocument,
          categoryPageNumber: index + 1,
        })),
      }

      setDragDropDocumentIds([])
      updateTaxReturnPages(generatePageBody)
    }

    setSortDocuments(documents)
    setIsDropSortedDocuments(false)
  }, [documents, isDropSortedDocuments])

  return (
    <>
      {loading && <SpinnerBackdrop mode="global" />}
      <Flex flexWrap={['wrap']} px={[3]} py={[3]}>
        {sortDocuments.map((document, index) => (
          <DocumentPage
            key={document.guid}
            page={document}
            isDraggingPage={isDraggingPage(document.guid)}
            setIsDraggingPages={setIsDraggingPages}
            index={index}
            moveDocument={moveDocument}
            setIsDropSortedDocuments={setIsDropSortedDocuments}
            dragPageId={dragPageId}
            setDragPageId={setDragPageId}
            setDragPageIdsFotPressShift={handleSetDragPageIdsFotPressShift}
            showResetIcon={showResetIcon}
            uncategorized={uncategorized}
            sortDocuments={sortDocuments}
          />
        ))}
      </Flex>
    </>
  )
}

export default DocumentsList
