import { TCPAFirmAssociatedUser, TJobTitle } from 'context/Dashboard'
import { ActionTypes } from './actions'

export type InviteMember = {
  email: string
  role: ICPAUserRole
  transferOwnership?: boolean
}

export type TFirmInvitationsParams = {
  page?: number
  ordering?: string
  search?: string
}

export enum EnumStatus {
  PENDING = 'pen',
  ACCEPTED = 'acc',
  REVOKED = 'rev',
}

export type TFirmInvitation = {
  guid: string
  email: string
  cpaFirm: TCpaFirm
  role?: ICPAUserRole
  status?: EnumStatus
  expiresOn?: string | null
  lastSentOn?: string | null
  statusOn?: string | null
  invitedBy: IContact
}

export type TTradeShow = {
  clientsConnectedCount: number
  clientsInvitedCount: number
  code1: string
  code2: string
  cpaFirm: {
    guid: string
    name: string
  }
  tradeShowPromotion: {
    guid: string
    name: string
    logo: string
  }
}

export enum ProfessionEnum {
  ATTY = 'ATTY',
  CAA = 'CAA',
  CPA = 'CPA',
  EA = 'EA',
  EAC = 'EAC',
  ERPA = 'ERPA',
  SRTP = 'SRTP',
}

export type PTINType = {
  guid: string
  firstName?: string
  lastName?: string
  middleName?: string
  suffix?: string
  businessName?: string
  busAddrLine1?: string
  busAddrLine2?: string
  busAddrLine3?: string
  busAddrCity?: string
  busStateCode?: string
  busAddrZip?: string
  busCountryCode?: string
  website?: string
  busPhoneNumber?: string
  profession?: ProfessionEnum[]
  afspIndicator?: boolean
}

export type TCPAUserRoleWithPermission = {
  role: ICPAUserRole
  label: string
  canAssign: boolean
}

export type IFirmState = {
  members: {
    data: TCPAFirmAssociatedUser[]
    total: number
  }
  invitations: {
    data: TFirmInvitation[]
    total: number
  }
  tradeShow: TTradeShow | undefined
  jobTitles: TJobTitle[]
  ptins: {
    data: PTINType[]
    total: number
  }
  cpaUserRoles: TCPAUserRoleWithPermission[]
}

export type IAction = { type: keyof typeof ActionTypes; payload?: any }
