import { ReactNode } from 'react'
import { Input } from 'antd'
import { TextAreaProps as TextAreaPropsAntd } from 'antd/lib/input/TextArea'

import './styles.scss'

const { TextArea } = Input

export type TextAreaProps = TextAreaPropsAntd & {
  icon?: ReactNode
}

const TextAreaComponent = ({ icon, ...props }: TextAreaProps) => {
  return (
    <div className="textarea">
      {icon && <div className="textarea-icon">{icon}</div>}
      <TextArea {...props} />
    </div>
  )
}

export default TextAreaComponent
