import { useMemo, useEffect, useState } from 'react'
import { Row, Col, Alert, Button } from 'antd'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import useUserType from 'hooks/useUserType'
import { useTaxOrganizer } from 'context/TaxOrganizer'
import { useEntityActions } from 'context/Entity'

import Timeline from './components/Timeline'
import ActivityFeed from './components/ActivityFeed'
import TaxTeamTaxOrganizerWrapper from './components/TaxTeamTaxOrganizerWrapper'
import useTimelineActions, { PhaseActionLink } from './components/Timeline/hooks/useTimelineActions'
import EngagementLetterSigningWidget from './components/Timeline/components/EngagementLetterSigningWidget'

import styles from './tax.overview.module.scss'

const DashboardOverview = () => {
  const {
    taxOrganizer: { data, engagementLetterSigningInformation },
  } = useTaxOrganizer()
  const { loadEntityUsers } = useEntityActions()
  const userType = useUserType()
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'overview.timelinePhases' })
  const [signingWidgetSource, setSigningWidgetSource] = useState<string | null>(null)
  const [signingWidgetError, setSigningWidgetError] = useState<string | null>(null)

  useEffect(() => {
    if (userType === 'ENTITY') {
      loadEntityUsers(data.entity.guid)
    }
  }, [data])

  const { actionLinks } = useTimelineActions({
    setIframeSource: setSigningWidgetSource,
    setError: setSigningWidgetError,
  })

  const onAction = (actionLink: PhaseActionLink) => {
    if (!actionLink?.actionCallback) {
      return
    }
    actionLink.actionCallback()
  }

  const letterReadyToBeSigned = useMemo(() => {
    return (
      userType === 'CPA' &&
      engagementLetterSigningInformation?.signedByClientOn &&
      !engagementLetterSigningInformation?.signedByPreparerOn
    )
  }, [engagementLetterSigningInformation, userType])

  const actionLink = actionLinks.SIGN_ENGAGEMENT

  return (
    <div className={cn(styles.section, 'container')}>
      {signingWidgetError && <Alert type="error" message={signingWidgetError} banner closable />}
      {signingWidgetSource && (
        <EngagementLetterSigningWidget
          source={signingWidgetSource}
          setSource={setSigningWidgetSource}
          setError={setSigningWidgetError}
        />
      )}
      {letterReadyToBeSigned && (
        <Alert
          className={styles.signAlert}
          message={t('cpaLetterReady')}
          type="warning"
          showIcon
          action={
            <div className={styles.action_button}>
              <Button
                disabled={actionLink?.isDisabled}
                onClick={() => onAction(actionLink)}
                type="primary"
                ghost
              >
                {actionLink.title}
              </Button>
            </div>
          }
        />
      )}
      <Row gutter={[20, 20]}>
        <Col xs={24} lg={14}>
          <Timeline />
        </Col>
        <Col xs={24} lg={10}>
          <Row gutter={[20, 20]}>
            <Col xs={24} md={12} lg={24}>
              <TaxTeamTaxOrganizerWrapper />
            </Col>
            <Col xs={24} md={12} lg={24}>
              <ActivityFeed />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default DashboardOverview
