import { createContext, useContext, Dispatch, FC, useReducer } from 'react'

import { useReducerLogger } from 'utils'

import { reducer, initialState } from './reducer'
import { ActionTypes } from './action-types'
import { TState, TDispatch } from './types'

interface IContextProps {
  state: TState
  dispatch: Dispatch<TDispatch>
}

const FinancialsContext = createContext<IContextProps>({} as IContextProps)

const FinancialsProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(
    process.env.NODE_ENV === 'development' ? useReducerLogger<TState, TDispatch>(reducer) : reducer,
    initialState
  )

  return (
    <FinancialsContext.Provider value={{ state, dispatch }}>{children}</FinancialsContext.Provider>
  )
}

const useFinancials = () => {
  const context = useContext(FinancialsContext)
  if (context === undefined) {
    throw new Error('useFinancials must be used within a FinancialsProvider')
  }
  return context
}

export { FinancialsProvider, useFinancials, ActionTypes }
