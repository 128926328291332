import { TTaxReturnCategory, TTaxReturnPage } from 'context/TaxReturns'
import { useMemo } from 'react'
import { getGenerateCategories } from 'utils/categorize'

export default (categories: TTaxReturnCategory[], pages: TTaxReturnPage[]) => {
  const categoryPages = useMemo(
    () => (categories.length && pages.length ? getGenerateCategories(categories, pages) : []),
    [categories, pages]
  )
  const federal = useMemo(
    () => categoryPages.find((categoryPage) => categoryPage.category.slug === 'federal'),
    [categoryPages]
  )
  const states = useMemo(
    () => categoryPages.find((categoryPage) => categoryPage.category.slug === 'state'),
    [categoryPages]
  )
  const other = useMemo(
    () => categoryPages.find((categoryPage) => categoryPage.category.slug === 'other'),
    [categoryPages]
  )
  const uncategorized = useMemo(
    () => categoryPages.find((categoryPage) => categoryPage.category.slug === 'uncategorized'),
    [categoryPages]
  )

  return { federal, states, other, uncategorized }
}
