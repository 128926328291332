import { useEffect } from 'react'

import { useEntity, useEntityActions, TEntity } from 'context/Entity'

import useUserType from 'hooks/useUserType'
import useUserEntityPermissions from 'hooks/useUserEntityPermissions'

import { SpinnerBackdrop, Section } from 'components'

import TaxTeam from './components/TaxTeam'
import InviteTaxFirm from '../../../components/InviteTaxFirm'

type Props = {
  entity: TEntity
}

const TaxTeamEntityWrapper = ({ entity }: Props) => {
  const { cpaFirm } = entity
  const {
    entity: {
      cpaContacts: { data, count },
    },
  } = useEntity()
  const { loadEntityCPAContacts, loading } = useEntityActions()
  const userType = useUserType()
  const { userHasInvitePermissions } = useUserEntityPermissions()

  useEffect(() => {
    if (cpaFirm) {
      loadEntityCPAContacts(entity.guid, cpaFirm.guid)
    }
  }, [cpaFirm])

  if (userType === 'CPA') {
    return (
      <Section style={{ padding: '0' }}>
        {loading && <SpinnerBackdrop />}
        <TaxTeam data={data} count={count} cpaFirm={cpaFirm} entity={entity} editable />
      </Section>
    )
  }

  if (userType === 'ENTITY') {
    if (!cpaFirm) {
      return userHasInvitePermissions ? <InviteTaxFirm entity={entity} /> : null
    }
    return (
      <Section style={{ padding: '0' }}>
        {loading && <SpinnerBackdrop />}
        <TaxTeam data={data} count={count} cpaFirm={cpaFirm} entity={entity} editable={false} />
      </Section>
    )
  }

  return null
}

export default TaxTeamEntityWrapper
