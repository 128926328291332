import { useState, useEffect, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'antd'
import { Key } from 'antd/lib/table/interface'

import {
  TLoadDocumentsParams,
  useDocumentsActions,
  useTaxOrganizer,
  TFile,
} from 'context/TaxOrganizer'
import { useDashboardActions } from 'context/Dashboard'

import { ALLOWED_DOCUMENT_FILE_TYPES } from 'utils/taxOrganizer'
import { checkIfFileTypeIsSupported } from 'utils/files'

import { getOrderingParams } from 'utils/table'

import { Section, Table, Upload, UploadModal } from 'components'
import Header from './components/Header'
import AlertCategorize from './components/Alert'
import CategorizeModal from './components/Alert/components/CategorizeModal'

import styles from './tax.documents.module.scss'
import useTableData from './components/Table/hooks/use-table-data'
import { DEFAULT_PARAMS } from './components/contstants'

const { error } = Modal

const DashboardDocuments = () => {
  const [files, setFiles] = useState<TFile[]>([])
  const [showCategorizeModal, setShowCategorizeModal] = useState<boolean>(false)
  const [params, setParams] = useState<TLoadDocumentsParams>(DEFAULT_PARAMS)
  const [currentPage, setCurrentPage] = useState<number | undefined>()
  const { t: t2 } = useTranslation('common', { keyPrefix: 'upload' })
  const { loadDocumentsSummary } = useDocumentsActions()
  const { loadDocuments, loading } = useDocumentsActions()
  const { setSelectedTableDocumentIds } = useDocumentsActions()
  const { loadDocumentsTags, loadDocumentsCategories } = useDashboardActions()
  const {
    taxOrganizer: {
      data: { guid: taxOrganizerGuid },
    },
    documents,
  } = useTaxOrganizer()
  const { dataSource, columns } = useTableData()

  const handleUpload = ({ fileList }: any) => {
    const fileTypeSupported = (fileList as TFile[]).every((file) =>
      checkIfFileTypeIsSupported(file, ALLOWED_DOCUMENT_FILE_TYPES)
    )
    const noEmptyFiles = (fileList as TFile[]).every((file) => file.size && file.size > 0)
    if (!noEmptyFiles) {
      error({ title: t2('fileSizeError') })
      return
    }
    if (!fileTypeSupported) {
      error({ title: t2('formatError') })
      return
    }
    setFiles(fileList)
  }
  const getDocuments = () => {
    loadDocuments(taxOrganizerGuid, params)
    loadDocumentsSummary(taxOrganizerGuid)
  }
  const handleHideModal = () => {
    setFiles([])
    getDocuments()
  }

  const handleHideModalCategorized = () => {
    setShowCategorizeModal(false)
    loadDocumentsSummary(taxOrganizerGuid)
    loadDocuments(taxOrganizerGuid, DEFAULT_PARAMS)
  }

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setParams((prevParams: TLoadDocumentsParams) => {
      setCurrentPage(DEFAULT_PARAMS.page)
      return {
        ...prevParams,
        page: DEFAULT_PARAMS.page,
        search: event.target.value ? event.target.value.trim() : undefined,
      }
    })
  }

  const handlePageChange = (page: number) => {
    setParams((prevParams: TLoadDocumentsParams) => {
      setCurrentPage(page)
      return { ...prevParams, page }
    })
  }

  useEffect(() => {
    loadDocumentsTags()
    loadDocumentsCategories()
  }, [])

  useEffect(() => {
    getDocuments()
  }, [params])

  return (
    <div className={styles.section}>
      <AlertCategorize setShowCategorizeModal={setShowCategorizeModal} />
      <Section>
        <Header
          onUpload={handleUpload}
          onDeletedDocuments={getDocuments}
          handleSearch={handleSearch}
          params={params}
          setParams={setParams}
          setCurrentPage={setCurrentPage}
        />
        <Table
          table={{
            columns,
            dataSource,
            onChange: (p, f, sorter) => {
              setParams((prevParams) => ({ ...prevParams, ordering: getOrderingParams(sorter) }))
            },
            rowSelection: {
              selectedRowKeys: documents.selectedDocumentIds,
              onChange: (values: Key[]) => setSelectedTableDocumentIds(values),
            },
            loading,
          }}
          pagination={{
            total: documents.count,
            onChange: handlePageChange,
            current: currentPage,
          }}
        />
        <div className={styles.upload}>
          <Upload
            fileList={[]}
            onChange={handleUpload}
            accept={ALLOWED_DOCUMENT_FILE_TYPES.join(',')}
          />
        </div>
      </Section>

      {files.length > 0 && <UploadModal fileList={files} onHide={handleHideModal} />}
      {showCategorizeModal && <CategorizeModal onHide={handleHideModalCategorized} />}
    </div>
  )
}

export default DashboardDocuments
