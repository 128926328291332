import { Box, Flex } from 'reflexbox'
import { useTranslation } from 'react-i18next'

import styles from '../../dashboard.firm.billing.module.scss'

const Description = () => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'firm.billing' })

  return (
    <Flex flexDirection={['column']} py={['32px']} className={styles.block}>
      <Box lineHeight={['24px']} fontSize={['20px']} fontWeight={[700]}>
        {t('how')}
      </Box>
      <Box lineHeight={['23px']} fontSize={['16px']} mt={['20px']}>
        {t('description-3')}
      </Box>
    </Flex>
  )
}

export default Description
