import { ActionTypes } from './actions/action-types'
import { TState, TAction } from './types'

export const initialState: TState = {
  count: 0,
  next: null,
  previous: null,
  notifications: [],
  totalUnread: 0,
}

export const reducer = (state: TState = initialState, action: TAction) => {
  const { payload } = action

  switch (action.type) {
    case ActionTypes.LOAD_NOTIFICATIONS:
      return {
        ...state,
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
        notifications: payload.results,
      }
    case ActionTypes.LOAD_UNREAD_NOTIFICATIONS:
      return {
        ...state,
        totalUnread: payload.unreadNotifications,
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}
