import { useFetch, CachePolicies } from 'use-http'
import { toSnake } from 'convert-keys'
import { identify } from '@chamaeleonidae/chmln'
import { getMessageFromResponse } from 'utils/http'
import { useTranslation } from 'react-i18next'
import { useMarkerDispatch, ActionTypes as MarkerActionTypes } from 'context/Marker'

import {
  useAuth,
  ActionTypes,
  useAuthDispatch,
  UserProfile,
  LoginToken,
  LoginRequest,
  RegisterRequest,
  ChangePassword,
  EmailConfirmation,
} from 'context/Auth'

import { message } from 'antd'
import { ResetPasswordConfirm, ResetPasswordRequest, StatusType, UserType } from './types'

const useAuthActions = () => {
  const markerDispatch = useMarkerDispatch()
  const dispatch = useAuthDispatch()
  const { token: loginToken, maskedGuid } = useAuth()
  const { i18n } = useTranslation()
  const { get, post, patch, put, response, loading, error } = useFetch(
    process.env.REACT_APP_API_URL,
    {
      cachePolicy: CachePolicies.NO_CACHE,
    }
  )
  const { get: get2, response: response2 } = useFetch(process.env.REACT_APP_API_URL, {
    cachePolicy: CachePolicies.NO_CACHE,
  })
  const { get: get3, response: response3 } = useFetch(process.env.REACT_APP_API_URL, {
    cachePolicy: CachePolicies.NO_CACHE,
  })

  const loadCPAUserInfo = async () => {
    const user: UserProfile = await get('/users/me/profile')
    i18n.changeLanguage(user?.localeName || 'en-US')
    const cpaFirm = await get2('/cpas/memberships')
    const invitations = await get3(`/cpas/cpa-firm-invites`)
    // const subscriptions = await get4(
    //   `/billing/cpa-firm/${cpaFirm.data.results?.[0]?.cpa_firm.guid}/profile`,
    //   token
    // )
    const initResponse = [response.ok, response2.ok, response3.ok].every((r) => r)
    if (initResponse) {
      dispatch({
        type: ActionTypes.SET_CPA_USER,
        payload: {
          user,
          cpaFirm,
          invitations,
          responseOk: true,
        },
      })
      if (process.env.NODE_ENV === 'production') {
        identify(maskedGuid ? `${user.guid}-${maskedGuid}` : user.guid, {
          email: user.email,
          name: `${user.firstName} ${user.lastName}`,
          created: user.createdOn,
          role: user.type,
        })
      }
      markerDispatch({
        type: MarkerActionTypes.SET_REPORTER,
        payload: { email: user.email, fullName: `${user.firstName} ${user.lastName}` },
      })
    }
  }

  const loadEntityUserInfo = async () => {
    const user = await get('/users/me/profile')
    i18n.changeLanguage(user?.localeName || 'en-US')
    const entities = await get2('/entities')
    const invitations = await get3(`/entities/user-invites-mine`)
    const initResponse = [response.ok, response2.ok, response3.ok].every((r) => r)
    if (initResponse) {
      dispatch({
        type: ActionTypes.SET_ENTITY_USER,
        payload: {
          user,
          entities,
          invitations,
          responseOk: true,
        },
      })
      if (process.env.NODE_ENV === 'production') {
        identify(maskedGuid ? `${user.guid}-${maskedGuid}` : user.guid, {
          email: user.email,
          name: `${user.firstName} ${user.lastName}`,
          created: user.createdOn,
          role: user.type,
        })
      }
      markerDispatch({
        type: MarkerActionTypes.SET_REPORTER,
        payload: { email: user.email, fullName: `${user.firstName} ${user.lastName}` },
      })
    }
  }

  const setOnboardingFlowStatus = (status?: StatusType) => {
    dispatch({
      type: 'SET_ONBOARDING_FLOW_STATUS',
      payload: status,
    })
  }

  const setInvitationsFlowStatus = (status?: StatusType) => {
    dispatch({
      type: 'SET_INVITATIONS_FLOW_STATUS',
      payload: status,
    })
  }

  const setOnboardingStep = (status?: TOnboardingClientStatus | undefined) => {
    dispatch({
      type: 'SET_ONBOARDING_STEP',
      payload: { status, userType: loginToken!.type },
    })
  }

  const loadUsers = async (search = '') => {
    dispatch({ type: ActionTypes.SET_USERS, payload: null })

    const users = await get(`/users?search=${search}`)
    if (response.ok) {
      dispatch({ type: ActionTypes.SET_USERS, payload: users.results })
    }
  }

  const login = async (values: LoginRequest) => {
    const token: LoginToken = await post('/auth/token', values)
    if (response.ok) {
      dispatch({ type: ActionTypes.LOGIN_SUCCESS, payload: token })
    }
  }

  const refreshToken = async (token: string) => {
    const refresh = await post('/auth/token/refresh', { refresh: token })
    if (response.ok) {
      dispatch({ type: ActionTypes.REFRESH_TOKEN, payload: refresh.access })
    } else {
      dispatch({ type: ActionTypes.LOGOUT })
    }
  }

  const verifyToken = async (token: LoginToken) => {
    if (!token) {
      dispatch({ type: ActionTypes.LOGOUT })
    } else {
      await post('/auth/token/verify', { token: token.access })

      if (!response.ok) {
        refreshToken(token.refresh)
      }
    }
  }

  const register = async (values: RegisterRequest) => {
    const result = await post('/auth/register', toSnake(values))
    if (response.ok) {
      await login({ email: values.email, password: values.password })
    }
    return result
  }

  const updateUserProfile = async (params: Partial<UserProfile>) => {
    const userProfile: UserProfile = await patch('/users/me/profile', toSnake(params))
    if (response.ok) {
      dispatch({ type: ActionTypes.SET_USER, payload: userProfile })
      if (process.env.NODE_ENV === 'production') {
        identify(userProfile.guid, {
          email: userProfile.email,
          name: `${userProfile.firstName} ${userProfile.lastName}`,
          created: userProfile.createdOn,
          role: userProfile.type,
        })
      }
      markerDispatch({
        type: MarkerActionTypes.SET_REPORTER,
        payload: {
          email: userProfile.email,
          fullName: `${userProfile.firstName} ${userProfile.lastName}`,
        },
      })
    }
    return userProfile
  }

  const updateAvatar = async (file: File) => {
    const data = new FormData()
    data.append('file', file)
    const res = await put('/users/me/avatar', data)
    if (response.ok) {
      dispatch({ type: ActionTypes.UPDATE_USER, payload: { avatar: res.file } })
    }
  }

  const changePassword = async (params: ChangePassword) => {
    const result = await patch('/auth/change-password', toSnake(params))
    return result
  }

  const resetPassword = async (params: ResetPasswordRequest) => {
    const result = await post('/auth/reset-password/', toSnake(params))
    return result
  }

  const resetPasswordConfirm = async (params: ResetPasswordConfirm) => {
    const result = await post('/auth/reset-password/confirm/', toSnake(params))
    return result
  }

  const confirmEmail = async (params: EmailConfirmation) => {
    const emailConfirmation = await post('/auth/email/confirm', toSnake(params))
    if (response.ok) {
      dispatch({ type: ActionTypes.REFRESH_TOKEN, payload: emailConfirmation.access })
    }
    return emailConfirmation
  }

  const resendEmailConfirmation = async () => {
    const resendResponse = await post('/auth/email/confirm/resend')
    return resendResponse
  }

  const getInviteInfo = async (userType: UserType, inviteGuid: string) => {
    let results = {}
    if (userType === 'CPA') {
      results = await get(`/cpas/cpa-firm-invites/${inviteGuid}`)
    } else {
      results = await get(`/cpas/client-invites/${inviteGuid}`)
    }
    if (response.ok) {
      dispatch({
        type: ActionTypes.SET_INVITE_INFO,
        payload: { response, inviteInfo: results, userType },
      })
    } else {
      dispatch({
        type: ActionTypes.SET_INVITE_INFO,
        payload: { response, inviteInfo: undefined, userType: undefined },
      })
    }
  }

  const setEntityStatus = async () => {
    const entities = await get('/entities')
    const invitations = await get(`/entities/user-invites-mine`)
    if (response.ok) {
      dispatch({
        type: ActionTypes.SET_ENTITY_USER,
        payload: { entities: entities.results, invitations: invitations.results },
      })
    }
    return invitations
  }

  const maskAs = async (guid: string) => {
    const token: LoginToken = await post('/auth/mask', { guid })
    if (response.ok) {
      dispatch({
        type: ActionTypes.MASK_SUCCESS,
        payload: {
          token,
        },
      })
    } else {
      message.error(getMessageFromResponse(response))
    }
  }

  const maskOff = async () => {
    dispatch({ type: ActionTypes.STOP_MASK })
  }

  const logout = () => {
    dispatch({ type: ActionTypes.LOGOUT })
  }

  const createCpaFirm = async (payload: Partial<TCpaFirm>) => {
    const result = await post(`/cpas/cpa-firms`, toSnake(payload))
    if (response.ok) {
      dispatch({ type: ActionTypes.UPSERT_CPA_FIRM, payload: result })
    }
  }

  const updateCpaFirm = async (guid: string, params: Partial<TCpaFirm>) => {
    const result = await patch(`/cpas/cpa-firms/${guid}`, toSnake(params))
    if (response.ok) {
      dispatch({ type: ActionTypes.UPSERT_CPA_FIRM, payload: result })
    }
  }

  const updateCpaFirmLogo = async (guid: string, file: File) => {
    const data = new FormData()
    data.append('image', file)
    const result = await patch(`/cpas/cpa-firms/${guid}/letterhead-image`, data)
    if (response.ok) {
      dispatch({
        type: ActionTypes.UPSERT_CPA_FIRM,
        payload: {
          letterheadImage: result?.image,
        },
      })
    }
  }

  const getPreferences = async () => {
    const preferencesResponse = await get(`/users/me/preferences`)
    return response.ok ? preferencesResponse.notificationFrequency : null
  }

  const updatePreferences = async (notificationsFrequency: string) => {
    const body = {
      notification_frequency: notificationsFrequency,
    }
    const preferencesResponse = await patch(`/users/me/preferences`, body)
    return response.ok ? preferencesResponse.notificationFrequency : `null`
  }

  const getUserInvites = async () => {
    const invitations = await get('/users/me/invitations-summary')
    if (response.ok) {
      dispatch({
        type: ActionTypes.SET_PENDING_INVITES,
        payload: invitations,
      })
    }
    return invitations
  }

  return {
    loadUsers,
    loadCPAUserInfo,
    loadEntityUserInfo,
    setOnboardingFlowStatus,
    setInvitationsFlowStatus,
    setOnboardingStep,
    login,
    verifyToken,
    maskAs,
    refreshToken,
    register,
    updateUserProfile,
    updateAvatar,
    changePassword,
    resetPassword,
    resetPasswordConfirm,
    confirmEmail,
    resendEmailConfirmation,
    getInviteInfo,
    setEntityStatus,
    maskOff,
    logout,
    updateCpaFirm,
    createCpaFirm,
    updateCpaFirmLogo,
    getPreferences,
    updatePreferences,
    getUserInvites,
    loading,
    response,
    error,
  }
}

export default useAuthActions
