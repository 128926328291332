import { useTranslation } from 'react-i18next'
import { Box, Flex } from 'reflexbox'
import { ColumnsType } from 'antd/lib/table'

import useDate from 'hooks/useDate'

import { useAuth } from 'context/Auth'
import { TFirmInvitation } from 'context/CPAFirm'

import { UserIcon } from 'components'
import { TJobTitle } from 'context/Dashboard'
import Actions from '../components/Actions'

type TProps = {
  setParams: (state: TParams | ((state: TParams) => TParams)) => void
}

export default ({ setParams }: TProps) => {
  const { getDate } = useDate()
  const {
    CPA: { userRole },
  } = useAuth()
  const { t } = useTranslation('dashboard', { keyPrefix: 'firm.team' })
  const { t: t2 } = useTranslation('common')

  const excludeColumns = (columns: ColumnsType<TFirmInvitation>, columnKey: string) => {
    return columns.filter((column) => !(userRole === 'MEMBER' && column.key === columnKey))
  }

  const columns: ColumnsType<TFirmInvitation> = [
    {
      title: t('email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('accessLevel'),
      dataIndex: 'role',
      key: 'role',
      render: (role: string) => (
        <span className="text-semibold">{t2(`user.roles.${role}`, role)}</span>
      ),
    },
    {
      title: t('jobTitle'),
      dataIndex: 'jobTitle',
      key: 'jobTitle',
      render: (jobTitle: TJobTitle) => (
        <span className="text-semibold">{jobTitle?.label ?? t('noJobTitle')}</span>
      ),
    },
    {
      title: <span className="text-nowrap">{t('invitedFrom')}</span>,
      dataIndex: 'invitedBy',
      key: 'invitedBy',
      ellipsis: true,
      render: (user: IContact) => (
        <Flex flexWrap={['nowrap']}>
          <UserIcon user={user} avatarProps={{ size: 'small' }} />
          <Box className="text-nowrap" ml={['11px']}>
            {user.firstName} {user.lastName}
          </Box>
        </Flex>
      ),
    },
    {
      title: <span className="text-nowrap">{t('lastSentOn')}</span>,
      dataIndex: 'lastSentOn',
      key: 'lastSentOn',
      render: (lastSentOn: string) => (
        <div className="text-nowrap">{getDate(lastSentOn, 'yyyy-MM-dd')}</div>
      ),
    },
    {
      title: t('actions'),
      dataIndex: 'actions',
      key: 'actions',
      render: (_: any, { guid: inviteGuid, cpaFirm: { guid: cpaFirmGuid } }: TFirmInvitation) => {
        return <Actions inviteGuid={inviteGuid} cpaFirmGuid={cpaFirmGuid} setParams={setParams} />
      },
    },
  ]

  return { columns: excludeColumns(columns, 'actions') }
}
