import { Box, Flex } from 'reflexbox'
import { useTranslation } from 'react-i18next'
import { Button } from 'components'

type TProps = {
  title: string
  countFiles: number
  hideModal: () => void
}
const TitleModal = ({ title, countFiles, hideModal }: TProps) => {
  const { t } = useTranslation('common', { keyPrefix: 'upload.modal' })

  return (
    <Flex
      py={[3]}
      width={['100%']}
      justifyContent={['space-between']}
      alignItems={['center']}
      flexDirection={['column', 'column', 'row']}
    >
      <Box lineHeight={['24px']} fontSize={['20px']} fontWeight={[600]}>
        {title}
      </Box>

      <Box
        lineHeight={['24px']}
        fontSize={['15px']}
        mr={[3]}
        width={['100%']}
        mt={[2, 2, 0]}
        textAlign={['center']}
      >
        {countFiles} {t('filesLeft')}
      </Box>

      <Box mt={[2, 2, 0]} minWidth={['142px', '142px', 'auto']}>
        <Button type="primary" ghost size="large" onClick={hideModal}>
          {t('categorizeLater')}
        </Button>
      </Box>
    </Flex>
  )
}

export default TitleModal
