import { ActionTypes } from './action-types'
import { TState, TAction } from './types'

import data from './static'

export const initialState: TState = {
  data,
}

export const reducer = (state: TState = initialState, action: TAction) => {
  switch (action.type) {
    case ActionTypes.LOAD_FINANCIALS_SOFTWARE:
      return {
        ...state,
        data: state.data,
      }

    default:
      return state
  }
}
