import { Modal } from 'antd'
import { Box, Flex } from 'reflexbox'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import { Badge, Button } from 'components'
import styles from '../../../dashboard.tax_return.module.scss'

type TProps = {
  visible: boolean
  setVisible: (arg: boolean) => void
}

const ModalComponent = ({ visible, setVisible }: TProps) => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'taxReturns' })
  const { t: t2 } = useTranslation('common', { keyPrefix: 'buttons' })
  const isMobile = useMediaQuery({ query: '(max-width: 40em)' })

  const handleOk = () => {
    setVisible(false)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  return (
    <>
      <Modal
        className={styles.modal}
        width={isMobile ? 'auto' : '580px'}
        bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
        title={
          <Flex
            pt={[4]}
            pl={['10px', 3]}
            pr={['10px', '50px']}
            lineHeight={['24px']}
            fontSize={['20px']}
            fontWeight={[600]}
          >
            {t('upload.modal.title')}
          </Flex>
        }
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Flex
            pb={[3]}
            pl={['10px', 3]}
            pr={['10px', '50px']}
            flexDirection="column"
            alignItems="flex-start"
            key="modal-footer-1"
          >
            <Box mt="2" key="modal-subfooter-2">
              <Button key="submit" size="large" type="primary" onClick={handleOk}>
                {t2('download')}
              </Button>
            </Box>
          </Flex>,
        ]}
      >
        <Flex
          pl={['10px', 3]}
          pr={['10px', '50px']}
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Flex
            alignItems={['baseline']}
            my="2"
            className={styles.tax_return_step}
            key="modal-tax-returns-1"
          >
            <span className={styles.tax_return_step_badge}>
              <Badge count={1} scheme="llgray" />
            </span>
            <span className={styles.tax_return_step_text}>{t('upload.modal.1')}</span>
          </Flex>
          <Flex
            alignItems={['baseline']}
            my="2"
            className={styles.tax_return_step}
            key="modal-tax-returns-2"
          >
            <span className={styles.tax_return_step_badge}>
              <Badge count={2} scheme="llgray" />
            </span>
            <span className={styles.tax_return_step_text}>{t('upload.modal.2')}</span>
          </Flex>
          <Flex
            alignItems={['baseline']}
            my="2"
            className={styles.tax_return_step}
            key="modal-tax-returns-3"
          >
            <span className={styles.tax_return_step_badge}>
              <Badge count={3} scheme="llgray" />
            </span>
            <span className={styles.tax_return_step_text}>{t('upload.modal.3')}</span>
          </Flex>
          <Flex
            alignItems={['baseline']}
            my="2"
            className={styles.tax_return_step}
            key="modal-tax-returns-4"
          >
            <span className={styles.tax_return_step_badge}>
              <Badge count={4} scheme="llgray" />
            </span>
            <span className={styles.tax_return_step_text}>{t('upload.modal.4')}</span>
          </Flex>
          <Flex
            alignItems={['baseline']}
            my="2"
            className={styles.tax_return_step}
            key="modal-tax-returns-5"
          >
            <span className={styles.tax_return_step_badge}>
              <Badge count={5} scheme="llgray" />
            </span>
            <span className={styles.tax_return_step_text}>{t('upload.modal.5')}</span>
          </Flex>
        </Flex>
      </Modal>
    </>
  )
}

export default ModalComponent
