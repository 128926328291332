import { createContext, useContext, Dispatch, FC, useReducer } from 'react'
import { useReducerLogger } from 'utils'

import { reducer, initialState } from './reducer'
import { TState, TAction } from './types'
import { ActionTypes } from './actions/action-types'

import useNotificationsActions from './actions'

const NotificationsContext = createContext<TState>({} as TState)
const NotificationsDispatchContext = createContext<Dispatch<TAction>>(() => null)

const NotificationsProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(useReducerLogger<TState, TAction>(reducer), initialState)

  return (
    <NotificationsContext.Provider value={state!}>
      <NotificationsDispatchContext.Provider value={dispatch}>
        {children}
      </NotificationsDispatchContext.Provider>
    </NotificationsContext.Provider>
  )
}

const useNotifications = () => {
  const context = useContext(NotificationsContext)
  if (context === undefined) {
    throw new Error('useNotifications must be used within a NotificationsProvider')
  }
  return context
}
const useNotificationsDispatch = () => {
  const context = useContext(NotificationsDispatchContext)
  if (context === undefined) {
    throw new Error('useNotificationsDispatch must be used within a NotificationsProvider')
  }
  return context
}

export {
  NotificationsProvider,
  useNotifications,
  useNotificationsDispatch,
  ActionTypes,
  useNotificationsActions,
}
