import { useTranslation } from 'react-i18next'
import { ColumnsType } from 'antd/lib/table'
import { Dropdown, Menu } from 'antd'
import { DashOutlined } from '@ant-design/icons'
import { Box } from 'reflexbox'

import useDate from 'hooks/useDate'
import { TEntity, useEntityActions } from 'context/Entity'

import { Button, User } from 'components'

import colors from 'assets/styles/variables.module.scss'
import { TEntityUserInvite } from 'context/Invites/types'

type TProps = {
  entity: TEntity
}

export default ({ entity }: TProps) => {
  const { t } = useTranslation('entity')
  const { t: t2 } = useTranslation('dashboard', { keyPrefix: 'clients.table' })
  const { resendEntityUserInvite, revokeEntityUserInvite } = useEntityActions()
  const { getDate } = useDate()

  const columns: ColumnsType<TEntityUserInvite> = [
    {
      title: t('common.email'),
      key: 'email',
      render: ({ email }: TEntityUserInvite) => <Box>{email}</Box>,
    },
    {
      title: t('common.role'),
      key: 'role',
      render: ({ role }: TEntityUserInvite) => <Box>{t(`userRole.${role}`)}</Box>,
    },
    {
      title: t('users.invitedBy'),
      key: 'invitedBy',
      render: ({ invitedBy: { firstName, lastName, avatar } }: TEntityUserInvite) => (
        <User username={`${firstName} ${lastName}`} bgColor={colors.tag5} avatarLink={avatar} />
      ),
    },
    {
      title: t('users.expiresOn'),
      key: 'expiresOn',
      render: ({ expiresOn }: TEntityUserInvite) => {
        return <Box className="text-nowrap">{getDate(expiresOn, 'yyyy-MM-dd')}</Box>
      },
    },
    {
      title: t2('actions'),
      key: 'actions',
      render: (invite) => (
        <Dropdown
          overlay={() => (
            <Menu theme="dark">
              <Menu.Item
                onClick={() => resendEntityUserInvite(entity.guid, invite.guid)}
                key="settings"
              >
                {t2('resend')}
              </Menu.Item>
              <Menu.Item
                onClick={() => revokeEntityUserInvite(entity.guid, invite.guid)}
                key="profile"
              >
                {t2('revoke')}
              </Menu.Item>
            </Menu>
          )}
          trigger={['click']}
          placement="bottomLeft"
        >
          <Button type="text">
            {/* <DashOutlined className={styles.actions} /> */}
            <DashOutlined />
          </Button>
        </Dropdown>
      ),
    },
  ]

  return { columns }
}
