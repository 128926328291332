import { Flex, Box } from 'reflexbox'
import { FilePdfOutlined, LoadingOutlined, LockOutlined, RightOutlined } from '@ant-design/icons'
import { getTagColorByName } from 'utils'
import { useTranslation } from 'react-i18next'
import { Collapse } from 'react-collapse'

import { useDashboard } from 'context/Dashboard'
import { TFile, TTaxDocument } from 'context/TaxOrganizer'

import { Tag } from 'components'

import { getCategoryLabel, getSubCategoryLabel, getTagLabels } from 'utils/form'

import styles from '../uploadmodal.module.scss'

type Props = {
  fileCollapse: boolean
  setFileCollapse: (value: boolean | ((prevState: boolean) => boolean)) => void
  fileUploading?: TFile
  fileUploaded: TTaxDocument | undefined
  fileCategories: any
  loading?: boolean
  hideFileEditing?: boolean
  fileIsLocked: boolean
}

const Header = ({
  fileCollapse,
  setFileCollapse,
  fileUploading,
  fileUploaded,
  fileCategories,
  loading,
  hideFileEditing,
  fileIsLocked,
}: Props) => {
  const { t } = useTranslation('common', { keyPrefix: 'upload.modal' })
  const { tags, categories, subCategories } = useDashboard()

  const fileCategory =
    getCategoryLabel(fileCategories.category, subCategories, categories) ??
    getCategoryLabel(fileUploaded?.category || '', subCategories, categories) ??
    '-'
  const fileSubCategory =
    getSubCategoryLabel(fileCategories.category, subCategories) ??
    getSubCategoryLabel(fileUploaded?.category || '', subCategories) ??
    '-'
  const fileTags = fileCategories.tags
    ? getTagLabels(fileCategories.tags, tags)
    : fileUploaded
    ? fileUploaded?.tags.map((tag: TOption) => tag.label)
    : []

  return (
    <Collapse isOpened={!fileCollapse} theme={{ collapse: fileIsLocked ? 'disabled-wrapper' : '' }}>
      {' '}
      <Flex
        className={fileIsLocked ? 'disabled' : ''}
        flexDirection={['column', 'column', 'row']}
        px={[2, '12px']}
        py={['14px']}
        onClick={() => {
          setFileCollapse((prev: boolean) => {
            if (hideFileEditing || fileIsLocked) {
              return false
            }
            return !prev
          })
        }}
      >
        <Flex alignItems={['center']} flexBasis={['100%', '40%']} flexShrink={[0]}>
          {!hideFileEditing && (
            <Box className={styles.text_gray}>
              {fileIsLocked ? <LockOutlined /> : loading ? <LoadingOutlined /> : <RightOutlined />}
            </Box>
          )}

          <Flex alignItems={['center']} ml={['7px']}>
            <Box className={styles.text_gray} flexShrink={[0]}>
              <FilePdfOutlined />
            </Box>
            <Box
              fontSize={['16px']}
              fontWeight={[700]}
              lineHeight={['20px']}
              px={['10px']}
              alignSelf={['flex-end']}
            >
              {fileUploading?.name ?? fileUploaded?.name}
            </Box>
          </Flex>
        </Flex>
        <Flex
          alignItems={['flex-start', 'center', 'center']}
          flexBasis={['100%', '60%']}
          flexShrink={[0]}
          mt={[2, 3, 0]}
          ml={['22px', '22px', 0]}
          flexDirection={['column', 'row', 'row']}
        >
          <Flex
            flexDirection={['column']}
            flexBasis={['100%', '30%']}
            flexShrink={[0]}
            mt={[2, 0]}
            ml={['22px', 0]}
            pr={['10px']}
          >
            <Box
              className={styles.text_gray}
              fontSize={['9px']}
              lineHeight={['11px']}
              letterSpacing={['0.19px']}
            >
              {t('category')}
            </Box>
            <Box
              fontSize={['14px']}
              lineHeight={['17px']}
              letterSpacing={['0.3px']}
              fontWeight={[500]}
            >
              {fileCategory}
            </Box>
          </Flex>
          <Flex
            flexDirection={['column']}
            flexBasis={['100%', '30%']}
            flexShrink={[0]}
            mt={[2, 0]}
            ml={['22px', 0]}
            pr={['10px']}
          >
            <Box
              className={styles.text_gray}
              fontSize={['9px']}
              lineHeight={['11px']}
              letterSpacing={['0.19px']}
            >
              {t('subcategory')}
            </Box>
            <Box
              fontSize={['14px']}
              lineHeight={['17px']}
              letterSpacing={['0.3px']}
              fontWeight={[500]}
            >
              {fileSubCategory}
            </Box>
          </Flex>
          <Flex
            flexDirection={['row']}
            alignItems={['center']}
            flexBasis={['100%', 'auto']}
            flexWrap={['wrap']}
            mt={[2, 0]}
            ml={['22px', 0]}
            pr={['10px']}
          >
            {fileTags.map((tagName: string) => (
              <Box
                fontSize={['12px']}
                lineHeight={['15px']}
                letterSpacing={['0.3px']}
                mt={[1]}
                mr={[1]}
                key={tagName}
              >
                <Tag style={getTagColorByName(tagName)}>{tagName}</Tag>
              </Box>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </Collapse>
  )
}

export default Header
