import { Box, Flex } from 'reflexbox'

import { TChildCategories } from 'context/TaxReturns'
import styles from '../categorize.module.scss'
import SubCategory from './SubCategory'

type TProps = {
  title: string
  documentsCount: number
  slagCategory: string
  subCategories: TChildCategories[]
  state?: string
  isHeaderSubCategory?: boolean
  capitalizeTitle?: boolean
}

const Header = ({
  title,
  documentsCount,
  slagCategory,
  subCategories,
  state,
  isHeaderSubCategory = false,
  capitalizeTitle = false,
}: TProps) => {
  return (
    <Flex
      alignItems={['flex-start', 'flex-start', 'center']}
      justifyContent={['space-between']}
      my={['6px']}
      width={['100%']}
      flexDirection={['column', 'column', 'row']}
    >
      <Flex alignItems={['center']}>
        <Box
          fontSize={['20px']}
          lineHeight={['24px']}
          fontWeight={[600]}
          style={{ textTransform: capitalizeTitle ? 'uppercase' : 'none' }}
        >
          {title}
        </Box>
        {!!documentsCount && <span className={styles.label}>{documentsCount}</span>}
      </Flex>
      {!isHeaderSubCategory && (
        <Flex mt={[0, 2, 0]} flexWrap={['wrap']}>
          {subCategories.map((subCategory) => (
            <SubCategory
              key={subCategory.slug}
              slagCategory={slagCategory}
              subCategory={subCategory}
              state={state ?? null}
            />
          ))}
        </Flex>
      )}
    </Flex>
  )
}

export default Header
