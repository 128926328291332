import { useEffect } from 'react'
import { Image } from 'antd'
import cn from 'classnames'
import { isImage, isPDF } from 'utils/files'

import { useEntity } from 'context/Entity'

import usePrintPDF from 'hooks/usePrintPDF'

import { SpinnerBackdrop } from 'components'
import Header from 'pages/TaxOrganizer/Document/components/DocumentView/components/Header'
import PDFdocument from 'pages/TaxOrganizer/Document/components/DocumentView/components/PDFDocument'
import Unsupported from 'components/Unsupported'

import styles from './documentview.module.scss'

const DocumentView = () => {
  const {
    entity: { currentEntityDocument },
  } = useEntity()

  const {
    height,
    documentRef,
    documentWrapperHeight,
    setDocumentWrapperHeight,
    pdfError,
    setPDFError,
    documentLoaded,
    setDocumentLoaded,
    activePage,
    setActivePage,
    scale,
    setScale,
    printPluginInstance,
    pageNavigationPluginInstance,
    jumpToPage,
  } = usePrintPDF()
  const fileIsPdf = isPDF(currentEntityDocument.presignFilePath)
  const fileIsImage = isImage(currentEntityDocument.presignFilePath)

  useEffect(() => {
    setDocumentWrapperHeight(document.querySelector('.rpv-core__canvas-layer')?.clientHeight)
    jumpToPage(activePage - 1)
  }, [activePage, scale, height])

  return (
    <div className={styles.document_view}>
      {!documentLoaded && <SpinnerBackdrop />}
      {(fileIsPdf || fileIsImage) && (
        <Header
          activePage={activePage}
          total={currentEntityDocument.pageCount}
          setActivePage={setActivePage}
          printPluginInstance={printPluginInstance}
          pdfError={pdfError}
          documentLoaded={documentLoaded}
          fileIsImage={fileIsImage}
          fileUrl={currentEntityDocument.file}
        />
      )}
      <div
        className={cn(styles.document, {
          [styles.document_image]: !fileIsPdf,
        })}
        ref={documentRef}
      >
        {fileIsPdf ? (
          <PDFdocument
            fileUrl={currentEntityDocument.file!}
            printPluginInstance={printPluginInstance}
            setPDFLoaded={setDocumentLoaded}
            pageNavigationPluginInstance={pageNavigationPluginInstance}
            setPDFError={setPDFError}
            documentWrapperHeight={documentWrapperHeight}
            setScale={setScale}
          />
        ) : fileIsImage ? (
          <Image
            src={currentEntityDocument?.file!}
            preview={false}
            width="100%"
            onLoad={() => setDocumentLoaded(true)}
          />
        ) : (
          <Unsupported
            setDocumentLoaded={setDocumentLoaded}
            fileUrl={currentEntityDocument.file}
            fileName={currentEntityDocument.name}
          />
        )}
      </div>
    </div>
  )
}

export default DocumentView
