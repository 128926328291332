import { useState } from 'react'
import { ArrowRightOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Button, Modal } from 'components'
import { inRange } from 'lodash'
import { useTranslation } from 'react-i18next'

import { InputNumber } from 'antd'
import styles from '../../documentview.module.scss'

const START_PAGE = 1

type Props = {
  activePage?: number
  setActivePage?: (pageIndex: number) => void
  page: number
  total: number
  onChange: (page: number) => void
}

const Pagination = ({ activePage, setActivePage, page, total, onChange }: Props) => {
  const { t } = useTranslation('taxOrganizer')
  const [modalVisible, setModalVisible] = useState(false)
  const [value, setValue] = useState<string | number>('')

  const handleBack = () => {
    if (page >= START_PAGE) onChange(page - 1)
  }

  const handleNext = () => {
    if (page <= total) onChange(page + 1)
  }

  const handlePress = () => {
    if (!value || !inRange(Number(value), START_PAGE, total + 1)) {
      return
    }
    if (setActivePage && typeof setActivePage === 'function') {
      setActivePage(Number(value))
    }
    setModalVisible(false)
  }

  const handleStep = (n: number) => {
    if (!n || !inRange(Number(n), START_PAGE, total + 1)) {
      return
    }
    setValue(n)
    if (setActivePage && typeof setActivePage === 'function') {
      setActivePage(Number(n))
    }
  }

  return (
    <div className="text-center">
      <div className={styles.pagination}>
        <Button
          className={styles.pagination_arrow}
          onClick={handleBack}
          disabled={activePage === START_PAGE}
          icon={<LeftOutlined />}
          type="text"
        />
        <Modal
          showCloseIcon={false}
          content={
            <div className={styles.number_input}>
              <InputNumber
                min={START_PAGE}
                max={total}
                value={activePage}
                addonBefore={t('document.pagination.gotToPage')}
                addonAfter={<ArrowRightOutlined className="p-2" onClick={handlePress} />}
                onPressEnter={handlePress}
                onChange={setValue}
                onStep={handleStep}
              />
            </div>
          }
          visible={modalVisible}
          onVisibleChange={(visible) => setModalVisible(visible)}
        />

        <Button type="text" onClick={() => setModalVisible(true)}>
          <span className="text-bold me-1">{page}</span>/ {total}
        </Button>

        <Button
          className={styles.pagination_arrow}
          onClick={handleNext}
          disabled={activePage === total}
          icon={<RightOutlined />}
          type="text"
        />
      </div>
    </div>
  )
}

export default Pagination
