import { useEffect, useCallback } from 'react'
import { Avatar, Comment, Tag } from 'antd'
import { formatDistance } from 'date-fns'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import { Box } from 'reflexbox'

import useLocale from 'hooks/useLocale'
import { fieldIsLocked, getInitials } from 'utils'

import { Alert, Button, SpinnerBackdrop } from 'components'

import { useTaxOrganizer } from 'context/TaxOrganizer'
import { useMessagesActions, useMessages, IThread, IThreadMessage } from 'context/Messages'
import { useAuth } from 'context/Auth'

import { FileOutlined } from '@ant-design/icons'

import styles from './tax.messages.list.module.scss'

type Props = {
  thread: IThread
  taxOrganizerGuid: string
}

const Messages = ({ thread, taxOrganizerGuid }: Props) => {
  const {
    loadMessageThreadDetails,
    loadMessageThreadDocument,
    markThreadRead,
    setCurrentThreadStatus,
    loading,
  } = useMessagesActions()
  const { currentThreadMessages, currentThreadDocument, currentThreadIsLocked } = useMessages()
  const { user } = useAuth()
  const {
    taxOrganizer: {
      data: { isLocked },
    },
  } = useTaxOrganizer()

  const isCurrentUserPost = (threadMessage: IThreadMessage) => {
    return user?.guid === threadMessage.createdBy.guid
  }

  const fetchData = useCallback(async () => {
    await loadMessageThreadDetails(thread.guid)
    if (thread.guid !== '0') {
      await markThreadRead(thread.guid)
    }
    if (thread.context) {
      await loadMessageThreadDocument(taxOrganizerGuid, thread.context.taxDocument)
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    if (currentThreadMessages.length) {
      setCurrentThreadStatus(
        fieldIsLocked(
          isLocked,
          currentThreadMessages[currentThreadMessages.length - 1].afterTaxOrganizerLock
        )
      )
    }
  }, [currentThreadMessages])

  const newStartAt = currentThreadMessages.findIndex(({ read }) => !read)
  const locale = useLocale()
  const { t } = useTranslation('taxOrganizer')
  if (loading) {
    return <SpinnerBackdrop />
  }
  return (
    <div className={styles.container}>
      {currentThreadIsLocked && (
        <Box mt={[3]} ml={[2]}>
          <Alert message={t('locked.message')} type="warning" />
        </Box>
      )}

      <div className={styles.head}>
        <div className={styles.info}>
          <Tag style={{ borderRadius: '10px', backgroundColor: 'white' }}>
            #{String(thread.referenceNumber || t('messages.new')).padStart(3, '0')}
          </Tag>
          {thread.context && (
            <>
              <div className={styles.info_ref} title={currentThreadDocument?.name}>
                {t('messages.documentComment')} <FileOutlined />{' '}
                <span>{currentThreadDocument?.name}</span>
              </div>
              <Button
                href={`/tax-organizer/${taxOrganizerGuid}/documents/${currentThreadDocument?.guid}`}
                type="link"
                size="large"
                className={styles.info_button}
              >
                {t('messages.viewDocument')}
              </Button>
            </>
          )}
        </div>
      </div>
      <div className={styles.messages}>
        {currentThreadMessages
          .sort((a, b) => +new Date(a.createdOn) - +new Date(b.createdOn))
          .map((message, i) => (
            <div key={message.guid}>
              {i === newStartAt && (
                <div className={styles.message_new} key="new">
                  <div>{t('messages.new')}</div>
                </div>
              )}
              <div
                key={message.guid}
                className={cn(
                  isCurrentUserPost(message) ? styles.message_author : styles.message_response
                )}
              >
                <Comment
                  author={`${message.createdBy.firstName.charAt(0)}. ${message.createdBy.lastName}`}
                  avatar={
                    message.createdBy.avatar ? (
                      <Avatar src={message.createdBy.avatar} />
                    ) : (
                      <Avatar size="small" className={styles.message_icon}>
                        {getInitials(message.createdBy)}
                      </Avatar>
                    )
                  }
                  content={message.message}
                  datetime={formatDistance(new Date(message.createdOn), new Date(), {
                    addSuffix: true,
                    locale,
                  })}
                  className={cn(styles.message, isCurrentUserPost(message) ? styles.author : '')}
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}

export default Messages
