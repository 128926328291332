import { useState, useEffect, useRef, useCallback } from 'react'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'
import {
  useEntity,
  useEntityDispatch,
  useEntityDocumentsActions,
  ActionTypes,
} from 'context/Entity'

import { getMessageFromResponse } from 'utils/http'
import useAlert from 'hooks/useAlert'

type TStatus = 'IDLE' | 'START' | 'END_SUCCESS' | 'END_RETRY' | 'END_FAILURE'

const REQUEST_INTERVAL = 2000
const MAX_RETRY_FETCH_DOCUMENT = 5

export default () => {
  const { t } = useTranslation('taxOrganizer')
  const {
    entity: { currentEntityDocument: document },
  } = useEntity()
  const { loadDocument, response } = useEntityDocumentsActions()
  const dispatch = useEntityDispatch()
  const [status, setStatus] = useState<TStatus>('START')
  const [loading, setLoading] = useState<boolean>(true)
  const [refetchDocumentCounter, setrefetchDocumentCounter] = useState<number>(0)
  const { alert, setAlert, resetAlert } = useAlert()
  const timeoutId = useRef<NodeJS.Timeout>()

  const getDocument = useCallback(async (scopeGuid: string, documentGuid: string) => {
    if (['END_SUCCESS', 'END_RETRY', 'END_FAILURE'].includes(status)) {
      return
    }
    await loadDocument(scopeGuid, documentGuid)
    setrefetchDocumentCounter((counter) => ++counter)
    if (response.ok) {
      timeoutId.current = setTimeout(() => {
        getDocument(scopeGuid, documentGuid)
      }, REQUEST_INTERVAL)
    } else {
      setStatus('END_FAILURE')
    }
  }, [])

  useEffect(() => {
    if (document?.status === 'REA') {
      setStatus('END_SUCCESS')
    }
  }, [document?.status])

  useEffect(() => {
    if (status === 'START') {
      resetAlert()
    }
    if (status === 'END_SUCCESS') {
      setLoading(false)
      clearTimeout(timeoutId.current as NodeJS.Timeout)
    }
    if (status === 'END_RETRY') {
      setLoading(false)
      clearTimeout(timeoutId.current as NodeJS.Timeout)
      setAlert({
        type: 'error',
        message: t('document.errors.pending'),
        status: true,
      })
    }
    if (status === 'END_FAILURE') {
      setLoading(false)
      clearTimeout(timeoutId.current as NodeJS.Timeout)
      message.error(getMessageFromResponse(response))
    }
  }, [status])

  useEffect(() => {
    if (refetchDocumentCounter >= MAX_RETRY_FETCH_DOCUMENT) {
      setStatus('END_RETRY')
    }
  }, [refetchDocumentCounter])

  useEffect(() => {
    return () => {
      dispatch({ type: ActionTypes.LOAD_ENTITY_DOCUMENT, payload: undefined })
      clearTimeout(timeoutId.current as NodeJS.Timeout)
    }
  }, [])

  return { getDocument, loading, alert, document }
}
