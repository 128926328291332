import { Dropdown, Menu, Spin, Modal } from 'antd'
import { Link } from 'react-router-dom'
import { EllipsisOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { Box } from 'reflexbox'

import { useDocumentsActions, TTaxDocument, useTaxOrganizer } from 'context/TaxOrganizer'

const { confirm } = Modal

type Props = {
  guid: string
  document: Partial<TTaxDocument>
}

const Actions = ({ guid, document }: Props) => {
  const { deleteDocument, loading } = useDocumentsActions()
  const {
    taxOrganizer: {
      data: { guid: taxOrganizerGuid },
    },
  } = useTaxOrganizer()
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'documents' })
  const { t: t2 } = useTranslation('common')

  const handleDelete = async () => {
    confirm({
      maskClosable: true,
      title: t('deleteConfirmation'),
      icon: <ExclamationCircleOutlined />,
      okText: t2('switcher.yes'),
      okType: 'danger',
      cancelText: t2('switcher.no'),
      onOk() {
        return new Promise((resolve) => {
          deleteDocument(taxOrganizerGuid, guid).then(() => resolve(true))
        })
      },
    })
  }

  return loading ? (
    <Spin size="small" />
  ) : (
    <Dropdown
      placement="bottomRight"
      trigger={['click']}
      overlay={
        <Menu theme="dark">
          <Menu.Item key="Edit">
            <Link to={`/tax-organizer/${taxOrganizerGuid}/documents/${guid}`}>
              <Box fontSize={['14px']}>{t('columns.actions.edit')}</Box>
            </Link>
          </Menu.Item>
          <Menu.Item key="Download">
            <Link to={{ pathname: document.file! }} target="_blank" download>
              <Box fontSize={['14px']}>{t('columns.actions.download')}</Box>
            </Link>
          </Menu.Item>
          <Menu.Item key="Delete" onClick={handleDelete}>
            <Box fontSize={['14px']}>{t('columns.actions.delete')}</Box>
          </Menu.Item>
        </Menu>
      }
    >
      <EllipsisOutlined style={{ transform: 'rotate(90deg)' }} />
    </Dropdown>
  )
}

export default Actions
