import { sortBy } from 'lodash'
import { getTaxYears } from 'utils'
import { ActionTypes } from './action-types'
import { TAction, TState, DocumentCategory, DocumentTag } from './types'

export const initialState: TState = {
  taxOrganizersList: [],
  total: 0,
  mostResent: undefined!,
  activityFeed: null,
  deadlines: [],
  deadline: {
    selected: undefined,
    all: true,
  },
  totalReturns: undefined,
  primaryContacts: {
    data: [],
    count: 0,
  },
  summary: {
    phases: [],
    deadline: {},
    extended: null,
    total: 0,
    extensionDeadlines: null,
  },
  taxYears: {
    range: getTaxYears(),
    options: getTaxYears().map((year) => ({ label: year, value: year })),
  },
  taxYear: undefined,
  tags: [],
  categories: [],
  subCategories: [],
  cpaAssocitatedUsers: {
    data: [],
    count: 0,
  },
  chartData: [],
}

export const DashboardReducer = (state: TState = initialState, action: TAction) => {
  const { type, payload } = action
  switch (type) {
    case ActionTypes.SET_TAX_LIST:
      return {
        ...state,
        taxOrganizersList: payload?.results ?? [],
        total: payload?.count ?? 0,
      }

    case ActionTypes.LOAD_TAX_DEADLINES: {
      return {
        ...state,
        deadlines: payload,
      }
    }

    case ActionTypes.LOAD_MOST_RECENT:
      return {
        ...state,
        mostResent: payload,
      }

    case ActionTypes.LOAD_ACTIVITY_FEED:
      return {
        ...state,
        activityFeed: payload,
      }

    case ActionTypes.LOAD_PRIMARY_CONTACTS:
      return {
        ...state,
        primaryContacts: {
          data: payload ? payload.results : [],
          count: payload ? payload.count : 0,
        },
      }

    case ActionTypes.LOAD_TAX_SUMMARY: {
      return {
        ...state,
        summary: payload,
      }
    }

    case ActionTypes.LOAD_DOCUMENTS_TAGS: {
      return {
        ...state,
        tags: sortBy(
          payload.map((tag: DocumentTag) => ({
            value: tag.slug,
            label: tag.name,
          })),
          'label'
        ),
      }
    }

    case ActionTypes.LOAD_DOCUMENTS_CATEGORIES: {
      return {
        ...state,
        categories: payload
          .filter((category: DocumentCategory) => !category.parent)
          .map((category: DocumentCategory) => ({
            value: category.slug,
            label: category.title,
          })),
        subCategories: payload
          .filter((category: DocumentCategory) => category.parent)
          .map((category: DocumentCategory) => ({
            value: category.slug,
            label: category.title,
            parent: category.parent,
          })),
      }
    }

    case ActionTypes.SET_DEADLINE: {
      const selected = state.deadlines.find(({ guid }) => guid === payload.deadlineGuid)
      return {
        ...state,
        deadline: {
          selected,
          all: !selected,
        },
      }
    }

    case ActionTypes.SET_TAX_YEAR: {
      return {
        ...state,
        taxYear: payload,
      }
    }

    case ActionTypes.SET_CPA_ASSOCIATED_USERS:
      return {
        ...state,
        cpaAssocitatedUsers: {
          data: payload?.results ?? [],
          count: payload?.count ?? 0,
        },
      }

    case ActionTypes.LOAD_BURN_DOWN_CHART_DATA:
      return {
        ...state,
        chartData: payload?.chartData ?? [],
      }

    default:
      throw new Error(`Unhandled action type: ${type}`)
  }
}
