import { useTranslation } from 'react-i18next'
import { Flex, Box } from 'reflexbox'

type Props = {
  progress: { total: number; loaded: number; width: number; filesLeft: number }
}

const Title = ({ progress }: Props) => {
  const { t } = useTranslation('common', { keyPrefix: 'upload.modal' })

  return (
    <Flex flexWrap={['wrap']} justifyContent={['space-between']} alignItems={['center']}>
      <Flex
        width={['100%', 'auto']}
        fontSize={['16px', '20px']}
        lineHeight={['24px']}
        fontWeight={[900]}
        flexDirection={['column', 'row', 'row']}
      >
        <Box>{t('titleMain')}</Box>
      </Flex>
      <Flex
        width={['100%', '100%', 'auto']}
        justifyContent={['space-between']}
        alignItems={['center']}
        px={[0, 0]}
        mt={['10px', '10px', 0]}
      >
        <Flex fontSize={['14px']} lineHeight={['17px']} flexShrink={[0]} alignItems={['center']}>
          <Box fontWeight={[600]} mr={['5px']}>
            {progress.filesLeft}
          </Box>
          <Box fontWeight={[400]}>{t('filesLeft')}</Box>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Title
