import { useCallback, useState } from 'react'
import { AutoComplete } from 'antd'
import { debounce } from 'lodash'
import { useFetch } from 'use-http'
import { LoadingOutlined } from '@ant-design/icons'
import { getAddressComponentByType, toSnake } from 'utils'
import qs from 'qs'

import './styles.scss'
import styles from './googleadress.module.scss'

import { TGoogleAddressComponent, TGoogleAddressResult } from './types'

type Props = {
  onSelect: (addressComponents: TGoogleAddressComponent[]) => void
  placeholder?: string
  onChange?: (input: string) => void
}

const GoogleAddressLookup = ({ onSelect, placeholder, ...otherProps }: Props) => {
  const { get, loading } = useFetch()
  const [addressOptions, setAddressOptions] = useState<TGoogleAddressResult[]>([])

  const addressSearchHandler = async (query: string) => {
    const addressList = await get(
      `/integrations/google-places/address-search?${qs.stringify({ query })}`
    )
    setAddressOptions(addressList?.predictions)
  }
  const debouncedAddressSearchHandler = useCallback(debounce(addressSearchHandler, 300), [])

  const getPlaceHandler = async (placeId: string) => {
    onSelect([])
    const placeInformation = await get(
      `/integrations/google-places/place-details?${qs.stringify(toSnake({ placeId }))}`
    )
    const addressComponents = placeInformation?.result?.addressComponents || []

    const streetAddress = getAddressComponentByType(addressComponents, 'streetAddress')
    if (!streetAddress) {
      const selectedPlace = addressOptions.find((option) => option.placeId === placeId)
      const formattedAddress = selectedPlace?.structuredFormatting?.mainText
      addressComponents.push({
        longName: formattedAddress,
        shortName: formattedAddress,
        types: ['streetAddress'],
      })
    }

    onSelect(placeInformation?.result?.addressComponents)
  }

  return (
    <div className={styles.field}>
      <AutoComplete
        className={styles.input}
        options={addressOptions.map((option) => ({
          label: option?.description,
          value: option?.placeId,
        }))}
        placeholder={placeholder}
        onSelect={getPlaceHandler}
        {...otherProps}
        onChange={(query) => {
          if (otherProps?.onChange) otherProps.onChange(query)
          debouncedAddressSearchHandler(query)
        }}
      />
      {loading && <LoadingOutlined className={styles.icon} />}
    </div>
  )
}

export default GoogleAddressLookup
