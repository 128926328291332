import { Switch, Route, useRouteMatch } from 'react-router-dom'

import { CPAFirmProvider } from 'context/CPAFirm'
import { BillingProvider } from 'context/Billing'

import Profile from 'pages/Dashboard/Firm/Profile'
import Team from 'pages/Dashboard/Firm/Team'
import Billing from 'pages/Dashboard/Firm/Billing'

import Navigation from './components/Navigation'

const FirmLayout = () => {
  const { path } = useRouteMatch()

  return (
    <>
      <Navigation />
      <Switch>
        <Route path={`${path}/profile`} exact>
          <Profile isEditing />
        </Route>
        <Route path={`${path}/team/:slug?`}>
          <Team />
        </Route>
        <Route path={`${path}/billing`} exact>
          <BillingProvider>
            <Billing />
          </BillingProvider>
        </Route>
      </Switch>
    </>
  )
}

export default () => (
  <CPAFirmProvider>
    <FirmLayout />
  </CPAFirmProvider>
)
