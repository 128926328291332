import { useState, useEffect, useMemo } from 'react'

import { TFile } from 'context/TaxOrganizer'
import { useTaxReturnsActions } from 'context/TaxReturns'

import { TFileProgressSettings } from 'components/UploadModal/types'
import { getProgress, setTotalProgressHelper } from 'components/UploadModal/helpers'

type TProps = {
  files: TFile[]
  onFinishUploading?: () => void
}

export default ({ files, onFinishUploading }: TProps) => {
  const [totalProgress, setTotalProgress] = useState<TFileProgressSettings[]>(
    files?.map(({ uid }: TFile) => ({
      uid,
      total: 0,
      loaded: 0,
      start: false,
    })) ?? []
  )

  const { generateSignedUploadURL } = useTaxReturnsActions()

  useEffect(() => {
    const startUploadDocument = async (file: TFile) => {
      await generateSignedUploadURL(
        {
          file: file.name,
          name: file.name,
        },
        {
          file: file.originFileObj,
          onUploadProgress: (event: ProgressEvent) => {
            setTotalProgress((prevData: TFileProgressSettings[]) =>
              setTotalProgressHelper(prevData, file, event)
            )
          },
        }
      )
    }
    if (files?.length) {
      files.forEach((file: TFile) => {
        startUploadDocument(file)
      })
    }
  }, [])
  const progress = useMemo(
    () => getProgress(totalProgress, files, onFinishUploading),
    [totalProgress]
  )
  return { progress, totalProgress }
}
