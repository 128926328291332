import { Alert, AlertProps } from 'antd'

import './styles.scss'
import styles from './alert.module.scss'

type TProps = {
  sameColor?: boolean
  message?: React.ReactNode
} & AlertProps

const AlertComponent = ({ sameColor, message, ...rest }: TProps) => {
  return (
    <Alert
      message={
        sameColor ? (
          <span className={sameColor && rest.type ? styles[rest.type] : ''}>{message}</span>
        ) : (
          message
        )
      }
      {...rest}
    />
  )
}

export default AlertComponent
export * from './types'
