import { useEffect } from 'react'
import { Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { Box, Flex } from 'reflexbox'

import {
  useDocumentsActions,
  useTaxOrganizer,
  TMultipleDocumentParams,
  TTaxDocumentExtended,
} from 'context/TaxOrganizer'

import { SpinnerBackdrop, Button } from 'components'
import File from 'components/UploadModal/components/File'
import TitleModal from './TitleModal'

import styles from '../../../tax.documents.module.scss'

type Props = {
  onHide: () => void
}

const UploadModal = ({ onHide }: Props) => {
  const { t } = useTranslation('common', { keyPrefix: 'upload.modal' })
  const {
    documents: {
      mulptipleDocumentParams,
      // dataRequiresAction: documents,
      data: documents,
      documentsSummary,
    },
    taxOrganizer: {
      data: { guid: taxOrganizerGuid, entity, taxYear },
    },
  } = useTaxOrganizer()
  const { clearMulptipleDocumentParams } = useDocumentsActions()
  const { updateDocuments, loading: updateLoading } = useDocumentsActions()
  const { loading, loadDocumentsSummary } = useDocumentsActions()
  const {
    deleteDocument,
    response: deleteResponse,
    removeMulptipleDocumentParam,
  } = useDocumentsActions()

  const checkValidation = () =>
    !mulptipleDocumentParams.find(
      (param: TMultipleDocumentParams) => param.validateStatus === 'error'
    )

  const handleRemove = async (fileUploadedGuid: string | undefined) => {
    if (fileUploadedGuid) {
      await deleteDocument(taxOrganizerGuid, fileUploadedGuid)
      if (deleteResponse.ok) {
        removeMulptipleDocumentParam(fileUploadedGuid)
      }
    }
  }

  const handleSaveAll = async () => {
    if (!mulptipleDocumentParams.length) {
      onHide()
      return
    }
    if (checkValidation()) {
      await updateDocuments(taxOrganizerGuid, mulptipleDocumentParams)
      clearMulptipleDocumentParams()
      onHide()
    }
  }

  const handleCancell = () => {
    onHide()
    clearMulptipleDocumentParams()
  }

  useEffect(() => {
    if (!documents.length) {
      onHide()
    } else {
      loadDocumentsSummary(taxOrganizerGuid)
    }
  }, [documents])

  return (
    <Modal
      visible
      title={
        <TitleModal
          title={`${t('categorize')}: ${entity.title}, ${entity.kind.label} ${taxYear}`}
          countFiles={documentsSummary?.requiresAction ?? 0}
          hideModal={onHide}
        />
      }
      className={styles.modal}
      width={1180}
      destroyOnClose
      closable={false}
      onCancel={handleCancell}
      footer={[
        <Box mr={[3]} mb={[1]} key="submit-block">
          <Button
            key="submit"
            type="primary"
            ghost
            size="large"
            disabled={updateLoading}
            onClick={handleSaveAll}
            loading={updateLoading}
          >
            {t('saveAll')}
          </Button>
        </Box>,
      ]}
    >
      {loading && <SpinnerBackdrop />}
      <Flex flexDirection={['column']} mt={['10px']}>
        {documents.map((uploadedFile: TTaxDocumentExtended) => {
          return (
            <File key={uploadedFile.guid} onRemove={handleRemove} fileUploaded={uploadedFile} />
          )
        })}
      </Flex>
    </Modal>
  )
}

export default UploadModal
