import { Typography, Form, Select } from 'antd'
import { Flex, Box } from 'reflexbox'
import { isEmpty, concat, filter } from 'lodash'
import { useTranslation } from 'react-i18next'
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons'

import { Button, TextField } from 'components'

import { TInviteClient } from '../types'
import useEntities from '../Header/hooks/useEntities'

const { Title, Text } = Typography

type TProps = {
  title: string
  fieldName: string
  errors: Partial<TInviteClient>
  initialEntities?: IEntity[]
  deletedDocIds?: string[]
  setDeletedDocIds?: (docIds: string[]) => void
}

const ClientInvitationEntity = ({
  title,
  fieldName,
  errors,
  initialEntities,
  deletedDocIds,
  setDeletedDocIds,
}: TProps) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'clients.popover' })
  const { t: t2 } = useTranslation('common', { keyPrefix: 'form' })
  const { entityKinds } = useEntities()

  return (
    <>
      <Box mb={[2, 3]}>
        <Title level={5}>{title}</Title>
      </Box>
      <Form.List name={fieldName}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ name, key, fieldKey, ...rest }: any) => {
              const documents: IEntityDocument[] = filter(
                initialEntities?.[name]?.documents,
                (doc: IEntityDocument) => !deletedDocIds?.includes(doc.guid)
              )

              return (
                <div key={key}>
                  <Flex
                    alignItems={['baseline']}
                    justifyContent={['space-between']}
                    alignContent={['center']}
                    flexDirection={['row']}
                    width={['100%']}
                    my={[2, 0]}
                  >
                    <Flex
                      alignItems={['baseline']}
                      justifyContent={['space-between']}
                      alignContent={['center']}
                      flexDirection={['column', 'row']}
                      width={['100%']}
                    >
                      <Box mr={[0, '16px']} width={['100%', '40%']} mb={[1, 3]}>
                        <Form.Item
                          {...rest}
                          name={[name, 'name']}
                          fieldKey={[fieldKey, 'name']}
                          rules={[
                            {
                              required: true,
                              message: t2('requiredField', { field: t2('entityName') }),
                            },
                          ]}
                          validateStatus={errors?.entities?.[key + 1]?.name && 'error'}
                          help={errors?.entities?.[key + 1]?.name}
                          style={{ margin: '0' }}
                        >
                          <TextField type="text" placeholder={t2('entityName')} />
                        </Form.Item>
                      </Box>
                      <Box mr={[0, '16px']} width={['100%', '30%']} mb={[1, 3]}>
                        <Form.Item
                          name={[name, 'kind']}
                          fieldKey={[fieldKey, 'kind']}
                          rules={[
                            {
                              required: true,
                              message: t2('requiredField', { field: t2('entityKind') }),
                            },
                          ]}
                          validateStatus={errors?.entities?.[key + 1]?.kind && 'error'}
                          help={errors?.entities?.[key + 1]?.kind}
                          style={{ margin: '0' }}
                        >
                          <Select
                            options={entityKinds}
                            placeholder={t2('entityKind')}
                            dropdownMatchSelectWidth={false}
                          />
                        </Form.Item>
                      </Box>
                      <Box mr={[0, '16px']} width={['100%', '30%']} mb={[1, 3]}>
                        <Form.Item
                          {...rest}
                          name={[name, 'tin']}
                          fieldKey={[fieldKey, 'tin']}
                          rules={[
                            {
                              required: true,
                              message: t2('requiredField', { field: t2('tin') }),
                            },
                            { pattern: /^\d{9}$/, message: t2('validTin') },
                          ]}
                          validateStatus={errors?.entities?.[key + 1]?.tin && 'error'}
                          help={errors?.entities?.[key + 1]?.tin}
                          style={{ margin: '0' }}
                        >
                          <TextField type="text" placeholder={t2('tin')} maxLength={9} />
                        </Form.Item>
                      </Box>
                    </Flex>

                    <Flex width={['50px', 'auto']} justifyContent={['center']}>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Flex>
                  </Flex>
                  {!isEmpty(documents) && (
                    <Box style={{ marginBottom: 16, marginLeft: 16 }}>
                      <Box>
                        <Text style={{ fontSize: 14, fontWeight: 600 }}>{t2('attachments')}</Text>
                      </Box>
                      {documents.map((doc: IEntityDocument) => (
                        <Flex
                          key={doc.guid}
                          alignItems={['baseline']}
                          justifyContent={['space-between']}
                          alignContent={['center']}
                          flexDirection={['row']}
                        >
                          <Box mr={[0, '16px']} width={['100%', '60%']}>
                            <a
                              href={doc.file}
                              style={{
                                textDecoration: 'underline',
                                whiteSpace: 'normal',
                                wordBreak: 'break-all',
                              }}
                              download
                              target="_blank"
                              rel="noreferrer"
                            >
                              {doc.name}
                            </a>
                          </Box>
                          <Box mr={[0, '16px']} width={['100%', '20%']}>
                            <Text>{t2('year', { year: doc.year })}</Text>
                          </Box>
                          <Box>
                            <MinusCircleOutlined
                              onClick={() =>
                                setDeletedDocIds?.(concat(deletedDocIds || [], doc.guid))
                              }
                            />
                          </Box>
                        </Flex>
                      ))}
                    </Box>
                  )}
                </div>
              )
            })}
            <Form.Item>
              <Button type="dashed" onClick={() => add()} size="large" icon={<PlusOutlined />}>
                {t('addButton')}
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  )
}

export default ClientInvitationEntity
