import { EnumStatus } from 'context/CPAFirm'
import { Res } from 'use-http'
import { ActionTypes } from './action-types'

export enum UserType {
  ENTITY = 'ENTITY',
  CPA = 'CPA',
}

export enum StaffEnum {
  staff = 'staff',
  member = 'member',
  admin = 'admin',
}

export type LoginRequest = {
  email: string
  password: string
}

export type RegisterRequest = {
  email: string
  password: string
  firstName: string
  lastName: string
  type: UserType
}

export type LoginToken = {
  access: string
  refresh: string
  type: UserType
  staff: StaffEnum
}

export type UserProfile = {
  firstName: string
  lastName: string
  phone: string
  enable2fa: boolean
  localeName: string
  guid: string
  email: string
  phoneConfirmed: boolean
  avatar: string | null
  termsAcceptedOn: string | null
  createdOn: string
  updatedOn: string
  emailConfirmed: boolean
  isStaff: boolean
  isActive: boolean
  hasV1Account: boolean
  type: UserType
}

export type ChangePassword = {
  newPassword: string
  oldPassword: string
}

export type ResetPasswordRequest = {
  email: string
}

export type ResetPasswordConfirm = {
  password: string
  token: string
}

export type ResetPasswordConfirmRequest = {
  password: string
  confirmPassword: string
}

export type EmailConfirmation = {
  token: string
  refreshToken: string | null
}

export enum ExternalApplication {
  V1 = 'v1',
  MARQIX = 'marqix',
}

export type InviteResponse = {
  email: string
  cpaFirm: {
    name: string
  }
}

export type StatusType = 'START' | 'END' | undefined

export type TIviteInfoCPA = {
  guid: string
  email: string
  cpaFirm: {
    guid: string
    name: string
  }
  role: ICPAUserRole
  status: EnumStatus
  expiresOn: string | null
  lastSentOn: string | null
  statusOn: string | null
  invitedBy: string
  letterhead: string
  accountExists: boolean
}

export type TIviteInfoEntity = {
  guid: string
  cpaFirm: {
    guid: string
    name: string
  }
  entities: {
    guid: string
    name: string
  }[]
  email: string
  lastSentOn: string | null
  invitedBy: string
  accountExists: boolean
  letterheadImage: string
}

export type IAuthContext = {
  token: LoginToken | null
  maskedToken: LoginToken | null
  maskedGuid: string | null
  user: UserProfile | null
  users: Array<UserProfile> | null
  CPA: {
    cpaUserInvites: {
      pending: number | undefined
    }
    cpaFirm: TCpaFirm | undefined
    userRole: ICPAUserRole | undefined
    subscriptionActive: boolean | null
  }
  ENTITY: {
    INDVEntitiesCount: number | undefined
    pendingInvitations: number | undefined
  }
  onboardingFlow: {
    status: StatusType
    responseOk: boolean | undefined
    step: number | undefined
  }
  invitationsFlow: {
    status: StatusType
    response: Res<any> | undefined
    inviteInfo: TIviteInfoCPA | TIviteInfoEntity | undefined
    userType: UserType | undefined
    forwardInvitation?: boolean
  }
  invitations: {
    entityUserInvitations: number
    clientInvitations: number
    firmInvitations: number
    entityFirmInvitations: number
    pendingInvitations: number
    previouslyAcceptedAnInvitation: boolean
  }
}

export type IAction = { type: keyof typeof ActionTypes; payload?: any }
