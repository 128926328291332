import { Empty } from 'antd'
import { useTranslation } from 'react-i18next'

import styles from './empty.module.scss'

type PropsType = {
  description?: JSX.Element | string
}

const EmptyComponent = ({ description, ...rest }: PropsType) => {
  const { t } = useTranslation('common')
  return <Empty className={styles.empty} {...rest} description={description ?? t('noData')} />
}

export default EmptyComponent
