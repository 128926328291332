import { SyntheticEvent } from 'react'
import { Box, Flex } from 'reflexbox'
import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import { ReactComponent as PaperFile } from 'assets/images/paper_file.svg'
import { ReactComponent as EFile } from 'assets/images/e-file.svg'

import {
  FilingStateEnum,
  TTaxReturn,
  useTaxReturns,
  useTaxReturnsActions,
} from 'context/TaxReturns'

import styles from '../../../dashboard.tax_return.module.scss'

const { Paragraph } = Typography

type TProps = {
  onSubmit: (isEFile: boolean) => void
  isReadyEFile: boolean
}

const AuthorizeForFilingContent = ({ onSubmit, isReadyEFile }: TProps) => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'taxReturns.review' })
  const { updateTaxReturn, response } = useTaxReturnsActions()
  const {
    state: {
      taxReturns,
      taxReturns: { latest: latestTaxReturn },
    },
  } = useTaxReturns()

  const handleUpdateTaxReturn = async (isEFile: boolean) => {
    if (!taxReturns.data.length) return

    const patchTaxReturnBody: Partial<TTaxReturn> = {
      ...latestTaxReturn,
      phase: isEFile ? 'AUTHORIZE' : 'FILING',
      filingState: isEFile
        ? FilingStateEnum.EFILE_PENDING_CONFIRMATION
        : FilingStateEnum.PAPER_PENDING_CONFIRMATION,
    }

    await updateTaxReturn(latestTaxReturn?.guid!, patchTaxReturnBody)

    if (response.ok) {
      onSubmit(isEFile)
    }
  }

  return (
    <Flex
      px={[2]}
      py={[3]}
      flexDirection={['column']}
      maxWidth={['100%', '100%', '100%']}
      onClick={(event: SyntheticEvent) => {
        event.stopPropagation()
      }}
    >
      <Box lineHeight={['24px']} fontSize={['20px']} fontWeight={[600]} mb={[3]}>
        {t('title')}
      </Box>

      <Paragraph className={styles.filing_description}>{t('description')}</Paragraph>
      <Flex maxWidth={['100%']} justifyContent={['space-evenly']}>
        {isReadyEFile && (
          <Box className={styles.box_icon} lineHeight={['24px']} fontSize={['15px']} mr={['5px']}>
            <Flex
              flexDirection={['column']}
              alignItems={['center']}
              justifyContent={['space-around']}
              height={['100%']}
            >
              <div
                className={styles.box_icon_content}
                onClick={() => {
                  if (isReadyEFile) handleUpdateTaxReturn(true)
                }}
              >
                <EFile />

                <div>{t('eFile')}</div>
              </div>

              <Paragraph>{t('eFileDescription')}</Paragraph>
            </Flex>
          </Box>
        )}
        <Box className={styles.box_icon} lineHeight={['24px']} fontSize={['15px']} ml={['5px']}>
          <Flex
            flexDirection={['column']}
            alignItems={['center']}
            justifyContent={['space-around']}
            height={['100%']}
          >
            <div className={styles.box_icon_content} onClick={() => handleUpdateTaxReturn(false)}>
              <PaperFile />

              <div>{t('paperFile')}</div>
            </div>

            <Paragraph>{t('paperFileDescription')}</Paragraph>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  )
}

export default AuthorizeForFilingContent
