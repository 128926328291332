import { Redirect, Route, useLocation } from 'react-router-dom'
import { useAuth } from 'context/Auth'
import { isEmailConfirmed } from 'utils'
import { EMAIL_VERIFICATION_PATH } from 'utils/routes'
import { getUserType } from 'utils/user'

const AuthRoute = (props: any) => {
  const {
    token,
    maskedToken,
    CPA: {
      cpaUserInvites: { pending: pendingInvitations },
    },
    ENTITY: { INDVEntitiesCount },
  } = useAuth()
  const { state, search } = useLocation()

  if (token) {
    if (getUserType(token, maskedToken) === 'ENTITY' && !INDVEntitiesCount) {
      return (
        <Redirect
          to={{
            pathname: pendingInvitations ? '/dashboard/user/invitations' : '/dashboard/entities',
            search,
            state,
          }}
        />
      )
    }

    if (!isEmailConfirmed(token)) {
      return (
        <Redirect
          to={{
            pathname: EMAIL_VERIFICATION_PATH,
            search,
          }}
        />
      )
    }
    const { from }: any = state || { from: { pathname: '/' } }
    return <Redirect to={from} />
  }

  return <Route {...props} />
}

export default AuthRoute
