import { TTaxOrganizersListItem } from 'context/Dashboard'

export const getWithCurrentFirst = (
  taxOrganizers: TTaxOrganizersListItem[]
): (TTaxOrganizersListItem & { current?: boolean })[] => {
  const currentTaxYear = new Date().getFullYear() - 1
  const currentTaxOrg = taxOrganizers.find(
    (taxOrganizer) => taxOrganizer.taxYear === currentTaxYear
  )
  if (currentTaxOrg) {
    return [{ ...currentTaxOrg, current: true }, ...taxOrganizers]
  }
  return taxOrganizers
}
