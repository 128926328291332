import { TDocumentPageIdentifier, TTaxReturnMenuCategory } from 'context/TaxReturns'
import { SpinnerBackdrop } from 'components'
import RecursiveCategory from './RecursiveCategory'

type Props = {
  menuTreeStructure: TTaxReturnMenuCategory[] | undefined
  setMenuTreeStructure: (treeStructure: TTaxReturnMenuCategory[]) => void
  selectedPage: TDocumentPageIdentifier | null
  setSelectedPage: (pageIdentifier: TDocumentPageIdentifier) => void
}

const CollapsibleMenu = ({
  menuTreeStructure,
  setMenuTreeStructure,
  selectedPage,
  setSelectedPage,
}: Props) => {
  if (!menuTreeStructure) return <SpinnerBackdrop />

  return (
    <>
      <RecursiveCategory
        categoryList={menuTreeStructure}
        setMenuTreeStructure={setMenuTreeStructure}
        menuTreeStructure={menuTreeStructure}
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
      />
    </>
  )
}

export default CollapsibleMenu
