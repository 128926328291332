import { PhaseName } from 'context/TaxOrganizer'
import { ActionTypes } from './action-types'

export enum EnumFinSoftware {
  QB_DESKTOP = 'QB_DESKTOP',
  QB_ONLINE = 'QB_ONLINE',
  NETSUITE = 'NETSUITE',
  XERO = 'XERO',
}

export type TFinSoftwareParams = {
  phase?: PhaseName
  financialSoftware: EnumFinSoftware
}

export type TRightPart = {
  title: string
  steps: string[]
  upload: boolean
}

export type TLeftPart = {
  title: string
  description: string
  logo: string
}

export type TFinSoftware = {
  id: string | number
  slug: EnumFinSoftware
  leftPart: TLeftPart
  rightPart: TRightPart
  status: boolean
}

export type TState = {
  data: TFinSoftware[]
}

export type TAction = { type: string; payload?: any }

export type TDispatch = {
  type: keyof typeof ActionTypes
  payload: any
}
