import { useTranslation } from 'react-i18next'
import { Box } from 'reflexbox'
import { ColumnsType } from 'antd/lib/table'
import { Link } from 'react-router-dom'
import { Modal } from 'antd'
import { cloneDeep } from 'lodash'

import { TCPAEntity, useEntityCPAsActions } from 'context/EntityCPAs'
import { useAuth, UserProfile } from 'context/Auth'

import { Button, User } from 'components'

import { getEntityPathById } from 'utils/routes'

import colors from 'assets/styles/variables.module.scss'
import { DEFAULT_PARAMS } from '../constatnts'

const { confirm } = Modal

type TProps = {
  dataActiveOnPageCount: number
  setParams: (
    value: TLoadEntitiesParams | ((prevState: TLoadEntitiesParams) => TLoadEntitiesParams)
  ) => void
  setCurrentPage: (page: number) => void
}

export default ({ setParams, setCurrentPage }: TProps) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'clients.table' })
  const { t: t2 } = useTranslation('common')
  const { reactivateClient, response } = useEntityCPAsActions()
  const {
    CPA: { cpaFirm },
  } = useAuth()

  const handleConfirmation = (cpaFirmGuid: string, entityGuid: string) => {
    confirm({
      maskClosable: true,
      title: t('messageReactivate'),
      okText: t2('buttons.confirm'),
      okType: 'danger',
      cancelText: t2('buttons.cancel'),
      onOk: async () => {
        await reactivateClient(cpaFirmGuid, entityGuid)
        if (response.ok) {
          setParams(cloneDeep({ ...DEFAULT_PARAMS, isActive: false }))
          setCurrentPage(DEFAULT_PARAMS.page as number)
          return Promise.resolve(true)
        }
        const responseError = await response.text()
        return Promise.reject(new Error(responseError))
      },
    })
  }

  const columns: ColumnsType<TCPAEntity> = [
    {
      title: <Box fontWeight="bold">{t('entity')}</Box>,
      dataIndex: 'title',
      key: 'entity__title',
      sorter: true,
      width: '25%',
      ellipsis: true,
      render: (title: string, record: any) => (
        <Link to={getEntityPathById(record?.guid)} className="text-black text-bold">
          <div className="text-black">
            <Box fontWeight="bold">{title}</Box>
          </div>
        </Link>
      ),
      onFilter: (value: any, record: TCPAEntity) => {
        return record.title.toLocaleLowerCase().includes(value)
      },
    },
    {
      title: t('type'),
      dataIndex: ['kind', 'title'],
      key: 'kind__title',
      sorter: true,
    },
    {
      title: t('primaryContact'),
      dataIndex: ['primaryContact'],
      key: 'primary_contact__first_name',
      sorter: true,
      render: (primaryContact: IContact) => {
        if (!primaryContact) return '-'
        const { firstName, lastName, avatar } = primaryContact
        return <User username={`${firstName} ${lastName}`} bgColor="#E1F0F3" avatarLink={avatar} />
      },
    },
    {
      title: t('taxPreparer'),
      dataIndex: ['primary_cpa_contact__first_name', 'user'],
      key: 'contact',
      sorter: true,
      render: (cpaContact: UserProfile) => {
        if (!cpaContact) return '-'
        const { firstName, lastName, avatar } = cpaContact
        return (
          <User username={`${firstName} ${lastName}`} bgColor={colors.tag5} avatarLink={avatar} />
        )
      },
    },
    {
      key: 'actions',
      render: (cpaEntity: TCPAEntity) => (
        <Button
          onClick={() => {
            handleConfirmation(cpaFirm!.guid, cpaEntity.guid)
          }}
          type="link"
        >
          {t('reactivate')}
        </Button>
      ),
    },
  ]

  return { columns }
}
