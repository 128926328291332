import { useFetch } from 'use-http'
import { message } from 'antd'
import qs from 'qs'
import { useTranslation } from 'react-i18next'

import { toSnake } from 'utils'
import { useAuthActions } from 'context/Auth'

import { ActionTypes } from './action-types'
import { useInviteDispatch } from '..'
import { TEntityInvites } from '../types'

const useInviteActions = () => {
  const dispatch = useInviteDispatch()
  const { getUserInvites } = useAuthActions()
  const { get, response, loading, post } = useFetch()
  const { t } = useTranslation('common', { keyPrefix: 'invitations.invitationStatus' })

  const loadEntityInvitations = async (params?: TParams) => {
    const query = qs.stringify(toSnake({ ...params }), { addQueryPrefix: !!params })
    const data = await get(`/cpas/client-invites${query}`)
    if (response.ok) {
      const tableData = { ...data }
      tableData.results = tableData.results.flatMap(
        ({ email, guid, invitedBy, lastSentOn, cpaFirm, entities }: TEntityInvites) =>
          entities.map((entity: any) => ({
            email,
            entity,
            guid,
            invitedBy,
            lastSentOn,
            cpaFirm,
          }))
      )
      getUserInvites()
      dispatch({
        type: ActionTypes.SET_ENTITY_CPA_INVITES,
        payload: tableData,
      })
    }
  }

  const loadEntityUserInvitations = async (params?: TParams) => {
    const query = qs.stringify(toSnake({ ...params }), { addQueryPrefix: !!params })
    const data = await get(`/entities/user-invites-mine${query}`)
    if (response.ok) {
      getUserInvites()
      dispatch({
        type: ActionTypes.SET_ENTITY_USER_INVITES,
        payload: data,
      })
    }
  }

  const loadCPAEntityInvitations = async (params?: TParams) => {
    const query = qs.stringify(toSnake({ ...params }), { addQueryPrefix: !!params })
    const data = await get(`/entities/firm-invites${query}`)
    if (response.ok) {
      getUserInvites()
      dispatch({
        type: ActionTypes.SET_CPA_ENTITY_INVITES,
        payload: data,
      })
    }
  }

  const loadCPAUserInvitations = async (params?: TParams) => {
    const query = qs.stringify(toSnake({ ...params }), { addQueryPrefix: !!params })
    const data = await get(`/cpas/cpa-firm-invites${query}`)
    if (response.ok) {
      getUserInvites()
      dispatch({
        type: ActionTypes.SET_CPA_USER_INVITES,
        payload: data,
      })
    }
  }

  const acceptCPAEntityInvitation = async (entityGuid: string, inviteGuid: string) => {
    await post(`/entities/${entityGuid}/firm-invites/${inviteGuid}/accept`)
    if (response.ok) {
      message.success(t('acc'))
      loadCPAEntityInvitations()
    }
  }

  const declineCPAEntityInvitation = async (entityGuid: string, inviteGuid: string) => {
    await post(`/entities/${entityGuid}/firm-invites/${inviteGuid}/reject`)
    if (response.ok) {
      message.success(t('rej'))
      loadCPAEntityInvitations()
    }
  }

  const acceptCPAUserInvitation = async (cpaFirmGuid: string, inviteGuid: string) => {
    await post(`/cpas/cpa-firms/${cpaFirmGuid}/invites/${inviteGuid}/accept`)
    if (response.ok) {
      message.success(t('acc'))
    }
  }

  const declineCPAUserInvitation = async (cpaFirmGuid: string, inviteGuid: string) => {
    await post(`/cpas/cpa-firms/${cpaFirmGuid}/invites/${inviteGuid}/revoke`)
    if (response.ok) {
      message.success(t('rej'))
    }
  }

  const acceptEntityInvitation = async (
    cpaFirmGuid: string,
    inviteGuid: string,
    clientInviteEntityGuid: string
  ) => {
    await post(
      `/cpas/cpa-firms/${cpaFirmGuid}/client-invites/${inviteGuid}/client-invites-entity/${clientInviteEntityGuid}/accept`
    )
    if (response.ok) {
      message.success(t('acc'))
      loadEntityInvitations()
    }
  }

  const declineEntityInvitation = async (
    cpaFirmGuid: string,
    inviteGuid: string,
    clientInviteEntityGuid: string
  ) => {
    await post(
      `/cpas/cpa-firms/${cpaFirmGuid}/client-invites/${inviteGuid}/client-invites-entity/${clientInviteEntityGuid}/revoke`
    )
    if (response.ok) {
      message.success(t('rev'))
      loadEntityInvitations()
    }
  }

  const acceptEntityUserInvitation = async (entityGuid: string, inviteGuid: string) => {
    await post(`/entities/${entityGuid}/user-invites/${inviteGuid}/accept`)
    if (response.ok) {
      message.success(t('acc'))
      loadEntityUserInvitations()
    }
  }

  const declineEntityUserInvitation = async (entityGuid: string, inviteGuid: string) => {
    await post(`/entities/${entityGuid}/user-invites/${inviteGuid}/reject`)
    if (response.ok) {
      message.success(t('rej'))
      loadEntityUserInvitations()
    }
  }

  return {
    loadEntityInvitations,
    acceptEntityInvitation,
    declineEntityInvitation,
    loadEntityUserInvitations,
    acceptEntityUserInvitation,
    declineEntityUserInvitation,
    loadCPAEntityInvitations,
    acceptCPAEntityInvitation,
    declineCPAEntityInvitation,
    loadCPAUserInvitations,
    acceptCPAUserInvitation,
    declineCPAUserInvitation,
    response,
    loading,
  }
}

export default useInviteActions
export { ActionTypes }
