import {
  TLoadNotificationsParams,
  useNotifications,
  useNotificationsActions,
} from 'context/Notifications'
import { useEffect, useState } from 'react'

const DEFAULT_PAGE = 1

export default (sidebarCollapsed: boolean) => {
  const { loadNotifications, loading, markNotificationsAsRead } = useNotificationsActions()
  const { notifications, count } = useNotifications()
  const [params, setParams] = useState<TLoadNotificationsParams>({ page: DEFAULT_PAGE })

  useEffect(() => {
    if (sidebarCollapsed) {
      setParams({ page: DEFAULT_PAGE })
    }
  }, [sidebarCollapsed])

  useEffect(() => {
    if (!sidebarCollapsed) {
      loadNotifications(params)
    }
  }, [params, sidebarCollapsed])

  useEffect(() => {
    if (notifications.find((f) => f.readOn === null)) {
      markNotificationsAsRead(notifications.filter((f) => f.readOn === null).map((n) => n.guid))
    }
  }, [notifications, sidebarCollapsed])

  return { loading, loadNotifications, notifications, count, params, setParams }
}
