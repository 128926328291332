import { TTaxDeadline, TTaxOrganizerPhaseSummary, TTaxSummary } from 'context/Dashboard'
import { EnumPhaseTypes, PhaseName } from 'context/TaxOrganizer'

export const getNextDeadline = (
  deadlines: TTaxDeadline[] | undefined
): TTaxDeadline | undefined => {
  const currentDeadlines = deadlines?.filter((_: TTaxDeadline) => new Date(_.dueDate) >= new Date())
  return currentDeadlines?.reduce((acc, cur) => (cur.dueDate <= acc.dueDate ? cur : acc))
}

export const SUMMARY_TEMPLATE: { phase: EnumPhaseTypes; count: number }[] = [
  { phase: EnumPhaseTypes.FILED, count: 0 },
  { phase: EnumPhaseTypes.FILING, count: 0 },
  { phase: EnumPhaseTypes.REVIEWING, count: 0 },
  { phase: EnumPhaseTypes.PREPARING, count: 0 },
  { phase: EnumPhaseTypes.COLLECTING, count: 0 },
]

export const getExtendedCount = (summary: TTaxSummary) => {
  const extendedPhase = summary.phases.find(
    ({ phase }: { phase: PhaseName }) => phase === 'EXTENDED'
  )
  return summary.extended ?? extendedPhase?.count ?? 0
}

export const fillTemplate = (phases: TTaxOrganizerPhaseSummary[]) => {
  const dataPhases = phases?.length ? phases : []
  return SUMMARY_TEMPLATE.map((templatePhase: { phase: PhaseName; count: number }) => {
    const currentPhase = dataPhases.find(
      (dataPhase: { phase: PhaseName; count: number }) => dataPhase.phase === templatePhase.phase
    )
    if (currentPhase) {
      return {
        phase: currentPhase.phase,
        count: currentPhase.count,
      }
    }
    return templatePhase
  })
}
