import ESign from './ESign'

type TProps = {
  closeModal: () => void
}

const DigitalSign = ({ closeModal }: TProps) => {
  return <ESign closeModal={closeModal} />
}

export default DigitalSign
