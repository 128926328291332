import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Form, Radio, Checkbox, Popover } from 'antd'
import { RegisterRequest, useAuth, useAuthActions } from 'context/Auth'
import PasswordStrengthBar from 'react-password-strength-bar'
import { useTranslation } from 'react-i18next'
import { omit } from 'lodash'

import { Button, PasswordField, TextField } from 'components'
import { FIRM_INVITATIONS_PATH, INVITATIONS_PATH, LOGIN_PATH } from 'utils/routes'
import PasswordTooltipContent from './components/PasswordTooltipContent'
import HeaderFirmInvitations from './components/HeaderFirmInvitations'

import styles from './register.module.scss'
import HeaderDefault from './components/HeaderDefault'
import HeaderUserInvitations from './components/HeaderUserInvitations'

const Register = () => {
  const { t } = useTranslation('common', { keyPrefix: 'form' })
  const [form] = Form.useForm()
  const {
    invitationsFlow: { userType, inviteInfo },
  } = useAuth()
  const { register, loading, response } = useAuthActions()
  const [password, setPassword] = useState('')
  const [passwordTooltip, setPasswordTooltip] = useState(false)
  const [disabledSubmit, setDisabledSubmit] = useState(true)
  const location = useLocation()
  const fromPathname = (location.state as any)?.from?.pathname

  const onSubmit = async (values: RegisterRequest) => {
    const result = await register({
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      password,
      type: userType || values.type,
    })
    if (!response.ok) {
      const errors = Object.entries(result).map(([key, value]) => {
        return {
          name: key,
          errors: value as string[],
        }
      })

      form.setFields(errors)
    }
  }

  const handleValuesChange = (_: any, allFields: any) => {
    let excludedFields: string[] = []
    if (fromPathname === FIRM_INVITATIONS_PATH) {
      excludedFields = ['email']
    } else if (fromPathname === INVITATIONS_PATH) {
      excludedFields = ['type', 'email']
    }
    const validity =
      Object.values(omit(allFields, excludedFields)).filter((field: any) => field === undefined)
        .length > 0
    setDisabledSubmit(validity)
  }

  useEffect(() => {
    window.history.replaceState(null, '')
  }, [])

  return (
    <Form
      form={form}
      className={styles.form}
      onFinish={onSubmit}
      onValuesChange={handleValuesChange}
    >
      {!fromPathname && <HeaderDefault />}
      {fromPathname === FIRM_INVITATIONS_PATH && <HeaderFirmInvitations />}
      {fromPathname === INVITATIONS_PATH && <HeaderUserInvitations />}

      <Form.Item
        name="firstName"
        rules={[
          { required: true, message: t('requiredField', { field: t('firstName') }) },
          { type: 'string' },
        ]}
      >
        <TextField placeholder={t('firstName')} size="large" className={styles.input} />
      </Form.Item>
      <Form.Item
        name="lastName"
        rules={[
          { required: true, message: t('requiredField', { field: t('lastName') }) },
          { type: 'string' },
        ]}
      >
        <TextField placeholder={t('lastName')} size="large" className={styles.input} />
      </Form.Item>
      <Form.Item
        name="email"
        hasFeedback
        rules={[
          { required: true, message: t('requiredField', { field: t('email') }) },
          { type: 'email', message: t('invalidField', { field: t('email') }) },
        ]}
        initialValue={inviteInfo?.email}
      >
        <TextField
          disabled={!!inviteInfo}
          placeholder={t('email')}
          size="large"
          className={styles.input}
        />
      </Form.Item>
      <Popover
        content={<PasswordTooltipContent password={password} />}
        placement="topLeft"
        visible={passwordTooltip}
      >
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: t('requiredField', { field: t('password') }),
            },
            () => ({
              validator() {
                if (!password.match(/^(.|\s)*\S(.|\s)*$/)) {
                  return Promise.reject(new Error(t('emptyField', { field: t('password') })))
                }
                if (password.match(/^.{0,7}$/)) {
                  return Promise.reject(new Error(t('passwordCheck.lengthStatus')))
                }
                if (!password.match(/^(?!^\d+$)^.+$/)) {
                  return Promise.reject(new Error(t('passwordCheck.notOnlyNumbers')))
                }
                return Promise.resolve()
              },
            }),
          ]}
          hasFeedback
        >
          <PasswordField
            placeholder={t('password')}
            size="large"
            className={styles.input}
            type="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value)
            }}
            onFocus={() => setPasswordTooltip(true)}
            onBlur={() => setPasswordTooltip(false)}
          />
        </Form.Item>
      </Popover>
      <PasswordStrengthBar password={password} />
      <Form.Item
        name="confirmPassword"
        dependencies={['password']}
        hasFeedback
        rules={[
          { required: true, message: t('requiredField', { field: t('confirmPassword') }) },
          () => ({
            validator(_, value) {
              if (!value || password === value) {
                return Promise.resolve()
              }
              return Promise.reject(new Error(t('passwordsNotMatch')))
            },
          }),
        ]}
      >
        <PasswordField
          placeholder={t('confirmPassword')}
          size="large"
          className={styles.input}
          type="password"
        />
      </Form.Item>

      {fromPathname === FIRM_INVITATIONS_PATH || fromPathname === INVITATIONS_PATH ? null : (
        <Form.Item
          name="type"
          rules={[{ required: true, message: t('requiredField', { field: t('userType') }) }]}
          initialValue={userType}
        >
          <Radio.Group
            buttonStyle="solid"
            size="large"
            className={styles.user_type}
            disabled={!!userType}
          >
            <Radio.Button style={{ width: '50%', textAlign: 'center' }} value="CPA">
              {t('taxPreparer')}
            </Radio.Button>
            <Radio.Button style={{ width: '50%', textAlign: 'center' }} value="ENTITY">
              {t('client')}
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
      )}

      <Form.Item
        name="agreement"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value
                ? Promise.resolve()
                : Promise.reject(new Error(t('requiredFields', { fields: t('privacyPolicy') }))),
          },
        ]}
      >
        <Checkbox>
          {t('agreeTo')}&nbsp;
          <a href="https://www.repositax.com/terms-conditions/" target="_blank" rel="noreferrer">
            {t('terms')}
          </a>
          <span> & </span>
          <a href="https://www.repositax.com/privacy-policy/" target="_blank" rel="noreferrer">
            {t('policy')}
          </a>
        </Checkbox>
      </Form.Item>

      <Form.Item>
        <Button
          htmlType="submit"
          type="primary"
          size="large"
          loading={loading}
          block
          disabled={disabledSubmit}
        >
          {t('createAccount')}
        </Button>
      </Form.Item>

      <Form.Item>
        <Button href={LOGIN_PATH} type="link" block>
          {t('alreadyHaveAccount')}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default Register
