import { FC, Dispatch, createContext, useReducer, useContext } from 'react'
import { useReducerLogger } from 'utils'

import { initialState, documentReducer, IDocumentState, IAction } from './reducer'

const DocumentContext = createContext<IDocumentState>({} as IDocumentState)
const DocumentDispatchContext = createContext<Dispatch<IAction>>(() => null)

export function useDocument() {
  const context = useContext(DocumentContext)
  if (context === undefined) {
    throw new Error('useDocument must be used within a DocumentProvider')
  }
  return context
}

export function useDocumentDispatch() {
  const context = useContext(DocumentDispatchContext)
  if (context === undefined) {
    throw new Error('useDocumentDispatch must be used within a DocumentProvider')
  }
  return context
}

export const DocumentProvider: FC = ({ children }) => {
  const [data, dispatch] = useReducer(
    useReducerLogger<IDocumentState, IAction>(documentReducer),
    initialState
  )

  return (
    <DocumentContext.Provider value={data}>
      <DocumentDispatchContext.Provider value={dispatch}>
        {children}
      </DocumentDispatchContext.Provider>
    </DocumentContext.Provider>
  )
}
