import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Flex } from 'reflexbox'

import { useTaxReturns, useTaxReturnsActions, TTaxReturn, SigningMethod } from 'context/TaxReturns'

import { Button } from 'components'
import Title from '../../components/Title'
import FilingStatus from './FilingStatus'
import AuthorizedSigner from './AuthorizedSigner'
import ConfirmSigner from './ConfirmSigner'
import AuthorizeForFilingContent from '../Review/components/AuthorizeForFilingContent'

import styles from './authorize.module.scss'
import ManualSign from './ManualSign'
import DigitalSign from './DigitalSign'
import GenerateEfileDoc from './DigitalSign/GenerateEfileDoc'

type TProps = {
  closeModal: () => void
  isEntity: boolean
}

const Authorize = ({ closeModal, isEntity }: TProps) => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'taxReturns.authorize' })
  const [signingMethod, setSigningMethod] = useState<SigningMethod | null>(null)
  const [isEFile, setIsEFile] = useState<boolean>(false)

  const { updateTaxReturn } = useTaxReturnsActions()
  const {
    state: {
      authorizationDocumentDetails,
      taxReturns: { latest: latestTaxReturn },
    },
  } = useTaxReturns()

  const updateSignMethod = async (signMethod: SigningMethod) => {
    setSigningMethod(signMethod)
    const patchTaxReturnBody: Partial<TTaxReturn> = {
      ...latestTaxReturn,
      efileAuthorizationSigningMethod: signMethod,
      authorizeStep: 'E_SIGN',
    }

    await updateTaxReturn(latestTaxReturn?.guid!, patchTaxReturnBody)
  }

  const CPASelection = () => {
    return (
      <AuthorizeForFilingContent
        isReadyEFile
        onSubmit={(isEFileResult) => {
          setIsEFile(isEFileResult)
          if (!isEFileResult) {
            closeModal()
          }
        }}
      />
    )
  }

  const ClientSelection = (signingChoice: SigningMethod | null) => {
    switch (signingChoice) {
      case 'PHYSICAL':
        return <ManualSign />
      case 'DIGITAL':
        return <DigitalSign closeModal={closeModal} />
      default:
        return (
          <>
            <Flex justifyContent={['space-between']} alignItems={['baseline']}>
              <Title text={t('title')} />
            </Flex>
            <Flex mt={['22px']} flexDirection={['column', 'row']}>
              <Box width={['100%', '50%']}>
                <Flex
                  flexDirection={['column']}
                  alignItems={['center']}
                  justifyContent={['center']}
                  py={['26px', '28px']}
                  className={styles.upload}
                >
                  <Box fontSize={['16px']} fontWeight={[700]} lineHeight={['20px']} mt={['10px']}>
                    {t('digitallySign')}
                  </Box>
                  <Box fontSize={['14px']} fontWeight={[500]} lineHeight={['20px']} mb={['10px']}>
                    {/* {t('software.2')} */}
                    Benefits
                  </Box>
                  <Button size="large" type="primary" onClick={() => updateSignMethod('DIGITAL')}>
                    {t('confirm')}
                  </Button>
                </Flex>
              </Box>
              <Flex
                width={['100%', '6%']}
                justifyContent={['center']}
                alignItems={['center']}
                my={[3, 0]}
                fontWeight={[600]}
                fontSize={['16px']}
              >
                {t('or')}
              </Flex>
              <Box width={['100%', '50%']}>
                <Flex
                  flexDirection={['column']}
                  alignItems={['center']}
                  justifyContent={['center']}
                  py={['26px', '28px']}
                  className={styles.upload}
                >
                  <Box fontSize={['16px']} fontWeight={[700]} lineHeight={['20px']} mt={['10px']}>
                    {t('manuallySign')}
                  </Box>
                  <Box fontSize={['14px']} fontWeight={[500]} lineHeight={['20px']} mb={['10px']}>
                    {/* {t('software.2')} */}
                    Benefits
                  </Box>
                  <Button size="large" type="primary" onClick={() => updateSignMethod('PHYSICAL')}>
                    {t('confirm')}
                  </Button>
                </Flex>
              </Box>
            </Flex>
          </>
        )
    }
  }

  const switchContent = () => {
    if (latestTaxReturn?.authorizeStep === 'COMPLETE') closeModal()

    if (isEntity) {
      return ClientSelection(signingMethod)
    }

    if (
      authorizationDocumentDetails?.fieldsStatus === 'OK' ||
      authorizationDocumentDetails?.fieldsStatus === 'ERROR'
    ) {
      closeModal()
    }

    if (
      latestTaxReturn?.authorizeStep === 'E_SIGN' &&
      latestTaxReturn?.efileAuthorizationSigningMethod === 'DIGITAL' &&
      authorizationDocumentDetails?.signers.length > 0 &&
      isEFile
    ) {
      return <GenerateEfileDoc closeModal={closeModal} />
    }
    try {
      switch (latestTaxReturn?.authorizeStep) {
        case 'AUTHORIZED_SIGNER':
          return (
            <>
              <AuthorizedSigner latestTaxReturn={latestTaxReturn} />
            </>
          )
        case 'CONFIRM_SIGNER':
          return (
            <>
              <ConfirmSigner latestTaxReturn={latestTaxReturn} />
            </>
          )
        case 'E_SIGN':
          return CPASelection()
        case 'FILING_STATUS':
          return <FilingStatus />
        default:
          throw new Error()
      }
    } catch {
      closeModal()
      return null
    }
  }
  return <div className={styles.header}>{switchContent()}</div>
}

export default Authorize
