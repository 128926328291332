import { enUS } from 'date-fns/locale'
import { useTranslation } from 'react-i18next'

const formatRelativeLocaleEN = {
  lastWeek: "'Last' eeee h:mmaaa",
  yesterday: "'Yesterday' h:mmaaa",
  today: "'Today' h:mmaaa",
  tomorrow: "'Tomorrow' h:mmaaa",
  nextWeek: "'Next' eeee",
  other: 'MM/dd/yyyy',
}
const locales = {
  en: {
    ...enUS,
    formatRelative: (token: string) =>
      formatRelativeLocaleEN[token as keyof typeof formatRelativeLocaleEN],
  },
}

export default (): Locale => {
  const { i18n } = useTranslation()
  return locales[i18n.language as keyof typeof locales] || locales.en
}
