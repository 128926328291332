import { SyntheticEvent, useState } from 'react'
import { Dropdown, Menu } from 'antd'
import { DashOutlined } from '@ant-design/icons'
import Button from 'components/Button'
import { TClientInviteParams, useEntityCPAsActions } from 'context/EntityCPAs'
import { useTranslation } from 'react-i18next'
import { cloneDeep } from 'lodash'
import styles from '../../dashboard.clients.module.scss'
import { DEFAULT_PARAMS } from '../constants'

type TProps = {
  entities: IEntity[]
  cpaFirmGuid: string
  inviteGuid: string
  setParams: (
    value: TClientInviteParams | ((prevState: TClientInviteParams) => TClientInviteParams)
  ) => void
}

const Actions = ({ entities, cpaFirmGuid, inviteGuid, setParams }: TProps) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'clients.table' })
  const { reSendInvitation } = useEntityCPAsActions()
  const { revokeInvitation, response } = useEntityCPAsActions()
  const [dropdownVisible, setDropdownVisible] = useState<string>('')

  const closeDropdown = () => setDropdownVisible('')

  const onReSend = async ({ domEvent }: { domEvent: SyntheticEvent }) => {
    domEvent.stopPropagation()
    closeDropdown()
    await reSendInvitation(cpaFirmGuid, inviteGuid)
  }

  const onRevoke = async ({ domEvent }: { domEvent: SyntheticEvent }) => {
    domEvent.stopPropagation()
    closeDropdown()
    await Promise.all(
      entities.map(async (entity) => {
        await revokeInvitation(cpaFirmGuid, inviteGuid, entity.guid)
      })
    )
    // TODO: [REFACTORING]: CHECK IF response.ok HERE BELONGS TO
    // ALL Promises responses IF NOT - REPLACE
    if (response.ok) {
      setParams(cloneDeep(DEFAULT_PARAMS))
    }
  }

  return (
    <Dropdown
      trigger={['click']}
      placement="bottomLeft"
      visible={dropdownVisible === inviteGuid}
      onVisibleChange={(value: boolean) => {
        if (value) {
          setDropdownVisible(inviteGuid)
        } else {
          closeDropdown()
        }
      }}
      overlay={() => (
        <Menu theme="dark">
          <Menu.Item key="resend" onClick={onReSend}>
            {t('resend')}
          </Menu.Item>
          <Menu.Item key="revoke" onClick={onRevoke}>
            {t('revoke')}
          </Menu.Item>
        </Menu>
      )}
    >
      <Button type="text" onClick={(e: SyntheticEvent) => e.stopPropagation()}>
        <DashOutlined className={styles.actions} />
      </Button>
    </Dropdown>
  )
}

export default Actions
