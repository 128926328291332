import { useCallback, useEffect, useState } from 'react'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import { ConfirmCardPaymentData, PaymentMethodResult } from '@stripe/stripe-js'
import { Button } from 'components'
import { Box, Flex } from 'reflexbox'
import { useTranslation } from 'react-i18next'

import { useBillingActions } from 'context/Billing'
import { useAuth } from 'context/Auth'

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: "'Helvetica Neue', Helvetica, sans-serif",
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
}

const CardForm = () => {
  const {
    CPA: { cpaFirm: firm },
  } = useAuth()
  const stripe = useStripe()
  const elements = useElements()

  const { subscribe, loadSubscription, loading } = useBillingActions()
  const { t } = useTranslation('dashboard', { keyPrefix: 'firm.billing' })
  const [formComplete, setFormComplete] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (elements) {
      const cardElement = elements.getElement(CardElement)
      cardElement?.on('change', (event) => {
        setFormComplete(event?.complete)
      })
    }
  }, [elements])

  const clearCard = () => {
    const cardElement = elements?.getElement(CardElement)
    if (cardElement) {
      cardElement.clear()
    }
    loadSubscription(firm!.guid)
  }

  const handleSubscribe = async (result: PaymentMethodResult) => {
    if (!result?.paymentMethod?.id) return

    const { furtherAction } = await subscribe(firm!.guid, result.paymentMethod.id)

    if (furtherAction?.secret) {
      const { secret } = furtherAction
      const newCardElement = elements ? elements.getElement(CardElement) : null
      const config: ConfirmCardPaymentData = {
        setup_future_usage: 'off_session',
      }

      if (newCardElement) {
        config.payment_method = { card: newCardElement }
      }

      stripe?.confirmCardPayment(secret, config).then((newResult) => {
        if (newResult.error) {
          const { message } = newResult.error
          if (message) setErrorMessage(message)
        } else {
          clearCard()
        }
      })
    } else {
      clearCard()
    }
  }

  const handleSubmit = useCallback(
    async (e) => {
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      e.preventDefault()

      if (!stripe || !elements) {
        // NOTE: This is mostly for TS checking as users cannot click submit in this state.
        return
      }

      const cardElement = elements.getElement(CardElement)
      if (cardElement) {
        const result: PaymentMethodResult = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        })

        if (result.error) {
          const message = `${result.error.message}`
          setErrorMessage(message)
        } else {
          handleSubscribe(result)
        }
      }
    },
    [elements, stripe]
  )

  return (
    <form onSubmit={handleSubmit}>
      <Flex
        flexDirection={['column', 'row']}
        alignItems={['start', 'start']}
        justifyContent={['space-between']}
        mt={[1, 3, 3, 0]}
      >
        <Box fontSize={['10px']} width={['100%', '22%']} fontWeight={[600]} mt={[2, 0]}>
          {t('description-2')}
        </Box>
        <Box width={['100%', '60%']} mt={[4, 2]} mb={[4, 0]}>
          <CardElement options={CARD_ELEMENT_OPTIONS} />
          <Box className="text-error" fontSize={['12px']} fontWeight={[600]}>
            {errorMessage}
          </Box>
        </Box>
        <Flex width={['100%', 'auto']}>
          <Button
            htmlType="submit"
            type="primary"
            block
            loading={!stripe || !elements}
            disabled={!formComplete || loading}
          >
            {loading ? 'Loading ...' : 'Subscribe'}
          </Button>
        </Flex>
      </Flex>
    </form>
  )
}

export default CardForm
