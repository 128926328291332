import { ChangeEvent, useEffect, useState } from 'react'

import { getOrderingParams } from 'utils/table'

import { TTaxOrganizersParams, useDashboard, useDashboardActions } from 'context/Dashboard'

import { Section, Table } from 'components'
import Head from './components/Head'

import useColumns from './components/hooks/useColumns'
import { DEFAULT_PARAMS } from './components/constants'

type TProps = { entity: string }

const TaxOrganizes = ({ entity }: TProps) => {
  const { loadTaxOrganizers, loading } = useDashboardActions()
  const { taxOrganizersList, total } = useDashboard()
  const { columns } = useColumns()
  const [params, setParams] = useState<TTaxOrganizersParams>({
    ...DEFAULT_PARAMS,
    entity,
  })
  const [currentPage, setCurrentPage] = useState<number | undefined>()

  const reloadTaxOrganizers = () =>
    loadTaxOrganizers({
      ...DEFAULT_PARAMS,
      entity,
    })

  const handlePageChange = (page: number) => {
    setParams((prevParams: TTaxOrganizersParams) => {
      setCurrentPage(page)
      return { ...prevParams, page }
    })
  }

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setParams((prevParams: TTaxOrganizersParams) => {
      setCurrentPage(DEFAULT_PARAMS.page)
      return {
        ...prevParams,
        page: DEFAULT_PARAMS.page,
        search: event.target.value ? event.target.value.trim() : undefined,
      }
    })
  }

  useEffect(() => {
    loadTaxOrganizers(params)
  }, [params])

  return (
    <Section>
      <Head
        handleSearch={handleSearch}
        reloadTaxOrganizers={reloadTaxOrganizers}
        params={params}
        setParams={setParams}
        setCurrentPage={setCurrentPage}
      />
      <Table
        table={{
          dataSource: taxOrganizersList,
          columns,
          rowKey: 'guid',
          loading,
          onChange: (p, f, sorter) => {
            setParams((prevParams) => ({ ...prevParams, ordering: getOrderingParams(sorter) }))
          },
        }}
        pagination={{ total, onChange: handlePageChange, current: currentPage }}
      />
    </Section>
  )
}

export default TaxOrganizes
