import { Image } from 'antd'
import { Flex } from 'reflexbox'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'

import colors from 'assets/styles/variables.module.scss'
import styles from '../../../uploadmodal.module.scss'

type TProps = {
  fileType: string
  fileUrl: string
}

const Preview = ({ fileType, fileUrl }: TProps) => {
  return (
    <>
      <Flex
        width={['100%', '100%', '40%']}
        style={{
          background: colors.purpleGrey20,
          borderTopLeftRadius: '8px',
          borderBottomLeftRadius: '8px',
        }}
        justifyContent={['center']}
        alignItems={['center']}
        height={['auto', 'auto', 'auto', '100%']}
        minHeight={['auto', 'auto', 'auto', '300px']}
      >
        {fileType?.includes('pdf') ? (
          <Document file={fileUrl}>
            <Page pageNumber={1} renderMode="canvas" className={styles.file_preview} />
          </Document>
        ) : (
          <Image src={fileUrl} className={styles.file_preview} />
        )}
      </Flex>
    </>
  )
}

export default Preview
