import { useTranslation } from 'react-i18next'
import { Box } from 'reflexbox'
import { ColumnsType } from 'antd/lib/table'

import useDate from 'hooks/useDate'

import { TJobTitle } from 'context/Dashboard'

import { User } from 'components'
import { TEntityInvite } from 'context/Invites/types'
import { useInviteActions } from 'context/Invites'

import colors from 'assets/styles/variables.module.scss'

import ActionsCell from '../../ActionsCell'

export default () => {
  const { getDate } = useDate()
  const { t } = useTranslation('dashboard', { keyPrefix: 'clients.table' })
  const { t: t2 } = useTranslation('common', { keyPrefix: 'user.roles' })
  const { acceptCPAUserInvitation, response: responseAccept } = useInviteActions()
  const { declineCPAUserInvitation, response: responseDecline } = useInviteActions()
  const { loadCPAUserInvitations } = useInviteActions()

  const columns: ColumnsType<TEntityInvite> = [
    {
      title: <Box fontWeight="bold">{t('taxFirm')}</Box>,
      dataIndex: ['cpaFirm', 'name'],
      key: 'name',
      ellipsis: true,
      render: (name: string) => (
        <Box fontWeight="bold" className="text-black">
          {name}
        </Box>
      ),
    },
    {
      title: t('inviteBy'),
      dataIndex: ['invitedBy'],
      key: 'inviteBy',
      render: (cpaContact: IContact) => {
        if (!cpaContact) return null
        const { firstName, lastName, avatar } = cpaContact
        return (
          <User username={`${firstName} ${lastName}`} bgColor={colors.tag5} avatarLink={avatar} />
        )
      },
    },
    {
      title: t('accessLevel'),
      dataIndex: 'role',
      key: 'accessLevel',
      render: (role?: string) => {
        return <div style={{ whiteSpace: 'nowrap' }}>{role && t2(role)}</div>
      },
    },
    {
      title: t('jobTitle'),
      dataIndex: 'jobTitle',
      key: 'jobTitle',
      render: (jobTitle: TJobTitle) => (
        <span className="text-semibold">{jobTitle?.label ?? t('noJobTitle')}</span>
      ),
    },
    {
      title: <span className="text-nowrap">{t('lastSentOn')}</span>,
      dataIndex: 'lastSentOn',
      key: 'lastSentOn',
      render: (lastSentOn: string) => (
        <div className="text-nowrap">{getDate(lastSentOn, 'yyyy-MM-dd')}</div>
      ),
    },
    {
      title: t('actions'),
      key: 'actions',
      width: '350px',
      render: (invitation: TEntityInvite) => (
        <ActionsCell
          acceptAction={async () => {
            await acceptCPAUserInvitation(invitation.cpaFirm.guid, invitation.guid)
            if (responseAccept.ok) {
              loadCPAUserInvitations()
            }
          }}
          declineAction={async () => {
            await declineCPAUserInvitation(invitation.cpaFirm.guid, invitation.guid)
            if (responseDecline.ok) {
              loadCPAUserInvitations()
            }
          }}
        />
      ),
    },
  ]

  return { columns }
}
