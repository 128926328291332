import { useState } from 'react'
import { Flex } from 'reflexbox'
import cn from 'classnames'
import { fieldIsLocked } from 'utils'
import { useTranslation } from 'react-i18next'

import { TFile, TTaxDocumentExtended, useTaxOrganizer } from 'context/TaxOrganizer'

import { Modal } from 'components'
import Header from './Header'
import Body from './Body'

import styles from '../uploadmodal.module.scss'

type Props = {
  fileUploading?: TFile
  fileUploaded: TTaxDocumentExtended | undefined
  onRemove: (fileUploadedGuid: string | undefined, fileUploadingUid?: string) => void
  hideFileEditing?: boolean
  loading?: boolean
}

const File = ({ fileUploading, fileUploaded, onRemove, hideFileEditing, loading }: Props) => {
  const { t } = useTranslation('taxOrganizer')
  const [fileCollapse, setFileCollapse] = useState(false)
  const [fileCategories, setFileCategories] = useState({
    category: fileUploaded?.category,
    tags: fileUploaded?.tags,
  })
  const {
    taxOrganizer: {
      data: { isLocked },
    },
  } = useTaxOrganizer()
  return (
    <>
      <Modal
        content={t('locked.document.modified')}
        overlayClassName={
          fieldIsLocked(isLocked, fileUploaded?.afterTaxOrganizerLock) ? '' : 'd-none'
        }
      >
        <Flex
          className={cn(
            hideFileEditing
              ? styles.file_forbidden
              : loading
              ? styles.file_loading
              : fileCollapse
              ? styles.file_expanded
              : styles.file
          )}
          flexDirection={['column']}
          mt={['14px']}
        >
          <Header
            fileCollapse={fileCollapse}
            setFileCollapse={setFileCollapse}
            fileUploaded={fileUploaded}
            fileUploading={fileUploading}
            fileCategories={fileCategories}
            loading={loading}
            hideFileEditing={hideFileEditing}
            fileIsLocked={fieldIsLocked(isLocked, fileUploaded?.afterTaxOrganizerLock)}
          />
          {(!hideFileEditing || !fieldIsLocked(isLocked, fileUploaded?.afterTaxOrganizerLock)) && (
            <Body
              fileCollapse={fileCollapse}
              setFileCollapse={setFileCollapse}
              fileUploading={fileUploading}
              fileUploaded={fileUploaded}
              onRemove={onRemove}
              setFileCategories={setFileCategories}
            />
          )}
        </Flex>
      </Modal>
    </>
  )
}

export default File
