import { useFetch } from 'use-http'
import qs from 'qs'
import { toSnake } from 'utils'

import { LoadMessageThreadsParams } from '../types'
import { ActionTypes } from './action-types'
import { useMessagesDispatch } from '../context'

const useMessagesActions = () => {
  const dispatch = useMessagesDispatch()
  const { get, put, post, patch, response, loading } = useFetch()

  const loadMessageThreads = async (params: LoadMessageThreadsParams = {}) => {
    const threads = await get(
      `/tax-organizers/threads${qs.stringify(toSnake(params), { addQueryPrefix: true })}`
    )
    if (response.ok) {
      dispatch({
        type: ActionTypes.LOAD_MESSAGE_THREADS,
        payload: threads,
      })
    }
  }

  const loadMessageThreadDetails = async (threadId: string) => {
    if (threadId === '0') {
      dispatch({ type: ActionTypes.CLEAR_MESSAGE_DETAILS })
      return
    }
    const messageDetails = await get(`/tax-organizers/threads/${threadId}/messages`)
    if (response.ok) {
      dispatch({ type: ActionTypes.LOAD_MESSAGE_DETAILS, payload: messageDetails.results })
    }
  }

  const loadMessageThreadDocument = async (taxOrganizerId: string, taxDocumentId: string) => {
    const threadDocument = await get(`/tax-organizers/${taxOrganizerId}/documents/${taxDocumentId}`)
    if (response.ok) {
      dispatch({ type: ActionTypes.LOAD_MESSAGE_DOCUMENT, payload: threadDocument })
    }
  }

  const markThreadRead = async (id: string) => {
    await put(`/tax-organizers/threads/${id}/mark-read`, {
      timestamp: new Date(Date.now()).toISOString(),
    })
  }

  const markThreadResolved = async (id: string, isResolved: boolean) => {
    await patch(`/tax-organizers/threads/${id}`, {
      is_resolved: isResolved,
    })
  }

  const loadThread = async (id: string) => {
    const thread = await get(`/tax-organizers/threads/${id}`)
    if (response.ok) {
      dispatch({ type: ActionTypes.LOAD_THREAD, payload: thread })
    }
  }

  const addThreadMessage = async (id: string, message: string) => {
    const threadReply = await post(`/tax-organizers/threads/${id}/messages`, {
      message,
    })
    if (response.ok) {
      dispatch({ type: ActionTypes.ADD_MESSAGE, payload: threadReply })
    }
  }

  const addNewThreadMessage = async (id: string, message: string) => {
    const thread = await post(`/tax-organizers/threads`, {
      tax_organizer_guid: id,
      message,
    })
    if (response.ok) {
      dispatch({ type: ActionTypes.ADD_NEW_MESSAGE, payload: thread })
    }
  }

  const loadUnreadMessages = async () => {
    const unreadMessages = await get(`/tax-organizers/threads/unread-count`)
    if (response.ok) {
      dispatch({ type: ActionTypes.LOAD_UNREAD_MESSAGES, payload: unreadMessages })
    }
  }

  const filterThreads = (filterType: string) => {
    dispatch({ type: ActionTypes.FILTER_THREADS, payload: filterType })
  }

  const addThread = () => {
    dispatch({ type: ActionTypes.ADD_THREAD })
  }

  const setCurrentThreadStatus = (status: boolean) => {
    dispatch({ type: ActionTypes.SET_CURRENT_THREAD_STATUS, payload: status })
  }

  return {
    loadThread,
    loadMessageThreads,
    loadMessageThreadDetails,
    markThreadRead,
    addThreadMessage,
    loadMessageThreadDocument,
    markThreadResolved,
    loadUnreadMessages,
    addNewThreadMessage,
    addThread,
    filterThreads,
    setCurrentThreadStatus,
    loading,
    response,
  }
}

export default useMessagesActions
export { ActionTypes }
