import { useRef } from 'react'
import { Box, Flex } from 'reflexbox'
import { useTranslation } from 'react-i18next'
import { Button } from 'components'

import { INotification } from 'context/Notifications'
import { useHistory } from 'react-router-dom'
import { getTaxOrganizerPathById } from 'utils/routes'

import styles from '../../dashboard.notifications.module.scss'
import Notification from '../Notification'

type TProps = {
  notifications: INotification[]
  loading: boolean
  fetchMoreData: () => void
  nextStep: boolean
}

const NotificationsList = ({ notifications, loading, fetchMoreData, nextStep }: TProps) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'sidebar' })
  const scrollArea = useRef(null)

  const history = useHistory()

  const redirectToTaxOrganizer = (guid: string) => history.push(getTaxOrganizerPathById(guid))

  return (
    <>
      <Box
        mt={['5px']}
        mb={['25px']}
        className={styles.scrollbox}
        id="scrollableSidebarBlock"
        ref={scrollArea}
      >
        {notifications.map((n: INotification) => {
          return (
            <Notification
              key={n.guid}
              data={n}
              onItemClick={() => redirectToTaxOrganizer(n.sourceGuid)}
            />
          )
        })}
        {nextStep && (
          <Flex justifyContent={['center']} mt={[3]}>
            <Button onClick={fetchMoreData} size="small" disabled={loading} loading={loading}>
              {t('moreNotifications')}
            </Button>
          </Flex>
        )}
      </Box>
    </>
  )
}

export default NotificationsList
