import { useEffect } from 'react'
import { Table, Button } from 'antd'
import { Box, Flex } from 'reflexbox'
import { useTranslation } from 'react-i18next'
import { DownOutlined } from '@ant-design/icons'

import {
  useDashboardActions,
  useDashboard,
  TTaxSummaryParams,
  TTaxOrganizersParams,
} from 'context/Dashboard'

import { Section } from 'components'
import Header from './components/Header'
import Chart from './components/StatusBar'

import useColumns from './hooks/useColumns'
import usePagination from './hooks/usePagination'
import { PAGE_SIZE } from '../../constants'

type TProps = {
  taxParams: TTaxOrganizersParams
  showTable: boolean
  summaryParams: TTaxSummaryParams
  setSummaryParams: (
    value: TTaxSummaryParams | ((prevState: TTaxSummaryParams) => TTaxSummaryParams)
  ) => void
  setShowTable: (state: boolean) => void
  setTaxParams: (
    value: TTaxOrganizersParams | ((prevState: TTaxOrganizersParams) => TTaxOrganizersParams)
  ) => void
  loadingDeadlines: boolean
}

const Info = ({
  taxParams,
  showTable,
  summaryParams,
  setSummaryParams,
  setShowTable,
  setTaxParams,
  loadingDeadlines,
}: TProps) => {
  const { t } = useTranslation('dashboard', { keyPrefix: '' })
  const { taxOrganizersList, total: taxTotal } = useDashboard()
  const { loadTaxOrganizers, loading: loadingTaxOrganizers } = useDashboardActions()
  const { getSummary, loading: summaryLoading } = useDashboardActions()

  const { columns } = useColumns()
  const { list: taxOrganizersListPaginated, nextStep } = usePagination({
    page: 1,
    chunk: taxOrganizersList,
    total: taxTotal,
  })

  useEffect(() => {
    if (showTable) {
      loadTaxOrganizers(taxParams)
    }
  }, [taxParams, showTable])

  useEffect(() => {
    if (summaryParams?.taxYear) {
      getSummary(summaryParams)
    }
  }, [summaryParams])

  const fetchMoreData = () => {
    if (nextStep) {
      setTaxParams((currentParams: TTaxOrganizersParams) => ({
        ...currentParams,
        pageSize: (currentParams.pageSize as number) + PAGE_SIZE,
      }))
    }
  }

  return (
    <Section>
      <Header
        setTaxParams={setTaxParams}
        setSummaryParams={setSummaryParams}
        setShowTable={setShowTable}
      />
      <Chart
        loading={summaryLoading || loadingDeadlines}
        summaryParams={summaryParams}
        setTaxParams={setTaxParams}
        setShowTable={setShowTable}
      />
      {showTable && (
        <Box width={['100%']} mt={['18px']}>
          <Table
            loading={loadingTaxOrganizers}
            columns={columns}
            dataSource={taxOrganizersListPaginated}
            rowKey="guid"
            scroll={{ x: true }}
            pagination={false}
          />
          {nextStep && (
            <Flex mt={['16px']}>
              <Button type="link" onClick={fetchMoreData}>
                <Flex fontSize={['16px']} alignItems={['center']}>
                  {t('showMore')}
                  <Box ml={[2]} color="lightgray">
                    <DownOutlined />
                  </Box>
                </Flex>
              </Button>
            </Flex>
          )}
        </Box>
      )}
    </Section>
  )
}

export default Info
