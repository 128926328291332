import { NavLink, Link, useLocation } from 'react-router-dom'
import { Dropdown, Menu, Tooltip } from 'antd'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { DownOutlined } from '@ant-design/icons'

import { useAuth } from 'context/Auth'

import useUserType from 'hooks/useUserType'

import styles from './dashboard.navigation.module.scss'

type SubItem = {
  title: string
  path: string
  disabled?: boolean
  hidden: boolean
}

type MenuItem = {
  title: string
  icon?: JSX.Element | null
  path: string
  exact?: boolean
  items?: SubItem[]
  disabled?: boolean
  comingSoon?: boolean
  hidden: boolean
}

const isActiveRoute = (items: SubItem[], pathname: string): boolean =>
  items.some((subItem) => pathname.indexOf(subItem.path) === 0)

const getActiveSubRoute = (items: SubItem[], pathname: string): string[] =>
  items.filter((subItem) => pathname.indexOf(subItem.path) === 0).map((subItem) => subItem.path)

const Navigation = () => {
  const { t } = useTranslation('common')
  const { user } = useAuth()
  const { pathname } = useLocation()
  const userType = useUserType()

  const menuItems = {
    ENTITY: [
      {
        title: t('navigation.dashboard'),
        icon: null,
        path: '/dashboard',
        exact: true,
        disabled: false,
        comingSoon: false,
        hidden: false,
        items: [],
      },
      {
        title: t('navigation.entities'),
        icon: <DownOutlined />,
        path: '',
        exact: true,
        disabled: false,
        comingSoon: false,
        hidden: false,
        items: [
          {
            title: t('navigation.entityList'),
            path: '/dashboard/entities',
            hidden: false,
          },
          {
            title: t('navigation.taxOrganizers'),
            path: '/dashboard/tax-organizers',
            hidden: false,
          },
        ],
      },
      {
        title: t('navigation.calendar'),
        icon: null,
        path: '/dashboard/calendar',
        exact: true,
        disabled: true,
        comingSoon: true,
        hidden: false,
        items: [],
      },
      {
        title: t('navigation.resources'),
        icon: <DownOutlined />,
        path: '',
        disabled: false,
        exact: true,
        comingSoon: false,
        hidden: false,
        items: [
          {
            title: t('navigation.taxPrepaprerDatabase'),
            path: '/dashboard/promo',
            hidden: false,
          },
        ],
      },
    ],
    CPA: [
      {
        title: t('navigation.dashboard'),
        icon: null,
        path: '/dashboard',
        exact: true,
        disabled: false,
        comingSoon: false,
        hidden: false,
        items: [],
      },
      {
        title: t('navigation.clients'),
        icon: <DownOutlined />,
        path: '',
        exact: true,
        disabled: false,
        comingSoon: false,
        hidden: false,
        items: [
          {
            title: t('navigation.clientsList'),
            path: '/dashboard/clients',
            hidden: false,
          },
          {
            title: t('navigation.taxOrganizers'),
            path: '/dashboard/tax-organizers',
            hidden: false,
          },
        ],
      },
      {
        title: t('navigation.firmManagement'),
        path: '/dashboard/firm/team',
        exact: false,
        disabled: false,
        comingSoon: false,
        hidden: false,
        items: [],
      },
      {
        title: t('navigation.resources'),
        icon: <DownOutlined />,
        path: '',
        disabled: false,
        exact: true,
        comingSoon: false,
        hidden: false,
        items: [
          {
            title: t('navigation.taxPrepaprerDatabase'),
            path: '/dashboard/promo',
            hidden: false,
          },
        ],
      },
    ],
  }

  if (!user) return null

  const items = userType === 'ENTITY' ? menuItems.ENTITY : menuItems.CPA

  const buildItem = (item: MenuItem) => {
    return (
      <div className={styles.item} key={item.title}>
        {!item?.items?.length ? (
          <NavLink
            to={item.path}
            exact={item.exact}
            className={cn(styles.link, item.disabled ? styles.disabled : '')}
            activeClassName={styles.active}
          >
            {item.title}
          </NavLink>
        ) : (
          <Dropdown
            overlay={
              <Menu selectedKeys={getActiveSubRoute(item.items, pathname)}>
                {item.items
                  .filter((subItem) => !subItem.hidden)
                  .map((subItem) => (
                    <Menu.Item key={subItem.path}>
                      <Link to={subItem.path} rel="noopener noreferrer">
                        {subItem.title}
                      </Link>
                    </Menu.Item>
                  ))}
              </Menu>
            }
          >
            <div
              className={cn(
                styles.link,
                item.items?.length && isActiveRoute(item.items, pathname) ? styles.active : '',
                item.disabled ? styles.disabled : ''
              )}
            >
              {item.title} {item.icon}
            </div>
          </Dropdown>
        )}
      </div>
    )
  }
  return (
    <div className={styles.box}>
      {items
        .filter((item) => !item.hidden)
        .map((item) => {
          if (item.comingSoon)
            return (
              <Tooltip key={item.title} title={t('comingSoon')}>
                {buildItem(item)}
              </Tooltip>
            )
          return buildItem(item)
        })}
    </div>
  )
}

export default Navigation
