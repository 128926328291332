import { useEffect, useState } from 'react'
import { Box, Flex } from 'reflexbox'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'

import { Button, Select, Table } from 'components'

import useFormTable from 'hooks/useFormTable'

import {
  TInvoiceListItemExtended,
  TInvoiceParams,
  useBilling,
  useBillingActions,
} from 'context/Billing'
import { useDashboard } from 'context/Dashboard'

import { getOrderingParams } from 'utils/table'
import { Form } from 'antd'
import useColumns from './hooks/useColumns'

import { DEFAULT_PARAMS } from './constants'

type TBillingProps = {
  cpaFirmGuid: string
}

const Billing = ({ cpaFirmGuid }: TBillingProps) => {
  const currentTaxYear = new Date().getFullYear() - 1
  const { t } = useTranslation('dashboard', { keyPrefix: 'firm.billing' })
  const [form] = Form.useForm()
  const isMobile = useMediaQuery({ query: '(max-width: 40em)' })
  const { loadInvoices } = useBillingActions()
  const {
    state: { invoices },
  } = useBilling()
  const { taxYears } = useDashboard()
  const { params, setParams } = useFormTable<TInvoiceParams>({
    defaultParams: { ...DEFAULT_PARAMS, year: currentTaxYear },
  })
  const [invoicesDataSource, setInvoicesData] = useState<TInvoiceListItemExtended[]>([])

  const { columns } = useColumns()

  const convertToDollars = (quantity: number) => quantity / 100

  const handleChange = (year: number) => setParams((preParams) => ({ ...preParams, year }))

  useEffect(() => {
    const invoicesConverted = invoices.map((invoice) => {
      invoice.unitPriceDollars = convertToDollars(invoice.unitPrice)
      invoice.totalDollars = convertToDollars(invoice.total)
      return invoice
    })
    setInvoicesData(invoicesConverted)
  }, [invoices])

  useEffect(() => {
    loadInvoices(cpaFirmGuid, params)
  }, [params])

  useEffect(() => {
    form.setFieldsValue({ year: currentTaxYear })
  }, [])

  return (
    <Flex flexDirection={['column']} pt={['32px']}>
      <Flex justifyContent={['space-between']} alignItems={['center']}>
        <Flex alignItems={['center']}>
          <Box lineHeight={['24px']} fontSize={['20px']} fontWeight={[700]}>
            {t('bills')}
          </Box>
          <Box ml={['20px']} width={['93px']}>
            <Form form={form}>
              <Form.Item name="year" key="year" className="m-0">
                <Select
                  dropdownMatchSelectWidth={false}
                  style={{ width: '100%' }}
                  options={taxYears.options}
                  onChange={handleChange}
                />
              </Form.Item>
            </Form>
          </Box>
        </Flex>
        <Box>
          <Button type="primary" ghost size={isMobile ? 'middle' : 'large'}>
            {t('downloadAll')}
          </Button>
        </Box>
      </Flex>
      <Box mt={['10px']}>
        <Table
          table={{
            dataSource: invoicesDataSource,
            columns,
            rowKey: 'externalId',
            tableLayout: 'auto',
            onChange: (p, f, sorter) => {
              setParams((prevParams) => ({ ...prevParams, ordering: getOrderingParams(sorter) }))
            },
          }}
          pagination={{}}
        />
      </Box>
    </Flex>
  )
}

export default Billing
