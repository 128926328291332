import { Avatar, AvatarProps } from 'antd'

import { getInitials } from 'utils'

import styles from './usericon.module.scss'

type Props = {
  user: IContact
  avatarProps?: AvatarProps
}

const UserIcon = ({ user, avatarProps }: Props) => {
  return user.avatar ? (
    <Avatar {...avatarProps} src={user.avatar} className={styles.icon} />
  ) : (
    <Avatar {...avatarProps} className={styles.icon}>
      {getInitials(user)}
    </Avatar>
  )
}

export default UserIcon
