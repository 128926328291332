import { FC } from 'react'
import { Box } from 'reflexbox'

type TProps = {
  title: string
  subtitles?: string[]
}

const Title: FC<TProps> = ({ title, subtitles, children }) => {
  return (
    <Box mt={[1, 3]} mb={[3, 4]}>
      <Box textAlign={['center']} fontSize={['20px', '24px']} fontWeight={[700]}>
        {title}
      </Box>
      {!!subtitles?.length && (
        <Box textAlign={['center']} fontStyle="italic" mt={[3]} fontSize={['12px', '14px']}>
          {subtitles.map((subtitle: string, i: number) => (
            <Box mt={[i === 0 ? 0 : 1]} key={JSON.stringify(subtitle)}>
              {subtitle}
            </Box>
          ))}
        </Box>
      )}
      {children}
    </Box>
  )
}

export default Title
