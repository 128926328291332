import { useState } from 'react'
import { Box, Flex } from 'reflexbox'
import { Button } from 'antd'
import { useAuth, useAuthActions } from 'context/Auth'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getCPAOnboardingPath } from 'utils/routes'
import UploadField from './UploadField'

const Uninvited = () => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'firm' })
  const {
    CPA: { cpaFirm: firm },
  } = useAuth()
  const [fileIsUploaded, setFileUploadStatus] = useState(false)
  const { updateCpaFirm, response: updateResponse, loading: updateLoading } = useAuthActions()
  const { setOnboardingStep } = useAuthActions()
  const history = useHistory()

  const handleNext = async () => {
    await updateCpaFirm(firm!.guid, { onboardingStatus: 'COMPLETE' })
    if (updateResponse.ok) {
      setOnboardingStep()
      history.replace(getCPAOnboardingPath('COMPLETE'))
    }
  }

  return (
    <>
      <UploadField fileIsUploaded={fileIsUploaded} setFileUploadStatus={setFileUploadStatus} />
      <Flex
        width={['100%']}
        justifyContent={['flex-end']}
        flexDirection={['column', 'row']}
        my={[4]}
      >
        <Box width={['100%', '190px']}>
          <Button
            type={fileIsUploaded ? 'primary' : 'ghost'}
            size="large"
            block
            onClick={handleNext}
            loading={updateLoading}
            disabled={updateLoading}
          >
            {fileIsUploaded ? t('onboarding.button.continue') : t('onboarding.button.skip')}
          </Button>
        </Box>
      </Flex>
    </>
  )
}

export default Uninvited
