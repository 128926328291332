import { useEffect, FC } from 'react'
import { Route, useHistory, useLocation } from 'react-router-dom'
import { useAuth, useAuthActions, UserType } from 'context/Auth'
import qs from 'qs'

import { INVITATIONS_PATH, LOGIN_PATH, REGISTER_PATH } from 'utils/routes'
import { SpinnerBackdrop } from 'components'
import { urlContainsInviteParams } from './helpers'

type TProps = any

const InvitationsRoute: FC<TProps> = (props) => {
  const history = useHistory()
  const location = useLocation()
  const {
    invitationsFlow: { status: flowStatus, response, inviteInfo },
    token,
  } = useAuth()
  const { setInvitationsFlowStatus, getInviteInfo } = useAuthActions()

  useEffect(() => {
    if (token) {
      setInvitationsFlowStatus('END')
    } else if (
      !flowStatus &&
      location.pathname === INVITATIONS_PATH &&
      urlContainsInviteParams(location)
    ) {
      setInvitationsFlowStatus('START')
      const userType = qs.parse(location.search, { ignoreQueryPrefix: true }).userType as UserType
      const invitationGuid = qs.parse(location.search, { ignoreQueryPrefix: true })
        .invitation_guid as string
      getInviteInfo(userType, invitationGuid)
    } else {
      setInvitationsFlowStatus('END')
    }
  }, [token])

  useEffect(() => {
    if (flowStatus === 'START') {
      if (response?.ok) {
        setInvitationsFlowStatus('END')
        if (inviteInfo!.accountExists) {
          history.replace({
            pathname: LOGIN_PATH,
            search: location.search,
            state: {
              from: location,
            },
          })
        } else {
          history.replace({
            pathname: REGISTER_PATH,
            search: location.search,
            state: {
              from: location,
            },
          })
        }
      }
      if (response?.ok === false) {
        setInvitationsFlowStatus('END')
        history.replace({
          pathname: LOGIN_PATH,
          state: {
            from: location,
          },
        })
      }
    }
  }, [flowStatus, response?.ok])

  return flowStatus === 'END' ? (
    <Route {...props} />
  ) : (
    <SpinnerBackdrop transparent={false} mode="global" size="large" />
  )
}

export default InvitationsRoute
