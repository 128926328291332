import { useEffect, useRef } from 'react'

export function useInterval(callback: (args?: any) => void, delay: number) {
  const savedCallback = useRef<(args?: any) => void>()

  useEffect(() => {
    savedCallback.current = callback
  })

  useEffect(() => {
    function tick() {
      if (savedCallback && savedCallback.current) savedCallback.current()
    }

    const id = setInterval(tick, delay)
    return () => clearInterval(id)
  }, [delay])
}
