import { useState } from 'react'
import { Typography, Form, message } from 'antd'
import { Flex, Box } from 'reflexbox'
import { useTranslation } from 'react-i18next'

import { browserSession } from 'utils/session'
import { initForm, saveFormToSession } from 'utils/form'
import { clientsLimitExceeded } from 'utils/http'

import { useAuth } from 'context/Auth'
import { useEntityCPAsActions } from 'context/EntityCPAs'

import { Button } from 'components'

import { TInviteClient } from '../types'
import ClientInvitationEntity from '../ClientInvitationEntity'
import EmailAndMessage from '../../Uninvited/components/EmailAndMessage'

const { Title, Text } = Typography

type TProps = {
  setVisiblePopover: (visible: boolean) => void
  setModalVisible: (state: boolean) => void
}

const InviteClient = ({ setVisiblePopover, setModalVisible }: TProps) => {
  const [form] = Form.useForm()
  const { t } = useTranslation('dashboard', { keyPrefix: 'clients.popover' })
  const { t: t2 } = useTranslation('common')
  const [errors, setErrors] = useState<Partial<TInviteClient>>({})
  const { sendInvitations, response, loading } = useEntityCPAsActions()

  const {
    CPA: { cpaFirm },
  } = useAuth()

  const onSubmit = async ({ entities = [], ...rest }: any) => {
    const params = { entities, ...rest }
    const result = await sendInvitations(cpaFirm!.guid, params)
    browserSession.removeItem('form:inviteClient')
    if (response.ok) {
      setErrors({})
      form.resetFields()
      message.success(t2('messages.success.invitationsSent'))
      setTimeout(() => setVisiblePopover(false), 500)
    } else if (clientsLimitExceeded(response.status, response.data)) {
      setVisiblePopover(false)
      setModalVisible(true)
    } else {
      setErrors(result)
    }
  }
  return (
    <>
      <Flex style={{ position: 'relative' }} width={['100%', '500px']}>
        <Form
          onFinish={onSubmit}
          form={form}
          autoComplete="off"
          onValuesChange={(changedValues: any, allValues: any) =>
            saveFormToSession('form:inviteClient', allValues)
          }
          initialValues={{
            ...initForm('form:inviteClient'),
            entities: [{ name: '', tin: '', kind: null }],
          }}
        >
          <Flex
            pt={2}
            px={[1, 2]}
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="inherit"
          >
            <Box>
              <Title level={4}>{t('title1')}</Title>
            </Box>
            <Box>
              <Text>{t('text1')}</Text>
            </Box>
            <Box mt={[2, 3]}>
              <EmailAndMessage errors={errors} />
            </Box>
            <Box mt={[0, 3]}>
              <ClientInvitationEntity title={t('title2')} fieldName="entities" errors={errors} />
            </Box>
            <Flex my={[2]} alignSelf={['stretch', 'flex-end']} width={['100%', '38%']}>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                block
                disabled={loading}
                loading={loading}
              >
                {t('submitButton')}
              </Button>
            </Flex>
          </Flex>
        </Form>
      </Flex>
    </>
  )
}

export default InviteClient
