import { useTranslation } from 'react-i18next'
import { Tabs } from 'antd'
import { Box } from 'reflexbox'

import Pending from './components/Pending'
import Uninvited from './components/Uninvited'
import Title from '../components/Title'

import './ant.tabs.scss'

const DEFAULT_KEY = 'pending'

const InviteClients = () => {
  const { t: t3 } = useTranslation('dashboard', { keyPrefix: 'firm' })

  return (
    <>
      <Box mb={[4, 5]}>
        <Title
          title={t3('onboarding.inviteClients.title')}
          subtitles={[
            t3('onboarding.inviteClients.subtitle1'),
            t3('onboarding.inviteClients.subtitle2'),
            t3('onboarding.inviteClients.subtitle3'),
          ]}
        />
        <Box className="tabs">
          <Tabs defaultActiveKey={DEFAULT_KEY} destroyInactiveTabPane>
            <Tabs.TabPane
              tab={
                <Box fontWeight={['600']} fontSize={['16px', '20px']}>
                  {t3('onboarding.inviteClients.tab1')}
                </Box>
              }
              key="pending"
            >
              <Pending />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <Box fontWeight={['600']} fontSize={['16px', '20px']}>
                  {t3('onboarding.inviteClients.tab2')}
                </Box>
              }
              key="uninvited"
            >
              <Uninvited />
            </Tabs.TabPane>
          </Tabs>
        </Box>
      </Box>
    </>
  )
}

export default InviteClients
