import { Modal, Typography } from 'antd'
import { Box, Flex } from 'reflexbox'
import { useTranslation } from 'react-i18next'

import styles from '../../../dashboard.tax_return.module.scss'

type TProps = {
  visible: boolean
  setVisible: (arg: boolean) => void
  currentStep: number
}

const CustomModal = ({ visible, setVisible, currentStep }: TProps) => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'taxReturns.instructions' })

  const handleOk = () => {
    setVisible(false)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  return (
    <>
      <Modal
        className={styles.tax_modal_container}
        title={
          <Flex pt={[3]} px={[3]}>
            <Typography.Title level={4}>{t(`modal.title.${currentStep + 1}`)}</Typography.Title>
          </Flex>
        }
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Flex px={[3]} flexDirection="column" alignItems="flex-start" justifyContent="flex-start">
          <Box
            alignItems={['baseline']}
            my="2"
            className={styles.tax_return_step}
            key="modal-tax-returns-1"
          >
            <span className={styles.tax_return_step_text}>
              {t(`modal.list.${currentStep + 1}`)}
            </span>
          </Box>
        </Flex>
      </Modal>
    </>
  )
}

export default CustomModal
