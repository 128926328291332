import { UploadFile } from 'antd/lib/upload/interface'
import { ValidateStatus } from 'antd/lib/form/FormItem'
import { EnumFinSoftware } from 'context/Financials'
import { TJobTitle } from 'context/Dashboard'
import { ActionTypes } from '.'
import { TEntity } from '../Entity'

export type IAction = {
  type: keyof typeof ActionTypes
  payload?: any
}

export enum EnumPhaseTypes {
  COLLECTING = 'COLLECTING',
  PREPARING = 'PREPARING',
  REVIEWING = 'REVIEWING',
  FILING = 'FILING',
  FILED = 'FILED',
  EXTENDED = 'EXTENDED',
}

export type PhaseName = EnumPhaseTypes

export type PhaseActionSlug =
  | 'SIGN_ENGAGEMENT'
  | 'ANSWER_QUESTIONS'
  | 'UPLOAD_DOCUMENTS'
  | 'ADD_FINANCIALS'
  | 'PREPARE_DRAFTS'
  | 'UPLOAD_DRAFTS'
  | 'REVIEW_RETURNS'
  | 'APPROVE_RETURNS'
  | 'FILE_RETURNS'
  | 'MARK_RETURNS_FILED'
  | 'COMPLETED'
  | string

export type PhaseAction = {
  completed: boolean
  label: string
  slug: PhaseActionSlug
  mutable: boolean
}

export enum EnumPhaseStatus {
  ACTIVE = 'ACTIVE',
  WAITING = 'WAITING',
  FILED = 'FILED',
}

export type PhaseStatus = EnumPhaseStatus

export type Phase = {
  actions: PhaseAction[]
  dueDate: string | null
  name: PhaseName
  completedOn: string | null
  isActionable: boolean
  status: PhaseStatus
}

export type DueDate = {
  dueDate: string
  isExtension: boolean
  guid: string
}

export type TEvent = {
  guid: string
  cpaFirm: string
  taxOrganizer: string
  eventType: 'CREATE_TAX_ORGANIZER' | 'UPLOAD_DOCUMENT' | string
  occurredOn: string
  user: IContact
}

export type TActivityFeed = {
  count: number
  next: string | null
  previous: string | null
  results: TEvent[]
}

export enum ClienStatusEnum {
  BLOCKED = 'BLOCKED',
  WAITING = 'WAITING',
  IN_PROGRESS = 'IN_PROGRESS',
}

export type ClientStatus = ClienStatusEnum

export enum CpaStatusEnum {
  BLOCKED = 'BLOCKED',
  WAITING = 'WAITING',
  IN_PROGRESS = 'IN_PROGRESS',
}

export type CpaStatus = CpaStatusEnum

export type TaxOrganizer = {
  clientStatus: ClientStatus
  primaryCpaContact: {
    role: 'owner' | string
    user: IContact
  }
  cpaFirm?: TCpaFirm
  cpaStatus: CpaStatus
  deadline: DueDate | null
  entity: TEntity
  guid: string
  phase: PhaseName
  phases: Phase[]
  taxYear: number
  financialSoftware: EnumFinSoftware | void
  unansweredQuestions: number
  unresolvedMessages: number
  unreadMessages: number
  isExtended: boolean
  extendedOn: string
  isLocked: boolean
}

export type UploadedBy = {
  guid: string
  firstName: string
  lastName: string
}

export type TTaxDocument = {
  guid: string
  name: string
  category: string
  status: 'PEN' | 'REA' | 'DEL' | 'FAI' | 'UPD'
  file: string | null
  presignFilePath: string | null
  pageCount: number | null
  fileSize: number | null
  version?: number
  year: number | null
  tags: TTags[]
  createdOn: string
  updatedOn: string
  uploadedBy: UploadedBy
  fileUrl?: string
  afterTaxOrganizerLock: boolean
}

export type TActivityFeedQueryParams = {
  cpaFirm?: string
  ordering?: string
  page?: number
  pageSize?: number
  search?: string
  taxOrganizer?: string
  type?: string
}

export type TCPAContactsQueryParams = {
  ordering?: string
  page?: number
  search?: string
}

export type TCPAContact = {
  guid: string
  email: string
  firstName?: string
  lastName?: string
  phone?: string
  avatar?: string | null
}

export type TCPAEntityContact = {
  user: TCPAContact
  isPrimary: boolean
  role?: string
  jobTitle: TJobTitle
}

export type SharedInviteesRequest = {
  emails: string[]
  message: string
  documentGuids: string[]
}

export type RequestInvitees = Omit<SharedInviteesRequest, 'documentGuids'>

export type TaxDocumentInvite = Omit<TTaxDocument, 'unresolvedMessages'>

export type TaxDocumentResults = {
  taxReturnId: string
  isLatest: boolean
  taxDocument: TaxDocumentInvite
  pageCount: number
  taxQuestionareQuestionGuid: string
}

export type InvitedBy = Omit<UploadedBy, 'guid'>

export interface ShareInvites {
  entityName: string
  taxYear: number
  invitedBy: InvitedBy
  message: string
  taxDocuments: TaxDocumentResults[]
  zipFile: string
}

export type RequestFormDetails = {
  email: string
  firstName: string
  lastName: string
}

export type TaxOrganizerInvites = {
  guid: string
  taxYear: number
  entityName: string
}

export type Invites = {
  invitationGuid: string
  token: string
}

export type UploadInvites = {
  taxOrganizer: TaxOrganizerInvites
  invitedBy: InvitedBy
  message: string
  email: string
}

export type UpdateTaxOrganizer = {
  phase?: string
  cpaStatus?: CpaStatus | string
  clientStatus?: ClientStatus | string
  financialSoftware?: EnumFinSoftware | string
}

export type BodyUploadInvites = RequestFormDetails & { token: string; file: string; name: string }

export type TDocumentsSummary = {
  total: number
  untagged: number
  uncategorized: number
  requiresAction: number
}

export type TFileUploadOverrideProps = {
  uploadUrl?: string
  deleteUrl?: string
  updateUrl?: string
}

export type TLoadDocumentsParams = {
  category?: string
  name?: string
  ordering?: string
  page?: number
  pageSize?: number
  requiresAction?: boolean
  tags?: string
  search?: string
  isVisible?: boolean
}

export type TUploadDocumentParams = {
  name: string
  category?: string
  year?: number
  tags?: string | string[]
  unregisteredUserEmail?: string | null
  file: string | null
  tqQuestion?: string
}

export type TTaxDocumentExtended = TTaxDocument & {
  uid?: string
  loading?: boolean
  responseOk?: boolean
}

export type TUploadDocumentResponse = {
  category: string | null
  file: string
  fileUrl: string
  guid: string
  name: string
  presignFilePath: string
  status: string
  taxOrganizer: string
  uploadUrl: string
  year: number
  tags?: { slug: string; name: string }[]
}

export type UpdateDocumentParams = {
  name?: string
  category?: string
  tags?: string[]
  year?: number
}

export type TMultipleDocumentParams = UpdateDocumentParams & {
  guid: string
  validateStatus: ValidateStatus
}

export type TFile = UploadFile
