import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useFetch from 'use-http'
import { localizeEntityTitles } from '../../../helpers'

export default () => {
  const { get, response } = useFetch()
  const [entityKinds, setEntityKinds] = useState<TOption[]>([])
  const { t } = useTranslation('entity')
  useEffect(() => {
    const loadEntityKinds = async () => {
      const kinds = await get('/entities/kinds')
      if (response.ok) {
        setEntityKinds(localizeEntityTitles(kinds, t))
      }
    }
    loadEntityKinds()
  }, [])

  return { entityKinds }
}
