import { useFetch } from 'use-http'
import qs from 'qs'
import { has } from 'lodash'
import { toSnake } from 'utils'

import { ActionTypes } from './action-types'
import { useQuestions } from './context'
import { TqCategoriesParamsType, TQQuestionParams, UpdateQuestionType } from './types'

const useQuestionsActions = () => {
  const { dispatch } = useQuestions()

  const { get, patch, loading, error, response, abort } = useFetch()

  const loadTQCategories = async (guid: string, params: TqCategoriesParamsType = {}) => {
    const categories = await get(
      `tax-organizers/${guid}/tq-categories${qs.stringify(toSnake(params), {
        addQueryPrefix: true,
      })}`
    )
    dispatch({
      type: ActionTypes.LOAD_TQ_CATEGORIES,
      payload: categories,
    })
  }

  const loadQuestions = async (params: TQQuestionParams) => {
    const query = qs.stringify(toSnake({ ...params }), { addQueryPrefix: !!params })
    const result = await get(`tax-organizers/tq-questions${query}`)
    if (response.ok) {
      if (has(params, 'category')) {
        dispatch({ type: ActionTypes.LOAD_QUESTIONS_BY_CATEGORY, payload: result })
      } else {
        dispatch({ type: ActionTypes.LOAD_QUESTIONS, payload: result })
      }
    }
  }

  const updateQuestion = async (params: UpdateQuestionType) => {
    await patch('tax-organizers/tq-questions', [toSnake(params)])
  }

  const updateQuestionValueSync = async (questionGuid: string, value: boolean) => {
    dispatch({
      type: ActionTypes.UPDATE_QUESTION_VALUE,
      payload: {
        questionGuid,
        value,
      },
    })
  }

  const updateQuestionTextSync = async (questionGuid: string, text: string) => {
    dispatch({
      type: ActionTypes.UPDATE_QUESTION_TEXT,
      payload: {
        questionGuid,
        text,
      },
    })
  }

  return {
    loadTQCategories,
    loadQuestions,
    updateQuestion,
    updateQuestionValueSync,
    updateQuestionTextSync,
    response,
    loading,
    error,
    abort,
  }
}

export default useQuestionsActions
export { ActionTypes, useQuestionsActions }
