import { useMemo, useState, useEffect } from 'react'
import { Row, Col, Form } from 'antd'
import { useTranslation } from 'react-i18next'

import { useCPAFirm, useCPAFirmActions } from 'context/CPAFirm'
import { useAuth, useAuthActions } from 'context/Auth'

import {
  Alert,
  Select,
  TextField,
  Button,
  PhoneInput,
  GoogleAddressLookup,
  SpinnerBackdrop,
} from 'components'

import { countries, states } from 'constants/address'

import { getAddressComponentByType } from 'utils'
import ProfileLogo from '../Logo'
import styles from '../../dashboard.firm.profile.module.scss'
import TradeShow from '../TradeShow'

const ProfileForm = () => {
  const { tradeShow } = useCPAFirm()
  const {
    CPA: { userRole, cpaFirm },
  } = useAuth()
  const { updateCpaFirm, loading, response } = useAuthActions()
  const { loadTradeShow, loading: loadingTradeShow } = useCPAFirmActions()

  const [errors, setErrors] = useState({} as TCpaFirm)
  const { t } = useTranslation('dashboard', { keyPrefix: 'firm.form' })
  const { t: t2 } = useTranslation('common', { keyPrefix: 'form' })
  const required = {
    required: true,
    message: t2('required'),
  }

  const initialValues = useMemo(
    () => ({
      ...cpaFirm,
    }),
    [cpaFirm]
  )
  const statesOptions = useMemo(
    () =>
      states.map((state) => ({
        ...state,
        label: `${state.label} (${state.value})`,
      })),
    []
  )

  const handleSubmit = async (values: Partial<TCpaFirm>) => {
    updateCpaFirm(cpaFirm!.guid, values)
    if (response.ok) {
      setErrors(response.data)
    }
  }

  useEffect(() => {
    loadTradeShow(cpaFirm!.guid)
  }, [])

  return (
    <>
      <Form
        initialValues={initialValues}
        layout="vertical"
        onFinish={handleSubmit}
        style={{ pointerEvents: userRole === 'MEMBER' ? 'none' : 'auto' }}
      >
        <Row gutter={[20, 0]}>
          <Col xs={24} md={12}>
            <Form.Item name="name" label={t('name')} rules={[required]}>
              <TextField />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item noStyle shouldUpdate>
              {(props) => (
                <Form.Item name={['address', 'line1']} label={t('address1')} rules={[required]}>
                  <GoogleAddressLookup
                    onSelect={(addressComponents) => {
                      props.setFieldsValue({
                        // Geocoding reference: https://developers.google.com/maps/documentation/geocoding/requests-geocoding#Types
                        address: {
                          line1: getAddressComponentByType(addressComponents, 'streetAddress'),
                          city: getAddressComponentByType(addressComponents, 'locality'),
                          zipCode: getAddressComponentByType(addressComponents, 'postal_code'),
                          state: getAddressComponentByType(
                            addressComponents,
                            'administrative_area_level_1'
                          ),
                          country: getAddressComponentByType(addressComponents, 'country'),
                        },
                      })
                    }}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item name={['address', 'city']} label={t('city')} rules={[required]}>
              <TextField />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item name={['address', 'line2']} label={t('address2')}>
              <TextField />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Row gutter={[16, 0]}>
              <Col xs={12}>
                <Form.Item name={['address', 'state']} label={t('state')} rules={[required]}>
                  <Select options={statesOptions} />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item
                  name={['address', 'zipCode']}
                  label={t('zipCode')}
                  rules={[required, { max: 6, message: t('invalidZipCodeLength') }]}
                  validateStatus={errors?.address?.zipCode && 'error'}
                  help={errors?.address?.zipCode}
                >
                  <TextField />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item name={['address', 'line3']} label={t('address3')}>
              <TextField />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item name={['address', 'country']} label={t('country')}>
              <Select options={countries} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="email"
              label={t('email')}
              rules={[{ type: 'email', message: t('invalidEmail') }]}
              validateStatus={errors?.email && 'error'}
              help={errors?.email}
            >
              <TextField />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <PhoneInput
              name="phone"
              label={t('phone')}
              validateStatus={errors?.phone && 'error'}
              help={errors?.phone}
              initialValue={cpaFirm!.phone}
            />
          </Col>
        </Row>
        {response.ok && <Alert type="success" message={t('updateSuccess')} />}
        <Button
          htmlType="submit"
          type="primary"
          size="large"
          className={styles.submit}
          loading={loading}
          disabled={userRole === 'MEMBER'}
        >
          {t('submit')}
        </Button>
      </Form>
      <Row gutter={[32, 0]} className={styles.block}>
        <Col xs={24} md={12}>
          <ProfileLogo />
        </Col>
        <Col xs={24} md={12}>
          {loadingTradeShow && <SpinnerBackdrop mode="local" />}
          {tradeShow && <TradeShow />}
        </Col>
      </Row>
    </>
  )
}

export default ProfileForm
