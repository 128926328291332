import { useEffect, useMemo, useRef, useState } from 'react'
import { DragSourceMonitor, useDrag, useDrop } from 'react-dnd'
import { Box, Flex } from 'reflexbox'
import cn from 'classnames'
import { CloseOutlined, ZoomInOutlined } from '@ant-design/icons'
import { Dropdown, Image, Modal } from 'antd'
import type { Identifier } from 'dnd-core'
import { capitalize } from 'lodash'

import { Button, SpinnerBackdrop } from 'components'

import {
  TTaxReturnPage,
  TPagesRequest,
  TRequestDocument,
  useTaxReturns,
  useTaxReturnsActions,
} from 'context/TaxReturns'
import { getBodyForResetSelectedPage, getGeneratePagesBody } from 'utils/categorize'
import { useTranslation } from 'react-i18next'
import styles from '../categorize.module.scss'
import CategorizeMenu from './CategorizeMenu'
import PDFDocument from '../../../../Document/components/DocumentView/components/[OLD]PDFDocument'
import Pagination from '../../../../Document/components/DocumentView/components/Pagination'

type TProps = {
  page: TTaxReturnPage
  isDraggingPage: boolean
  setIsDraggingPages: (isDragging: boolean) => void
  index: number
  moveDocument: (dragIndex: number, hoverIndex: number) => void
  setIsDropSortedDocuments: (isDropSortedDocuments: boolean) => void
  dragPageId: string | null
  setDragPageId: (guidId: string | null) => void
  setDragPageIdsFotPressShift: (guidId: string) => void
  showResetIcon?: boolean
  uncategorized?: boolean
  sortDocuments: TTaxReturnPage[]
}

interface DragItem {
  index: number
  type: string
  isSorted: boolean
}

const DocumentPage = ({
  page,
  isDraggingPage,
  setIsDraggingPages,
  index,
  moveDocument,
  setIsDropSortedDocuments,
  dragPageId,
  setDragPageId,
  setDragPageIdsFotPressShift,
  showResetIcon = false,
  uncategorized,
  sortDocuments,
}: TProps) => {
  const { t } = useTranslation('common')
  const { updateTaxReturnPages, loading, setDragDropDocumentIds } = useTaxReturnsActions()
  const {
    state: {
      selectedTags,
      categories,
      dragDropDocumentIds,
      pages,
      isPressShift,
      taxReturnDocuments: { data: taxReturnDocuments },
    },
  } = useTaxReturns()
  const [pagesBody, setPagesBody] = useState<TPagesRequest | null>(null)
  const [clickedPage, setClickedPage] = useState<boolean>(false)
  const [selectedPage, setSelectedPage] = useState<TTaxReturnPage | undefined>()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false)

  const fileDocument = useMemo(
    () =>
      taxReturnDocuments.find(
        ({ taxDocument }) => taxDocument.guid === selectedPage?.taxDocumentGuid
      ),
    [selectedPage]
  )
  const isPDF = useMemo(() => {
    return !!(fileDocument ? /.pdf$/i.test(fileDocument.taxDocument.presignFilePath ?? '') : false)
  }, [fileDocument])

  const ref = useRef<HTMLDivElement>(null)

  const [{ handlerId }, drop] = useDrop<DragItem, {}, { handlerId: Identifier | null }>({
    accept: 'categorize',
    drop: (item) => ({
      type: 'moveDocument',
      isSorted: item.isSorted,
    }),
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem) {
      if (!ref.current) {
        return
      }

      if (!dragPageId) return

      const dragIndex = item.index
      const hoverIndex = index

      moveDocument(dragIndex, hoverIndex)

      item.index = hoverIndex
      item.isSorted = true
    },
  })

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'categorize',
      item: { page, index },
      end(item, monitor) {
        const dropResult = monitor.getDropResult<
          Omit<TRequestDocument, 'guid' | 'categoryPageNumber'> & DragItem
        >()

        if (item && dropResult?.type === 'moveDocument') {
          setIsDropSortedDocuments(dropResult.isSorted)
        }

        if (item && dropResult?.parentCategory) {
          const generatedPagesBody = getGeneratePagesBody(
            dragPageId ? [dragPageId] : dragDropDocumentIds,
            pages,
            dropResult,
            !showResetIcon
          )

          if (generatedPagesBody) {
            setDragDropDocumentIds([])
            setDragPageId(null)
            setPagesBody(generatedPagesBody)
          }
        }
      },
      collect: (monitor: DragSourceMonitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [page, dragDropDocumentIds, dragPageId]
  )

  const showModal = (pageToOpen: TTaxReturnPage) => {
    setDragDropDocumentIds([])
    setIsModalVisible(true)
    setIsMenuVisible(false)
    setSelectedPage(pageToOpen)
  }

  const onCancel = () => {
    setIsModalVisible(false)
  }

  const visibleChangeMenu = (isMenu: boolean) => {
    setIsMenuVisible(isMenu)
  }

  const handleSelectMenu = (contentMenu: Omit<TRequestDocument, 'guid' | 'categoryPageNumber'>) => {
    const generatedPagesBody = getGeneratePagesBody(
      dragDropDocumentIds.length ? dragDropDocumentIds : [page.guid],
      pages,
      contentMenu,
      !showResetIcon
    )

    if (generatedPagesBody) {
      setIsMenuVisible(false)
      setPagesBody(generatedPagesBody)
    }
  }

  const handleRemoveDocumentId = () => {
    setDragDropDocumentIds(dragDropDocumentIds.filter((documentGuid) => documentGuid !== page.guid))
  }

  const handleSetDocumentId = (event: any) => {
    event.stopPropagation()
    if (isPressShift && dragDropDocumentIds.length) {
      setDragPageIdsFotPressShift(page.guid)
    } else {
      setDragDropDocumentIds(dragDropDocumentIds.concat(page.guid))
    }

    setDragPageId(null)
  }

  const handlePressPage = () => {
    setDragPageId(page.guid)
  }

  const handleOverPage = () => {
    if (dragPageId === page.guid) {
      setDragPageId(null)
    }
  }

  const resetDocument = (currentPage: TTaxReturnPage) => {
    const resetDocumentBody = getBodyForResetSelectedPage(currentPage, pages)

    setPagesBody(resetDocumentBody)
  }

  useEffect(() => {
    setIsDraggingPages(isDragging)
  }, [isDragging])

  useEffect(() => {
    if (pagesBody) {
      updateTaxReturnPages(pagesBody)
    }
  }, [pagesBody])

  drag(drop(ref))

  return (
    <div style={{ opacity: isDraggingPage ? '0' : '1' }}>
      {loading && <SpinnerBackdrop mode="global" />}
      <Box>
        <Flex
          width={['100px']}
          mr={[3]}
          mb={[3]}
          flexDirection={['column']}
          alignItems={['center']}
        >
          <Box
            className={styles.document_container_box}
            ref={dragPageId === page.guid || dragDropDocumentIds.includes(page.guid) ? ref : null}
            data-handler-id={handlerId}
          >
            <Box
              ref={
                dragDropDocumentIds.includes(page.guid) || dragPageId === page.guid
                  ? drag
                  : dragPageId && dragPageId !== page.guid && showResetIcon
                  ? ref
                  : null
              }
              onClick={handleRemoveDocumentId}
              onMouseDown={() => setClickedPage(true)}
              onMouseOver={() => setClickedPage(false)}
              className={cn(
                styles.document_box,
                dragDropDocumentIds.includes(page.guid)
                  ? styles.document_box_drag
                  : styles.document_box_hover,
                {
                  [styles.document_clicked_page]:
                    clickedPage && dragDropDocumentIds.includes(page.guid),
                  [styles.focus]: isMenuVisible,
                  [styles.drag]: isDragging,
                }
              )}
              data-handler-id={handlerId}
            >
              <Image
                src={page.thumbnail}
                preview={false}
                width="100%"
                height="100%"
                className={styles.document_fullview}
                alt="document"
              />

              {showResetIcon && (
                <span
                  className={cn(styles.document_btn, styles.document_close)}
                  onClick={() => resetDocument(page)}
                >
                  <CloseOutlined />
                </span>
              )}

              <span className={styles.document_btn} onClick={() => showModal(page)}>
                <ZoomInOutlined />
              </span>

              <Box
                style={{ opacity: dragPageId === page.guid ? 0 : '' }}
                className={styles.overlay}
                onClick={(event) => handleSetDocumentId(event)}
                onMouseDown={handlePressPage}
                onMouseOver={() => handleOverPage()}
              />

              <Dropdown
                className={styles.btn_categorize}
                overlay={
                  <CategorizeMenu
                    categories={categories}
                    selectedTags={selectedTags}
                    handleSelectMenu={handleSelectMenu}
                  />
                }
                placement="bottomLeft"
                trigger={['click']}
                visible={isMenuVisible}
                onVisibleChange={(isVisible) => visibleChangeMenu(isVisible)}
              >
                <Button
                  style={{ display: dragPageId === page.guid ? 'none' : 'block' }}
                  type="primary"
                  size="small"
                  onClick={(event) => event.stopPropagation()}
                >
                  {t('buttons.categorize')}
                </Button>
              </Dropdown>
            </Box>
          </Box>

          {uncategorized && (
            <Box mt={[2]}>
              {capitalize(t('page'))} {page.pageNumber + 1}
            </Box>
          )}
        </Flex>
        <Modal
          visible={isModalVisible && !isMenuVisible}
          onCancel={onCancel}
          closable={false}
          className="tax-return-document"
          footer={null}
          bodyStyle={{ padding: '20px', marginTop: 0 }}
          destroyOnClose
        >
          <Flex justifyContent={['flex-end']}>
            {' '}
            <Flex width={['50%']} justifyContent={['space-between']} alignItems={['center']}>
              <Box className={styles.modal_pagination}>
                <Pagination
                  page={
                    uncategorized
                      ? selectedPage
                        ? selectedPage.pageNumber + 1
                        : 1
                      : selectedPage
                      ? selectedPage.categoryPageNumber
                      : 1
                  }
                  total={sortDocuments.length}
                  onChange={(currentPage: number) => {
                    if (uncategorized) {
                      setSelectedPage(
                        sortDocuments.find(
                          (doc: TTaxReturnPage) => doc?.pageNumber + 1 === currentPage
                        )
                      )
                    } else {
                      setSelectedPage(
                        sortDocuments.find(
                          (doc: TTaxReturnPage) => doc?.categoryPageNumber === currentPage
                        )
                      )
                    }
                  }}
                />
              </Box>
              <Button
                ghost
                size="large"
                type="primary"
                onClick={() => {
                  setIsModalVisible(false)
                }}
              >
                {t('buttons.close')}
              </Button>
            </Flex>
          </Flex>
          {isPDF ? (
            <PDFDocument
              file={fileDocument?.taxDocument.file}
              page={Number(selectedPage?.pageNumber) + 1}
            />
          ) : (
            <Image
              src={fileDocument?.taxDocument.file!}
              preview={false}
              width="100%"
              className={styles.document_fullview}
              alt="document"
            />
          )}
        </Modal>
      </Box>
    </div>
  )
}

export default DocumentPage
