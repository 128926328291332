import { useEffect } from 'react'
import { Box } from 'reflexbox'
import { useTranslation } from 'react-i18next'

// @ts-expect-error
import csvTemplate from 'assets/templates/ClientListTemplate.xlsx'

import { Alert, Upload, SpinnerBackdrop } from 'components'

import { useEntityCPAs, useEntityCPAsActions } from 'context/EntityCPAs'
import { useAuth } from 'context/Auth'

type TProps = {
  fileIsUploaded: boolean
  setFileUploadStatus: (state: boolean) => void
}

const UploadField = ({ fileIsUploaded, setFileUploadStatus }: TProps) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'clients' })
  const {
    CPA: { cpaFirm },
  } = useAuth()
  const {
    response: uploadResponse,
    loading: uploadLoading,
    uploadUnsentInvitationsFile,
  } = useEntityCPAsActions()
  const { getUnsentInvitationFiles, loading: unsentLoading } = useEntityCPAsActions()
  const {
    state: { unsentInvitationFiles },
  } = useEntityCPAs()

  const handleUpload = async ({ file }: { file: File }) => {
    await uploadUnsentInvitationsFile(cpaFirm!.guid, file)
    if (uploadResponse.ok) {
      setFileUploadStatus(true)
    }
  }

  useEffect(() => {
    getUnsentInvitationFiles(cpaFirm!.guid)
  }, [])

  useEffect(() => {
    setFileUploadStatus(!!unsentInvitationFiles?.length)
  }, [unsentInvitationFiles])

  return (
    <Box className="position-relative" mb={[4]}>
      {(uploadLoading || unsentLoading) && <SpinnerBackdrop mode="local" transparent={false} />}
      <Box mb={[1, 2, 3]}>
        {fileIsUploaded ? (
          <Alert type="warning" message={t('upload.after')} />
        ) : (
          <Alert
            type="info"
            message={
              <>
                {t('upload.before.0')}{' '}
                <a href={csvTemplate} download target="_blank" rel="noreferrer">
                  {t('upload.before.link')}
                </a>{' '}
                {t('upload.before.1')}
              </>
            }
          />
        )}
      </Box>
      <Box>
        {!fileIsUploaded && (
          <Upload fileList={[]} onChange={handleUpload} accept=".xls,.xlsx,.csv" multiple={false} />
        )}
      </Box>
    </Box>
  )
}

export default UploadField
