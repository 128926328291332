import { useEffect, useState } from 'react'
import { Form, Modal, Typography } from 'antd'
import { CloseOutlined, FileOutlined } from '@ant-design/icons'
import { Box, Flex } from 'reflexbox'

import { useTranslation } from 'react-i18next'

import { checkIfFileTypeIsSupported } from 'utils/files'
import { ALLOWED_DOCUMENT_FILE_TYPES } from 'utils/taxOrganizer'

import colors from 'assets/styles/variables.module.scss'

import {
  BodyUploadInvites,
  RequestFormDetails,
  UploadInvites,
  useDocumentsActions,
  TFile,
} from 'context/TaxOrganizer'

import { Alert, Button, Upload, TextField } from 'components'
import { REGISTER_PATH } from 'utils/routes'
import UploadRequestModal from './components/UploadRequestModal'

import styles from './request.documents.module.scss'

const { Title, Paragraph } = Typography
const { error } = Modal

const RequestDocuments = () => {
  const { t } = useTranslation('common', { keyPrefix: 'shared' })
  const { t: t2 } = useTranslation('common', { keyPrefix: 'request' })
  const { t: t3 } = useTranslation('common', { keyPrefix: 'upload2' })
  const { t: t4 } = useTranslation('common', { keyPrefix: 'form' })
  const { t: t5 } = useTranslation('common', { keyPrefix: 'upload' })

  const { loadDocumentUploadInvites, response } = useDocumentsActions()
  const { resendDocumentUploadInvites, loading: resendLoading } = useDocumentsActions()
  const [form] = Form.useForm()
  const [queryParams, setQueryParams] = useState<{ invitationGuid: string; token: string } | null>(
    null
  )
  const [files, setFiles] = useState<TFile[]>([])
  const [paramsFile, setParamsFile] = useState<
    (Omit<BodyUploadInvites, 'file' | 'name'> & { invitationGuid: string }) | null
  >(null)
  const [uploadInvites, setUploadInvites] = useState<UploadInvites | null>(null)
  const [isExpiredToken, setIsExpiredToken] = useState<boolean>(false)
  const [isRequiredFile, setIsRequiredFile] = useState<boolean>(false)
  const [isShowModal, setIsShowModal] = useState<boolean>(false)
  const [filesUploaded, setFilesUploaded] = useState<boolean>(false)

  const onSubmit = async (formValues: RequestFormDetails) => {
    if (!files.length) {
      setIsRequiredFile(true)

      return
    }

    if (!queryParams) return

    const formationParamsFile: Omit<BodyUploadInvites, 'file' | 'name'> & {
      invitationGuid: string
    } = {
      ...formValues,
      token: queryParams.token,
      invitationGuid: queryParams.invitationGuid,
    }

    setParamsFile(formationParamsFile)
    setFilesUploaded(false)
    setIsShowModal(true)
  }

  const handleHideModal = () => {
    setIsShowModal(false)
    setParamsFile(null)
    form.resetFields()
    setFiles([])
  }

  const handleUpload = ({ fileList }: any) => {
    const fileTypeSupported = fileList.every((file: TFile) =>
      checkIfFileTypeIsSupported(file, ALLOWED_DOCUMENT_FILE_TYPES)
    )
    if (!fileTypeSupported) {
      error({ title: t3('formatError') })
      return
    }
    setFiles(fileList)
    setIsRequiredFile(false)
  }

  const handleResendInvites = async () => {
    if (queryParams && queryParams.invitationGuid) {
      await resendDocumentUploadInvites(queryParams.invitationGuid)
    }
  }

  const getDocumentInvites = async () => {
    const responseInvites = await loadDocumentUploadInvites(queryParams!)

    if (response.ok) {
      setUploadInvites(responseInvites as UploadInvites)
      setIsExpiredToken(false)
    } else {
      setIsExpiredToken(true)
    }
  }

  useEffect(() => {
    const { search } = window.location
    const params = new URLSearchParams(search)
    setQueryParams({
      invitationGuid: params.get('invitation_guid') as string,
      token: params.get('token') as string,
    })
  }, [])

  useEffect(() => {
    if (!queryParams) return

    getDocumentInvites()
  }, [queryParams])

  return (
    <div className={styles.request_container}>
      {isExpiredToken ? (
        <>
          <Paragraph>{t('expiredToken')}</Paragraph>

          <Paragraph>{t('expiredTokenAfter')}</Paragraph>

          <Button
            disabled={resendLoading}
            type="primary"
            size="large"
            onClick={handleResendInvites}
          >
            {t('buttonResendLink')}
          </Button>
        </>
      ) : (
        <>
          {uploadInvites && (
            <>
              <Title level={3} style={{ margin: 0 }}>
                {uploadInvites?.taxOrganizer?.entityName}
              </Title>

              <Title level={3} style={{ margin: 0 }}>
                {`${t('taxOrganizerYear')} ${uploadInvites.taxOrganizer.taxYear}`}
              </Title>

              <Paragraph style={{ marginTop: '1rem' }}>
                {`${t('messageFrom')} ${uploadInvites.invitedBy.firstName} ${
                  uploadInvites.invitedBy.lastName
                }`}
              </Paragraph>

              <div className={styles.alert_container}>
                <Paragraph className={styles.alert_text}>{uploadInvites.message}</Paragraph>
              </div>

              <div className={styles.upload}>
                <Upload fileList={[]} onChange={handleUpload} />

                {isRequiredFile && <span style={{ color: colors.error }}>File is required</span>}

                {files.length ? (
                  <Flex alignItems={['baseline']} mr={[3]} mt={[3]}>
                    <FileOutlined />

                    <Box ml={[1]}>{files[0].name}</Box>

                    <Box ml={[2]}>
                      <CloseOutlined style={{ cursor: 'pointer' }} onClick={() => setFiles([])} />
                    </Box>
                  </Flex>
                ) : null}

                {filesUploaded && (
                  <Box mt={[3]}>
                    <Alert type="success" message={t5('modal.status.uploaded')} />
                  </Box>
                )}
              </div>

              {isShowModal && paramsFile && (
                <UploadRequestModal
                  fileList={files}
                  onHide={handleHideModal}
                  paramsFileNotAuthorize={paramsFile}
                  setFilesUploaded={setFilesUploaded}
                />
              )}

              <Form form={form} onFinish={onSubmit} className={styles.form_wrapper}>
                <div className={styles.form_fields}>
                  <Paragraph className={styles.form_label}>{t2('email')}</Paragraph>

                  <Form.Item
                    rules={[
                      { required: true, message: t2('requiredEmail') },
                      { type: 'email', message: t2('validEmail') },
                    ]}
                    initialValue={uploadInvites?.email}
                    name="email"
                    key="email"
                  >
                    <TextField
                      placeholder={t2('email')}
                      size="middle"
                      disabled={!!uploadInvites?.email}
                    />
                  </Form.Item>

                  <Paragraph className={styles.form_label}>{t2('firstName')}</Paragraph>

                  <Form.Item
                    rules={[{ required: true, message: t2('requiredFirstName') }]}
                    initialValue=""
                    name="firstName"
                    key="firstName"
                  >
                    <TextField placeholder={t2('firstName')} size="middle" />
                  </Form.Item>

                  <Paragraph className={styles.form_label}>{t2('lastName')}</Paragraph>

                  <Form.Item
                    rules={[{ required: true, message: t2('requiredLastName') }]}
                    initialValue=""
                    name="lastName"
                    key="lastName"
                  >
                    <TextField placeholder={t2('lastName')} size="middle" />
                  </Form.Item>

                  <div className={styles.form_action_row}>
                    <Button
                      className={styles.form_submit}
                      type="primary"
                      size="large"
                      htmlType="submit"
                      block
                    >
                      {t2('buttonsText')}
                    </Button>
                  </div>
                  <Form.Item>
                    <Button href={REGISTER_PATH} type="link" block>
                      {t4('noAccountSignUp')}
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default RequestDocuments
