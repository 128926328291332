import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { Alert } from 'components'

import { FilingStateEnum } from 'context/TaxReturns'
import useUserType from 'hooks/useUserType'
import { TFilingStateProps } from '../types'

import styles from '../../../dashboard.tax_return.module.scss'

type TProps = TFilingStateProps & {
  isFilePending: boolean
}
const PendingConfirmation = ({ taxReturnUpdate, isFilePending }: TProps) => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'taxReturns.fieldReturn' })
  const userType = useUserType()

  return (
    <Alert
      className={styles.tax_return_review_guide}
      message={
        <div className={styles.wrapper}>
          {isFilePending ? (
            <div className={styles.content_alert}>
              <div>
                <div className={styles.title}>{t('pendingEFileConfirmationTitle')}</div>

                <div>{t('filePendingConfirmationDescription')}</div>
              </div>
              {userType === 'CPA' && (
                <div className={styles.actions_btn}>
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => taxReturnUpdate(FilingStateEnum.EFILE_SUCCESS)}
                  >
                    {t('confirmEFilingSucceeded')}
                  </Button>

                  <Button
                    type="primary"
                    size="small"
                    ghost
                    onClick={() => taxReturnUpdate(FilingStateEnum.EFILE_FAILED)}
                  >
                    {t('eFilingFailed')}
                  </Button>

                  <Button
                    type="primary"
                    size="small"
                    ghost
                    onClick={() => taxReturnUpdate(FilingStateEnum.PAPER_PENDING_CONFIRMATION)}
                  >
                    {t('switchToPaperFile')}
                  </Button>
                </div>
              )}
            </div>
          ) : (
            <div className={styles.content_alert}>
              <div>
                <div className={styles.title}>{t('pendingConfirmationTitle')}</div>

                <div>{t('paperPendingConfirmationDescription')}</div>
              </div>
              <div className={styles.actions_btn}>
                {userType === 'CPA' && (
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => taxReturnUpdate(FilingStateEnum.EFILE_PENDING_CONFIRMATION)}
                  >
                    {t('switchToEFile')}
                  </Button>
                )}
                <Button
                  type="primary"
                  size="small"
                  ghost
                  onClick={() => taxReturnUpdate(FilingStateEnum.PAPER_SUCESSS)}
                >
                  {t('confirmPaperReturnMailed')}
                </Button>
              </div>
            </div>
          )}
        </div>
      }
      type="warning"
    />
  )
}

export default PendingConfirmation
