import { useState, useEffect } from 'react'
import { Modal, Button } from 'components'
import { Flex } from 'reflexbox'
import { useTranslation } from 'react-i18next'
import { NavLink, useRouteMatch } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import cn from 'classnames'
import { PlusOutlined } from '@ant-design/icons'

import { useAuth } from 'context/Auth'

import { useCPAFirmActions } from 'context/CPAFirm'
import InviteMembers from './components/InviteMembers'

import styles from '../../dashboard.firm.module.scss'

type TProps = {
  setPopoverStatus: (state: boolean) => void
}

const Header = ({ setPopoverStatus }: TProps) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'firm.team' })
  const {
    CPA: { userRole, cpaFirm },
  } = useAuth()
  const { loadUserRolesCPAFirm, loading: loadingUserRoles } = useCPAFirmActions()
  const isMobile = useMediaQuery({ query: '(max-width: 40em)' })
  const {
    params: { slug },
  } = useRouteMatch<any>()
  const [popoverVisible, setPopoverVisible] = useState(false)

  const handleVisibleChange = (visible: boolean) => {
    setPopoverVisible(visible)
    setPopoverStatus(visible)
  }

  useEffect(() => {
    loadUserRolesCPAFirm(cpaFirm!.guid)
  }, [])

  return (
    <>
      <Flex
        flexDirection={['column', 'row']}
        alignItems={['center']}
        justifyContent="space-between"
      >
        <Flex className={styles.nav} width={['100%', '70%', '60%']}>
          <NavLink
            to="/dashboard/firm/team"
            exact
            className={cn(!slug && styles.nav_is_active)}
            activeClassName={styles.nav_is_active}
          >
            {t('teamMembers')}
          </NavLink>
          <NavLink
            to="/dashboard/firm/team/invitations"
            exact
            activeClassName={styles.nav_is_active}
          >
            {t('pending')}
          </NavLink>
        </Flex>
        {userRole !== 'MEMBER' && (
          <Flex justifyContent={['flex-start', 'flex-end']} width={['100%']} mt={[3, 0]}>
            <Modal
              content={
                <InviteMembers
                  setVisiblePopover={(isVisiblePopover) => handleVisibleChange(isVisiblePopover)}
                />
              }
              trigger="click"
              placement="bottomRight"
              overlayClassName={styles.invite_popover}
              destroyTooltipOnHide
              visible={popoverVisible}
              onVisibleChange={handleVisibleChange}
            >
              <Button
                type="primary"
                size="large"
                style={{ marginLeft: 'auto' }}
                icon={<PlusOutlined />}
                block={isMobile}
                disabled={loadingUserRoles}
              >
                {t('addTeamMember')}
              </Button>
            </Modal>
          </Flex>
        )}
      </Flex>
    </>
  )
}

export default Header
