import ReviewTab from '../Review'
import SelectFiling from './components/SelectFiling'

const FieldReturn = () => {
  return (
    <>
      <SelectFiling />
      <ReviewTab isFiledReturn />
    </>
  )
}

export default FieldReturn
