import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Empty } from 'antd'
import { Flex } from 'reflexbox'

import { useEntity, useEntityActions } from 'context/Entity'
import { useDashboardActions } from 'context/Dashboard'

import useUserType from 'hooks/useUserType'

import { SpinnerBackdrop } from 'components'
import Header from './components/Header'
import Router from './components/Router'

import styles from './dashboard.entity.management.module.scss'

const EntityManagement = () => {
  const { t } = useTranslation('common')
  const { entityGuid } = useParams<{ entityGuid: string }>()
  const {
    entity: { currentEntity },
  } = useEntity()
  const userType = useUserType()
  const { loadCurrentEntity, loading: loadingCurrentEntity } = useEntityActions()
  const { getEntityUserRoles, loading: loadingEntityUserRoles } = useEntityActions()
  const { loadDocumentsTags, loadDocumentsCategories } = useDashboardActions()
  const [showNavigation, setNavigation] = useState(true)

  useEffect(() => {
    loadCurrentEntity(entityGuid)
    loadDocumentsTags()
    loadDocumentsCategories()
  }, [])

  useEffect(() => {
    if (userType === 'ENTITY') {
      getEntityUserRoles(entityGuid)
    }
  }, [userType])

  return (
    <div className="container">
      {(loadingCurrentEntity || loadingEntityUserRoles) && <SpinnerBackdrop transparent={false} />}
      {!currentEntity ? (
        <Flex width={['100%']} height={['50vh']} justifyContent="center" alignItems={['center']}>
          <Empty description={t('noData')} />
        </Flex>
      ) : (
        <>
          <div className={styles.title}>{currentEntity?.title}</div>
          {showNavigation && <Header />}
          <Router currentEntity={currentEntity} toggleNavigation={setNavigation} />
        </>
      )}
    </div>
  )
}

export default EntityManagement
