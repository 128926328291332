import { PAGE_SIZE } from 'constants/table'
import { useFetch } from 'use-http'
import { toSnake } from 'convert-keys'

import qs from 'qs'
import { ActionTypes } from './action-types'
import { useCPAFirmDispatch, InviteMember } from '.'

// TODO: move to global context (AuthContext -> UserContext (auth: {}, CPA: {}, ENTITY: {}))
const useCPAFirmActions = () => {
  const dispatch = useCPAFirmDispatch()
  const { get, post, patch, response, del, loading, error, abort } = useFetch()

  const loadPtin = async (params?: TParams) => {
    const query = qs.stringify(toSnake({ ...params }), { addQueryPrefix: !!params })
    const result = await get(`/cpas/ptins${query}`)
    if (response.ok) {
      dispatch({ type: ActionTypes.LOAD_PTIN, payload: result })
    }
  }

  const clearPtin = () => dispatch({ type: ActionTypes.CLEAR_PTIN })

  const loadCpaEntities = async (firmId: string, payload?: any) => {
    const entities = await get(
      `/cpas/cpa-firms/${firmId}/entities?${qs.stringify(toSnake(payload))}`
    )
    return entities
  }

  // TODO: [REFACTORING] DUPLICATED WITH loadCPAFirmAssociatedUsers (DashboardProvider)
  const loadCpaMembers = async (firmId: string, params?: TParams) => {
    const query = qs.stringify(toSnake({ ...params }), { addQueryPrefix: !!params })
    const users = await get(`/cpas/cpa-firms/${firmId}/users${query}`)
    if (response.ok) {
      dispatch({ type: ActionTypes.LOAD_MEMBERS, payload: users })
    }
  }

  const inviteMembers = async (firmId: string, params: InviteMember[]) => {
    const result = await post(`/cpas/cpa-firms/${firmId}/invites`, params)
    return result
  }

  const loadFirmInvitations = async (cpaFirmGuid: string, params?: TParams) => {
    const query = qs.stringify(toSnake({ ...params }), { addQueryPrefix: !!params })
    const invitations = await get(`/cpas/cpa-firms/${cpaFirmGuid}/invites${query}`)
    if (response.ok) {
      dispatch({
        type: ActionTypes.LOAD_FIRM_INVITES,
        payload: { data: invitations?.results ?? [], total: invitations?.count ?? 0 },
      })
    }
  }

  const editFirmUser = async (cpaFirmGuid: string, userGuid: string, params?: any) => {
    const result = await patch(`/cpas/cpa-firms/${cpaFirmGuid}/users/${userGuid}`, toSnake(params))
    return result
  }

  const resendFirmInvitation = async (cpaFirmGuid: string, inviteGuid: string) => {
    await post(`/cpas/cpa-firms/${cpaFirmGuid}/invites/${inviteGuid}/resend`)
  }

  const revokeFirmInvitation = async (cpaFirmGuid: string, inviteGuid: string) => {
    await post(`/cpas/cpa-firms/${cpaFirmGuid}/invites/${inviteGuid}/revoke`)
  }

  const removeFirmUser = async (cpaFirmGuid: string, userGuid: string) => {
    await del(`/cpas/cpa-firms/${cpaFirmGuid}/users/${userGuid}`)
    if (response.ok) {
      await loadCpaMembers(cpaFirmGuid, { pageSize: PAGE_SIZE, page: 1 })
    }
  }

  const loadTradeShow = async (cpaFirmGuid: string) => {
    const result = await get(`/cpas/cpa-firms/${cpaFirmGuid}/trade-show-promotions`)
    if (response.ok) {
      dispatch({
        type: ActionTypes.LOAD_TRADE_SHOW,
        payload: result?.clientsInvitedCount === undefined ? null : result,
      })
    }
  }

  const loadCpaJobTitles = async () => {
    const result = await get('/cpas/job-titles')
    if (response.ok) {
      dispatch({
        type: ActionTypes.LOAD_CPA_JOB_TITLES,
        payload: result,
      })
    }
  }

  const redeemTradeShowCode = async (cpaFirmGuid: string, code: string) => {
    const result = await post(`/cpas/cpa-firms/${cpaFirmGuid}/trade-show-promotions/add-code`, {
      code,
    })
    return result
  }

  const loadUserRolesCPAFirm = async (
    cpaFirmGuid: string,
    params?: Pick<TParams, 'ordering' | 'search'>
  ) => {
    const query = qs.stringify(toSnake({ ...params }), { addQueryPrefix: !!params })
    const roles = await get(`/cpas/cpa-firms/${cpaFirmGuid}/user-roles${query}`)
    if (response.ok) {
      dispatch({ type: ActionTypes.LOAD_CPA_FIRM_USER_ROLES, payload: roles })
    }
  }

  return {
    loadPtin,
    clearPtin,
    loadCpaMembers,
    inviteMembers,
    loadCpaEntities,
    loadFirmInvitations,
    resendFirmInvitation,
    revokeFirmInvitation,
    removeFirmUser,
    editFirmUser,
    loadTradeShow,
    loadCpaJobTitles,
    redeemTradeShowCode,
    loadUserRolesCPAFirm,
    response,
    loading,
    error,
    abort,
  }
}

export default useCPAFirmActions
export { ActionTypes }
