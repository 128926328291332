import { useEffect } from 'react'
import { DatePicker, Form, Select, Typography, Radio, FormInstance } from 'antd'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import MaskedInput from 'antd-mask-input'
import countryCodes from 'country-list'

import { formatTin, getTinFormat, normalizeTin } from 'utils/entity'
import { getAddressComponentByType } from 'utils'
import { initForm, saveFormToSession } from 'utils/form'
import { browserSession } from 'utils/session'

import { getTinKinds, useEntity, useEntityActions, TEntity } from 'context/Entity'
import { useAuth } from 'context/Auth'
import { EntityEditForm } from 'context/Entity/types'

import { Button, TextField, GoogleAddressLookup } from 'components'
import { TGoogleAddressComponent } from 'components/GoogleAddressLookup/types'

import styles from 'pages/Dashboard/EntityManagement/dashboard.entity.management.module.scss'

const { Title } = Typography

const countryOptions = Object.entries(countryCodes.getCodeList()).map(([code, name]) => ({
  value: code.toUpperCase(),
  label: name,
}))

type Props = {
  entity?: TEntity
  onSubmit: (payload: EntityEditForm, form: FormInstance) => void
  action: string
  upsertLoading: boolean
}

const EntityDetailsForm = ({ entity, onSubmit, action, upsertLoading }: Props) => {
  const [form] = Form.useForm()
  const { loading, loadEntityKinds } = useEntityActions()
  const {
    ENTITY: { INDVEntitiesCount },
  } = useAuth()
  const {
    entity: { kinds },
  } = useEntity()
  const { t } = useTranslation('entity')
  const { t: t2 } = useTranslation('common')
  const { t: t3 } = useTranslation('dashboard')
  const tinKinds = getTinKinds()

  useEffect(() => {
    loadEntityKinds()
    if (!INDVEntitiesCount) {
      form.setFieldsValue({ kind: 'INDV' })
    }
  }, [])

  const cleanForm = (submittedForm: EntityEditForm) => {
    browserSession.removeItem('form:addEntity')
    onSubmit(
      {
        ...submittedForm,
        tin: normalizeTin(submittedForm?.tin),
      },
      form
    )
  }

  const formHelper = () => {
    const savedForm = initForm('form:addEntity')
    if (savedForm) {
      return {
        ...savedForm,
        businessStartedDate: null,
      }
    }
    return undefined
  }

  return (
    <Form
      form={form}
      onFinish={cleanForm}
      className={styles.form_wrapper}
      onValuesChange={(changedValues: any, allValues: any) =>
        saveFormToSession('form:addEntity', allValues)
      }
      initialValues={formHelper()}
      layout="vertical"
    >
      <Title className={styles.form_title} level={4}>
        {t2('buttons.edit')} {t('details.details')}
      </Title>
      <div className={styles.form_fields}>
        <Form.Item
          label={t('common.name')}
          rules={[{ required: true, message: `${t(`common.name`)} is required` }]}
          initialValue={entity?.title}
          name="title"
          key="title"
        >
          <TextField placeholder={t('common.name')} size="middle" />
        </Form.Item>
        <div className={styles.form_radio_group_row}>
          <span className={styles.form_radio_group_required}>*</span>
          <Form.Item
            className={styles.form_radio_group}
            rules={[{ required: true, message: `${t('common.tinType')} is required` }]}
            initialValue={entity?.tinKind}
            name="tinKind"
            key="tinKind"
          >
            <Radio.Group>
              {tinKinds.map(({ value, label }) => (
                <Radio.Button
                  onChange={(e) => {
                    if (form.getFieldValue('tin')) {
                      form.setFieldsValue({
                        tin: formatTin(normalizeTin(form.getFieldValue('tin')), e.target.value),
                      })
                    }
                  }}
                  key={value}
                  value={value}
                >
                  {label}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
        </div>
        <div className={styles.form_group}>
          <Form.Item noStyle shouldUpdate>
            {(props) => (
              <Form.Item
                label={t('common.tin')}
                rules={[
                  { required: true, message: `${t('common.tin')} is required` },
                  {
                    pattern: /^(?:\d{3}-\d{2}-\d{4}|\d{2}-\d{7}|\d{9})$/,
                    message: t2('form.validTin'),
                  },
                ]}
                initialValue={entity?.tin}
                name="tin"
                key="tin"
              >
                <MaskedInput
                  onChange={(e) => {
                    props.setFieldsValue({ tin: e.target.value })
                  }}
                  value={props.getFieldValue('tin')}
                  placeholder={t('common.tin')}
                  mask={getTinFormat(props.getFieldValue('tinKind'))}
                />
              </Form.Item>
            )}
          </Form.Item>
        </div>
        <Form.Item
          label={t('common.type')}
          rules={[{ required: true, message: `${t('common.type')} is required` }]}
          initialValue={entity?.kind?.label}
          name="kind"
          key="kind"
        >
          <Select placeholder={t('common.type')} options={kinds} />
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {(props) => (
            <Form.Item
              label={t3('firm.form.address')}
              rules={[{ required: true, message: `${t3('firm.form.address')} is required` }]}
              initialValue={entity?.address?.line1}
              name={['address', 'line1']}
              key="address-line1"
            >
              <GoogleAddressLookup
                onSelect={(addressComponents: TGoogleAddressComponent[]) => {
                  props.setFieldsValue({
                    // Geocoding reference: https://developers.google.com/maps/documentation/geocoding/requests-geocoding#Types
                    address: {
                      line1: getAddressComponentByType(addressComponents, 'streetAddress'),
                      city: getAddressComponentByType(addressComponents, 'locality'),
                      zipCode: getAddressComponentByType(addressComponents, 'postal_code'),
                      state: getAddressComponentByType(
                        addressComponents,
                        'administrative_area_level_1'
                      ),
                      country: getAddressComponentByType(addressComponents, 'country'),
                    },
                  })
                }}
                placeholder={t3('firm.form.address')}
              />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item
          label={t3('firm.form.city')}
          initialValue={entity?.address?.city}
          name={['address', 'city']}
          key="address-city"
        >
          <TextField placeholder={t3('firm.form.city')} size="middle" />
        </Form.Item>
        <Form.Item
          label={t3('firm.form.state')}
          initialValue={entity?.address?.state}
          name={['address', 'state']}
          key="address-state"
        >
          <TextField placeholder={t3('firm.form.state')} size="middle" />
        </Form.Item>
        <Form.Item
          label={t3('firm.form.country')}
          initialValue={entity?.address?.country}
          name={['address', 'country']}
          key="address-country"
        >
          <Select
            filterOption={(input, option) =>
              !!option?.label && option?.label?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
            }
            showSearch
            placeholder={t3('firm.form.country')}
            options={countryOptions}
          />
        </Form.Item>
        <Form.Item
          label={t3('firm.form.zipCode')}
          rules={[{ required: true, message: `${t3('firm.form.zipCode')} is required` }]}
          initialValue={entity?.address?.zipCode}
          name={['address', 'zipCode']}
          key="address-zipcode"
        >
          <TextField placeholder={t3('firm.form.zipCode')} size="middle" />
        </Form.Item>
        <Form.Item
          label={t('common.naicsCode')}
          initialValue={entity?.naicsCode}
          name="naicsCode"
          key="naicsCode"
          rules={[{ pattern: /^\d{6}$/, message: t2('form.validNaics') }]}
        >
          <TextField placeholder={t('common.naicsCode')} size="middle" />
        </Form.Item>
        <Form.Item
          label={t('common.startDate')}
          rules={[
            {
              required: false,
              message: `${t('common.startDate')} is required`,
            },
          ]}
          initialValue={entity?.businessStartedDate && moment(entity.businessStartedDate)}
          name="businessStartedDate"
          key="businessStartedDate"
        >
          <DatePicker
            disabledDate={(current) => current && current > moment().endOf('day')}
            className={styles.form_input}
            placeholder={t('common.startDate')}
            size="middle"
          />
        </Form.Item>
        <Form.Item
          label={t('common.totalAssets')}
          initialValue={entity?.totalAssets}
          name="totalAssets"
          key="totalAssets"
        >
          <TextField type="number" placeholder={t('common.totalAssets')} size="middle" />
        </Form.Item>
        <div className={styles.form_action_row}>
          <Button
            className={styles.form_submit}
            type="primary"
            size="large"
            htmlType="submit"
            block
            loading={loading || upsertLoading}
            disabled={loading || upsertLoading}
          >
            {action}
          </Button>
        </div>
      </div>
    </Form>
  )
}

export default EntityDetailsForm
