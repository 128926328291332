import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Flex } from 'reflexbox'
import { isEmpty, pick } from 'lodash'
import { FilterOutlined } from '@ant-design/icons'
import { Form } from 'antd'
import useFormTable from 'hooks/useFormTable'

import { useAuth } from 'context/Auth'
import { useEntityCPAs, useEntityCPAsActions } from 'context/EntityCPAs'

import { getOrderingParams } from 'utils/table'

import { Button, Modal, Table, Badge, Search } from 'components'
import FiltersModalContent from './components/FiltersModalContent'

import useColumnsActive from './hooks/useColumnsActive'
import useColumnsInactive from './hooks/useColumnsInactive'
import useFilters from './hooks/useFilters'

import { DEFAULT_PARAM_FILTERS, DEFAULT_PARAMS, FILTERS } from './constatnts'

type TProps = {
  isActive: boolean
}

const List = ({ isActive }: TProps) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'clients' })
  const { params, setParams, currentPage, setCurrentPage, handleSearch, handlePageChange } =
    useFormTable<TLoadEntitiesParams>({
      defaultParams: { ...DEFAULT_PARAMS, isActive },
    })
  const { filters, setFilters, filtersCount, activeFiltersCount } =
    useFilters<TLoadEntitiesParams>(params)
  const {
    CPA: { cpaFirm },
  } = useAuth()
  const { loadClientSummary, loadClients, loading } = useEntityCPAsActions()
  const { getEntitiesPrimaryContactsList } = useEntityCPAsActions()
  const [modalFiltersVisible, setModalFiltersVisible] = useState<boolean>(false)
  const [form] = Form.useForm()

  const {
    state: {
      entities: { data, total },
      entitiesPrimaryContacts,
    },
  } = useEntityCPAs()

  const { columns: columnsActive } = useColumnsActive({
    dataActiveOnPageCount: data?.length,
    setParams,
    setCurrentPage,
  })

  const { columns: columnsInactive } = useColumnsInactive({
    dataActiveOnPageCount: data?.length,
    setParams,
    setCurrentPage,
  })

  const entitiesPrimaryContactsOptions = useMemo(() => {
    return entitiesPrimaryContacts?.map(
      ({ firstName, lastName, guid }: IContact): TOption => ({
        label: `${firstName} ${lastName}`,
        value: guid,
      })
    )
  }, [entitiesPrimaryContacts])

  const handleVisibleChange = (isVisible: boolean) => {
    if (isVisible) {
      const newFilters = pick(params, FILTERS)
      setFilters(newFilters)
      if (isEmpty(newFilters)) {
        form.setFieldsValue(DEFAULT_PARAM_FILTERS)
      } else {
        form.setFieldsValue(newFilters)
      }
    }
    setModalFiltersVisible(isVisible)
  }

  useEffect(() => {
    if (!filtersCount) {
      setModalFiltersVisible(false)
    }
  }, [filtersCount])

  useEffect(() => {
    loadClients(cpaFirm!.guid, params)
    loadClientSummary(cpaFirm!.guid)
  }, [params])

  useEffect(() => {
    if (!entitiesPrimaryContacts.length) {
      getEntitiesPrimaryContactsList()
    }
  }, [])

  return (
    <>
      <Flex flexWrap="wrap" justifyContent={['space-between']} mb={[3]}>
        <Flex width={['100%']} my={[1, 0]} justifyContent="flex-end">
          <Box mr={[2]}>
            <Modal
              placement="leftBottom"
              content={
                <FiltersModalContent
                  form={form}
                  setParams={setParams}
                  setFilters={setFilters}
                  setModalFiltersVisible={setModalFiltersVisible}
                  disableButton={!filtersCount && !activeFiltersCount}
                  entitiesPrimaryContactsOptions={entitiesPrimaryContactsOptions}
                  setCurrentPage={setCurrentPage}
                  filters={filters}
                />
              }
              trigger="click"
              visible={modalFiltersVisible}
              onVisibleChange={handleVisibleChange}
              showCloseIcon={false}
            >
              <Badge count={activeFiltersCount} scheme="purple" size="small">
                <Button icon={<FilterOutlined />} />
              </Badge>
            </Modal>
          </Box>
          <Box width={['100%', '100%', '250px']}>
            <Search
              loading={loading}
              searchHandler={handleSearch}
              placeholder={t('head.searchClient')}
              clear
            />
          </Box>
        </Flex>
      </Flex>

      <Table
        table={{
          dataSource: data,
          columns: isActive ? columnsActive : columnsInactive,
          rowKey: 'guid',
          loading,
          tableLayout: 'auto',
          onChange: (p, f, sorter) => {
            setParams((prevParams) => ({ ...prevParams, ordering: getOrderingParams(sorter) }))
          },
        }}
        pagination={{ total, onChange: handlePageChange, current: currentPage }}
      />
    </>
  )
}

export default List
