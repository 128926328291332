import { Form } from 'antd'
import { Box } from 'reflexbox'
import { useTranslation } from 'react-i18next'

import { TextField, Textarea } from 'components'

import css from 'assets/styles/variables.module.scss'

const EmailAndMessage = ({ errors }: any) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'clients.popover' })
  const { t: t2 } = useTranslation('common', { keyPrefix: 'form' })
  return (
    <>
      <Box mb={[1, 3]}>
        <Form.Item
          name="email"
          rules={[
            { required: true, message: t2('requiredField', { field: t2('email') }) },
            { type: 'email', message: t2('invalidField', { field: t2('email') }) },
          ]}
          validateStatus={errors?.email?.[0] && 'error'}
          help={errors?.email?.[0]}
          style={{ margin: 0 }}
        >
          <TextField type="email" placeholder={t2('email')} />
        </Form.Item>
      </Box>
      <Box mb={[3]}>
        <Form.Item
          name="message"
          rules={[{ required: true, message: t2('requiredField', { field: t2('message') }) }]}
          validateStatus={errors?.message?.[0] && 'error'}
          help={errors?.message?.[0]}
          style={{ margin: 0 }}
        >
          <Textarea
            autoSize={{ minRows: 3 }}
            placeholder={t('textAreaPlaceholder')}
            style={{ borderRadius: css.borderRadiusDefault }}
          />
        </Form.Item>
      </Box>
    </>
  )
}

export default EmailAndMessage
