import { Box } from 'reflexbox'

type TProps = {
  text: string
}

const Title = ({ text }: TProps) => {
  return (
    <Box fontSize={['20px']} lineHeight={['24px']} fontWeight={[700]}>
      {text}
    </Box>
  )
}

export default Title
