import { useEffect, useMemo, useState } from 'react'
import { Button, Typography } from 'antd'
import {
  ActionTypes,
  EmailConfirmation,
  useAuth,
  useAuthActions,
  useAuthDispatch,
} from 'context/Auth'
import { useLocation, NavLink, useHistory, Redirect } from 'react-router-dom'
import qs from 'qs'
import { Alert, SpinnerBackdrop } from 'components'
import { Box } from 'reflexbox'
import { isEmailConfirmed } from 'utils'
import { DASHBOARD_PATH, LOGIN_PATH } from 'utils/routes'
import { useTranslation } from 'react-i18next'
import styles from './email.verification.module.scss'

const { Paragraph } = Typography

const EmailVerification = () => {
  const { confirmEmail, resendEmailConfirmation, loading, response } = useAuthActions()
  const { search } = useLocation()
  const [errors, setErrors] = useState<string[]>([])
  const { t } = useTranslation('common')
  const { token: authToken } = useAuth()
  const dispatch = useAuthDispatch()
  const history = useHistory()
  const { token } = qs.parse(search, { ignoreQueryPrefix: true }) as unknown as EmailConfirmation

  const onLoad = async () => {
    if (!authToken) return
    const result = await confirmEmail({ token, refreshToken: authToken.refresh })
    if (response.ok) {
      history.push(DASHBOARD_PATH)
    } else {
      setErrors(Object.values(result))
    }
  }

  const onResendEmailConfirmation = async () => {
    const result = await resendEmailConfirmation()
    if (!response.ok) {
      setErrors(Object.values(result))
    }
  }

  useEffect(() => {
    if (!authToken) {
      setErrors([t('form.loginNeededForEmailConfirmation')])
      return
    }
    if (token) onLoad()
  }, [])

  const onLogout = () => {
    dispatch({ type: ActionTypes.LOGOUT })
    history.push(`${LOGIN_PATH}${search}`)
  }

  const isConfirmed: boolean = useMemo(() => isEmailConfirmed(authToken), [authToken])

  return (
    <div className={styles.email_verification_wrapper}>
      {isConfirmed ? (
        <Redirect to={DASHBOARD_PATH} />
      ) : (
        <>
          {loading && <SpinnerBackdrop />}
          {response.ok && (
            <Box mt={[2]} mb={[4]}>
              <Alert message={t('form.resendVerificationEmailSuccess')} type="success" />
            </Box>
          )}
          <Paragraph className={styles.lineBreak}>{t('form.emailNeedsConfirmation')}</Paragraph>
          <Paragraph className={styles.lineBreak}>{t('form.checkSpamFolder')}</Paragraph>
          {errors.length
            ? errors.map((error) => <Paragraph className={styles.error}>{error}</Paragraph>)
            : null}
          {authToken ? (
            <>
              <Button
                className={styles.resend_button}
                type="primary"
                onClick={onResendEmailConfirmation}
              >
                {t('form.resendVerificationEmail')}
              </Button>
              <Button onClick={onLogout}>{t('user.dropdown.logout')}</Button>
            </>
          ) : (
            <NavLink to={`${LOGIN_PATH}${search}`}>{t('form.login')}</NavLink>
          )}
        </>
      )}
    </div>
  )
}

export default EmailVerification
