import { EnumEntityUserRoles } from 'context/Entity'
import useUserEntityPermissions from 'hooks/useUserEntityPermissions'

export default () => {
  const { userEntityRole } = useUserEntityPermissions()
  return [
    {
      label: 'users',
      value: 'users',
      show: true,
    },
    {
      label: 'pending',
      value: 'pending',
      show: userEntityRole !== EnumEntityUserRoles.MEMBER,
    },
  ]
}
