import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Flex } from 'reflexbox'
import { debounce, intersection, isEmpty, keys, pick } from 'lodash'
import { FilterOutlined, SearchOutlined } from '@ant-design/icons'
import { Form } from 'antd'

import { Modal, TextField, Table, Badge, Button } from 'components'

import { useEntity, useEntityActions } from 'context/Entity'

import { getOrderingParams } from 'utils/table'
import useColumns from './hooks/useColumns'

import { DEFAULT_PARAM_FILTERS, DEFAULT_FILTERS, DEFAULT_PARAMS, FILTERS } from './constatnts'
import { TFilters } from './types'

import FiltersModalContent from './components/FiltersModalContent'

const List = () => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'clients' })
  const [params, setParams] = useState<TLoadEntitiesParams>(DEFAULT_PARAMS)
  const [currentPage, setCurrentPage] = useState<number | undefined>()
  const { loadEntities, loading } = useEntityActions()
  const { getEntitiesPrimaryContactsList } = useEntityActions()
  const [filters, setFilters] = useState<TFilters>(DEFAULT_FILTERS)
  const [modalFiltersVisible, setModalFiltersVisible] = useState<boolean>(false)
  const [form] = Form.useForm()

  const {
    entity: {
      entities: { data, total },
      entitiesPrimaryContacts,
    },
  } = useEntity()

  const { columns } = useColumns()

  const filtersCount = useMemo(() => keys(filters).length, [filters])
  const activeFiltersCount = useMemo(() => intersection(keys(params), FILTERS).length, [params])

  const entitiesPrimaryContactsOptions = useMemo(() => {
    return entitiesPrimaryContacts?.map(
      ({ firstName, lastName, guid }: IContact): TOption => ({
        label: `${firstName} ${lastName}`,
        value: guid,
      })
    )
  }, [entitiesPrimaryContacts])

  const handleVisibleChange = (isVisible: boolean) => {
    if (isVisible) {
      const newFilters = pick(params, FILTERS)
      setFilters(newFilters)
      if (isEmpty(newFilters)) {
        form.setFieldsValue(DEFAULT_PARAM_FILTERS)
      } else {
        form.setFieldsValue(newFilters)
      }
    }
    setModalFiltersVisible(isVisible)
  }

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setParams((prevParams: TLoadEntitiesParams) => {
      setCurrentPage(DEFAULT_PARAMS.page)
      return {
        ...prevParams,
        page: DEFAULT_PARAMS.page,
        search: event.target.value ? event.target.value.trim() : undefined,
      }
    })
  }

  const handlePageChange = (page: number) => {
    setParams((prevParams: TLoadEntitiesParams) => {
      setCurrentPage(page)
      return { ...prevParams, page }
    })
  }

  const debouncedTextChangeHandler = useMemo(() => debounce(handleSearch, 300), [])

  useEffect(() => {
    return () => {
      debouncedTextChangeHandler.cancel()
    }
  }, [])

  useEffect(() => {
    loadEntities(params)
  }, [params])

  useEffect(() => {
    getEntitiesPrimaryContactsList()
  }, [])

  return (
    <>
      <Flex flexWrap="wrap" justifyContent={['space-between']} mb={[3]}>
        <Flex width={['100%']} my={[1, 0]} justifyContent="flex-end">
          <Box mr={[2]}>
            <Modal
              placement="leftBottom"
              content={
                <FiltersModalContent
                  form={form}
                  setParams={setParams}
                  setFilters={setFilters}
                  setModalFiltersVisible={setModalFiltersVisible}
                  disableButton={!filtersCount && !activeFiltersCount}
                  entitiesPrimaryContactsOptions={entitiesPrimaryContactsOptions}
                  setCurrentPage={setCurrentPage}
                  filters={filters}
                />
              }
              trigger="click"
              visible={modalFiltersVisible}
              onVisibleChange={handleVisibleChange}
              showCloseIcon={false}
            >
              <Badge count={activeFiltersCount} scheme="purple" size="small">
                <Button icon={<FilterOutlined />} />
              </Badge>
            </Modal>
          </Box>

          <Box width={['100%', '100%', '250px']}>
            <TextField
              placeholder={t('head.searchClient')}
              size="middle"
              suffix={<SearchOutlined />}
              allowClear
              onChange={debouncedTextChangeHandler}
            />
          </Box>
        </Flex>
      </Flex>

      <Table
        table={{
          dataSource: data,
          columns,
          rowKey: 'guid',
          loading,
          tableLayout: 'auto',
          onChange: (p, f, sorter) => {
            setParams((prevParams) => ({ ...prevParams, ordering: getOrderingParams(sorter) }))
          },
        }}
        pagination={{ total, onChange: handlePageChange, current: currentPage }}
      />
    </>
  )
}

export default List
