import { useAuthDispatch } from 'context/Auth'
import { useFetch } from 'use-http'
import qs from 'qs'
import { toSnake } from 'utils'
import { ActionTypes } from './action-types'
import { ActionTypes as AuthActionTypes } from '../Auth/action-types'
import { useBilling } from './context'
import { TInvoiceParams, TSubscriptionResponse } from './types'

const useBillingActions = () => {
  const { dispatch } = useBilling()
  const authDispatch = useAuthDispatch()
  const { loading, error, response, get, post } = useFetch()

  const loadConfig = async () => {
    const config = await get('/billing/config')
    if (response.ok) {
      dispatch({
        type: ActionTypes.LOAD_BILLING_CONFIG,
        payload: { publicKey: config?.publicKey },
      })
    }
  }

  // TODO: this should be cleaned up and be returned as part of /cpas/memberships
  const loadSubscription = async (cpaFirmGuid: string) => {
    const subscription = await get(`/billing/cpa-firm/${cpaFirmGuid}/profile`)
    if (response.ok) {
      dispatch({
        type: ActionTypes.LOAD_SUBSCRIPTION,
        payload: { subscription },
      })
      authDispatch({
        type: AuthActionTypes.SET_SUB_STATUS,
        payload: {
          subscriptionActive: subscription.subscription ? subscription.subscription.active : null,
        },
      })
    }
  }

  const loadInvoices = async (cpaFirmGuid: string, params?: TInvoiceParams) => {
    const query = qs.stringify(toSnake({ ...params }), { addQueryPrefix: !!params })
    const results = await get(`/billing/cpa-firm/${cpaFirmGuid}/invoices${query}`)
    if (response.ok) {
      dispatch({
        type: ActionTypes.LOAD_INVOICES,
        payload: { data: results },
      })
    }
  }

  const subscribe = async (
    cpaFirmGuid: string,
    paymentMethodId: string
  ): Promise<TSubscriptionResponse> => {
    return post(`/billing/cpa-firm/${cpaFirmGuid}/subscribe`, { paymentMethodId })
  }

  return {
    loadConfig,
    loadSubscription,
    loadInvoices,
    subscribe,
    response,
    loading,
    error,
  }
}

export default useBillingActions
export { ActionTypes, useBillingActions }
