import { useTranslation } from 'react-i18next'

import { Section } from 'components'

import ProfileForm from './components/Form'

import styles from './dashboard.firm.profile.module.scss'

type TProps = {
  isEditing: boolean
}

const FirmProfile = ({ isEditing }: TProps) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'firm' })
  return (
    <div className="container">
      <Section title={null}>
        <div className={styles.title}>{t('title')}</div>
        <div className={styles.description}>
          {isEditing ? t('description') : t('createDescription')}
        </div>
        <ProfileForm />
      </Section>
    </div>
  )
}

export default FirmProfile
