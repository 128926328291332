import { useEffect, useState } from 'react'
import { loadStripe, Stripe } from '@stripe/stripe-js'
import { TPaymentStatus, useBilling, useBillingActions } from 'context/Billing'

export default () => {
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null)
  const [paymentStatus, setPaymentStatus] = useState<TPaymentStatus>()
  const {
    state: { stripePublishableKey },
  } = useBilling()
  const { loadConfig, loading: loadingConfig } = useBillingActions()

  useEffect(() => {
    loadConfig()
  }, [])

  useEffect(() => {
    if (stripePublishableKey) {
      setStripePromise(loadStripe(stripePublishableKey))
    }
  }, [stripePublishableKey])

  return { stripePromise, paymentStatus, setPaymentStatus, loadingConfig }
}
