import { UserType } from 'context/Auth'

export const getPenInvitationsCount = (invitatons: []): number | undefined => {
  return invitatons?.filter(({ status }: { status: string }) => status === 'pen').length
}

export const getINDVEntitiesCount = (entities: []): number | undefined =>
  entities?.filter(({ kind: { label } }: { kind: { label: string } }) => label === 'INDV').length

export const getOnboardingStep = ({
  status,
  userType,
}: {
  status: TOnboardingClientStatus
  userType: UserType
}) => {
  if (userType === 'CPA') {
    switch (status) {
      case 'SETUP_CREATE':
        return 0
      case 'SETUP':
      case 'LOGO':
        return 1
      case 'BILLING':
        return 2
      case 'INVITE_USERS':
        return 3
      case 'INVITE_CLIENTS':
        return 4
      default:
        return undefined
    }
  }
  return undefined
}
