import { useState } from 'react'
import { Alert, SpinnerBackdrop } from 'components'
import { useAuth, TIviteInfoCPA } from 'context/Auth'
import { useTranslation } from 'react-i18next'

import styles from '../register.module.scss'

const HeaderFirmInvitations = () => {
  const {
    invitationsFlow: { inviteInfo },
  } = useAuth()
  const { t } = useTranslation('common', { keyPrefix: 'register' })
  const [imageLoading, setImageLoading] = useState(!!(inviteInfo as TIviteInfoCPA)?.letterhead)
  return (
    <>
      <div className={styles.logo_image}>
        {imageLoading && <SpinnerBackdrop mode="local" />}
        <img
          src={(inviteInfo as TIviteInfoCPA)?.letterhead}
          alt="Logo"
          onLoad={() => setImageLoading(false)}
        />
      </div>
      <div className="text-title">{inviteInfo?.cpaFirm.name}</div>
      <div className="my-4">
        <Alert
          message={
            <div>{t('inviteFrom', { 0: inviteInfo?.invitedBy, 1: inviteInfo?.cpaFirm.name })}</div>
          }
          banner
          type="info"
        />
      </div>
    </>
  )
}

export default HeaderFirmInvitations
