import { useEffect } from 'react'
import { Redirect, useParams, useRouteMatch } from 'react-router-dom'

import { useAuth, useAuthActions, useAuthDispatch, UserType } from 'context/Auth'
import { DASHBOARD_PATH, INVITATIONS_PATH, LOGIN_PATH, REGISTER_PATH } from 'utils/routes'
import { SpinnerBackdrop } from 'components'

const FirmInvitations = () => {
  const {
    token,
    invitationsFlow: { response, inviteInfo },
  } = useAuth()
  const { getInviteInfo } = useAuthActions()
  const dispatch = useAuthDispatch()
  const { inviteGuid } = useParams<{ inviteGuid?: string }>()
  const { path } = useRouteMatch()

  if (token) {
    return <Redirect to={INVITATIONS_PATH} />
  }

  useEffect(() => {
    getInviteInfo(UserType.CPA, inviteGuid as string)
  }, [])

  useEffect(() => {
    if (response?.ok && !inviteInfo?.accountExists) {
      dispatch({
        type: 'SET_INVITE_INFO',
        payload: {
          userType: UserType.CPA,
          forwardInvitation: true,
        },
      })
    }
  }, [response?.ok, inviteInfo?.accountExists])

  if (response?.ok === false) return <Redirect to={DASHBOARD_PATH} />

  if (response?.ok)
    return (
      <Redirect
        to={{
          pathname: inviteInfo?.accountExists ? LOGIN_PATH : REGISTER_PATH,
          state: { from: { pathname: path } },
        }}
      />
    )

  return (
    <div className="position-relative">
      <SpinnerBackdrop size="large" />
    </div>
  )
}

export default FirmInvitations
