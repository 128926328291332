import { useState, useEffect, useMemo } from 'react'

import { TFile, useEntityDocumentsActions, useEntity } from 'context/Entity'

import { TFileProgressSettings, TFileExtended } from '../types'
import { getProgress, setTotalProgressHelper } from '../helpers'

type TProps = {
  files: TFileExtended[]
  tqQuestion?: string
  onFinishUploading?: () => void
}

export default ({ files, tqQuestion, onFinishUploading }: TProps) => {
  const {
    entity: { currentEntity },
  } = useEntity()
  const [totalProgress, setTotalProgress] = useState<TFileProgressSettings[]>(
    files?.map(({ uid }: TFile) => ({
      uid,
      total: 0,
      loaded: 0,
      start: false,
    })) ?? []
  )
  const [responses, setResponses] = useState<boolean[]>([])
  const { uploadEntityDocument, response } = useEntityDocumentsActions()

  useEffect(() => {
    const startUploadDocument = async (file: TFileExtended) => {
      const { defaultSettings } = file
      const fileParams = {
        file: file.name,
        name: file.name,
        tqQuestion,
        category: defaultSettings?.subCategory || defaultSettings?.category,
        tags: defaultSettings?.tags,
      }
      await uploadEntityDocument(
        currentEntity!.guid,
        fileParams,
        {
          file: file.originFileObj,
          onUploadProgress: (event: ProgressEvent) => {
            setTotalProgress((prevData: TFileProgressSettings[]) => {
              return setTotalProgressHelper(prevData, file, event)
            })
          },
        },
        { uid: file.uid }
      )
      setResponses((prevResponses) => [...prevResponses, response.ok])
    }

    if (files?.length) {
      files.forEach((file: TFileExtended) => {
        startUploadDocument(file)
      })
    }
  }, [])

  const progress = useMemo(
    () => getProgress(totalProgress, files, onFinishUploading),
    [totalProgress]
  )
  const uploadStatus: undefined | 'error' | 'success' = !responses.length
    ? undefined
    : responses.some((error: boolean) => !error)
    ? 'error'
    : 'success'
  return { progress, totalProgress, uploadStatus }
}
