import { STATES_BY_CODE } from 'constants/address'
import {
  TTaxReturnPage,
  TTaxReturn,
  TTaxReturnCategory,
  TTaxReturnMenuCategory,
  TChildCategories,
  TDocumentPageIdentifier,
} from 'context/TaxReturns'
import { cloneDeep } from 'lodash'

export const getLatestTaxReturn = (taxReturns: TTaxReturn[]) =>
  taxReturns.find(({ isLatest }) => isLatest) ?? taxReturns?.[0]

export const getCategoryTreeStructure = (
  definedCategories: TTaxReturnCategory[],
  pages: TTaxReturnPage[]
): TTaxReturnMenuCategory[] => {
  const CATEGORIES_ORDER_TEMPLATE = ['federal', 'state', 'other']
  let uiIndex = 0

  const uniqueStates = pages.reduce((acc: string[], { state }: TTaxReturnPage) => {
    if (state && !acc.includes(state)) {
      return [...acc, state]
    }
    return acc
  }, [])

  const reoderedCategories = CATEGORIES_ORDER_TEMPLATE.reduce(
    (acc: TTaxReturnCategory[], categorySlug: string) => {
      const definedCategory = definedCategories.find(
        ({ slug }: TTaxReturnCategory) => slug === categorySlug
      )
      if (definedCategory) {
        return [...acc, definedCategory]
      }
      return acc
    },
    []
  )

  const categoriesWithStates = reoderedCategories.reduce(
    (acc: TTaxReturnCategory[], category: TTaxReturnCategory) => {
      if (category.slug !== 'state') {
        return [...acc, category]
      }
      if (category.slug === 'state' && uniqueStates.length) {
        return [
          ...acc,
          ...uniqueStates.map((state: string) => {
            return {
              label: STATES_BY_CODE[state.toUpperCase()],
              slug: state,
              childCategories: category.childCategories,
            }
          }),
        ]
      }
      return acc
    },
    []
  )

  return (
    categoriesWithStates.reduce((acc: TTaxReturnCategory[], category: TTaxReturnCategory) => {
      return [
        ...acc,
        {
          ...category,
          expanded: false,
          childCategories: category.childCategories?.reduce(
            (accChildCategory: TChildCategories[], childCategory: TChildCategories) => {
              const pagesExist = pages.reduce(
                (accPages: TDocumentPageIdentifier[], page: TTaxReturnPage) => {
                  if (
                    (page.parentCategory === category.slug || page.state === category.slug) &&
                    page.childCategory === childCategory.slug
                  ) {
                    uiIndex++
                    accPages.push({
                      documentGuid: page.taxDocumentGuid,
                      pageId: page.pageNumber,
                      uiIndex,
                      guid: page.guid,
                      thumbnail: page.thumbnail,
                    })
                  }
                  return accPages
                },
                []
              )
              if (pagesExist.length) {
                return [
                  ...accChildCategory,
                  {
                    ...childCategory,
                    expanded: false,
                    pages: pagesExist,
                  },
                ]
              }
              return accChildCategory
            },
            []
          ),
        },
      ]
    }, []) as TTaxReturnMenuCategory[]
  ).filter(
    (category: TTaxReturnMenuCategory) => category.childCategories.length
  ) as TTaxReturnMenuCategory[]
}

export const toggleMenuItem = (
  menuTreeStructure: TTaxReturnMenuCategory[],
  keyPath: string[]
): TTaxReturnMenuCategory[] => {
  const newTreeStructure = cloneDeep(menuTreeStructure)
  let currentScope = newTreeStructure
  // eslint-disable-next-line no-restricted-syntax
  for (const key of keyPath) {
    if (!(key in currentScope)) {
      return menuTreeStructure
    }
    // @ts-ignore
    currentScope = currentScope[key]
  }
  // @ts-ignore
  currentScope.expanded = !currentScope.expanded
  return newTreeStructure
}

const recursivelyCollapse = (menuTreeRow?: TTaxReturnMenuCategory[]) => {
  if (!menuTreeRow) return
  // eslint-disable-next-line no-restricted-syntax
  for (const menuItem of menuTreeRow) {
    menuItem.expanded = true
    if (menuItem.childCategories) recursivelyCollapse(menuItem.childCategories)
  }
}

export const expandAll = (menuTreeStructure?: TTaxReturnMenuCategory[]) => {
  const newTreeStructure = cloneDeep(menuTreeStructure)
  recursivelyCollapse(newTreeStructure)
  return newTreeStructure
}

export const collapseAll = (menuTreeStructure: TTaxReturnMenuCategory[]) => {
  return menuTreeStructure.map((branch: TTaxReturnMenuCategory) => {
    return {
      ...branch,
      expanded: false,
    }
  })
}

export const getBetweenSelectedIds = (
  documents: TTaxReturnPage[],
  selectedIds: string[],
  lastSelectedId: string
) => {
  let startIndex = documents.findIndex(
    (indexPage) => indexPage.guid === selectedIds[selectedIds.length - 1]
  )
  let endIndex = documents.findIndex((indexPage) => indexPage.guid === lastSelectedId)

  if (startIndex > endIndex) {
    const translateIndex = startIndex
    startIndex = endIndex - 1
    endIndex = translateIndex
  }
  const betweenIds = documents
    .slice(startIndex + 1, endIndex + 1)
    .map((slicePage) => slicePage.guid)

  return selectedIds.concat(betweenIds)
}
