import { useEffect } from 'react'
import { useAuthActions } from 'context/Auth'
import { browserStorage } from 'utils/session'

const WindowFocusHandler = () => {
  const { verifyToken } = useAuthActions()

  // User has switched back to the tab
  const onFocus = () => {
    const currentToken = browserStorage.getItem('token')
    verifyToken(currentToken)
  }

  useEffect(() => {
    window.addEventListener('focus', onFocus)
    // Calls onFocus when the window first loads
    onFocus()
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener('focus', onFocus)
    }
  }, [])

  return <></>
}

export default WindowFocusHandler
