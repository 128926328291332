import { useEffect, useState } from 'react'
import { useMessagesActions, useMessages } from 'context/Messages'
import { TLoadMessageThreadsParams } from '../types'

const DEFAULT_PAGE = 1

export default (sidebarCollapsed: boolean) => {
  const { loadMessageThreads: loadThreads, loading } = useMessagesActions()
  const { threads: chunk, count } = useMessages()
  const [params, setParams] = useState<TLoadMessageThreadsParams>({ page: DEFAULT_PAGE })

  useEffect(() => {
    if (sidebarCollapsed) {
      setParams({ page: DEFAULT_PAGE })
    }
  }, [sidebarCollapsed])

  useEffect(() => {
    if (!sidebarCollapsed) {
      loadThreads(params)
    }
  }, [params, sidebarCollapsed])

  return { loading, loadThreads, chunk, count, params, setParams }
}
