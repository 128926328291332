import { FC, useEffect } from 'react'
import { useAuth, useAuthActions } from 'context/Auth'
import SpinnerBackdrop from 'components/SpinnerBackdrop'

const EntityController: FC = ({ children }) => {
  const {
    onboardingFlow: { status: flowStatus, responseOk },
  } = useAuth()
  const { loadEntityUserInfo, setOnboardingFlowStatus } = useAuthActions()

  useEffect(() => {
    if (!flowStatus) {
      setOnboardingFlowStatus('START')
      loadEntityUserInfo()
    }
  }, [flowStatus])

  useEffect(() => {
    if (responseOk && flowStatus !== 'END') {
      setOnboardingFlowStatus('END')
    }
  }, [responseOk])

  return flowStatus === 'END' ? (
    <>{children}</>
  ) : (
    <SpinnerBackdrop transparent={false} mode="global" size="large" />
  )
}

export default EntityController
