import { TTaxreturnPhase } from 'context/TaxReturns'

export const CONTROLLED_TABS: Readonly<TTaxreturnPhase[]> = [
  'CATEGORIZE',
  'REVIEW',
  'AUTHORIZE',
  'FILING',
  'CHANGES_REQUESTED',
  'RETURN_FILED',
]
