export enum ThreadContextType {
  CONTEXT = 'context',
  TAX_RETURN_CONTEXT = 'taxReturnContext',
}

export type DocumentThread = {
  guid: string
  createdBy: IContact
  title: string
  isResolved: boolean
  lastReplyOn: string
  unreadMessageCount: string
  context: {
    guid: string
    taxDocument: string
    pageNumber: number
    pdfCoordinates: {
      x: number
      y: number
    }
  }
  taxReturnContext: {
    guid: string
    taxDocument: string
    pageNumber: number
    taxReturnPage: {
      guid: string
    }
    pdfCoordinates: {
      x: number
      y: number
    }
  }
  summary: string
  referenceNumber: number
}

export type ThreadMessage = {
  createdBy: IContact
  createdOn: string
  guid: string
  message: string
  read: boolean
}

export type TThreadsParams = {
  taxOrganizerGuid?: string
  taxReturnId?: string
  documentGuid?: string
  page?: number
  ordering?: string
  search?: string
}

export type NewThread = {
  x: number
  y: number
  page: number
}
