import markerSDK, { MarkerSdk } from '@marker.io/browser'
import { FC, Dispatch, createContext, useContext, useEffect, useReducer } from 'react'

import { useReducerLogger } from 'utils'
import { ActionTypes } from './action-types'
import { initialState, MarkerReducer, IMarkerContext, IAction } from './reducer'

const MarkerContext = createContext<IMarkerContext>({} as IMarkerContext)
const MarkerDispatchContext = createContext<Dispatch<IAction>>(() => null)

export function useMarker() {
  const context = useContext(MarkerContext)
  if (context === undefined) {
    throw new Error('useMarker must be used within a MarkerProvider')
  }
  return context
}

export function useMarkerDispatch() {
  const context = useContext(MarkerDispatchContext)
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within a AuthProvider')
  }
  return context
}

export const MarkerProvider: FC = ({ children }) => {
  const [data, dispatch] = useReducer(
    useReducerLogger<IMarkerContext, IAction>(MarkerReducer),
    initialState
  )

  useEffect(() => {
    markerSDK.loadWidget({ project: '63d8bc0f470212bff024a4bb' }).then((widget: MarkerSdk) => {
      dispatch({
        type: ActionTypes.WIDGET_LOADED,
        payload: widget,
      })
    })
  }, [])

  return (
    <MarkerContext.Provider value={data}>
      <MarkerDispatchContext.Provider value={dispatch}>{children}</MarkerDispatchContext.Provider>
    </MarkerContext.Provider>
  )
}
