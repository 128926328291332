import { useEffect, useState, useMemo } from 'react'
import { Empty } from 'antd'
import { Button, Modal, SpinnerBackdrop } from 'components'
import { Box, Flex } from 'reflexbox'
import { useTranslation } from 'react-i18next'
import { round } from 'lodash'
import { useMediaQuery } from 'react-responsive'
import { getPhaseColorByName } from 'utils'

import { PhaseName } from 'context/TaxOrganizer'

import {
  TTaxOrganizerPhaseSummary,
  TTaxOrganizersParams,
  TTaxSummaryParams,
  useDashboard,
} from 'context/Dashboard'
import { DEFAULT_TAX_PARAMS, PAGE_SIZE } from 'pages/Dashboard/Dashboard/constants'

import { fillTemplate } from 'pages/Dashboard/Dashboard/helpers'
import styles from '../../../../dashboard.module.scss'

const OPACITY_RATE = 0.5
const SHARE_EDGE = 10

const percentage = (n: number, _total: number) => (_total === 0 ? 0 : (100 * n) / _total)
const setOpacity = (phase: PhaseName | 'all', activeTab: string) =>
  !activeTab || activeTab === 'all' ? 1 : activeTab === phase ? 1 : OPACITY_RATE

type TProps = {
  summaryParams: TTaxSummaryParams
  setTaxParams: (
    value: TTaxOrganizersParams | ((prevState: TTaxOrganizersParams) => TTaxOrganizersParams)
  ) => void
  setShowTable: (state: boolean) => void
  loading: boolean
}

const StatusBar = ({ summaryParams, setTaxParams, setShowTable, loading }: TProps) => {
  const [activeTab, setActiveTab] = useState('')
  const { t } = useTranslation('dashboard', { keyPrefix: 'info' })
  const {
    summary: { phases, total },
  } = useDashboard()
  const { summary } = useDashboard()
  const { t: t2 } = useTranslation('common')
  const isMobile = useMediaQuery({ query: '(max-width: 40em)' })
  const extendedCount = useMemo(
    () =>
      summary.phases.find(({ phase }: { phase: PhaseName }) => phase === 'EXTENDED')?.count ?? 0,
    [summary]
  )
  const phasesWithoutExtended = useMemo(() => fillTemplate(phases), [phases])

  useEffect(() => {
    setActiveTab('')
  }, [summaryParams])

  const defaultView = () => {
    setActiveTab('')
    setShowTable(false)
    setTaxParams((prevParams: TTaxSummaryParams) => {
      return { ...prevParams, page: DEFAULT_TAX_PARAMS.page, pageSize: PAGE_SIZE }
    })
  }

  const handleClick = (phase: PhaseName) => {
    setShowTable(true)
    if (phase !== activeTab) {
      setTaxParams((prevParams: TTaxSummaryParams) => ({
        ...prevParams,
        phase,
        page: DEFAULT_TAX_PARAMS.page,
        pageSize: DEFAULT_TAX_PARAMS.pageSize,
      }))
      setActiveTab(phase)
    } else {
      defaultView()
    }
  }

  const handleClickAll = () => {
    setShowTable(true)
    if (activeTab !== 'all') {
      setActiveTab('all')
      setTaxParams((prevParams: TTaxSummaryParams) => {
        return {
          ...prevParams,
          phase: undefined,
          page: DEFAULT_TAX_PARAMS.page,
          pageSize: DEFAULT_TAX_PARAMS.pageSize,
        }
      })
    } else {
      defaultView()
    }
  }

  return (
    <>
      {loading && <SpinnerBackdrop />}
      {!total ? (
        <Empty description={t2('noData')} />
      ) : (
        <Flex
          justifyContent={['flex-start']}
          alignItems={['flex-start']}
          flexDirection={['row', 'column']}
        >
          <Flex
            justifyContent={['space-between']}
            width={['100%']}
            flexDirection={['column', 'row']}
            alignItems={['start', 'center']}
            pl={[2, 0]}
          >
            <Button
              key="tabs-all"
              type="text"
              style={{
                opacity: setOpacity('all', activeTab),
              }}
              className={styles.all}
              onClick={() => {
                handleClickAll()
              }}
            >
              <Flex fontSize={['12px']}>{total}</Flex>
              <Box fontWeight={[600]} fontSize={['16px']}>
                {t('all')}
              </Box>
            </Button>
            {phasesWithoutExtended?.map(({ phase, count }: TTaxOrganizerPhaseSummary) => {
              return (
                <Button
                  key={`tabs-${phase}`}
                  type="text"
                  style={{
                    opacity: setOpacity(phase, activeTab),
                  }}
                  className={styles.info}
                  onClick={() => handleClick(phase)}
                >
                  <Box color={getPhaseColorByName(phase)} mr={[2]}>
                    &#9679;
                  </Box>
                  <Flex
                    flexDirection={['row-reverse', 'column']}
                    width={['100%', 'auto']}
                    justifyContent={['space-between']}
                    alignItems={['center', 'start']}
                  >
                    <Flex fontSize={['12px']}>{count}</Flex>
                    <Box fontWeight={[600]} fontSize={['16px']}>
                      {t2(`phases.${phase}`)}
                    </Box>
                  </Flex>
                </Button>
              )
            })}
          </Flex>
          <Flex
            height={[isMobile ? 'auto' : '54px']}
            width={['100%']}
            pr={[1, 0]}
            pl={[4, 0]}
            mt={['12px']}
            flexDirection={['column', 'row']}
            alignSelf={['stretch']}
          >
            {phasesWithoutExtended?.map(({ phase, count }: TTaxOrganizerPhaseSummary) => {
              const share = percentage(count, total - extendedCount)
              return (
                <Flex
                  key={`tabs-content-${phase}`}
                  width={[!isMobile ? `${share}%` : 'auto']}
                  height={[isMobile ? `${share}%` : 'auto']}
                  style={{
                    backgroundColor: getPhaseColorByName(phase),
                    opacity: setOpacity(phase, activeTab),
                  }}
                  className={styles.info}
                  justifyContent={['center']}
                  alignItems={['center']}
                  onClick={() => {
                    handleClick(phase)
                  }}
                >
                  {share > SHARE_EDGE && (
                    <Box
                      fontWeight={['500']}
                      fontSize={[isMobile ? '12px' : '16px']}
                      style={{ whiteSpace: 'nowrap' }}
                      color="white"
                    >
                      {count} ({Math.round(share) === 0 ? 1 : Math.round(share)}
                      %)
                    </Box>
                  )}
                  {share > 0 && share <= SHARE_EDGE && (
                    <Modal
                      placement="bottom"
                      content={`${count} (${share < 1 ? round(share, 1) : Math.round(share)}%)`}
                      visible={activeTab === phase}
                      overlayInnerStyle={{ backgroundColor: 'white', color: 'black' }}
                    />
                  )}
                </Flex>
              )
            })}
          </Flex>
        </Flex>
      )}
    </>
  )
}

export default StatusBar
