import { useState } from 'react'
import { Form } from 'antd'
import { Box } from 'reflexbox'
import { Button, TextField } from 'components'
import Alert, { TAlert } from 'components/Alert'

import { useTranslation } from 'react-i18next'
import { ResetPasswordRequest, useAuthActions } from 'context/Auth'

import { LOGIN_PATH } from 'utils/routes'
import styles from './reset.password.module.scss'

const defaultAlertSettings: TAlert = {
  status: false,
  message: '',
  type: 'success',
}

const ResetPassword = () => {
  const { t } = useTranslation('common', { keyPrefix: 'form' })
  const [form] = Form.useForm()
  const { resetPassword, loading, response } = useAuthActions()
  const [alert, setAlert] = useState<TAlert>(defaultAlertSettings)

  const onSubmit = async (values: ResetPasswordRequest) => {
    setAlert(defaultAlertSettings)

    const result = await resetPassword({
      email: values.email,
    })

    if (response.ok) {
      setAlert({
        status: true,
        message: t('resetPasswordRequestSuccess'),
        type: 'success',
      })
    } else {
      const errors = Object.entries(result).map(([key, value]) => {
        return {
          name: key,
          errors: value as string[],
        }
      })
      form.setFields(errors)
    }
  }

  return (
    <>
      <Form form={form} className={styles.form} onFinish={onSubmit}>
        {alert.status && (
          <Box mt={[2]} mb={[4]}>
            <Alert message={alert.message} type={alert.type} banner sameColor />
          </Box>
        )}

        <Box textAlign={['center']} my={[4]} fontSize={['24px']} fontWeight={[700]}>
          {t('resetPasswordT')}
        </Box>

        <Form.Item
          name="email"
          hasFeedback
          rules={[
            { required: true, message: t('requiredField', { field: t('email') }) },
            { type: 'email', message: t('invalidField', { field: t('email') }) },
          ]}
        >
          <TextField placeholder={t('enterEmail')} size="large" className={styles.input} />
        </Form.Item>
        <Button htmlType="submit" type="primary" size="large" block loading={loading}>
          {t('resetPassword')}
        </Button>
        <Box mt={[3]}>
          {' '}
          <Form.Item>
            <Button href={LOGIN_PATH} type="link" block>
              {t('returnToLogin')}
            </Button>
          </Form.Item>
        </Box>
      </Form>
    </>
  )
}

export default ResetPassword
