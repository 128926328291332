import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth, useAuthActions } from 'context/Auth'
import { useTranslation } from 'react-i18next'
import { Box, Flex } from 'reflexbox'
import { getCPAOnboardingPath } from 'utils/routes'

import { Button, SpinnerBackdrop, Upload } from 'components'
import Title from '../components/Title'

import styles from './onboard.logo.module.scss'

const Logo = () => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'firm' })
  const {
    CPA: { cpaFirm },
  } = useAuth()
  const { setOnboardingStep } = useAuthActions()
  const { updateCpaFirmLogo, response: updateLogoResponse } = useAuthActions()
  const { updateCpaFirm, response, loading: updateLoading } = useAuthActions()
  const history = useHistory()
  const image = cpaFirm?.letterheadImage
  const [imageLoading, setImageLoading] = useState(!!image)

  const handleChange = async ({ file }: { file: File }) => {
    setImageLoading(true)
    await updateCpaFirmLogo(cpaFirm!.guid, file)
    if (!updateLogoResponse.ok) {
      setImageLoading(false)
    }
  }

  const handleNext = async () => {
    await updateCpaFirm(cpaFirm!.guid, { onboardingStatus: 'BILLING' })
    if (response.ok) {
      setOnboardingStep('BILLING')
      history.replace(getCPAOnboardingPath('BILLING'))
    }
  }

  useEffect(() => {
    setImageLoading(!!image)
  }, [image])

  return (
    <>
      <Box mb={[3]}>
        <Title title={t('onboarding.logo.title')} subtitles={[t('onboarding.logo.subtitle')]} />
        <Box
          width={['100%', '520px']}
          height={['185px']}
          justifyContent={['center']}
          my={[3, 5]}
          ml="auto"
          mr="auto"
          className="position-relative"
        >
          {imageLoading && <SpinnerBackdrop mode="local" transparent={false} />}
          {image ? (
            <img
              className={styles.image}
              src={image}
              alt="Logo"
              onLoad={() => setImageLoading(false)}
            />
          ) : (
            <Upload
              fileList={[]}
              accept="image/png,image/jpeg"
              multiple={false}
              hint={t('logo.hint')}
              onChange={handleChange}
            />
          )}
        </Box>

        <Flex
          width={['100%']}
          justifyContent={[image ? 'space-between' : 'flex-end']}
          flexDirection={['column', 'row']}
        >
          {image && (
            <Box width={['100%', '190px']}>
              <Upload
                fileList={[]}
                accept="image/png,image/jpeg"
                multiple={false}
                hint={t('logo.hint')}
                onChange={handleChange}
                className={styles.uploadbtn}
              >
                <Button
                  type="ghost"
                  size="large"
                  block
                  // loading={imageLoading}
                  disabled={imageLoading}
                >
                  {t('onboarding.button.change')}
                </Button>
              </Upload>
            </Box>
          )}
          <Box width={['100%', '190px']} my={[2, 0]}>
            <Button
              type="primary"
              size="large"
              block
              onClick={handleNext}
              loading={updateLoading}
              disabled={updateLoading}
            >
              {image ? t('onboarding.button.continue') : t('onboarding.button.skip')}
            </Button>
          </Box>
        </Flex>
      </Box>
    </>
  )
}

export default Logo
