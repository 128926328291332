import { UserType } from 'context/Auth'
import { TaxOrganizer } from 'context/TaxOrganizer'
import { EngagementLetterSigningInformation } from '../context/TaxOrganizer/reducers/tax-organizer'

export const isEngagementLetterSigned = (
  letterSigningInformation: EngagementLetterSigningInformation | undefined,
  userType: UserType | undefined
): boolean => {
  if (userType === 'ENTITY') return !!letterSigningInformation?.signedByClientOn
  return !!letterSigningInformation?.signedByPreparerOn
}

export const canSignEngagementLetter = (taxOrganizer: TaxOrganizer): boolean =>
  !!taxOrganizer.cpaFirm
