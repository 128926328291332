import { useEffect, useState } from 'react'
import cn from 'classnames'
import { Modal } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import styles from './dashboard.document.modal.module.scss'
import Button from '../../../../../../components/Button'
import ModalStyles from '../../../../../../components/UploadModal/uploadmodal.module.scss'
import Checkbox from '../../../../../../components/Checkbox'

type Props = {
  onHide: () => void
}

const steps = ['step1', 'step2', 'step3']

const DocumentModal = ({ onHide }: Props) => {
  const [step, setSTep] = useState<number>(1)
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'tax.approveModal' })

  useEffect(() => {}, [])

  const indicator = (index: number) => {
    return <div className={cn(styles.modal_indicator, index === step ? styles.current : '')} />
  }

  const nextstep = () => {
    if (step !== steps.length - 1) {
      setSTep((prev) => prev + 1)
    }
  }

  return (
    <Modal
      visible
      footer={null}
      title=""
      className={ModalStyles.modal}
      width={800}
      destroyOnClose
      closeIcon={<CloseOutlined />}
      onCancel={onHide}
    >
      <div className={styles.modal_body}>
        <span className={styles.modal_body_text}>{t(steps[step - 1])}</span>
        <div className={styles.modal_indicators}>
          {indicator(1)}
          {indicator(2)}
          {indicator(3)}
        </div>
        {step === 3 && <Checkbox responsive>{t('skip')}</Checkbox>}
        <div className={styles.modal_footer}>
          <Button type="primary" size="middle" onClick={onHide}>
            {t('no')}
          </Button>

          {step < steps.length - 1 && (
            <Button type="primary" size="middle" onClick={nextstep}>
              {t('yes')}
            </Button>
          )}
          {step === steps.length - 1 && (
            <a href="https://eval.signnow.com/s/q23b2REn?blank_redirect=https%3A%2F%2Fdemo.repositax.com%2Ftax-organizer%2F3953885093938463">
              <Button type="primary" size="middle" onClick={nextstep}>
                {t('sign')}
              </Button>
            </a>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default DocumentModal
