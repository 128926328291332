import { FC, Dispatch, createContext, useReducer, useContext } from 'react'
import { useReducerLogger } from 'utils'

import { IAction } from '.'
import { initialState, mainReducer, ITaxOrganizerContext } from './reducers'

const TaxOrganizerContext = createContext<ITaxOrganizerContext>({} as ITaxOrganizerContext)
const TaxOrganizerDispatchContext = createContext<Dispatch<IAction>>(() => null)

export function useTaxOrganizer() {
  const context = useContext(TaxOrganizerContext)
  if (context === undefined) {
    throw new Error('useTaxOrganizer must be used within a TaxOrganizerProvider')
  }
  return context
}

export function useTaxOrganizerDispatch() {
  const context = useContext(TaxOrganizerDispatchContext)
  if (context === undefined) {
    throw new Error('useTaxOrganizerDispatch must be used within a TaxOrganizerProvider')
  }
  return context
}

export const TaxOrganizerProvider: FC = ({ children }) => {
  const [data, dispatch] = useReducer(useReducerLogger(mainReducer), initialState)

  return (
    <TaxOrganizerContext.Provider value={data}>
      <TaxOrganizerDispatchContext.Provider value={dispatch}>
        {children}
      </TaxOrganizerDispatchContext.Provider>
    </TaxOrganizerContext.Provider>
  )
}
