import { useTranslation } from 'react-i18next'
import { Flex } from 'reflexbox'
import { Button } from 'components'

type TProps = {
  acceptAction: () => void
  declineAction: () => void
}

const ActionsCell = ({ acceptAction, declineAction }: TProps) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'clients.table' })

  return (
    <>
      <Flex justifyContent={['space-between']} alignItems={['center']} flexWrap="nowrap">
        <Flex>
          <Button onClick={() => acceptAction()} type="link">
            {t('accept')}
          </Button>
          <Button onClick={() => declineAction()} type="link">
            {t('reject')}
          </Button>
        </Flex>
      </Flex>
    </>
  )
}

export default ActionsCell
