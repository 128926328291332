import { useMemo, useState } from 'react'
import { Typography, Form, Modal } from 'antd'
import { Flex, Box } from 'reflexbox'
import { useTranslation } from 'react-i18next'

import useAlert from 'hooks/useAlert'
import { TClientInviteStatus, useEntityCPAsActions } from 'context/EntityCPAs'
import { Alert, Button, Textarea, TextField } from 'components'

import { TInviteClient } from '../types'
import ClientInvitationEntity from '../ClientInvitationEntity'

const { Title } = Typography

type TProps = {
  invite: IClientInvite
  inviteStatus: TClientInviteStatus
  closeForm: () => void
}

const EditInvitationModal = ({ invite, closeForm, inviteStatus }: TProps) => {
  const [form] = Form.useForm()
  const { t } = useTranslation('dashboard', { keyPrefix: 'clients.popover' })
  const { t: t2 } = useTranslation('common', { keyPrefix: 'form' })
  const { t: t3 } = useTranslation('dashboard', { keyPrefix: 'clients.table' })
  const [errors, setErrors] = useState<Partial<TInviteClient>>({})
  const [deletedDocIds, setDeletedDocIds] = useState<string[]>([])
  const { editClientInvitation, deleteEntityDocument, response, loading } = useEntityCPAsActions()
  const { alert, setAlert, resetAlert } = useAlert({ status: false })

  const isPendingInvitation = useMemo(() => inviteStatus === 'pen', [inviteStatus])
  const cpaFirmGuid = invite.cpaFirm.guid

  const onSubmit = async ({ entities = [], ...rest }: any) => {
    resetAlert()

    const params = isPendingInvitation ? { entities } : { entities, ...rest }
    const result = await editClientInvitation(cpaFirmGuid, invite.guid, params, inviteStatus)

    if (response.ok) {
      // Send documents deletion request after Save is successful.
      const deletePromises = deletedDocIds.map((id) => deleteEntityDocument(cpaFirmGuid, id))
      await Promise.all(deletePromises)

      // Reset form and inform user of save success.
      form.resetFields()
      setErrors({})
      setAlert({ status: true, message: t3('invitationSave.success'), type: 'success' })
      closeForm()
    } else {
      setErrors(result)
    }
  }

  return (
    <Modal
      visible={!!invite.guid}
      footer={null}
      closable={false}
      onCancel={() => {
        form.resetFields()
        setErrors({})
        resetAlert()
        closeForm()
      }}
    >
      <Form
        onFinish={onSubmit}
        form={form}
        autoComplete="off"
        initialValues={{
          entities: invite.entities,
          email: invite.email,
          message: invite.message,
        }}
        style={{ width: '100%' }}
      >
        <Flex
          pt={2}
          px={[1, 2]}
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="inherit"
        >
          <Box>
            <Title level={4}>{t(isPendingInvitation ? 'editPenInvited' : 'editUnInvited')}</Title>
          </Box>
          <Box mt={[2, 3]}>
            <Box>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: t2('requiredField', { field: t2('email') }) },
                  { type: 'email', message: t2('invalidField', { field: t2('email') }) },
                ]}
                validateStatus={errors?.email?.[0] && 'error'}
                help={errors?.email?.[0]}
              >
                <TextField type="email" placeholder={t2('email')} disabled={isPendingInvitation} />
              </Form.Item>
            </Box>
            <Box mt={[0, 2]}>
              <Form.Item
                name="message"
                rules={[{ required: true, message: t2('requiredField', { field: t2('message') }) }]}
                validateStatus={errors?.message?.[0] && 'error'}
                help={errors?.message?.[0]}
              >
                <Textarea
                  autoSize={{ minRows: 3 }}
                  style={{ borderRadius: '18px' }}
                  placeholder={t('textAreaPlaceholder')}
                  disabled={isPendingInvitation}
                />
              </Form.Item>
            </Box>
          </Box>
          <Box mt={[0, 3]}>
            <ClientInvitationEntity
              title={t('title2')}
              fieldName="entities"
              errors={errors}
              initialEntities={invite.entities}
              deletedDocIds={deletedDocIds}
              setDeletedDocIds={setDeletedDocIds}
            />
          </Box>
          {alert.status && <Alert message={alert.message} type={alert.type} />}
          <Flex my={[2]} alignSelf={['stretch', 'flex-end']} width={['100%', '38%']}>
            <Button type="primary" size="large" htmlType="submit" block disabled={loading}>
              {t('save')}
            </Button>
          </Flex>
        </Flex>
      </Form>
    </Modal>
  )
}

export default EditInvitationModal
