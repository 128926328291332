import { NavLink, useParams } from 'react-router-dom'
import { Box, Flex } from 'reflexbox'
import { useTranslation } from 'react-i18next'
import { getEntityPathById } from 'utils/routes'

import styles from '../../dashboard.entity.management.module.scss'

const Header = () => {
  const { t } = useTranslation('entity', { keyPrefix: 'navigation' })
  const { entityGuid } = useParams<{ entityGuid: string }>()

  return (
    <Flex flexWrap="wrap" justifyContent="space-between" mb={[3]}>
      <Flex className={styles.nav} width={['100%', '70%', '60%']} flexWrap={['wrap']}>
        <Box mr={[3]}>
          <NavLink
            to={getEntityPathById(entityGuid)}
            exact
            className={styles.nav}
            activeClassName={styles.nav_is_active}
          >
            {t('overview')}
          </NavLink>
        </Box>
        <Box mr={[3]}>
          <NavLink
            to={`${getEntityPathById(entityGuid)}/documents`}
            className={styles.nav}
            activeClassName={styles.nav_is_active}
            exact
          >
            {t('documents')}
          </NavLink>
        </Box>
        <Box mr={[3]}>
          <NavLink
            to={`${getEntityPathById(entityGuid)}/taxes`}
            className={styles.nav}
            activeClassName={styles.nav_is_active}
            exact
          >
            {t('taxOrganizers')}
          </NavLink>
        </Box>
        <Box mr={[3]}>
          <NavLink
            to={`${getEntityPathById(entityGuid)}/users`}
            className={styles.nav}
            activeClassName={styles.nav_is_active}
            exact
          >
            {t('users')}
          </NavLink>
        </Box>
      </Flex>
    </Flex>
  )
}

export default Header
