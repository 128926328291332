import { Button, Table } from 'components'
import { Typography } from 'antd'
import { TEntity } from 'context/Entity'
import { useTranslation } from 'react-i18next'
import Icon from '@ant-design/icons'
import { useState } from 'react'
import { ReactComponent as Plus } from 'assets/images/plus-white.svg'
import cn from 'classnames'

import useColumns from './hooks/useColumns'

import InviteEntityUserForm from './InviteEntityUserForm'
import EntityUserInvitesList from './EntityUserInvitesList'

import styles from '../../../dashboard.entity.management.module.scss'
import useTabs from './hooks/useTabs'

const { Title } = Typography

type Props = {
  entity: TEntity
  users: Array<ICpaContact>
}

const FullUserList = ({ entity, users }: Props) => {
  const [formVisible, setFormVisible] = useState(false)
  const tabs = useTabs()
  const [currentTab, setCurrentTab] = useState(tabs[0].value)
  const { columns } = useColumns()
  const { t } = useTranslation('entity')
  const { t: t2 } = useTranslation('common')

  return (
    <div className={styles.users_wrapper}>
      <div className={cn(styles.section_header, styles.section_header_left)}>
        {tabs.map(
          (tab) =>
            tab.show && (
              <Title
                key={tab.value}
                level={4}
                onClick={() => setCurrentTab(tab.value)}
                className={cn(styles.section_title, styles.section_title_tab, {
                  [styles.section_title_inactive]: currentTab !== tab.value,
                })}
              >
                {t(`users.${tab.label}`)}
              </Title>
            )
        )}
      </div>
      {currentTab === 'users' && (
        <>
          <Table
            table={{
              dataSource: users,
              columns,
              rowKey: (record: ICpaContact) => record.user.guid,
              size: 'small',
            }}
            pagination={{ total: users.length, onChange: () => {} }}
          />
          {!formVisible ? (
            <Button
              className={styles.users_btn}
              onClick={() => setFormVisible(true)}
              type="primary"
              size="large"
              htmlType="submit"
              disabled={formVisible}
            >
              <Icon component={Plus} style={{ fontSize: 18 }} /> {t2('buttons.add')}
            </Button>
          ) : (
            <InviteEntityUserForm entity={entity} widgetStyle />
          )}
        </>
      )}
      {currentTab === 'pending' && <EntityUserInvitesList entity={entity} />}
    </div>
  )
}

export default FullUserList
