import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Flex } from 'reflexbox'
import { ColumnsType } from 'antd/lib/table'
import { Dropdown, Menu } from 'antd'
import { EllipsisOutlined, UserSwitchOutlined, TagOutlined, StopOutlined } from '@ant-design/icons'

import useDate from 'hooks/useDate'

import { TCPAFirmAssociatedUser, TJobTitle } from 'context/Dashboard'
import { useCPAFirmActions } from 'context/CPAFirm'
import { useAuth } from 'context/Auth'
import { TCPAEntityContact } from 'context/TaxOrganizer'

import { UserIcon, Modal } from 'components'
import styles from '../../dashboard.firm.module.scss'
import ChangeRoleForm from '../../../../EntityCPAs/List/components/ChangeRoleForm'
import JobTitleForm from '../../components/JobTitleForm'

export default () => {
  const { getDate } = useDate()
  const { removeFirmUser } = useCPAFirmActions()
  const {
    CPA: { userRole, cpaFirm },
  } = useAuth()
  const { t } = useTranslation('dashboard', { keyPrefix: 'firm.team' })
  const { t: t2 } = useTranslation('common')
  const [dropdownVisible, setDropdownVisible] = useState<string>('')
  const [rolePopOverVisible, setRolePopOverVisible] = useState<string>('')
  const [jobTitlePopOverVisible, setJobTitlePopOverVisible] = useState<string>('')

  const closePopOvers = () => {
    setRolePopOverVisible('')
    setJobTitlePopOverVisible('')
  }
  const closeDropdown = () => {
    setDropdownVisible('')
    closePopOvers()
  }

  const excludeColumns = (columns: ColumnsType<TCPAFirmAssociatedUser>, columnKey: string) => {
    return columns.filter((column) => !(userRole === 'MEMBER' && column.key === columnKey))
  }

  const columns: ColumnsType<TCPAFirmAssociatedUser> = [
    {
      title: <Box fontWeight="bold">{t('name')}</Box>,
      dataIndex: 'user',
      key: 'user',
      ellipsis: true,
      render: (user: IContact) => (
        <Flex flexWrap={['nowrap']}>
          <UserIcon user={user} avatarProps={{ size: 'small' }} />
          <Box className="text-bold text-nowrap" ml={['11px']}>
            {user.firstName} {user.lastName}
          </Box>
        </Flex>
      ),
    },
    {
      title: t('accessLevel'),
      dataIndex: 'role',
      key: 'role',
      render: (role: string) => (
        <span className="text-semibold">{t2(`user.roles.${role}`, role)}</span>
      ),
    },
    {
      title: t('jobTitle'),
      dataIndex: 'jobTitle',
      key: 'jobTitle',
      render: (jobTitle: TJobTitle) => (
        <span className="text-semibold">{jobTitle?.label ?? t('noJobTitle')}</span>
      ),
    },
    {
      title: t('clients'),
      dataIndex: 'clients',
      key: 'clients',
    },
    {
      title: t('signed'),
      dataIndex: 'lastSignedIn',
      key: 'lastSignedIn',
      render: (lastSignedIn: string) => (
        <div className="text-nowrap">{getDate(lastSignedIn, 'yyyy-MM-dd')}</div>
      ),
    },
    {
      title: t('actions'),
      key: 'actions',
      render: (taxPreparer: TCPAEntityContact) => (
        <Dropdown
          placement="bottomRight"
          trigger={['click']}
          visible={dropdownVisible === taxPreparer.user.guid}
          onVisibleChange={(value: boolean) => {
            if (value) {
              setDropdownVisible(taxPreparer.user.guid)
            } else {
              closeDropdown()
            }
          }}
          overlay={
            <Menu theme="dark">
              <Modal
                placement="left"
                trigger="click"
                destroyTooltipOnHide
                content={<ChangeRoleForm onSubmit={closeDropdown} taxPreparer={taxPreparer} />}
                showCloseIcon={false}
                visible={rolePopOverVisible === taxPreparer.user.guid}
                onVisibleChange={() => {
                  setJobTitlePopOverVisible('')
                  setRolePopOverVisible(taxPreparer.user.guid)
                }}
              >
                <Menu.Item key="CHANGE_ROLE" icon={<UserSwitchOutlined />}>
                  {t('changeRole')}
                </Menu.Item>
              </Modal>
              <Modal
                key="JobTitleModal"
                placement="left"
                trigger="click"
                destroyTooltipOnHide
                showCloseIcon={false}
                visible={jobTitlePopOverVisible === taxPreparer.user.guid}
                onVisibleChange={() => {
                  setRolePopOverVisible('')
                  setJobTitlePopOverVisible(taxPreparer.user.guid)
                }}
                content={<JobTitleForm onSubmit={closeDropdown} taxPreparer={taxPreparer} />}
              >
                <Menu.Item key="CHANGE_JOBTITLE" icon={<TagOutlined />}>
                  {t('changeJobTitle')}
                </Menu.Item>
              </Modal>
              <Menu.Item
                onClick={() => {
                  closePopOvers()
                  removeFirmUser(cpaFirm!.guid, taxPreparer.user.guid)
                }}
                key="DISABLE"
                icon={<StopOutlined />}
              >
                {t('disable')}
              </Menu.Item>
            </Menu>
          }
        >
          <EllipsisOutlined className={styles.actions_icon} />
        </Dropdown>
      ),
    },
  ]

  return { columns: excludeColumns(columns, 'actions') }
}
