import cn from 'classnames'
import { Flex } from 'reflexbox'

import { useTaxOrganizer, useTaxOrganizerActions } from 'context/TaxOrganizer'
import { EnumFinSoftware, TLeftPart } from 'context/Financials'

import { useTranslation } from 'react-i18next'

import styles from '../../dashboard.financials.module.scss'

type TProps = {
  slug: EnumFinSoftware
  leftPart: TLeftPart
  setSpinner: (loading: boolean) => void
}

const SystemsListItem = ({ slug, leftPart, setSpinner }: TProps) => {
  const { setFinancialSoftware } = useTaxOrganizerActions()
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'financials.options' })
  const {
    taxOrganizer: {
      data: { guid, financialSoftware },
    },
  } = useTaxOrganizer()

  const handleClick = async () => {
    setSpinner(true)
    await setFinancialSoftware(guid, slug)
    setSpinner(false)
  }

  return (
    <Flex
      mb={[1, 3]}
      width={['100%', '100%', '100%', '448px']}
      className={cn(styles.system_card, slug === financialSoftware ? styles.selected : '')}
      onClick={handleClick}
    >
      <div className={cn(styles.system_card_indicator)} />
      <div className={styles.system_info}>
        <span className={styles.system_title}>{leftPart.title}</span>
        <p className={styles.system_description}>{t(leftPart.description)}</p>
      </div>
      <img className={styles.system_logo} src={leftPart.logo} alt="logo" />
    </Flex>
  )
}

export default SystemsListItem
