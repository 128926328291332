import { QuestionIcon } from 'components'
import { useTaxOrganizer } from 'context/TaxOrganizer'
import { useTaxReturnsActions, TTaxReturn, useTaxReturns } from 'context/TaxReturns'
import Title from 'pages/TaxOrganizer/TaxReturn/components/Title'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Flex } from 'reflexbox'
import styles from '../authorize.module.scss'
import RadioCardItem from '../components/RadioCardItem'

type TProps = {
  latestTaxReturn: TTaxReturn | null
}

const AuthorizedSigner = ({ latestTaxReturn }: TProps) => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'taxReturns.authorize' })
  const { updateTaxReturn, loadEntitySigners, assignAuthorizedSigner } = useTaxReturnsActions()
  const {
    taxOrganizer: { data },
  } = useTaxOrganizer()
  const {
    state: { entitySigners },
  } = useTaxReturns()

  useEffect(() => {
    loadEntitySigners(data.entity.guid)
  }, [])

  const [guidSelected, selectionChange] = useState('')

  const updateAuthorizeStep = async (id: string) => {
    if (!latestTaxReturn) return
    await assignAuthorizedSigner(id, latestTaxReturn?.guid, 0)
    const patchTaxReturnBody: Partial<TTaxReturn> = {
      ...latestTaxReturn,
      authorizeStep: 'E_SIGN',
    }

    await updateTaxReturn(latestTaxReturn?.guid!, patchTaxReturnBody)
  }

  return (
    <>
      <Flex alignItems="center" justifyContent={['space-between']}>
        <Flex>
          <Title text={t('authorizedSigner.title')} />
          <QuestionIcon className={styles.question_icon} />
        </Flex>
      </Flex>
      {entitySigners?.map((u: ICpaContact) => {
        return (
          <RadioCardItem
            key={u.user.guid}
            avatar={u.user.avatar}
            title={`${u.user.firstName} ${u.user.lastName}`}
            buttonText={t('confirm')}
            showAvatar
            selected={guidSelected === u.user.guid}
            itemClick={() => selectionChange(u.user.guid)}
            buttonClick={() => updateAuthorizeStep(u.user.email)}
          />
        )
      })}
    </>
  )
}

export default AuthorizedSigner
