import { TQDocumentType } from 'context/Questions'
import { useTranslation } from 'react-i18next'

type TProps = {
  documents: TQDocumentType[]
}

const PrintQuestionDocuments = ({ documents }: TProps) => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'questionnaire' })
  return (
    <>
      <div className="print-qst-documents">
        <b>{t('attachedDocuments')}:</b>{' '}
        {documents.map((document, i) => {
          return (
            <span key={document.guid}>
              {document.name}
              {i !== documents.length - 1 ? ', ' : ''}
            </span>
          )
        })}
      </div>
    </>
  )
}

export default PrintQuestionDocuments
