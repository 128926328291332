import { Collapse } from 'react-collapse'
import { Flex } from 'reflexbox'

import { TFile, TTaxDocumentExtended } from 'context/TaxOrganizer'

import Preview from './components/Preview'
import Form from './components/Form'

type TProps = {
  fileCollapse: boolean
  setFileCollapse: (value: boolean | ((prevState: boolean) => boolean)) => void
  fileUploading?: TFile
  fileUploaded: TTaxDocumentExtended | undefined
  onRemove: (fileUploadedGuid: string | undefined, fileUploadingUid?: string) => void
  setFileCategories: (value: any | ((prevState: any) => any)) => void
}

const Body = ({
  fileCollapse,
  setFileCollapse,
  fileUploading,
  fileUploaded,
  onRemove,
  setFileCategories,
}: TProps) => {
  const fileUrl = fileUploaded?.file || fileUploaded?.fileUrl || ''
  const fileType = fileUploading?.type ?? fileUploaded?.name ?? ''
  return (
    <>
      <Collapse isOpened={fileCollapse}>
        <Flex flexDirection={['column', 'column', 'row']}>
          <Preview fileType={fileType} fileUrl={fileUrl} />
          <Form
            setFileCollapse={setFileCollapse}
            fileUploaded={fileUploaded}
            onRemove={() => onRemove(fileUploaded?.guid, fileUploading?.uid)}
            setFileCategories={setFileCategories}
          />
        </Flex>
      </Collapse>
    </>
  )
}

export default Body
