import { Col, Row } from 'antd'
import cn from 'classnames'

import styles from './tax.footer.module.scss'

const Footer = () => {
  return (
    <div className={cn(styles.box, 'container')}>
      <Row gutter={[20, 20]}>
        <Col flex="auto" />
        <Col>© REPOSITAX</Col>
      </Row>
    </div>
  )
}

export default Footer
