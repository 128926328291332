import { useTranslation } from 'react-i18next'
import { Typography } from 'antd'
import { Box, Flex } from 'reflexbox'
import { RightOutlined } from '@ant-design/icons'

import styles from '../../../dashboard.tax_return.module.scss'

const Cards = () => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'taxReturns.info' })
  return (
    <>
      <Flex flexWrap="wrap">
        <Box
          className={styles.tax_return_step_card}
          width={['100%', 'inherit', 'inherit']}
          minWidth="200px"
          px={3}
          py={3}
          m={2}
          key="100"
        >
          <Typography.Title level={5}>{t('cards.federal')}</Typography.Title>
          <Flex justifyContent="flex-start" alignItems="center">
            <RightOutlined style={{ opacity: '0.3' }} />
            <Box ml="5px">{t('cards.content.first.1')}</Box>
          </Flex>
          <Flex mt={1} justifyContent="flex-start" alignItems="center">
            <RightOutlined style={{ opacity: '0.3' }} />
            <Box ml="5px">{t('cards.content.first.2')}</Box>
          </Flex>
          <Flex mt={1} justifyContent="flex-start" alignItems="center">
            <RightOutlined style={{ opacity: '0.3' }} />
            <Box ml="5px">{t('cards.content.first.3')}</Box>
          </Flex>
        </Box>
        <Box
          className={styles.tax_return_step_card}
          width={['100%', 'inherit', 'inherit']}
          minWidth="200px"
          px={3}
          py={3}
          m={2}
          key="200"
        >
          <Typography.Title level={5}>{t('cards.states(s)')}</Typography.Title>
          <Flex justifyContent="flex-start" alignItems="center">
            <RightOutlined style={{ opacity: '0.3' }} />
            <Box ml="5px">{t('cards.content.first.1')}</Box>
          </Flex>
          <Flex mt={1} justifyContent="flex-start" alignItems="center">
            <RightOutlined style={{ opacity: '0.3' }} />
            <Box ml="5px">{t('cards.content.first.2')}</Box>
          </Flex>
          <Flex mt={1} justifyContent="flex-start" alignItems="center">
            <RightOutlined style={{ opacity: '0.3' }} />
            <Box ml="5px">{t('cards.content.first.3')}</Box>
          </Flex>
        </Box>
        <Box
          className={styles.tax_return_step_card}
          width={['100%', 'inherit', 'inherit']}
          minWidth="200px"
          px={3}
          py={3}
          m={2}
          key="300"
        >
          <Typography.Title level={5}>{t('cards.miscellaneous')}</Typography.Title>
          <Flex justifyContent="flex-start" alignItems="center">
            <RightOutlined style={{ opacity: '0.3' }} />
            <Box ml="5px">{t('cards.content.third.1')}</Box>
          </Flex>
          <Flex mt={1} justifyContent="flex-start" alignItems="center">
            <RightOutlined style={{ opacity: '0.3' }} />
            <Box ml="5px">{t('cards.content.third.2')}</Box>
          </Flex>
          <Flex mt={1} justifyContent="flex-start" alignItems="center">
            <RightOutlined style={{ opacity: '0.3' }} />
            <Box ml="5px">{t('cards.content.third.3')}</Box>
          </Flex>
        </Box>
      </Flex>
    </>
  )
}

export default Cards
