import logger from 'use-reducer-logger'
import _, { cloneDeep, range, snakeCase } from 'lodash'
import {
  ClienStatusEnum,
  ClientStatus,
  CpaStatus,
  CpaStatusEnum,
  EnumPhaseTypes,
  PhaseName,
} from 'context/TaxOrganizer'
import { LoginToken } from 'context/Auth'
import { EnumStatus } from 'context/CPAFirm'
import { TGoogleAddressComponent } from 'components/GoogleAddressLookup/types'
import jwtDecode from 'jwt-decode'
import { TClientInviteParams } from 'context/EntityCPAs'
import scss from '../assets/styles/variables.module.scss'

const scheme = {
  1: {
    backgroundColor: scss.tag1,
    borderColor: scss.tag1,
  },
  2: {
    backgroundColor: scss.tag2,
    borderColor: scss.tag2,
  },
  3: {
    backgroundColor: scss.tag3,
    borderColor: scss.tag3,
  },
  4: {
    backgroundColor: scss.tag4,
    borderColor: scss.tag4,
  },
}

export const getTagColorByName = (tag: string) => {
  return tag.length > 12
    ? scheme[1]
    : tag.length > 9
    ? scheme[2]
    : tag.length > 6
    ? scheme[3]
    : scheme[4]
}

export const getPhaseColorByName = (phase: PhaseName) => {
  switch (phase) {
    case EnumPhaseTypes.EXTENDED:
      return scss.extended
    case EnumPhaseTypes.COLLECTING:
      return scss.collecting
    case EnumPhaseTypes.PREPARING:
      return scss.preparing
    case EnumPhaseTypes.REVIEWING:
      return scss.reviewing
    case EnumPhaseTypes.FILING:
      return scss.filling
    case EnumPhaseTypes.FILED:
      return scss.filed
    default:
      throw new Error(`No color for ${phase}`)
  }
}

export const getStatusColorByName = (status: EnumStatus) => {
  switch (status) {
    case 'pen':
      return scss.filling
    case 'acc':
      return scss.collecting
    case 'rev':
      return scss.extended
    default:
      throw new Error(`No color for ${status}`)
  }
}

export const getCPAStatusColorByName = (status: CpaStatus | string) => {
  switch (status) {
    case CpaStatusEnum.BLOCKED:
      return scss.blocked
    case CpaStatusEnum.IN_PROGRESS:
      return scss.progress
    case CpaStatusEnum.WAITING:
      return scss.waiting
    default:
      throw new Error(`No color for ${status}`)
  }
}

export const getEntityStatusColorByName = (status: ClientStatus | string) => {
  switch (status) {
    case ClienStatusEnum.BLOCKED:
      return scss.blocked
    case ClienStatusEnum.IN_PROGRESS:
      return scss.progress
    case ClienStatusEnum.WAITING:
      return scss.waiting
    default:
      throw new Error(`No color for ${status}`)
  }
}

export const fakeCurrentUser = {
  name: 'J. Smith',
  fullName: 'John Smith',
}

export const getInitials = (user: {
  firstName?: string | undefined
  lastName?: string | undefined
}) => {
  return `${user?.firstName?.charAt(0)}${user?.lastName?.charAt(0)}`
}

export const toFormData = (params: { [key: string]: any }) => {
  const formData = new FormData()
  Object.keys(params).forEach((key) => formData.append(key, params[key]))
  return formData
}

export function useReducerLogger<T, K>(reducer: (state: T, action: K) => T) {
  return process.env.NODE_ENV === 'development' ? logger<T>(reducer) : reducer
}

export const closest = (el: any, fn: any): any => el && (fn(el) ? el : closest(el.parentNode, fn))

_.mixin({
  deep(obj, mapper) {
    return mapper(
      _.mapValues(obj, (v) => {
        // @ts-ignore
        return _.isPlainObject(v) ? _.deep(v, mapper) : v
      })
    )
  },
})

export const toSnake = (data: any): any =>
  // @ts-ignore
  _.deep(data, (x) => _.mapKeys(x, (val, key) => snakeCase(key)))

export const getPagination =
  (perPage: number, total?: number) => (page: number, type: string, originalElement: any) => {
    if (type === 'page') {
      const last = page * perPage
      return (
        <>
          {last - perPage + 1}–{total ? Math.min(last, total) : last}
        </>
      )
    }
    return originalElement
  }

export const paginateRows = (sortedRows: any, activePage: number, rowsPerPage: number) => {
  return [...sortedRows].slice((activePage - 1) * rowsPerPage, activePage * rowsPerPage)
}

export const div = (a: number, b: number): number => (a - (a % b)) / b
export const mod = (a: number, b: number): number => a % b

export const isEmailConfirmed = (token: LoginToken | null): boolean => {
  if (!token) return false
  return !!(jwtDecode(token.access) as any)?.email_confirmed
}

export const getFirstError = (errorList: Array<string>) => (errorList.length ? errorList[0] : null)

export const formatApiErrorResponse = (response: any) => {
  const shallowErrors = Object.entries(response).map(([key, value]) => {
    return {
      name: key === 'detail' ? 'entity' : key,
      errors: value,
    }
  })

  // @ts-ignore
  return shallowErrors.flatMap(({ errors, name, ...rest }) => {
    if (Array.isArray(errors)) return { errors, name, ...rest }
    if (typeof errors === 'object' && errors !== null) {
      return Object.entries(errors).map(([deepErrorKey, deepError]) => ({
        name: [name, deepErrorKey],
        errors: deepError,
        ...rest,
      }))
    }
    return []
  })
}

export const getAddressComponentByType = (
  addressComponents: TGoogleAddressComponent[] = [],
  type: string,
  variant: 'short' | 'long' = 'short'
) => addressComponents.find((component) => component.types.includes(type))?.[`${variant}Name`]

export const removeAllChildNodes = (parent: null | HTMLElement) => {
  while (parent?.firstChild) {
    parent?.removeChild(parent?.firstChild)
  }
}

export const fieldIsLocked = (
  taxOrganizerCurrentlyLocked: boolean,
  afterTaxOrganizerLock: boolean | undefined
) =>
  taxOrganizerCurrentlyLocked &&
  typeof afterTaxOrganizerLock !== 'undefined' &&
  !afterTaxOrganizerLock

export const reloadData = <T extends TClientInviteParams | TLoadEntitiesParams | TParams>(
  rowIsLast: boolean,
  setParams: (value: T | ((prevState: T) => T)) => void,
  defaultParams: T
) => {
  if (rowIsLast) {
    setParams((prevParams: T) => {
      if ((prevParams.page as number) === 1) {
        setParams(cloneDeep(defaultParams))
      }
      return {
        ...prevParams,
        page: (prevParams.page as number) - 1,
      }
    })
  } else {
    setParams((prevParams: T) => cloneDeep(prevParams))
  }
}

export const getTaxYears = () => {
  const currentYear = new Date().getFullYear()
  return range(2014, currentYear + 2)
}
