import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { Alert } from 'components'

import useUserType from 'hooks/useUserType'

import { FilingStateEnum } from 'context/TaxReturns'
import { TFilingStateProps } from '../types'

import styles from '../../../dashboard.tax_return.module.scss'

type TProps = TFilingStateProps & {
  isPaperSuccess: boolean
}
const SelectFilingSuccess = ({ taxReturnUpdate, isPaperSuccess }: TProps) => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'taxReturns.fieldReturn' })
  const userType = useUserType()

  return (
    <Alert
      className={styles.tax_return_review_guide}
      message={
        <div className={styles.wrapper}>
          <div className={styles.content_alert}>
            <div>
              <div className={styles.title}>{t('selectFilingSuccessTitle')}</div>

              <div>{t('selectFilingSuccessDescription')}</div>
            </div>
            {userType === 'CPA' && (
              <div className={styles.actions_btn}>
                {isPaperSuccess ? (
                  <>
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => taxReturnUpdate(FilingStateEnum.PAPER_PENDING_CONFIRMATION)}
                    >
                      {t('undoPaperSuccess')}
                    </Button>

                    <Button type="primary" size="small" ghost>
                      {t('uploadReceipt')}
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      type="primary"
                      size="small"
                      style={{ width: '150px' }}
                      onClick={() => taxReturnUpdate(FilingStateEnum.EFILE_FAILED)}
                    >
                      {t('undo')}
                    </Button>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      }
      type="success"
    />
  )
}

export default SelectFilingSuccess
