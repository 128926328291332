import { useTranslation } from 'react-i18next'

import styles from '../../dashboard.outlook.module.scss'

const Head = () => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'outlook' })
  return (
    <div className={styles.header}>
      <span className={styles.header_title}>{t('title')}</span>
      <p className={styles.header_description}>{t('description')}</p>
    </div>
  )
}

export default Head
