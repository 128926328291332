import { TEntityUserPermissions, EnumEntityUserRoles } from 'context/Entity'
import { EnumStatus } from 'context/CPAFirm'
import { EnumTinKind } from '../context/EntityCPAs'
import { TEntityUserInvite } from '../context/Invites/types'

export const getEntityUserRole = (entityUserRoles: Array<TOption>, role: EnumEntityUserRoles) =>
  entityUserRoles.find((userRole) => userRole.value === role)

export const formatTin = (tin: string | undefined, tinType: EnumTinKind | undefined) => {
  if (!tin || tin.length !== 9 || !tinType) return tin
  switch (tinType) {
    case EnumTinKind.EIN:
      return `${tin.substring(0, 2)}-${tin.substring(2)}`
    case EnumTinKind.SSN:
      return `${tin.substring(0, 3)}-${tin.substring(3, 5)}-${tin.substring(5)}`
    default:
      return tin
  }
}

export const normalizeTin = (tin: string) => tin.replaceAll('-', '')

export const getTinFormat = (tinType: EnumTinKind | undefined) => {
  switch (tinType) {
    case EnumTinKind.SSN:
      return '111-11-1111'
    case EnumTinKind.EIN:
      return '11-1111111'
    default:
      return '111111111'
  }
}

export const serializeAddress = (address: TAddress) => {
  const addressFormat: string[][] = []
  const emptyFields = new Map(Object.entries(address).filter((x) => x[1] === '' || x[1] === null))
  // First line
  if (!emptyFields.has('line1')) addressFormat.push([address.line1])
  // Second Line
  if (!emptyFields.has('city') && (!emptyFields.has('state') || !emptyFields.has('zipCode'))) {
    addressFormat.push([`${address.city},`])
  }
  if (
    address.city &&
    !emptyFields.has('city') &&
    emptyFields.has('state') &&
    emptyFields.has('zipCode')
  ) {
    addressFormat.push([address.city])
  }
  if (address.state && !emptyFields.has('state') && !emptyFields.has('city')) {
    addressFormat[addressFormat.length - 1].push(address.state)
  }
  if (address.state && !emptyFields.has('state') && emptyFields.has('city')) {
    addressFormat.push([address.state])
  }
  if (!emptyFields.has('zipCode') && (!emptyFields.has('city') || !emptyFields.has('state'))) {
    addressFormat[addressFormat.length - 1].push(address.zipCode)
  }
  if (!emptyFields.has('zipCode') && emptyFields.has('city') && emptyFields.has('state')) {
    addressFormat.push([address.zipCode])
  }
  // Third Line
  if (address.country && !emptyFields.has('country')) addressFormat.push([address.country])
  return addressFormat.map((value) => value.join(' ')).join('\n')
}

export const getNonRevokedInvite = (inviteList: TEntityUserInvite[]) =>
  inviteList.find((invite) => invite.status !== EnumStatus.REVOKED)

export const getEntityUserRolesByPermission = (permissions: TEntityUserPermissions[]) => {
  return permissions
    .filter((permission) => permission.canAssign)
    .map((value) => ({
      label: value.label,
      value: value.role,
    }))
}
