import { Alert } from 'components'
import { useEffect, useState, useRef } from 'react'
import { Button, Steps, Switch, Modal as SignModal } from 'antd'
import { useMediaQuery } from 'react-responsive'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import {
  TTaxReturn,
  useTaxReturnsActions,
  useTaxReturns,
  FilingStateEnum,
} from 'context/TaxReturns'
import { useAuth } from 'context/Auth'
import { useTaxOrganizer } from 'context/TaxOrganizer'

import useUserType from 'hooks/useUserType'

import ModalStyles from 'components/UploadModal/uploadmodal.module.scss'

import { ESteps } from 'pages/TaxOrganizer/TaxReturn/steps'
import styles from '../../../dashboard.tax_return.module.scss'
import Authorize from '../../Authorize'

const { Step } = Steps

type TProps = {
  setCommentsEnabled: (value: boolean) => void
  commentsEnabled: boolean
  taxReturn: TTaxReturn | undefined
  draftVersion: number
}

type AlertTypes = 'error' | 'success' | 'info' | 'warning' | undefined

const REVIEW_GUIDE_STEPS = [
  {
    id: 'review-step-1',
  },
  {
    id: 'review-step-2',
  },
  {
    id: 'review-step-3',
  },
  {
    id: 'review-step-4',
  },
  {
    id: 'review-step-5',
  },
  {
    id: 'review-step-6',
  },
]

const REQUEST_INTERVAL = 5000
const MAX_RETRY = 5

const ReviewGuide = ({ commentsEnabled, setCommentsEnabled, taxReturn, draftVersion }: TProps) => {
  const isTablet = useMediaQuery({ query: '(min-width: 52em)' })
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'taxReturns.review' })
  const {
    loadThreads,
    checkSigned,
    setStep,
    updateTaxReturn,
    createTaxReturn,
    loadLatestTaxReturn,
    loading,
  } = useTaxReturnsActions()
  const {
    state: { authorizationDocumentDetails },
  } = useTaxReturns()
  const {
    taxOrganizer: {
      data: { guid },
    },
  } = useTaxOrganizer()
  const [commentCount, setCommentCount] = useState<number>(0)
  const [hasError, setHasError] = useState<boolean>(false)
  const [canClientAuthorize, setCanClientAuthorize] = useState<boolean>(false)
  const { user } = useAuth()
  const userType = useUserType()
  const isEntity = userType === 'ENTITY'
  const [signModalVisible, setSignModalVisible] = useState(false)
  const localStorageReviewStepKey = `${taxReturn?.guid}${user?.guid}+_tax-review-guide`
  const [current, setCurrent] = useState<number>(
    Number(localStorage.getItem(localStorageReviewStepKey)) || 0
  )
  const [refetchDocumentCounter, setRefetchDocumentCounter] = useState(0)
  const stop = useRef<NodeJS.Timeout>()

  const CHANGES_REQUESTED_PHASE = taxReturn?.phase === 'CHANGES_REQUESTED'

  const setTimeRequest = () => {
    stop.current = setTimeout(async () => {
      if (taxReturn) {
        checkSigned(taxReturn.guid)
        setRefetchDocumentCounter(refetchDocumentCounter + 1)
      }
    }, REQUEST_INTERVAL)
  }

  useEffect(() => {
    return () => {
      clearInterval(stop.current as NodeJS.Timeout)
    }
  }, [])

  const checkIfShouldGenerateESign = async () => {
    if (!taxReturn || !authorizationDocumentDetails) return

    if (
      authorizationDocumentDetails.fieldsStatus === 'OK' ||
      authorizationDocumentDetails.fieldsStatus === 'ERROR'
    ) {
      clearInterval(stop.current as NodeJS.Timeout)
      setSignModalVisible(false)
    }

    // maybne also check on auth step to prevent auto open after close
    if (authorizationDocumentDetails.fieldsStatus === 'EMPTY' && taxReturn.phase !== 'FILING') {
      setSignModalVisible(true)
      setTimeRequest()
    }
  }

  const didCurrentUserSign = (userId: string | undefined): boolean => {
    const currentSigner = authorizationDocumentDetails.signers.find(
      (signer) => signer.user.guid === userId
    )
    if (currentSigner?.signedOn !== null) {
      return true
    }
    return false
  }

  const checkIfShouldAuthorize = async () => {
    if (!taxReturn || !authorizationDocumentDetails) return

    if (didCurrentUserSign(user?.guid)) {
      clearInterval(stop.current as NodeJS.Timeout)
      setSignModalVisible(false)
    }

    if (authorizationDocumentDetails.isSignedByAll) {
      const patchTaxReturnBody: Partial<TTaxReturn> = {
        ...taxReturn,
        phase: 'FILING',
        authorizeStep: 'COMPLETE',
        filingState: FilingStateEnum.EFILE_PENDING_CONFIRMATION,
      }
      await updateTaxReturn(taxReturn?.guid!, patchTaxReturnBody)
      setStep(ESteps.FILING, 'push')
    }

    if (canClientAuthorize) {
      setSignModalVisible(true)
      setTimeRequest()
    }
  }

  const handleModalClose = async () => {
    clearInterval(stop.current as NodeJS.Timeout)
    setSignModalVisible(false)
    if (taxReturn?.filingState === FilingStateEnum.PAPER_PENDING_CONFIRMATION) {
      const newTaxReturn = await loadLatestTaxReturn(taxReturn?.guid!)
      setStep(ESteps[newTaxReturn!.phase as keyof typeof ESteps], 'replace')
    }
  }

  const closeModalAndPoll = async () => {
    setSignModalVisible(false)
    clearInterval(stop.current as NodeJS.Timeout)
    setCanClientAuthorize(false)
    setTimeRequest()
  }

  const getThreads = async () => {
    if (!taxReturn) return
    const threads = await loadThreads({ taxReturn: taxReturn.guid })
    setCommentCount(threads?.count || 0)
    checkSigned(taxReturn.guid)
  }

  const setCurrentWithLocalStorage = (newValue: number) => {
    setCurrent(newValue)
    localStorage.setItem(localStorageReviewStepKey, String(newValue))
  }

  const handleBack = () => {
    setCurrentWithLocalStorage(Math.max(current - 1, 0))
  }

  const handleNext = () => {
    setCurrentWithLocalStorage(Math.min(current + 1, REVIEW_GUIDE_STEPS.length - 1))
  }

  const getStatusType = (): AlertTypes => {
    if (hasError) {
      return 'error'
    }

    if (taxReturn?.phase === 'REVIEW') {
      return 'info'
    }

    if (
      (isEntity && !canClientAuthorize) ||
      (!isEntity && canClientAuthorize) ||
      CHANGES_REQUESTED_PHASE
    ) {
      return 'warning'
    }

    return 'info'
  }

  const getGuideTitle = (): string => {
    if (current !== REVIEW_GUIDE_STEPS.length - 1) {
      return t('reviewGuide')
    }

    if (hasError) {
      return t('entityError')
    }

    if (isEntity && CHANGES_REQUESTED_PHASE) {
      return t('changedByEntity')
    }

    if (!isEntity && CHANGES_REQUESTED_PHASE) {
      return t('changesRequested')
    }

    if (isEntity && !canClientAuthorize) {
      return t('awaitingCPATitle')
    }

    if (isEntity && canClientAuthorize) {
      return t('readyToSign')
    }

    if (!isEntity && canClientAuthorize) {
      return t('awaitingEntity')
    }

    return t('reviewGuide')
  }

  const getGuideDescription = (): string => {
    if (hasError) {
      return t('entityErrorDescription')
    }

    if (isEntity && taxReturn?.phase === 'REVIEW') {
      return t(`guideEntity.${current + 1}`)
    }

    if (isEntity && !canClientAuthorize) {
      return t('awaitingCPADescription')
    }

    if (isEntity && canClientAuthorize) {
      return t('readyToSignDescription')
    }

    if (!isEntity && canClientAuthorize) {
      return t('awaitingEntityDescription')
    }

    if (!isEntity && CHANGES_REQUESTED_PHASE) {
      return t('changesRequestedDescription')
    }

    return t(`guideCPA.${current + 1}`)
  }

  const handleRequestChanges = () => {
    if (!taxReturn) return

    const patchTaxReturnBody: Partial<TTaxReturn> = {
      ...taxReturn,
      phase: 'CHANGES_REQUESTED',
    }

    updateTaxReturn(taxReturn?.guid!, patchTaxReturnBody)
  }

  const resetTaxReturn = () => {
    createTaxReturn(guid)
  }

  const dismiss = () => {
    if (!taxReturn) return

    const patchTaxReturnBody: Partial<TTaxReturn> = {
      ...taxReturn,
      phase: 'REVIEW',
    }

    updateTaxReturn(taxReturn?.guid!, patchTaxReturnBody)
  }

  useEffect(() => {
    getThreads()
  }, [taxReturn])

  useEffect(() => {
    if (!authorizationDocumentDetails) return
    if (refetchDocumentCounter >= MAX_RETRY && !signModalVisible) {
      clearInterval(stop.current as NodeJS.Timeout)
    }

    if (!isEntity && authorizationDocumentDetails.fieldsStatus === 'EMPTY') {
      checkIfShouldGenerateESign()
    }
    if (isEntity && signModalVisible) {
      checkIfShouldAuthorize()
    }
    if (authorizationDocumentDetails.fieldsStatus === 'ERROR') {
      setHasError(true)
      return
    }

    if (taxReturn?.authorizeStep === 'COMPLETE' || authorizationDocumentDetails.isSignedByAll) {
      setCanClientAuthorize(false)
    } else {
      setCanClientAuthorize(
        authorizationDocumentDetails.signers.length > 0 &&
          authorizationDocumentDetails.fieldsStatus === 'OK'
      )
    }
  }, [authorizationDocumentDetails, taxReturn])

  return (
    <Alert
      className={styles.tax_return_review_guide}
      message={
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <div>
              <div className={styles.title}>{getGuideTitle()}</div>
              <div className={styles.descriptionText}>{getGuideDescription()}</div>
              {isEntity && CHANGES_REQUESTED_PHASE ? null : (
                <div className={styles.navigation}>
                  <LeftOutlined className={styles.pagination_arrow} onClick={handleBack} />
                  <Steps
                    current={current}
                    size="small"
                    className={styles.steps_small}
                    labelPlacement={isTablet ? 'horizontal' : 'vertical'}
                    status="process"
                    onChange={(stepNumber) => setCurrentWithLocalStorage(stepNumber)}
                    responsive={false}
                  >
                    {REVIEW_GUIDE_STEPS.map((step: any) => (
                      <Step key={step.id} className={styles.step} />
                    ))}
                  </Steps>
                  <RightOutlined className={styles.pagination_arrow} onClick={handleNext} />
                </div>
              )}
            </div>
            {(current === REVIEW_GUIDE_STEPS.length - 1 || CHANGES_REQUESTED_PHASE) && (
              <div
                className={
                  isEntity || CHANGES_REQUESTED_PHASE
                    ? isEntity && CHANGES_REQUESTED_PHASE
                      ? styles.actions_single
                      : styles.actions_multiple
                    : styles.skip
                }
              >
                {isEntity ? (
                  <>
                    {CHANGES_REQUESTED_PHASE ? null : (
                      <Button
                        type="primary"
                        size="large"
                        onClick={handleRequestChanges}
                        disabled={CHANGES_REQUESTED_PHASE}
                      >
                        {t('requestChanges')}
                      </Button>
                    )}
                    <SignModal
                      closable
                      visible={signModalVisible}
                      className={ModalStyles.modal}
                      width={1200}
                      destroyOnClose
                      footer={null}
                      onCancel={closeModalAndPoll}
                    >
                      <Authorize isEntity={isEntity} closeModal={handleModalClose} />
                    </SignModal>
                    {CHANGES_REQUESTED_PHASE ? (
                      <Button
                        disabled={loading || hasError}
                        type="primary"
                        size="large"
                        ghost
                        onClick={() => dismiss()}
                      >
                        {t('dismissChanges')}
                      </Button>
                    ) : (
                      <Button
                        disabled={!canClientAuthorize || loading || hasError}
                        type="primary"
                        size="large"
                        ghost
                        onClick={checkIfShouldAuthorize}
                      >
                        {canClientAuthorize && !hasError
                          ? t('authorizeForFiling', { draftVersion })
                          : hasError
                          ? t('entityError')
                          : t('awaitingCPA')}
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    <SignModal
                      closable
                      visible={signModalVisible}
                      className={ModalStyles.modal}
                      width={1200}
                      destroyOnClose
                      footer={null}
                      onCancel={handleModalClose}
                    >
                      <Authorize isEntity={isEntity} closeModal={handleModalClose} />
                    </SignModal>
                    {CHANGES_REQUESTED_PHASE ? (
                      <>
                        <Button
                          disabled={loading || hasError}
                          type="primary"
                          size="large"
                          onClick={() => resetTaxReturn()}
                        >
                          {t('resetTaxReturn')}
                        </Button>
                        <Button
                          disabled={loading || hasError}
                          type="primary"
                          size="large"
                          ghost
                          onClick={() => dismiss()}
                        >
                          {t('dismissChanges')}
                        </Button>
                      </>
                    ) : (
                      <Button
                        disabled={canClientAuthorize || loading || hasError}
                        type="primary"
                        size="large"
                        ghost
                        onClick={() => setSignModalVisible(true)}
                      >
                        {canClientAuthorize && !hasError
                          ? t('awaitingAuthorize')
                          : hasError
                          ? t('entityError')
                          : t('createEfileDoc')}
                      </Button>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
          <div className={styles.comments}>
            <Switch
              className={styles.switch}
              checked={commentsEnabled}
              onChange={(newValue) => setCommentsEnabled(newValue)}
            />
            {t('comments')} {commentCount ? `(${commentCount})` : null}
          </div>
        </div>
      }
      type={getStatusType()}
    />
  )
}

export default ReviewGuide
