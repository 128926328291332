import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as InstructionsIcon } from 'assets/images/instructions.svg'

import { Button } from 'components'
import Modal from './Modal'

type TProps = {
  currentStep: number | undefined
}

const Instructions = ({ currentStep }: TProps) => {
  const [visible, setVisible] = useState(false)
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'taxReturns' })

  if (currentStep === undefined) {
    return null
  }

  return (
    <>
      <Button icon={<InstructionsIcon />} block flex size="large" onClick={() => setVisible(true)}>
        {t('instructions.title')}
      </Button>
      <Modal visible={visible} setVisible={setVisible} currentStep={currentStep} />
    </>
  )
}

export default Instructions
