import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ColumnsType } from 'antd/lib/table'
import { getEntityUserRole } from 'utils/entity'
import { Typography, Menu, Dropdown } from 'antd'
import { EllipsisOutlined, UserSwitchOutlined } from '@ant-design/icons'

import { getEntityUserRolesLocal } from 'context/Entity/types'

import { User, Modal } from 'components'
import colors from 'assets/styles/variables.module.scss'

import useUserEntityPermissions from 'hooks/useUserEntityPermissions'
import useUserType from 'hooks/useUserType'

import ChangeEntityRoleForm from '../components/ChangeEntityRoleForm'

import styles from '../../dashboard.entity.management.module.scss'

const { Text } = Typography

export default () => {
  const { t } = useTranslation('entity')
  const { t: t2 } = useTranslation('dashboard', { keyPrefix: 'firm.team' })

  const { userHasInvitePermissions } = useUserEntityPermissions()
  const userType = useUserType()

  const entityUserRoles = getEntityUserRolesLocal(t)
  const [dropdownVisible, setDropdownVisible] = useState<string>('')
  const [rolePopOverVisible, setRolePopOverVisible] = useState<string>('')

  const closePopOvers = () => {
    setRolePopOverVisible('')
  }
  const closeDropdown = () => {
    setDropdownVisible('')
    closePopOvers()
  }

  const excludeColumns = (columns: ColumnsType<IClientInvite>, columnKey: string) => {
    return columns.filter((column) => {
      if (userType === 'ENTITY') {
        return !(!userHasInvitePermissions && column.key === columnKey)
      }
      if (userType === 'CPA') {
        return column.key !== columnKey
      }
      return columns
    })
  }

  const columns: ColumnsType<IClientInvite> = [
    {
      title: t('common.name'),
      key: 'user',
      render: ({ user }: ICpaContact) => (
        <User
          username={`${user.firstName} ${user.lastName}`}
          bgColor={colors.tag5}
          avatarLink={user.avatar}
        />
      ),
    },
    {
      title: t('common.phone'),
      key: 'phone',
      render: ({ user }: ICpaContact) => <Text>{user.phone}</Text>,
    },
    {
      title: t('common.email'),
      key: 'user.email',
      render: ({ user }: ICpaContact) => <Text>{user.email}</Text>,
    },
    {
      title: t('common.role'),
      key: 'role',
      render: ({ role }: ICpaContact) => getEntityUserRole(entityUserRoles, role)?.label,
    },
    {
      title: t2('actions'),
      key: 'actions',
      render: (contact: ICpaContact) => (
        <Dropdown
          placement="bottomRight"
          trigger={['click']}
          visible={dropdownVisible === contact.user.guid}
          onVisibleChange={(value: boolean) => {
            if (value) {
              setDropdownVisible(contact.user.guid)
            } else {
              closeDropdown()
            }
          }}
          overlay={
            <Menu theme="dark">
              <Modal
                placement="left"
                trigger="click"
                destroyTooltipOnHide
                content={<ChangeEntityRoleForm onSubmit={closeDropdown} contact={contact} />}
                showCloseIcon={false}
                visible={rolePopOverVisible === contact.user.guid}
                onVisibleChange={() => {
                  setRolePopOverVisible(contact.user.guid)
                }}
              >
                <Menu.Item key="CHANGE_ROLE" icon={<UserSwitchOutlined />}>
                  {t2('changeRole')}
                </Menu.Item>
              </Modal>
            </Menu>
          }
        >
          <EllipsisOutlined className={styles.actions_icon} />
        </Dropdown>
      ),
    },
  ]

  return { columns: excludeColumns(columns, 'actions') }
}
