import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Flex } from 'reflexbox'

import { ReactComponent as UploadIcon } from 'assets/images/upload.svg'

import { Button } from 'components'
import Modal from './Modal'

import styles from './upload.module.scss'

const UploadSoftware = () => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'taxReturns.upload' })
  const { t: t2 } = useTranslation('common', { keyPrefix: 'buttons' })
  const [visible, setVisible] = useState(false)

  return (
    <>
      <Flex
        flexDirection={['column']}
        alignItems={['center']}
        justifyContent={['center']}
        py={['26px', '28px']}
        className={styles.upload}
        onClick={() => {
          setVisible(true)
        }}
      >
        <UploadIcon className={styles.icon} />
        <Box fontSize={['16px']} fontWeight={[700]} lineHeight={['20px']} mt={['10px']}>
          {t('software.1')}
        </Box>
        <Box fontSize={['16px']} fontWeight={[700]} lineHeight={['20px']} mb={['10px']}>
          {t('software.2')}
        </Box>
        <Button size="large" type="primary">
          {t2('upload')}
        </Button>
      </Flex>
      <Modal visible={visible} setVisible={setVisible} />
    </>
  )
}

export default UploadSoftware
