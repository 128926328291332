import { Redirect, Route, useLocation } from 'react-router-dom'

import { useAuth } from 'context/Auth'
import { isEmailConfirmed } from 'utils'
import { EMAIL_VERIFICATION_PATH } from 'utils/routes'
import WindowFocusHandler from 'hooks/useTabVisibility'

const PrivateRoute = (props: any) => {
  const { token } = useAuth()

  const location = useLocation()

  WindowFocusHandler()

  if (!token) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          search: location.search,
          state: {
            from: location,
          },
        }}
      />
    )
  }

  if (!isEmailConfirmed(token)) {
    return (
      <Redirect
        to={{
          pathname: EMAIL_VERIFICATION_PATH,
          search: location.search,
          state: {
            from: location,
          },
        }}
      />
    )
  }

  return <Route {...props} />
}

export default PrivateRoute
