import { PhaseActionSlug, useTaxOrganizer, useTaxOrganizerActions } from 'context/TaxOrganizer'
import { useMemo, ReactNode } from 'react'
import { LoadingOutlined, RightOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { canSignEngagementLetter, isEngagementLetterSigned } from 'utils/engagementLetter'
import { COLLECTION_PATH } from 'utils/routes'
import useUserType from 'hooks/useUserType'

export type PhaseActionLink = {
  title: ReactNode
  path: string
  actionCallback?: (args?: any) => void
  actionHidden?: boolean
  isDisabled?: boolean
}

type Props = {
  setError: (value: string | null) => void
  setIframeSource: (source: string) => void
}

export default ({ setIframeSource, setError }: Props) => {
  const { t } = useTranslation('common', { keyPrefix: 'buttons' })
  const {
    taxOrganizer: { data: taxOrganizerData, engagementLetterSigningInformation },
  } = useTaxOrganizer()
  const userType = useUserType()
  const { generateEngagementLetter, loading, response } = useTaxOrganizerActions()

  const actionLinks = useMemo(() => {
    const initialActionLinks: {
      [key in PhaseActionSlug]: PhaseActionLink
    } = {
      SIGN_ENGAGEMENT: {
        title: loading ? (
          <LoadingOutlined />
        ) : (
          <>
            {t('sign')}
            <RightOutlined />
          </>
        ),
        path: 'sign-engagement',
        actionCallback: async () => {
          if (loading) return
          const engagementLetter = await generateEngagementLetter(taxOrganizerData.guid)
          if (response.ok) {
            if (engagementLetter.signingLink) setIframeSource(engagementLetter.signingLink)
          } else {
            setError(engagementLetter.msg || engagementLetter.detail)
          }
        },
        actionHidden:
          isEngagementLetterSigned(engagementLetterSigningInformation, userType) ||
          userType === 'CPA',
        isDisabled: !canSignEngagementLetter(taxOrganizerData),
      },
      ANSWER_QUESTIONS: {
        title: (
          <>
            {t('answer')}
            <RightOutlined />
          </>
        ),
        path: `${COLLECTION_PATH}/questionnaire`,
      },
      UPLOAD_DOCUMENTS: {
        title: (
          <>
            {t('upload')}
            <RightOutlined />
          </>
        ),
        path: `${COLLECTION_PATH}/documents`,
      },
      ADD_FINANCIALS: {
        title: (
          <>
            {t('add')}
            <RightOutlined />
          </>
        ),
        path: 'financials',
      },
      UPLOAD_DRAFTS: {
        title: (
          <>
            {t('upload')}
            <RightOutlined />
          </>
        ),
        path: 'taxes',
      },
      MARK_RETURNS_FILED: {
        title: (
          <>
            {t('confirm')}
            <RightOutlined />
          </>
        ),
        path: '?confirm=true',
      },
    }

    return initialActionLinks
  }, [loading, engagementLetterSigningInformation])

  return { actionLinks }
}
