import { useEffect, useMemo } from 'react'
import { useAuth } from 'context/Auth'
import { useEntityCPAs, useEntityCPAsActions } from 'context/EntityCPAs'

export default () => {
  const { loadCpaClientInvites } = useEntityCPAsActions()
  const { loadClients } = useEntityCPAsActions()
  const {
    state: {
      entities: { total: totalEntities },
      clientInvites: { total: totalClients },
    },
  } = useEntityCPAs()
  const {
    CPA: { cpaFirm },
  } = useAuth()
  const showBanner: boolean = useMemo(() => {
    return totalEntities === 0 && totalClients === 0
  }, [totalEntities, totalClients])

  useEffect(() => {
    loadClients(cpaFirm!.guid, { isActive: true })
    loadCpaClientInvites(cpaFirm!.guid, { status: 'pen' })
  }, [])

  return { showBanner }
}
