import { useTranslation } from 'react-i18next'
import { Box } from 'reflexbox'
import { Link } from 'react-router-dom'
import { ColumnsType } from 'antd/lib/table'

import useDate from 'hooks/useDate'

import { Button } from 'components'

import { TInvoiceListItemExtended } from 'context/Billing'

import styles from '../../../dashboard.firm.billing.module.scss'

export default () => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'firm.billing' })
  const { getDate } = useDate()

  const columns: ColumnsType<TInvoiceListItemExtended> = [
    {
      title: (
        <Box fontWeight={[700]} fontSize={['14px']} className="text-nowrap">
          {t('month')}
        </Box>
      ),
      dataIndex: 'periodStartOn',
      key: 'period_start_on',
      sorter: true,
      ellipsis: true,
      render: (periodStartOn: string) => (
        <Box fontWeight={[700]}>{getDate(periodStartOn, 'MMMM')}</Box>
      ),
    },
    {
      title: (
        <Box fontSize="14px" className="text-nowrap">
          {t('price-user')}
        </Box>
      ),
      dataIndex: 'unitPriceDollars',
      key: 'unitPrice',
      render: (unitPriceDollars: number) => {
        return <span className="text-nowrap">${unitPriceDollars}</span>
      },
    },
    {
      title: (
        <Box fontSize="14px" className="text-nowrap">
          {t('activeClients')}
        </Box>
      ),
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: (
        <Box fontSize="14px" className="text-nowrap">
          {t('invoice')}
        </Box>
      ),
      dataIndex: 'totalDollars',
      key: 'total',
      render: (totalDollars: number) => {
        return <span className="text-nowrap">${totalDollars}</span>
      },
    },
    {
      title: (
        <Box fontSize="14px" className="text-nowrap">
          {t('status')}
        </Box>
      ),
      dataIndex: 'status',
      key: 'status',
      render: (status: boolean) => {
        return <span className="text-nowrap text-capitalize">{status}</span>
      },
    },
    {
      title: (
        <Box fontSize="14px" className="text-nowrap">
          {t('billDate')}
        </Box>
      ),
      dataIndex: 'createdOn',
      key: 'createdOn',
      render: (date: string) => {
        return <span className="text-nowrap">{getDate(date, 'yyyy-MM-dd')}</span>
      },
    },
    {
      title: (
        <Box fontSize="14px" className="text-nowrap">
          {t('download')}
        </Box>
      ),
      width: '150px',
      dataIndex: 'pdfUrl',
      key: 'pdfUrl',
      render: (pdfUrl: string) => {
        return (
          <Box minHeight={['30px']}>
            <Link to={{ pathname: pdfUrl }} target="_blank" download>
              <Button className={styles.btn_download} type="link">
                {t('download')}
              </Button>
            </Link>
          </Box>
        )
      },
    },
  ]

  return { columns }
}
