import { browserSession, removeCookies, browserStorage } from 'utils/session'

import { ActionTypes } from './action-types'
import { IAction, IAuthContext } from '.'
import { getINDVEntitiesCount, getOnboardingStep, getPenInvitationsCount } from './helpers'

export const initialState: IAuthContext = {
  token: browserStorage.getItem('token'),
  maskedToken: browserSession.getItem('maskedToken'),
  maskedGuid: browserSession.getItem('maskedGuid'),
  user: null,
  users: null,
  CPA: {
    cpaUserInvites: {
      pending: undefined,
    },
    cpaFirm: undefined,
    userRole: undefined,
    subscriptionActive: null,
  },
  ENTITY: {
    INDVEntitiesCount: undefined,
    pendingInvitations: undefined,
  },
  onboardingFlow: {
    status: undefined,
    responseOk: undefined,
    step: undefined,
  },
  invitationsFlow: {
    status: undefined,
    response: undefined,
    inviteInfo: undefined,
    userType: undefined,
    forwardInvitation: undefined,
  },
  invitations: {
    entityUserInvitations: 0,
    clientInvitations: 0,
    firmInvitations: 0,
    entityFirmInvitations: 0,
    pendingInvitations: 0,
    previouslyAcceptedAnInvitation: false,
  },
}

export const AuthReducer = (state: IAuthContext = initialState, action: IAction) => {
  let newToken
  const { type, payload } = action

  switch (type) {
    case ActionTypes.LOGIN_SUCCESS:
      browserStorage.setItem('token', payload)
      browserSession.removeItem('maskedToken')
      browserSession.removeItem('maskedGuid')
      return {
        ...state,
        token: payload,
        maskedToken: null,
        maskedGuid: null,
      }

    case ActionTypes.LOGOUT:
      browserSession.clear()
      browserStorage.clear()
      localStorage.clear()
      removeCookies()
      return {
        ...state,
        token: null,
        user: null,
        maskedToken: null,
        maskedGuid: null,
        CPA: {
          cpaUserInvites: {
            pending: undefined,
          },
          cpaFirm: undefined,
          userRole: undefined,
          subscriptionActive: null,
        },
        ENTITY: {
          INDVEntitiesCount: undefined,
          pendingInvitations: undefined,
        },
        onboardingFlow: {
          status: undefined,
          responseOk: undefined,
          step: undefined,
        },
        invitationsFlow: {
          status: undefined,
          response: undefined,
          inviteInfo: undefined,
          userType: undefined,
          forwardInvitation: undefined,
        },
        invitations: {
          entityUserInvitations: 0,
          clientInvitations: 0,
          firmInvitations: 0,
          entityFirmInvitations: 0,
          pendingInvitations: 0,
          previouslyAcceptedAnInvitation: false,
        },
      }

    case ActionTypes.REFRESH_TOKEN:
      // NOTE: The below state.token check is to pass TS compiler.
      newToken = state.token
        ? {
            ...state.token,
            access: payload,
          }
        : null
      browserSession.setItem('token', newToken)

      browserStorage.setItem('token', newToken)
      return {
        ...state,
        token: newToken,
      }

    case ActionTypes.MASK_SUCCESS: {
      const { token } = payload
      browserSession.setItem('maskedToken', token)
      browserSession.setItem('maskedGuid', state.user?.guid)
      return state
    }

    case ActionTypes.STOP_MASK: {
      browserSession.removeItem('maskedToken')
      browserSession.removeItem('maskedGuid')
      window.location.href = '/'
      return {
        ...state,
        maskedToken: null,
        maskedGuid: null,
      }
    }

    case ActionTypes.SET_USER:
      return {
        ...state,
        user: payload,
      }

    case ActionTypes.SET_USERS:
      return {
        ...state,
        users: payload,
      }

    case ActionTypes.UPDATE_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload,
        },
      }

    case ActionTypes.SET_SUB_STATUS: {
      const { subscriptionActive } = payload
      return {
        ...state,
        CPA: {
          ...state.CPA,
          subscriptionActive,
        },
      }
    }

    case ActionTypes.SET_CPA_USER: {
      const { user, invitations, responseOk, cpaFirm } = payload

      return {
        ...state,
        user,
        CPA: {
          cpaFirm: Array.isArray(cpaFirm?.results) ? cpaFirm?.results[0]?.cpaFirm : undefined,
          userRole: Array.isArray(cpaFirm?.results) ? cpaFirm?.results[0]?.role : undefined,
          cpaUserInvites: {
            pending: getPenInvitationsCount(invitations?.results),
          },
          subscriptionActive: null,
        },
        onboardingFlow: {
          ...state.onboardingFlow,
          responseOk,
        },
      }
    }

    case ActionTypes.SET_ENTITY_USER: {
      const { user, entities, invitations, responseOk } = payload
      return {
        ...state,
        user,
        ENTITY: {
          INDVEntitiesCount: getINDVEntitiesCount(entities?.results),
          pendingInvitations: getPenInvitationsCount(invitations?.results),
        },
        onboardingFlow: {
          ...state.onboardingFlow,
          responseOk,
        },
      }
    }

    case ActionTypes.SET_ONBOARDING_FLOW_STATUS: {
      return {
        ...state,
        onboardingFlow: {
          ...state.onboardingFlow,
          status: payload,
        },
      }
    }

    case ActionTypes.SET_INVITATIONS_FLOW_STATUS: {
      return {
        ...state,
        invitationsFlow: {
          ...state.invitationsFlow,
          status: payload,
        },
      }
    }

    case ActionTypes.SET_ONBOARDING_STEP: {
      return {
        ...state,
        onboardingFlow: {
          ...state.onboardingFlow,
          step: getOnboardingStep(payload),
        },
      }
    }

    case ActionTypes.UPSERT_CPA_FIRM: {
      return {
        ...state,
        CPA: {
          ...state.CPA,
          cpaFirm: payload ? { ...state.CPA.cpaFirm, ...payload } : undefined,
        },
      }
    }

    case ActionTypes.SET_INVITE_INFO: {
      return {
        ...state,
        invitationsFlow: {
          ...state.invitationsFlow,
          ...payload,
        },
      }
    }

    case ActionTypes.SET_PENDING_INVITES: {
      return {
        ...state,
        invitations: {
          ...payload,
        },
      }
    }

    default:
      throw new Error(`Unhandled action type: ${type}`)
  }
}
