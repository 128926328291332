import { useState } from 'react'
import { Box, Flex } from 'reflexbox'
import { LoadingOutlined, MailOutlined } from '@ant-design/icons'
import cn from 'classnames'
import { truncate } from 'lodash'
import { formatDistance } from 'date-fns'
import { useTranslation } from 'react-i18next'

import useLocale from 'hooks/useLocale'

import { Button, TextField, Tag } from 'components'

import { IThread, useMessagesActions } from 'context/Messages'

import { ReactComponent as CommentIcon } from 'assets/images/comment.svg'

import styles from '../../dashboard.sidebar.module.scss'

type TProps = {
  thread: IThread
  activeThreadGuid: string
  setActiveThreadGuid: (guid: string) => void
  resetData: () => void
}

const Thread = ({ thread, activeThreadGuid, setActiveThreadGuid, resetData }: TProps) => {
  const { addThreadMessage, loading, response } = useMessagesActions()
  const { markThreadRead, response: responseRead } = useMessagesActions()
  const { loadUnreadMessages } = useMessagesActions()

  const resolved = thread?.isResolved
  const active = activeThreadGuid === thread?.guid
  const [unread, setUnread] = useState<boolean>(thread?.unreadMessageCount > 0)

  const {
    taxOrganizer: {
      entity: { title },
      taxYear,
    },
  } = thread

  const locale = useLocale()
  const { t } = useTranslation('dashboard', { keyPrefix: 'sidebar.thread' })
  const [message, setMessage] = useState<string>('')

  const handleChange = (e: any) => setMessage(e.target.value)
  const handleClickCreate = async (id: string) => {
    await addThreadMessage(id, message)
    if (response.ok) {
      setMessage('')
      resetData()
    }
  }
  const handleClickSetActive = async (guid: string) => {
    setActiveThreadGuid(guid)
    if (unread) {
      await markThreadRead(guid)
      if (responseRead.ok) {
        setUnread(false)
        loadUnreadMessages()
      }
    }
  }

  return (
    <>
      <Flex
        flexDirection={['column']}
        className={cn(
          styles.thread,
          active ? styles.thread_active : '',
          !active && unread ? styles.thread_unread : ''
        )}
        fontWeight={[!active && unread ? 'bold' : 'normal']}
        px={[3]}
        pt={[2]}
        pb={[3]}
        key={1}
        onClick={() => {
          handleClickSetActive(thread?.guid)
        }}
      >
        <Flex
          lineHeight={['20px']}
          fontSize={['16px']}
          alignItems={['start']}
          mt={['3px']}
          mb={['5px']}
          justifyContent={['space-between']}
        >
          <Box>{title}</Box>
          <Flex alignItems={['center']} className={styles.thread_header_year}>
            <Box fontSize={['8px']} mx={[2]}>
              &#9679;
            </Box>
            <Box>{taxYear}</Box>
          </Flex>
        </Flex>
        <Flex justifyContent={['space-between']} alignItems={['center']} mt={['3px']}>
          <Flex>
            <Tag color="transparent" className={cn(styles.thread_tag, styles.thread_tag_active)}>
              #{String(thread?.referenceNumber).padStart(3, '0')}
            </Tag>
            {!active && unread && (
              <Flex alignItems={['center']}>
                <Box fontSize={['12px']} ml={[1]} className={styles.text_purple}>
                  {t('unread')}
                </Box>
                <Box fontSize={['8px']} ml={[1]} className={styles.text_gray}>
                  &#9679;
                </Box>
              </Flex>
            )}
            {!unread && resolved && (
              <Flex alignItems={['center']}>
                <Box fontSize={['12px']} ml={[1]} className={styles.text_gray}>
                  {t('resolved')}
                </Box>
                <Box fontSize={['8px']} ml={[1]} className={styles.text_gray}>
                  &#9679;
                </Box>
              </Flex>
            )}
            <Box fontSize={['12px']} ml={[1]} className={styles.text_gray}>
              {formatDistance(new Date(thread?.lastReplyOn), new Date(), {
                addSuffix: true,
                locale,
              })}
            </Box>
          </Flex>
          <Box>
            <CommentIcon className={styles.thread_icon} />
          </Box>
        </Flex>
        <Flex mt={['10px']}>
          {active
            ? thread?.summary
            : thread?.summary
            ? truncate(String(thread?.summary), {
                length: 77,
              })
            : ''}
        </Flex>
        {active && (
          <Flex className={styles.input_group} mt={['16px']}>
            <TextField
              placeholder={t('reply')}
              size="middle"
              onChange={handleChange}
              className={styles.input_group_field}
              value={message}
            />
            <Button
              type="primary"
              ghost
              size="large"
              className={styles.input_group_button}
              onClick={() => handleClickCreate(thread?.guid)}
              disabled={loading || !message}
            >
              {loading ? <LoadingOutlined /> : <MailOutlined />}
            </Button>
          </Flex>
        )}
      </Flex>
    </>
  )
}

export default Thread
