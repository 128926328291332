import { useState } from 'react'
import { printPlugin } from '@react-pdf-viewer/print'
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation'
import { useResizeDetector } from 'react-resize-detector'
import PrintProgressBar from 'pages/TaxOrganizer/Document/components/DocumentView/components/PrintProgressBar'

const DEFAULT_PAGE = 1

export default () => {
  const { height, ref: documentRef } = useResizeDetector()
  const [documentWrapperHeight, setDocumentWrapperHeight] = useState<number | undefined>(undefined)
  const [pdfError, setPDFError] = useState(false)
  const [documentLoaded, setDocumentLoaded] = useState(false)
  const [activePage, setActivePage] = useState(DEFAULT_PAGE)
  const [scale, setScale] = useState<number | undefined>()
  const printPluginInstance = printPlugin({
    renderProgressBar: PrintProgressBar,
  })
  const pageNavigationPluginInstance = pageNavigationPlugin()
  const { jumpToPage } = pageNavigationPluginInstance

  return {
    height,
    documentRef,
    documentWrapperHeight,
    setDocumentWrapperHeight,
    pdfError,
    setPDFError,
    documentLoaded,
    setDocumentLoaded,
    activePage,
    setActivePage,
    scale,
    setScale,
    printPluginInstance,
    pageNavigationPluginInstance,
    jumpToPage,
  }
}
