import { useEffect } from 'react'
import { Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { Box } from 'reflexbox'

import { BodyUploadInvites, TFile } from 'context/TaxOrganizer'

import { Button } from 'components'
import Title from 'components/UploadModal/components/Title'
import StatusBar from 'components/UploadModal/components/StatusBar'
import styles from 'components/UploadModal/uploadmodal.module.scss'

import 'components/UploadModal/style.scss'

import useUploadDocument from '../hooks/useUploadDocument'

type Props = {
  fileList?: TFile[]
  onHide: () => void
  onFinishUploading?: () => void
  paramsFileNotAuthorize: Omit<BodyUploadInvites, 'file' | 'name'> & { invitationGuid: string }
  setFilesUploaded: (v: boolean) => void
}

const UploadRequestModal = ({
  fileList,
  onFinishUploading,
  onHide,
  paramsFileNotAuthorize,
  setFilesUploaded,
}: Props) => {
  const { t } = useTranslation('common', { keyPrefix: 'upload.modal' })
  const files = fileList?.slice() || []
  const { progress, uploadStatus } = useUploadDocument({
    files,
    paramsFileNotAuthorize,
    onFinishUploading,
  })

  useEffect(() => {
    if (!files.length) {
      onHide()
    }
  }, [files])

  useEffect(() => {
    if (progress.isFinished) {
      setFilesUploaded(true)
    }
  }, [progress.isFinished])

  const handleCancell = () => {
    if (uploadStatus) {
      onHide()
    }
  }

  return (
    <Modal
      visible
      title={<Title progress={progress} />}
      className={styles.modal}
      width={1180}
      destroyOnClose
      closable={uploadStatus === 'error'}
      onCancel={handleCancell}
      footer={[
        <Box mr={[3]} mb={[1]} key="submit-block">
          <Button
            key="submit"
            type="primary"
            ghost
            size="large"
            onClick={onHide}
            disabled={!progress.isFinished}
          >
            {t('done')}
          </Button>
        </Box>,
      ]}
    >
      <StatusBar progress={progress} totalFiles={files?.length ?? 0} />
    </Modal>
  )
}

export default UploadRequestModal
