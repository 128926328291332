import { createContext, useContext, Dispatch, FC, useReducer } from 'react'
import { useReducerLogger } from 'utils'

import { reducer, initialState } from './reducer'
import { TState, TAction } from './types'
import { ActionTypes } from './actions/action-types'

import useMessagesActions from './actions'

const MessagesContext = createContext<TState>({} as TState)
const MessagesDispatchContext = createContext<Dispatch<TAction>>(() => null)

const MessagesProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(useReducerLogger<TState, TAction>(reducer), initialState)

  return (
    <MessagesContext.Provider value={state!}>
      <MessagesDispatchContext.Provider value={dispatch}>
        {children}
      </MessagesDispatchContext.Provider>
    </MessagesContext.Provider>
  )
}

const useMessages = () => {
  const context = useContext(MessagesContext)
  if (context === undefined) {
    throw new Error('useMessages must be used within a ChestsProvider')
  }
  return context
}
const useMessagesDispatch = () => {
  const context = useContext(MessagesDispatchContext)
  if (context === undefined) {
    throw new Error('useMessagesDispatch must be used within a MessagesProvider')
  }
  return context
}

export { MessagesProvider, useMessages, useMessagesDispatch, ActionTypes, useMessagesActions }
