import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col, Tag } from 'antd'
import { FileOutlined, MessageOutlined } from '@ant-design/icons'
import cn from 'classnames'
import { SpinnerBackdrop } from 'components'

import { IThread, useMessages, useMessagesActions } from 'context/Messages'
import { useTaxOrganizer } from 'context/TaxOrganizer'

import Messages from '../Messages'
import AddMessage from '../AddMessage'
import Header from '../Header'

import styles from '../../tax.messages.module.scss'
import ThreadState from '../ThreadState'

const Threads = () => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'messages' })
  const { loadMessageThreads, loading } = useMessagesActions()
  const { taxOrganizer } = useTaxOrganizer()
  const { threads, threadFilter } = useMessages()
  const [currentThread, setCurrentThread] = useState<IThread>(threads[0])
  const [filteredThreads, setFilteredThreads] = useState<IThread[]>(threads)

  useEffect(() => {
    loadMessageThreads({ taxOrganizer: taxOrganizer.data.guid })
  }, [])

  const updateThreads = () => {
    loadMessageThreads({ taxOrganizer: taxOrganizer.data.guid })
  }

  useEffect(() => {
    switch (threadFilter) {
      case 'unresolved':
        setFilteredThreads(threads.filter((thread) => !thread.isResolved))
        return
      case 'resolved':
        setFilteredThreads(threads.filter((thread) => thread.isResolved))
        return
      case 'unread':
        setFilteredThreads(threads.filter((thread) => thread.unreadMessageCount > 0))
        return
      default:
        setFilteredThreads(threads)
    }
  }, [threadFilter, threads])

  useEffect(() => {
    setCurrentThread(filteredThreads[0])
  }, [filteredThreads])

  if (loading) {
    return <SpinnerBackdrop />
  }

  return (
    <Row>
      <Col xs={24} lg={7} className={styles.border}>
        <Header taxOrganizer={taxOrganizer.data.guid} />
        <div className={styles.threads}>
          {filteredThreads.length > 0 ? (
            filteredThreads.map((thread) => {
              return (
                <div
                  key={thread.guid}
                  className={cn(
                    styles.thread,
                    currentThread?.guid === thread.guid ? styles.active : '',
                    thread.unreadMessageCount > 0 ? styles.unread : ''
                  )}
                  onClick={() =>
                    setCurrentThread(filteredThreads.filter(({ guid }) => guid === thread.guid)[0])
                  }
                >
                  <div className={styles.thread_message}>
                    <div className={styles.thread_head}>
                      <Tag style={{ borderRadius: '10px', backgroundColor: 'white' }}>
                        #{String(thread.referenceNumber || 'New').padStart(3, '0')}
                      </Tag>
                      <ThreadState
                        unreadMessageCount={thread.unreadMessageCount}
                        lastReplyOn={thread.lastReplyOn}
                        isResolved={thread.isResolved}
                      />
                      <div className={styles.thread_right}>
                        {thread.context ? (
                          <FileOutlined
                            style={{ fontSize: '12px' }}
                            className={styles.thread_icon}
                          />
                        ) : (
                          <MessageOutlined
                            style={{ fontSize: '12px' }}
                            className={styles.thread_icon}
                          />
                        )}
                      </div>
                    </div>
                    <div className={styles.thread_text}>{thread.summary}</div>
                  </div>
                </div>
              )
            })
          ) : (
            <div className={styles.threads_empty}>{t('noThread')}</div>
          )}
        </div>
      </Col>
      <Col xs={24} lg={17} className={styles.thread_messages}>
        {currentThread && (
          <>
            <Messages
              key={currentThread?.guid}
              thread={currentThread}
              taxOrganizerGuid={taxOrganizer.data.guid}
            />
            <AddMessage
              updateThreads={updateThreads}
              threadId={currentThread?.guid}
              isResolved={currentThread?.isResolved}
              taxOrganizer={taxOrganizer.data.guid}
            />
          </>
        )}
      </Col>
    </Row>
  )
}

export default Threads
