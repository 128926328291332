import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { ColumnsType } from 'antd/lib/table'
import { getCategoryLabel } from 'utils/form'

import { useDashboard } from 'context/Dashboard'
import { useEntity, TTaxDocument } from 'context/Entity'

import scss from 'assets/styles/variables.module.scss'
import { DocName, Tags } from 'components/Table/helpers'

export default () => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'documents.columns' })
  const { categories, subCategories } = useDashboard()
  const {
    entity: {
      currentEntity: { guid: entityGuid },
    },
  } = useEntity()
  const minWidthSm = useMediaQuery({
    query: `(min-width: ${scss.sm})`,
  })

  const columns: ColumnsType<TTaxDocument> = [
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
      width: minWidthSm ? '25%' : '100%',
      render: (name: string, record: TTaxDocument) => (
        <DocName name={name} guid={record.guid} showTooltip entityGuid={entityGuid} />
      ),
    },
    {
      title: t('category'),
      dataIndex: 'category',
      key: 'category__title',
      width: '25%',
      render: (category: string) => getCategoryLabel(category, subCategories, categories),
    },
    {
      title: t('tags'),
      dataIndex: 'tags',
      key: 'tags__name',
      width: '25%',
      render: (tags: TOption[]) => {
        return <Tags tags={tags} />
      },
    },
    {
      title: <span className="text-nowrap">{t('taxOrganizers')}</span>,
      dataIndex: 'taxOrganizers',
      key: 'taxOrganizers',
      width: '25%',
      render: () =>
        // TODO: This should probably just show the tax years the document is associated with as a comma separated list
        [2021, 2022].join(', '),
    },
  ]

  return { columns }
}
