import { useEffect, useState } from 'react'
import { Form, Modal, Typography } from 'antd'
import { ValidateStatus } from 'antd/lib/form/FormItem'
import { Flex, Box } from 'reflexbox'
import { useTranslation } from 'react-i18next'
import Icon, { ExclamationCircleOutlined, FilePdfOutlined } from '@ant-design/icons'

import {
  useTaxOrganizer,
  UpdateDocumentParams,
  useDocumentsActions,
  TMultipleDocumentParams,
  TTaxDocumentExtended,
} from 'context/TaxOrganizer'
import { useDashboard } from 'context/Dashboard'

import { CategoryOption } from 'context/TaxOrganizer/reducers/documents'

import { ReactComponent as Trash } from 'assets/images/trash.svg'
import { ReactComponent as Search } from 'assets/images/search.svg'

import { Button } from 'components'
import SelectField, { tagRender } from 'components/Select'

import { getCategoryValue, getSubCategoryValue, getTagValues } from 'utils/form'

import colors from 'assets/styles/variables.module.scss'

import styles from '../../../uploadmodal.module.scss'

const { confirm } = Modal
const { Paragraph } = Typography

type TProps = {
  setFileCollapse: (value: boolean | ((prevState: boolean) => boolean)) => void
  fileUploaded: TTaxDocumentExtended | undefined
  onRemove: () => void
  setFileCategories: (value: any | ((prevState: any) => any)) => void
}

type FormValues = UpdateDocumentParams & { subCategory?: string }

const checkValidation = (params: TMultipleDocumentParams[], documentGuid: string) => {
  const doc = params.find((param: TMultipleDocumentParams) => param.guid === documentGuid)
  if (!doc) {
    return true
  }
  if (doc && doc.validateStatus !== 'error') {
    return true
  }
  return false
}

const Body = ({ setFileCollapse, fileUploaded, onRemove, setFileCategories }: TProps) => {
  const { t } = useTranslation('common')
  const { t: t2 } = useTranslation('taxOrganizer', { keyPrefix: 'documents' })
  const [errors, setErrors] = useState<{
    category: { validateStatus: ValidateStatus }
  }>({
    category: {
      validateStatus: '',
    },
  })
  const [form] = Form.useForm()
  const {
    taxOrganizer: {
      data: { guid },
    },
  } = useTaxOrganizer()
  const { updateDocument, response: responseUpdate, loading: loadingUpdate } = useDocumentsActions()
  const { setMulptipleDocumentParams, removeMulptipleDocumentParam } = useDocumentsActions()
  const {
    documents: { mulptipleDocumentParams },
  } = useTaxOrganizer()
  const { tags, categories, subCategories } = useDashboard()

  const getSubCategoriesOptions = (): CategoryOption[] => {
    return subCategories.filter((sub) => sub.parent.slug === form.getFieldValue('category'))
  }

  const [subCategoriesOptions, setSubCategoriesOptions] = useState<CategoryOption[]>(
    subCategories.filter((sub) => sub.parent.slug === form.getFieldValue('category'))
  )

  const handleValuesChange = (
    changedValues: Partial<UpdateDocumentParams>,
    { subCategory, ...values }: FormValues
  ) => {
    const params = {
      ...values,
      category: subCategory || values.category,
    }
    setSubCategoriesOptions(getSubCategoriesOptions())
    if (changedValues.category && subCategory) {
      form.setFieldsValue({ subCategory: undefined })
    }
    setMulptipleDocumentParams({
      ...params,
      guid: fileUploaded?.guid || '',
      validateStatus: params.category ? '' : 'error',
    })
  }

  const onSubmit = async ({ subCategory, ...values }: FormValues) => {
    if (!checkValidation(mulptipleDocumentParams, fileUploaded?.guid || '')) {
      setErrors({
        category: {
          validateStatus: 'error',
        },
      })
    } else {
      setErrors({
        category: {
          validateStatus: '',
        },
      })
      const params = {
        ...values,
        category: subCategory || values.category,
      }
      await updateDocument(guid, fileUploaded!.guid, params)
      if (responseUpdate.ok) {
        setFileCategories(params)
        setFileCollapse(false)
      }
    }
  }

  const initialValues = {
    category: getCategoryValue(fileUploaded?.category || '', subCategories, categories),
    subCategory: getSubCategoryValue(fileUploaded?.category, subCategories),
    tags: getTagValues(fileUploaded?.tags || []),
  }

  const handleRemoveFile = () => {
    confirm({
      maskClosable: true,
      title: t2('deleteConfirmation'),
      icon: <ExclamationCircleOutlined />,
      okText: t('switcher.yes'),
      okType: 'danger',
      cancelText: t('switcher.no'),
      onOk: onRemove,
    })
  }

  useEffect(() => {
    if (initialValues.category) {
      form.setFieldsValue(initialValues)
    }
    setSubCategoriesOptions(getSubCategoriesOptions())
  }, [fileUploaded, subCategories])

  useEffect(() => {
    const doc = mulptipleDocumentParams.find((_: any) => _?.guid === fileUploaded?.guid)
    const validateStatus = doc ? doc.validateStatus : ''
    setErrors({
      category: {
        validateStatus,
      },
    })
  }, [mulptipleDocumentParams])

  return (
    <>
      <Flex flexDirection={['column']} width={['100%', '100%', '60%']} px={['22px']} mt={['26px']}>
        <Flex
          flexDirection={['row']}
          justifyContent={['space-between']}
          alignItems={['baseline']}
          pb={['14px']}
          style={{ borderBottom: `1px solid ${colors.purpleGrey20}` }}
        >
          <Flex
            alignItems={['center']}
            fontSize={['16px']}
            fontWeight={[700]}
            lineHeight={['20px']}
          >
            <Box className={styles.text_gray} fontSize={['18px']}>
              {' '}
              <FilePdfOutlined />
            </Box>
            <Box ml={['5px']}>{fileUploaded?.name}</Box>
          </Flex>
          <Button type="link" icon={<Trash />} onClick={handleRemoveFile}>
            {t('upload.modal.delete')}
          </Button>
        </Flex>
        <Box mt={['26px']}>
          <Form
            form={form}
            className={styles.file_body}
            onValuesChange={handleValuesChange}
            onFinish={onSubmit}
          >
            <Flex flexDirection={['column']} width={['100%']}>
              {' '}
              <Flex flexDirection={['column', 'row', 'column', 'row']} width={['100%']}>
                <Flex flexDirection={['column']} width={['100%', '30%', '100%', '30%']}>
                  <Form.Item
                    name="category"
                    shouldUpdate
                    validateStatus={errors.category.validateStatus}
                    extra={
                      errors.category.validateStatus === 'error' && (
                        <Paragraph className="text-error">
                          {t('upload.modal.categoryRequired')}
                        </Paragraph>
                      )
                    }
                  >
                    <SelectField
                      dropdownMatchSelectWidth={false}
                      options={categories}
                      placeholder="Category"
                      onChange={(value) => {
                        setErrors({
                          category: {
                            validateStatus: value ? '' : 'error',
                          },
                        })
                      }}
                    />
                  </Form.Item>
                  <Box mt={[0, '5px', 0, '5px']}>
                    <Form.Item name="subCategory">
                      <SelectField
                        options={subCategoriesOptions}
                        placeholder={t('upload.modal.subcategory')}
                        disabled={!subCategoriesOptions.length}
                      />
                    </Form.Item>
                  </Box>
                </Flex>
                <Flex
                  flexDirection={['column']}
                  width={['100%', '70%', '100%', '70%']}
                  pl={[0, '24px', 0, '24px']}
                >
                  <Form.Item name="tags">
                    <SelectField
                      mode="multiple"
                      options={tags}
                      tagRender={tagRender}
                      optionFilterProp="children"
                      showSearch
                      showArrow
                      placeholder={t('upload.modal.tags')}
                      suffixIcon={
                        <Icon component={Search} style={{ fontSize: '14px', marginTop: -1 }} />
                      }
                    />
                  </Form.Item>
                </Flex>
              </Flex>
              <Flex
                flexDirection={['column', 'row', 'row']}
                justifyContent={['center', 'flex-end', 'flex-end']}
                mb={[3, 3, 3, 3]}
                mt={[3, 3, 3, 0]}
              >
                <Box width={['100%', '141px', '141px']}>
                  <Button
                    type="primary"
                    size="large"
                    block
                    ghost
                    onClick={() => {
                      removeMulptipleDocumentParam(fileUploaded!.guid)
                      setFileCollapse(false)
                    }}
                  >
                    {t('upload.modal.skip')}
                  </Button>
                </Box>
                <Box ml={[0, '10px', '17px']} mt={[2, 0, 0]} width={['100%', '141px', '141px']}>
                  <Button
                    htmlType="submit"
                    type="primary"
                    size="large"
                    block
                    loading={loadingUpdate}
                    disabled={loadingUpdate}
                  >
                    {t('upload.modal.save')}
                  </Button>
                </Box>
              </Flex>
            </Flex>
          </Form>
        </Box>
      </Flex>
    </>
  )
}

export default Body
