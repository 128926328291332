import { Col, Form, Select } from 'antd'
import { TTaxOrganizersParams, TTaxSummaryParams } from 'context/Dashboard'
import { DEFAULT_TAX_PARAMS } from 'pages/Dashboard/Dashboard/constants'
import { useTranslation } from 'react-i18next'
import useContacts from '../hooks/useContacts'

type TProps = {
  setSummaryParams: (
    value: TTaxSummaryParams | ((prevState: TTaxSummaryParams) => TTaxSummaryParams)
  ) => void
  setTaxParams: (
    value: TTaxOrganizersParams | ((prevState: TTaxOrganizersParams) => TTaxOrganizersParams)
  ) => void
  setShowTable: (state: boolean) => void
}

const Contacts = ({ setShowTable, setTaxParams, setSummaryParams }: TProps) => {
  const { cpaContactsOptions } = useContacts()
  const { t } = useTranslation('dashboard', { keyPrefix: 'info' })

  const handleCpaContactChange = (value: string) => {
    const cpaContact = value === 'default' ? undefined : value
    setShowTable(false)
    setTaxParams((prevParams: TTaxOrganizersParams) => ({
      ...prevParams,
      primaryCpaContact: cpaContact,
      phase: undefined,
      page: DEFAULT_TAX_PARAMS.page,
      pageSize: DEFAULT_TAX_PARAMS.pageSize,
    }))
    setSummaryParams((prevParams: TTaxSummaryParams) => ({
      ...prevParams,
      cpaContact,
      phase: undefined,
      page: DEFAULT_TAX_PARAMS.page,
      pageSize: DEFAULT_TAX_PARAMS.pageSize,
    }))
  }

  return (
    <Col xs={24} sm={12}>
      <Form.Item
        style={{ marginBottom: 0 }}
        name="primaryCpaContact"
        key="primaryCpaContact"
        initialValue="default"
      >
        <Select onChange={handleCpaContactChange}>
          <Select.Option key="default">
            <span>{t('allTaxPreparers')}</span>
          </Select.Option>
          {cpaContactsOptions.map((contact) => {
            return <Select.Option key={contact.value}>{contact.label}</Select.Option>
          })}
        </Select>
      </Form.Item>
    </Col>
  )
}

export default Contacts
