import { InviteMember, TCPAUserRoleWithPermission } from 'context/CPAFirm'
import { TFunction } from 'react-i18next'

export const getTaxFirmUserRoles = (
  cpaUserRoles: TCPAUserRoleWithPermission[],
  t: TFunction<'common'>
) => {
  return cpaUserRoles.map(({ role }) => ({
    label: t(`user.roles.${role}`),
    value: role,
  }))
}

export const getTaxFirmParams = (
  members: InviteMember[] | undefined,
  staticField: InviteMember | undefined
) => {
  if (!staticField) return []
  const { email, role, transferOwnership } = staticField
  return [
    {
      email,
      role,
      transferOwnership,
    },
    ...(members ?? []),
  ]
}
