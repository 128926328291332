import { useEffect } from 'react'

import { useDocumentsActions, useTaxOrganizer } from 'context/TaxOrganizer'

export default () => {
  const {
    taxOrganizer: {
      data: { guid },
    },
  } = useTaxOrganizer()
  const { loadDocumentsSummary } = useDocumentsActions()

  useEffect(() => {
    loadDocumentsSummary(guid)
  }, [])
}
