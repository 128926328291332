import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Empty } from 'antd'
import { Box } from 'reflexbox'
import { PlusOutlined } from '@ant-design/icons'
import cn from 'classnames'
import { getWithCurrentFirst } from 'utils/taxOrganizers'

import { TTaxOrganizersListItem } from 'context/Dashboard'
import { TEntity } from 'context/Entity'

import useDate from 'hooks/useDate'

import { Modal } from 'components'
import TaxOrganizerPhaseWidget from './TaxOrganizerPhaseWidget'
import AddTaxOrganizerForm from './AddTaxOrganizerForm'

import styles from '../../../dashboard.entity.management.module.scss'

type Props = {
  taxOrganizers: TTaxOrganizersListItem[]
  entity: TEntity
  reloadTaxOrganizers: () => void
}

const TaxOrganizers = ({ taxOrganizers, entity, reloadTaxOrganizers }: Props) => {
  const [popoverVisible, setPopoverVisible] = useState(false)
  const { t } = useTranslation('taxOrganizer')
  const { t: t2 } = useTranslation('common')
  const { t: t3 } = useTranslation('entity')
  const { getDate } = useDate()

  return (
    <>
      <div className={styles.organizers_header}>
        <div>
          {!!taxOrganizers.length && (
            <Box fontSize={['20px']} fontWeight={['600']}>
              {t('navigation.taxOrganizers')}
            </Box>
          )}
        </div>

        <Modal
          placement="bottom"
          content={
            <AddTaxOrganizerForm
              entity={entity}
              setVisible={setPopoverVisible}
              reloadTaxOrganizers={reloadTaxOrganizers}
            />
          }
          destroyTooltipOnHide
          trigger="click"
          visible={popoverVisible}
          onVisibleChange={(visible) => setPopoverVisible(visible)}
        >
          <Button type="primary" icon={<PlusOutlined />}>
            {t2('buttons.add')}
          </Button>
        </Modal>
      </div>
      <div>
        {!taxOrganizers.length && (
          <Empty description={<>{t3('common.emptyOrganizersMessage')}</>} />
        )}
        {getWithCurrentFirst(taxOrganizers).map(
          ({ phase, guid, taxYear, deadline, current }, i) => {
            const dt = new Date(deadline?.dueDate)
            const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000)
            return (
              <div
                key={guid + String(current)}
                className={cn([
                  styles.organizers_row,
                  i === 0 && current ? styles.organizers_current : '',
                ])}
              >
                {i === 0 && current && (
                  <span className={styles.organizers_label}>{t2('current')}</span>
                )}
                <div className={styles.organizers_tax_year}>{taxYear}</div>
                <div className={styles.organizers_row_underlined}>
                  <div>
                    {t(`overview.phases.${phase.toLowerCase()}`)}
                    {deadline?.dueDate &&
                      `, ${t2('date.dueBy')} ${getDate(dtDateOnly, 'MM/dd/yy')}`}
                  </div>
                  <div>
                    <TaxOrganizerPhaseWidget phase={phase} guid={guid} />
                  </div>
                </div>
              </div>
            )
          }
        )}
      </div>
    </>
  )
}

export default TaxOrganizers
