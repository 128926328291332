import { TEntityUserInvite } from 'context/Invites/types'
import { TFunction } from 'react-i18next'
import { ValidateStatus } from 'antd/lib/form/FormItem'
import { UploadFile } from 'antd/lib/upload/interface'
import { TCPAFirmAssociatedUser, TJobTitle } from 'context/Dashboard'
import { EnumTinKind } from '../EntityCPAs'
import { ActionTypes } from './actions'

export type TEntity = {
  address: TAddress
  businessEndedDate: string | null
  businessStartedDate: string | null
  createdBy: IContact
  guid: string
  kind: {
    label: string
    title: string
  }
  naicsCode?: string | null
  totalAssets?: string
  primaryContact: IContact
  tin: string
  tinKind?: EnumTinKind
  title: string
  cpaContact: ICpaContact | null
  cpaFirm: TCpaFirm
  isLocked: boolean
}

export type TEntityKind = {
  label: string
  title: string
}

export enum EnumEntityKinds {
  TRST = 'TRST',
  'S-CO' = 'S-CO',
  PART = 'PART',
  LLCS = 'LLCS',
  LLCP = 'LLCP',
  LLCC = 'LLCC',
  INDV = 'INDV',
  EXMT = 'EXMT',
  'C-CO' = 'C-CO',
  BENP = 'BENP',
}

export enum EnumEntityUserRoles {
  OWNER = 'OWN',
  ADMIN = 'ADM',
  MEMBER = 'MEM',
}

export type ErrorResponse = { [key: string]: Array<string> }

export type EntityEditForm = {
  tin: string
  title: string
  kind: EnumEntityKinds
  businessStartedDate: string | null
  tinKind: string
  address: string
  naicsCode: string
  totalAssets: string
}

export type EntityUserInviteForm = {
  email: string
  role?: EnumEntityUserRoles
  message?: string
  transferOwnership?: boolean
}

export type TaxOrganizerForm = {
  entity: string
  taxYear: number
}

export type EntityTaxOrganizerForm = {
  taxYear: number
}

export type TaxPreparerInviteForm = {
  email: string
  message?: string
}

export type TEntityDocumentParams = TParams & { taxYear?: number }

export type TEntityUserPermissions = {
  label: string
  role: EnumEntityUserRoles
  canAssign: boolean
}

export const getTinKinds = () => {
  return Object.values(EnumTinKind).map((value) => ({
    label: value,
    value,
  }))
}

export const getEntityUserRolesLocal = (t: TFunction<'entity'>) => {
  return Object.values(EnumEntityUserRoles).map((value) => ({
    label: t(`userRole.${value}`),
    value,
  }))
}

export type TCPAContact = {
  guid: string
  email: string
  firstName?: string
  lastName?: string
  phone?: string
  avatar?: string | null
}

export type TCPAEntityContact = {
  user: TCPAContact
  isPrimary: boolean
  jobTitle: TJobTitle
}

export type UploadedBy = {
  guid: string
  firstName: string
  lastName: string
}

export type DocumentTag = {
  slug: string
  name: string
}

export type TTaxDocument = {
  guid: string
  name: string
  category: string
  status: 'PEN' | 'REA' | 'DEL' | 'FAI' | 'UPD'
  file: string | null
  presignFilePath: string | null
  pageCount: number | null
  fileSize: number | null
  version?: number
  year: number | null
  tags: TTags[]
  createdOn: string
  updatedOn: string
  uploadedBy: UploadedBy
  fileUrl?: string
  afterTaxOrganizerLock: boolean
}

export type EntityTaxOrganizer = {
  taxOrganizerGuid: string
  taxYear: string
  isLocked: boolean
}

export type TEntityDocument = {
  guid: string
  name: string
  category: string
  status: 'PEN' | 'REA' | 'DEL' | 'FAI' | 'UPD'
  file: string | null
  presignFilePath: string | null
  pageCount: number | null
  fileSize: number | null
  version?: number
  year: number | null
  tags: TTags[]
  createdOn: string
  updatedOn: string
  uploadedBy: UploadedBy
  fileUrl?: string
  afterTaxOrganizerLock: boolean
  taxOrganizers: EntityTaxOrganizer[]
}

export type IEntityState = {
  entities: {
    data: TEntity[]
    total: number
  }
  currentEntity: TEntity
  users: {
    data: Array<ICpaContact>
    total: number
  }
  userInvites: {
    total: number
    data: TEntityUserInvite[]
  }
  kinds: TOption[]
  cpaContacts: {
    count: number
    data: TCPAEntityContact[]
    page: number
  }
  entitiesPrimaryContacts: IContact[]
  documents: {
    data: TTaxDocument[]
    total: number
  }
  cpaAssocitatedUsers: {
    data: TCPAFirmAssociatedUser[]
    count: number
  }
  currentUserRole: EnumEntityUserRoles | undefined
  currentUserPermissions: TEntityUserPermissions[]
  currentEntityDocument: TEntityDocument
}

export type TDocumentsSummary = {
  total: number
  untagged: number
  uncategorized: number
  requiresAction: number
}

export type UpdateDocumentParams = {
  name?: string
  category?: string
  tags?: string[]
  year?: number
  taxOrganizers?: EntityTaxOrganizer[]
}

export type TMultipleDocumentParams = UpdateDocumentParams & {
  guid: string
  validateStatus: ValidateStatus
}

export type TTaxDocumentExtended = TTaxDocument & {
  uid?: string
  loading?: boolean
  responseOk?: boolean
}

export type IDocumentsState = {
  data: TTaxDocument[]
  temporaryUploaded: TTaxDocumentExtended[]
  dataRequiresAction: TTaxDocument[]
  count: number
  selectedDocumentIds: string[]
  documentsSummary: TDocumentsSummary
  mulptipleDocumentParams: TMultipleDocumentParams[]
}

export type RequestFormDetails = {
  email: string
  firstName: string
  lastName: string
}

export type BodyUploadInvites = RequestFormDetails & { token: string; file: string; name: string }

export type TFileUploadOverrideProps = {
  uploadUrl?: string
  deleteUrl?: string
  updateUrl?: string
}

export type TLoadDocumentsParams = {
  category?: string
  name?: string
  ordering?: string
  page?: number
  pageSize?: number
  requiresAction?: boolean
  tags?: string
  search?: string
}

export type TUploadDocumentParams = {
  name: string
  category?: string
  year?: number
  tags?: string | string[]
  unregisteredUserEmail?: string | null
  file: string | null
  tqQuestion?: string
}

export type TUploadDocumentResponse = {
  category: string | null
  file: string
  fileUrl: string
  guid: string
  name: string
  presignFilePath: string
  status: string
  taxOrganizer: string
  uploadUrl: string
  year: number
  tags?: { slug: string; name: string }[]
}

export type TFile = UploadFile

export type IAction = { type: keyof typeof ActionTypes; payload?: any }
