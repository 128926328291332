import { useEffect } from 'react'
import { useDrop } from 'react-dnd'
import { Box } from 'reflexbox'

import { Button, Modal } from 'components'

import { TChildCategories } from 'context/TaxReturns'

import colors from 'assets/styles/variables.module.scss'
import styles from '../../../categorize.module.scss'

import ModalSubCategories from './ModalSubCategories'

type TProps = {
  subCategory: TChildCategories[]
  state: string
  hoverCurrentState: string
  setCurrentState: (state: string) => void
}

const StateSubCategory = ({ subCategory, state, hoverCurrentState, setCurrentState }: TProps) => {
  const [{ isOverCurrent, didDropCurrent, canDrop }, drop] = useDrop(
    () => ({
      accept: 'categorize',
      drop: () => null,
      collect: (monitor) => ({
        isOverCurrent: monitor.isOver({ shallow: true }),
        didDropCurrent: monitor.didDrop(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [state]
  )

  useEffect(() => {
    if (isOverCurrent) {
      setCurrentState(state)
    }
  }, [isOverCurrent])

  useEffect(() => {
    if (didDropCurrent || canDrop) {
      setCurrentState('')
    }
  }, [didDropCurrent, canDrop])

  return (
    <Modal
      placement="bottom"
      content={<ModalSubCategories state={state} subCategories={subCategory} />}
      destroyTooltipOnHide
      overlayInnerStyle={{
        backgroundColor: 'oldlace',
        border: `1px solid ${colors.docsStatesBorder}`,
        borderRadius: '5px',
      }}
      openClassName={styles.popup_tag}
      visible={!!hoverCurrentState && hoverCurrentState === state && canDrop}
    >
      <Box ref={drop} mx={[1]} mr={[0, 0, 2]} width={['70px']}>
        <Button
          size="large"
          ghost
          block
          type="primary"
          style={{
            background: 'transparent',
            border: `1px solid ${colors.docsStatesBorder}`,
            color: 'black',
          }}
        >
          {state}
        </Button>
      </Box>
    </Modal>
  )
}

export default StateSubCategory
