export enum ActionTypes {
  LOAD_DOCUMENT = 'LOAD_DOCUMENT',
  LOAD_THREADS = 'LOAD_THREADS',
  CREATE_THREAD = 'CREATE_THREAD',
  UPDATE_THREAD = 'UPDATE_THREAD',
  SET_COMMENT_MODE = 'SET_COMMENT_MODE',
  SET_NEW_THREAD = 'SET_NEW_THREAD',
}

export type CreateThreadParams = {
  page: number
  x: number
  y: number
  message: string
  taxReturnGuid?: string
  taxReturnPageGuid?: string
}
