import { Empty, Tabs } from 'antd'
import { useTranslation } from 'react-i18next'
import { useState, useRef } from 'react'
import { Flex } from 'reflexbox'

import { useTaxOrganizer } from 'context/TaxOrganizer'
import { useQuestions, useQuestionsActions, TQCategoryType } from 'context/Questions'

import { Badge, Button, Section, SpinnerBackdrop } from 'components'
import { PrinterOutlined } from '@ant-design/icons'
import TaxQuestions from './components/TaxQuestions'

import styles from './tax.question.module.scss'
import Print from './components/TaxQuestions/components/Print'
import usePrint from './hooks/usePrint'

const { TabPane } = Tabs

const TabTitle = ({ title, count }: { title: string; count: number }) => (
  <Badge count={count} offset={[12, -3]} size="small" scheme="purple">
    {title}
  </Badge>
)

type TProps = { loading: boolean }

const DashboardQuestionnaire = ({ loading }: TProps) => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'questionnaire' })
  const { t: t2 } = useTranslation('common')
  const componentRef = useRef<HTMLDivElement | null>(null)
  const {
    taxOrganizer: {
      data: {
        guid,
        entity: { kind },
        taxYear,
      },
    },
  } = useTaxOrganizer()
  const {
    state: { questions, categories },
  } = useQuestions()
  const { loadTQCategories, loadQuestions, loading: loading2 } = useQuestionsActions()
  const [activeCategory, setActiveCategory] = useState<string>('')
  const { startPrinting, loading: loadingDataToPrint, resume } = usePrint({ componentRef })

  const handleChange = async (activeKey: string) => {
    setActiveCategory(activeKey)
    await loadTQCategories(guid, {
      entityKind: kind.label,
      taxYear,
    })
    await loadQuestions({
      category: activeKey,
      taxOrganizer: guid,
      entityKind: kind.label,
      taxYear,
    })
    await loadQuestions({
      taxOrganizer: guid,
      entityKind: kind.label,
      taxYear,
    })
  }

  return (
    <>
      {' '}
      <Section titleIndent={20}>
        {(loading || loading2) && <SpinnerBackdrop />}
        {categories && categories?.length ? (
          <>
            <Tabs className={styles.tabs} onChange={handleChange} style={{ zIndex: 1 }}>
              {categories?.map((category: TQCategoryType) => {
                return (
                  <TabPane
                    tab={
                      <TabTitle
                        title={t(`tabs.${category.name.toLowerCase()}`, category.name)}
                        count={category.unansweredQuestions}
                      />
                    }
                    key={category.guid}
                  >
                    <TaxQuestions questions={questions} activeCategory={activeCategory} />
                  </TabPane>
                )
              })}
            </Tabs>
            <Button
              icon={<PrinterOutlined />}
              style={{ position: 'absolute', top: '10px', right: '10px', zIndex: 10 }}
              title={t2('print')}
              onClick={async () => {
                startPrinting()
              }}
              disabled={loadingDataToPrint}
              loading={loadingDataToPrint}
            />
          </>
        ) : (
          <Flex width={['100%']} justifyContent={['center']}>
            <Empty description={<>{t2('noData')}</>} />
          </Flex>
        )}
      </Section>
      {resume && <Print dataToPrint={resume} ref={componentRef} />}
    </>
  )
}

export default DashboardQuestionnaire
