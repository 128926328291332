import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { Flex } from 'reflexbox'
import { DownOutlined, UpOutlined } from '@ant-design/icons'

import { TEntity } from 'context/Entity'
import { TCPAEntityContact } from 'context/TaxOrganizer'

import { DEFAULT_TAX_TEAM_COUNT } from '../../constants'
import usePrimaryContact from '../../hooks/usePrimaryContact'

import LogoName from '../LogoName'
import PrimaryContact from '../PrimaryContact'
import CPAEntityContact from '../CPAEntityContact'
import EditTaxTeam from '../EditTaxTeam'

import styles from '../../taxteam.module.scss'

type PropsType = {
  editable?: boolean
  data: TCPAEntityContact[]
  count: number
  cpaFirm: TCpaFirm
  entity: TEntity
}

const TaxTeam = ({ data, count, cpaFirm, entity, editable }: PropsType) => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'overview.contacts' })
  const { primaryContact, formattedContacts, showCount, setShowCount } = usePrimaryContact({ data })
  return (
    <Flex flexDirection={['column']} width={['100%']}>
      <LogoName cpaFirm={cpaFirm} />
      <Flex flexDirection={['column']} flexShrink={[1]} px={['32px']} pt={['12px']} pb={['22px']}>
        {primaryContact && <PrimaryContact user={primaryContact.user} />}
        <Flex flexDirection={['column']} mt={['24px']}>
          <Flex justifyContent={['space-between']} width={['100%']}>
            <Flex
              fontSize={['16px']}
              fontWeight={[700]}
              justifyContent={['space-between']}
              alignItems={['center']}
              width={['100%']}
            >
              <div>
                {t('team')} ({count})
              </div>{' '}
              {editable && <EditTaxTeam entity={entity} cpaFirm={cpaFirm} />}
            </Flex>
            {count <= DEFAULT_TAX_TEAM_COUNT ? (
              ''
            ) : showCount !== count ? (
              <Button onClick={() => setShowCount(count)} type="link" className={styles.button}>
                {t('seeAll')}
              </Button>
            ) : (
              <Button
                onClick={() => setShowCount(DEFAULT_TAX_TEAM_COUNT)}
                type="link"
                className={styles.button}
              >
                {t('seeLess')}
              </Button>
            )}
          </Flex>
          <Flex
            flexWrap={['wrap']}
            flexDirection={['column', 'row', 'column', 'row']}
            justifyContent={['space-between']}
            mt={['16px']}
          >
            {formattedContacts?.map(({ user, jobTitle }: TCPAEntityContact) => {
              return <CPAEntityContact user={user} jobTitle={jobTitle} />
            })}
            {count <= DEFAULT_TAX_TEAM_COUNT ? null : showCount !== count ? (
              <Flex justifyContent={['center']} width={['100%']}>
                <Button
                  type="link"
                  icon={<DownOutlined />}
                  title={t('seeAll')}
                  onClick={() => setShowCount(count)}
                />
              </Flex>
            ) : (
              <Flex justifyContent={['center']} width={['100%']}>
                <Button
                  type="text"
                  icon={<UpOutlined />}
                  title={t('seeLess')}
                  onClick={() => setShowCount(DEFAULT_TAX_TEAM_COUNT)}
                />
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default TaxTeam
