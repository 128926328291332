import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col } from 'antd'
import { MailOutlined, BellOutlined } from '@ant-design/icons'

import { useAuth, useAuthActions } from 'context/Auth'
import { useMessages, useMessagesActions } from 'context/Messages'
import { useNotifications, useNotificationsActions } from 'context/Notifications'

import { Badge, UserDropdown } from 'components'

import { ReactComponent as Logo } from 'assets/images/logo.svg'

import Navigation from '../Navigation'
import SupportDropdown from './components/SupportDropdown'
import Search from './components/Search'

import styles from './dashboard.header.module.scss'
import MaskBanner from './components/MaskBanner'

export type TSidebar = 'MESSAGES' | 'NOTIFICATIONS'

type TProps = {
  sidebarCollapsed: boolean
  selectedSidebar: TSidebar
  setCollapsed: (value: boolean) => void
  sidebarChange: (type: TSidebar) => void
}

const DashboardHeader = ({
  sidebarCollapsed,
  selectedSidebar,
  setCollapsed,
  sidebarChange,
}: TProps) => {
  const { maskedToken, user } = useAuth()
  const { getUserInvites } = useAuthActions()
  const { loadUnreadMessages } = useMessagesActions()
  const { loadUnreadNotifications } = useNotificationsActions()
  const { totalUnreadThreads } = useMessages()
  const { totalUnread: totalUnreadNotifications } = useNotifications()

  useEffect(() => {
    loadUnreadMessages()
    loadUnreadNotifications()
    getUserInvites()
  }, [])

  const clickButtonHandler = (type: TSidebar) => {
    setCollapsed(false)
    if (selectedSidebar !== type) {
      sidebarChange(type)
    }
  }

  const clickHeaderHandler = () => {
    if (!sidebarCollapsed) {
      setCollapsed(true)
    }
  }

  return (
    <div className="container-xxl">
      {maskedToken && user && <MaskBanner />}
      <div className={styles.header} onClick={clickHeaderHandler}>
        <Row gutter={[32, 20]} align="middle" style={{ justifyContent: 'center' }}>
          <Col className={styles.logo}>
            <Link to="/">
              <Logo />
            </Link>
          </Col>
          <Col flex="auto">
            <Navigation />
          </Col>
          <Col>
            <Search />
          </Col>
          <Col className={styles.user_col}>
            <div className={styles.user_panel}>
              <Badge count={totalUnreadThreads} scheme="purple" size="small" offset={[0, 2]}>
                <MailOutlined
                  className={styles.mail_icon}
                  onClick={() => clickButtonHandler('MESSAGES')}
                />
              </Badge>
              <Badge count={totalUnreadNotifications} scheme="purple" size="small" offset={[0, 2]}>
                <BellOutlined
                  className={styles.bell_icon}
                  onClick={() => clickButtonHandler('NOTIFICATIONS')}
                />
              </Badge>
              <SupportDropdown />
              <UserDropdown />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default DashboardHeader
