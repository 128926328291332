import { SetStateAction, useCallback, useMemo } from 'react'
import { Box, Flex } from 'reflexbox'
import { Form, FormInstance } from 'antd'
import { keys, omit } from 'lodash'
import { useTranslation } from 'react-i18next'

import { useDashboard } from 'context/Dashboard'
import { TLoadDocumentsParams } from 'context/TaxOrganizer'

import { Button, Select } from 'components'

import { TFilters } from 'pages/TaxOrganizer/Documents/components/types'
import {
  DEFAULT_FILTERS,
  DEFAULT_PARAMS,
  FILTERS,
} from 'pages/TaxOrganizer/Documents/components/contstants'

type TProps = {
  form: FormInstance
  setParams: (params: SetStateAction<TLoadDocumentsParams>) => void
  setModalFiltersVisible: (visible: boolean) => void
  disableButton: boolean
  filters: TFilters
  setFilters: (filters: SetStateAction<TFilters>) => void
  setCurrentPage: (page?: number) => void
}

const FiltersModalContent = ({
  form,
  setParams,
  setModalFiltersVisible,
  disableButton,
  filters,
  setFilters,
  setCurrentPage,
}: TProps) => {
  const { t } = useTranslation(['common', 'taxOrganizer'])
  const { t: t2 } = useTranslation('common', { keyPrefix: 'select' })
  const { tags: tagOptions, categories, subCategories } = useDashboard()

  const subCategoryOptions = useMemo(
    () =>
      filters?.category ? subCategories.filter((cat) => cat.parent.slug === filters?.category) : [],
    [filters?.category]
  )

  const setFirstPageParams = useCallback(
    (prevParams: TLoadDocumentsParams) => ({ ...prevParams, page: DEFAULT_PARAMS.page }),
    [filters]
  )

  const handleClearSelect = (key: string) =>
    setFilters((prevFilters: TFilters) => omit(prevFilters, [key]))

  const handleSelect = (newFilter: TFilters) => {
    const key = keys(newFilter)[0]
    if (!newFilter[key as keyof TFilters]) return
    setFilters((prevFilters: TFilters) => ({
      ...prevFilters,
      ...newFilter,
    }))
  }

  const handleApplyForm = () => {
    setModalFiltersVisible(false)
    setCurrentPage(DEFAULT_PARAMS.page)
    setParams((prevParams: TLoadDocumentsParams) => ({
      ...omit(setFirstPageParams(prevParams), FILTERS),
      ...filters,
    }))
  }

  const handleApplyClearForm = () => {
    form.resetFields()
    setFilters(DEFAULT_FILTERS)
    setCurrentPage(DEFAULT_PARAMS.page)
    setModalFiltersVisible(false)
    setParams((prevParams: TLoadDocumentsParams) => omit(setFirstPageParams(prevParams), FILTERS))
  }

  const handleValuesChange = (valueObj: { [key: string]: string }) => {
    const key = keys(valueObj)[0]
    if (!valueObj[key]) {
      setFilters((prevFilters: TFilters) => omit(prevFilters, [key]))
    }
  }

  return (
    <>
      <Form form={form} onValuesChange={handleValuesChange}>
        <Flex flexDirection={['column']} p={[2]} minWidth={['200px']}>
          <Box mb={[1]}>
            <Form.Item name="category" key="category" style={{ margin: 0 }}>
              <Select
                form={form}
                placeholder={t('placeholders.category')}
                options={categories}
                allowClearOption
                dropdownMatchSelectWidth={false}
                onSelect={(category: string) => handleSelect({ category })}
                onClear={() => handleClearSelect('category')}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Box>

          {!!subCategoryOptions.length && (
            <Box mb={[1]}>
              <Form.Item name="subCcategory" key="subCcategory" style={{ margin: 0 }}>
                <Select
                  placeholder={t('placeholders.subCategory')}
                  options={subCategoryOptions}
                  form={form}
                  allowClearOption
                  dropdownMatchSelectWidth={false}
                  style={{ width: '100%' }}
                  disabled
                />
              </Form.Item>
            </Box>
          )}

          <Box mb={[3]} width={['100%']}>
            <Form.Item name="tags" key="tags" style={{ margin: 0 }}>
              <Select
                placeholder={t('placeholders.tags')}
                options={tagOptions}
                allowClearOption
                form={form}
                value={filters.tags}
                dropdownMatchSelectWidth={false}
                onSelect={(tags: string) => handleSelect({ tags })}
                onClear={() => handleClearSelect('tags')}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Box>
          <Box width={['100%']} mb={[1]}>
            <Button block onClick={handleApplyForm} type="primary" disabled={disableButton}>
              {t2('apply')}
            </Button>
          </Box>
          <Box width={['100%']}>
            <Button block onClick={handleApplyClearForm} disabled={disableButton}>
              {t2('clearAll')}
            </Button>
          </Box>
        </Flex>
      </Form>
    </>
  )
}

export default FiltersModalContent
