import { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Row, Col, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { Box, Flex } from 'reflexbox'
import countryCodes from 'country-list'
import { getAddressComponentByType } from 'utils'
import { getCPAOnboardingPath } from 'utils/routes'
import { states } from 'constants/address'

import { useAuth, useAuthActions } from 'context/Auth'

import { Alert, Select, TextField, Button, PhoneInput, GoogleAddressLookup } from 'components'
import Title from '../components/Title'

const Setup = () => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'firm' })
  const { t: t2 } = useTranslation('common', { keyPrefix: 'form' })
  const {
    CPA: { cpaFirm: firm },
  } = useAuth()
  const [form] = Form.useForm()
  const { createCpaFirm, loading, response, setOnboardingStep } = useAuthActions()
  const [errors, setErrors] = useState({} as TCpaFirm)
  const history = useHistory()
  const required = {
    required: true,
    message: t2('required'),
  }

  const initialValues = useMemo(
    () => ({
      ...firm,
      address: {
        country: 'US',
      },
    }),
    [firm]
  )
  const statesOptions = useMemo(
    () =>
      states.map((state) => ({
        ...state,
        label: `${state.label} (${state.value})`,
      })),
    []
  )

  const countryOptions = Object.entries(countryCodes.getCodeList()).map(([code, name]) => ({
    value: code.toUpperCase(),
    label: name,
  }))

  const onSubmit = async (values: Partial<TCpaFirm>) => {
    await createCpaFirm(values)
    if (!response.ok) {
      setErrors(response.data)
    }
  }

  useEffect(() => {
    if (firm) {
      setOnboardingStep('LOGO')
      history.replace(getCPAOnboardingPath('LOGO'))
    }
  }, [firm])

  useEffect(() => {
    form.resetFields()
  }, [initialValues])

  return (
    <>
      <Box mb={[3, 5]}>
        <Title title={t('onboarding.setup.title')} subtitles={[t('onboarding.setup.subtitle')]} />
        <Form form={form} initialValues={initialValues} layout="vertical" onFinish={onSubmit}>
          <Row gutter={[20, 0]}>
            <Col xs={24} md={12}>
              <Form.Item
                name="name"
                label={t('form.name')}
                rules={[required]}
                validateStatus={errors?.name && 'error'}
                help={errors?.name?.[0]}
              >
                <TextField />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[20, 0]}>
            <Col xs={24} md={12}>
              <Form.Item noStyle shouldUpdate>
                {(props) => (
                  <Form.Item
                    name={['address', 'line1']}
                    label={t('form.address1')}
                    rules={[required]}
                  >
                    <GoogleAddressLookup
                      onSelect={(addressComponents) => {
                        props.setFieldsValue({
                          // Geocoding reference: https://developers.google.com/maps/documentation/geocoding/requests-geocoding#Types
                          address: {
                            line1: getAddressComponentByType(addressComponents, 'streetAddress'),
                            city: getAddressComponentByType(addressComponents, 'locality'),
                            zipCode: getAddressComponentByType(addressComponents, 'postal_code'),
                            state: getAddressComponentByType(
                              addressComponents,
                              'administrative_area_level_1'
                            ),
                            country: getAddressComponentByType(addressComponents, 'country'),
                          },
                        })
                      }}
                    />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item name={['address', 'line2']} label={t('form.address2')}>
                <TextField />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item name={['address', 'line3']} label={t('form.address3')}>
                <TextField />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item name={['address', 'city']} label={t('form.city')} rules={[required]}>
                <TextField />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Row gutter={[16, 0]}>
                <Col xs={12}>
                  <Form.Item name={['address', 'state']} label={t('form.state')} rules={[required]}>
                    <Select options={statesOptions} />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    name={['address', 'zipCode']}
                    label={t('form.zipCode')}
                    rules={[required, { max: 6, message: t('form.invalidZipCodeLength') }]}
                    validateStatus={errors?.address?.zipCode && 'error'}
                    help={errors?.address?.zipCode}
                  >
                    <TextField />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item name={['address', 'country']} label={t('form.country')} rules={[required]}>
                <Select options={countryOptions} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <PhoneInput
                name="phone"
                label={t('form.phone')}
                validateStatus={errors?.phone && 'error'}
                help={errors?.phone}
                initialValue={firm?.phone}
              />
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="email"
                label={t('form.email')}
                rules={[{ type: 'email', message: t('form.invalidEmail') }]}
                validateStatus={errors?.email && 'error'}
                help={errors?.email}
              >
                <TextField />
              </Form.Item>
            </Col>
          </Row>
          {response.ok && (
            <Box my={[2]}>
              <Alert type="success" message={t('form.updateSuccess')} />
            </Box>
          )}
          <Flex width={['100%']} justifyContent={['flex-end']}>
            {' '}
            <Box width={['100%', '190px']} mt={[2]}>
              {' '}
              <Button htmlType="submit" type="primary" size="large" loading={loading} block>
                {t('form.continue')}
              </Button>
            </Box>
          </Flex>
        </Form>
      </Box>
    </>
  )
}

export default Setup
