import { Box } from 'reflexbox'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'

import { PAGE_SIZE } from 'constants/table'

import useUserType from 'hooks/useUserType'

import { Table } from 'components'

import { useInvite, useInviteActions } from 'context/Invites'

import useTableData from './hooks/useColumns'
import useCPAColumns from './hooks/useCPAColumns'

const DEFAULT_PARAMS: Readonly<TParams> = {
  page: 1,
  pageSize: PAGE_SIZE,
}

const You = () => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'clients.titles' })
  const { columns } = useTableData()
  const { columns: cpaColumns } = useCPAColumns()
  const { entityUserInvites, cpaUserInvites } = useInvite()
  const { loadEntityUserInvitations, loadCPAUserInvitations, loading } = useInviteActions()
  const [params, setParams] = useState<TLoadEntitiesParams>(DEFAULT_PARAMS)
  const [currentPage, setCurrentPage] = useState<number | undefined>(DEFAULT_PARAMS.page)
  const userType = useUserType()

  const handlePageChange = (page: number) => {
    setParams((prevParams: TLoadEntitiesParams) => {
      setCurrentPage(page)
      return { ...prevParams, page }
    })
  }

  useEffect(() => {
    if (userType === 'ENTITY') loadEntityUserInvitations(params)
    else loadCPAUserInvitations(params)
  }, [params])

  return (
    <>
      <Box>
        <Box fontSize={['20px']} fontWeight={[600]} pb={[3]} my={[2]}>
          {t('invitationsForYou')}
        </Box>
        <Table
          table={{
            dataSource: userType === 'ENTITY' ? entityUserInvites.results : cpaUserInvites.data,
            columns: userType === 'ENTITY' ? columns : cpaColumns,
            rowKey: 'guid',
            loading,
          }}
          pagination={{
            total: userType === 'ENTITY' ? entityUserInvites.count : cpaUserInvites.total,
            onChange: handlePageChange,
            current: currentPage,
          }}
        />
      </Box>
    </>
  )
}

export default You
