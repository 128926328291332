import { FC, Dispatch, createContext, useReducer, useContext } from 'react'
import { useReducerLogger } from 'utils'

import { IAction } from './types'

import { IEntityContext, initialState, mainReducer } from './reducers'

const EntityContext = createContext<IEntityContext>({} as IEntityContext)
const EntityDispatchContext = createContext<Dispatch<IAction>>(() => null)

export function useEntity() {
  const context = useContext(EntityContext)
  if (context === undefined) {
    throw new Error('useEntity must be used within an EntityProvider')
  }
  return context
}

export function useEntityDispatch() {
  const context = useContext(EntityDispatchContext)
  if (context === undefined) {
    throw new Error('useEntityDispatch must be used within an EntityProvider')
  }
  return context
}

export const EntityProvider: FC = ({ children }) => {
  const [data, dispatch] = useReducer(useReducerLogger(mainReducer), initialState)

  return (
    <EntityContext.Provider value={data}>
      <EntityDispatchContext.Provider value={dispatch}>{children}</EntityDispatchContext.Provider>
    </EntityContext.Provider>
  )
}
