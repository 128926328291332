import { useReducerLogger } from 'utils'
import { FC, Dispatch, createContext, useReducer, useContext } from 'react'

import { initialState, AuthReducer } from './reducer'
import { IAction, IAuthContext } from './types'

const AuthContext = createContext<IAuthContext>({} as IAuthContext)
const AuthDispatchContext = createContext<Dispatch<IAction>>(() => null)

export function useAuth() {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}

export function useAuthDispatch() {
  const context = useContext(AuthDispatchContext)
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within a AuthProvider')
  }
  return context
}

export const AuthProvider: FC = ({ children }) => {
  const [data, dispatch] = useReducer(
    useReducerLogger<IAuthContext, IAction>(AuthReducer),
    initialState
  )

  return (
    <AuthContext.Provider value={data}>
      <AuthDispatchContext.Provider value={dispatch}>{children}</AuthDispatchContext.Provider>
    </AuthContext.Provider>
  )
}
