import formatRelative from 'date-fns/formatRelative'

import useLocale from 'hooks/useLocale'
import { DocumentThread, ThreadMessage, useDocumentActions } from 'context/Document'

import Icon, { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, TextField } from 'components'
import { useState } from 'react'
import { ReactComponent as Telegram } from 'assets/images/telegram.svg'
import { Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import styles from '../../documentview.module.scss'

const { confirm } = Modal

type Props = {
  messages: ThreadMessage[]
  thread: DocumentThread
  onSubmitSuccess: () => void
  setIsChildModalVisible: (visible: boolean) => void
  setReloadThreads: (flag: boolean | ((newFlag: boolean) => boolean)) => void
}

const Messages = ({
  messages,
  thread,
  onSubmitSuccess,
  setIsChildModalVisible,
  setReloadThreads,
}: Props) => {
  const locale = useLocale()
  const [focusMessageId, setFocusMessageId] = useState<string>('')
  const [newMessage, setNewMessage] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const { updateMessage, loading: loadingUpdate, response: responseUpdate } = useDocumentActions()
  const { deleteMessage, response: responseDelMessage } = useDocumentActions()
  const { deleteThread, response: responsedelThread } = useDocumentActions()

  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'messages' })
  const { t: t2 } = useTranslation('common')

  const onEditMessageSelect = (message: ThreadMessage) => {
    setFocusMessageId(message.guid)
    setIsEditing(true)
    setNewMessage(message.message)
    setErrorMessage('')
  }

  const onEditSubmit = async () => {
    const updatedMessage = await updateMessage(thread.guid, focusMessageId, { message: newMessage })
    if (responseUpdate.ok) {
      onSubmitSuccess()
      setNewMessage('')
      setFocusMessageId('')
    } else {
      setErrorMessage(updatedMessage?.msg)
    }
  }

  const onDelete = async (deleteMessageId: string) => {
    setIsChildModalVisible(true)
    setIsEditing(false)
    setFocusMessageId(deleteMessageId)
    setErrorMessage('')
    confirm({
      maskClosable: true,
      title: t('deleteConfirmation'),
      icon: <ExclamationCircleOutlined />,
      okText: t2('switcher.yes'),
      okType: 'danger',
      cancelText: t2('switcher.no'),
      zIndex: 2000,
      afterClose: () => {
        setReloadThreads((flag) => !flag)
      },
      onOk() {
        return new Promise((resolve) => {
          ;(async () => {
            if (messages.length > 1) {
              await deleteMessage(thread.guid, deleteMessageId)
            } else {
              await deleteThread(thread.guid)
            }
            setIsChildModalVisible(false)
            if (responseDelMessage.ok || responsedelThread.ok) {
              onSubmitSuccess()
              setNewMessage('')
              setFocusMessageId('')
              resolve(true)
            } else {
              setErrorMessage(responseDelMessage?.data?.msg ?? responsedelThread?.data?.msg)
              resolve(false)
            }
          })()
        })
      },
      onCancel() {
        setIsChildModalVisible(false)
      },
    })
  }

  return (
    <div>
      {messages.map(({ createdBy, ...message }, i) => (
        <div key={message.guid} className={styles.thread_message}>
          <div className={styles.thread_message_head}>
            <div
              className={styles.thread_message_user}
              title={`${createdBy.firstName} ${createdBy.lastName}`}
            >
              {createdBy.firstName?.charAt(0)}. {createdBy.lastName}
            </div>
            <span>&nbsp;•&nbsp;</span>
            <div>{formatRelative(new Date(message.createdOn), new Date(), { locale })}</div>
            {i + 1 === messages.length && (
              <>
                {' '}
                <Button
                  onClick={() => onEditMessageSelect({ ...message, createdBy })}
                  className={styles.thread_message_edit}
                  size="middle"
                  icon={<EditOutlined />}
                />
                <Button
                  onClick={() => onDelete(message.guid)}
                  className={styles.thread_message_edit}
                  size="middle"
                  icon={<DeleteOutlined />}
                />
              </>
            )}
          </div>
          {focusMessageId === message.guid && isEditing ? (
            <>
              <TextField
                value={newMessage}
                className={styles.thread_submit}
                onChange={(e) => setNewMessage(e.target.value)}
                suffix={
                  <Button
                    type="primary"
                    ghost
                    size="large"
                    className={styles.submit}
                    loading={loadingUpdate}
                    onClick={onEditSubmit}
                  >
                    <Icon component={Telegram} />
                  </Button>
                }
              />
            </>
          ) : (
            message.message
          )}
          {focusMessageId === message.guid && errorMessage && (
            <div className={styles.thread_message_error}>{errorMessage}</div>
          )}
        </div>
      ))}
    </div>
  )
}

export default Messages
