import { useFetch } from 'use-http'
import qs from 'qs'
import { toSnake } from 'utils'
import { getNonRevokedInvite } from 'utils/entity'

import { ActionTypes } from './action-types'
import { useEntityDispatch } from '..'
import {
  EntityEditForm,
  EntityTaxOrganizerForm,
  EntityUserInviteForm,
  TaxPreparerInviteForm,
  TEntityDocumentParams,
} from '../types'

const useEntityActions = () => {
  const dispatch = useEntityDispatch()
  const { get, patch, response, loading, post, delete: del, abort } = useFetch()

  const getEntityUserRoles = async (entityGuid: string) => {
    const result = await get(`/entities/${entityGuid}/user-roles`)
    if (response.ok) {
      dispatch({
        type: ActionTypes.LOAD_CURRENT_ENTITY_ROLES,
        payload: { role: result?.role, roles: result?.roles },
      })
    }
  }

  const loadCurrentEntity = async (entityGuid: string) => {
    const entity = await get(`/entities/${entityGuid}`)
    if (response.ok) {
      dispatch({ type: ActionTypes.LOAD_CURRENT_ENTITY, payload: entity })
    }
  }

  const loadEntities = async (params: TLoadEntitiesParams) => {
    const entities = await get(`/entities?${qs.stringify(toSnake(params))}`)
    if (response.ok) {
      dispatch({
        type: ActionTypes.LOAD_ENTITIES,
        payload: { data: entities?.results ?? [], total: entities?.count ?? 0 },
      })
    }
    return entities
  }

  const loadEntityKinds = async () => {
    const entityKinds = await get(`/entities/kinds`)
    if (response.ok) {
      dispatch({ type: ActionTypes.SET_KINDS, payload: entityKinds })
    }
  }

  const updateEntity = async (guid: string, payload: EntityEditForm) => {
    const updatedEntityResponse = await patch(`/entities/${guid}`, toSnake(payload))
    if (response.ok) {
      loadCurrentEntity(guid)
    }
    return updatedEntityResponse
  }

  const createEntity = async (payload: EntityEditForm) => {
    const createdEntityResponse = await post(`/entities`, toSnake(payload))
    return createdEntityResponse
  }

  const getEntityTaxFirmInvites = async (guid: string) => {
    const invites = await get(`/entities/${guid}/entity-firm-invites`)
    // As per task definition we only take the first invite
    return getNonRevokedInvite(invites?.results || [])
  }

  const revokeEntityCpaInvite = async (entityGuid: string, inviteId: string) => {
    await post(`/entities/${entityGuid}/firm-invites/${inviteId}/revoke`)
  }

  const resendEntityCpaInvite = async (entityGuid: string, inviteId: string) => {
    await post(`/entities/${entityGuid}/firm-invites/${inviteId}/resend`)
  }

  const inviteEntityTaxFirm = async (guid: string, payload: TaxPreparerInviteForm) => {
    await post(`/entities/${guid}/firm-invites`, payload)
  }

  const loadEntityUsers = async (entityGuid: string, params?: TParams) => {
    const query = qs.stringify(toSnake({ ...params }), { addQueryPrefix: !!params })
    const users = await get(`/entities/${entityGuid}/users${query}`)
    if (response.ok) {
      dispatch({
        type: ActionTypes.SET_ENTITY_USERS,
        payload: {
          data: users ? users.results : [],
          total: users ? users.count : 0,
        },
      })
    }
  }

  const loadEntityUserInvites = async (entityGuid: string, params?: TParams) => {
    const query = qs.stringify(toSnake({ ...params }), { addQueryPrefix: !!params })
    const users = await get(`/entities/${entityGuid}/user-invites-by-entity${query}`)
    if (response.ok) {
      dispatch({
        type: ActionTypes.SET_ENTITY_USER_INVITES,
        payload: {
          data: users ? users.results : [],
          total: users ? users.count : 0,
        },
      })
    }
  }

  const revokeEntityUserInvite = async (entityGuid: string, inviteId: string) => {
    await post(`/entities/${entityGuid}/user-invites/${inviteId}/reject`)
    if (response.ok) {
      loadEntityUserInvites(entityGuid)
    }
  }

  const resendEntityUserInvite = async (entityGuid: string, inviteId: string) => {
    await post(`/entities/${entityGuid}/user-invites/${inviteId}/resend`)
    if (response.ok) {
      loadEntityUserInvites(entityGuid)
    }
  }

  const loadEntityCPAContacts = async (entityGuid: string, cpaId: string, params?: TParams) => {
    const query = qs.stringify(toSnake({ ...params }), { addQueryPrefix: !!params })
    const users = await get(`/cpas/cpa-firms/${cpaId}/entities/${entityGuid}/cpa-contacts${query}`)
    if (response.ok) {
      dispatch({ type: ActionTypes.SET_CPA_CONTACTS, payload: users })
    }
  }

  const assignCPAUser = async (
    entityGuid: string,
    cpaGuid: string,
    userGuid: string,
    payload?: any
  ) => {
    return post(
      `/cpas/cpa-firms/${cpaGuid}/entities/${entityGuid}/cpa-contacts/${userGuid}`,
      toSnake(payload)
    )
  }

  const assignCPAUsers = async (entityGuid: string, cpaId: string, userIds: string[]) => {
    await Promise.all(userIds.map((userId) => assignCPAUser(entityGuid, cpaId, userId)))
    await loadEntityCPAContacts(entityGuid, cpaId)
  }

  const unassignCPAUser = async (entityGuid: string, cpaId: string, userId: string) => {
    await del(`/cpas/cpa-firms/${cpaId}/entities/${entityGuid}/cpa-contacts/${userId}`)
  }

  const inviteEntityUser = async (entityGuid: string, params: EntityUserInviteForm) => {
    const updatedEntityResponse = await post(
      `/entities/${entityGuid}/user-invites`,
      toSnake(params)
    )
    if (response.ok) {
      loadEntityUsers(entityGuid)
    }
    return updatedEntityResponse
  }

  const createTaxOrganizer = async (guid: string, payload: EntityTaxOrganizerForm) => {
    const updatedEntityResponse = await post(`/tax-organizers`, {
      ...toSnake(payload),
      entity: guid,
    })

    return updatedEntityResponse
  }

  const getEntitiesPrimaryContactsList = async (params?: TParams) => {
    const query = qs.stringify(toSnake({ ...params }), { addQueryPrefix: !!params })
    const result = await get(`/entities/primary-contacts${query}`)
    if (response.ok) {
      dispatch({
        type: ActionTypes.LOAD_ENTITIES_PRIMARY_CONTACTS,
        payload: result,
      })
    }
  }

  const getCurrentEntityDocuments = async (entityGuid: string, params?: TEntityDocumentParams) => {
    const query = qs.stringify(toSnake({ ...params }), { addQueryPrefix: !!params })
    const result = await get(`/entities/${entityGuid}/documents${query}`)
    if (response.ok) {
      dispatch({
        type: ActionTypes.LOAD_CURRENT_ENTITY_DOCS,
        payload: result,
      })
    }
  }

  const updateEntityRole = async (guid: string, userGuid: string, role: string) => {
    const updatedEntityResponse = await patch(`/entities/${guid}/user/${userGuid}`, {
      role,
    })
    return updatedEntityResponse
  }

  return {
    loadCurrentEntity,
    updateEntity,
    updateEntityRole,
    inviteEntityTaxFirm,
    getEntityTaxFirmInvites,
    revokeEntityCpaInvite,
    resendEntityCpaInvite,
    createTaxOrganizer,
    inviteEntityUser,
    loadEntityUsers,
    loadEntityCPAContacts,
    assignCPAUser,
    unassignCPAUser,
    assignCPAUsers,
    loadEntityKinds,
    loadEntityUserInvites,
    resendEntityUserInvite,
    revokeEntityUserInvite,
    loadEntities,
    createEntity,
    getCurrentEntityDocuments,
    getEntitiesPrimaryContactsList,
    getEntityUserRoles,
    response,
    loading,
    abort,
  }
}

export default useEntityActions
export { ActionTypes }
