export const STEPS_LIST = [
  {
    id: '0-Setup',
    title: 'Setup',
  },
  {
    id: '1-Logo',
    title: 'Logo',
  },
  {
    id: '2-Billing',
    title: 'Billing',
  },
  {
    id: '3-Invite-Users',
    title: 'Invite Users',
  },
  {
    id: '4-Invite-Clients',
    title: 'Invite Clients',
  },
]
