import outlook from 'assets/images/outlook.png'

export const dataSource = [
  {
    id: 1,
    image: outlook,
    title: 'automatedPageClassification.title',
    description: 'automatedPageClassification.description',
  },
  {
    id: 2,
    image: outlook,
    title: 'dataExtractionClassification.title',
    description: 'dataExtractionClassification.description',
  },
  {
    id: 3,
    image: outlook,
    title: 'historicalSummaries.title',
    description: 'historicalSummaries.description',
  },
  {
    id: 4,
    image: outlook,
    title: 'receiveClientPayments.title',
    description: 'receiveClientPayments.description',
  },
  {
    id: 5,
    image: outlook,
    title: 'dateManagementClients.title',
    description: 'dateManagementClients.description',
  },
  {
    id: 6,
    image: outlook,
    title: 'companyVerificationWithAllStates.title',
    description: 'companyVerificationWithAllStates.description',
  },
  {
    id: 7,
    image: outlook,
    title: 'cpaLicenseVerificationWithAllStates.title',
    description: 'cpaLicenseVerificationWithAllStates.description',
  },
  {
    id: 8,
    image: outlook,
    title: 'taxpayerIdentificationMatching.title',
    description: 'taxpayerIdentificationMatching.description',
  },
  {
    id: 9,
    image: outlook,
    title: 'factaCheck.title',
    description: 'factaCheck.description',
  },
  {
    id: 10,
    image: outlook,
    title: 'complianceSupportCorporateTransparencyAct.title',
    description: 'complianceSupportCorporateTransparencyAct.description',
  },
  {
    id: 11,
    image: outlook,
    title: 'integrationWithQBO.title',
    description: 'integrationWithQBO.description',
  },
  {
    id: 12,
    image: outlook,
    title: 'bookTaxReconciliation.title',
    description: 'bookTaxReconciliation.description',
  },
  {
    id: 13,
    image: outlook,
    title: 'integrationWithAllTaxSoftwares.title',
    description: 'integrationWithAllTaxSoftwares.description',
  },
  {
    id: 14,
    image: outlook,
    title: 'customizableQuestionnaire.title',
    description: 'customizableQuestionnaire.description',
  },
  {
    id: 15,
    image: outlook,
    title: 'uniqueUrlDownloads.title',
    description: 'uniqueUrlDownloads.description',
  },
  {
    id: 16,
    image: outlook,
    title: 'bookTaxSummary.title',
    description: 'bookTaxSummary.description',
  },
]
