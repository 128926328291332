import { Link, useLocation } from 'react-router-dom'
import { Row, Col, Empty } from 'antd'
import { useTranslation } from 'react-i18next'
import { useEffect, useMemo } from 'react'
import { Flex } from 'reflexbox'

import useDate from 'hooks/useDate'

import { Section, SpinnerBackdrop } from 'components'
import { useDashboard, useDashboardActions } from 'context/Dashboard'

import styles from '../../dashboard.module.scss'

const MostRecent = () => {
  const { t } = useTranslation('dashboard')
  const { t: tCommon } = useTranslation('common')
  const { getDate, isTodayDate } = useDate()
  const { pathname } = useLocation()
  const { loadMostRecent, loading } = useDashboardActions()
  const { mostResent } = useDashboard()
  const count = 4

  const mostRecentResults = useMemo(() => {
    if (mostResent) {
      return mostResent?.count <= count ? mostResent.results : mostResent.results.slice(0, count)
    }

    return []
  }, [mostResent])

  useEffect(() => {
    loadMostRecent()
  }, [])

  const hasRecords = mostRecentResults.length > 0
  const showNoData = !hasRecords && !loading

  return (
    <Section className={styles.most_recent_section} title={t('titles.mostRecent')} titleIndent={24}>
      <div>
        {loading && <SpinnerBackdrop mode="local" />}

        {showNoData && (
          <Flex
            height={['200px']}
            width={['100%']}
            justifyContent={['center']}
            alignItems={['center']}
          >
            <Empty description={tCommon('noData')} />
          </Flex>
        )}

        {hasRecords &&
          mostRecentResults.map(({ ...mostRecentResult }) => (
            <div key={mostRecentResult.guid} className={styles.item}>
              <Row gutter={[10, 10]} align="middle">
                <Col xs={5} className="text-bold">
                  {mostRecentResult && (
                    <span className={styles.item_first_col}>
                      {isTodayDate(mostRecentResult.lastAccessedOn)
                        ? t('date.today')
                        : getDate(mostRecentResult?.lastAccessedOn, 'MMM dd')}
                    </span>
                  )}
                </Col>

                <Col xs={15} className="text-ellipsis text-semibold">
                  <Link
                    to={{
                      pathname: `/tax-organizer/${mostRecentResult.guid}`,
                      state: {
                        taxPrevPath: pathname,
                      },
                    }}
                    className="text-black"
                  >
                    {mostRecentResult.entity.title}
                  </Link>
                </Col>

                <Col xs={4} className="text-ellipsis text-semibold">
                  <span className={styles.item_first_col}>{mostRecentResult.taxYear}</span>
                </Col>
              </Row>
            </div>
          ))}
      </div>
    </Section>
  )
}

export default MostRecent
