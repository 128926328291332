import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EditOutlined } from '@ant-design/icons'

import { TEntity } from 'context/Entity'

import { Button, Modal } from 'components'
import styles from 'pages/Dashboard/EntityManagement/dashboard.entity.management.module.scss'
import EntityTaxTeamList from '../EntityTaxTeamList'

type PropsType = {
  entity: TEntity
  cpaFirm: TCpaFirm
}

const EditTaxTeam = ({ entity, cpaFirm }: PropsType) => {
  const { t } = useTranslation('common')
  const [popoverVisible, setPopoverVisible] = useState(false)
  return (
    <Modal
      overlayClassName={styles.modal_addteam}
      placement="left"
      content={<EntityTaxTeamList entity={entity} cpaFirm={cpaFirm} />}
      destroyTooltipOnHide
      trigger="click"
      visible={popoverVisible}
      onVisibleChange={(visible) => setPopoverVisible(visible)}
    >
      <Button type="link" icon={<EditOutlined />} style={{ paddingRight: 0 }}>
        {t('buttons.edit')}
      </Button>
    </Modal>
  )
}

export default EditTaxTeam
