import { useTranslation } from 'react-i18next'
import { ColumnsType } from 'antd/lib/table'
import { getCategoryLabel, getSubCategoryLabel } from 'utils/form'

import { useDashboard } from 'context/Dashboard'
import { useEntity, TTaxDocument } from 'context/Entity'
import useDate from 'hooks/useDate'

import { DocName, Tags } from 'components/Table/helpers'
import { Link } from 'react-router-dom'
import Actions from '../components/Actions'

export default (data: TTaxDocument[]) => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'documents.columns' })
  const { categories, subCategories } = useDashboard()
  const {
    entity: {
      currentEntity: { guid: entityGuid },
    },
  } = useEntity()
  const { getDate } = useDate()
  const dataSource = data?.map((document) => ({
    ...document,
    key: document.guid,
    uploaded: new Date(document.createdOn).getTime(),
  }))

  const taxYears = (document: any) => {
    const years = document.map((taxOrganizer: any, index: number) => {
      return (
        <Link
          to={{
            pathname: `/tax-organizer/${taxOrganizer.taxOrganizerGuid}`,
          }}
        >
          {index < document.length - 1 ? `${taxOrganizer.taxYear}, ` : `${taxOrganizer.taxYear}`}
        </Link>
      )
    })
    return years
  }

  const columns: ColumnsType<TTaxDocument> = [
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: (name: string, record: TTaxDocument) => (
        <DocName name={name} guid={record.guid} showTooltip entityGuid={entityGuid} />
      ),
    },
    {
      title: t('category'),
      dataIndex: 'category',
      key: 'category__title',
      sorter: true,
      render: (category: string) => getCategoryLabel(category, subCategories, categories),
    },
    {
      title: t('subCategory'),
      dataIndex: 'category',
      key: 'subCategory',
      render: (category: string) => getSubCategoryLabel(category, subCategories),
    },
    {
      title: t('uploaded'),
      dataIndex: 'createdOn',
      key: 'created_on',
      sorter: {
        multiple: 2,
      },
      render: (date: string) => {
        return <div style={{ whiteSpace: 'nowrap' }}>{getDate(date, 'MM/dd/yyyy')}</div>
      },
    },
    {
      title: t('tags'),
      dataIndex: 'tags',
      key: 'tags__name',
      sorter: true,
      render: (tags: TOption[]) => {
        return <Tags tags={tags} />
      },
    },
    {
      title: <span className="text-nowrap">{t('taxOrganizers')}</span>,
      dataIndex: 'taxOrganizers',
      key: 'taxOrganizers',
      render: (record) => taxYears(record),
    },
    {
      title: t('actions.title'),
      key: 'actions',
      dataIndex: 'guid',
      align: 'right',
      render: (guid: string, record: Partial<TTaxDocument>) => (
        <Actions guid={guid} document={record} />
      ),
    },
  ]

  return { dataSource, columns }
}
