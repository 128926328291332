import { useEffect } from 'react'
import { Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { Box } from 'reflexbox'

import { TFile } from 'context/TaxOrganizer'

import { Button } from 'components'
import Title from 'components/UploadModal/components/Title'
import StatusBar from 'components/UploadModal/components/StatusBar'

import styles from 'components/UploadModal/uploadmodal.module.scss'
import 'components/UploadModal/style.scss'
import useUploadDocument from './hooks/useUploadDocument'

type Props = {
  fileList?: TFile[]
  onHide: () => void
  setFilesUploaded?: (value: boolean) => void
  onFinishUploading?: () => void
}

const UploadModalManualSign = ({
  fileList,
  onHide,
  setFilesUploaded,
  onFinishUploading,
}: Props) => {
  const { t } = useTranslation('common', { keyPrefix: 'upload.modal' })
  const files = fileList?.slice() || []
  const { progress } = useUploadDocument({
    files,
    onFinishUploading,
  })

  useEffect(() => {
    if (!files?.length) {
      onHide()
    }
    if (setFilesUploaded) {
      setFilesUploaded(!!files?.length)
    }
  }, [files])

  return (
    <Modal
      visible
      title={<Title progress={progress} />}
      className={styles.modal}
      width={1180}
      destroyOnClose
      closable={false}
      onCancel={onHide}
      footer={[
        <Box mr={[3]} mb={[1]} key="submit-block">
          <Button
            key="submit"
            type="primary"
            ghost
            size="large"
            disabled={!progress.isFinished}
            onClick={onHide}
          >
            {t('done')}
          </Button>
        </Box>,
      ]}
    >
      <StatusBar progress={progress} totalFiles={files?.length ?? 0} />
    </Modal>
  )
}

export default UploadModalManualSign
