import { Box } from 'reflexbox'
import { Button } from 'components'
import { Form, Select, Typography } from 'antd'
import { useEntityActions, TEntity } from 'context/Entity'
import { useTranslation } from 'react-i18next'
import { initForm, saveFormToSession } from 'utils/form'
import { browserSession } from 'utils/session'

import { useDashboard } from 'context/Dashboard'

import { defaultYear } from 'utils/taxOrganizer'
import styles from '../../../dashboard.entity.management.module.scss'

const { Title } = Typography

type Props = {
  setVisible: (visible: boolean) => void
  reloadTaxOrganizers: () => void
  currentEntity: TEntity | undefined
}

const AddTaxOrganizerForm = ({ setVisible, reloadTaxOrganizers, currentEntity }: Props) => {
  const [form] = Form.useForm()
  const { createTaxOrganizer, loading, response } = useEntityActions()
  const {
    taxYears: { range: taxYears },
  } = useDashboard()
  const { t } = useTranslation('entity')
  const { t: t2 } = useTranslation('common')

  const taxYearOptions = taxYears.map((year) => ({ label: year, value: year }))

  const onSubmit = async (taxYear: { taxYear: number }) => {
    const taxOrganizerResponse = await createTaxOrganizer(currentEntity!.guid, taxYear)
    browserSession.removeItem('form:dashboardAddTaxOrganizer')
    if (!response.ok) {
      const errors = Object.entries(taxOrganizerResponse).map(([key, value]) => {
        return {
          name: key === 'detail' ? 'entity' : key,
          errors: Array.isArray(value) ? value : [value],
        }
      })

      // @ts-ignore
      form.setFields(errors)
    } else {
      setVisible(false)
      reloadTaxOrganizers()
    }
  }

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      className={styles.form_wrapper}
      onValuesChange={(changedValues: any, allValues: any) =>
        saveFormToSession('form:dashboardAddTaxOrganizer', allValues)
      }
      initialValues={initForm('form:dashboardAddTaxOrganizer')}
    >
      <Title className={styles.form_title} level={4}>
        {t2('buttons.add')} {t('common.taxOrganizer')}
      </Title>
      <div className={styles.form_fields}>
        <Box fontSize={['16px']} mb={[1]}>
          {t('common.taxYear')}
        </Box>
        <Form.Item
          rules={[{ required: true, message: `${t('common.taxYear')} is required` }]}
          name="tax_year"
          key="taxYear"
        >
          <Select options={taxYearOptions} defaultValue={defaultYear} />
        </Form.Item>
        <div className={styles.form_action_row}>
          <Button
            className={styles.form_submit}
            type="primary"
            size="large"
            htmlType="submit"
            block
            disabled={loading}
          >
            {t2('buttons.add')}
          </Button>
        </div>
      </div>
    </Form>
  )
}

export default AddTaxOrganizerForm
