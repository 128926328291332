import { NavLink, useLocation, useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Flex } from 'reflexbox'

import { TaxOrganizer, useTaxOrganizer, useTaxOrganizerActions } from 'context/TaxOrganizer'
import { useAuth, UserType } from 'context/Auth'
import cn from 'classnames'

import { Badge, QuestionIcon } from 'components'
import EntityStateDropDown from './components/EntityStateDropDown'

import styles from './tax.navigation.module.scss'

type Props = {
  taxOrganizer: TaxOrganizer
}

const Navigation = ({ taxOrganizer }: Props) => {
  const { url } = useRouteMatch()
  const { pathname } = useLocation()
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'navigation' })
  const { user } = useAuth()
  const {
    taxOrganizer: { data },
  } = useTaxOrganizer()

  const { updateTaxOrganizer } = useTaxOrganizerActions()
  const updateTaxOrganizerStatus = (status: string, type: UserType) =>
    type === UserType.CPA
      ? updateTaxOrganizer(data.guid, { cpaStatus: status })
      : updateTaxOrganizer(data.guid, { clientStatus: status })

  const isDropDownEnabled = (type: UserType) => user?.type === type

  return (
    <div className={cn(styles.border, 'container-xxl')}>
      <div className={styles.box}>
        {/* id prop is for chameleon. Do not remove. */}
        <div id="overview" className={styles.item}>
          <NavLink to={url} exact className={styles.link} activeClassName={styles.active}>
            {t('overview')}
          </NavLink>
        </div>
        {/* id prop is for chameleon. Do not remove. */}
        <div id="messages" className={styles.item}>
          <NavLink to={`${url}/messages`} className={styles.link} activeClassName={styles.active}>
            <Badge
              scheme="purple"
              count={taxOrganizer.unreadMessages}
              size="small"
              offset={[8, -3]}
            >
              {t('messages')}
            </Badge>
          </NavLink>
        </div>
        {/* id prop is for chameleon. Do not remove. */}
        <div id="collection" className={styles.item}>
          <NavLink
            to={`${url}/collection/documents`}
            className={styles.link}
            activeClassName={styles.active}
            isActive={() => pathname.includes('/collection')}
          >
            {t('collection')}
          </NavLink>
        </div>
        {/* id prop is for chameleon. Do not remove. */}
        <div id="taxreturns" className={styles.item}>
          <NavLink to={`${url}/taxes`} className={styles.link} activeClassName={styles.active}>
            {t('taxReturns')}
            <QuestionIcon className={styles.question_icon} />
          </NavLink>
        </div>
        <div className={styles.item}>
          <Flex>
            <Flex flexDirection="column">
              <p className={styles.dropdown_title}>{t('client')}:</p>
              <p className={styles.dropdown_title}>{t('taxPreparer')}:</p>
            </Flex>
            <Flex flexDirection="column">
              <EntityStateDropDown
                currentStatus={data.clientStatus}
                type={UserType.ENTITY}
                enabled={isDropDownEnabled(UserType.ENTITY)}
                onChange={(value) => updateTaxOrganizerStatus(value, UserType.ENTITY)}
              />
              <EntityStateDropDown
                currentStatus={data.cpaStatus}
                type={UserType.CPA}
                enabled={isDropDownEnabled(UserType.CPA)}
                onChange={(value) => updateTaxOrganizerStatus(value, UserType.CPA)}
              />
            </Flex>
          </Flex>
        </div>
      </div>
    </div>
  )
}

export default Navigation
