import { ActionTypes } from '../actions'
import { TTaxDocument, DocumentTag } from '../types'

import { TEntityKind, IEntityState, IAction } from '..'

export const initialEntityState: IEntityState = {
  entities: {
    data: [],
    total: 0,
  },
  currentEntity: undefined!,
  users: {
    data: [],
    total: 0,
  },
  userInvites: {
    total: 0,
    data: [],
  },
  kinds: [],
  cpaContacts: {
    count: 0,
    data: [],
    page: 0,
  },
  entitiesPrimaryContacts: [],
  documents: {
    data: [],
    total: 0,
  },
  cpaAssocitatedUsers: {
    data: [],
    count: 0,
  },
  currentUserRole: undefined,
  currentUserPermissions: [],
  currentEntityDocument: undefined!,
}

export const entityReducer = (state: IEntityState = initialEntityState, action: IAction) => {
  const { type, payload } = action
  switch (type) {
    case ActionTypes.LOAD_ENTITIES:
      return {
        ...state,
        entities: {
          data: payload.data,
          total: payload.total,
        },
      }

    case ActionTypes.LOAD_CURRENT_ENTITY:
      return {
        ...state,
        currentEntity: payload,
      }

    case ActionTypes.SET_KINDS:
      return {
        ...state,
        kinds: payload.map(({ label, title }: TEntityKind) => ({
          value: label,
          label: title,
        })),
      }

    case ActionTypes.SET_ENTITY_USERS: {
      return {
        ...state,
        users: { ...payload },
      }
    }

    case ActionTypes.SET_CPA_CONTACTS:
      return {
        ...state,
        cpaContacts: {
          ...payload,
          data: payload?.results ?? [],
        },
      }
    case ActionTypes.SET_ENTITY_USER_INVITES:
      return {
        ...state,
        userInvites: { ...payload },
      }

    case ActionTypes.LOAD_ENTITIES_PRIMARY_CONTACTS:
      return {
        ...state,
        entitiesPrimaryContacts: payload?.results,
      }

    case ActionTypes.LOAD_CURRENT_ENTITY_DOCS:
      return {
        ...state,
        documents: {
          data: payload?.results
            ? payload.results.map((document: TTaxDocument & { tags: DocumentTag[] }) => {
                return {
                  ...document,
                  tags: document.tags.map((tag: DocumentTag): TOption => {
                    return {
                      label: tag.name,
                      value: tag.slug,
                    }
                  }),
                }
              })
            : [],
          total: payload?.count ?? 0,
        },
      }

    case ActionTypes.SET_CPA_ASSOCIATED_USERS:
      return {
        ...state,
        cpaAssocitatedUsers: {
          data: payload?.results ?? [],
          count: payload?.count ?? 0,
        },
      }

    case ActionTypes.LOAD_CURRENT_ENTITY_ROLES:
      return {
        ...state,
        currentUserRole: payload.role,
        currentUserPermissions: payload.roles ?? [],
      }

    case ActionTypes.LOAD_ENTITY_DOCUMENT:
      return {
        ...state,
        currentEntityDocument: payload
          ? {
              ...payload,
              tags: payload.tags.map((tag: DocumentTag): TOption => {
                return {
                  label: tag.name,
                  value: tag.slug,
                }
              }),
            }
          : undefined,
      }

    case ActionTypes.DELETE_DOCUMENT: {
      const data = state.documents.data.filter(
        (document: TTaxDocument) => document.guid !== action.payload.documentGuid
      )
      return {
        ...state,
        documents: {
          total: data.length,
          data,
        },
      }
    }

    default:
      return state
  }
}
