import { useTranslation } from 'react-i18next'
import { Flex, Box } from 'reflexbox'
import { ColumnsType } from 'antd/lib/table'
import { getCategoryLabel, getSubCategoryLabel } from 'utils/form'
import { fieldIsLocked } from 'utils'

import { useDashboard } from 'context/Dashboard'
import { useTaxOrganizer, TTaxDocument } from 'context/TaxOrganizer'
import useDate from 'hooks/useDate'

import { ReactComponent as CommentSvg } from 'assets/images/comment-small.svg'

import { Tags, DocName } from '../constants'

import Actions from '../components/Actions'

export default () => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'documents.columns' })
  const {
    documents: { data },
    taxOrganizer: {
      data: { guid: taxOrganizerGuid, isLocked },
    },
  } = useTaxOrganizer()
  const { categories, subCategories } = useDashboard()
  const { getDate } = useDate()
  const dataSource = data?.map((document) => ({
    ...document,
    key: document.guid,
    uploaded: new Date(document.createdOn).getTime(),
  }))

  const columns: ColumnsType<TTaxDocument> = [
    {
      title: (
        <Box fontSize={['14px']} fontWeight={['600']}>
          {t('name')}
        </Box>
      ),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: (name: string, record: TTaxDocument) => (
        <Box fontSize={['12px']} fontWeight={['600']}>
          <DocName
            name={name}
            guid={record.guid}
            showTooltip
            taxOrganizerGuid={taxOrganizerGuid}
            disabled={fieldIsLocked(isLocked, record.afterTaxOrganizerLock)}
          />
        </Box>
      ),
    },
    {
      title: <Box fontSize={['14px']}>{t('category')}</Box>,
      dataIndex: 'category',
      key: 'category__title',
      sorter: true,
      render: (category: string) => (
        <Box fontSize={['12px']}>{getCategoryLabel(category, subCategories, categories)}</Box>
      ),
    },
    {
      title: <Box fontSize={['14px']}>{t('subCategory')}</Box>,
      dataIndex: 'category',
      key: 'subCategory',
      render: (category: string) => (
        <Box fontSize={['12px']}>{getSubCategoryLabel(category, subCategories)}</Box>
      ),
    },
    {
      title: <Box fontSize={['14px']}>{t('uploaded')}</Box>,
      dataIndex: 'createdOn',
      key: 'created_on',
      sorter: {
        multiple: 2,
      },
      render: (createdOn: string) => (
        <Box fontSize={['12px']} className="text-nowrap">
          {getDate(createdOn, 'MM/dd/yyyy')}
        </Box>
      ),
    },
    {
      title: <Box fontSize={['14px']}>{t('tags')}</Box>,
      dataIndex: 'tags',
      key: 'tags__name',
      sorter: true,
      render: (tags: TOption[]) => (
        <Box fontSize={['14px']}>
          <Tags tags={tags} />
        </Box>
      ),
    },
    {
      title: <Box fontSize={['14px']}>{t('comments')}</Box>,
      dataIndex: 'unresolvedMessages',
      key: 'unresolvedMessages',
      render: (count: number) =>
        count > 0 ? (
          <Flex alignItems="center" fontSize={['14px']}>
            <Box>
              <CommentSvg />
            </Box>
            <Box ml={2}>{count}</Box>
          </Flex>
        ) : null,
    },
    {
      title: <Box fontSize={['14px']}>{t('actions.title')}</Box>,
      key: 'actions',
      dataIndex: 'guid',
      align: 'right',
      render: (guid: string, record: TTaxDocument) => <Actions guid={guid} document={record} />,
    },
  ]

  return { dataSource, columns }
}
