import { useEffect, useMemo } from 'react'
import { debounce } from 'lodash'
import { Form, Select } from 'antd'
import { Box } from 'reflexbox'
import { SearchOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import { TextField } from 'components'

import { TLoadMessageThreadsParams } from '../../types'

type TProps = {
  setParams: (params: TLoadMessageThreadsParams) => void
  sidebarCollapsed: boolean
}

type TFilters = {
  search: string
  type: string
}

const SidebarForm = ({ setParams, sidebarCollapsed }: TProps) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'sidebar.form' })
  const [form] = Form.useForm()

  const options: any = [
    {
      label: t('options.all'),
      value: '',
    },
    {
      label: t('options.resolved'),
      value: 'resolved',
    },
    {
      label: t('options.unresolved'),
      value: 'unresolved',
    },
  ]

  const onValuesChange = (cur: string | undefined, { type, search }: TFilters) => {
    const params = { page: 1 }
    if (type) {
      Object.assign(params, { isResolved: type === 'resolved' })
    }
    if (search) {
      Object.assign(params, { search })
    }
    setParams(params)
  }

  const debouncedChangeHandler = useMemo(() => debounce(onValuesChange, 300), [])

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel()
    }
  }, [])

  useEffect(() => {
    if (sidebarCollapsed) {
      form.setFieldsValue({
        search: '',
        type: '',
      })
    }
  }, [sidebarCollapsed])

  return (
    <Box pl={[3]} pr={[3]} mt={['20px']}>
      <Form
        form={form}
        onValuesChange={debouncedChangeHandler}
        initialValues={{ type: options[0].value }}
      >
        <Form.Item name="search" key="search">
          <TextField placeholder={t('search')} size="middle" suffix={<SearchOutlined />} />
        </Form.Item>
        <Form.Item name="type" key="type">
          <Select
            placeholder={t('threads')}
            options={options}
            dropdownMatchSelectWidth={false}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Form>
    </Box>
  )
}

export default SidebarForm
