import { useEffect, useMemo, useState } from 'react'
import { isEmpty, range } from 'lodash'

import {
  TDocumentPageIdentifier,
  TTaxReturnMenuCategory,
  useTaxReturns,
  useTaxReturnsActions,
} from 'context/TaxReturns'

import { Button, Section } from 'components'

import { Select } from 'antd'
import { collapseAll, expandAll, getCategoryTreeStructure } from 'utils/taxReturn'
import { useTranslation } from 'react-i18next'
import { DocumentProvider } from 'context/Document'

import styles from '../../dashboard.tax_return.module.scss'
import CollapsibleMenu from './components/CollapsibleMenu'
import ReviewGuide from './components/ReviewGuide'
import DocumentPreview from './components/DocumentPreview'

type TProps = {
  isFiledReturn?: boolean
}

const ReviewTab = ({ isFiledReturn = false }: TProps) => {
  const [selectedPage, setSelectedPage] = useState<TDocumentPageIdentifier | null>(null)
  const [menuTreeStructure, setMenuTreeStructure] = useState<TTaxReturnMenuCategory[]>()
  const [collapseMenu, setCollapseMenu] = useState(true)
  const [commentsEnabled, setCommentsEnabled] = useState<boolean>(false)
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'taxReturns.review' })

  const { filterTaxReturnPages, loadTaxReturnCategories, loadTaxReturnPages } =
    useTaxReturnsActions()

  const {
    state: {
      taxReturns,
      categories,
      pages,
      filteredTaxReturnPages,
      taxReturns: { latest: latestTaxReturn },
    },
  } = useTaxReturns()

  const onCollapseAll = () => {
    if (collapseMenu) {
      setMenuTreeStructure(() => {
        setCollapseMenu(false)
        return expandAll(menuTreeStructure)
      })
    } else {
      setMenuTreeStructure(() => {
        setCollapseMenu(true)
        return collapseAll(menuTreeStructure!)
      })
    }
  }

  useEffect(() => {
    if (isEmpty(categories)) {
      loadTaxReturnCategories()
    }
  }, [])

  useEffect(() => {
    loadTaxReturnPages(latestTaxReturn!)
  }, [])

  useEffect(() => {
    if (categories && pages) {
      setMenuTreeStructure(getCategoryTreeStructure(categories, pages))
      filterTaxReturnPages(getCategoryTreeStructure(categories, pages))
    }
  }, [categories, pages])

  useEffect(() => {
    if (filteredTaxReturnPages?.length < 1) return
    const page = filteredTaxReturnPages[0]
    setSelectedPage({
      documentGuid: page.documentGuid,
      pageId: Number(page.pageId),
      uiIndex: 1,
      guid: page.guid,
    })
  }, [filteredTaxReturnPages])

  const isInErrorState = useMemo(() => isEmpty(taxReturns), [taxReturns])

  const draftVersion = latestTaxReturn?.draftVersion || 1

  if (isInErrorState) return <Section>{t('errorMessage')}</Section>

  return (
    <>
      {!isFiledReturn && (
        <>
          <ReviewGuide
            commentsEnabled={commentsEnabled}
            setCommentsEnabled={setCommentsEnabled}
            taxReturn={latestTaxReturn}
            draftVersion={draftVersion}
          />
        </>
      )}
      <Section>
        <div className={styles.tax_return_preview_section}>
          <div className={styles.tax_return_file_section}>
            <div className="mb-4">
              <Select
                options={range(1, draftVersion + 1).map((version) => ({
                  value: version,
                  label: `${t('draft')} ${version}`,
                }))}
                value={draftVersion}
                className={styles.menu_item}
              />
              <Button
                onClick={onCollapseAll}
                type="primary"
                ghost
                size="large"
                className={styles.menu_item}
              >
                {collapseMenu ? t('expandAll') : t('collapseAll')}
              </Button>
            </div>
            <CollapsibleMenu
              menuTreeStructure={menuTreeStructure}
              setMenuTreeStructure={setMenuTreeStructure}
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
            />
          </div>
          {selectedPage && latestTaxReturn && (
            <DocumentProvider>
              <DocumentPreview
                commentsEnabled={commentsEnabled}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
                taxReturn={latestTaxReturn}
              />
            </DocumentProvider>
          )}
        </div>
      </Section>
    </>
  )
}

export default ReviewTab
