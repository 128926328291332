import { Button } from 'components'
import { Form, Select, Typography } from 'antd'
import { useEntityActions, TEntity } from 'context/Entity'
import { useDashboard } from 'context/Dashboard'
import { EntityTaxOrganizerForm, ErrorResponse } from 'context/Entity/types'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { getFirstError } from 'utils'
import { initForm, saveFormToSession } from 'utils/form'
import { defaultYear } from 'utils/taxOrganizer'
import { browserSession } from 'utils/session'

import styles from '../../../../dashboard.entity.management.module.scss'

const { Title, Paragraph } = Typography

type Props = {
  entity: TEntity
  setVisible: (visible: boolean) => void
  reloadTaxOrganizers: () => void
}

const AddTaxOrganizerForm = ({ entity, setVisible, reloadTaxOrganizers }: Props) => {
  const [form] = Form.useForm()
  const [errorMap, setErrorMap] = useState<ErrorResponse>({})
  const { createTaxOrganizer, loading, response } = useEntityActions()
  const {
    taxYears: { range: taxYears },
  } = useDashboard()
  const { t } = useTranslation('entity')
  const { t: t2 } = useTranslation('common')

  const taxYearOptions = taxYears.map((year: number) => ({ label: year, value: year }))

  const onSubmit = async (payload: EntityTaxOrganizerForm) => {
    const taxOrganizerResponse = await createTaxOrganizer(entity?.guid, payload)
    browserSession.removeItem('form:entityAddTaxOrganizer')
    if (!response.ok) {
      setErrorMap(taxOrganizerResponse)
    } else {
      setVisible(false)
      reloadTaxOrganizers()
    }
  }

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      className={styles.form_wrapper}
      onValuesChange={(changedValues: any, allValues: any) =>
        saveFormToSession('form:entityAddTaxOrganizer', allValues)
      }
      initialValues={initForm('form:entityAddTaxOrganizer')}
    >
      <Title className={styles.form_title} level={4}>
        {t2('buttons.add')} {t('common.taxOrganizer')}
      </Title>
      <div className={styles.form_fields}>
        <Paragraph className={styles.form_label}>{t('common.taxYear')}</Paragraph>
        <Form.Item
          rules={[{ required: true, message: `${t('common.taxYear')} is required` }]}
          name="tax_year"
          key="taxYear"
          extra={
            errorMap?.taxYear && (
              <Paragraph className={styles.form_error}>{getFirstError(errorMap.taxYear)}</Paragraph>
            )
          }
          validateStatus={errorMap?.taxYear && 'error'}
        >
          <Select options={taxYearOptions} defaultValue={defaultYear} />
        </Form.Item>
        {errorMap?.entity && (
          <Paragraph className={styles.form_error}>{getFirstError(errorMap.entity)}</Paragraph>
        )}
        <div className={styles.form_action_row}>
          <Button
            className={styles.form_submit}
            type="primary"
            size="large"
            htmlType="submit"
            block
            disabled={loading}
          >
            {t2('buttons.add')}
          </Button>
        </div>
      </div>
    </Form>
  )
}

export default AddTaxOrganizerForm
