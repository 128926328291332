import _ from 'lodash'
import { useState, useEffect } from 'react'
import { Modal, Select, Form, Button } from 'antd'
import { useAuth, useAuthActions } from 'context/Auth'
import { useTranslation } from 'react-i18next'

import styles from './userdropdown.module.scss'

type IUser = {
  guid: string
  firstName: string
  lastName: string
  email: string
}

type Props = {
  open: boolean
  onClose: () => void
}

const MaskModal = ({ open, onClose }: Props) => {
  const { t } = useTranslation('common', { keyPrefix: 'user.dropdown' })
  const { loading: loadingUsers, loadUsers } = useAuthActions()
  const { response: responseMask, loading: loadingMask, maskAs } = useAuthActions()
  const { users } = useAuth()

  const [selectedGuid, setSelectedGuid] = useState('')
  const debounceSearch = _.debounce((search) => loadUsers(search), 200)

  useEffect(() => {
    loadUsers('')
  }, [])

  const handleCloseModal = () => {
    setSelectedGuid('')
    onClose()
  }

  const handleSubmit = async () => {
    await maskAs(selectedGuid)
    if (responseMask.ok) {
      window.location.href = '/'
    }
  }

  const userOptions = (users || []).map((item: IUser) => ({
    value: item.guid,
    label: `${item.firstName} ${item.lastName} <${item.email}>`,
  }))

  return (
    <Modal
      title="Mask User Support"
      visible={open}
      className={styles.users_modal}
      footer={null}
      destroyOnClose
      onCancel={handleCloseModal}
    >
      <Form className={styles.form_wrapper}>
        <Form.Item label="Mask as:">
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder="Select a user"
            onChange={setSelectedGuid}
            onSearch={debounceSearch}
            filterOption={false}
            loading={loadingUsers}
            options={userOptions}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" size="large" block disabled={loadingMask} onClick={handleSubmit}>
            {t('mask')}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default MaskModal
