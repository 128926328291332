import { TQQuestionType } from 'context/Questions'
import { useTranslation } from 'react-i18next'

type TProps = {
  question: TQQuestionType
  i: number
}

const PrintQuestion = ({ question, i }: TProps) => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'questionnaire' })
  const { t: t2 } = useTranslation('common')
  return (
    <>
      <div key={`${question.guid}title`} className="print-qst-title">
        <span>{i}.</span> {question.title}
      </div>
      {question.response ? (
        <>
          <div className="print-qst-checkboxes">
            <span>
              <input type="checkbox" defaultChecked={question.response.value === true} />
              {t2('switcher.yes')}
            </span>
            <span>
              <input type="checkbox" defaultChecked={question.response.value === false} />{' '}
              {t2('switcher.no')}
            </span>
          </div>
          <div className="print-qst-response">{question.response.response}</div>{' '}
        </>
      ) : (
        <i className="print-no-responses">{t('noResponses')}</i>
      )}
    </>
  )
}

export default PrintQuestion
