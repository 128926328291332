import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Flex } from 'reflexbox'
import { Button, QuestionIcon, SpinnerBackdrop } from 'components'
import { TTaxReturn, useTaxReturnsActions, useTaxReturns, TSigner } from 'context/TaxReturns'
import { useAuth } from 'context/Auth'
import Title from 'pages/TaxOrganizer/TaxReturn/components/Title'
import { ESteps } from '../../../steps'

import styles from '../authorize.module.scss'
import RadioCardItem from '../components/RadioCardItem'

type TProps = {
  latestTaxReturn: TTaxReturn | null
}

const ConfirmSigner = ({ latestTaxReturn }: TProps) => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'taxReturns.authorize.confirmSigner' })
  const { updateTaxReturn, checkSigned, setStep, loading } = useTaxReturnsActions()
  const [authorizedSigner, setAuthorizedSigner] = useState<TSigner | undefined>()

  const {
    state: { authorizationDocumentDetails },
  } = useTaxReturns()

  const { user } = useAuth()

  const changeRepresentative = async () => {
    const patchTaxReturnBody: Partial<TTaxReturn> = {
      ...latestTaxReturn,
      authorizeStep: 'AUTHORIZED_SIGNER',
    }
    await updateTaxReturn(latestTaxReturn?.guid!, patchTaxReturnBody)
  }

  const updateAuthorizeStep = async () => {
    const patchTaxReturnBody: Partial<TTaxReturn> = {
      ...latestTaxReturn,
      phase: 'FILING',
    }

    await updateTaxReturn(latestTaxReturn?.guid!, patchTaxReturnBody)
    setStep(ESteps.FILING, 'push')
  }

  useEffect(() => {
    if (!latestTaxReturn) return
    checkSigned(latestTaxReturn.guid)
  }, [])

  useEffect(() => {
    if (!authorizationDocumentDetails) return
    if (authorizationDocumentDetails.isSignedByAll) {
      updateAuthorizeStep()
      return
    }

    const latestUnsignedUser = authorizationDocumentDetails.signers
      .filter((signer) => signer.signedOn === null)
      .reduce((prev, current) => {
        return current.signingOrder < prev.signingOrder ? current : prev
      })

    if (user?.guid === latestUnsignedUser?.user.guid) {
      console.warn('****CONFIRMSIGNER****')
      const patchTaxReturnBody: Partial<TTaxReturn> = {
        ...latestTaxReturn,
        authorizeStep: 'E_SIGN',
      }
      updateTaxReturn(latestTaxReturn?.guid!, patchTaxReturnBody)
    } else {
      setAuthorizedSigner(latestUnsignedUser)
    }
  }, [authorizationDocumentDetails])

  return (
    <>
      {loading && <SpinnerBackdrop />}
      <Flex alignItems="center" justifyContent={['space-between']}>
        <Flex>
          <Title text={t('title')} />
          <QuestionIcon className={styles.question_icon} />
        </Flex>
        <Button size="large" htmlType="button" loading={false} onClick={changeRepresentative}>
          {t('changeRepresentative')}
        </Button>
      </Flex>
      {authorizedSigner && (
        <RadioCardItem
          key={authorizedSigner.userGuid}
          avatar={authorizedSigner.user.avatar}
          title={`${authorizedSigner.user.firstName} ${authorizedSigner.user.lastName}`}
          buttonText={t('resend')}
          showAvatar
          buttonClick={updateAuthorizeStep}
          selected
          hideRadio
        />
      )}
    </>
  )
}

export default ConfirmSigner
