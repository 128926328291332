import { Modal, Tag } from 'components'
import { Link } from 'react-router-dom'
import { Typography } from 'antd'
import { getTagColorByName } from 'utils'

const { Paragraph } = Typography

export const Tags = ({ tags }: { tags: TOption[] }) => (
  <div>
    {tags.map((tag) => {
      return (
        <Tag key={tag.value} style={getTagColorByName(tag.label)}>
          {tag.label}
        </Tag>
      )
    })}
  </div>
)

type TProps = {
  name: string
  guid: string
  showTooltip: boolean
  taxOrganizerGuid: string
  disabled?: boolean
}

export const DocName = ({
  name,
  guid,
  showTooltip,
  taxOrganizerGuid,
  disabled = false,
}: TProps) => {
  return (
    <Modal content={name} placement="top" overlayClassName={!showTooltip ? 'd-none' : ''}>
      <Paragraph
        ellipsis={{
          rows: 1,
          expandable: false,
          symbol: '>>',
        }}
        style={{ marginBottom: 0, width: '150px' }}
      >
        <Link
          to={{
            pathname: `/tax-organizer/${taxOrganizerGuid}/documents/${guid}`,
            state: {
              disabled,
            },
          }}
        >
          {name}
        </Link>
      </Paragraph>
    </Modal>
  )
}
