import { useEffect, useState } from 'react'
import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { Box, Flex } from 'reflexbox'

import { useDashboardActions } from 'context/Dashboard'
import { useTaxOrganizer } from 'context/TaxOrganizer'
import { useFinancialsActions, useFinancials, TFinSoftware } from 'context/Financials'

import { Section, SpinnerBackdrop } from 'components'

import Title from './components/Title'
import SystemsSteps from './components/SystemSteps'
import SystemsList from './components/SystemsList'

const Financials = () => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'financials' })
  const {
    taxOrganizer: {
      data: { financialSoftware },
    },
  } = useTaxOrganizer()
  const { loadFinancialsSoftware, loading } = useFinancialsActions()
  const {
    state: { data },
  } = useFinancials()
  const [spinner, setSpinner] = useState(loading)
  const { loadDocumentsTags, loadDocumentsCategories } = useDashboardActions()

  const currentSoftware = data.find(
    ({ slug }: { slug: string }) => slug === financialSoftware
  ) as TFinSoftware

  const currentSteps = currentSoftware ? currentSoftware.rightPart : data[0].rightPart

  useEffect(() => {
    loadFinancialsSoftware(financialSoftware)
    loadDocumentsTags()
    loadDocumentsCategories()
  }, [])

  return (
    <>
      <Section title={<Title />} titleIndent={18}>
        {spinner && <SpinnerBackdrop />}
        <Box mt={[4]}>
          <Typography.Title level={3}>{t('description')}</Typography.Title>
        </Box>
        <Flex flexWrap={['wrap']}>
          <SystemsList data={data} setSpinner={setSpinner} />
          <SystemsSteps currentSteps={currentSteps} />
        </Flex>
      </Section>
    </>
  )
}

export default Financials
