import { useEffect, useState } from 'react'
import { Form } from 'antd'
import { Box } from 'reflexbox'
import { useTranslation } from 'react-i18next'
import qs from 'qs'
import PasswordStrengthBar from 'react-password-strength-bar'
import { Button, PasswordField } from 'components'
import Alert, { TAlert } from 'components/Alert'

import { useLocation, useHistory } from 'react-router-dom'
import { ResetPasswordConfirmRequest, useAuthActions } from 'context/Auth'

import { getFormFieldsError } from 'utils/form'
import { LOGIN_PATH } from 'utils/routes'
import styles from './reset.password.confirm.module.scss'

const defaultAlertSettings: TAlert = {
  status: false,
  message: '',
  type: 'success',
}

const ResetPasswordConfirm = () => {
  const { t } = useTranslation('common', { keyPrefix: 'form' })
  const { resetPasswordConfirm, loading, response } = useAuthActions()
  const { search } = useLocation()
  const [password, setPassword] = useState('')
  const history = useHistory()
  const [form] = Form.useForm()
  const { token } = qs.parse(search, { ignoreQueryPrefix: true })
  const [alert, setAlert] = useState<TAlert>(defaultAlertSettings)

  const secondsToRedirect = 3

  const delayRedirect = () => {
    let counter: number = secondsToRedirect
    const intervalId = setInterval(() => {
      counter -= 1
      setAlert({
        status: true,
        message: t('resetPasswordConfirmSuccess', { number: counter }),
        type: 'success',
      })
      if (counter === 0) {
        clearInterval(intervalId)
        history.replace(LOGIN_PATH)
      }
    }, 1000)
  }

  const onSubmit = async (values: ResetPasswordConfirmRequest) => {
    setAlert(defaultAlertSettings)
    await resetPasswordConfirm({
      password: values.confirmPassword,
      token: token as string,
    })

    if (response.ok) {
      setAlert({
        status: true,
        message: t('resetPasswordConfirmSuccess', { number: secondsToRedirect }),
        type: 'success',
      })
      delayRedirect()
    } else {
      form.setFields(getFormFieldsError(response.data))
    }
  }

  useEffect(() => {
    if (!token) {
      history.replace(LOGIN_PATH)
    }
  }, [])

  return (
    <>
      <Form form={form} className={styles.form} onFinish={onSubmit}>
        {alert.status && (
          <Box mt={[2]} mb={[4]}>
            <Alert message={alert.message} type={alert.type} banner sameColor />
          </Box>
        )}
        <Form.Item
          name="password"
          rules={[
            () => ({
              validator() {
                if (password && password.length > 0) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error(t('requiredField', { field: t('password') })))
              },
            }),
          ]}
          hasFeedback
        >
          <PasswordField
            placeholder={t('enterNewPassword')}
            size="large"
            className={styles.input}
            type="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value)
            }}
          />
        </Form.Item>
        <PasswordStrengthBar password={password} />
        <Form.Item
          name="confirmPassword"
          dependencies={['password']}
          hasFeedback
          rules={[
            { required: true, message: t('confirmPasswordRequired') },
            () => ({
              validator(_, value) {
                if (!value || password === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error(t('passwordsNotMatch')))
              },
            }),
          ]}
        >
          <PasswordField
            placeholder={t('confirmNewPassword')}
            size="large"
            className={styles.input}
            type="password"
          />
        </Form.Item>
        <Button htmlType="submit" type="primary" size="large" block loading={loading}>
          {t('resetPassword')}
        </Button>
      </Form>
    </>
  )
}

export default ResetPasswordConfirm
