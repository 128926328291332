import { useEffect, useState } from 'react'
import { Form, Spin } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import Icon from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import { useDocumentActions, DocumentThread, ThreadMessage } from 'context/Document'

import { Button, Checkbox, TextField } from 'components'

import { ReactComponent as Telegram } from 'assets/images/telegram.svg'
import Messages from './Messages'

import styles from '../../documentview.module.scss'

type Props = {
  thread: DocumentThread
  onSubmitSuccess: () => void
  setIsChildModalVisible: (visible: boolean) => void
  setReloadThreads: (flag: boolean | ((newFlag: boolean) => boolean)) => void
}

const NewThreadForm = ({
  thread,
  onSubmitSuccess,
  setIsChildModalVisible,
  setReloadThreads,
}: Props) => {
  const { addMessage, loading, response } = useDocumentActions()
  const { loadThreadMessages, loading: messagesLoading } = useDocumentActions()
  const { markThreadAsRead } = useDocumentActions()
  const { updateThread, loading: updateLoading } = useDocumentActions()
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'document.commentPopover' })
  const { t: t2 } = useTranslation('common', { keyPrefix: 'form' })

  const [messages, setMessages] = useState<ThreadMessage[]>([])

  const onSubmit = async (values: { message: string }) => {
    await addMessage(thread.guid, values.message)
    if (response.ok) {
      onSubmitSuccess()
    }
  }

  const handleMarkAsResolved = async (e: CheckboxChangeEvent) => {
    const isResolved = e.target.checked
    await updateThread(thread.guid, { isResolved })
    if (isResolved) {
      onSubmitSuccess()
    }
  }

  const getMessages = async () => {
    const messagesList = await loadThreadMessages(thread.guid)
    setMessages(messagesList.reverse())
  }

  useEffect(() => {
    getMessages()
    markThreadAsRead(thread.guid)
  }, [])

  if (messagesLoading)
    return (
      <div className="text-center">
        <Spin />
      </div>
    )

  return (
    <Form onFinish={onSubmit} onClick={(e) => e.stopPropagation()}>
      <Messages
        setIsChildModalVisible={setIsChildModalVisible}
        onSubmitSuccess={onSubmitSuccess}
        thread={thread}
        messages={messages}
        setReloadThreads={setReloadThreads}
      />
      <Form.Item
        name="message"
        rules={[{ required: true, message: t2('requiredField', { field: t2('message') }) }]}
        style={{ marginBottom: 0 }}
      >
        <TextField
          placeholder="Comment"
          size="large"
          className={styles.thread_submit}
          suffix={
            <Button
              htmlType="submit"
              type="primary"
              ghost
              size="large"
              className={styles.submit}
              loading={loading}
            >
              <Icon component={Telegram} />
            </Button>
          }
        />
      </Form.Item>
      <div className={styles.thread_footer}>
        <Checkbox
          defaultChecked={thread.isResolved}
          disabled={updateLoading}
          onChange={handleMarkAsResolved}
          size="small"
        >
          {t('markAsResolved')}
        </Checkbox>
      </div>
    </Form>
  )
}

export default NewThreadForm
