import { Key, useState } from 'react'
import { Dropdown, Menu, message as antdMessage } from 'antd'
import { useTranslation } from 'react-i18next'
import { DashOutlined, LoadingOutlined } from '@ant-design/icons'
import { reloadData } from 'utils'

import { TClientInviteParams, useEntityCPAsActions } from 'context/EntityCPAs'

import Button from 'components/Button'

import { DEFAULT_PARAMS } from '../constants'

import styles from '../../dashboard.clients.module.scss'

type TProps = {
  cpaFirmGuid: string
  inviteGuid: string
  dataOnPageCount: number
  setUninvitedSelectedRows: (keys: Key[]) => void
  setParams: (
    value: TClientInviteParams | ((prevState: TClientInviteParams) => TClientInviteParams)
  ) => void
}

const Actions = ({
  cpaFirmGuid,
  inviteGuid,
  dataOnPageCount,
  setUninvitedSelectedRows,
  setParams,
}: TProps) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'clients.table' })
  const {
    sendUninvited,
    loading: sendUninvitedLoading,
    response: sendUninvitedResponse,
  } = useEntityCPAsActions()
  const [dropdownVisible, setDropdownVisible] = useState<string>('')

  const onSend = async () => {
    const uninvitedSelectedRows = [inviteGuid]
    await sendUninvited(cpaFirmGuid, { guids: uninvitedSelectedRows })
    if (sendUninvitedResponse.ok) {
      setUninvitedSelectedRows([])
      antdMessage.success(t('messageSendUninvited.one'))
      reloadData(dataOnPageCount === uninvitedSelectedRows.length, setParams, DEFAULT_PARAMS)
    }
  }

  return (
    <Dropdown
      trigger={['click']}
      placement="bottomLeft"
      visible={dropdownVisible === inviteGuid}
      onVisibleChange={(value: boolean) => {
        if (value) {
          setDropdownVisible(inviteGuid)
        } else {
          setDropdownVisible('')
        }
      }}
      overlay={() => (
        <Menu theme="dark">
          <Menu.Item
            key="send"
            onClick={({ domEvent }) => {
              domEvent.stopPropagation()
              onSend()
            }}
            disabled={sendUninvitedLoading}
          >
            {sendUninvitedLoading && <LoadingOutlined style={{ marginRight: '8px' }} />}
            {t('send')}
          </Menu.Item>
        </Menu>
      )}
    >
      <Button type="text" onClick={(e) => e.stopPropagation()}>
        <DashOutlined className={styles.actions} />
      </Button>
    </Dropdown>
  )
}

export default Actions
