import { Image } from 'antd'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'

import { useAuth, useAuthActions } from 'context/Auth'

import { Button, Upload } from 'components'

import { convertFileToBase64 } from 'utils/files'
import styles from '../../dashboard.firm.profile.module.scss'

const Logo = () => {
  const {
    CPA: { userRole, cpaFirm },
  } = useAuth()
  const { updateCpaFirmLogo, loading } = useAuthActions()
  const { t } = useTranslation('dashboard', { keyPrefix: 'firm.logo' })
  const [image, setImage] = useState<string>()

  const onImageChange = async (file: File) => {
    const base64 = await convertFileToBase64(file)
    setImage(base64)
    await updateCpaFirmLogo(cpaFirm!.guid, file)
  }

  const getImageSrc = () => cpaFirm!.letterheadImage ?? image

  return (
    <div className={styles.logo}>
      <div className={styles.logo_title}>{t('title')}</div>
      <div className={styles.logo_description}>{t('description')}</div>
      {cpaFirm!.letterheadImage || image ? (
        <>
          <Image src={getImageSrc()} wrapperClassName={styles.logo_image} preview={false} />
          <Upload
            fileList={[]}
            accept="image/png,image/jpeg"
            multiple={false}
            onChange={(info: any) => onImageChange(info.file)}
            disabled={userRole === 'MEMBER'}
          >
            <Button
              type="primary"
              size="large"
              ghost
              loading={loading}
              disabled={userRole === 'MEMBER'}
            >
              {t('submit')}
            </Button>
          </Upload>
        </>
      ) : (
        <Upload
          className={styles.logo_upload}
          fileList={[]}
          accept="image/png,image/jpeg"
          multiple={false}
          hint={t('hint')}
          loading={loading}
          disabled={loading}
          onChange={(info: any) => onImageChange(info.file)}
        />
      )}
    </div>
  )
}

export default Logo
