import { FC } from 'react'
import { Upload as AntdUpload, UploadProps as UploadPropsAntd } from 'antd'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { ReactComponent as UploadIcon } from 'assets/images/upload-2.svg'
import { SpinnerBackdrop } from 'components'

import styles from './upload.module.scss'

export type UploadProps = UploadPropsAntd & {
  hint?: string
  loading?: boolean
}

const Upload: FC<UploadProps> = ({ children, className, hint, loading, ...props }) => {
  const { t } = useTranslation()

  const defaultProps = {
    beforeUpload: () => false,
    multiple: true,
    showUploadList: false,
    accept: '.pdf,.doc,.docx,.xls,.xlsx,image/png,image/jpeg,.QBW,.QBM,.QBB,.txt,.csv',
    className: cn(styles.box, className),
    ...props,
  }

  return children ? (
    <AntdUpload {...defaultProps}>{children}</AntdUpload>
  ) : (
    <AntdUpload.Dragger {...defaultProps}>
      <div className={styles.content}>
        <UploadIcon className={styles.icon} />
        <div className={styles.drop_text}>{t('upload.dropText')}</div>
        <div className={styles.or_text}>{t('upload.or')}</div>
        <div className={styles.click_text}>{t('upload.clickText')}</div>
        <div className={styles.hint}>{hint || t('upload.hint')}</div>
        {loading && <SpinnerBackdrop />}
      </div>
    </AntdUpload.Dragger>
  )
}

export default Upload
