import { useFetch } from 'use-http'
import qs from 'qs'

import { toSnake } from 'utils'

import { EnumFinSoftware } from 'context/Financials'
import useDocumentsActions from './documents'
import { ActionTypes } from './action-types'
import {
  useTaxOrganizerDispatch,
  PhaseActionSlug,
  PhaseName,
  TCPAContactsQueryParams,
  TActivityFeedQueryParams,
} from '..'
import { UpdateTaxOrganizer } from '../types'

const useTaxOrganizerActions = () => {
  const dispatch = useTaxOrganizerDispatch()
  const { get, post, del, patch, response, loading, error, abort } = useFetch()

  const loadEngagementLetterSigningInformation = async (guid: string) => {
    const engagementLetterSigningInformation = await get(
      `/tax-organizers/${guid}/engagement-letter/is-signed`
    )
    if (response.ok) {
      dispatch({
        type: ActionTypes.LOAD_ENGAGEMENT_LETTER_INFO,
        payload: engagementLetterSigningInformation,
      })
    }
    return engagementLetterSigningInformation
  }

  const generateEngagementLetter = async (guid: string) => {
    const engagementLetter = await post(`/tax-organizers/${guid}/engagement-letter/start`)
    return engagementLetter
  }

  const loadTaxOrganizer = async (id: string) => {
    const taxOrganizerInfo = await get(`/tax-organizers/${id}`)
    if (response.ok) {
      dispatch({ type: ActionTypes.LOAD_TAX_ORGANIZER, payload: taxOrganizerInfo })
    } else {
      dispatch({ type: ActionTypes.LOAD_TAX_ORGANIZER, payload: null })
    }
  }

  const updateTaxOrganizer = async (guid: string, params: UpdateTaxOrganizer) => {
    const result = await patch(`/tax-organizers/${guid}`, toSnake(params))
    if (response.ok) {
      dispatch({ type: ActionTypes.UPDATE_TAX_ORGANIZER, payload: result })
    }
  }

  const checkPhaseAction = async (guid: string, action: PhaseActionSlug) => {
    await post(`/tax-organizers/${guid}/actions`, {
      action,
    })
    if (response.ok) {
      dispatch({ type: ActionTypes.CHECK_PHASE_ACTION, payload: { slug: action } })
    }
  }
  const unCheckPhaseAction = async (guid: string, action: PhaseActionSlug) => {
    await del(`/tax-organizers/${guid}/actions/${action}`)
    if (response.ok) {
      dispatch({ type: ActionTypes.UNCHECK_PHASE_ACTION, payload: { slug: action } })
    }
  }

  const submitPhase = async (guid: string, phaseName: PhaseName) => {
    await post(`/tax-organizers/${guid}/submit`, { phase: phaseName })
    if (response.ok) {
      const payload = {
        phaseName,
      }
      dispatch({ type: ActionTypes.SUBMIT_PHASE, payload })

      loadTaxOrganizer(guid)
    }
  }

  const setFinancialSoftware = async (guid: string, slug: EnumFinSoftware) => {
    const params = {
      financialSoftware: slug,
    }
    const { financialSoftware } = await patch(`tax-organizers/${guid}`, toSnake(params))
    if (response.ok) {
      dispatch({
        type: ActionTypes.SET_FINANCIAL_SOFTWARE,
        payload: { financialSoftware },
      })
    }
  }

  const requestExtension = async (guid: string) => {
    await post(`/tax-organizers/${guid}/extend`)
    if (response.ok) {
      dispatch({ type: ActionTypes.REQUEST_EXTENSION })
    }
  }

  const cancelExtention = async (guid: string) => {
    await del(`/tax-organizers/${guid}/extend`)
    if (response.ok) {
      dispatch({ type: ActionTypes.CANCEL_EXTENSION })
    }
  }

  const loadCPAContacts = async (guid: string, params?: TCPAContactsQueryParams) => {
    const query = qs.stringify(params, { addQueryPrefix: !!params })
    const contacts = await get(`/tax-organizers/${guid}/cpa-contacts${query}`)
    if (response.ok) {
      dispatch({ type: ActionTypes.LOAD_CPA_CONTACTS, payload: contacts })
    }
  }

  const loadActivityFeed = async (params?: TActivityFeedQueryParams) => {
    const query = qs.stringify(toSnake({ ...params }), { addQueryPrefix: !!params })
    const feed = await get(`/activity_feed/events${query}`)
    if (response.ok) {
      dispatch({
        type: ActionTypes.LOAD_ACTIVITY_FEED,
        payload: feed,
      })
    }
  }

  return {
    loadTaxOrganizer,
    updateTaxOrganizer,
    checkPhaseAction,
    unCheckPhaseAction,
    loadEngagementLetterSigningInformation,
    generateEngagementLetter,
    submitPhase,
    setFinancialSoftware,
    requestExtension,
    cancelExtention,
    loadCPAContacts,
    loadActivityFeed,
    loading,
    response,
    error,
    abort,
  }
}

export default useTaxOrganizerActions
export { ActionTypes, useDocumentsActions }
