import { ActionTypes } from '../actions'
import { TaxOrganizer, IAction, Phase, PhaseAction } from '..'
import { EnumPhaseStatus, TActivityFeed, TCPAEntityContact } from '../types'

export type EngagementLetterSigningInformation = {
  signedByClientOn?: string
  signedByPreparerOn?: string
}

export type ITaxOrganizerState = {
  data: TaxOrganizer
  activityFeed: TActivityFeed | null
  cpaContacts: {
    data: TCPAEntityContact[]
    count: number
  }
  engagementLetterSigningInformation?: EngagementLetterSigningInformation
}

export const initialTaxOrganizerState: ITaxOrganizerState = {
  data: undefined!,
  cpaContacts: {
    data: [],
    count: 0,
  },
  activityFeed: null,
  engagementLetterSigningInformation: undefined,
}

export const taxOrganizerReducer = (
  state: ITaxOrganizerState = initialTaxOrganizerState,
  action: IAction
) => {
  switch (action.type) {
    case ActionTypes.LOAD_TAX_ORGANIZER:
      return {
        ...state,
        data: action.payload,
      }

    case ActionTypes.UPDATE_TAX_ORGANIZER:
      return {
        ...state,
        data: action.payload,
      }

    case ActionTypes.CHECK_PHASE_ACTION:
      return {
        ...state,
        data: {
          ...state.data,
          phases: [
            ...state.data.phases.map((phase: Phase): Phase => {
              return {
                ...phase,
                actions: phase.actions.map((_action: PhaseAction): PhaseAction => {
                  if (_action.slug === action.payload.slug) {
                    return {
                      ..._action,
                      completed: true,
                    }
                  }
                  return _action
                }),
              }
            }),
          ],
        },
      }

    case ActionTypes.UNCHECK_PHASE_ACTION:
      return {
        ...state,
        data: {
          ...state.data,
          phases: [
            ...state.data.phases.map((phase: Phase): Phase => {
              return {
                ...phase,
                actions: phase.actions.map((_action: PhaseAction): PhaseAction => {
                  if (_action.slug === action.payload.slug) {
                    return {
                      ..._action,
                      completed: false,
                    }
                  }
                  return _action
                }),
              }
            }),
          ],
        },
      }

    case ActionTypes.SUBMIT_PHASE:
      return {
        ...state,
        data: {
          ...state.data,
          phases: [
            ...state.data.phases.map((phase: Phase): Phase => {
              if (phase.name === action.payload.phaseName) {
                return {
                  ...phase,
                  status: EnumPhaseStatus.WAITING,
                }
              }
              return {
                ...phase,
              }
            }),
          ],
        },
      }

    case ActionTypes.SET_FINANCIAL_SOFTWARE:
      return {
        ...state,
        data: {
          ...state.data,
          financialSoftware: action.payload.financialSoftware,
        },
      }

    case ActionTypes.REQUEST_EXTENSION:
      return {
        ...state,
        data: {
          ...state.data,
          isExtended: true,
        },
      }

    case ActionTypes.CANCEL_EXTENSION:
      return {
        ...state,
        data: {
          ...state.data,
          isExtended: false,
        },
      }

    case ActionTypes.LOAD_CPA_CONTACTS:
      return {
        ...state,
        cpaContacts: {
          count: action.payload.count,
          data: action.payload.results,
        },
      }

    case ActionTypes.LOAD_ENGAGEMENT_LETTER_INFO:
      return {
        ...state,
        engagementLetterSigningInformation: action.payload,
      }

    case ActionTypes.LOAD_ACTIVITY_FEED:
      return {
        ...state,
        activityFeed: action.payload,
      }

    default:
      return state
  }
}
