import { useEffect, useState } from 'react'
import { Box, Flex } from 'reflexbox'
import { useTranslation } from 'react-i18next'
import { Typography, Button } from 'antd'
import { DownloadOutlined, FileOutlined } from '@ant-design/icons'

import { useTaxReturns, useTaxReturnsActions, TTaxReturn } from 'context/TaxReturns'

import { useLocation } from 'react-router-dom'
import qs from 'qs'
import { Upload, SpinnerBackdrop } from 'components'
import UploadModalManualSign from './UploadModalManualSign'
import { ESteps } from '../../../steps'

const ManualSign = () => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'taxReturns.authorize.sign' })
  const [files, setFiles] = useState<any>([])
  const [filesUploaded, setFilesUploaded] = useState(false)
  const {
    loadSteps,
    setStep,
    updateTaxReturn,
    loadTaxReturnDocuments,
    checkSigned,
    generateEFileAuthorizationLetter,
  } = useTaxReturnsActions()
  const { search } = useLocation()
  const searchParams = qs.parse(search, { ignoreQueryPrefix: true })
  const [modal, setModal] = useState(false)

  const {
    state: {
      loading,
      steps: data,
      step,
      taxReturns,
      authorizationDocumentDetails,
      taxReturns: { latest: latestTaxReturn },
    },
  } = useTaxReturns()

  useEffect(() => {
    if (
      searchParams.step !== undefined &&
      step.current !== parseInt(searchParams.step as string, 10)
    ) {
      setStep(Number(searchParams.step), 'push')
    }
  }, [searchParams.step])

  useEffect(() => {
    if (taxReturns.data.length) {
      const taxReturnsStep = data.find(
        (d) => d.title?.toLowerCase() === latestTaxReturn?.phase?.toLowerCase()
      )

      setStep(taxReturnsStep ? taxReturnsStep.id - 1 : 0, 'push')
    }
  }, [taxReturns])

  useEffect(() => {
    loadSteps()
    if (!latestTaxReturn) return
    generateEFileAuthorizationLetter(latestTaxReturn.guid).then(() => {
      checkSigned(latestTaxReturn.guid)
    })
  }, [])

  const handleNext = async () => {
    if (!authorizationDocumentDetails || !latestTaxReturn) return
    await checkSigned(latestTaxReturn.guid)

    if (!authorizationDocumentDetails.isSignedByAll) {
      const patchTaxReturnBody: Partial<TTaxReturn> = {
        ...latestTaxReturn,
        authorizeStep: 'CONFIRM_SIGNER',
      }

      await updateTaxReturn(latestTaxReturn?.guid!, patchTaxReturnBody)
    } else {
      const patchTaxReturnBody: Partial<TTaxReturn> = {
        ...latestTaxReturn,
        phase: 'FILING',
      }

      await updateTaxReturn(latestTaxReturn?.guid!, patchTaxReturnBody)
      setStep(ESteps.FILING, 'push')
    }
  }

  const handleUpload = ({ fileList }: any) => {
    setFiles(
      fileList.map((file: any) => ({
        ...file,
        fileUploadOverride: {
          uploadUrl: `/tax-organizers/tax-return/${latestTaxReturn?.guid}/efile-authorization/upload`,
        },
      }))
    )
    setModal(true)
    setFilesUploaded(!!fileList.length)
  }

  return (
    <>
      {loading && <SpinnerBackdrop />}
      <Box>
        <Flex alignItems="center" justifyContent={['space-between']}>
          <Flex>
            <Typography.Title level={4}>{t('title')}</Typography.Title>
          </Flex>
          <Button type="primary" size="large" onClick={handleNext} disabled={!filesUploaded}>
            {t('proceed')}
          </Button>
        </Flex>
      </Box>
      <Flex
        mb={['22px']}
        px={[3, 4]}
        flexWrap="wrap"
        alignItems={['flex-start', 'flex-start']}
        justifyContent={['space-around']}
      >
        <Box p={3} width={1 / 2}>
          <Typography.Title level={5}>{t('subTitle1')}</Typography.Title>
          <Button
            type="default"
            icon={<DownloadOutlined />}
            size="large"
            href={
              authorizationDocumentDetails
                ? authorizationDocumentDetails.signedFileUrl ||
                  authorizationDocumentDetails.unsignedFileUrl
                : ''
            }
            target="blank"
          >
            {t('download')}
          </Button>
        </Box>
        <Box p={3} width={1 / 2} alignItems={['flex-end']}>
          <Typography.Title level={5}>{t('subTitle2')}</Typography.Title>
          <Box width={['100%', '50%']}>
            <Upload fileList={[]} onChange={handleUpload} />
            {modal && (
              <UploadModalManualSign
                fileList={files}
                onHide={() => setModal(false)}
                setFilesUploaded={setFilesUploaded}
                onFinishUploading={() => {
                  loadTaxReturnDocuments(latestTaxReturn!)
                }}
              />
            )}
            {files && files.length > 0 && (
              <Flex
                mt={[3]}
                p={[2]}
                style={{
                  opacity: '0.5',
                  borderRadius: '8px',
                  border: '1px solid lightgray',
                  flexWrap: 'wrap',
                }}
              >
                {files.map((document: any) => (
                  <Flex key={document.uid} alignItems={['baseline']} mr={[3]}>
                    <FileOutlined />
                    <Box ml={[1]}>{document.name}</Box>{' '}
                  </Flex>
                ))}
              </Flex>
            )}
          </Box>
        </Box>
      </Flex>
    </>
  )
}

export default ManualSign
