import { useEffect, useState } from 'react'
import { Button } from 'components'
import { Form, Select, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { debounce } from 'lodash'

import { initForm, saveFormToSession } from 'utils/form'
import { defaultYear } from 'utils/taxOrganizer'
import { browserSession } from 'utils/session'

import { TaxOrganizerForm, useEntityActions, TEntity } from 'context/Entity'
import { useDashboard } from 'context/Dashboard'
import { useAuth } from 'context/Auth'
import { useCPAFirmActions } from 'context/CPAFirm'

import useUserType from 'hooks/useUserType'

import styles from '../../dashboard.tax.module.scss'

const { Title, Paragraph } = Typography

type Props = {
  setVisible: (visible: boolean) => void
  reloadTaxOrganizers: () => void
}

const AddTaxOrganizerForm = ({ setVisible, reloadTaxOrganizers }: Props) => {
  const [form] = Form.useForm()
  const { createTaxOrganizer, loadEntities, loading, response } = useEntityActions()
  const { loadCpaEntities } = useCPAFirmActions()
  const {
    taxYears: { range: taxYears },
  } = useDashboard()
  const [loadedEntities, setLoadedEntities] = useState<TEntity[]>([])
  const {
    CPA: { cpaFirm },
  } = useAuth()
  const { t } = useTranslation('entity')
  const { t: t2 } = useTranslation('common')
  const userType = useUserType()

  const taxYearOptions = taxYears.map((year) => ({ label: year, value: year }))

  const entitySearchHandler = async (entitySearch: string) => {
    let matchingEntities
    if (userType === 'ENTITY') {
      matchingEntities = await loadEntities({ search: entitySearch })
    } else {
      matchingEntities = await loadCpaEntities(cpaFirm!.guid, { search: entitySearch })
    }
    setLoadedEntities(matchingEntities.results)
  }
  const debouncedEntitySearchHandler = debounce(entitySearchHandler, 300)

  useEffect(() => {
    entitySearchHandler('')
  }, [])

  const onSubmit = async (payload: TaxOrganizerForm) => {
    const taxOrganizerResponse = await createTaxOrganizer(payload.entity, payload)
    browserSession.removeItem('form:dashboardAddTaxOrganizer')
    if (!response.ok) {
      const errors = Object.entries(taxOrganizerResponse).map(([key, value]) => {
        return {
          name: key === 'detail' ? 'entity' : key,
          errors: Array.isArray(value) ? value : [value],
        }
      })

      // @ts-ignore
      form.setFields(errors)
    } else {
      setVisible(false)
      reloadTaxOrganizers()
    }
  }

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      className={styles.form_wrapper}
      onValuesChange={(changedValues: any, allValues: any) =>
        saveFormToSession('form:dashboardAddTaxOrganizer', allValues)
      }
      initialValues={initForm('form:dashboardAddTaxOrganizer')}
    >
      <Title className={styles.form_title} level={4}>
        {t2('buttons.add')} {t('common.taxOrganizer')}
      </Title>
      <div className={styles.form_fields}>
        <Paragraph className={styles.form_label}>{t2('entity')}</Paragraph>
        <Form.Item
          key="entity"
          name="entity"
          rules={[{ required: true, message: `${t2('entity')} is required` }]}
        >
          <Select
            showSearch
            filterOption={false}
            className={styles.entity_search}
            options={loadedEntities?.map((entity) => ({
              label: entity.title,
              value: entity.guid,
            }))}
            onSearch={debouncedEntitySearchHandler}
          />
        </Form.Item>
        <Paragraph className={styles.form_label}>{t('common.taxYear')}</Paragraph>
        <Form.Item
          rules={[{ required: true, message: `${t('common.taxYear')} is required` }]}
          name="tax_year"
          key="taxYear"
        >
          <Select options={taxYearOptions} defaultValue={defaultYear} />
        </Form.Item>
        <div className={styles.form_action_row}>
          <Button
            className={styles.form_submit}
            type="primary"
            size="large"
            htmlType="submit"
            block
            disabled={loading}
          >
            {t2('buttons.add')}
          </Button>
        </div>
      </div>
    </Form>
  )
}

export default AddTaxOrganizerForm
