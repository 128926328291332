import { Empty, Skeleton } from 'antd'
import { Flex } from 'reflexbox'
import { useTranslation } from 'react-i18next'

import SidebarForm from './components/Form'
import Title from './components/Title'
import Threads from './components/Threads'

import useData from './hooks/useData'
import usePagination from './hooks/usePagination'

import styles from './dashboard.sidebar.module.scss'

type TProps = { sidebarCollapsed: boolean }

const SidebarOverlay = ({ sidebarCollapsed }: TProps) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'sidebar' })
  const { loading, chunk, count, params, setParams } = useData(sidebarCollapsed)
  const { threads, nextStep } = usePagination({
    sidebarCollapsed,
    params,
    chunk,
    count,
  })
  const fetchMoreData = () => {
    if (nextStep) {
      setParams((currentParams: any) => ({ ...currentParams, page: ++currentParams.page }))
    }
  }

  const resetData = () => {
    setParams((currentParams: any) => ({ ...currentParams, page: 1 }))
  }

  return (
    <Flex
      flexDirection={['column']}
      width={['100%', 310]}
      className={styles.sidebar}
      style={{
        right: sidebarCollapsed ? '-110%' : '0px',
      }}
    >
      <Title />
      <SidebarForm setParams={setParams} sidebarCollapsed={sidebarCollapsed} />
      {loading && params.page === 1 && (
        <>
          <Flex
            justifyContent={['flex-start']}
            alignItems={['center']}
            flexDirection={['column']}
            px={[3]}
          >
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </Flex>
        </>
      )}
      {!threads.length && (
        <Flex
          height={['300px']}
          width={['100%']}
          justifyContent={['center']}
          alignItems={['center']}
        >
          <Empty description={<>{t('empty')}</>} />
        </Flex>
      )}
      <Threads
        threads={threads}
        loading={loading}
        fetchMoreData={fetchMoreData}
        resetData={resetData}
        nextStep={nextStep}
      />
    </Flex>
  )
}

export default SidebarOverlay
