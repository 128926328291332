export const steps = [
  { id: 1, title: 'info', hidden: false },
  { id: 2, title: 'upload', hidden: false },
  {
    id: 3,
    title: 'categorize',
    hidden: false,
  },
  {
    id: 4,
    title: 'review',
    hidden: false,
  },
  { id: 5, title: 'authorize', hidden: false },
  {
    id: 6,
    title: 'filing',
    hidden: true,
  },
  {
    id: 7,
    title: 'filedReturn',
    hidden: false,
  },
  {
    id: 8,
    title: 'changesRequested',
    hidden: true,
  },
]
