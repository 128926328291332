import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { Alert } from 'components'

import { FilingStateEnum } from 'context/TaxReturns'
import useUserType from 'hooks/useUserType'
import { TFilingStateProps } from '../types'

import styles from '../../../dashboard.tax_return.module.scss'

const FileFailed = ({ taxReturnUpdate }: TFilingStateProps) => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'taxReturns.fieldReturn' })
  const userType = useUserType()

  return (
    <Alert
      className={styles.tax_return_review_guide}
      message={
        <div className={styles.wrapper}>
          <div className={styles.content_alert}>
            <div>
              <div className={styles.title}>{t('fileFailedTitle')}</div>

              <div>{t('fileFailedDescription')}</div>
            </div>
            {userType === 'CPA' && (
              <div className={styles.actions_btn}>
                <Button
                  type="primary"
                  size="small"
                  style={{ width: '150px' }}
                  onClick={() => taxReturnUpdate(FilingStateEnum.PAPER_PENDING_CONFIRMATION)}
                >
                  {t('switchToPaperFile')}
                </Button>
                <Button
                  type="primary"
                  size="small"
                  style={{ width: '150px' }}
                  onClick={() => taxReturnUpdate(FilingStateEnum.PAPER_PENDING_CONFIRMATION)}
                >
                  {t('retryEFile')}
                </Button>
              </div>
            )}
          </div>
        </div>
      }
      type="error"
    />
  )
}

export default FileFailed
