import { useEffect, useState } from 'react'
import { loadStripe, Stripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

import {
  useTaxReturns,
  useTaxReturnsActions,
  VerificationSession,
  VerificationStatus,
} from 'context/TaxReturns'
import { useAuth } from 'context/Auth'
import Verify from './Verify'

const ClientVerfication = () => {
  const { createVerificationSession } = useTaxReturnsActions()
  const {
    state: {
      taxReturns: { latest: latestTaxReturn },
      authorizationDocumentDetails,
    },
  } = useTaxReturns()
  const { user } = useAuth()

  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null)
  const [verficationSession, setVerficationSession] = useState<VerificationSession | null>(null)
  const [signerStatus, setSignerStatus] = useState<VerificationStatus | null>(null)
  const [verificationStarted, setVerificationStarted] = useState<boolean>(false)

  const startVerification = async () => {
    if (!latestTaxReturn) return
    const result = await createVerificationSession(latestTaxReturn.guid)
    setVerficationSession(result)
  }

  useEffect(() => {
    if (verficationSession) {
      setStripePromise(loadStripe(verficationSession.publicKey))
    }
  }, [verficationSession])

  useEffect(() => {
    const currentSigner = authorizationDocumentDetails.signers.find(
      (signer) => signer.user.guid === user?.guid
    )
    if (!currentSigner || authorizationDocumentDetails.isSignedByAll) return

    setSignerStatus(currentSigner.idVerificationStatus)
    if (
      (currentSigner.idVerificationStatus === null ||
        currentSigner.idVerificationStatus === VerificationStatus.CREATED) &&
      !verificationStarted
    ) {
      startVerification()
      setVerificationStarted(true)
    }
  }, [authorizationDocumentDetails])

  return (
    <div>
      <Elements stripe={stripePromise}>
        <Verify verficationSession={verficationSession} signerStatus={signerStatus} />
      </Elements>
    </div>
  )
}

export default ClientVerfication
