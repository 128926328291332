import { Spin, SpinProps } from 'antd'
import cn from 'classnames'

import styles from './spinner.backdrop.module.scss'

export interface SpinnerProps extends SpinProps {
  mode?: 'local' | 'global'
  transparent?: boolean
}

const SpinnerBackdrop = ({ mode, transparent = true, ...props }: SpinnerProps) => {
  const spinnerStyle = mode === 'global' ? styles.container_global : styles.container_local
  return (
    <div className={cn(spinnerStyle, transparent ? '' : styles.not_transparent)}>
      <Spin {...props} />
    </div>
  )
}

export default SpinnerBackdrop
