import { useTranslation } from 'react-i18next'
import { Form, Image } from 'antd'
import { Box, Flex } from 'reflexbox'

import { Alert, Button, TextField } from 'components'
import { useCPAFirmActions, useCPAFirm } from 'context/CPAFirm'

import { useState } from 'react'

import { useAuth } from 'context/Auth'
import styles from '../../dashboard.firm.profile.module.scss'

type TAlertTypes = 'success' | 'error' | undefined
type TAlert = { status: boolean; message?: string; type?: TAlertTypes }

const defaultAlertSettings: TAlert = {
  status: false,
  message: '',
  type: 'success',
}

const TradeShow = () => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'firm.tradeShow' })

  const { tradeShow } = useCPAFirm()
  const {
    CPA: { cpaFirm },
  } = useAuth()
  const { redeemTradeShowCode, loadTradeShow, loading, response } = useCPAFirmActions()

  const [form] = Form.useForm()
  const [alert, setAlert] = useState<TAlert>(defaultAlertSettings)

  const promoCodeDisabled =
    tradeShow?.clientsInvitedCount === undefined ? true : tradeShow?.clientsInvitedCount < 10

  const hasLogo = tradeShow?.tradeShowPromotion?.logo !== undefined

  const onSubmit = async (values: { code: string }) => {
    setAlert(defaultAlertSettings)
    await redeemTradeShowCode(cpaFirm!.guid, values.code)

    if (response.ok) {
      setAlert({
        status: true,
        message: t('codeRedeemed'),
        type: 'success',
      })

      // Reload the tradeshow promotion to get Code2.
      loadTradeShow(cpaFirm!.guid)
    } else {
      setAlert({
        status: true,
        message: t('codeNotRedeemed'),
        type: 'error',
      })
    }
  }

  return (
    <div className={styles.logo}>
      <div className={styles.logo_title}>{t('tradeShowPromotion')}</div>
      {hasLogo && (
        <Flex justifyContent={['space-between']} alignItems="center">
          <Box lineHeight={['20px']} fontSize={['16px']}>
            {t('tradeShowPromotion')}:
          </Box>
          <Image width={120} height={60} src={tradeShow?.tradeShowPromotion.logo} />
        </Flex>
      )}
      <Flex justifyContent={['space-between']} mb={['24px']} mt={['16px']} marginBottom={['32px']}>
        <Box lineHeight={['20px']} fontSize={['16px']}>
          {t('newClientCount')}:
        </Box>
        <Box lineHeight={['20px']} fontSize={['16px']}>
          {t('clientCount', { count: tradeShow?.clientsInvitedCount })}
        </Box>
      </Flex>
      <Flex justifyContent={['space-between']} mb={['16px']}>
        <Box lineHeight={['33px']} fontSize={['16px']}>
          {t('discountCode')}:
        </Box>
        {tradeShow?.code2 ? (
          <Box lineHeight={['33px']} fontSize={['16px']}>
            {tradeShow?.code2}
          </Box>
        ) : (
          <Form layout="vertical" form={form} onFinish={onSubmit}>
            <Form.Item
              name="code"
              validateStatus={alert.status ? alert.type : undefined}
              rules={[{ required: true, message: t('requiredCode') }]}
            >
              <TextField placeholder={t('enterCode')} size="large" />
            </Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              size="large"
              block
              loading={loading}
              disabled={promoCodeDisabled}
            >
              {t('submitCode')}
            </Button>
          </Form>
        )}
      </Flex>
      {alert.status && (
        <Box mt={[2]} mb={[4]}>
          <Alert message={alert.message} type={alert.type} />
        </Box>
      )}
    </div>
  )
}

export default TradeShow
