import { Box, Flex } from 'reflexbox'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'

import { PAGE_SIZE } from 'constants/table'

import { useInvite, useInviteActions } from 'context/Invites'

import { Table } from 'components'

import useColumns from './hooks/useColumns'

const DEFAULT_PARAMS: Readonly<TParams> = {
  page: 1,
  pageSize: PAGE_SIZE,
}

const Entity = () => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'clients.titles' })
  const { loadEntityInvitations, loading } = useInviteActions()
  const { entityCpaInvites } = useInvite()
  const [params, setParams] = useState<TLoadEntitiesParams>(DEFAULT_PARAMS)
  const [currentPage, setCurrentPage] = useState<number | undefined>(DEFAULT_PARAMS.page)
  const { columns } = useColumns()

  useEffect(() => {
    loadEntityInvitations(params)
  }, [params])

  const handlePageChange = (page: number) => {
    setParams((prevParams: TLoadEntitiesParams) => {
      setCurrentPage(page)
      return { ...prevParams, page }
    })
  }

  return (
    <>
      <Box>
        <Flex
          my={[2]}
          justifyContent={['space-between']}
          alignItems={['center']}
          flexWrap={['wrap']}
          flexDirection={['row']}
        >
          <Box fontSize={['20px']} fontWeight={[600]} my={[3]} width={['100%', 'auto']}>
            {t('invitationsForEntities')}
          </Box>
        </Flex>
        <Table
          table={{
            dataSource: entityCpaInvites.results,
            columns,
            rowKey: 'guid',
            loading,
          }}
          pagination={{
            total: entityCpaInvites.count,
            onChange: handlePageChange,
            current: currentPage,
          }}
        />
      </Box>
    </>
  )
}

export default Entity
