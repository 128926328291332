import { useRouteMatch } from 'react-router-dom'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import { useEntityCPAs } from 'context/EntityCPAs'
import styles from '../../../dashboard.clients.module.scss'
import { LinkType } from '../../types'

export default () => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'clients.head' })
  const {
    params: { slug },
  } = useRouteMatch<any>()

  const {
    state: {
      clientSummary: { clientsActive, clientsInactive, invitationsPending, invitationsUnsent },
    },
  } = useEntityCPAs()

  const links: LinkType[] = [
    {
      key: 'list',
      to: '/dashboard/clients/list',
      className: cn(!slug && styles.nav_is_active),
      activeClassName: styles.nav_is_active,
      label: t('clientList', {
        clientsActive: slug !== undefined ? ` (${clientsActive})` : '',
      }),
      exact: true,
    },
    {
      key: 'pending',
      to: '/dashboard/clients/pending',
      className: undefined,
      activeClassName: styles.nav_is_active,
      label: t('pending', {
        invitationsPending: slug !== 'pending' ? ` (${invitationsPending})` : '',
      }),
      exact: true,
    },
    {
      key: 'inactive',
      to: '/dashboard/clients/inactive',
      className: undefined,
      activeClassName: styles.nav_is_active,
      label: t('inactive', {
        clientsInactive: slug !== 'inactive' ? ` (${clientsInactive})` : '',
      }),
      exact: true,
    },
    {
      key: 'uninvited',
      to: '/dashboard/clients/uninvited',
      className: undefined,
      activeClassName: styles.nav_is_active,
      label: t('uninvited', {
        invitationsUnsent: slug !== 'uninvited' ? ` (${invitationsUnsent})` : '',
      }),
      exact: true,
    },
  ]

  return { slug, links }
}
