import { ActionTypes } from './action-types'
import { TState, TAction } from './types'

export const initialState: TState = {
  entities: {
    data: [],
    total: undefined,
  },
  clientInvites: {
    data: [],
    total: undefined,
    search: {
      data: [],
      total: 0,
    },
  },
  entitiesPrimaryContacts: [],
  unsentInvitationFiles: undefined,
  clientSummary: {
    clientsActive: 0,
    clientsInactive: 0,
    invitationsPending: 0,
    invitationsUnsent: 0,
  },
  CPAFirmProfile: {
    address: undefined,
    guid: undefined,
    letterheadImage: undefined,
    name: undefined,
    phone: undefined,
  },
}

export const reducer = (state: TState = initialState, { type, payload }: TAction) => {
  const { data, total } = payload

  switch (type) {
    case ActionTypes.LOAD_CPA_ENTITIES:
      return {
        ...state,
        entities: {
          data,
          total,
        },
      }

    case ActionTypes.LOAD_CLIENT_INVITES: {
      return {
        ...state,
        clientInvites: {
          data: payload.results,
          total: payload.count,
          search: {
            data: [],
            total: 0,
          },
        },
      }
    }

    case ActionTypes.LOAD_CLIENT_INVITES_WITH_PARAMS: {
      return {
        ...state,
        clientInvites: {
          data: [],
          total: undefined,
          search: {
            data: payload.results,
            total: payload.count,
          },
        },
      }
    }

    case ActionTypes.LOAD_ENTITIES_PRIMARY_CONTACTS:
      return {
        ...state,
        entitiesPrimaryContacts: payload?.results,
      }

    case ActionTypes.LOAD_UNSENT_INVITATION_FILES: {
      return {
        ...state,
        unsentInvitationFiles: payload?.results,
      }
    }

    case ActionTypes.LOAD_CPA_FIRM_PROFILE: {
      return {
        ...state,
        CPAFirmProfile: {
          address: payload.address,
          letterheadImage: payload.letterheadImage,
          guid: payload.guid,
          name: payload.name,
          phone: payload.phone,
        },
      }
    }

    case ActionTypes.LOAD_CLIENT_SUMMARY: {
      return {
        ...state,
        clientSummary: {
          clientsActive: payload?.clientsActive,
          clientsInactive: payload?.clientsInactive,
          invitationsPending: payload?.invitationsPending,
          invitationsUnsent: payload?.invitationsUnsent,
        },
      }
    }

    default:
      return state
  }
}
