import { useMemo } from 'react'
import { useAuth } from 'context/Auth'
import { getUserType } from 'utils/user'

import { CPAFirmProvider } from 'context/CPAFirm'
import { BillingProvider } from 'context/Billing'
import { EntityCPAsProvider } from 'context/EntityCPAs'
import { InviteProvider } from 'context/Invites'
import { EntityProvider } from 'context/Entity'

import { CPAController, EntityController } from 'components'

import DashboardLayout from 'layouts/Dashboard'
import DefaultLayout from 'layouts/DefaultLayout'
import CPAOnboardingLayout from 'layouts/Onboarding/CPALayout'
import EntityOnboardingLayout from 'layouts/Onboarding/EntityLayout'
import TaxOrganizerLayout from 'layouts/TaxOrganizer'
import { LayoutEnum } from 'layouts/types'

const LayoutMiddleware = ({ type }: { type: LayoutEnum }) => {
  const { token, maskedToken } = useAuth()
  const userType = useMemo(() => getUserType(token, maskedToken), [token, maskedToken])

  switch (type) {
    case LayoutEnum.ONBOARDING:
      return userType === 'CPA' ? (
        <CPAController>
          <BillingProvider>
            <CPAFirmProvider>
              <InviteProvider>
                <EntityCPAsProvider>
                  <DefaultLayout showLogout>
                    <CPAOnboardingLayout />
                  </DefaultLayout>
                </EntityCPAsProvider>
              </InviteProvider>
            </CPAFirmProvider>
          </BillingProvider>
        </CPAController>
      ) : (
        <EntityController>
          <DefaultLayout showLogout>
            <EntityOnboardingLayout />
          </DefaultLayout>
        </EntityController>
      )
    case LayoutEnum.DASHBOARD:
      return userType === 'CPA' ? (
        <CPAController>
          <BillingProvider>
            <EntityProvider>
              <DashboardLayout />
            </EntityProvider>
          </BillingProvider>
        </CPAController>
      ) : (
        <EntityController>
          <EntityProvider>
            <DashboardLayout />
          </EntityProvider>
        </EntityController>
      )
    case LayoutEnum.TAX_ORGANIZER:
      return userType === 'CPA' ? (
        <CPAController>
          <TaxOrganizerLayout />
        </CPAController>
      ) : (
        <EntityController>
          <TaxOrganizerLayout />
        </EntityController>
      )
    default:
      throw new Error('Layout type is not defined')
  }
}

export default LayoutMiddleware
