import { TCPAFirmAssociatedUser } from 'context/Dashboard'

export const taxPreparersOptions = (data: TCPAFirmAssociatedUser[]) => {
  return data.map((_: TCPAFirmAssociatedUser) => {
    return {
      value: _.user.guid,
      label: `${_.user.firstName} ${_.user.lastName}`,
    }
  })
}
