import { values } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'

import { Typography, Tooltip } from 'antd'
import { SpinnerBackdrop } from 'components'
import cn from 'classnames'

import styles from './firm.profile.module.scss'
import useFirmProfile from './hooks/useFirmProfile'

const { Paragraph } = Typography

const FirmProfile = () => {
  const { t } = useTranslation('common')
  const { slug } = useParams<{ slug: string }>()
  const {
    profile: { address, name, letterheadImage, phone },
    loading,
  } = useFirmProfile(slug)

  const hasCountryAndCity = values(address).some((value) => value !== undefined)

  if (loading) {
    return <SpinnerBackdrop />
  }

  return (
    <div className={cn(styles.wrapper, 'container-xs')}>
      <Link to="/">{`< Repositax`}</Link>
      <div className={styles.logo}>
        {letterheadImage ? (
          <img src={letterheadImage} alt="logo" />
        ) : (
          <div className={styles.placeholder}>{name}</div>
        )}
      </div>
      <div className={cn('my-3', 'text-subtitle')}>{name}</div>
      <div className={cn('mb-4', styles.bottom)}>
        <div className={styles.contacts}>
          <Paragraph>{address?.line1}</Paragraph>
          {hasCountryAndCity && (
            <Paragraph>{`${address.city}, ${address.country} ${address.zipCode}`}</Paragraph>
          )}
          {phone && <Paragraph>{phone}</Paragraph>}
        </div>
        <div className={styles.contact_us_link}>
          <Tooltip title={t('comingSoon')}>
            <Link to="/">{t('buttons.contactUs')} &gt;</Link>
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

export default FirmProfile
