import { useEffect, useState, Key } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Flex } from 'reflexbox'
import { find } from 'lodash'
import useFormTable from 'hooks/useFormTable'

import { useAuth } from 'context/Auth'
import { TClientInviteParams, useEntityCPAs, useEntityCPAsActions } from 'context/EntityCPAs'

import { Alert, Search, SpinnerBackdrop, Table, Upload } from 'components'

// @ts-expect-error
import csvTemplate from 'assets/templates/ClientListTemplate.xlsx'

import useColumns from './hooks/useColumns'

import Buttons from './components/Buttons'
import { DEFAULT_PARAMS } from './constants'
import useUploadSectionStatus from './hooks/useUploadSectionStatus'
import EditInvitationModal from '../components/EditInvitationModal'

import styles from '../dashboard.clients.module.scss'

const Uninvited = () => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'clients' })
  const {
    CPA: { cpaFirm },
  } = useAuth()
  const [uninvitedSelectedRows, setUninvitedSelectedRows] = useState<Key[]>([])
  const [pendingFiles, setPendingFiles] = useState(false)
  const [selectedInvitation, setSelectedInvitation] = useState<any>()

  const { params, setParams, currentPage, handleSearch, handlePageChange } =
    useFormTable<TClientInviteParams>({
      defaultParams: DEFAULT_PARAMS,
    })
  const {
    state: {
      clientInvites: { data: dataSource, total: totalUnsentInvitations, search: dataSearch },
      unsentInvitationFiles,
    },
  } = useEntityCPAs()
  const { loadCpaClientInvites, loading: unsentInvitationsLoading } = useEntityCPAsActions()
  const {
    uploadUnsentInvitationsFile,
    loading: uploadFileLoading,
    response: uploadFileResponse,
  } = useEntityCPAsActions()
  const { getUnsentInvitationFiles, loading: unsentFilesLoading } = useEntityCPAsActions()
  const { loadClientSummary } = useEntityCPAsActions()

  const columns = useColumns({
    dataOnPageCount: params.search ? dataSearch.data.length : dataSource.length,
    setParams,
    setUninvitedSelectedRows,
  })
  const loading = unsentInvitationsLoading || uploadFileLoading || unsentFilesLoading
  const { uploadSectionStatus, setUploadSectionStatus } = useUploadSectionStatus({
    unsentInvitationFiles,
    totalUnsentInvitations: params.search ? dataSearch.total : totalUnsentInvitations,
    searchIsActive: !!params.search,
  })

  const handleUpload = async ({ file }: { file: File }) => {
    setUploadSectionStatus(undefined)
    await uploadUnsentInvitationsFile(cpaFirm!.guid, file)
    if (uploadFileResponse.ok) {
      getUnsentInvitationFiles(cpaFirm!.guid)
    }
  }

  const handleDownload = () => {
    fetch(csvTemplate)
      .then((response) => {
        response.blob().then((blob) => {
          const fileURL = window.URL.createObjectURL(blob)
          const aLink = document.createElement('a')
          aLink.href = fileURL
          aLink.download = 'Repositax Client List Template.xlsx'
          aLink.click()
        })
      })
      .catch((error) => {
        console.error('The file template can not reach', error)
      })
  }

  useEffect(() => {
    setPendingFiles(!!find(unsentInvitationFiles, { status: 'PENDING' }))
  }, [unsentInvitationFiles])

  useEffect(() => {
    getUnsentInvitationFiles(cpaFirm!.guid)
    loadCpaClientInvites(cpaFirm!.guid, params)
    loadClientSummary(cpaFirm!.guid)
  }, [params])

  return (
    <>
      <Flex flexWrap="wrap" justifyContent={['space-between']} flexDirection={['column', 'row']}>
        <Buttons
          uploadSectionStatus={uploadSectionStatus}
          setUploadSectionStatus={setUploadSectionStatus}
          params={params}
          setParams={setParams}
          dataSource={params.search ? dataSearch.data : dataSource}
          uninvitedSelectedRows={uninvitedSelectedRows}
          setUninvitedSelectedRows={setUninvitedSelectedRows}
          totalUnsentInvitations={totalUnsentInvitations}
          cpaFirmGuid={cpaFirm!.guid}
        />
        {uploadSectionStatus === 'closed' && (
          <Box width={['100%', '250px']}>
            <Search
              loading={unsentInvitationsLoading}
              searchHandler={handleSearch}
              placeholder={t('head.searchClient')}
              clear
            />
          </Box>
        )}
      </Flex>
      <Box className="position-relative" height={[!uploadSectionStatus ? '150px' : 'auto']}>
        {loading && <SpinnerBackdrop mode="local" />}
        {uploadSectionStatus === 'opened' && (
          <Box>
            <Box my={[1, 2, 3]}>
              <Alert
                type="info"
                message={
                  <>
                    {t('upload.before.0')}{' '}
                    <button className={styles.template__button} onClick={handleDownload}>
                      {t('upload.before.link')}
                    </button>{' '}
                    {t('upload.before.1')}
                  </>
                }
              />
            </Box>
            <Box>
              <Upload
                fileList={[]}
                onChange={handleUpload}
                accept=".xls,.xlsx,.csv"
                multiple={false}
              />
            </Box>
          </Box>
        )}
        {uploadSectionStatus === 'closed' && (
          <>
            {pendingFiles && (
              <Box my={[1, 2, 3]}>
                <Alert type="warning" message={t('upload.after')} />
              </Box>
            )}
            <Table
              table={{
                dataSource: params.search ? dataSearch.data : dataSource,
                columns,
                rowKey: 'guid',
                rowSelection: {
                  selectedRowKeys: uninvitedSelectedRows,
                  onChange: (guids: Key[]) => {
                    setUninvitedSelectedRows(guids)
                  },
                },
                onRow: (record) => ({
                  onClick: () => {
                    setSelectedInvitation(record)
                  },
                }),
              }}
              pagination={{
                total: params.search ? dataSearch.total : totalUnsentInvitations,
                onChange: handlePageChange,
                current: currentPage,
              }}
            />
          </>
        )}
      </Box>
      {selectedInvitation && (
        <EditInvitationModal
          invite={selectedInvitation}
          inviteStatus="uns"
          closeForm={() => setSelectedInvitation(undefined)}
        />
      )}
    </>
  )
}

export default Uninvited
