import { TDocumentPageIdentifier, TTaxReturnDocument } from 'context/TaxReturns'

export const getAllDocs = (
  filteredTaxReturnPages: TDocumentPageIdentifier[],
  taxReturnDocuments: TTaxReturnDocument[]
) =>
  filteredTaxReturnPages.map((filteredTaxReturnPage) => {
    const document = taxReturnDocuments.find(
      (taxReturnDocument) =>
        taxReturnDocument.taxDocument.guid === filteredTaxReturnPage.documentGuid
    )!.taxDocument
    return {
      document,
      pages: String(filteredTaxReturnPage.pageId + 1),
    }
  })

export const getCurrentDoc = (
  selectedPage: TDocumentPageIdentifier,
  taxReturnDocuments: TTaxReturnDocument[]
) =>
  taxReturnDocuments.find(
    (taxReturnDocument) => taxReturnDocument.taxDocument.guid === selectedPage.documentGuid
  )!.taxDocument
