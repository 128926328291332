import { useFetch } from 'use-http'
import qs from 'qs'
import { toSnake } from 'utils'

import { ActionTypes } from './action-types'
import { useNotificationsDispatch } from '../context'
import { TLoadNotificationsParams } from '../types'

const useNotificationsActions = () => {
  const dispatch = useNotificationsDispatch()
  const { get, patch, response, loading } = useFetch()

  const loadNotifications = async (params: TLoadNotificationsParams = {}) => {
    const notifications = await get(
      `/notifications${qs.stringify(toSnake(params), { addQueryPrefix: true })}`
    )
    if (response.ok) {
      dispatch({
        type: ActionTypes.LOAD_NOTIFICATIONS,
        payload: notifications,
      })
    }
  }

  const loadUnreadNotifications = async () => {
    const result = await get('/notifications/summary')
    if (response.ok) {
      dispatch({
        type: ActionTypes.LOAD_UNREAD_NOTIFICATIONS,
        payload: result,
      })
    }
  }

  const markNotificationsAsRead = async (guids: string[]) => {
    await patch('/notifications', {
      guids,
    })
    if (response.ok) {
      await loadUnreadNotifications()
    }
  }

  return {
    loadNotifications,
    markNotificationsAsRead,
    loadUnreadNotifications,
    loading,
    response,
  }
}

export default useNotificationsActions
export { ActionTypes }
