import {
  ActionTypes,
  TTaxOrganizersParams,
  useDashboardDispatch,
  TTaxSummaryParams,
  TCPAFirmAssociatedUsersParams,
} from 'context/Dashboard'
import { TActivityFeedQueryParams } from 'context/TaxOrganizer/types'
import qs from 'qs'
import { useFetch } from 'use-http'
import { toSnake } from 'utils'

const useDashboardActions = () => {
  const dispatch = useDashboardDispatch()
  const { get, response, loading } = useFetch()

  const loadTaxOrganizers = async (params?: TTaxOrganizersParams) => {
    const data = await get(
      `/tax-organizers${qs.stringify(toSnake(params), {
        addQueryPrefix: !!params,
      })}`
    )
    if (response.ok) {
      dispatch({
        type: ActionTypes.SET_TAX_LIST,
        payload: data,
      })
    }
  }

  const loadActivityFeed = async (params?: TActivityFeedQueryParams) => {
    const query = qs.stringify(toSnake({ ...params }), { addQueryPrefix: !!params })
    const feed = await get(`/activity_feed/events${query}`)
    if (response.ok) {
      dispatch({
        type: ActionTypes.LOAD_ACTIVITY_FEED,
        payload: feed,
      })
    }
  }

  const loadMostRecent = async () => {
    const data = await get('/tax-organizers/most-recent')
    if (response.ok) {
      dispatch({
        type: ActionTypes.LOAD_MOST_RECENT,
        payload: data,
      })
    }
  }

  const loadTaxDeadlines = async (params?: TParams) => {
    const query = qs.stringify(toSnake({ ...params }), {
      addQueryPrefix: !!params,
    })
    const results = await get(`/tax-organizers/deadlines${query}`)
    if (response.ok) {
      dispatch({
        type: ActionTypes.LOAD_TAX_DEADLINES,
        payload: results.results,
      })
    }
  }

  const getPrimaryContacts = async (params?: TParams) => {
    const query = qs.stringify(toSnake({ ...params }), { addQueryPrefix: !!params })
    const results = await get(`/tax-organizers/primary-contacts${query}`)
    if (response.ok) {
      dispatch({
        type: ActionTypes.LOAD_PRIMARY_CONTACTS,
        payload: results,
      })
    }
  }

  const getSummary = async (params?: TTaxSummaryParams) => {
    const query = qs.stringify(toSnake({ ...params }), { addQueryPrefix: !!params })
    const result = await get(`/tax-organizers/summary${query}`)
    if (response.ok) {
      dispatch({
        type: ActionTypes.LOAD_TAX_SUMMARY,
        payload: result,
      })
    }
  }

  const loadDocumentsTags = async () => {
    const tags = await get(`/documents/tags`)
    if (response.ok) {
      dispatch({ type: ActionTypes.LOAD_DOCUMENTS_TAGS, payload: tags })
    }
  }

  const loadDocumentsCategories = async () => {
    const categories = await get(`/documents/categories`)
    if (response.ok) {
      dispatch({ type: ActionTypes.LOAD_DOCUMENTS_CATEGORIES, payload: categories })
    }
  }

  const setDeadline = (deadlineGuid?: string) => {
    dispatch({
      type: ActionTypes.SET_DEADLINE,
      payload: { deadlineGuid },
    })
  }

  const setTaxYear = (year: number) => {
    dispatch({
      type: ActionTypes.SET_TAX_YEAR,
      payload: year,
    })
  }

  const loadCPAFirmAssociatedUsers = async (
    cpaFirmGuid: string,
    params?: TCPAFirmAssociatedUsersParams
  ) => {
    const query = qs.stringify(toSnake({ ...params }), { addQueryPrefix: !!params })
    const users = await get(`/cpas/cpa-firms/${cpaFirmGuid}/users${query}`)
    if (response.ok) {
      dispatch({ type: ActionTypes.SET_CPA_ASSOCIATED_USERS, payload: users })
    }
  }

  const loadBurnDownChartData = async (cpaFirmGuid: string, deadline: string) => {
    const payload = await get(`/tax-organizers/${cpaFirmGuid}/burn-down-chart/${deadline}`)
    if (response.ok) {
      dispatch({ type: ActionTypes.LOAD_BURN_DOWN_CHART_DATA, payload })
    }
  }

  return {
    loadTaxOrganizers,
    loadMostRecent,
    loadActivityFeed,
    loadTaxDeadlines,
    getPrimaryContacts,
    getSummary,
    loadDocumentsTags,
    loadDocumentsCategories,
    setDeadline,
    setTaxYear,
    loadCPAFirmAssociatedUsers,
    loadBurnDownChartData,
    loading,
    response,
  }
}

export default useDashboardActions
