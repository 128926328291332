import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react'
import { Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { CloseOutlined, LoadingOutlined, SearchOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import { getOrderingParams } from 'utils/table'

import { useCPAFirmActions, useCPAFirm } from 'context/CPAFirm'

import { Title, Empty, Table, TextField } from 'components'

import { debounce } from 'lodash'
import { DEFAULT_PARAMS } from './constatnts'
import useColumns from './hooks/useColumns'

import styles from './promo.module.scss'

const Promo = () => {
  const { t } = useTranslation('common')
  const readyToFetchData = useRef(false)
  const loadingKey = useRef<'search' | 'ordering' | undefined>()
  const [form] = Form.useForm()
  const [params, setParams] = useState<TParams>(DEFAULT_PARAMS)
  const [currentPage, setCurrentPage] = useState<number | undefined>(DEFAULT_PARAMS.page)
  const [clear, setClear] = useState(false)
  const { loadPtin, clearPtin, loading } = useCPAFirmActions()
  const {
    ptins: { data: dataSource, total },
  } = useCPAFirm()
  const { columns } = useColumns()

  const handlePageChange = (page: number) => {
    setParams((prevParams: TParams) => {
      setCurrentPage(page)
      return { ...prevParams, page }
    })
  }

  const handleClear = () => {
    setClear(false)
    form.resetFields(['search'])
  }

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    readyToFetchData.current = true
    loadingKey.current = 'search'
    const {
      target: { value },
    } = event
    if (value) {
      setClear(true)
    } else {
      setClear(false)
    }
    setParams((prevParams: TParams) => {
      setCurrentPage(DEFAULT_PARAMS.page)
      return {
        ...prevParams,
        page: DEFAULT_PARAMS.page,
        search: value ? value.trim() : undefined,
      }
    })
  }

  const debouncedTextChangeHandler = useMemo(() => debounce(handleSearch, 500), [])

  useEffect(() => {
    ;(async () => {
      if (readyToFetchData.current) {
        await loadPtin(params)
        if (!params.search) {
          clearPtin()
        }
      }
    })()
  }, [params, readyToFetchData.current])

  useEffect(() => {
    return () => {
      debouncedTextChangeHandler.cancel()
    }
  }, [])

  return (
    <div className={classNames(['container', styles.container])}>
      <Title title={t('promo.title')} subtitles={[t('promo.subtitle1'), t('promo.subtitle2')]} />
      <Form form={form} className={styles.form}>
        <Form.Item name="search" key="search" className="position-relative">
          <TextField
            suffix={
              loading && loadingKey.current === 'search' ? (
                <LoadingOutlined />
              ) : clear ? (
                <CloseOutlined onClick={handleClear} />
              ) : (
                <SearchOutlined />
              )
            }
            onChange={debouncedTextChangeHandler}
            placeholder={t('promo.autoCompletePlaceholder')}
            style={{ width: '100%' }}
            className={styles.autocomplete}
            onFocus={() => setClear(true)}
          />
        </Form.Item>
      </Form>
      <div className={styles.data}>
        {total ? (
          <Table
            table={{
              dataSource,
              columns,
              rowKey: 'guid',
              loading,
              tableLayout: 'auto',
              onChange: (p, f, sorter) => {
                loadingKey.current = 'ordering'
                setParams((prevParams) => ({ ...prevParams, ordering: getOrderingParams(sorter) }))
              },
            }}
            pagination={{ total, onChange: handlePageChange, current: currentPage }}
          />
        ) : (
          <Empty />
        )}
      </div>
    </div>
  )
}

export default Promo
