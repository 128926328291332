import { useState, useMemo } from 'react'
import { Form, notification } from 'antd'
import { useTranslation } from 'react-i18next'

import { useAuth, useAuthActions } from 'context/Auth'

import { Button, TextField, PhoneInput } from 'components'

import styles from '../../user.profile.module.scss'

const ProfileForm = () => {
  const { user } = useAuth()
  const { loading, response, updateUserProfile } = useAuthActions()
  const [errors, setErrors] = useState({} as Partial<IContact>)
  const [form] = Form.useForm()
  const { t } = useTranslation('dashboard', { keyPrefix: 'user.form' })
  const { t: t2 } = useTranslation('common', { keyPrefix: 'form' })
  const required = {
    required: true,
    message: t2('required'),
  }

  const initialValues = useMemo(
    () => ({
      ...user,
    }),
    [user]
  )

  const onSubmit = async (values: Partial<IContact>) => {
    const result = await updateUserProfile(values)
    if (response.ok) {
      setErrors({})
      notification.success({
        message: t('notification.message'),
        description: t('notification.description'),
      })
    } else {
      setErrors(result)
    }
  }

  return (
    <Form form={form} initialValues={initialValues} layout="vertical" onFinish={onSubmit}>
      <Form.Item
        name="firstName"
        label={t('firstName')}
        rules={[required]}
        validateStatus={errors?.firstName && 'error'}
        help={errors?.firstName}
      >
        <TextField />
      </Form.Item>
      <Form.Item
        name="lastName"
        label={t('lastName')}
        rules={[required]}
        validateStatus={errors?.lastName && 'error'}
        help={errors?.lastName}
      >
        <TextField />
      </Form.Item>
      <Form.Item
        name="email"
        label={t('email')}
        rules={[{ type: 'email', message: 'Email is not a valid' }, required]}
        validateStatus={errors?.email && 'error'}
        help={errors?.email}
      >
        <TextField />
      </Form.Item>
      <PhoneInput
        name="phone"
        label={t('phone')}
        validateStatus={errors?.phone && 'error'}
        help={errors?.phone}
        initialValue={user?.phone}
      />
      <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
        {() => (
          <Button
            htmlType="submit"
            type="primary"
            size="large"
            className={styles.submit}
            loading={loading}
            disabled={!form.isFieldsTouched() || loading}
          >
            {t('submit')}
          </Button>
        )}
      </Form.Item>
    </Form>
  )
}

export default ProfileForm
