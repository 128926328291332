import { useEffect, FC, ReactNode } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useAuth, useAuthActions } from 'context/Auth'

import { getCPAOnboardingPath, redirectFromTo } from 'utils/routes'
import { SpinnerBackdrop } from 'components'

type TProps = {
  children: ReactNode
}

const CPAController: FC<TProps> = ({ children }) => {
  const history = useHistory()
  const location = useLocation()
  const {
    CPA: {
      cpaUserInvites: { pending: pendingInvitations },
      cpaFirm,
    },
    onboardingFlow: { status: flowStatus, responseOk },
  } = useAuth()
  const { loadCPAUserInfo, setOnboardingFlowStatus, setOnboardingStep } = useAuthActions()

  useEffect(() => {
    if (!flowStatus) {
      setOnboardingFlowStatus('START')
      loadCPAUserInfo()
    }
  }, [flowStatus])

  useEffect(() => {
    if (responseOk && flowStatus !== 'END') {
      let path: string
      let onboardingStatus: TOnboardingClientStatus | undefined
      if (cpaFirm) {
        path = getCPAOnboardingPath(cpaFirm!.onboardingStatus, location.pathname)
        onboardingStatus = cpaFirm!.onboardingStatus
      } else if (pendingInvitations) {
        path = getCPAOnboardingPath('INVITATIONS')
      } else {
        path = getCPAOnboardingPath('SETUP_CREATE')
        onboardingStatus = 'SETUP_CREATE'
      }
      setOnboardingFlowStatus('END')
      setOnboardingStep(onboardingStatus)
      redirectFromTo(location.pathname, path, history)
    }
  }, [cpaFirm, pendingInvitations, responseOk])

  return flowStatus === 'END' ? (
    <>{children}</>
  ) : (
    <SpinnerBackdrop transparent={false} mode="global" size="large" />
  )
}

export default CPAController
