export default [
  {
    guid: '9647f9ce-275a-4cec-85e1-6d4a2e750b13',
    email: 'jgoullee1@house.gov',
    firstName: 'Allesandra',
    lastName: 'Palmerston',
    phone: '212-199-0988',
    avatar: 'https://robohash.org/etveritatislaudantium.png?size=30x30&set=set1',
    isPrimary: false,
  },
  {
    guid: '1e9379e0-522a-428f-8591-94824667dfbe',
    email: 'slorkin0@t-online.de',
    firstName: 'Allesandro',
    lastName: 'Rizzo',
    phone: '230-438-2662',
    avatar: 'https://robohash.org/estetquibusdam.png?size=30x30&set=set1',
    isPrimary: true,
  },
  {
    guid: '4f5be042-5932-4890-ad3c-db3e05ff5781',
    email: 'bmettericke2@whitehouse.gov',
    firstName: 'Marry Ann',
    lastName: 'Patterson',
    phone: '341-645-1634',
    avatar: 'https://robohash.org/consequunturexeveniet.png?size=30x30&set=set1',
    isPrimary: false,
  },
  {
    guid: '448b747f-225c-4e8c-b00f-c52ccb80d69c',
    email: 'dlorent3@nba.com',
    firstName: 'Brandon',
    lastName: 'Tran',
    phone: '132-399-5301',
    avatar: null,
    isPrimary: false,
  },
]
