import { createContext, useContext, Dispatch, FC, useReducer } from 'react'

import { useReducerLogger } from 'utils'

import { reducer, initialState } from './reducer'
import { ActionTypes } from './action-types'
import { TAction, TState } from './types'

interface IContextProps {
  state: TState
  dispatch: Dispatch<TAction>
}

const EntityCPAsContext = createContext<IContextProps>({} as IContextProps)

const EntityCPAsProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(useReducerLogger<TState, TAction>(reducer), initialState)

  return (
    <EntityCPAsContext.Provider value={{ state, dispatch }}>{children}</EntityCPAsContext.Provider>
  )
}

const useEntityCPAs = () => {
  const context = useContext(EntityCPAsContext)
  if (context === undefined) {
    throw new Error('useEntityCPAs must be used within a EntityCPAsProvider')
  }
  return context
}

export { EntityCPAsProvider, useEntityCPAs, ActionTypes }
