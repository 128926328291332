export enum ActionTypes {
  LOAD_ENTITIES = 'LOAD_ENTITIES',
  LOAD_CURRENT_ENTITY = 'LOAD_CURRENT_ENTITY',
  LOAD_CURRENT_ENTITY_DOCS = 'LOAD_CURRENT_ENTITY_DOCS',
  LOAD_DOCUMENTS_SUMMARY = 'LOAD_DOCUMENTS_SUMMARY',
  LOAD_DOCUMENTS = 'LOAD_DOCUMENTS',
  SET_ENTITY_USERS = 'SET_ENTITY_USERS',
  SET_CPA_CONTACTS = 'SET_CPA_CONTACTS',
  SET_CPA_ASSOCIATED_USERS = 'SET_CPA_ASSOCIATED_USERS',
  SET_ENTITY_USER_INVITES = 'SET_ENTITY_USER_INVITES',
  SET_KINDS = 'SET_KINDS',
  UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT',
  DELETE_DOCUMENT = 'DELETE_DOCUMENT',
  BULK_DELETE_DOCUMENTS = 'BULK_DELETE_DOCUMENTS',
  LOAD_ENTITIES_PRIMARY_CONTACTS = 'LOAD_ENTITIES_PRIMARY_CONTACTS',
  SET_MULTIPLE_DOCUMENT_PARAMS = 'SET_MULTIPLE_DOCUMENT_PARAMS',
  REMOVE_MULTIPLE_DOCUMENT_PARAM = 'REMOVE_MULTIPLE_DOCUMENT_PARAM',
  CLEAR_MULTIPLE_DOCUMENT_PARAMS = 'CLEAR_MULTIPLE_DOCUMENT_PARAMS',
  CLEAR_UPLOADED = 'CLEAR_UPLOADED',
  SET_SELECTED_TABLE_DOCUMENT_IDS = 'SET_SELECTED_TABLE_DOCUMENT_IDS',
  LOAD_CURRENT_ENTITY_ROLES = 'LOAD_CURRENT_ENTITY_ROLES',
  LOAD_ENTITY_DOCUMENT = 'LOAD_ENTITY_DOCUMENT',
}
