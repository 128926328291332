import { useEffect, useState, SyntheticEvent } from 'react'
import { Flex } from 'reflexbox'

import { useTaxReturns, useTaxReturnsActions, VerificationStatus } from 'context/TaxReturns'
import { useAuth } from 'context/Auth'
import ClientVerfication from './ClientVerification'

type TProps = {
  closeModal: () => void
}

const ESign = ({ closeModal }: TProps) => {
  const { eFileSignUrl, generateEFileAuthorizationLetter, eFileAuthPreCheck, checkSigned } =
    useTaxReturnsActions()
  const {
    state: {
      taxReturns: { latest: latestTaxReturn },
      authorizationDocumentDetails,
    },
  } = useTaxReturns()
  const { user } = useAuth()
  const [signUrl, setSignUrl] = useState<string>('')
  const [signerStatus, setSignerStatus] = useState<VerificationStatus | null>(null)

  const getSignUrl = async () => {
    try {
      if (latestTaxReturn) {
        const editUrlRespnse = await eFileSignUrl(latestTaxReturn.guid)
        setSignUrl(editUrlRespnse.link)
      }
    } catch (e) {
      console.log(e)
      closeModal()
    }
  }

  const checkReady = async () => {
    if (!latestTaxReturn || authorizationDocumentDetails.isSignedByAll) {
      closeModal()
      return
    }

    const isReady = await eFileAuthPreCheck(latestTaxReturn.guid)
    if (!isReady) {
      await generateEFileAuthorizationLetter(latestTaxReturn.guid)
    }

    checkSigned(latestTaxReturn.guid)
  }

  useEffect(() => {
    const currentSigner = authorizationDocumentDetails.signers.find(
      (signer) => signer.user.guid === user?.guid
    )
    if (!currentSigner || authorizationDocumentDetails.isSignedByAll) return

    setSignerStatus(currentSigner.idVerificationStatus)

    if (currentSigner.idVerificationStatus === VerificationStatus.VERIFIED) {
      getSignUrl()
    }
  }, [authorizationDocumentDetails])

  useEffect(() => {
    checkReady()
  }, [])

  return (
    <Flex
      px={[2]}
      py={[3]}
      flexDirection={['column']}
      maxWidth={['100%', '100%', '100%']}
      onClick={(event: SyntheticEvent) => {
        event.stopPropagation()
      }}
    >
      {signerStatus === VerificationStatus.VERIFIED ? (
        <iframe frameBorder={0} title="e-sign" src={signUrl} height={800} />
      ) : (
        <ClientVerfication />
      )}
    </Flex>
  )
}

export default ESign
