import { ChangeEvent, useEffect, useState, useMemo } from 'react'
import { Button, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { Table, TextField } from 'components'
import { TCPAEntityContact } from 'context/TaxOrganizer'
import { useEntityActions, TEntity, useEntity } from 'context/Entity'
import { debounce } from 'lodash'
import Icon, { SearchOutlined } from '@ant-design/icons'
import { ReactComponent as Plus } from 'assets/images/plus-white.svg'

import styles from 'pages/Dashboard/EntityManagement/dashboard.entity.management.module.scss'
import useTaxTeamColumns from '../../hooks/useTaxTeamColumns'
import AddEntityCPAUsersForm from '../AddEntityCPAUsersForm'
import { DEFAULT_CONTACTS_PARAMS } from '../../constants'

const { Title } = Typography

type Props = {
  cpaFirm: TCpaFirm
  entity: TEntity
}

const EntityTaxTeamList = ({ entity, cpaFirm }: Props) => {
  const { t } = useTranslation('entity')
  const { t: t2 } = useTranslation('common')
  const {
    entity: {
      cpaContacts: { data: dataSource, count: total },
    },
  } = useEntity()
  const [formVisible, setFormVisible] = useState(false)
  const [params, setParams] = useState<TParams>(DEFAULT_CONTACTS_PARAMS)
  const [reloadContactsFlag, setReloadContactsFlag] = useState(false)
  const [reloadUsersFlag, setReloadUsersFlag] = useState(false)
  const { columns, loadingEntityCPA } = useTaxTeamColumns({
    entity,
    cpaFirm,
    setReloadUsersFlag,
    setReloadContactsFlag,
  })
  const { loadEntityCPAContacts, loading } = useEntityActions()
  const [focus, setFocus] = useState(false)
  const [currentPage, setCurrentPage] = useState<number | undefined>(DEFAULT_CONTACTS_PARAMS.page)

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setParams((prevParams: TLoadEntitiesParams) => {
      setCurrentPage(DEFAULT_CONTACTS_PARAMS.page)
      return {
        ...prevParams,
        page: DEFAULT_CONTACTS_PARAMS.page,
        search: event.target.value ? event.target.value.trim() : undefined,
      }
    })
  }

  const handlePageChange = (page: number) => {
    setParams((prevParams: TLoadEntitiesParams) => {
      setCurrentPage(page)
      return { ...prevParams, page }
    })
  }

  const debouncedTextChangeHandler = useMemo(() => debounce(handleSearch, 300), [])

  useEffect(() => {
    loadEntityCPAContacts(entity.guid, cpaFirm.guid, params)
  }, [params, reloadContactsFlag])

  useEffect(() => {
    return () => {
      debouncedTextChangeHandler.cancel()
    }
  }, [])

  return (
    <div className={styles.users_wrapper}>
      <div className={styles.users_header}>
        {!focus && (
          <Title level={4} className={styles.section_title}>
            {t('taxFirm.manageTaxTeam')}
          </Title>
        )}
        <TextField
          suffix={<SearchOutlined />}
          className={styles.users_search}
          placeholder={t2('buttons.search')}
          onChange={debouncedTextChangeHandler}
          onBlur={() => setFocus(false)}
          onFocus={() => setFocus(true)}
          style={{ width: focus ? '100%' : 'auto' }}
          allowClear
        />
        <Button type="primary" onClick={() => setFormVisible(true)}>
          <Icon component={Plus} style={{ fontSize: 18 }} /> {t2('buttons.add')}
        </Button>
      </div>
      {formVisible && (
        <AddEntityCPAUsersForm
          cpaFirm={cpaFirm}
          entity={entity}
          setVisible={setFormVisible}
          setReloadContactsFlag={setReloadContactsFlag}
          reloadUsersFlag={reloadUsersFlag}
        />
      )}
      <Table
        table={{
          dataSource,
          columns,
          loading: loading || loadingEntityCPA,
          rowKey: (record: TCPAEntityContact) => record.user.guid,
          tableLayout: 'auto',
          scroll: { x: '100%' },
          size: 'small',
        }}
        pagination={{ total, onChange: handlePageChange, current: currentPage }}
      />
    </div>
  )
}

export default EntityTaxTeamList
