import { useState } from 'react'
import { Form } from 'antd'
import { LoginRequest, useAuth, useAuthActions } from 'context/Auth'
import { Box } from 'reflexbox'
import { useTranslation } from 'react-i18next'
import { REGISTER_PATH, RESET_PASSWORD_PATH } from 'utils/routes'

import { TextField, Button } from 'components'
import Alert, { TAlert } from 'components/Alert'

import { getMessageFromResponse } from 'utils/http'
import styles from './login.module.scss'

const defaultAlertSettings = {
  status: false,
  message: '',
  type: undefined,
}

const Login = () => {
  const { t } = useTranslation('common', { keyPrefix: 'form' })
  const [form] = Form.useForm()
  const {
    invitationsFlow: { inviteInfo },
  } = useAuth()
  const { response, login, loading } = useAuthActions()
  const [alert, setAlert] = useState<TAlert>(defaultAlertSettings)

  const onSubmit = async (values: LoginRequest) => {
    setAlert(defaultAlertSettings)
    await login(values)
    if (!response.ok) {
      if (response.status === 400 || response.status === 401) {
        setAlert({
          status: true,
          message: t('incorrectCredentials'),
          type: 'error',
        })
      } else {
        setAlert({
          status: true,
          message: getMessageFromResponse(response),
          type: 'error',
        })
      }
    }
  }

  return (
    <>
      <Form form={form} className={styles.form} onFinish={onSubmit}>
        {alert.status && (
          <Box mt={[2]} mb={[4]}>
            <Alert message={alert.message} type={alert.type} />
          </Box>
        )}

        <Box textAlign={['center']} my={[4]} fontSize={['24px']} fontWeight={[700]}>
          {t('singIn')}
        </Box>

        <Form.Item
          name="email"
          rules={[
            { required: true, message: t('requiredField', { field: t('email') }) },
            { type: 'email', message: t('invalidField', { field: t('email') }) },
          ]}
          initialValue={inviteInfo?.email || undefined}
        >
          <TextField placeholder={t('enterEmail')} size="large" className={styles.input} />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: t('requiredField', { field: t('password') }) }]}
        >
          <TextField
            placeholder={t('enterPassword')}
            size="large"
            className={styles.input}
            type="password"
          />
        </Form.Item>
        <Button htmlType="submit" type="primary" size="large" loading={loading} block>
          {t('login')}
        </Button>
        <Box mt={[3]}>
          <Button href={RESET_PASSWORD_PATH} type="link" block>
            {t('forgotPassword')}
          </Button>
        </Box>

        <Form.Item>
          <Button href={REGISTER_PATH} type="link" block>
            {t('noAccountSignUp')}
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default Login
