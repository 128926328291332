import { Switch, Route, Redirect } from 'react-router-dom'
import { Box } from 'reflexbox'
import Invitations from '../../Invitations'
import Members from '../../Members'

type TProps = {
  popoverStatus: boolean
}

const Router = ({ popoverStatus }: TProps) => {
  return (
    <Box mt={[1, 3]}>
      <Switch>
        <Route path="/dashboard/firm/team/invitations" exact>
          <Invitations popoverStatus={popoverStatus} />
        </Route>
        <Route path="/dashboard/firm/team" exact>
          <Members />
        </Route>
        <Route path="*">
          <Redirect to="/dashboard" />
        </Route>
      </Switch>
    </Box>
  )
}

export default Router
