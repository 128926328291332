import { FormEvent, useState } from 'react'
import Icon from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import { useMessages, useMessagesActions } from 'context/Messages'

import { Button, Checkbox, Textarea } from 'components'

import { ReactComponent as Telegram } from 'assets/images/telegram.svg'

import styles from './tax.messages.add.module.scss'

type Props = {
  threadId: string
  isResolved: boolean
  updateThreads: any
  taxOrganizer: string
}

const AddMessage = ({ threadId, isResolved, updateThreads, taxOrganizer }: Props) => {
  const [message, setMessage] = useState('')
  const { addThreadMessage, markThreadResolved, addNewThreadMessage, loading } =
    useMessagesActions()
  const { currentThreadIsLocked } = useMessages()

  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'messages' })

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    if (threadId === '0') {
      addNewThreadMessage(taxOrganizer, message).then(() => {
        setMessage('')
      })
    } else {
      addThreadMessage(threadId, message).then(() => {
        setMessage('')
      })
    }
  }

  const toggleIsResolved = () => {
    markThreadResolved(threadId, !isResolved).then(() => {
      updateThreads()
    })
  }

  return (
    <form className={styles.container} onSubmit={handleSubmit}>
      <Textarea
        autoSize={{ minRows: 3 }}
        placeholder={t('replyPlaceholder')}
        required
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        disabled={currentThreadIsLocked}
      />
      <div className={styles.actions}>
        <Checkbox
          key={threadId}
          onClick={toggleIsResolved}
          defaultChecked={isResolved}
          size="small"
          disabled={currentThreadIsLocked}
        >
          {t('markAsResolved')}
        </Checkbox>
        <Button
          type="primary"
          size="large"
          className={styles.submit}
          htmlType="submit"
          disabled={currentThreadIsLocked || loading}
        >
          <Icon component={Telegram} />
          {t('send')}
        </Button>
      </div>
    </form>
  )
}

export default AddMessage
