import { ActionTypes } from './actions/action-types'

export type TLoadNotificationsParams = {
  page?: number
  pageSize?: number
  search?: string
  ordering?: string
}

export enum EnumNotificationType {
  NOTIFIED = 'NOTIFIED',
  DOCUMENT_UPLOADED = 'DOCUMENT_UPLOADED',
  INVITE_ACCEPTED = 'INVITE_ACCEPTED',
  TAX_ORGANIZER_PREPARING = 'TAX_ORGANIZER_PREPARING',
  TAX_ORGANIZER_FILING = 'TAX_ORGANIZER_FILING',
  TAX_ORGANIZER_REVIEWING = 'TAX_ORGANIZER_REVIEWING',
  TAX_ORGANIZER_FILED = 'TAX_ORGANIZER_FILED',
}

export type NotificationType = EnumNotificationType

export interface INotification {
  guid: string
  user: IContact
  message: string | null
  notifiedOn: string | null
  deliverAfter: string | null
  processedOn: string | null
  queuedOn: string | null
  readOn: string | null
  type: NotificationType
  sourceGuid: string
  taxOrganizer: {
    guid: string
    taxYear: number
    entity: {
      guid: string
      title: string
    }
  }
}

export type TState = {
  count: number
  next: string | null
  previous: string | null
  notifications: INotification[]
  totalUnread: number
}

export type TAction = {
  type: keyof typeof ActionTypes
  payload?: any
}
