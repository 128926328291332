import { FC } from 'react'
import { Badge as AntBadge, BadgeProps } from 'antd'
import cn from 'classnames'

import styles from './badge.module.scss'

type Props = BadgeProps & {
  scheme?: 'blue' | 'purple' | 'none' | 'llgray' | 'transparent'
}
const Badge: FC<Props> = ({ scheme = 'none', className, ...props }) => {
  return <AntBadge {...props} className={cn(styles.badge, styles[`scheme_${scheme}`], className)} />
}

export default Badge
