import { SorterResult } from 'antd/lib/table/interface'

export const getOrderingParams = (
  sorter: SorterResult<any> | SorterResult<any>[]
): string | undefined => {
  const sorterKeys = Array.isArray(sorter)
    ? sorter.map(({ columnKey, order, column }) => ({
        column,
        order,
        columnKey,
      }))
    : [{ columnKey: sorter.columnKey, order: sorter.order, column: sorter.column }]

  const orderingParams = sorterKeys.reduce((acc: string[], { columnKey, order, column }: any) => {
    if (!column) {
      return acc
    }
    if (order === 'descend') {
      return [...acc, `-${columnKey}`]
    }
    return [...acc, columnKey]
  }, [])

  if (orderingParams.length) {
    return orderingParams.join(',')
  }
  return undefined
}
