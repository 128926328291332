import { Box } from 'reflexbox'

import { Button } from 'components'

import { TChildCategories } from 'context/TaxReturns'
import { useDrop } from 'react-dnd'

import colors from 'assets/styles/variables.module.scss'

type TProps = {
  state: string
  subCategory: TChildCategories
}

const SubCategoryContent = ({ state, subCategory }: TProps) => {
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: 'categorize',
      drop: () => ({
        parentCategory: 'state',
        childCategory: subCategory.slug,
        state: state.toLowerCase(),
      }),
      collect: (monitor: any) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [subCategory]
  )

  const isActive = canDrop && isOver

  return (
    <Box ref={drop} key={subCategory.slug} my={2}>
      <Button
        type="dashed"
        style={{
          background: isActive ? colors.primaryHover : 'transparent',
          color: isActive ? 'white' : '',
          border: isActive ? `1px solid ${colors.primaryHover}` : '',
          width: '100%',
        }}
        size="large"
      >
        {subCategory.label}
      </Button>
    </Box>
  )
}

export default SubCategoryContent
