export enum ActionTypes {
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGOUT = 'LOGOUT',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  MASK_SUCCESS = 'MASK_SUCCESS',
  STOP_MASK = 'STOP_MASK',
  SET_USER = 'SET_USER',
  SET_USERS = 'SET_USERS',
  UPDATE_USER = 'UPDATE_USER',
  SET_SUB_STATUS = 'SET_SUB_STATUS',
  SET_CPA_USER = 'SET_CPA_USER',
  UPSERT_CPA_FIRM = 'UPSERT_CPA_FIRM',
  SET_ENTITY_USER = 'SET_ENTITY_USER',
  SET_ONBOARDING_FLOW_STATUS = 'SET_ONBOARDING_FLOW_STATUS',
  SET_ONBOARDING_STEP = 'SET_ONBOARDING_STEP',
  SET_INVITATIONS_FLOW_STATUS = 'SET_INVITATIONS_FLOW_STATUS',
  SET_INVITE_INFO = 'SET_INVITE_INFO',
  SET_PENDING_INVITES = 'SET_PENDING_INVITES',
}
