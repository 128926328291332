import { useTranslation } from 'react-i18next'
import useLocale from 'hooks/useLocale'
import { formatDistance } from 'date-fns'

import styles from '../../tax.messages.module.scss'

type Props = {
  unreadMessageCount: number
  lastReplyOn: string
  isResolved: boolean
}

const ThreadState = ({ unreadMessageCount, isResolved, lastReplyOn }: Props) => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'status' })
  const locale = useLocale()

  const messageText = () => {
    if (isResolved) {
      return (
        <>
          <span className={styles.thread_info}>{t('resolved')}</span>
          <span className={styles.thread_separator}>•</span>
        </>
      )
    }
    if (unreadMessageCount > 0) {
      return (
        <>
          <span className={styles.thread_unread}>{t('unread')}</span>
          <span className={styles.thread_separator}>•</span>
        </>
      )
    }
    return ''
  }

  return (
    <div className={styles.thread_status}>
      {messageText()}
      <span className={styles.thread_date}>
        {formatDistance(new Date(lastReplyOn), new Date(), { addSuffix: true, locale })}
      </span>
    </div>
  )
}

export default ThreadState
