import { Checkbox, CheckboxProps } from 'antd'

import cn from 'classnames'

import './styles.scss'

type Props = CheckboxProps & {
  responsive?: boolean
  size?: 'small' | 'default'
}

const CheckboxComponent = ({ responsive, size = 'default', ...props }: Props) => {
  return (
    <Checkbox
      {...props}
      className={cn(
        props.className,
        responsive ? 'checkbox_responsive' : '',
        size === 'small' ? 'checkbox_small' : ''
      )}
    />
  )
}

export default CheckboxComponent
