import { useEffect, useMemo } from 'react'
import { Link, useLocation, useHistory, useRouteMatch } from 'react-router-dom'
import { Row, Col, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { CloseOutlined } from '@ant-design/icons'
import { Box, Flex } from 'reflexbox'
import Cookies from 'js-cookie'
import { DefaultOptionType } from 'antd/lib/select'

import { getEntityPathById } from 'utils/routes'

import { useAuth } from 'context/Auth'
import { TaxOrganizer } from 'context/TaxOrganizer'
import {
  useDashboard,
  useDashboardActions,
  TTaxOrganizersListItem,
  TTaxOrganizersParams,
} from 'context/Dashboard'

import { ReactComponent as ArrowUpRightFromSquare } from 'assets/images/arrow-up-right-from-square.svg'

import { UserDropdown, Select, Button } from 'components'

import MaskBanner from 'layouts/Dashboard/components/Header/components/MaskBanner'
import Search from 'layouts/Dashboard/components/Header/components/Search'

import { ReactComponent as Logo } from 'assets/images/logo.svg'

import colors from 'assets/styles/variables.module.scss'
import styles from './tax.header.module.scss'

const TAX_PREV_PATH = 'taxprevpath'

type PropsType = {
  taxOrganizer: TaxOrganizer
}

const Header = ({ taxOrganizer: { entity, taxYear } }: PropsType) => {
  const DEFAULT_PARAMS: TTaxOrganizersParams = {
    entity: entity.guid,
    ordering: 'tax_year',
  }
  const { t } = useTranslation('taxOrganizer')
  const { maskedToken, user } = useAuth()
  const { taxOrganizersList } = useDashboard()
  const { loadTaxOrganizers } = useDashboardActions()
  const [form] = Form.useForm()
  const { state, pathname } = useLocation<any>()
  const matches = useRouteMatch()
  const history = useHistory()
  const taxPrevPath = Cookies.get(TAX_PREV_PATH) ?? '/'

  const taxYearsOptions = useMemo(() => {
    return taxOrganizersList.map((taxYearsOption: TTaxOrganizersListItem): DefaultOptionType => {
      return {
        label:
          taxYear === taxYearsOption.taxYear ? (
            <span className="text-bold">{String(taxYearsOption.taxYear)}</span>
          ) : (
            String(taxYearsOption.taxYear)
          ),
        value: taxYearsOption.guid,
      }
    })
  }, [taxOrganizersList])

  useEffect(() => {
    loadTaxOrganizers(DEFAULT_PARAMS)
    form.setFieldsValue({
      taxYear,
    })
  }, [])

  useEffect(() => {
    if (state?.taxPrevPath) {
      Cookies.set(TAX_PREV_PATH, state?.taxPrevPath ?? '/')
    }
  }, [state?.taxPrevPath])

  const redirectToTaxOrganizer = (value: string) => {
    const newPath = pathname.replace(
      (matches.params as { taxOrganizerGuid: string }).taxOrganizerGuid,
      value
    )
    history.push(newPath)
  }

  return (
    <div className="container-xxl position-relative">
      <div className="container">
        {maskedToken && user && <MaskBanner />}
        <Row className={styles.header} gutter={[16, 20]} align="middle">
          <Col xs={24} lg={8} className={styles.logo_col}>
            <Link to="/">
              <Logo />
            </Link>
          </Col>
          <Col xs={24} lg={8}>
            <Flex
              fontSize={['16px']}
              flexDirection={['column']}
              alignItems={['center']}
              justifyContent={['center']}
            >
              <Box mb={[1]}>
                <Link to={getEntityPathById(entity.guid)}>
                  <Flex flexWrap={['nowrap']} alignItems={['center']} color={colors.black}>
                    {entity.title}
                    <Box width={['12px']} ml={['7px']}>
                      <ArrowUpRightFromSquare fill={colors.primaryHover} />
                    </Box>
                  </Flex>
                </Link>
              </Box>
              <Box mb={[1]}>{t('navigation.taxOrganizer')}</Box>
              <Box>
                <Form form={form}>
                  <Form.Item name="taxYear" key="taxYear" style={{ margin: 0 }}>
                    <Select
                      dropdownMatchSelectWidth={false}
                      size="small"
                      options={taxYearsOptions}
                      onChange={redirectToTaxOrganizer}
                    />
                  </Form.Item>
                </Form>
              </Box>
            </Flex>
          </Col>
          <Col lg={8} className={styles.search_col}>
            <Flex justifyContent={['center', 'flex-end']} width={['100%']}>
              <Search />
              <Box ml={[3]}>
                <UserDropdown className={styles.user} />
              </Box>
            </Flex>
          </Col>
        </Row>
      </div>
      <Link to={taxPrevPath} className={styles.close}>
        <Button type="primary" ghost size="small">
          Close
          <CloseOutlined />
        </Button>
      </Link>
    </div>
  )
}

export default Header
