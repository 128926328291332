import { useState } from 'react'
import { Badge, Button, Modal, Search } from 'components'
import { PlusOutlined, FilterOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { Row } from 'antd'

import { PAGE_SIZE } from 'constants/table'

import { TEntity } from 'context/Entity'

import useUserType from 'hooks/useUserType'
import useUserEntityPermissions from 'hooks/useUserEntityPermissions'

import cn from 'classnames'
import InviteEntityUserForm from '../Overview/components/Users/InviteEntityUserForm'

import styles from '../dashboard.entity.management.module.scss'

export const DEFAULT_PARAMS: Readonly<TParams> = {
  page: 1,
  pageSize: PAGE_SIZE,
  ordering: undefined,
  search: undefined,
}

type TProps = {
  entity: TEntity
  handleSearch: (search: string | undefined) => void
  loading: boolean
}

const Header = ({ entity, handleSearch, loading }: TProps) => {
  const { t } = useTranslation('common')
  const [popoverVisible, setPopoverVisible] = useState(false)
  const { userHasInvitePermissions } = useUserEntityPermissions()
  const userType = useUserType()

  return (
    <div className="pb-2">
      <Row wrap={false}>
        <div className="me-2">
          <Badge scheme="purple" size="small">
            <Button icon={<FilterOutlined />} disabled />
          </Badge>
        </div>
        <div className={styles.search}>
          <Search loading={loading} searchHandler={handleSearch} clear />
        </div>
      </Row>
      {userType === 'ENTITY' && userHasInvitePermissions && (
        <Modal
          placement="left"
          overlayClassName={styles.users_nowidget}
          content={<InviteEntityUserForm entity={entity} />}
          destroyTooltipOnHide
          trigger="click"
          visible={popoverVisible}
          onVisibleChange={(visible) => setPopoverVisible(visible)}
        >
          <div className={cn(styles.add_button, 'mt-0 mt-md-1')}>
            <Button type="primary" icon={<PlusOutlined />} block size="large">
              {t('buttons.add')}
            </Button>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default Header
