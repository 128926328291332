import { FC } from 'react'
import { Select, SelectProps as SelectPropsAntd, FormInstance } from 'antd'
import { DefaultOptionType } from 'antd/lib/select'
import { useTranslation } from 'react-i18next'

import { tagRender } from './helpers'

import './styles.scss'

const { Option } = Select

export type SelectProps = SelectPropsAntd<any> & { form?: FormInstance; allowClearOption?: boolean }

const SelectComponent: FC<SelectProps> = ({ options = [], form, allowClearOption, ...rest }) => {
  const { t } = useTranslation('common')
  if (allowClearOption && !form) {
    throw new Error('For field "allowClearOption", field "form" are mandatory')
  }
  return (
    <Select
      {...rest}
      onChange={(value: any, option: DefaultOptionType | DefaultOptionType[]) => {
        if (rest.onChange) {
          rest.onChange(value, option)
        }
        if (value === null && form && rest.id) {
          form.resetFields([rest.id])
          if (rest.onClear) {
            rest.onClear()
          }
        }
      }}
    >
      {allowClearOption && (
        <Option className="ant-select-item-option-divider">{t('select.clearAll')}</Option>
      )}
      {options.map((option) => (
        <Option
          key={option.value}
          value={option.value}
          className={option.divider ? 'ant-select-item-option-divider' : ''}
        >
          {option.label}
        </Option>
      ))}
    </Select>
  )
}

export default SelectComponent
export { tagRender }
