import { Modal } from 'components'
import { TQQuestionType } from 'context/Questions'
import { useTaxOrganizer } from 'context/TaxOrganizer'
import { useTranslation } from 'react-i18next'

import Question from './components/Question'

const TaxQuestions = ({
  questions,
  activeCategory,
}: {
  questions: TQQuestionType[]
  activeCategory: string
}) => {
  const { t } = useTranslation('taxOrganizer')
  const {
    taxOrganizer: {
      data: { isLocked },
    },
  } = useTaxOrganizer()
  return (
    <div>
      {questions.map((question, i) => (
        <Modal
          placement="top"
          content={t('locked.question')}
          overlayInnerStyle={{ display: isLocked ? 'block' : 'none' }}
          key={`${question.guid}-modal`}
        >
          {' '}
          <Question
            key={question.guid}
            question={question}
            index={i}
            activeCategory={activeCategory}
            taxIsLocked={isLocked}
          />
        </Modal>
      ))}
    </div>
  )
}

export default TaxQuestions
