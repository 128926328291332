import { useEffect } from 'react'
import { Table } from 'components'
import { getOrderingParams } from 'utils/table'

import useFormTable from 'hooks/useFormTable'

import { TEntity, useEntity, useEntityActions } from 'context/Entity'
import { DEFAULT_PARAMS } from './constants'

import useColumns from './hooks/useColumnsPending'
import Header from './Header'

type TProps = {
  entity: TEntity
}

const Pending = ({ entity }: TProps) => {
  const { loadEntityUserInvites, loading } = useEntityActions()
  const { params, setParams, currentPage, handleSearch, handlePageChange } = useFormTable<TParams>({
    defaultParams: { ...DEFAULT_PARAMS },
  })
  const { columns } = useColumns({ entity })
  const {
    entity: {
      userInvites: { data: dataSource, total },
    },
  } = useEntity()

  useEffect(() => {
    if (entity) {
      loadEntityUserInvites(entity.guid, params)
    }
  }, [entity, params])

  return (
    <>
      <Header entity={entity} handleSearch={handleSearch} loading={loading} />
      <Table
        table={{
          dataSource,
          columns,
          rowKey: 'guid',
          loading,
          tableLayout: 'auto',
          onChange: (p, f, sorter) => {
            setParams((prevParams) => ({ ...prevParams, ordering: getOrderingParams(sorter) }))
          },
        }}
        pagination={{ total, onChange: handlePageChange, current: currentPage }}
      />
    </>
  )
}

export default Pending
