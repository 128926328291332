import { InfoCircleOutlined } from '@ant-design/icons'
import { Steps, StepsProps } from 'antd'
import { useTranslation } from 'react-i18next'

import styles from './steps.module.scss'

const { Step } = Steps

type Props = StepsProps & {
  titles: { key: number; title: string; description?: string; hidden: boolean }[]
  getChangedValue: (current: number) => void
}

const CustomSteps = ({
  titles,
  direction,
  current,
  labelPlacement,
  status,
  getChangedValue,
}: Props) => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'taxReturns.steps' })
  return (
    <>
      {' '}
      <Steps
        current={current}
        size="small"
        className={styles.steps}
        direction={direction}
        labelPlacement={labelPlacement}
        status={status}
        onChange={getChangedValue}
        responsive={false}
      >
        {titles.map((_: { key: number; title: string; hidden: boolean }, i: number) => {
          if (_.hidden) return <></>
          if (i === 0) {
            return <Step title={t(_.title)} icon={<InfoCircleOutlined />} key={_.title} />
          }
          return <Step title={t(_.title)} key={_.title} />
        })}
      </Steps>
    </>
  )
}

export default CustomSteps
