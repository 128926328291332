import { useFetch } from 'use-http'
import qs from 'qs'

import { toSnake } from 'utils'
import { ActionTypes, CreateThreadParams } from './action-types'
import { useDocumentDispatch, TThreadsParams, NewThread } from '..'

const useDocumentActions = () => {
  const dispatch = useDocumentDispatch()
  const { get, post, del, put, patch, response, loading } = useFetch()

  const loadDocument = async (taxOrganizerId: string, documentGuid: string) => {
    const document = await get(`/tax-organizers/${taxOrganizerId}/documents/${documentGuid}`)
    if (response.ok) {
      dispatch({ type: ActionTypes.LOAD_DOCUMENT, payload: document })
    }
  }

  const loadThreads = async (params: TThreadsParams) => {
    const query = qs.stringify(
      toSnake({
        taxOrganizer: params.taxOrganizerGuid,
        document: params.documentGuid,
        page: params.page,
        taxReturn: params.taxReturnId,
      }),
      { addQueryPrefix: true }
    )
    const threads = await get(`tax-organizers/threads${query}`)
    if (response.ok) {
      dispatch({ type: ActionTypes.LOAD_THREADS, payload: threads.results })
    }
  }

  const setCommentMode = (value: boolean) =>
    dispatch({ type: ActionTypes.SET_COMMENT_MODE, payload: value })

  const setNewThread = (value?: NewThread) => {
    dispatch({ type: ActionTypes.SET_NEW_THREAD, payload: value })
  }

  const createThread = async (
    taxOrganizerId: string,
    documentGuid: string,
    params: CreateThreadParams,
    contextKey: string = 'context'
  ) => {
    const thread = await post(
      '/tax-organizers/threads',
      toSnake({
        taxOrganizerGuid: taxOrganizerId,
        [contextKey]: {
          taxDocumentGuid: documentGuid,
          taxReturnPageGuid: params.taxReturnPageGuid,
          pageNumber: params.page,
          pdfCoordinates: {
            x: params.x,
            y: params.y,
          },
          taxReturnGuid: params.taxReturnGuid,
        },
        message: params.message,
      })
    )
    if (response.ok) {
      dispatch({ type: ActionTypes.CREATE_THREAD, payload: thread })
    }
  }

  const updateThread = async (threadGuid: string, params: { isResolved?: boolean }) => {
    const thread = await patch(`/tax-organizers/threads/${threadGuid}`, {
      is_resolved: params.isResolved,
    })
    if (response.ok) {
      dispatch({ type: ActionTypes.UPDATE_THREAD, payload: thread })
    }
  }

  const loadThreadMessages = async (threadGuid: string) => {
    const messages = await get(`/tax-organizers/threads/${threadGuid}/messages`)
    if (response.ok) return messages.results
    return []
  }

  const addMessage = async (threadGuid: string, message: string) => {
    const newMessage = await post(`/tax-organizers/threads/${threadGuid}/messages`, { message })
    return newMessage
  }

  const deleteMessage = async (threadGuid: string, messageId: string) => {
    const deleteConfirmation = await del(
      `/tax-organizers/threads/${threadGuid}/messages/${messageId}`
    )
    return deleteConfirmation
  }

  const deleteThread = async (threadGuid: string) => {
    await del(`/tax-organizers/threads/${threadGuid}`)
  }

  const updateMessage = async (
    threadGuid: string,
    messageId: string,
    params: { message: string }
  ) => {
    const newMessage = await patch(
      `/tax-organizers/threads/${threadGuid}/messages/${messageId}`,
      params
    )
    return newMessage
  }

  const markThreadAsRead = async (threadGuid: string) => {
    await put(`/tax-organizers/threads/${threadGuid}/mark-read`, {
      timestamp: new Date().toISOString(),
    })
  }

  return {
    loadDocument,
    loadThreads,
    setCommentMode,
    setNewThread,
    createThread,
    updateThread,
    loadThreadMessages,
    addMessage,
    markThreadAsRead,
    updateMessage,
    deleteMessage,
    deleteThread,
    response,
    loading,
  }
}

export default useDocumentActions
export { ActionTypes }
