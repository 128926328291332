export const DASHBOARD_PATH = '/dashboard'
export const ONBOARDING_PATH = '/onboarding'
export const RESET_PASSWORD_CONFIRM_PATH = '/reset-password-confirm'
export const RESET_PASSWORD_PATH = '/reset-password'
export const EMAIL_VERIFICATION_PATH = '/confirm/email'
export const COLLECTION_PATH = 'collection'
export const INVITATIONS_PATH = `${DASHBOARD_PATH}/user/invitations`
export const LOGIN_PATH = '/login'
export const REGISTER_PATH = '/register'
export const FIRM_INVITATIONS_PATH = '/firm-invitations/:inviteGuid'
export const TAX_FIRM_PROFILE_PATH = '/tax-firms/:slug'
export const CONTACT_INFORMATION_PATH = '/tax-firms/:slug/contact'
export const TAX_ORGANIZER_PATH = `/tax-organizer/:taxOrganizerGuid`

export const getEntityPath = (path: string = DASHBOARD_PATH) =>
  `${path}/entity/:entityGuid/:slug?/:documentGuid?`
export const getEntityPathById = (entityGuid: string) => `${DASHBOARD_PATH}/entity/${entityGuid}`
export const getTaxOrganizerPathById = (taxOrganizerGuid: string) =>
  TAX_ORGANIZER_PATH.replace(':taxOrganizerGuid', taxOrganizerGuid)
export const getCPAOnboardingPath = (
  onboardingStatus: TOnboardingClientStatus,
  locationPathname?: string
) => {
  switch (onboardingStatus) {
    case 'INVITATIONS':
      return `${ONBOARDING_PATH}/cpa/invitations`
    case 'SETUP_CREATE':
      return `${ONBOARDING_PATH}/cpa/setup`
    case 'SETUP':
    case 'LOGO':
      return `${ONBOARDING_PATH}/cpa/logo`
    case 'BILLING':
      return `${ONBOARDING_PATH}/cpa/billing`
    case 'INVITE_USERS':
      return `${ONBOARDING_PATH}/cpa/invite-users`
    case 'INVITE_CLIENTS':
      return `${ONBOARDING_PATH}/cpa/invite-clients`
    case 'COMPLETE':
      return locationPathname
        ? locationPathname?.includes(ONBOARDING_PATH)
          ? '/'
          : locationPathname
        : '/'
    default:
      return '/'
  }
}

export const redirectFromTo = (from: string, to: string, history: any) => {
  if (from !== to) {
    history.replace(to)
  }
}
