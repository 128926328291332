import { useState, useEffect, useMemo } from 'react'
import { Box } from 'reflexbox'

import { TClientInviteParams, useEntityCPAs, useEntityCPAsActions } from 'context/EntityCPAs'
import { useAuth } from 'context/Auth'

import { Table } from 'components'
import useColumns from 'pages/Dashboard/EntityCPAs/Pending/hooks/useColumns'
import Invite from './Invite'
import { DEFAULT_INVITATION_PARAMS } from '../constants'

const Pending = () => {
  const {
    state: {
      clientInvites: { data, total },
    },
  } = useEntityCPAs()
  const { loadCpaClientInvites, loading } = useEntityCPAsActions()
  const [params, setParams] = useState<TClientInviteParams>(DEFAULT_INVITATION_PARAMS)
  const {
    CPA: { cpaFirm },
  } = useAuth()
  const anyInvitation = useMemo(() => total !== undefined && total > 0, [total])

  const pendingColumns = useColumns({
    setParams,
  })

  useEffect(() => {
    loadCpaClientInvites(cpaFirm!.guid, DEFAULT_INVITATION_PARAMS)
  }, [params])

  return (
    <>
      <Invite setParams={setParams} anyInvitation={anyInvitation} />
      {anyInvitation && (
        <Box mt={[4]}>
          <Table
            table={{
              dataSource: data,
              columns: pendingColumns,
              rowKey: 'guid',
              loading,
            }}
            pagination={{}}
          />
        </Box>
      )}
    </>
  )
}

export default Pending
