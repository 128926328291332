import { Link, useParams } from 'react-router-dom'
import { Modal } from 'components'
import { TCPAContact } from 'context/TaxOrganizer'

import { ReactComponent as Phone } from 'assets/images/phone-circle.svg'
import { ReactComponent as Letter } from 'assets/images/letter-circle.svg'

import styles from '../../taxteam.module.scss'

type PropsType = {
  user: TCPAContact
}

const PrimaryContact = ({ user }: PropsType) => {
  const { id }: { id: string } = useParams()

  if (!user) return null

  return (
    <div className={styles.primary_contact}>
      <div className={styles.primary_contact_title}>
        {user.firstName} {user.lastName}
      </div>
      <div className={styles.primary_contact_info}>
        <div>
          {user.phone && (
            <Modal content={<span>{user.phone}</span>}>
              <Phone />
            </Modal>
          )}
        </div>
        {id && (
          <div className={styles.primary_contact_link}>
            <Link to={`/tax-organizer/${id}/messages`}>
              <Letter />
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

export default PrimaryContact
