import { useEffect, useState } from 'react'
import { div, mod } from 'utils'
import { INotification } from 'context/Notifications'

export default ({ sidebarCollapsed, params, chunk, count }: any) => {
  const [more, setMore] = useState(true)
  const [notifications, setNotifications] = useState<INotification[]>([])
  const [nextStep, setNextStep] = useState(false)

  useEffect(() => {
    if (sidebarCollapsed) {
      setMore(true)
      setNotifications([])
      setNextStep(false)
    }
  }, [sidebarCollapsed])

  useEffect(() => {
    if (params.page === 1) {
      setNotifications(chunk)
    } else {
      setNotifications((currentNotifications) => [...currentNotifications, ...chunk])
    }
  }, [chunk])

  useEffect(() => {
    setNextStep(
      div(count, notifications?.length) > 1 ||
        (div(count, notifications?.length) === 1 && mod(count, notifications?.length) > 0)
    )
    setMore(notifications?.length < count)
  }, [notifications])

  return { more, setMore, notifications, setNotifications, nextStep, setNextStep }
}
