import Cookies from 'js-cookie'
import { COOKIES } from 'constants/cookies'

export const browserSession = {
  setItem: (key: string, value: any) => {
    const EXCEPTION_KEYS: Readonly<string[]> = ['maskedToken', 'maskedGuid']
    try {
      sessionStorage.setItem(key, JSON.stringify(value))
    } catch (e) {
      if (
        e instanceof DOMException &&
        (e.code === 22 ||
          e.code === 1014 ||
          e.name === 'QuotaExceededError' ||
          e.name === 'NS_ERROR_DOM_QUOTA_REACHED')
      ) {
        try {
          const storageKeys = Object.keys(sessionStorage)
          storageKeys.forEach((storageKey: string) => {
            if (!EXCEPTION_KEYS.includes(storageKey)) {
              sessionStorage.removeItem(storageKey)
            }
          })
        } catch (err) {
          console.error(`sessionStorage, probably, is not supported: ${err}`)
        }
      }
    }
  },
  getItem: (key: string) => {
    try {
      const value = sessionStorage.getItem(key)
      return value ? JSON.parse(value) : null
    } catch (e) {
      console.error(e)
      return null
    }
  },
  removeItem: (key: string) => {
    sessionStorage.removeItem(key)
  },
  clear: () => {
    sessionStorage.clear()
  },
}

export const browserStorage = {
  setItem: (key: string, value: any) => {
    const EXCEPTION_KEYS: Readonly<string[]> = ['token']
    try {
      localStorage.setItem(key, JSON.stringify(value))
    } catch (e) {
      if (
        e instanceof DOMException &&
        (e.code === 22 ||
          e.code === 1014 ||
          e.name === 'QuotaExceededError' ||
          e.name === 'NS_ERROR_DOM_QUOTA_REACHED')
      ) {
        try {
          const storageKeys = Object.keys(localStorage)
          storageKeys.forEach((storageKey: string) => {
            if (!EXCEPTION_KEYS.includes(storageKey)) {
              localStorage.removeItem(storageKey)
            }
          })
        } catch (err) {
          console.error(`localStorage, probably, is not supported: ${err}`)
        }
      }
    }
  },
  getItem: (key: string) => {
    try {
      const value = localStorage.getItem(key)
      return value ? JSON.parse(value) : null
    } catch (e) {
      console.error(e)
      return null
    }
  },
  removeItem: (key: string) => {
    localStorage.removeItem(key)
  },
  clear: () => {
    localStorage.clear()
  },
}

export const removeCookies = () => COOKIES.forEach((cookie: string) => Cookies.remove(cookie))
