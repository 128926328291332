import { useState } from 'react'
import { Avatar, Menu, Dropdown, Typography } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import cn from 'classnames'
import { TFunction, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import {
  ActionTypes,
  ExternalApplication,
  useAuth,
  useAuthDispatch,
  UserProfile,
} from 'context/Auth'
import useSwitchApplicationActions from 'context/Auth/use-switch-application-actions'
import { getInitials } from 'utils'
import { enableSSOV1Link, enableSSOMarqixLink } from 'utils/featureFlags'
import { Badge } from 'components'
import styles from './userdropdown.module.scss'
import MaskModal from './MaskModal'

type Props = {
  className?: string
}

const menu = (
  handleClick: (item: { key: string }) => void,
  user: UserProfile,
  t: TFunction<'common'>,
  invitations: number
) => {
  const showSSOV1Link = enableSSOV1Link && user.hasV1Account
  const showSSOMarqixLink = enableSSOMarqixLink

  return (
    <Menu onClick={handleClick} className="ant-dropdown-menu-default">
      <Menu.Item key="profile">
        <Link to="/dashboard/user/profile">{t('profile')}</Link>
      </Menu.Item>
      <Menu.Item key="invitations">
        <Link to="/dashboard/user/invitations">
          <Badge count={invitations} scheme="purple" size="small" offset={[8, 2]}>
            {t('invitations')}
          </Badge>
        </Link>
      </Menu.Item>
      <Menu.Item key="settings">
        <Link to="/dashboard/user/settings">{t('settings')}</Link>
      </Menu.Item>

      {(showSSOV1Link || showSSOMarqixLink) && <Menu.Divider />}
      {showSSOV1Link && <Menu.Item key="switch_application_v1">Go to Partnership Tool</Menu.Item>}
      {showSSOMarqixLink && (
        <Menu.Item key="switch_application_marqix">Go to Reporting Tool</Menu.Item>
      )}

      <Menu.Divider />
      {user.isStaff && (
        <Menu.Item key="mask">
          <Typography.Text>{t('mask')}</Typography.Text>
        </Menu.Item>
      )}
      <Menu.Item key="logout">{t('logout')}</Menu.Item>
    </Menu>
  )
}

const UserDropdown = ({ className }: Props) => {
  const { t } = useTranslation('common', { keyPrefix: 'user.dropdown' })
  const { user, invitations } = useAuth()
  const dispatch = useAuthDispatch()
  const { switchApplication } = useSwitchApplicationActions()
  const [openModal, setOpenModal] = useState(false)

  const handleMenuClick = (item: { key: string }) => {
    switch (item.key) {
      case 'logout':
        dispatch({ type: ActionTypes.LOGOUT })
        break

      case 'switch_application_v1':
        switchApplication(ExternalApplication.V1)
        break

      case 'switch_application_marqix':
        switchApplication(ExternalApplication.MARQIX)
        break

      case 'mask':
        setOpenModal(true)
        break

      default:
        break
    }
  }

  if (!user) return null

  return (
    <>
      <Dropdown
        overlay={() => menu(handleMenuClick, user, t, invitations.pendingInvitations)}
        trigger={['click']}
        align={{ offset: [0, 10] }}
      >
        <div className={cn(styles.user, className)}>
          <Badge
            count={invitations.pendingInvitations}
            scheme="purple"
            size="small"
            offset={[0, 2]}
          >
            {user?.avatar ? (
              <Avatar size={36} src={user?.avatar} />
            ) : (
              <Avatar size={36}>{getInitials(user)}</Avatar>
            )}
          </Badge>
          <DownOutlined className={styles.user_arrow} size={12} />
        </div>
      </Dropdown>
      {user.isStaff && <MaskModal open={openModal} onClose={() => setOpenModal(false)} />}
    </>
  )
}

export default UserDropdown
