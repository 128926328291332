import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Flex } from 'reflexbox'
import { SearchOutlined } from '@ant-design/icons'
import { Form } from 'antd'
import { debounce } from 'lodash'

import { TClientInviteParams, useEntityCPAs, useEntityCPAsActions } from 'context/EntityCPAs'

import { Table, TextField } from 'components'

import { useAuth } from 'context/Auth'
import useColumns from './hooks/useColumns'
import EditInvitationModal from '../components/EditInvitationModal'
import { DEFAULT_PARAMS } from './constants'

type TProps = {
  inviteClientPopoverStatus: boolean
}

const Pending = ({ inviteClientPopoverStatus }: TProps) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'clients.head' })
  const [params, setParams] = useState<TClientInviteParams>({ ...DEFAULT_PARAMS })
  const [selectedInvitation, setSelectedInvitation] = useState<IClientInvite>()
  const [form] = Form.useForm()
  const {
    state: {
      clientInvites: { data, total, search: dataSearch },
    },
  } = useEntityCPAs()
  const {
    CPA: { cpaFirm },
  } = useAuth()
  const { loadCpaClientInvites, loading } = useEntityCPAsActions()
  const { loadClientSummary } = useEntityCPAsActions()
  const columns = useColumns({ setParams })

  const setPageHandler = (pageNumber: number) => {
    setParams((prevParams: any) => ({ ...prevParams, page: pageNumber }))
  }

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setParams((prevParams: TLoadEntitiesParams) => {
      return {
        ...prevParams,
        page: DEFAULT_PARAMS.page,
        search: event.target.value ? event.target.value.trim() : undefined,
      }
    })
  }

  const debouncedTextChangeHandler = useMemo(() => debounce(handleSearch, 300), [])

  useEffect(() => {
    if (!inviteClientPopoverStatus) {
      loadCpaClientInvites(cpaFirm!.guid, params)
    }
    loadClientSummary(cpaFirm!.guid)
  }, [params, inviteClientPopoverStatus])

  useEffect(() => {
    return () => {
      debouncedTextChangeHandler.cancel()
    }
  }, [])

  return (
    <>
      <Flex flexWrap="wrap" justifyContent={['flex-end']} flexDirection={['column', 'row']}>
        <Form form={form}>
          <Box width={['100%', '250px']}>
            <Form.Item name="search" key="seacrh">
              <TextField
                placeholder={t('searchClient')}
                size="middle"
                suffix={<SearchOutlined />}
                allowClear
                onChange={debouncedTextChangeHandler}
              />
            </Form.Item>
          </Box>
        </Form>
      </Flex>
      <Table
        table={{
          dataSource: data.length ? data : dataSearch.data,
          columns,
          rowKey: 'guid',
          loading,
          onRow: (record) => ({
            onClick: () => {
              setSelectedInvitation(record)
            },
          }),
        }}
        pagination={{
          total: total || dataSearch.total,
          onChange: setPageHandler,
          current: params.page,
        }}
      />
      {selectedInvitation && (
        <EditInvitationModal
          invite={selectedInvitation}
          inviteStatus="pen"
          closeForm={() => setSelectedInvitation(undefined)}
        />
      )}
    </>
  )
}

export default Pending
