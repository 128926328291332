import { useTranslation } from 'react-i18next'
import { ColumnsType } from 'antd/lib/table'

import { Avatar, Button, Dropdown, Menu, Typography } from 'antd'
import { getInitials } from 'utils'
import { useEntityActions, TEntity } from 'context/Entity'
import { TEntityUserInvite } from 'context/Invites/types'
import { DashOutlined } from '@ant-design/icons'
import styles from '../../../../dashboard.entity.management.module.scss'

const { Text } = Typography

type Props = {
  entity: TEntity
}

export default ({ entity }: Props) => {
  const { t } = useTranslation('entity')
  const { t: t2 } = useTranslation('dashboard', { keyPrefix: 'clients.table' })
  const { resendEntityUserInvite, revokeEntityUserInvite } = useEntityActions()

  const columns: ColumnsType<TEntityUserInvite> = [
    {
      title: t('common.email'),
      key: 'email',
      dataIndex: 'email',
      render: (email) => {
        return <Text className={styles.section_name}>{email}</Text>
      },
    },
    {
      title: t('common.role'),
      key: 'role',
      dataIndex: 'role',
      render: (role) => <Text>{t(`userRole.${role}`)}</Text>,
    },
    {
      title: t('users.invitedBy'),
      key: 'invitedBy',
      render: ({ invitedBy }) => (
        <div className="text-nowrap">
          {invitedBy.avatar ? (
            <Avatar size={24} src={invitedBy.avatar} />
          ) : (
            <Avatar size={24}>{getInitials(invitedBy)}</Avatar>
          )}
          <Text className={styles.section_name}>{invitedBy.firstName}</Text>
        </div>
      ),
    },
    {
      title: t2('actions'),
      key: 'actions',
      render: (invite) => (
        <Dropdown
          overlay={() => (
            <Menu theme="dark">
              <Menu.Item
                onClick={() => resendEntityUserInvite(entity.guid, invite.guid)}
                key="settings"
              >
                {t2('resend')}
              </Menu.Item>
              <Menu.Item
                onClick={() => revokeEntityUserInvite(entity.guid, invite.guid)}
                key="profile"
              >
                {t2('revoke')}
              </Menu.Item>
            </Menu>
          )}
          trigger={['click']}
          placement="bottomLeft"
        >
          <Button type="text">
            <DashOutlined className={styles.actions} />
          </Button>
        </Dropdown>
      ),
    },
  ]

  return { columns }
}
