import { UnsentInvitationFilesType } from 'context/EntityCPAs'
import { useEffect, useState } from 'react'
import { TUploadedSection } from '../types'

type PropsType = {
  unsentInvitationFiles: UnsentInvitationFilesType[] | undefined
  totalUnsentInvitations: number | undefined
  searchIsActive: boolean
}

export default ({ unsentInvitationFiles, totalUnsentInvitations, searchIsActive }: PropsType) => {
  const [uploadSectionStatus, setUploadSectionStatus] = useState<TUploadedSection>()

  useEffect(() => {
    if (
      typeof unsentInvitationFiles !== 'undefined' &&
      typeof totalUnsentInvitations !== 'undefined' &&
      !searchIsActive
    ) {
      const status =
        unsentInvitationFiles.length > 0 || totalUnsentInvitations > 0 ? 'closed' : 'opened'
      setUploadSectionStatus(status)
    }
  }, [unsentInvitationFiles, totalUnsentInvitations, searchIsActive])

  return { uploadSectionStatus, setUploadSectionStatus }
}
