import { Key } from 'react'
import { Box, Flex } from 'reflexbox'
import { CloseOutlined, UploadOutlined } from '@ant-design/icons'
import { message } from 'antd'
import { Button } from 'components'
import { TClientInviteParams, useEntityCPAsActions } from 'context/EntityCPAs'
import { reloadData } from 'utils'
import useMediaQuery from 'hooks/useMediaQuery'
import { useTranslation } from 'react-i18next'
import { DEFAULT_PARAMS } from '../constants'
import { TUploadedSection } from '../types'

type PropsType = {
  uploadSectionStatus: TUploadedSection
  setUploadSectionStatus: (
    prev: TUploadedSection | ((prev: TUploadedSection) => TUploadedSection)
  ) => void
  params: TClientInviteParams
  setParams: (
    value: TClientInviteParams | ((prevState: TClientInviteParams) => TClientInviteParams)
  ) => void
  dataSource: IClientInvite[]
  uninvitedSelectedRows: Key[]
  setUninvitedSelectedRows: (keys: Key[]) => void
  totalUnsentInvitations: number | undefined
  cpaFirmGuid: string
}

const Buttons = ({
  uploadSectionStatus,
  setUploadSectionStatus,
  params,
  setParams,
  dataSource,
  uninvitedSelectedRows,
  setUninvitedSelectedRows,
  totalUnsentInvitations,
  cpaFirmGuid,
}: PropsType) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'clients' })
  const minWidthMd = useMediaQuery('md')
  const {
    sendUninvited,
    loading: sendUninvitedLoading,
    response: sendUninvitedResponse,
  } = useEntityCPAsActions()
  const handleSectionStatus = () => {
    setUploadSectionStatus((prevState) => (prevState === 'opened' ? 'closed' : 'opened'))
  }

  const handleSendToSelected = async () => {
    const selectAll = !params.search && uninvitedSelectedRows.length === totalUnsentInvitations
    const sendParams = selectAll
      ? {
          all: true,
        }
      : { guids: uninvitedSelectedRows }
    await sendUninvited(cpaFirmGuid, sendParams)
    if (sendUninvitedResponse.ok) {
      setUninvitedSelectedRows([])
      message.success(
        selectAll
          ? t('table.messageSendUninvited.all')
          : t('table.messageSendUninvited.invitations', { num: uninvitedSelectedRows.length })
      )
      reloadData(dataSource.length === uninvitedSelectedRows.length, setParams, DEFAULT_PARAMS)
    }
  }

  return (
    <Flex my={[2, 0]} flexDirection={['column', 'row']}>
      {uploadSectionStatus && (
        <>
          <Box width={['100%']}>
            <Button
              type={uploadSectionStatus === 'opened' ? 'default' : 'primary'}
              size="large"
              style={{ width: minWidthMd ? 160 : '100%' }}
              disabled={!!params.search || uninvitedSelectedRows.length > 0}
              icon={uploadSectionStatus === 'opened' ? <CloseOutlined /> : <UploadOutlined />}
              onClick={handleSectionStatus}
            >
              {uploadSectionStatus === 'opened' ? t('table.closeUpload') : t('table.uploadFile')}
            </Button>
          </Box>
          <Box ml={[0, 3]} my={[1, 0]} width={['100%']}>
            <Button
              type="primary"
              size="large"
              disabled={uninvitedSelectedRows.length <= 0 || sendUninvitedLoading}
              loading={sendUninvitedLoading}
              onClick={handleSendToSelected}
              style={{ width: minWidthMd ? 160 : '100%' }}
            >
              {uninvitedSelectedRows.length > 0
                ? t('table.inviteSelectedParam', { num: uninvitedSelectedRows.length })
                : t('table.inviteSelected')}
            </Button>
          </Box>
        </>
      )}
    </Flex>
  )
}

export default Buttons
