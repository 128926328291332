import { uniqBy } from 'lodash'

import { DocumentTag } from 'context/Dashboard'
import { ActionTypes } from '../actions'
import {
  IAction,
  TTaxDocument,
  TMultipleDocumentParams,
  TTaxDocumentExtended,
  IDocumentsState,
} from '..'

export interface CategoryOption extends TOption {
  parent: {
    slug: string
    title: string
  }
}

export const initialDocumentsState: IDocumentsState = {
  data: [],
  temporaryUploaded: [],
  dataRequiresAction: [],
  count: 0,
  selectedDocumentIds: [],
  documentsSummary: undefined!,
  mulptipleDocumentParams: [],
}

export const documentsReducer = (
  state: IDocumentsState = initialDocumentsState,
  action: IAction
) => {
  switch (action.type) {
    case ActionTypes.LOAD_CURRENT_ENTITY_DOCS:
      return {
        ...state,
        data: action.payload?.results?.map((document: TTaxDocument & { tags: DocumentTag[] }) => {
          return {
            ...document,
            tags: document.tags.map((tag: DocumentTag): TOption => {
              return {
                label: tag.name,
                value: tag.slug,
              }
            }),
          }
        }),
        count: action.payload?.count,
      }

    case ActionTypes.LOAD_DOCUMENTS_SUMMARY: {
      return {
        ...state,
        documentsSummary: action.payload,
      }
    }

    case ActionTypes.UPLOAD_DOCUMENT: {
      const newDocument = action.payload
      return {
        ...state,
        temporaryUploaded: uniqBy(
          [
            ...state.temporaryUploaded,
            {
              ...newDocument,
              tags: newDocument.tags.map((tag: { name: string; slug: string }) => {
                return {
                  label: tag.name,
                  value: tag.slug,
                }
              }),
            },
          ].reverse(),
          'guid'
        ),
      }
    }

    case ActionTypes.DELETE_DOCUMENT: {
      const data = state.data.filter(
        (document: TTaxDocument) => document.guid !== action.payload.documentGuid
      )
      return {
        ...state,
        count: data.length,
        data,
        temporaryUploaded: state.temporaryUploaded.filter(
          (file: TTaxDocumentExtended) => file.guid !== action.payload.documentGuid
        ),
        dataRequiresAction: state.dataRequiresAction.filter(
          (file: TTaxDocument) => file.guid !== action.payload.documentGuid
        ),
        selectedDocumentIds: state.selectedDocumentIds?.length
          ? state.selectedDocumentIds?.filter((d) => d !== action.payload.documentGuid)
          : [],
      }
    }

    case ActionTypes.SET_SELECTED_TABLE_DOCUMENT_IDS: {
      return {
        ...state,
        selectedDocumentIds: action.payload,
      }
    }

    case ActionTypes.SET_MULTIPLE_DOCUMENT_PARAMS: {
      const { mulptipleDocumentParams } = state
      const newDocumentParams = action.payload
      return {
        ...state,
        mulptipleDocumentParams: uniqBy(
          [...mulptipleDocumentParams, newDocumentParams].reverse(),
          'guid'
        ),
      }
    }

    case ActionTypes.REMOVE_MULTIPLE_DOCUMENT_PARAM: {
      return {
        ...state,
        mulptipleDocumentParams: state.mulptipleDocumentParams.filter(
          (params: TMultipleDocumentParams) => params.guid !== action.payload
        ),
      }
    }

    case ActionTypes.CLEAR_MULTIPLE_DOCUMENT_PARAMS: {
      return {
        ...state,
        mulptipleDocumentParams: [],
      }
    }

    case ActionTypes.CLEAR_UPLOADED: {
      return {
        ...state,
        temporaryUploaded: [],
      }
    }

    default:
      return state
  }
}
