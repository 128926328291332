import { Dropdown, Menu } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { Flex } from 'reflexbox'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getCPAStatusColorByName, getEntityStatusColorByName } from 'utils'
import { ClienStatusEnum, ClientStatus, CpaStatus, CpaStatusEnum } from 'context/TaxOrganizer/types'
import { UserType } from 'context/Auth/types'

import styles from '../../tax.navigation.module.scss'

type TProps = {
  currentStatus: ClientStatus | CpaStatus
  type: UserType
  enabled: boolean
  onChange: (value: string) => void
}

const EntityStateDropDown = ({ currentStatus, type, enabled, onChange }: TProps) => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'navigation' })
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false)

  const getColorStatus = (status: string) => {
    return type === UserType.CPA
      ? getCPAStatusColorByName(status)
      : getEntityStatusColorByName(status)
  }

  const getStatus = (): string[] =>
    type === UserType.CPA ? Object.keys(CpaStatusEnum) : Object.keys(ClienStatusEnum)

  const getStatusTranslate = (status: string) =>
    type === UserType.CPA ? t(`cpaStatus.${status}`) : t(`entityStatus.${status}`)

  const onSelectionChange = (value: string) => {
    onChange(value)
    setDropdownVisible(false)
  }

  return (
    <Flex style={{ marginLeft: '8px' }} alignItems="center">
      <div className={styles.dot} style={{ backgroundColor: getColorStatus(currentStatus) }} />
      <p className={styles.dropdown_status}>{getStatusTranslate(currentStatus)}</p>
      {enabled && (
        <Dropdown
          placement="bottomRight"
          trigger={['click']}
          visible={dropdownVisible}
          onVisibleChange={setDropdownVisible}
          overlay={
            <Menu theme="dark">
              {getStatus().map((s) => (
                <Menu.Item
                  key={s}
                  icon={
                    <div className={styles.dot} style={{ backgroundColor: getColorStatus(s) }} />
                  }
                  onClick={() => onSelectionChange(s)}
                >
                  {getStatusTranslate(s)}
                </Menu.Item>
              ))}
            </Menu>
          }
        >
          <DownOutlined className={styles.status_arrow} size={12} />
        </Dropdown>
      )}
    </Flex>
  )
}

export default EntityStateDropDown
