import { useState } from 'react'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom'
import cn from 'classnames'
import { getEntityPath } from 'utils/routes'

import { EntityCPAsProvider } from 'context/EntityCPAs'
import { CPAFirmProvider } from 'context/CPAFirm'

import FirmLayout from 'layouts/FirmLayout'

import DashboardCPA from 'pages/Dashboard/Dashboard/CPA'
import DashboardEntity from 'pages/Dashboard/Dashboard/Entity'
import TaxOrganizers from 'pages/Dashboard/TaxOrganizers'
import EntityCPAs from 'pages/Dashboard/EntityCPAs'
import Entities from 'pages/Dashboard/Entities'
import Outlook from 'pages/Dashboard/Outlook'
import User from 'pages/Dashboard/User'
import Promo from 'pages/Promo'
import EntityManagement from 'pages/Dashboard/EntityManagement'

import useUserType from 'hooks/useUserType'

import Header, { TSidebar } from './components/Header'
import Footer from './components/Footer'
import SidebarOverlay from './components/SidebarOverlay'

import styles from './dashboard.layout.module.scss'
import NotificationsSidebar from './components/NotificationsSidebar'

const DashboardLayout = () => {
  const { path } = useRouteMatch()
  const userType = useUserType()

  const [sidebarCollapsed, setCollapsed] = useState<boolean>(true)
  const [selectedSidebar, setSidebar] = useState<TSidebar>('MESSAGES')

  const getSidebarOverlay = () => {
    switch (selectedSidebar) {
      case 'NOTIFICATIONS':
        return <NotificationsSidebar sidebarCollapsed={sidebarCollapsed} />
      case 'MESSAGES':
      default:
        return <SidebarOverlay sidebarCollapsed={sidebarCollapsed} />
    }
  }

  return (
    <>
      <Header
        sidebarCollapsed={sidebarCollapsed}
        selectedSidebar={selectedSidebar}
        setCollapsed={setCollapsed}
        sidebarChange={setSidebar}
      />
      <div className={cn(styles.box)}>
        <div className={cn(styles.content)}>
          <Switch>
            <Route exact path={path}>
              {userType === 'CPA' ? (
                <EntityCPAsProvider>
                  <DashboardCPA />
                </EntityCPAsProvider>
              ) : (
                <DashboardEntity />
              )}
            </Route>
            <Route path={`${path}/tax-organizers`} exact>
              <TaxOrganizers />
            </Route>
            {userType === 'CPA' ? (
              <Route path={`${path}/clients/:slug?`}>
                <EntityCPAsProvider>
                  <EntityCPAs />
                </EntityCPAsProvider>
              </Route>
            ) : (
              <Route path={`${path}/entities/:slug?`}>
                <Entities />
              </Route>
            )}
            <Route path={`${path}/outlook`} exact>
              <Outlook />
            </Route>
            <Route path={`${path}/promo`} exact>
              <CPAFirmProvider>
                <Promo />
              </CPAFirmProvider>
            </Route>
            <Route path={`${path}/user/:slug?`} exact>
              <User />
            </Route>
            {userType === 'CPA' && (
              <Route path={`${path}/firm`}>
                <FirmLayout />
              </Route>
            )}

            <Route path={getEntityPath(path)} exact>
              <EntityManagement />
            </Route>

            <Route path="*">
              <Redirect to="/dashboard" />
            </Route>
          </Switch>
        </div>
        <div
          className={styles['sidebar-bg']}
          style={{ width: sidebarCollapsed ? 0 : '100%' }}
          onClick={() => {
            setCollapsed(true)
          }}
        />
        {getSidebarOverlay()}
        <Footer />
      </div>
    </>
  )
}

export default DashboardLayout
