import { useState, useEffect, useMemo } from 'react'

import { getProgress, setTotalProgressHelper } from 'components/UploadModal/helpers'
import { BodyUploadInvites, useDocumentsActions } from 'context/TaxOrganizer'
import { TFileProgressSettings, TUploadDocumentNotAuthorize } from '../types'

export default ({
  files,
  paramsFileNotAuthorize,
  onFinishUploading,
}: TUploadDocumentNotAuthorize): any => {
  const [totalProgress, setTotalProgress] = useState<TFileProgressSettings[]>(
    files?.map(({ uid }: any) => ({
      uid,
      total: 0,
      loaded: 0,
      start: false,
    })) ?? []
  )
  const [responses, setResponses] = useState<boolean[]>([])
  const { uploadRequestDocument, response } = useDocumentsActions()

  useEffect(() => {
    const startUploadDocument = async (file: any) => {
      const fileParams: BodyUploadInvites = {
        file: file.name,
        name: file.name,
        email: paramsFileNotAuthorize.email,
        firstName: paramsFileNotAuthorize.firstName,
        lastName: paramsFileNotAuthorize.lastName,
        token: paramsFileNotAuthorize.token,
      }
      await uploadRequestDocument(paramsFileNotAuthorize.invitationGuid, fileParams, {
        file: file.originFileObj,
        onUploadProgress: (event: ProgressEvent) => {
          setTotalProgress((prevData: TFileProgressSettings[]) => {
            return setTotalProgressHelper(prevData, file, event)
          })
        },
      })
      setResponses((prevResponses) => [...prevResponses, response.ok])
    }
    if (files?.length) {
      files.forEach((file: any) => {
        startUploadDocument(file)
      })
    }
  }, [])
  const progress = useMemo(
    () => getProgress(totalProgress, files, onFinishUploading),
    [totalProgress]
  )
  const uploadStatus: undefined | 'error' | 'success' = !responses.length
    ? undefined
    : responses.some((error: boolean) => !error)
    ? 'error'
    : 'success'
  return { progress, totalProgress, uploadStatus }
}
