import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Flex } from 'reflexbox'
import { Button, Modal } from 'components'
import ForwardPopoverContent from './ForwardPopoverContent'

type TProps = {
  acceptAction: () => void
  declineAction: () => void
}

const IS_FORWARD_ENABLED = false

const ActionsCell = ({ acceptAction, declineAction }: TProps) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'clients.table' })
  const [visiblePopover, setVisiblePopover] = useState(false)
  const handleVisibleChange = (visible: boolean) => setVisiblePopover(visible)

  return (
    <>
      <Flex justifyContent={['space-between']} alignItems={['center']} flexWrap="nowrap">
        <Flex>
          <Button onClick={() => acceptAction()} type="link">
            {t('accept')}
          </Button>
          <Button onClick={() => declineAction()} type="link">
            {t('reject')}
          </Button>
          {IS_FORWARD_ENABLED && (
            <Modal
              placement="bottomLeft"
              trigger="click"
              destroyTooltipOnHide
              content={<ForwardPopoverContent setVisiblePopover={setVisiblePopover} />}
              visible={visiblePopover}
              onVisibleChange={handleVisibleChange}
            >
              <Flex flexShrink={[0]} flexGrow={[1]} justifyContent={['flex-end']}>
                <Button type="link">{t('forward')}</Button>
              </Flex>
            </Modal>
          )}
        </Flex>
      </Flex>
    </>
  )
}

export default ActionsCell
