import { Form, Select, Tag, Tooltip } from 'antd'
import { LockTwoTone } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import type { CustomTagProps } from 'rc-select/lib/BaseSelect'

import { useDashboard, useDashboardActions } from 'context/Dashboard'
import { useEntity, useEntityDocumentsActions } from 'context/Entity'

import styles from '../../dashboard.document.module.scss'

type TProps = {
  disabled: boolean
}

const tagRender = (props: CustomTagProps) => {
  const { label, value, onClose } = props
  const { taxOrganizersList } = useDashboard()
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const taxOrganizer = taxOrganizersList.find((to) => to.guid === value)
  return (
    <Tag
      color="processing"
      id={value}
      onMouseDown={onPreventMouseDown}
      closable={!taxOrganizer?.isLocked}
      onClose={onClose}
      icon={!taxOrganizer?.isLocked ? null : <LockTwoTone />}
      style={{ marginRight: 3 }}
    >
      {taxOrganizer?.isLocked ? (
        <Tooltip key={value} title="Return Filed">
          {label}
        </Tooltip>
      ) : (
        <>{label}</>
      )}
    </Tag>
  )
}

const TaxOrganizerSelectView = ({ disabled }: TProps) => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'document' })
  const { documentGuid }: { documentGuid: string } = useParams()
  const { taxOrganizersList } = useDashboard()
  const { loadTaxOrganizers } = useDashboardActions()
  const { addDocumentTaxOrganizer, removeDocumentTaxOrganizer } = useEntityDocumentsActions()
  const {
    entity: {
      currentEntity: { guid: id },
      currentEntityDocument,
    },
  } = useEntity()
  const [options, setOptions] = useState<any>()
  const initialTags = currentEntityDocument.taxOrganizers?.map(({ taxOrganizerGuid, taxYear }) => {
    return {
      label: taxYear,
      value: taxOrganizerGuid,
    }
  })

  const [form] = Form.useForm()

  const handleValueAdded = (taxOrganizerGuid: any) => {
    addDocumentTaxOrganizer(taxOrganizerGuid, documentGuid)
  }

  const handleValueRemoved = (taxOrganizerGuid: any) => {
    const taxOrganizer = taxOrganizersList.find((to) => to.guid === taxOrganizerGuid)
    if (!taxOrganizer?.isLocked) {
      removeDocumentTaxOrganizer(taxOrganizerGuid, documentGuid)
    }
  }

  useEffect(() => {
    const optionsItems = taxOrganizersList
      ?.filter(({ isLocked }) => !isLocked)
      .map(({ taxYear, guid }) => {
        return {
          label: taxYear,
          value: guid,
        }
      })

    setOptions(optionsItems)
  }, [taxOrganizersList])

  useEffect(() => {
    loadTaxOrganizers({ entity: id, ordering: '-tax_year' })
  }, [])

  return (
    <div className={styles.right_section}>
      <div className={styles.right_section_top}>
        <span className={styles.right_section_title}>{t('taxOrganizers')}</span>
      </div>
      <Form form={form}>
        <Form.Item name="taxOrganizers">
          <Select
            mode="multiple"
            optionFilterProp="children"
            disabled={disabled}
            tagRender={tagRender}
            onSelect={handleValueAdded}
            onDeselect={handleValueRemoved}
            showArrow
            showSearch={false}
            defaultValue={initialTags}
            style={{ width: '100%' }}
            options={options}
          />
        </Form.Item>
      </Form>
    </div>
  )
}

export default TaxOrganizerSelectView
