import { FC, ReactNode } from 'react'
import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'

import cn from 'classnames'
import styles from './section.module.scss'

type TStyle = { [key: string]: string }

type Props = {
  style?: TStyle
  title?: string | ReactNode
  titleIndent?: number
  className?: string
  scheme?: 'blue' | 'white'
  disabled?: boolean
  tooltip?: string
  comingSoon?: boolean
}

const Section: FC<Props> = ({
  style,
  title,
  titleIndent,
  className,
  scheme = 'white',
  disabled,
  tooltip,
  children,
  comingSoon,
}) => {
  const { t } = useTranslation('common')
  return (
    <Tooltip title={tooltip}>
      <div
        style={style}
        className={cn(styles.box, className, styles[`scheme_${scheme}`], {
          [`${styles.disabled}`]: disabled,
        })}
      >
        {comingSoon && (
          <div className={styles.coming_soon}>
            <p className={styles.overlay_text}>{t('comingSoon')}</p>
          </div>
        )}
        {title && (
          <div className={styles.title} style={{ marginBottom: titleIndent }}>
            {title}
          </div>
        )}
        {children}
      </div>
    </Tooltip>
  )
}

export default Section
