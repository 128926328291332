import { Empty } from 'antd'
import { useTranslation } from 'react-i18next'
import { Flex } from 'reflexbox'

import { Badge, Section, QuestionIcon, SpinnerBackdrop } from 'components'

import { useDashboard } from 'context/Dashboard'

import useChartData from './hooks/useChartData'
import useChart from './hooks/useChart'

const ProductionRate = () => {
  const { t } = useTranslation('dashboard')
  const { t: tCommon } = useTranslation('common')
  const { deadline } = useDashboard()
  const { chartData, loading } = useChartData()
  const { areaChart } = useChart({ chartData })

  if (!deadline?.selected) return null

  return (
    <Section
      title={
        <Badge
          count={
            <QuestionIcon
              popoverProps={{
                title: 'Info',
                content: 'Descripton text',
                placement: 'bottom',
                arrowPointAtCenter: false,
              }}
            />
          }
        >
          {t('titles.productionRate')}
        </Badge>
      }
    >
      {loading && <SpinnerBackdrop mode="local" />}
      {chartData.length ? (
        <Flex
          ref={areaChart}
          width={['100%']}
          height={['100%']}
          justifyContent={['flex-start']}
          alignItems={['center']}
        />
      ) : (
        <Flex
          height={['200px']}
          width={['100%']}
          justifyContent={['center']}
          alignItems={['center']}
        >
          <Empty description={tCommon('noData')} />
        </Flex>
      )}
    </Section>
  )
}

export default ProductionRate
