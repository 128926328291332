import { Menu, Dropdown } from 'antd'
import { useTranslation } from 'react-i18next'

import { ReactComponent as Support } from 'assets/images/support.svg'

import styles from '../../dashboard.header.module.scss'

const { SubMenu } = Menu

const SupportDropdown = () => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'support' })

  const menu = () => (
    <Menu className="ant-dropdown-menu-default">
      <Menu.Item key="knowledgeBase">
        <a href="https://help.repositax.com" target="_blank" rel="noreferrer">
          {t('knowledgeBase')}
        </a>
      </Menu.Item>
      <Menu.Item key="requestFeature" disabled>
        {t('requestFeature')}
      </Menu.Item>
      <Menu.Item key="whatsNew" disabled>
        {t('whatsNew')}
      </Menu.Item>
      <Menu.Divider />
      <SubMenu
        key="contactSupport"
        title={t('contactSupport')}
        popupClassName="ant-dropdown-menu-default"
      >
        <Menu.Item key="contactSupport.sub1">T: (858) 824-9100</Menu.Item>
        <Menu.Item key="contactSupport.sub2">E: support@repositax.com</Menu.Item>
      </SubMenu>
    </Menu>
  )

  return (
    <Dropdown
      overlay={menu}
      trigger={['click']}
      placement="bottomCenter"
      align={{ offset: [0, 15] }}
    >
      <Support className={styles.support_icon} />
    </Dropdown>
  )
}

export default SupportDropdown
