import { useEffect, useState } from 'react'
import { Table } from 'components'

import { useAuth } from 'context/Auth'
import { useCPAFirm, useCPAFirmActions } from 'context/CPAFirm'

import useColumns from './hooks/useColumns'
import { DEFAULT_PARAMS } from './constants'

type TProps = {
  popoverStatus: boolean
}

const Invitations = ({ popoverStatus }: TProps) => {
  const { loadFirmInvitations, loading } = useCPAFirmActions()
  const [currentPage, setCurrentPage] = useState<number | undefined>(DEFAULT_PARAMS.page)
  const [params, setParams] = useState(DEFAULT_PARAMS)
  const {
    invitations: { data: dataSource, total },
  } = useCPAFirm()
  const {
    CPA: { cpaFirm },
  } = useAuth()
  const { columns } = useColumns({ setParams })

  const handlePageChange = (page: number) => {
    setParams((prevParams: TParams) => {
      setCurrentPage(page)
      return { ...prevParams, page }
    })
  }

  useEffect(() => {
    loadFirmInvitations(cpaFirm!.guid, params)
  }, [params])

  useEffect(() => {
    if (!popoverStatus) {
      loadFirmInvitations(cpaFirm!.guid, params)
    }
  }, [popoverStatus])

  return (
    <>
      <Table
        table={{
          dataSource,
          columns,
          rowKey: 'guid',
          loading,
        }}
        pagination={{ total, onChange: handlePageChange, current: currentPage }}
      />
    </>
  )
}

export default Invitations
