import { ActionTypes } from './action-types'
import { TTaxDocument } from '../TaxOrganizer'
import { DocumentThread } from '../Document'

export type SigningMethod = 'DIGITAL' | 'PHYSICAL'
export type AuthorizeStep =
  | 'FILING_STATUS'
  | 'AUTHORIZED_SIGNER'
  | 'CONFIRM_SIGNER'
  | 'E_SIGN'
  | 'COMPLETE'

export type TTaxreturnPhase =
  | 'UPLOAD'
  | 'CATEGORIZE'
  | 'REVIEW'
  | 'AUTHORIZE'
  | 'FILING'
  | 'FILED'
  | 'CHANGES_REQUESTED'
  | 'CLOSED_WITHOUT_FILING'
  | 'RETURN_FILED'

export enum VerificationStatus {
  CREATED = 'CREATED',
  PROCESSING = 'PROCESSING',
  VERIFIED = 'VERIFIED',
  CANCELED = 'CANCELED',
  REQUIRES_INPUT = 'REQUIRES_INPUT',
}

export enum FilingStateEnum {
  EFILE_PENDING_CONFIRMATION = 'EFILE_PENDING_CONFIRMATION',
  PAPER_PENDING_CONFIRMATION = 'PAPER_PENDING_CONFIRMATION',
  EFILE_SUCCESS = 'EFILE_SUCCESS',
  PAPER_SUCESSS = 'PAPER_SUCESSS',
  EFILE_FAILED = 'EFILE_FAILED',
}

export enum FilingStatusEnum {
  SINGLE = 'SINGLE',
  MARRIED_JOINT = 'MARRIED_JOINT',
  MARRIED_SEPARATE = 'MARRIED_SEPARATE',
  HEAD_OF_HOUSEHOLD = 'HEAD_OF_HOUSEHOLD',
  QUALIFIED_WIDOWHOOD = 'QUALIFIED_WIDOWHOOD',
}

export type TTaxReturn = {
  authorizeStep?: AuthorizeStep | null
  draftVersion?: number
  filingState?: FilingStateEnum | null
  filingStatus?: FilingStatusEnum | null
  guid: string
  isLatest?: boolean | null
  phase?: TTaxreturnPhase
  taxableStates?: string[] | null
  efileAuthorizationSigningMethod?: SigningMethod
}

export type TTaxReturnUpdateParams = {
  authorizeStep?: AuthorizeStep
  efileAuthorizationSigningMethod?: SigningMethod
  filingState?: string
  filingStatus?: string
  phase?: TTaxreturnPhase
  taxableStates?: string[]
}

export type TTaxReturnDocument = {
  taxDocument: TTaxDocument
  taxReturnId: string
  isLatest?: boolean | null
  pageCount?: number
  afterTaxOrganizerLock: boolean
}

export type TTaxReturnPage = {
  taxReturnPageId: number
  guid: string
  taxDocumentGuid: string
  pageNumber: number
  categoryPageNumber: number
  parentCategory: string
  childCategory: string
  state: string
  uiIndex?: number
  thumbnail: string
}

export type TChildCategories = {
  label: string
  slug: string
}

export type TDocumentPageIdentifier = {
  documentGuid: string
  pageId: number
  uiIndex?: number
  guid: string
  thumbnail?: string
}

export type TTaxReturnCategory = {
  label: string
  slug: string
  childCategories: TChildCategories[]
}

export type TTaxReturnMenuCategory = {
  label: string
  slug: string
  childCategories: TTaxReturnMenuCategory[]
  expanded: boolean
  pages?: TDocumentPageIdentifier[]
}

export type TRequestDocument = {
  guid: string
  parentCategory: string | null
  childCategory: string | null
  state: string | null
  categoryPageNumber: number | null
}

export type TPagesRequest = {
  pages: TRequestDocument[]
}

export type TCategories = {
  count: number
  next?: number
  previous?: number
  results: TTaxReturnCategory[]
}

export type TThreads = {
  count: number
  next?: number
  previous?: number
  results: DocumentThread[]
}

export type TFilingStatus = {
  code: FilingStatusEnum
  description: string
}

export type TAuthorizedSigner = {
  firstName: string
  lastName: string
  avatar: string
  email: string
  guid: string
  phone: string
}

export type TSigner = {
  userGuid: string
  signedOn: string
  signingOrder: number
  user: TAuthorizedSigner
  idVerificationStatus: VerificationStatus
}

export type TAuthorizedDocDetails = {
  isSignedByAll: boolean
  referenceId: string
  signedFileUrl: string
  signers: TSigner[]
  unsignedFileUrl: string
  fieldsStatus: string
}

export type TTaxReturnProcessStatus = 'TAX_RETURN' | 'DOCUMENTS' | 'FINISHED' | undefined

export type IInviteContact = {
  email: string
  guid: string
}

export type TState = {
  steps: any[]
  step: {
    current: number | undefined
    prev: number | null
    next: number | null
  }
  maxStep: number
  process: {
    status: TTaxReturnProcessStatus
  }
  taxReturns: {
    data: TTaxReturn[]
    latest: TTaxReturn | undefined
    response: boolean | undefined
  }
  taxReturnDocuments: {
    data: TTaxReturnDocument[]
    count: number
    response: boolean | undefined
  }
  filteredTaxReturnPages: TDocumentPageIdentifier[]
  threads: TThreads
  valid: boolean
  total: number
  status: 'wait' | 'process' | 'finish' | 'error'
  content?: any
  categories: TTaxReturnCategory[]
  pages: TTaxReturnPage[]
  selectedTags: string[]
  document: TTaxDocument & { pageCount: number }
  dragDropDocumentIds: string[]
  eFileAuthDoc: string
  filingStatus: TFilingStatus[]
  entitySigners: ICpaContact[]
  entityInvites: IInviteContact[]
  authorizationDocumentDetails: TAuthorizedDocDetails
  isPressShift: boolean
  loading: boolean
}

export type TDispatch = {
  type: keyof typeof ActionTypes
  payload: any
}

export type TDocumentQueryParams = {
  ordering?: string
  page?: number
  pageSize?: number
  search?: string
  status?: string[]
}

export type TUploadTaxReturnDocumentParams = {
  name: string
  category?: string
  year?: number
  tags?: string[]
  unregisteredUserEmail?: string | null
  file: string | null
}

export type VerificationSession = {
  clientSecret: string
  publicKey: string
}
