import { CloseOutlined } from '@ant-design/icons'
import { Button, Progress } from 'antd'

const PrintProgressBar = (numLoadedPages: number, numPages: number, onCancel: () => void) => {
  const percent = Math.round((numLoadedPages * 100) / numPages)
  return (
    <div
      style={{
        alignItems: 'center',
        background: 'rgba(0, 0, 0, 0.45)',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        left: 0,
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: 9999,
      }}
    >
      <div
        style={{
          alignItems: 'center',
          backgroundColor: '#fff',
          border: '1px solid rgba(0, 0, 0, 0.3)',
          borderRadius: '0.25rem',
          display: 'flex',
          flexDirection: 'column',
          padding: '1rem 2rem .5rem 2rem',
          position: 'relative',
        }}
      >
        <Progress type="circle" percent={percent} />
        <Button
          onClick={onCancel}
          icon={<CloseOutlined />}
          style={{ position: 'absolute', top: '10px', right: '10px' }}
        />
      </div>
    </div>
  )
}

export default PrintProgressBar
