import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'

import { Section } from 'components'

import SettingsForm from './components/Form'

import styles from './user.settings.module.scss'

const UserSettings = () => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'user.settings' })
  return (
    <div className="container">
      <Section>
        <div className="padding-h-0">
          <Row gutter={[50, 32]}>
            <Col xs={24} md={16} lg={8}>
              <div className={styles.title}>{t('title')}</div>
              <div className={styles.description}>{t('context')}</div>
              <SettingsForm />
            </Col>
          </Row>
        </div>
      </Section>
    </div>
  )
}

export default UserSettings
