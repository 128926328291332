import { useEffect, useState } from 'react'
import { CheckOutlined } from '@ant-design/icons'
import { Button, Modal } from 'components'

import { DocumentThread, ThreadContextType } from 'context/Document'

import ThreadForm from './ThreadForm'
import styles from '../../documentview.module.scss'

type Props = {
  thread: DocumentThread
  contextKey: ThreadContextType
  isVisible?: boolean
  setReloadThreads: (flag: boolean | ((newFlag: boolean) => boolean)) => void
}

const Thread = ({ thread, contextKey, isVisible, setReloadThreads }: Props) => {
  const [visible, setVisible] = useState(false)
  const [isChildModalVisible, setIsChildModalVisible] = useState(false)

  useEffect(() => {
    setVisible(!!isVisible)
  }, [isVisible])

  const {
    [contextKey]: {
      pdfCoordinates: { x, y },
    },
  } = thread

  return (
    <Modal
      content={
        <ThreadForm
          setIsChildModalVisible={setIsChildModalVisible}
          thread={thread}
          onSubmitSuccess={() => setVisible(false)}
          setReloadThreads={setReloadThreads}
        />
      }
      visible={visible}
      placement="bottomLeft"
      overlayClassName={styles.popover}
      trigger="click"
      align={{ targetOffset: [30, 0] }}
      destroyTooltipOnHide
      onVisibleChange={(nextVisible) => {
        if (!isChildModalVisible) setVisible(nextVisible)
      }}
    >
      <div
        className={styles.thread}
        style={{ top: `${y}%`, left: `${x}%` }}
        onClick={(e) => e.stopPropagation()}
      >
        <Button onClick={() => setVisible(true)} type="primary" shape="circle" size="large">
          {thread.isResolved ? <CheckOutlined style={{ fontSize: 16 }} /> : thread.referenceNumber}
        </Button>
      </div>
    </Modal>
  )
}

export default Thread
