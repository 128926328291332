import Head from './components/Head'
import Grid from './components/Grid'

const Outlook = () => {
  return (
    <div className="container">
      <Head />
      <Grid />
    </div>
  )
}

export default Outlook
