import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Row, Col, Skeleton, Empty, Tooltip } from 'antd'
import { Flex } from 'reflexbox'
import { useTranslation } from 'react-i18next'

import { TActivityFeedQueryParams, TEvent } from 'context/TaxOrganizer'
import { useDashboard, useDashboardActions } from 'context/Dashboard'

import { Section, Button, User } from 'components'

import colors from 'assets/styles/variables.module.scss'
import useDate from 'hooks/useDate'
import styles from '../../dashboard.module.scss'

const defaultParams: TActivityFeedQueryParams = {
  page: 1,
  pageSize: 4,
}

const ActivityFeed = () => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'overview' })
  const { t: tEventType } = useTranslation('taxOrganizer', { keyPrefix: 'overview.eventTypes' })
  const { t: tCommon } = useTranslation('common')
  const { activityFeed } = useDashboard()
  const { loadActivityFeed, loading } = useDashboardActions()
  const { pathname } = useLocation()
  const { getDate } = useDate()

  const [params, setParams] = useState(defaultParams)
  const [feed, setFeed] = useState<TEvent[]>([])

  useEffect(() => {
    setFeed([])
    setParams({ ...defaultParams, page: 1 })
    loadActivityFeed({ ...params })
  }, [])

  useEffect(() => {
    if (activityFeed?.results === undefined) return

    if (params.page === 1) {
      setFeed(activityFeed?.results)
    } else {
      setFeed((currentFeed) => [...currentFeed, ...activityFeed?.results])
    }
  }, [activityFeed])

  const fetchNextPage = (nextPage: boolean = true) => {
    if (!params.page) return
    params.page = nextPage ? ++params.page : --params.page
    setParams({ ...params })
    loadActivityFeed({ ...params })
  }

  const showResults: boolean = true
  const firstLoading: boolean = params.page === 1 && loading
  const showPaginator = activityFeed !== null ? activityFeed?.next : false
  const showNoData = activityFeed !== null && activityFeed?.results?.length === 0 && !loading
  return (
    <Section className={styles.feed_section} title={t('activityFeed')} titleIndent={24}>
      {showNoData && (
        <Flex
          height={['200px']}
          width={['100%']}
          justifyContent={['center']}
          alignItems={['center']}
        >
          <Empty description={<>{tCommon('noData')}</>} />
        </Flex>
      )}
      <div className={styles.feed_content}>
        {showResults &&
          !firstLoading &&
          feed.map((event) => {
            return (
              <Link
                to={{
                  pathname: `/tax-organizer/${event.taxOrganizer}`,
                  state: {
                    taxPrevPath: pathname,
                  },
                }}
                key={event.guid}
              >
                <div className={styles.feed_item}>
                  <Row gutter={[10, 10]} align="middle">
                    <Col xs={6}>{getDate(event?.occurredOn, 'MMM dd')}</Col>
                    <Col xs={10} className="text-ellipsis">
                      <Tooltip title={tEventType(event.eventType)}>
                        {tEventType(event.eventType)}
                      </Tooltip>
                    </Col>
                    <Col xs={8}>
                      <div className={styles.feed_user}>
                        <User
                          username={
                            event.user ? `${event.user?.firstName} ${event.user?.lastName}` : ''
                          }
                          avatarLink={event.user?.avatar}
                          hideAvatar={!event.user}
                          bgColor={colors.tag5}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>{' '}
              </Link>
            )
          })}
      </div>
      {loading && (
        <Flex justifyContent={['flex-start']} alignItems={['center']} flexDirection={['column']}>
          <Skeleton
            active
            title={false}
            paragraph={{ rows: defaultParams.pageSize, width: '100%' }}
          />
        </Flex>
      )}
      {showPaginator && !firstLoading && (
        <Flex justifyContent={['center']} mt={[3]}>
          <Button
            onClick={() => fetchNextPage(true)}
            size="middle"
            disabled={loading || !activityFeed?.next}
            loading={loading}
          >
            {t('loadMore')}
          </Button>
        </Flex>
      )}
    </Section>
  )
}

export default ActivityFeed
