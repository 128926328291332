import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Logo } from 'assets/images/logo.svg'
import cn from 'classnames'
import { useMediaQuery } from 'react-responsive'
import { LogoutOutlined } from '@ant-design/icons'

import { useAuthActions } from 'context/Auth'

import { Button } from 'components'
import styles from './default.layout.module.scss'

type TProps = {
  showLogout?: boolean
}

const DefaultLayout: FC<TProps> = ({ showLogout, children }) => {
  const { t } = useTranslation('common')
  const isMobile = useMediaQuery({ query: '(max-width: 40em)' })
  const { logout } = useAuthActions()

  return (
    <div className={styles.container}>
      <div className={cn([styles.logo, 'position-relative'])}>
        <a href="http://www.repositax.com/">
          <Logo width={isMobile ? '120' : '198'} />
        </a>
        {showLogout && (
          <div title={t('user.dropdown.logout')} className={styles.logout}>
            {isMobile ? (
              <Button onClick={logout} type="link" icon={<LogoutOutlined />} size="small" />
            ) : (
              <Button onClick={logout} type="link" icon={<LogoutOutlined />}>
                {t('user.dropdown.logout')}
              </Button>
            )}
          </div>
        )}
      </div>
      {children}

      <div className={styles.footer}>
        <a href="https://www.repositax.com/terms-conditions/" target="_blank" rel="noreferrer">
          {t('form.terms')}
        </a>
        <a href="https://www.repositax.com/privacy-policy/" target="_blank" rel="noreferrer">
          {t('form.policy')}
        </a>
        <span className={styles.copyright}>&copy; Repositax, Inc.</span>
      </div>
    </div>
  )
}

export default DefaultLayout
