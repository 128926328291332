import { useMediaQuery } from 'react-responsive'

enum Breakpoints {
  xs = 575,
  sm = 576,
  md = 768,
  lg = 992,
  xl = 1200,
}

export default (breakpoint: keyof typeof Breakpoints) => {
  if (breakpoint === 'xs') {
    return useMediaQuery({ query: `(max-width: ${Breakpoints[breakpoint]}px)` })
  }
  return useMediaQuery({ query: `(min-width: ${Breakpoints[breakpoint]}px)` })
}
