import { TFinSoftware } from 'context/Financials'
import { Box } from 'reflexbox'

import SystemsListItem from '../SystemsListItem'

type TProps = {
  data: TFinSoftware[]
  setSpinner: (loading: boolean) => void
}

const SystemsList = ({ data, setSpinner }: TProps) => {
  return (
    <>
      <Box width={['100%', '100%', '100%', '50%']} mt={[3]} pr={[0, 0, 0, 4]}>
        {data.map((_: TFinSoftware) => {
          return (
            <SystemsListItem
              key={_.id}
              slug={_.slug}
              leftPart={_.leftPart}
              setSpinner={setSpinner}
            />
          )
        })}
      </Box>
    </>
  )
}

export default SystemsList
