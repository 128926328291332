import { useState, useEffect } from 'react'
import { Flex, Box } from 'reflexbox'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { message } from 'antd'

import { MESSAGE_DURATION } from 'constants/message'

import usePayment from 'hooks/usePayment'

import { Modal, Button, BillingModal } from 'components'
import InviteClient from './InviteClient'

import styles from '../../dashboard.clients.module.scss'
import useLinks from './hooks/useLinks'

type TProps = {
  setInviteClientPopoverStatus: (state: boolean) => void
}

const Header = ({ setInviteClientPopoverStatus }: TProps) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'clients.head' })
  const { t: t2 } = useTranslation('common')
  const isMobile = useMediaQuery({ query: '(max-width: 40em)' })
  const { slug, links } = useLinks()
  const [inviteClientVisible, setInviteClientVisible] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const { stripePromise, paymentStatus, setPaymentStatus } = usePayment()

  const handleVisibleChange = (visible: boolean) => {
    setInviteClientVisible(visible)
    setInviteClientPopoverStatus(visible)
  }

  useEffect(() => {
    if (paymentStatus === 'success') {
      setModalVisible(false)
      message.success(t2('billing.billingStatusSuccess'), MESSAGE_DURATION)
    }
  }, [paymentStatus])

  return (
    <>
      <Flex flexWrap="wrap" justifyContent="space-between">
        <Flex className={styles.nav} width={['100%', '70%', '60%']} flexWrap={['wrap']}>
          {links.map(({ label, key, ...rest }: any) => {
            return (
              <Box mr={[3]} key={key}>
                <NavLink {...rest}>{label}</NavLink>
              </Box>
            )
          })}
        </Flex>
        {slug !== 'uninvited' && (
          <Flex
            justifyContent={['flex-start', 'flex-end']}
            width={['100%', '100%', '120px']}
            my={[2, 2, 2, 0]}
          >
            <Box minWidth={['150px']} width={['100%', '100%', 'auto']}>
              {' '}
              <Modal
                placement={isMobile ? 'bottom' : 'leftBottom'}
                content={
                  <InviteClient
                    setVisiblePopover={(isVisiblePopover) => handleVisibleChange(isVisiblePopover)}
                    setModalVisible={setModalVisible}
                  />
                }
                trigger="click"
                destroyTooltipOnHide
                visible={inviteClientVisible}
                onVisibleChange={handleVisibleChange}
              >
                <Button type="primary" size="large" block>
                  {t('inviteClient')}
                </Button>
              </Modal>
            </Box>
          </Flex>
        )}
      </Flex>
      {modalVisible && (
        <BillingModal
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          stripePromise={stripePromise}
          setPaymentStatus={setPaymentStatus}
          alertMessage={t2('billing.inviteExceeded')}
        />
      )}
    </>
  )
}

export default Header
