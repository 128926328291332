import { isNull } from 'lodash'
import { browserSession } from 'utils/session'
import { CategoryOption } from 'context/TaxOrganizer/reducers/documents'

export const initForm = (formKey: TSessionFormKey) => {
  const initData = browserSession.getItem(formKey)
  return !isNull(initData) ? initData : undefined
}

export const saveFormToSession = (formKey: TSessionFormKey, allValues: any) => {
  browserSession.setItem(formKey, allValues)
}

export const getCategoryLabel = (
  slug: string,
  subCategories: CategoryOption[],
  categories: CategoryOption[]
) => {
  const subCategory = subCategories.find(({ value }) => value === slug)
  if (subCategory?.parent) {
    return subCategory.parent.title
  }
  const category = categories.find(({ value }) => value === slug)
  return category?.label
}

export const getCategoryValue = (
  slug: string,
  subCategories: CategoryOption[],
  categories: CategoryOption[]
) => {
  const subCategory = subCategories.find(({ value }) => value === slug)
  if (subCategory?.parent) {
    return subCategory.parent.slug
  }
  const category = categories.find(({ value }) => value === slug)
  return category?.value
}

export const getSubCategoryLabel = (slug: any, subCategories: CategoryOption[]) => {
  return subCategories.find(({ value }) => value === slug)?.label
}

export const getSubCategoryValue = (slug: any, subCategories: CategoryOption[]) => {
  return subCategories.find(({ value }) => value === slug)?.value
}

export const getTagLabels = (tagValues: string[], tags: TOption[]) => {
  if (Array.isArray(tagValues)) {
    return tagValues.reduce((acc: string[], tagValue: string) => {
      const currentTag = tags.find((tag: TOption) => tagValue === tag.value)
      if (currentTag) {
        acc.push(currentTag.label)
      }
      return acc
    }, [])
  }
  return []
}

export const getTagValues = (tags: TOption[]) => {
  if (Array.isArray(tags)) {
    return tags.map((tag: TOption) => tag.value)
  }
  return []
}

export const getFormFieldsError = (data: { [key: string]: string | string[] }) =>
  Object.entries(data).map(([key, value]) => {
    return {
      name: key,
      errors: typeof value === 'string' ? [value] : value,
    }
  })
