import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom'

import { AuthProvider } from 'context/Auth'
import { MarkerProvider } from 'context/Marker'
import HttpProvider from 'services/HttpProvider'
import { MessagesProvider } from 'context/Messages'
import { DashboardProvider } from 'context/Dashboard'
import { TaxOrganizerProvider } from 'context/TaxOrganizer'
import { NotificationsProvider } from 'context/Notifications'
import { EntityCPAsProvider } from 'context/EntityCPAs'

import DefaultLayout from 'layouts/DefaultLayout'
import TaxFirmLayout from 'layouts/TaxFirmLayout'

import Login from 'pages/Login'
import Register from 'pages/Register'
import EmailVerification from 'pages/EmailVerification'

import { AuthRoute, PrivateRoute, ScrollToTop } from 'components'

import {
  DASHBOARD_PATH,
  EMAIL_VERIFICATION_PATH,
  ONBOARDING_PATH,
  RESET_PASSWORD_CONFIRM_PATH,
  RESET_PASSWORD_PATH,
  TAX_ORGANIZER_PATH,
  LOGIN_PATH,
  REGISTER_PATH,
  FIRM_INVITATIONS_PATH,
  CONTACT_INFORMATION_PATH,
  TAX_FIRM_PROFILE_PATH,
} from 'utils/routes'

import ResetPasswordConfirm from 'pages/ResetPasswordConfirm'
import ResetPassword from 'pages/ResetPassword'
import SharedDocuments from 'pages/SharedDocuments'
import RequestDocuments from 'pages/RequestDocuments'
import FirmInvitations from 'pages/FirmInvitations'
import ContactInformation from 'pages/ContactInformation'
import FirmProfile from 'pages/FirmProfile'

import { LayoutEnum } from 'layouts/types'
import LayoutMiddleware from 'layouts/MIddleware'
import InvitationsRoute from 'components/InvitationsRoute'

function App() {
  return (
    <div className="App">
      <MarkerProvider>
        <AuthProvider>
          <HttpProvider>
            <MessagesProvider>
              <NotificationsProvider>
                <DashboardProvider>
                  <BrowserRouter>
                    <ScrollToTop />
                    <Switch>
                      <Redirect exact from="/" to={DASHBOARD_PATH} />

                      <AuthRoute path={LOGIN_PATH}>
                        <DefaultLayout>
                          <Login />
                        </DefaultLayout>
                      </AuthRoute>

                      <AuthRoute path={REGISTER_PATH}>
                        <DefaultLayout>
                          <Register />
                        </DefaultLayout>
                      </AuthRoute>

                      <AuthRoute path={TAX_FIRM_PROFILE_PATH}>
                        <DefaultLayout>
                          <FirmProfile />
                        </DefaultLayout>
                      </AuthRoute>

                      <Route path="/shared-documents">
                        <DefaultLayout>
                          <SharedDocuments />
                        </DefaultLayout>
                      </Route>

                      <Route path="/request-documents">
                        <DefaultLayout>
                          <RequestDocuments />
                        </DefaultLayout>
                      </Route>

                      <Route path={`${FIRM_INVITATIONS_PATH}`}>
                        <DefaultLayout>
                          <FirmInvitations />
                        </DefaultLayout>
                      </Route>

                      <Route path={EMAIL_VERIFICATION_PATH}>
                        <DefaultLayout>
                          <EmailVerification />
                        </DefaultLayout>
                      </Route>

                      <Route path={RESET_PASSWORD_CONFIRM_PATH}>
                        <DefaultLayout>
                          <ResetPasswordConfirm />
                        </DefaultLayout>
                      </Route>

                      <Route path={RESET_PASSWORD_PATH}>
                        <DefaultLayout>
                          <ResetPassword />
                        </DefaultLayout>
                      </Route>

                      <Route path={CONTACT_INFORMATION_PATH}>
                        <EntityCPAsProvider>
                          <TaxFirmLayout>
                            <ContactInformation />
                          </TaxFirmLayout>
                        </EntityCPAsProvider>
                      </Route>

                      <InvitationsRoute path={DASHBOARD_PATH}>
                        <PrivateRoute>
                          <LayoutMiddleware type={LayoutEnum.DASHBOARD} />
                        </PrivateRoute>
                      </InvitationsRoute>

                      <PrivateRoute path={ONBOARDING_PATH}>
                        <LayoutMiddleware type={LayoutEnum.ONBOARDING} />
                      </PrivateRoute>

                      <PrivateRoute
                        path={TAX_ORGANIZER_PATH}
                        render={(props: any) => (
                          <TaxOrganizerProvider key={props.match.params.taxOrganizerGuid}>
                            <LayoutMiddleware type={LayoutEnum.TAX_ORGANIZER} />
                          </TaxOrganizerProvider>
                        )}
                      />

                      <Route path="*">
                        <Redirect to="/" />
                      </Route>
                    </Switch>
                  </BrowserRouter>
                </DashboardProvider>
              </NotificationsProvider>
            </MessagesProvider>
          </HttpProvider>
        </AuthProvider>
      </MarkerProvider>
      <canvas id="canvas-renderer" />
    </div>
  )
}

export default App
