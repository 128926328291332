import { useEffect } from 'react'
import { useDashboard, useDashboardActions } from 'context/Dashboard'
import { useAuth } from 'context/Auth'

export default () => {
  const {
    CPA: { cpaFirm },
  } = useAuth()
  const { deadline } = useDashboard()
  const { chartData } = useDashboard()
  const { loadBurnDownChartData, loading } = useDashboardActions()

  useEffect(() => {
    if (cpaFirm!.guid && deadline.selected?.guid) {
      loadBurnDownChartData(cpaFirm!.guid, deadline.selected?.guid)
    }
  }, [deadline])

  return { chartData, loading }
}
