import { Form } from 'antd'
import { useState } from 'react'
import Icon from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useDashboard } from 'context/Dashboard'
import { useDocumentsActions } from 'context/TaxOrganizer'
import { useDocument } from 'context/Document'

import SelectField, { tagRender } from 'components/Select'
import { Modal } from 'components'

import { ReactComponent as Search } from 'assets/images/search.svg'

import styles from '../../dashboard.document.module.scss'

type TProps = {
  disabled: boolean
}

const Tags = ({ disabled }: TProps) => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'document' })
  const { t: t2 } = useTranslation('taxOrganizer', { keyPrefix: 'locked' })
  const { taxOrganizerGuid, documentGuid }: { taxOrganizerGuid: string; documentGuid: string } =
    useParams()
  const { tags } = useDashboard()
  const { updateDocument, response } = useDocumentsActions()
  const { document } = useDocument()

  const [form] = Form.useForm()
  const initialTags = document.tags?.map(({ value }) => value)
  const [documentTags, setDocumentTags] = useState<string[]>(initialTags)

  const handleValuesChange = async ({ tags: newTags }: any) => {
    await updateDocument(taxOrganizerGuid, documentGuid, {
      tags: newTags,
    })
    if (response.ok) {
      setDocumentTags(newTags)
      form.setFieldsValue({ tags: newTags })
    } else {
      form.setFieldsValue({ tags: documentTags })
    }
  }

  const childContent = (
    <div className={styles.right_section}>
      <div className={styles.right_section_top}>
        <span className={styles.right_section_title}>{t('tags')}</span>
      </div>
      <Form form={form} initialValues={{ tags: initialTags }} onValuesChange={handleValuesChange}>
        <Form.Item label={t('tags')} name="tags">
          <SelectField
            optionFilterProp="children"
            mode="multiple"
            options={tags}
            style={{ width: '100%' }}
            tagRender={tagRender}
            showSearch
            showArrow
            suffixIcon={<Icon component={Search} style={{ fontSize: '14px', marginTop: -1 }} />}
            disabled={disabled}
          />
        </Form.Item>
      </Form>
    </div>
  )

  return disabled ? <Modal content={t2('document.modified')}>{childContent}</Modal> : childContent
}

export default Tags
