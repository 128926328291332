import { Steps } from 'antd'
import { useAuth } from 'context/Auth'
import useMediaQuery from 'hooks/useMediaQuery'

import { STEPS_LIST } from 'pages/Onboarding/constatnts'

const { Step } = Steps

const StepsComponent = () => {
  const {
    onboardingFlow: { step },
  } = useAuth()
  const minWidthSm = useMediaQuery('sm')
  return (
    <div
      className="container-md"
      style={{ padding: minWidthSm ? '32px 0 32px 0' : '16px 0 32px 0' }}
    >
      {typeof step !== 'undefined' && (
        <Steps current={step} responsive={false} size={minWidthSm ? 'default' : 'small'}>
          {STEPS_LIST.map((item) => (
            <Step key={item.id} />
          ))}
        </Steps>
      )}
    </div>
  )
}

export default StepsComponent
