import { useEffect, useState, SyntheticEvent } from 'react'
import { Flex } from 'reflexbox'

import { SpinnerBackdrop } from 'components'

import { useTaxReturns, useTaxReturnsActions } from 'context/TaxReturns'

type TProps = {
  closeModal: () => void
}

const GenerateEfileDoc = ({ closeModal }: TProps) => {
  const { eFileEditUrl, generateEFileAuthorizationLetter, eFileAuthPreCheck, loading } =
    useTaxReturnsActions()
  const {
    state: {
      taxReturns: { latest: latestTaxReturn },
    },
  } = useTaxReturns()
  const [editUrl, setEditUrl] = useState<string>('')

  const getEditUrl = async () => {
    try {
      if (latestTaxReturn) {
        const editUrlRespnse = await eFileEditUrl(latestTaxReturn.guid)
        setEditUrl(editUrlRespnse.link)
      }
    } catch (e) {
      console.log(e)
      closeModal()
    }
  }

  const checkReady = async () => {
    if (!latestTaxReturn) return

    const isReady = await eFileAuthPreCheck(latestTaxReturn.guid)
    if (isReady) {
      await generateEFileAuthorizationLetter(latestTaxReturn.guid)
      getEditUrl()
    }
  }

  useEffect(() => {
    if (!latestTaxReturn) return

    checkReady()
  }, [])

  return (
    <Flex
      px={[2]}
      py={[3]}
      flexDirection={['column']}
      maxWidth={['100%', '100%', '100%']}
      onClick={(event: SyntheticEvent) => {
        event.stopPropagation()
      }}
    >
      {loading && <SpinnerBackdrop />}
      <iframe frameBorder={0} title="e-sign" src={editUrl} height={800} />
    </Flex>
  )
}

export default GenerateEfileDoc
