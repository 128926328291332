import { Col, Row } from 'antd'

import styles from './dashboard.footer.module.scss'

const Footer = () => {
  return (
    <div className={styles.box} style={{ zIndex: 0 }}>
      <Row gutter={[20, 20]}>
        <Col flex="auto" />
        <Col>© REPOSITAX</Col>
      </Row>
    </div>
  )
}

export default Footer
