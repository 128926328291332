import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/page-navigation/lib/styles/index.css'
import { ScrollMode, SpecialZoomLevel, Viewer, ViewMode, Worker } from '@react-pdf-viewer/core'
import { PageNavigationPlugin } from '@react-pdf-viewer/page-navigation'
import { PrintPlugin } from '@react-pdf-viewer/print'
import { useTranslation } from 'react-i18next'
import { SizeMe } from 'react-sizeme'

import { Alert, SpinnerBackdrop } from 'components'

type TProps = {
  fileUrl: string
  printPluginInstance: PrintPlugin
  setPDFLoaded: (state: boolean) => void
  pageNavigationPluginInstance: PageNavigationPlugin
  setPDFError: (error: boolean) => void
  setScale: (scale: number | undefined) => void
  documentWrapperHeight: number | undefined
}

const PDFdocument = ({
  fileUrl,
  printPluginInstance,
  setPDFLoaded,
  pageNavigationPluginInstance,
  setPDFError,
  setScale,
  documentWrapperHeight,
}: TProps) => {
  const { t } = useTranslation('taxOrganizer')
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
      <SizeMe>
        {({ size }) => (
          <div
            style={{
              height:
                document.querySelector('.rpv-core__canvas-layer')?.clientHeight ??
                documentWrapperHeight,
              width: String(size.width),
              overflow: 'hidden',
            }}
          >
            <Viewer
              defaultScale={SpecialZoomLevel.PageWidth}
              scrollMode={ScrollMode.Page}
              viewMode={ViewMode.SinglePage}
              fileUrl={fileUrl}
              renderLoader={() => <SpinnerBackdrop />}
              onDocumentLoad={() => {
                setPDFLoaded(true)
              }}
              renderError={(error) => {
                console.log('error: ', error)
                setPDFLoaded(true)
                setPDFError(true)
                return <Alert message={t('document.errors.loading')} type="error" className="m-3" />
              }}
              plugins={[pageNavigationPluginInstance, printPluginInstance]}
              onZoom={(e) => {
                setScale(e.scale)
              }}
            />
          </div>
        )}
      </SizeMe>
    </Worker>
  )
}

export default PDFdocument
