export enum ActionTypes {
  SET_LOADING = 'SET_LOADING',
  LOAD_STEPS = 'LOAD_STEPS',
  SET_STEP = 'SET_STEP',
  SET_TAX_RETURN_STATUS = 'SET_TAX_RETURN_STATUS',
  LOAD_TAX_RETURNS = 'LOAD_TAX_RETURNS',
  LOAD_LATEST_TAX_RETURN = 'LOAD_LATEST_TAX_RETURN',
  LOAD_FILING_STATUS = 'LOAD_FILING_STATUS',
  LOAD_ENTITY_SIGNERS = 'LOAD_ENTITY_SIGNERS',
  LOAD_TAX_RETURN_DOCUMENTS = 'LOAD_TAX_RETURN_DOCUMENTS',
  LOAD_TAX_RETURN_DOCUMENT = 'LOAD_TAX_RETURN_DOCUMENT',
  DELETE_TAX_RETURN_DOCUMENT = 'DELETE_TAX_RETURN_DOCUMENT',
  LOAD_TAX_RETURN_PAGES = 'LOAD_TAX_RETURN_PAGES',
  FILTER_TAX_RETURN_PAGES = 'FILTER_TAX_RETURN_PAGES',
  LOAD_TAX_RETURN_CATEGORIES = 'LOAD_TAX_RETURN_CATEGORIES',
  LOAD_TAX_RETURN_THREADS = 'LOAD_TAX_RETURN_THREADS',
  SET_SELECTED_TAGS = 'SET_SELECTED_TAGS',
  UPDATE_TAX_RETURN_PAGES = 'UPDATE_TAX_RETURN_PAGES',
  SET_DRAG_DROP_DOCUMENT_IDS = 'SET_DRAG_DROP_DOCUMENT_IDS',
  SET_EFILE_AUTH_DOC = 'SET_EFILE_AUTH_DOC',
  SET_AUTHORIZED_SIGNER = 'SET_AUTHORIZED_SIGNER',
  LOAD_AUTH_SIGN_DETAILS = 'LOAD_AUTH_SIGN_DETAILS',
  SET_PRESS_SHIFT = 'SET_PRESS_SHIFT',
  LOAD_ENTITY_INVITES = 'LOAD_ENTITY_INVITES',
}
