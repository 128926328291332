import cn from 'classnames'
import { useState } from 'react'
import { TDocumentPageIdentifier, TTaxReturn, useTaxReturns } from 'context/TaxReturns'
import { useTranslation } from 'react-i18next'
import styles from '../../../dashboard.tax_return.module.scss'
import CommentList from './CommentList'

type TProps = {
  taxReturn: TTaxReturn
  setSelectedPage: (t: TDocumentPageIdentifier) => void
  setCurrentThread: (referenceNumber: string) => void
  currentThread: string | undefined
}

const CommentNavigator = ({
  taxReturn,
  setSelectedPage,
  setCurrentThread,
  currentThread,
}: TProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const { t: t2 } = useTranslation('common')
  const {
    state: { threads },
  } = useTaxReturns()

  const onBack = () => {
    if (!currentThread || Number(currentThread) === 1) return
    setCurrentThread(String(Number(currentThread) - 1))
  }

  const onNext = () => {
    if (!currentThread || Number(currentThread) === threads.count - 1) return
    setCurrentThread(String(Number(currentThread) + 1))
  }

  return (
    <div className={styles.document_comments}>
      {isExpanded && (
        <CommentList
          setCurrentThread={setCurrentThread}
          setSelectedPage={setSelectedPage}
          taxReturn={taxReturn}
        />
      )}
      <div className={styles.document_comments_control}>
        <div onClick={() => onBack()} className={styles.section}>
          {t2('buttons.prev')}
        </div>
        <div
          onClick={() => setIsExpanded(!isExpanded)}
          className={cn(styles.section, styles.middle)}
        >
          ...
        </div>
        <div className={styles.section} onClick={() => onNext()}>
          {t2('buttons.next')}
        </div>
      </div>
    </div>
  )
}

export default CommentNavigator
