import { useMemo, useState, useEffect } from 'react'
import { Box, Flex } from 'reflexbox'
import { useTranslation } from 'react-i18next'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe, Stripe } from '@stripe/stripe-js'
import { useBilling, useBillingActions } from 'context/Billing'

import CardForm from '../CardForm'
import styles from '../../dashboard.firm.billing.module.scss'

const Payment = () => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'firm.billing' })
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null)

  const {
    state: { stripePublishableKey, paymentMethod },
  } = useBilling()

  const { loadConfig } = useBillingActions()

  useEffect(() => {
    loadConfig()
  }, [])

  useEffect(() => {
    if (stripePublishableKey) {
      setStripePromise(loadStripe(stripePublishableKey))
    }
  }, [stripePublishableKey])

  const expDate = useMemo(() => {
    if (!paymentMethod) return ''

    const { expMonth, expYear } = paymentMethod

    const paddedMonth = `0${expMonth}`.slice(-2)
    return `${paddedMonth}/${expYear}`
  }, [paymentMethod])
  return (
    <Flex
      justifyContent={['space-between']}
      alignItems={['start']}
      flexDirection={['column', 'column', 'column', 'row']}
      pt={['26px']}
      pb={['30px']}
      className={styles.block}
    >
      <Box lineHeight={['24px']} fontSize={['20px']} fontWeight={[700]} flexShrink="0">
        {t('payment')}
      </Box>
      {paymentMethod ? (
        <Box lineHeight={['24px']} fontSize={['16px']} fontWeight={[500]}>
          {paymentMethod?.brand?.toUpperCase()}: **{paymentMethod?.last4} - {expDate}
        </Box>
      ) : (
        <Box lineHeight={['24px']} fontWeight={[700]} width={['100%']} ml={[0, 0, 0, 4]}>
          <Elements stripe={stripePromise}>
            <CardForm />
          </Elements>
        </Box>
      )}
    </Flex>
  )
}

export default Payment
