import { SyntheticEvent, useState } from 'react'
import { Flex } from 'reflexbox'
import { Form, message, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { getFirstError } from 'utils'

import { Button, Textarea } from 'components'

import { ErrorResponse, useEntity } from 'context/Entity'
import { SharedInviteesRequest, useDocumentsActions } from 'context/TaxOrganizer'

import styles from 'pages/TaxOrganizer/Documents/tax.documents.module.scss'

import Invitees from './Invitees'

const { Title, Paragraph } = Typography

type Props = {
  documentGuids: string[]
  hideShareModal: () => void
}
const ShareModalContent = ({ documentGuids, hideShareModal }: Props) => {
  const { t } = useTranslation(['taxOrganizer'])
  const { t: t2 } = useTranslation('common')
  const [form] = Form.useForm()
  const { setSelectedTableDocumentIds } = useDocumentsActions()
  const { createDocumentShareInvites, loading } = useDocumentsActions()
  const {
    entity: {
      currentEntity: { guid },
    },
  } = useEntity()
  const [emails, setEmails] = useState<string[]>([])
  const [errorInvitees, setErrorInvitees] = useState<boolean>(false)
  const [errorMap, setErrorMap] = useState<ErrorResponse>({})

  const onSubmitForm = async (formValue: { message: string }) => {
    if (!emails.length) {
      setErrorInvitees(true)

      return
    }

    const body: SharedInviteesRequest = {
      emails,
      message: formValue.message,
      documentGuids,
    }

    const response = await createDocumentShareInvites(guid, body)

    if (response) {
      setErrorMap(response)
    } else {
      message.success(t2('messages.success.documentsShared'))
      setSelectedTableDocumentIds([])
      hideShareModal()
    }
  }

  return (
    <Form form={form} onFinish={onSubmitForm}>
      <Flex
        px={[2]}
        py={[3]}
        flexDirection={['column']}
        maxWidth={['100%', '100%', '550px']}
        onClick={(event: SyntheticEvent) => {
          event.stopPropagation()
        }}
      >
        <Title level={4}>{t('shareModal.title')}</Title>

        <Paragraph style={{ fontSize: '16px' }}>{t('shareModal.description')}</Paragraph>

        <Paragraph className={styles.form_label}>{t('shareModal.invitees')}</Paragraph>

        <Form.Item
          extra={
            errorMap?.emails ? (
              <Paragraph className={styles.form_error}>{getFirstError(errorMap.emails)}</Paragraph>
            ) : (
              errorInvitees &&
              !emails.length && (
                <Paragraph className={styles.form_error}>
                  {t('shareModal.errorTextInvitees')}
                </Paragraph>
              )
            )
          }
        >
          <Invitees emails={emails} setEmails={setEmails} errorInvitees={errorInvitees} />
        </Form.Item>

        <Form.Item
          initialValue={null}
          name="message"
          key="message"
          rules={[{ required: true, message: t('shareModal.errorTextMessage') }]}
          extra={
            errorMap?.message && (
              <Paragraph className={styles.form_error}>{getFirstError(errorMap.message)}</Paragraph>
            )
          }
          validateStatus={errorMap?.message && 'error'}
        >
          <Textarea autoSize={{ minRows: 3 }} placeholder={t('shareModal.placeholderMessage')} />
        </Form.Item>

        <Button
          className={styles.form_submit}
          type="primary"
          size="large"
          loading={loading}
          htmlType="submit"
          block
          disabled={loading}
        >
          {t('shareModal.modalButton')}
        </Button>
      </Flex>
    </Form>
  )
}

export default ShareModalContent
