import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from 'components'

import { useAuthActions } from 'context/Auth'
import { useInvite, useInviteActions } from 'context/Invites'
import { PAGE_SIZE } from 'constants/table'

import useColumns from './hooks/useColumns'
import Title from '../components/Title'

const DEFAULT_PARAMS: Readonly<TParams> = {
  page: 1,
  pageSize: PAGE_SIZE,
}

const Invitations = () => {
  const { t } = useTranslation('common', { keyPrefix: 'invitations' })
  const [params, setParams] = useState<TLoadEntitiesParams>(DEFAULT_PARAMS)
  const {
    cpaUserInvites: { data: dataSource, total },
  } = useInvite()
  const { columns } = useColumns()
  const { loadCPAUserInvitations, loading } = useInviteActions()
  const [currentPage, setCurrentPage] = useState<number | undefined>(DEFAULT_PARAMS.page)
  const { setOnboardingFlowStatus } = useAuthActions()

  const handlePageChange = (page: number) => {
    setParams((prevParams: TLoadEntitiesParams) => {
      setCurrentPage(page)
      return { ...prevParams, page }
    })
  }

  useEffect(() => {
    loadCPAUserInvitations(params)
  }, [params])

  useEffect(() => {
    if (total === 0) {
      setOnboardingFlowStatus()
    }
  }, [total])

  return (
    <>
      <Title title={t('title')} />
      <Table
        table={{
          dataSource,
          columns,
          rowKey: 'guid',
          loading,
        }}
        pagination={{
          total,
          onChange: handlePageChange,
          current: currentPage,
        }}
      />
    </>
  )
}

export default Invitations
