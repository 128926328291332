import { Section } from 'components'
import Router from './components/Router'
import Header from './components/Header'

const Clients = () => {
  return (
    <div className="container">
      <Section>
        <Header />
        <Router />
      </Section>
    </div>
  )
}

export default Clients
