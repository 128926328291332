import { TTaxDocument } from 'context/TaxOrganizer'
import { DocumentTag } from 'context/Dashboard'

import { ActionTypes } from './actions'
import { DocumentThread, NewThread } from '.'

export type IDocumentState = {
  document: TTaxDocument
  threads: DocumentThread[]
  isCommentMode: boolean
  newThread: NewThread
}

export type IAction = { type: keyof typeof ActionTypes; payload?: any }

export const initialState: IDocumentState = {
  document: undefined!,
  threads: [],
  isCommentMode: false,
  newThread: undefined!,
}

export const documentReducer = (state: IDocumentState = initialState, action: IAction) => {
  switch (action.type) {
    case ActionTypes.LOAD_DOCUMENT:
      return {
        ...state,
        document: {
          ...action.payload,
          tags: action.payload.tags?.map((tag: DocumentTag): TOption => {
            return {
              label: tag.name,
              value: tag.slug,
            }
          }),
        },
      }

    case ActionTypes.LOAD_THREADS:
      return {
        ...state,
        threads: action.payload.reverse(),
      }

    case ActionTypes.CREATE_THREAD:
      return {
        ...state,
        threads: state.threads.concat(action.payload),
      }

    case ActionTypes.UPDATE_THREAD:
      return {
        ...state,
        threads: state.threads.map((thread) =>
          thread.guid === action.payload.guid ? action.payload : thread
        ),
      }

    case ActionTypes.SET_COMMENT_MODE:
      return {
        ...state,
        isCommentMode: action.payload,
      }

    case ActionTypes.SET_NEW_THREAD:
      return {
        ...state,
        newThread: action.payload,
      }

    default:
      return state
  }
}
