import { useMemo } from 'react'
import colors from 'assets/styles/variables.module.scss'

export default ({ local }: { [key: string]: { [key: string]: string } }) => {
  const options = useMemo(
    () => ({
      card: {
        style: {
          base: {
            fontSize: '14px',
            '::placeholder': {
              color: colors.grey80,
            },
          },
        },
        placeholder: local.placeholder,
        showIcon: true,
        classes: {
          base: 'onboarding-billing-card',
        },
      },
      cvc: {
        classes: {
          base: 'onboarding-billing-cvc',
        },
      },
      expire: {
        classes: {
          base: 'onboarding-billing-expire',
        },
      },
    }),
    []
  )
  return options
}
