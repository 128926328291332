import { useState } from 'react'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { Checkbox, Form, message, Select, Tooltip, Typography } from 'antd'
import { getFirstError } from 'utils'

import { Button, TextField } from 'components'

import { useEntityActions, TEntity, useEntity } from 'context/Entity'

import useUserEntityPermissions from 'hooks/useUserEntityPermissions'

import styles from 'pages/Dashboard/EntityManagement/dashboard.entity.management.module.scss'
import {
  EntityUserInviteForm,
  EnumEntityUserRoles,
  ErrorResponse,
  getEntityUserRolesLocal,
} from 'context/Entity/types'
import { DEFAULT_PARAMS } from '../../../Users/constants'

const { Title, Paragraph } = Typography

type Props = {
  entity: TEntity
  widgetStyle?: boolean
}

const InviteEntityUserForm = ({ entity, widgetStyle = false }: Props) => {
  const [form] = Form.useForm()
  const { userEntityRole } = useUserEntityPermissions()
  const {
    entity: { currentUserRole },
  } = useEntity()
  const { inviteEntityUser, response: responseInvite, loading: loadingInvite } = useEntityActions()
  const { loadEntityUserInvites } = useEntityActions()
  const [errorMap, setErrorMap] = useState<ErrorResponse>({})
  const [showTransferOwnership, setShowTransferOwnership] = useState(false)
  const { t } = useTranslation('entity')
  const { t: t2 } = useTranslation('common')
  const permissionsFilter = (options: TOption[]) => {
    if (userEntityRole === EnumEntityUserRoles.ADMIN) {
      return options.filter((option) => option.value !== EnumEntityUserRoles.OWNER)
    }
    return options
  }
  const entityKindOptions = getEntityUserRolesLocal(t)

  const onSubmit = async (params: EntityUserInviteForm) => {
    await inviteEntityUser(entity.guid, params)
    if (!responseInvite.ok) {
      setErrorMap(responseInvite.data)
    } else {
      message.success(t2('messages.success.invitationsSent'))
      loadEntityUserInvites(entity.guid, DEFAULT_PARAMS)
      form.resetFields()
      setShowTransferOwnership(false)
    }
  }

  const handleChange = (role: EnumEntityUserRoles) => {
    if (role !== EnumEntityUserRoles.OWNER) {
      form.setFieldsValue({ transferOwnership: undefined })
    }
    setShowTransferOwnership(
      role === EnumEntityUserRoles.OWNER && currentUserRole === EnumEntityUserRoles.OWNER
    )
  }

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      className={cn(styles.users_invite_wrapper, widgetStyle ? styles.users_widget : '')}
      layout="vertical"
    >
      <Title className={styles.users_form_title} level={5}>
        {t('users.inviteUser')}
      </Title>
      <div className={styles.users_form_fields}>
        <Form.Item
          rules={[
            { required: true, message: `${t('common.email')} is required` },
            { type: 'email', message: `${t('common.email')} is not valid` },
          ]}
          className={styles.users_email}
          initialValue={null}
          name="email"
          key="email"
          extra={
            errorMap?.email && (
              <Paragraph className={styles.form_error}>{getFirstError(errorMap.email)}</Paragraph>
            )
          }
          validateStatus={errorMap?.email && 'error'}
        >
          <TextField placeholder={t('common.email')} size="middle" />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: `${t('common.role')} is required` }]}
          className={styles.users_role}
          initialValue={null}
          name="role"
          key="role"
          extra={
            errorMap?.role && (
              <Paragraph className={styles.form_error}>{getFirstError(errorMap.role)}</Paragraph>
            )
          }
          validateStatus={errorMap?.role && 'error'}
        >
          <Select
            placeholder={t('common.role')}
            options={permissionsFilter(entityKindOptions)}
            onChange={handleChange}
          />
        </Form.Item>
      </div>
      {showTransferOwnership && (
        <Tooltip title={t('invitations.tooltipEntity')}>
          <Form.Item name="transferOwnership" key="transferOwnership" valuePropName="checked">
            <Checkbox>{t('invitations.checkbox')}</Checkbox>
          </Form.Item>
        </Tooltip>
      )}

      <div className={styles.users_submit_row}>
        <Button
          className={styles.users_submit}
          type="primary"
          size="large"
          htmlType="submit"
          loading={loadingInvite}
          disabled={loadingInvite}
        >
          {t2('buttons.invite')}
        </Button>
      </div>
    </Form>
  )
}

export default InviteEntityUserForm
