import { useState } from 'react'
import {
  PrinterOutlined,
  ArrowRightOutlined,
  LeftOutlined,
  RightOutlined,
  DownloadOutlined,
} from '@ant-design/icons'
import { Col, Row, InputNumber } from 'antd'
import { Button, Modal } from 'components'
import { PrintPlugin, RenderPrintProps } from '@react-pdf-viewer/print'
import { useTranslation } from 'react-i18next'
import { inRange } from 'lodash'
import printJS from 'print-js'

import styles from '../../documentview.module.scss'

const START_PAGE = 1

type TProps = {
  printPluginInstance: PrintPlugin
  pdfError: boolean
  documentLoaded: boolean
  activePage: number
  setActivePage: (pageIndex: number) => void
  total: number | null | undefined
  downloadLink?: string
  fileUrl: string | null | undefined
  fileIsImage: boolean
}

const Header = ({
  printPluginInstance,
  pdfError,
  documentLoaded,
  activePage,
  setActivePage,
  total,
  downloadLink,
  fileUrl,
  fileIsImage,
}: TProps) => {
  const { t } = useTranslation('taxOrganizer')
  const { t: t2 } = useTranslation('common')
  const { Print } = printPluginInstance
  const [modalVisible, setModalVisible] = useState(false)
  const [value, setValue] = useState<null | number>(null)
  const [loadingPrint, setLoadingPrint] = useState(false)
  const pageCount = total ?? 0

  const handleBack = () => {
    if (activePage >= START_PAGE) setActivePage(activePage - 1)
  }

  const handleNext = () => {
    if (activePage <= pageCount) setActivePage(activePage + 1)
  }

  const handlePress = () => {
    if (!value || !inRange(Number(value), START_PAGE, pageCount + 1)) {
      return
    }
    setActivePage(Number(value))
    setModalVisible(false)
  }

  const handleStep = (n: number) => {
    if (!n || !inRange(Number(n), START_PAGE, pageCount + 1)) {
      return
    }
    setValue(n)
    setActivePage(Number(n))
  }

  const handlePrintImage = async () => {
    setLoadingPrint(true)
    printJS({
      printable: fileUrl,
      type: 'image',
      onLoadingEnd: () => {
        setLoadingPrint(false)
      },
      onPrintDialogClose: () => {
        setLoadingPrint(false)
      },
      onError: (error) => {
        setLoadingPrint(false)
        console.error(error)
      },
    })
  }

  if (pdfError || !documentLoaded) return null

  return (
    <Row style={{ width: '100%' }}>
      <Col xs={{ offset: 8, span: 8 }} className={styles.pagination_wrap}>
        {pageCount !== 0 && activePage !== 0 && (
          <div className={styles.pagination_menu}>
            <Button
              className={styles.pagination_arrow}
              onClick={handleBack}
              disabled={activePage === START_PAGE}
              icon={<LeftOutlined />}
              type="text"
            />
            <Modal
              showCloseIcon={false}
              content={
                <div className={styles.number_input}>
                  <InputNumber
                    min={START_PAGE}
                    max={pageCount}
                    value={activePage}
                    addonBefore={t('document.pagination.gotToPage')}
                    addonAfter={<ArrowRightOutlined className="p-2" onClick={handlePress} />}
                    onPressEnter={handlePress}
                    onChange={setValue}
                    onStep={handleStep}
                  />
                </div>
              }
              visible={modalVisible}
              onVisibleChange={(visible) => setModalVisible(visible)}
            />

            <Button type="text" onClick={() => setModalVisible(true)}>
              <span className="text-bold me-1">{activePage}</span>/ {total}
            </Button>

            <Button
              className={styles.pagination_arrow}
              onClick={handleNext}
              disabled={activePage === total}
              icon={<RightOutlined />}
              type="text"
            />
          </div>
        )}
      </Col>
      <Col xs={8} className={styles.actions}>
        {downloadLink && (
          <div className={styles.download}>
            <a href={downloadLink} download="Repositax" target="_blank" rel="noreferrer">
              <DownloadOutlined />
            </a>
          </div>
        )}
        <div className={styles.print}>
          {fileIsImage ? (
            <Button
              icon={<PrinterOutlined />}
              onClick={handlePrintImage}
              title={t2('buttons.print')}
              loading={loadingPrint}
              disabled={loadingPrint}
            />
          ) : (
            <Print>
              {(props: RenderPrintProps) => (
                <Button
                  icon={<PrinterOutlined />}
                  onClick={props.onClick}
                  title={t2('buttons.print')}
                />
              )}
            </Print>
          )}
        </div>
      </Col>
    </Row>
  )
}

export default Header
