import { useState } from 'react'
import { Button, Form, message, Select, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { getTaxFirmUserRoles } from 'utils/taxFirm'
import { useCPAFirm, useCPAFirmActions } from 'context/CPAFirm'
import { getFirstError } from 'utils'

import { ErrorResponse } from 'context/Entity'
import { useAuth } from 'context/Auth'

import { TCPAEntityContact } from 'context/TaxOrganizer'

import styles from '../../../Firm/Team/dashboard.firm.module.scss'

const { Paragraph } = Typography

type TProps = {
  taxPreparer: TCPAEntityContact
  onSubmit: () => void
}

const ChangeRoleForm = ({ taxPreparer, onSubmit }: TProps) => {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const { editFirmUser, response } = useCPAFirmActions()
  const {
    CPA: { cpaFirm },
  } = useAuth()
  const { cpaUserRoles } = useCPAFirm()
  const [errorMap, setErrorMap] = useState<ErrorResponse>({})
  const { t } = useTranslation('common')
  const roleOptions = getTaxFirmUserRoles(cpaUserRoles, t)

  const handleRoleChange = async ({ role }: { role: string }) => {
    setLoading(true)
    await editFirmUser(cpaFirm!.guid, taxPreparer.user.guid, { role })
    if (response.ok) {
      onSubmit()
      message.success(t('messages.success.roleChanged'))
    } else {
      setErrorMap({ role: [response.data.role] })
    }
    setLoading(false)
  }

  return (
    <div className={styles.role_form}>
      <Form onFinish={handleRoleChange} form={form}>
        <div className={styles.form_fields}>
          <Paragraph className={styles.form_label}>{t('form.role')}</Paragraph>
          <Form.Item
            rules={[{ required: true, message: `${t('form.role')} is required` }]}
            className={styles.users_role}
            initialValue={taxPreparer.role}
            name="role"
            key="role"
            extra={
              errorMap?.role && (
                <Paragraph className={styles.form_error}>{getFirstError(errorMap.role)}</Paragraph>
              )
            }
            validateStatus={errorMap?.role && 'error'}
          >
            <Select placeholder={t('form.role')} options={roleOptions} />
          </Form.Item>
          <Button
            htmlType="submit"
            className={styles.form_submit}
            type="primary"
            loading={loading}
            disabled={loading}
          >
            {t('buttons.confirm')}
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default ChangeRoleForm
