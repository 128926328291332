import { useEffect } from 'react'
import { Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useDashboard } from 'context/Dashboard'
import { useDocumentsActions } from 'context/TaxOrganizer'
import { useDocument } from 'context/Document'

import { Modal, Select } from 'components'

import styles from '../../dashboard.document.module.scss'

type TProps = {
  disabled: boolean
}

const Category = ({ disabled }: TProps) => {
  const { taxOrganizerGuid, documentGuid }: { taxOrganizerGuid: string; documentGuid: string } =
    useParams()
  const { categories, subCategories } = useDashboard()
  const { updateDocument, response } = useDocumentsActions()
  const { document } = useDocument()

  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'document' })
  const { t: t2 } = useTranslation('taxOrganizer', { keyPrefix: 'locked' })
  const [form] = Form.useForm()
  const subCategoriesOptions = subCategories.filter(
    (sub) => sub.parent.slug === form.getFieldValue('category')
  )

  const getInitialCategory = () => {
    const { category } = document
    const subCategory = subCategories.find(({ value }) => String(value) === category)
    return subCategory ? subCategory.parent.slug : category
  }

  useEffect(() => {
    if (categories.length && subCategories.length) {
      const category = getInitialCategory()
      form.setFieldsValue({
        category,
        subCategory: subCategories.filter((cat) => cat.value === document.category)[0]?.value,
      })
    }
  }, [categories, subCategories])

  const handleValuesChange = async ({ category, subCategory }: any) => {
    await updateDocument(taxOrganizerGuid, documentGuid, {
      category: subCategory || category,
    })
    if (response.ok) {
      if (category) {
        form.setFieldsValue({ subCategory: undefined })
      }
    }
  }

  const childContent = (
    <div className={styles.right_section}>
      <div className={styles.right_section_top}>
        <span className={styles.right_section_title}>{t('category')}</span>
      </div>
      <div className={styles.lis_comment_wrapper}>
        <Form
          onValuesChange={handleValuesChange}
          initialValues={{ category: getInitialCategory() }}
          form={form}
        >
          {() => (
            <>
              <Form.Item name="category">
                <Select options={categories} style={{ width: '100%' }} disabled={disabled} />
              </Form.Item>
              <Form.Item name="subCategory">
                <Select
                  options={subCategoriesOptions}
                  style={{ width: '100%' }}
                  disabled={disabled || subCategoriesOptions.length === 0}
                />
              </Form.Item>
            </>
          )}
        </Form>
      </div>
    </div>
  )

  return disabled ? <Modal content={t2('document.modified')}>{childContent}</Modal> : childContent
}

export default Category
