import { Modal } from 'antd'
import { Elements } from '@stripe/react-stripe-js'
import { LockOutlined } from '@ant-design/icons'
import { Stripe } from '@stripe/stripe-js'
import { useTranslation } from 'react-i18next'

import { TPaymentStatus } from 'context/Billing'

import { Alert, BillingForm } from 'components'

type TProps = {
  modalVisible: boolean
  setModalVisible: (state: boolean) => void
  stripePromise: Promise<Stripe | null> | null
  setPaymentStatus: (state: TPaymentStatus) => void
  alertMessage: string
}

const BillingModal = ({
  modalVisible,
  setModalVisible,
  stripePromise,
  setPaymentStatus,
  alertMessage,
}: TProps) => {
  const { t } = useTranslation('common')
  return (
    <Modal
      visible={modalVisible}
      closable={false}
      destroyOnClose
      maskClosable
      onCancel={() => setModalVisible(false)}
      footer={false}
    >
      <Alert
        message={alertMessage}
        type="warning"
        icon={<LockOutlined />}
        showIcon
        banner
        sameColor
      />
      <Elements stripe={stripePromise}>
        <BillingForm
          setPaymentStatus={setPaymentStatus}
          handleSkip={() => setModalVisible(false)}
          buttonTitles={{ skip: t('buttons.cancel'), next: t('buttons.add') }}
        />
      </Elements>
    </Modal>
  )
}

export default BillingModal
