import { TCPAEntityContact } from 'context/TaxOrganizer'
import { useState, useEffect } from 'react'
import { DEFAULT_TAX_TEAM_COUNT } from '../constants'

type PropsType = {
  data: TCPAEntityContact[]
}

export default ({ data }: PropsType) => {
  const [primaryContact, setPrimaryContact] = useState<TCPAEntityContact | undefined>()
  const [formattedContacts, setFormattedContacts] = useState<TCPAEntityContact[]>()
  const [showCount, setShowCount] = useState(DEFAULT_TAX_TEAM_COUNT)
  useEffect(() => {
    const primary = data.find(({ isPrimary }) => isPrimary)
    setPrimaryContact(primary)
    if (primary) {
      const primaryFirst = [primary, ...data.filter(({ isPrimary }) => !isPrimary)]
      setFormattedContacts(primaryFirst.slice(0, showCount) as any)
    } else {
      setFormattedContacts(data.slice(0, showCount))
    }
  }, [data, showCount])

  return {
    primaryContact,
    formattedContacts,
    showCount,
    setShowCount,
  }
}
