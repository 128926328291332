import { Tag } from 'components'
import { getTagColorByName } from 'utils'

export const tagRender = (props: any) => {
  const { label, closable, onClose } = props
  const onPreventMouseDown = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
  }

  return (
    <Tag
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3, ...getTagColorByName(label) }}
    >
      {label}
    </Tag>
  )
}
