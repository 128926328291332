import { Table, TableProps, TablePaginationConfig } from 'antd'
import { PAGE_SIZE } from 'constants/table'
import { useTranslation } from 'react-i18next'

type TProps<RecordType extends any> = {
  table: TableProps<RecordType>
  pagination: TablePaginationConfig
}

const TableComponent = ({
  table: {
    size,
    columns,
    dataSource,
    loading,
    rowKey,
    rowClassName,
    onRow,
    scroll,
    onChange: onTableChange,
    rowSelection,
  },
  pagination: { total, current, hideOnSinglePage, onChange: onPaginationChange },
}: TProps<any>) => {
  const { t } = useTranslation('common', { keyPrefix: 'pagination' })
  return (
    <>
      <Table
        size={size}
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        rowKey={rowKey}
        rowClassName={rowClassName}
        onRow={onRow}
        onChange={onTableChange}
        pagination={{
          current: current ?? 1,
          position: ['bottomCenter'],
          pageSize: PAGE_SIZE,
          hideOnSinglePage: hideOnSinglePage ?? true,
          showTotal: (total2: number, range: [number, number]) =>
            t('showTotal', { range0: range[0], range1: range[1], total: total2 }),
          total,
          showSizeChanger: false,
          onChange: onPaginationChange,
        }}
        scroll={{ x: true, ...scroll }}
        rowSelection={rowSelection}
      />
    </>
  )
}

export default TableComponent
