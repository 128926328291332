import { useEffect, useMemo, useState } from 'react'
import { Alert, Collapse, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { Box, Flex } from 'reflexbox'

import { TTaxReturn, useTaxReturns, useTaxReturnsActions } from 'context/TaxReturns'
import { useTaxOrganizer } from 'context/TaxOrganizer'

import { getSortedTags, sortPageByCategoryPageNumber } from 'utils/categorize'

import { Button, SpinnerBackdrop, Section } from 'components'

import { STATES_BY_CODE } from 'constants/address'

import Header from './components/Header'
import StatesHeader from './components/StatesHeader'
import DocumentsList from './components/DocumentsList'
import { ESteps } from '../../steps'

import usePanels from './hooks/usePanels'

import './styles.scss'
import styles from './categorize.module.scss'
import usePendingDocuments from './hooks/usePendingDocuments'

const { Panel } = Collapse

const Categorize = () => {
  const [isShowTags, setIsShowTags] = useState<boolean>(true)
  const { loadTaxReturnPages, loading: loadingPages } = useTaxReturnsActions()
  const { loadTaxReturnCategories, loading: loadingCategories } = useTaxReturnsActions()
  const { loadTaxReturnDocuments, loadingDocuments } = useTaxReturnsActions()
  const { setSelectedTags, setStep, setDragDropDocumentIds, setPressShift } = useTaxReturnsActions()
  const { updateTaxReturn, loading: loadingUpd } = useTaxReturnsActions()
  const loading = loadingPages || loadingCategories || loadingDocuments || loadingUpd
  const { t } = useTranslation('common', { keyPrefix: 'buttons' })
  const { t: t1 } = useTranslation('taxOrganizer', { keyPrefix: 'taxReturns.categorize' })
  const {
    state: {
      categories,
      pages,
      selectedTags,
      taxReturnDocuments: { data: taxReturnDocuments },
      taxReturns: { latest: latestTaxReturn },
      loading: globalLoading,
    },
  } = useTaxReturns()
  const {
    taxOrganizer: {
      data: {
        entity: { kind },
      },
    },
  } = useTaxOrganizer()
  const { federal, states, other, uncategorized } = usePanels(categories, pages)
  const { pendingDocumentsStatus } = usePendingDocuments()
  const uncategorizedIsNotEmpty = uncategorized && uncategorized?.categoryPages.length > 0

  const sortedTags = useMemo(
    () => (states ? getSortedTags(selectedTags, states.categoryPages) : selectedTags),
    [selectedTags, states]
  )

  const handleNext = async () => {
    if (uncategorizedIsNotEmpty) return
    let updateParams: Partial<TTaxReturn>
    if (latestTaxReturn?.authorizeStep === null) {
      updateParams = {
        ...latestTaxReturn,
        phase: 'REVIEW',
        authorizeStep: kind.label.startsWith('IND') ? 'FILING_STATUS' : 'AUTHORIZED_SIGNER',
      }
    } else {
      updateParams = {
        ...latestTaxReturn,
        phase: 'REVIEW',
      }
    }
    await updateTaxReturn(latestTaxReturn?.guid!, updateParams)
    setStep(ESteps.REVIEW, 'push')
  }

  const handleIsShowHeaderStatesTags = (key: string | string[]) => {
    setDragDropDocumentIds([])
    setIsShowTags(Array.isArray(key) ? !key.includes('2-states') : key !== '2-states')
  }

  const handleCollapseChange = () => {
    setDragDropDocumentIds([])
  }

  const handelShiftDown = (event: any) => {
    setPressShift(event.key === 'Shift')
  }

  const handelShiftUp = (event: any) => {
    if (event.key === 'Shift') {
      setPressShift(false)
    }
  }

  useEffect(() => {
    loadTaxReturnCategories()
  }, [])

  useEffect(() => {
    setSelectedTags(
      latestTaxReturn!.taxableStates?.length
        ? latestTaxReturn!.taxableStates.map((taxableState) => taxableState.toUpperCase())
        : []
    )
  }, [latestTaxReturn])

  useEffect(() => {
    if (pendingDocumentsStatus === 'END') {
      loadTaxReturnDocuments(latestTaxReturn!)
      loadTaxReturnPages(latestTaxReturn!)
    }
  }, [pendingDocumentsStatus])

  if (pendingDocumentsStatus === 'START' || pendingDocumentsStatus === 'PENDING_PAGES') {
    return (
      <Section className="position-relative" style={{ minHeight: '400px' }}>
        <SpinnerBackdrop mode="local" />
      </Section>
    )
  }

  if (pendingDocumentsStatus === 'PENDING_DOCUMENTS') {
    return (
      <Alert
        style={{ marginBottom: '15px' }}
        message={
          <Flex width={['auto']} m={['auto']} py={[3]}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            <Box ml={[3]} fontSize="16px">
              {t1('uploadTaxDocsWarning')}
            </Box>
          </Flex>
        }
        type="warning"
      />
    )
  }

  return (
    <div tabIndex={-1} onKeyDown={handelShiftDown} onKeyUp={handelShiftUp}>
      {pendingDocumentsStatus === 'END' && (
        <Section className="position-relative" style={{ minHeight: '400px' }}>
          {(loading || globalLoading) && <SpinnerBackdrop mode="local" />}
          <Flex justifyContent={['flex-end']}>
            <Button
              type="primary"
              size="large"
              disabled={uncategorizedIsNotEmpty || loadingUpd}
              onClick={handleNext}
            >
              {t('next')}
            </Button>
          </Flex>

          <Collapse
            ghost
            destroyInactivePanel
            className={cn('tax-return-collapse', styles.tax)}
            onChange={(event) => handleIsShowHeaderStatesTags(event)}
          >
            {!!federal && (
              <Panel
                header={
                  <Header
                    title={t1('federal')}
                    documentsCount={federal.categoryPages.length}
                    slagCategory={federal.category.slug}
                    subCategories={federal.category.childCategories}
                  />
                }
                key="1-federal"
                className={styles.tax_federal}
              >
                <Collapse ghost destroyInactivePanel className="tax-return-subcollapse">
                  {federal.category.childCategories.map((childCategory) => (
                    <Panel
                      key={`panel-states-${childCategory.slug}`}
                      header={
                        <Header
                          title={childCategory.label}
                          documentsCount={
                            federal.categoryPages.filter(
                              (page) => page.childCategory === childCategory.slug
                            ).length
                          }
                          slagCategory={federal.category.slug}
                          subCategories={federal.category.childCategories.filter(
                            (child) => child.slug === childCategory.slug
                          )}
                        />
                      }
                    >
                      <DocumentsList
                        showResetIcon
                        documents={sortPageByCategoryPageNumber(
                          federal.categoryPages.filter(
                            (categoryPage) => categoryPage.childCategory === childCategory.slug
                          )
                        )}
                      />
                    </Panel>
                  ))}
                </Collapse>
              </Panel>
            )}
            {!!states && (
              <Panel
                className={styles.tax_states}
                header={
                  <StatesHeader
                    title={t1('states')}
                    states={states}
                    isShowTags={isShowTags}
                    documentsCount={states.categoryPages.length}
                  />
                }
                key="2-states"
              >
                <Collapse
                  ghost
                  destroyInactivePanel
                  className="tax-return-subcollapse"
                  onChange={handleCollapseChange}
                >
                  {sortedTags.length &&
                    sortedTags.map((state) => {
                      return (
                        <Panel
                          header={
                            <Header
                              title={STATES_BY_CODE[state]}
                              documentsCount={
                                states.categoryPages.filter(
                                  (categoryPage) => categoryPage.state === state.toLowerCase()
                                ).length
                              }
                              slagCategory={states.category.slug}
                              subCategories={states.category.childCategories}
                              state={state.toLowerCase()}
                            />
                          }
                          key={`panel-states-${state}`}
                        >
                          <Collapse ghost destroyInactivePanel className="tax-return-subcollapse">
                            {states.category.childCategories.map((childCategory) => (
                              <Panel
                                key={`panel-states-${state}-${childCategory.slug}`}
                                header={
                                  <Header
                                    title={childCategory.label}
                                    documentsCount={
                                      states.categoryPages.filter(
                                        (page) =>
                                          page.state === state.toLowerCase() &&
                                          page.childCategory === childCategory.slug
                                      ).length
                                    }
                                    slagCategory={states.category.slug}
                                    subCategories={states.category.childCategories.filter(
                                      (child) => child.slug === childCategory.slug
                                    )}
                                    state={state.toLowerCase()}
                                  />
                                }
                              >
                                <DocumentsList
                                  showResetIcon
                                  documents={sortPageByCategoryPageNumber(
                                    states.categoryPages.filter(
                                      (categoryPage) =>
                                        categoryPage.state === state.toLowerCase() &&
                                        categoryPage.childCategory === childCategory.slug
                                    )
                                  )}
                                />
                              </Panel>
                            ))}
                          </Collapse>
                        </Panel>
                      )
                    })}
                </Collapse>
              </Panel>
            )}
            {!!other && (
              <Panel
                className={styles.tax_other}
                header={
                  <Header
                    title={t1('other')}
                    documentsCount={other.categoryPages.length}
                    slagCategory={other.category.slug}
                    subCategories={other.category.childCategories}
                  />
                }
                key="3-other"
              >
                <Collapse ghost destroyInactivePanel className="tax-return-subcollapse">
                  {other.category.childCategories.map((childCategory) => (
                    <Panel
                      key={`panel-states-${childCategory.slug}`}
                      header={
                        <Header
                          title={childCategory.label}
                          documentsCount={
                            other.categoryPages.filter(
                              (page) => page.childCategory === childCategory.slug
                            ).length
                          }
                          slagCategory={other.category.slug}
                          subCategories={other.category.childCategories.filter(
                            (child) => child.slug === childCategory.slug
                          )}
                        />
                      }
                    >
                      <DocumentsList
                        showResetIcon
                        documents={sortPageByCategoryPageNumber(
                          other.categoryPages.filter(
                            (categoryPage) => categoryPage.childCategory === childCategory.slug
                          )
                        )}
                      />
                    </Panel>
                  ))}
                </Collapse>
              </Panel>
            )}
            {!!uncategorized && (
              <Panel
                className={styles.tax_uncategorized}
                header={
                  <Header
                    title={t1('uncategorized')}
                    documentsCount={uncategorized.categoryPages.length}
                    slagCategory={uncategorized.category.slug}
                    subCategories={uncategorized.category.childCategories}
                  />
                }
                key="4-uncategorized"
              >
                <Collapse ghost destroyInactivePanel className="tax-return-subcollapse">
                  {taxReturnDocuments.map((fileCategory) => {
                    const hasCategoryPage = uncategorized.categoryPages.some(
                      (categoryPage) =>
                        categoryPage.taxDocumentGuid === fileCategory.taxDocument.guid
                    )
                    return (
                      hasCategoryPage &&
                      fileCategory?.taxDocument && (
                        <Panel
                          key={`panel-uncategorized-${fileCategory.taxDocument.guid}`}
                          header={
                            <Header
                              title={fileCategory?.taxDocument?.name}
                              documentsCount={
                                uncategorized.categoryPages.filter(
                                  (page) => page.taxDocumentGuid === fileCategory.taxDocument.guid
                                ).length
                              }
                              slagCategory={uncategorized.category.slug}
                              subCategories={uncategorized.category.childCategories}
                            />
                          }
                        >
                          <DocumentsList
                            documents={uncategorized.categoryPages.filter(
                              (page) => page.taxDocumentGuid === fileCategory.taxDocument.guid
                            )}
                            uncategorized
                          />
                        </Panel>
                      )
                    )
                  })}
                </Collapse>
              </Panel>
            )}
          </Collapse>
        </Section>
      )}
    </div>
  )
}

export default Categorize
