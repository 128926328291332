export enum ActionTypes {
  ADD_MESSAGE = 'ADD_MESSAGE',
  LOAD_MESSAGE_THREADS = 'LOAD_MESSAGE_THREADS',
  LOAD_MESSAGE_DETAILS = 'LOAD_MESSAGE_DETAILS',
  LOAD_MESSAGE_DOCUMENT = 'LOAD_MESSAGE_DOCUMENT',
  LOAD_UNREAD_MESSAGES = 'LOAD_UNREAD_MESSAGES',
  FILTER_THREADS = 'FILTER_THREADS',
  ADD_THREAD = 'ADD_THREAD',
  ADD_NEW_MESSAGE = 'ADD_NEW_MESSAGE',
  CLEAR_MESSAGE_DETAILS = 'CLEAR_MESSAGE_DETAILS',
  LOAD_THREAD = 'LOAD_THREAD',
  SET_CURRENT_THREAD_STATUS = 'SET_CURRENT_THREAD_STATUS',
}
