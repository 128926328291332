import { Row, Col, Skeleton, Empty, Tooltip } from 'antd'
import { Flex } from 'reflexbox'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'

import {
  TActivityFeedQueryParams,
  useTaxOrganizer,
  useTaxOrganizerActions,
} from 'context/TaxOrganizer'

import useDate from 'hooks/useDate'

import { Button, Section, User } from 'components'

import colors from 'assets/styles/variables.module.scss'
import styles from '../../tax.overview.module.scss'

const defaultParams: TActivityFeedQueryParams = {
  page: 1,
}

const ActivityFeed = () => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'overview' })
  const { t: tEventType } = useTranslation('taxOrganizer', { keyPrefix: 'overview.eventTypes' })
  const [params, setParams] = useState(defaultParams)
  const {
    taxOrganizer: {
      activityFeed,
      data: { guid },
    },
  } = useTaxOrganizer()
  const { loadActivityFeed, loading } = useTaxOrganizerActions()
  const { getDate } = useDate()

  useEffect(() => {
    loadActivityFeed({ ...params, taxOrganizer: guid })
  }, [guid, params])

  const fetchNextPage = (nextPage: boolean = true) => {
    if (!params.page) return
    params.page = nextPage ? ++params.page : --params.page
    setParams({ ...params })
  }

  const showPaginator: boolean = !!activityFeed?.results?.length
  const showResults: boolean = !loading && showPaginator
  return (
    <Section title={t('activityFeed')} titleIndent={8}>
      {loading && (
        <Flex justifyContent={['flex-start']} alignItems={['center']} flexDirection={['column']}>
          <Skeleton active title={false} paragraph={{ rows: 4, width: '100%' }} />
        </Flex>
      )}
      {!activityFeed?.results.length && (
        <Flex
          height={['200px']}
          width={['100%']}
          justifyContent={['center']}
          alignItems={['center']}
        >
          <Empty description={<>{t('noActivityFeed')}</>} />
        </Flex>
      )}
      {showResults &&
        activityFeed?.results?.map((event) => {
          return (
            <div key={event.guid} className={styles.feed_item}>
              <Row gutter={[10, 10]} align="middle">
                <Col xs={6}>{getDate(event?.occurredOn, 'MMM dd')}</Col>
                <Col xs={10} className="text-ellipsis">
                  <Tooltip title={tEventType(event.eventType)}>
                    {tEventType(event.eventType)}
                  </Tooltip>
                </Col>
                <Col xs={8}>
                  <div className={styles.feed_user}>
                    <User
                      username={
                        event.user ? `${event.user?.firstName} ${event.user?.lastName}` : ''
                      }
                      avatarLink={event.user?.avatar}
                      hideAvatar={!event.user}
                      bgColor={colors.tag5}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          )
        })}
      {showPaginator && (
        <Flex justifyContent={['right']} mt={[3]}>
          <Button
            onClick={() => fetchNextPage(false)}
            type="primary"
            size="middle"
            className={styles.feed_button}
            disabled={loading || !activityFeed?.previous}
            loading={loading}
          >
            {t('previous')}
          </Button>
          <div className={styles.feed_page}>{params.page}</div>
          <Button
            onClick={() => fetchNextPage(true)}
            type="primary"
            size="middle"
            className={styles.feed_button}
            disabled={loading || !activityFeed?.next}
            loading={loading}
          >
            {t('next')}
          </Button>
        </Flex>
      )}
    </Section>
  )
}

export default ActivityFeed
