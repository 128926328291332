import { Collapse } from 'antd'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { useTaxOrganizer } from 'context/TaxOrganizer'
import { useQuestions } from 'context/Questions'

import { ReactComponent as DocumentsIcon } from 'assets/images/collection.documents.svg'
import { ReactComponent as QuestionnaireIcon } from 'assets/images/collection.questionnaire.svg'

import { CollectionKeys } from '../types'

import styles from '../tax.collection.module.scss'

const { Panel } = Collapse

const Tabs = () => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'navigation' })
  const {
    taxOrganizer: {
      data: { guid: taxOrganizerGuid },
    },
    documents: { documentsSummary },
  } = useTaxOrganizer()
  const {
    state: { questionsCount },
  } = useQuestions()

  return (
    <div className={styles.collapse_wrapper}>
      <Collapse bordered={false} destroyInactivePanel expandIconPosition="right" ghost accordion>
        {/* id prop is for chameleon. Do not remove. */}
        <Panel
          id="documents"
          header={
            <NavLink
              to={`/tax-organizer/${taxOrganizerGuid}/collection/documents`}
              className={styles.link}
              activeClassName={styles.link_active}
            >
              <div className={styles.navlink}>
                <DocumentsIcon />
                <span>{t('documents')}</span>
              </div>
            </NavLink>
          }
          key={CollectionKeys.DOCUMENTS}
          className={styles['no-child-panel']}
          extra={documentsSummary?.total}
          showArrow={false}
        />
        {/* id prop is for chameleon. Do not remove. */}
        <Panel
          id="questionnaire"
          header={
            <NavLink
              to={`/tax-organizer/${taxOrganizerGuid}/collection/questionnaire`}
              className={styles.link}
              activeClassName={styles.link_active}
            >
              <div className={styles.navlink}>
                <QuestionnaireIcon />
                <span className={styles.navlink}>{t('questionnaire')}</span>
              </div>
            </NavLink>
          }
          key={CollectionKeys.QUESTIONNAIRE}
          className={styles['no-child-panel']}
          extra={`${questionsCount.total - questionsCount.unanswered}/${questionsCount.total}`}
          showArrow={false}
        />
      </Collapse>
    </div>
  )
}

export default Tabs
