import { createContext, useContext, Dispatch, FC, useReducer } from 'react'

import { useReducerLogger } from 'utils'

import { reducer, initialState } from './reducer'
import { ActionTypes } from './action-types'
import { TDispatch, TState } from './types'

interface IContextProps {
  state: TState
  dispatch: Dispatch<TDispatch>
}

const TaxReturnsContext = createContext<IContextProps>({} as IContextProps)

const TaxReturnsProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(useReducerLogger<TState, TDispatch>(reducer), initialState)

  return (
    // TODO: SPLIT state & dispatch
    <TaxReturnsContext.Provider value={{ state, dispatch }}>{children}</TaxReturnsContext.Provider>
  )
}

const useTaxReturns = () => {
  const context = useContext(TaxReturnsContext)
  if (context === undefined) {
    throw new Error('useTaxReturns must be used within a TaxReturnsProvider')
  }
  return context
}

export { TaxReturnsProvider, useTaxReturns, ActionTypes }
