import { DownloadOutlined } from '@ant-design/icons'
import { Alert, Button } from 'components'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

type TProps = {
  setDocumentLoaded: (state: boolean) => void
  fileUrl: string | undefined | null
  fileName: string
}
const Unsupported = ({ setDocumentLoaded, fileUrl, fileName }: TProps) => {
  const { t } = useTranslation('taxOrganizer')
  const { t: t2 } = useTranslation('common')
  useEffect(() => {
    setDocumentLoaded(true)
  }, [])
  return (
    <>
      <Alert
        message={
          <div style={{ fontSize: '16px' }}>
            <div>{t('document.unsupported.0')}</div>
            <div>{t('document.unsupported.1')}</div>
            <div>{t('document.unsupported.2')}</div>
          </div>
        }
        type="warning"
      />
      {fileUrl && (
        <div className="mt-3">
          <Button
            type="link"
            href={fileUrl}
            download={fileName}
            target="_blank"
            className="px-0"
            title={t2('buttons.download')}
          >
            <Button icon={<DownloadOutlined />} className="px-3">
              {fileName}
            </Button>
          </Button>
        </div>
      )}
    </>
  )
}

export default Unsupported
