export enum ActionTypes {
  LOAD_TAX_ORGANIZER = 'LOAD_TAX_ORGANIZER',
  UPDATE_TAX_ORGANIZER = 'UPDATE_TAX_ORGANIZER',
  CHECK_PHASE_ACTION = 'CHECK_PHASE_ACTION',
  UNCHECK_PHASE_ACTION = 'UNCHECK_PHASE_ACTION',
  SUBMIT_PHASE = 'SUBMIT_PHASE',
  REQUEST_EXTENSION = 'REQUEST_EXTENSION',
  CANCEL_EXTENSION = 'CANCEL_EXTENSION',
  SET_SELECTED_TABLE_DOCUMENT_IDS = 'SET_SELECTED_TABLE_DOCUMENT_IDS',

  LOAD_DOCUMENTS = 'LOAD_DOCUMENTS',
  UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT',
  LOAD_DOCUMENTS_SUMMARY = 'LOAD_DOCUMENTS_SUMMARY',
  DELETE_DOCUMENT = 'DELETE_DOCUMENT',
  BULK_DELETE_DOCUMENTS = 'BULK_DELETE_DOCUMENTS',
  SET_FINANCIAL_SOFTWARE = 'SET_FINANCIAL_SOFTWARE',
  SET_MULTIPLE_DOCUMENT_PARAMS = 'SET_MULTIPLE_DOCUMENT_PARAMS',
  REMOVE_MULTIPLE_DOCUMENT_PARAM = 'REMOVE_MULTIPLE_DOCUMENT_PARAM',
  CLEAR_MULTIPLE_DOCUMENT_PARAMS = 'CLEAR_MULTIPLE_DOCUMENT_PARAMS',
  CLEAR_UPLOADED = 'CLEAR_UPLOADED',

  LOAD_CPA_CONTACTS = 'LOAD_CPA_CONTACTS',
  LOAD_ENGAGEMENT_LETTER_INFO = 'LOAD_ENGAGEMENT_LETTER_INFO',

  LOAD_ACTIVITY_FEED = 'LOAD_ACTIVITY_FEED',
}
