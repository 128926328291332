import {
  FilingStateEnum,
  TTaxReturn,
  useTaxReturns,
  useTaxReturnsActions,
} from 'context/TaxReturns'

import { SpinnerBackdrop } from 'components'

import PendingConfirmation from './PendingConfirmation'
import SelectFilingSuccess from './SelectFilingSuccess'
import FileFailed from './FileFailed'

const SelectFiling = () => {
  const { updateTaxReturn, loading } = useTaxReturnsActions()
  const {
    state: {
      taxReturns: { latest: latestTaxReturn },
    },
  } = useTaxReturns()

  const taxReturnUpdate = async (filingState: FilingStateEnum) => {
    const patchTaxReturnBody: Partial<TTaxReturn> = {
      ...latestTaxReturn,
      filingState,
    }

    await updateTaxReturn(latestTaxReturn?.guid!, patchTaxReturnBody)
  }

  const selectFilingAlert = () => {
    const filingState = latestTaxReturn?.filingState
    if (!filingState) return null

    if (
      filingState === FilingStateEnum.EFILE_PENDING_CONFIRMATION ||
      filingState === FilingStateEnum.PAPER_PENDING_CONFIRMATION
    ) {
      return (
        <PendingConfirmation
          isFilePending={filingState === FilingStateEnum.EFILE_PENDING_CONFIRMATION}
          taxReturnUpdate={taxReturnUpdate}
        />
      )
    }

    if (
      filingState === FilingStateEnum.EFILE_SUCCESS ||
      filingState === FilingStateEnum.PAPER_SUCESSS
    ) {
      return (
        <SelectFilingSuccess
          isPaperSuccess={filingState === FilingStateEnum.PAPER_SUCESSS}
          taxReturnUpdate={taxReturnUpdate}
        />
      )
    }

    if (filingState === FilingStateEnum.EFILE_FAILED) {
      return <FileFailed taxReturnUpdate={taxReturnUpdate} />
    }

    return null
  }

  return (
    <>
      {loading && <SpinnerBackdrop size="large" />}

      <>{selectFilingAlert()}</>
    </>
  )
}

export default SelectFiling
