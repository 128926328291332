import { useEffect } from 'react'
import { Form, notification } from 'antd'
import { useTranslation } from 'react-i18next'

import { useAuthActions } from 'context/Auth'

import { Button, Select, SpinnerBackdrop } from 'components'

import styles from '../../user.settings.module.scss'
import useOptions from '../../hooks/useOptions'

const SettingsForm = () => {
  const {
    loading: loadingPreferences,
    response: reponsePreferences,
    getPreferences,
  } = useAuthActions()
  const {
    loading: loadingUpdatedPreferences,
    response: responseUpdatedPreferences,
    updatePreferences,
  } = useAuthActions()
  const { t } = useTranslation('dashboard', { keyPrefix: 'user.settings' })
  const [form] = Form.useForm()

  const totalOptions = useOptions()

  const loadPreferences = async () => {
    const defaultPreference = await getPreferences()
    if (reponsePreferences.ok) {
      form.setFieldsValue({
        notificationFrequency: defaultPreference,
      })
    }
  }

  useEffect(() => {
    loadPreferences()
  }, [])

  const onSubmit = async (values: IUserSettings) => {
    await updatePreferences(values.notificationFrequency)
    if (responseUpdatedPreferences.ok) {
      notification.success({
        message: t('notification.message'),
        description: t('notification.description'),
      })
    }
  }

  if (loadingPreferences) return <SpinnerBackdrop mode="local" />

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <Form.Item name="notificationFrequency" label={t('frequencyPicker')}>
        <Select options={totalOptions} />
      </Form.Item>
      <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
        {() => (
          <Button
            htmlType="submit"
            type="primary"
            size="large"
            className={styles.submit}
            loading={loadingUpdatedPreferences}
            disabled={!form.isFieldsTouched() || loadingUpdatedPreferences}
          >
            {t('submit')}
          </Button>
        )}
      </Form.Item>
    </Form>
  )
}

export default SettingsForm
