import { FC, SyntheticEvent } from 'react'
import { Popover, PopoverProps } from 'antd'
import { ReactComponent as Close } from 'assets/images/big-cross.svg'

import './styles.scss'

interface PopoverPropsExtended extends PopoverProps {
  showCloseIcon?: boolean
}

const Modal: FC<PopoverPropsExtended> = ({ children, showCloseIcon, ...popoverProps }) => {
  const { content, onVisibleChange } = popoverProps
  const showClose = showCloseIcon === undefined ? true : showCloseIcon
  return (
    <Popover
      // @ts-ignore https://github.com/ant-design/ant-design/issues/14830
      // getPopupContainer={(trigger) => trigger.parentElement}
      {...popoverProps}
      content={
        content && (
          <div className="modal-container">
            {onVisibleChange && showClose && (
              <Close
                onClick={(event: SyntheticEvent) => {
                  event.stopPropagation()
                  onVisibleChange(false)
                }}
                className="modal-close"
              />
            )}
            {content}
          </div>
        )
      }
    >
      {children}
    </Popover>
  )
}

export default Modal
