import { useEffect, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { Row, Col } from 'antd'
import { fieldIsLocked } from 'utils'
import { getTaxOrganizerPathById } from 'utils/routes'

import { useDashboardActions } from 'context/Dashboard'
import { useTaxOrganizer } from 'context/TaxOrganizer'

import { Alert, SpinnerBackdrop } from 'components'

import useDocument from './hooks/useDocument'

import DocumentView from './components/DocumentView'
import Comments from './components/Comments'
import Category from './components/Category'
import Tags from './components/Tags'
import DocumentModal from './components/ModalBody'
import Name from './components/Name'

import styles from './dashboard.document.module.scss'
import Navigation from './components/Navigation'

type Props = {
  toggleNavigation: (show: boolean) => void
}

const Document = ({ toggleNavigation }: Props) => {
  const { taxOrganizerGuid, documentGuid } = useParams<{
    taxOrganizerGuid: string
    documentGuid: string
  }>()
  const [modal, setModal] = useState(false)
  const { state }: any = useLocation()
  const {
    taxOrganizer: {
      data: { isLocked },
    },
  } = useTaxOrganizer()
  const { getDocument, loading, alert, document } = useDocument()
  const { loadDocumentsTags, loadDocumentsCategories } = useDashboardActions()

  useEffect(() => {
    getDocument(taxOrganizerGuid, documentGuid)
    loadDocumentsTags()
    loadDocumentsCategories()
    toggleNavigation(false)
    return () => toggleNavigation(true)
  }, [])

  if (loading) return <SpinnerBackdrop size="large" mode="global" />

  return (
    <div className="container">
      <div className={styles.document}>
        <Navigation
          backPath={`${getTaxOrganizerPathById(taxOrganizerGuid)}/collection/documents`}
        />
        <Row gutter={[20, 0]}>
          <Col xs={24} lg={15}>
            {alert.status ? <Alert message={alert.message} type="error" /> : <DocumentView />}
          </Col>
          <Col xs={24} lg={9}>
            <Comments
              // TODO: remove state?.disabled when it will be added to a single document endpoint
              disabled={state?.disabled || fieldIsLocked(isLocked, document?.afterTaxOrganizerLock)}
            />
            <Name
              disabled={state?.disabled || fieldIsLocked(isLocked, document?.afterTaxOrganizerLock)}
            />
            <Category
              disabled={state?.disabled || fieldIsLocked(isLocked, document?.afterTaxOrganizerLock)}
            />
            <Tags
              disabled={state?.disabled || fieldIsLocked(isLocked, document?.afterTaxOrganizerLock)}
            />
          </Col>
        </Row>
      </div>
      {modal && (
        <DocumentModal
          onHide={() => {
            setModal(false)
          }}
        />
      )}
    </div>
  )
}

export default Document
