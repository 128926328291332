import { useState } from 'react'
import { Button, Form, message, Select, Typography } from 'antd'
import { getFirstError } from 'utils'
import { useTranslation } from 'react-i18next'

import { ErrorResponse } from 'context/Entity'
import { useAuth } from 'context/Auth'
import { useCPAFirm, useCPAFirmActions } from 'context/CPAFirm'
import { TCPAEntityContact } from 'context/TaxOrganizer'

import styles from '../../dashboard.firm.module.scss'

const { Paragraph } = Typography

type TProps = {
  taxPreparer: TCPAEntityContact
  onSubmit: () => void
}

const JobTitleForm = ({ taxPreparer, onSubmit }: TProps) => {
  const [form] = Form.useForm()
  const {
    CPA: { cpaFirm },
  } = useAuth()
  const { editFirmUser, response, loading } = useCPAFirmActions()
  const { jobTitles } = useCPAFirm()
  const [errorMap, setErrorMap] = useState<ErrorResponse>({})
  const { t } = useTranslation('common')

  const jobTitleOptions = jobTitles.map((option) => ({
    label: option.label,
    value: option.guid,
  }))

  const handleJobTitleChange = async ({ jobTitleGuid }: { jobTitleGuid: string }) => {
    const editResponse = await editFirmUser(cpaFirm!.guid, taxPreparer.user.guid, { jobTitleGuid })
    if (response.ok) {
      onSubmit()
      message.success(t('messages.success.titleChanged'))
    } else {
      setErrorMap({ jobTitle: [editResponse?.detail] })
    }
  }

  return (
    <div className={styles.role_form}>
      <Form onFinish={handleJobTitleChange} form={form}>
        <div className={styles.form_fields}>
          <Paragraph className={styles.form_label}>{t('form.jobTitle')}</Paragraph>
          <Form.Item
            rules={[
              { required: true, message: t('form.requiredField', { field: t('form.jobTitle') }) },
            ]}
            className={styles.users_role}
            initialValue={taxPreparer?.jobTitle?.label}
            name="jobTitleGuid"
            key="jobTitleGuid"
            extra={
              errorMap?.jobTitle && (
                <Paragraph className={styles.form_error}>
                  {getFirstError(errorMap.jobTitle)}
                </Paragraph>
              )
            }
            validateStatus={errorMap?.jobTitle && 'error'}
          >
            <Select placeholder={t('form.jobTitle')} options={jobTitleOptions} />
          </Form.Item>
          <Button htmlType="submit" className={styles.form_submit} type="primary" loading={loading}>
            {t('buttons.confirm')}
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default JobTitleForm
