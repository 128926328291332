import { TChartData } from 'context/Dashboard'
import moment from 'moment'
import { TRawDataExt } from './types'

export const dayOfAYear = (date: string) => {
  const now = new Date(date)
  const start = new Date(now.getFullYear(), 0, 0)
  const diff = Number(now) - Number(start)
  const oneDay = 1000 * 60 * 60 * 24
  const day = Math.floor(diff / oneDay)
  return day
}

export const extendData = (data: TChartData[], locale?: any): TRawDataExt[] => {
  const extendedRaw: TRawDataExt[] = []
  for (let i = 0; i < data.length; i++) {
    extendedRaw.push({ ...data[i], reper: true })
    if (data[i + 1] !== undefined) {
      const days = dayOfAYear(data[i + 1].date) - dayOfAYear(data[i].date) - 1
      const step = (data[i + 1].count - data[i].count) / days
      const daysRange = Array.from({ length: days })
      for (let j = 0; j < daysRange.length; j++) {
        const { date, count } = extendedRaw[extendedRaw.length - 1]
        const dateIsValid = moment(date).isValid()
        if (!dateIsValid) throw new Error('Production Rate date is not valid')
        extendedRaw.push({
          date: moment(new Date(date).toISOString())
            .add(1, 'd')
            .locale(locale?.code ?? 'en')
            .utc()
            .format('YYYY-MM-DD'),
          count: count + step,
          reper: false,
        })
      }
    }
  }
  return extendedRaw
}
