import { useState } from 'react'
import { Box, Flex } from 'reflexbox'
import { CloseOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Input, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import { IsValidEmail } from 'utils/taxOrganizer'

import colors from 'assets/styles/variables.module.scss'
import styles from 'pages/TaxOrganizer/Documents/tax.documents.module.scss'

const { Paragraph } = Typography

type Props = {
  emails: string[]
  setEmails: (emails: string[]) => void
  errorInvitees: boolean
}
const Invitees = ({ emails, setEmails, errorInvitees }: Props) => {
  const { t } = useTranslation(['taxOrganizer'])
  const [emailValue, setEmailValue] = useState<string>('')
  const [emailError, setEmailError] = useState<boolean>(false)

  const handleSetEmail = () => {
    if (!IsValidEmail(emailValue.trim())) {
      setEmailError(true)

      return
    }

    if (emails.includes(emailValue.trim())) return

    setEmails(emails.concat(emailValue.trim()))
    setEmailValue('')
  }

  const handleRemoveEmail = (removedEmail: string) => {
    setEmails(emails.filter((email) => email !== removedEmail))
  }

  const handleSetEmailValue = (value: string) => {
    setEmailError(false)
    setEmailValue(value)
  }

  return (
    <Flex
      flexWrap={['wrap']}
      alignItems="start"
      p={[2]}
      style={{
        border: '1px solid',
        borderRadius: '3px',
        borderColor: errorInvitees && !emails.length ? colors.blocked : 'lightgray',
      }}
    >
      {emails.map((email) => (
        <Flex key={email} alignItems="center" className={styles.email_container}>
          <Box>{email}</Box>

          <Box ml={[2]}>
            <CloseOutlined style={{ cursor: 'pointer' }} onClick={() => handleRemoveEmail(email)} />
          </Box>
        </Flex>
      ))}

      <Box>
        <Input
          style={{ borderColor: emailError ? colors.blocked : '' }}
          placeholder={t('shareModal.placeholderInvitees')}
          suffix={<PlusCircleOutlined className={styles.suffix_input} onClick={handleSetEmail} />}
          value={emailValue}
          onChange={({ target: { value } }) => handleSetEmailValue(value)}
          onBlur={handleSetEmail}
        />

        {emailError && (
          <Paragraph className={styles.error_text}>{t('shareModal.errorTextEmail')}</Paragraph>
        )}
      </Box>
    </Flex>
  )
}

export default Invitees
