import { useState, useEffect } from 'react'
import { Switch, Route, useRouteMatch, useParams, Redirect } from 'react-router-dom'
import { Spin } from 'antd'

import { useAuth } from 'context/Auth'
import { useTaxOrganizer, useTaxOrganizerActions } from 'context/TaxOrganizer'
import { DocumentProvider } from 'context/Document'
import { TaxReturnsProvider } from 'context/TaxReturns'
import { FinancialsProvider } from 'context/Financials'
import { QuestionsProvider } from 'context/Questions'
import { EntityProvider } from 'context/Entity'

import Overview from 'pages/TaxOrganizer/Overview'
import Collection from 'pages/TaxOrganizer/Collection'
import Messages from 'pages/TaxOrganizer/Messages'
import Document from 'pages/TaxOrganizer/Document'
import Financials from 'pages/TaxOrganizer/Financials'
import { COLLECTION_PATH } from 'utils/routes'

import { SpinnerBackdrop } from 'components'

import Header from './components/Header'
import Navigation from './components/Navigation'
import Footer from './components/Footer'

import styles from './tax.layout.module.scss'
import Taxes from '../../pages/TaxOrganizer/TaxReturn'

const TaxOrganizerLayout = () => {
  const { path } = useRouteMatch()
  const { taxOrganizerGuid }: { taxOrganizerGuid: string } = useParams()
  const [showNavigation, setNavigation] = useState(true)
  const { user } = useAuth()
  const {
    taxOrganizer: { data },
  } = useTaxOrganizer()
  const { loadTaxOrganizer, response } = useTaxOrganizerActions()
  const { loadEngagementLetterSigningInformation } = useTaxOrganizerActions()

  useEffect(() => {
    loadTaxOrganizer(taxOrganizerGuid)
    loadEngagementLetterSigningInformation(taxOrganizerGuid)
  }, [])

  // TODO: probably need add a 404 page
  if (response.status === 404) return <Redirect to="/" />

  if (!user || !data) return <SpinnerBackdrop size="large" />

  return (
    <div className={styles.box}>
      <Header taxOrganizer={data} />
      {showNavigation && (
        <>
          <Navigation taxOrganizer={data} />
        </>
      )}
      <div className="position-relative">
        <Switch>
          <Route exact path={path}>
            <EntityProvider>
              <Overview />
            </EntityProvider>
          </Route>
          <Route exact path={`${path}/${COLLECTION_PATH}/:slug`}>
            <QuestionsProvider>
              <Collection />
            </QuestionsProvider>
          </Route>
          <Route path={`${path}/documents/:documentGuid`}>
            <DocumentProvider>
              <Document toggleNavigation={setNavigation} />
            </DocumentProvider>
          </Route>
          <Route path={`${path}/financials`} exact>
            <FinancialsProvider>
              <Financials />
            </FinancialsProvider>
          </Route>
          <Route path={`${path}/messages`} exact>
            <Messages />
          </Route>
          <Route path={`${path}/taxes`} exact>
            <TaxReturnsProvider>
              <Taxes />
            </TaxReturnsProvider>
          </Route>
          <Route path={`${path}/taxes/:documentGuid`}>
            <Document toggleNavigation={setNavigation} />
          </Route>
          <Route
            path={`${path}/sign-engagement`}
            component={() => {
              window.location.replace(
                'https://eval.signnow.com/s/gzyYvyXM?blank_redirect=https%3A%2F%2Fdemo.repositax.com%2Ftax-organizer%2F1353533869811556%3Fengagement-signed%3D1'
              )
              return <Spin size="large" style={{ margin: '200px auto', width: '100%' }} />
            }}
          />
          <Route path={`${path}/:view?`}>Coming soon...</Route>
        </Switch>
      </div>
      <Footer />
    </div>
  )
}

export default TaxOrganizerLayout
