import { IAction } from '..'
import { taxOrganizerReducer, initialTaxOrganizerState, ITaxOrganizerState } from './tax-organizer'
import { documentsReducer, initialDocumentsState, IDocumentsState } from './documents'

export type ITaxOrganizerContext = {
  taxOrganizer: ITaxOrganizerState
  documents: IDocumentsState
}

const initialState = {
  taxOrganizer: initialTaxOrganizerState,
  documents: initialDocumentsState,
}

const mainReducer = ({ taxOrganizer, documents }: ITaxOrganizerContext, action: IAction) => ({
  taxOrganizer: taxOrganizerReducer(taxOrganizer, action),
  documents: documentsReducer(documents, action),
})

export { initialState, mainReducer }
