import { Input, InputProps } from 'antd'

import './styles.scss'

type Props = InputProps

const TextField = (props: Props) => {
  return <Input {...props} />
}

export default TextField
