import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { SearchOutlined } from '@ant-design/icons'

import { debounce } from 'lodash'

import { Select, TextField } from 'components'

import { useMessagesActions } from 'context/Messages'

import styles from '../../tax.messages.module.scss'

type Props = {
  taxOrganizer: string
}

const Header = ({ taxOrganizer }: Props) => {
  const { t } = useTranslation(['common', 'taxOrganizer'])
  const { filterThreads, loadMessageThreads } = useMessagesActions()

  const onValuesChange = async (event: any) => {
    loadMessageThreads({ taxOrganizer, search: event.target.value })
  }

  const handleSearch = useMemo(() => debounce(onValuesChange, 300), [])

  const totalOptions = [
    {
      label: 'allThreads',
      value: 'all',
    },
    {
      label: t(`taxOrganizer:messages.resolved`),
      value: 'resolved',
    },
    {
      label: t(`taxOrganizer:messages.unresolved`),
      value: 'unresolved',
    },
    {
      label: t(`taxOrganizer:messages.unread`),
      value: 'unread',
    },
  ]

  return (
    <div className={styles.header}>
      <TextField
        placeholder={t('placeholders.search')}
        size="middle"
        suffix={<SearchOutlined />}
        onChange={handleSearch}
        className={styles.header_search}
      />
      <Select
        options={totalOptions.map((option) => ({
          ...option,
          label:
            option.label === 'allThreads'
              ? t(`taxOrganizer:messages.${option.label}`)
              : option.label,
        }))}
        dropdownMatchSelectWidth={false}
        defaultValue="all"
        className={styles.header_select}
        onChange={(e) => filterThreads(e)}
      />
    </div>
  )
}

export default Header
