import { useEffect, useState } from 'react'
import { Row, Col, Radio } from 'antd'
import type { RadioChangeEvent } from 'antd'
import { useTranslation } from 'react-i18next'

import { Section } from 'components'
import { useAuth, useAuthActions } from 'context/Auth'

import ProfileForm from './components/Form'
import Avatar from './components/Avatar'
import Password from './components/Password'

import styles from './user.profile.module.scss'

const UserProfile = () => {
  const { i18n, t } = useTranslation('dashboard', { keyPrefix: 'user.profile' })
  const { user } = useAuth()
  const { updateUserProfile } = useAuthActions()
  const [currentLang, setCurrentLang] = useState<string>('en')

  const onChange = (e: RadioChangeEvent) => {
    setCurrentLang(e.target.value)
    updateUserProfile({
      localeName: e.target.value,
    })
    i18n.changeLanguage(e.target.value)
  }

  useEffect(() => {
    setCurrentLang(user?.localeName || 'en_US')
  }, [])

  return (
    <div className="container">
      <Section>
        <div className="padding-h-0">
          <Row gutter={[50, 32]}>
            <Col xs={24} md={12}>
              <div className={styles.title}>{t('title')}</div>
              <div className={styles.description}>{t('context')}</div>
              <ProfileForm />
            </Col>
            <Col xs={24} md={12}>
              <div className="container-md padding-h-0">
                <div className={styles.title}>{t('languagePicker')}</div>
                <Radio.Group
                  onChange={onChange}
                  value={currentLang}
                  className={styles.languagePicker}
                >
                  <Radio.Button value="en_US">{t('english')}</Radio.Button>
                  <Radio.Button value="es_US">{t('spanish')}</Radio.Button>
                </Radio.Group>
              </div>
              <Avatar />
            </Col>
          </Row>
          <div className={styles.password}>
            <Row gutter={[50, 0]}>
              <Col xs={24} md={12}>
                <Password />
              </Col>
            </Row>
          </div>
        </div>
      </Section>
    </div>
  )
}

export default UserProfile
