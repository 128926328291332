import { SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { ColumnsType } from 'antd/lib/table'
import { Typography } from 'antd'
import { PaperClipOutlined } from '@ant-design/icons'

import { User } from 'components'
import useDate from 'hooks/useDate'
import colors from 'assets/styles/variables.module.scss'

import { TClientInviteParams } from 'context/EntityCPAs'
import { TEntity } from '../../components/types'
import Actions from '../components/Actions'

const { Paragraph, Text } = Typography

type Props = {
  setParams: (
    value: TClientInviteParams | ((prevState: TClientInviteParams) => TClientInviteParams)
  ) => void
}

export default ({ setParams }: Props) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'clients.table' })
  const { getDate } = useDate()

  const entityNameColumnWidth = 300

  const textComma = (entity: TEntity, isLastRecord: boolean) => {
    const { name, kind, guid, documents } = entity
    const numDocuments = documents ? documents.length : 0

    return (
      <>
        <Text key={guid} className="text-black">{`${name} (${kind}`}</Text>
        {numDocuments > 0 ? (
          <>
            <Text>, {numDocuments}</Text>
            <PaperClipOutlined />
          </>
        ) : null}
        <Text>)</Text>
        {!isLastRecord && <>{', '}</>}
      </>
    )
  }

  const renderEntities = (entities: TEntity[]) => {
    if (entities.length === 1) {
      return textComma(entities[0], true)
    }

    return (
      <Paragraph
        ellipsis={{
          rows: 2,
          expandable: true,
          symbol: 'more',
          onExpand: (e: SyntheticEvent) => e.stopPropagation(),
        }}
      >
        {entities.map((entity: TEntity, index: number) => {
          const isLastRecord = index === entities.length - 1
          return textComma(entity, isLastRecord)
        })}
      </Paragraph>
    )
  }

  const pendingColumns: ColumnsType<IClientInvite> = [
    {
      title: <Text className="text-bold">{t('entities')}</Text>,
      dataIndex: ['entities'],
      key: 'name',
      width: entityNameColumnWidth,
      render: (entities: TEntity[]) => renderEntities(entities),
    },
    {
      title: t('recipient'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('invitedOn'),
      dataIndex: 'lastSentOn',
      key: 'lastSentOn',
      render: (lastSentOn: string) => {
        return <div className="text-nowrap">{getDate(lastSentOn, 'yyyy-MM-dd')}</div>
      },
    },
    {
      title: t('invitedBy'),
      dataIndex: 'invitedBy',
      key: 'invitedBy',
      render: ({ firstName, lastName, avatar }: IContact) => {
        return (
          <User username={`${firstName} ${lastName}`} bgColor={colors.tag5} avatarLink={avatar} />
        )
      },
    },
    {
      title: t('actions'),
      dataIndex: 'actions',
      key: 'actions',
      render: (
        _: unknown,
        { guid: inviteGuid, cpaFirm: { guid: cpaFirmGuid }, entities }: IClientInvite
      ) => {
        return (
          <Actions
            entities={entities}
            inviteGuid={inviteGuid}
            cpaFirmGuid={cpaFirmGuid}
            setParams={setParams}
          />
        )
      },
    },
  ]

  return pendingColumns
}
