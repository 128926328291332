import { IGNORED_URLS } from 'constants/http'
import { isString, keys, flattenDeep, values } from 'lodash'

export const getMessageFromResponse = (response: any) => {
  const { data, status } = response
  if (status === 404) {
    try {
      throw new Error(`Response status: ${status}`)
    } catch (error) {
      console.error(error)
      return 'Not Found'
    }
  }
  if (status === 500) {
    try {
      throw new Error(`Response status: ${status}`)
    } catch (error) {
      console.error(error)
      return 'Server Error'
    }
  }

  const getErrors = (errors: { [key: string]: string | string[] }): string[] => {
    return keys(errors).reduce((acc: string[], cur: string): string[] => {
      if (isString(errors[cur])) {
        if (
          errors[cur].includes('This field is required.') ||
          errors[cur].includes('This field may not be null.')
        ) {
          return [...acc, `${cur}: ${errors[cur]}`]
        }
        return [...acc, errors[cur] as string]
      }
      if (Array.isArray(errors[cur])) {
        const a = (errors[cur] as string[]).map((_) => {
          if (_.includes('This field is required.') || _.includes('This field may not be null.')) {
            return `${cur}: ${_}`
          }
          return _
        })
        return [...acc, ...a]
      }
      return acc
    }, [])
  }

  return (
    <ul style={{ margin: 0, padding: 0, listStyle: 'none', textAlign: 'left' }}>
      {getErrors(data).map((str: string) => (
        <li key={str}>{str}</li>
      ))}
    </ul>
  )
}

export const ignoredUrl = (url: string) => IGNORED_URLS.some((_: string) => url.includes(_))

export const clientsLimitExceeded = (
  responseStatus: number,
  responseData: { [key: string]: string }
) =>
  // TODO: make verification more reliable
  responseStatus === 400 && flattenDeep(values(responseData)).includes('Payment method required.')
