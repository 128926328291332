import { useEffect, useLayoutEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import qs from 'qs'
import cn from 'classnames'

import { TTaxreturnPhase, useTaxReturns, useTaxReturnsActions } from 'context/TaxReturns'
import { useTaxOrganizer } from 'context/TaxOrganizer'

import useMediaQuery from 'hooks/useMediaQuery'

import { SpinnerBackdrop } from 'components'
import Instructions from './components/Instructions'
import CustomSteps from './components/Steps'

import { ESteps } from './steps'
import { switchContent } from './helpers'
import { CONTROLLED_TABS } from './constants'

import styles from './dashboard.tax_return.module.scss'

const TaxReturn = () => {
  const {
    taxOrganizer: {
      data: { guid: taxOrganizerGuid },
    },
  } = useTaxOrganizer()
  const {
    state: {
      process,
      taxReturns: { latest: latestTaxReturn, response: responseTaxReturns },
      taxReturnDocuments,
      step,
      steps,
      maxStep,
    },
  } = useTaxReturns()
  const { loadTaxReturns, setStep, loadTaxReturnDocuments, loadSteps, setTaxReturnStatus } =
    useTaxReturnsActions()
  const location = useLocation()
  const history = useHistory()
  const minWidthLg = useMediaQuery('lg')

  const handleStep = (newStep: number) => {
    if (newStep <= maxStep) {
      setStep(newStep, 'replace')
    }
  }

  useEffect(() => {
    if (!process.status) {
      setTaxReturnStatus('TAX_RETURN')
      loadSteps()
      loadTaxReturns(taxOrganizerGuid)
    }
  }, [process.status])

  useEffect(() => {
    if (process.status === 'TAX_RETURN' && responseTaxReturns) {
      if (latestTaxReturn) {
        setTaxReturnStatus('DOCUMENTS')
        loadTaxReturnDocuments(latestTaxReturn!)
      } else {
        setStep(ESteps.INFO, 'replace')
        setTaxReturnStatus('FINISHED')
      }
    }
  }, [process.status, responseTaxReturns])

  useEffect(() => {
    if (process.status === 'DOCUMENTS' && taxReturnDocuments.response) {
      if (CONTROLLED_TABS.includes(latestTaxReturn!.phase as TTaxreturnPhase)) {
        if (taxReturnDocuments.count > 0) {
          setStep(ESteps[latestTaxReturn!.phase as keyof typeof ESteps], 'replace')
        } else {
          setStep(ESteps.UPLOAD, 'replace')
        }
      } else {
        setStep(ESteps[latestTaxReturn!.phase as keyof typeof ESteps], 'replace')
      }
      setTaxReturnStatus('FINISHED')
    }
  }, [taxReturnDocuments.response, process.status])

  useLayoutEffect(() => {
    const searchParams = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (history.action === 'POP' && Number(searchParams.step) < step.current!) {
      setStep(Number(searchParams.step))
    }
  }, [location])

  return (
    <div className={cn([styles.main, 'container'])}>
      <div className={styles.header}>
        <div className={styles.steps}>
          {' '}
          <CustomSteps
            current={step.current}
            titles={steps}
            labelPlacement={minWidthLg ? 'horizontal' : 'vertical'}
            status="process"
            getChangedValue={handleStep}
          />
        </div>
        <div>
          <Instructions currentStep={step.current} />
        </div>
      </div>

      <div>
        {process.status === 'FINISHED' ? (
          switchContent(step.current)
        ) : (
          <SpinnerBackdrop mode="local" />
        )}
      </div>
    </div>
  )
}

export default TaxReturn
