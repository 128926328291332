import colors from 'assets/styles/variables.module.scss'
import styles from '../../taxteam.module.scss'

type PropsType = {
  cpaFirm: TCpaFirm
}

const LogoName = ({ cpaFirm }: PropsType) => {
  return (
    <div
      className={styles.header}
      style={{ backgroundColor: cpaFirm?.letterheadImage ? colors.white : colors.greyBorder }}
    >
      {cpaFirm?.letterheadImage ? (
        <img src={cpaFirm?.letterheadImage} alt="logo" className={styles.header_logo} />
      ) : (
        <div className={styles.header_name}>{cpaFirm?.name}</div>
      )}
    </div>
  )
}

export default LogoName
