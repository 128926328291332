import { useTranslation } from 'react-i18next'

import styles from '../../dashboard.outlook.module.scss'
import { dataSource } from './mock'

type GridProps = {
  image: string
  title: string
  description: string
}

const Grid = () => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'outlook.features' })

  const GridItem = ({ image, title, description }: GridProps) => {
    return (
      <div className={styles.greed_item}>
        <img className={styles.greed_item_image} src={image} alt="icon" />
        <p className={styles.greed_item_title}>{t(title)}</p>
        <p className={styles.greed_item_description}>{t(description)}</p>
      </div>
    )
  }

  return (
    <div className={styles.greed}>
      {dataSource.map((item) => {
        return (
          <GridItem
            key={item.id}
            image={item.image}
            title={item.title}
            description={item.description}
          />
        )
      })}
    </div>
  )
}

export default Grid
