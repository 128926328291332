import { useMemo, useRef, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Flex } from 'reflexbox'
import { Form } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'

import { useAuth, useAuthActions } from 'context/Auth'
import { InviteMember, useCPAFirm, useCPAFirmActions } from 'context/CPAFirm'

import { getCPAOnboardingPath } from 'utils/routes'
import { browserSession } from 'utils/session'
import { initForm, saveFormToSession } from 'utils/form'
import { getTaxFirmUserRoles } from 'utils/taxFirm'

import { Button, Select, TextField } from 'components'

type NextKeyType = 'skip' | 'continue' | undefined

const Billing = () => {
  const [form] = Form.useForm()
  const { t } = useTranslation('dashboard', { keyPrefix: 'firm.team' })
  const { t: t2 } = useTranslation('common')
  const { t: t3 } = useTranslation('dashboard', { keyPrefix: 'firm' })
  const {
    CPA: { cpaFirm: firm },
  } = useAuth()
  const { updateCpaFirm, response: updateResponse, loading: updateLoading } = useAuthActions()
  const { setOnboardingStep } = useAuthActions()
  const history = useHistory()
  const { cpaUserRoles } = useCPAFirm()
  const { inviteMembers, loading: inviteLoading, response } = useCPAFirmActions()
  const { loadUserRolesCPAFirm, loading: loadingUserRoles } = useCPAFirmActions()
  const [errors, setErrors] = useState<Partial<InviteMember>[]>([])
  const [continueIsActive, setContinueActive] = useState(false)
  const loading = useMemo(
    () => inviteLoading || updateLoading || loadingUserRoles,
    [inviteLoading, updateLoading, loadingUserRoles]
  )
  const roleOptions = getTaxFirmUserRoles(cpaUserRoles, t2)
  const nextKey = useRef<NextKeyType>()

  useEffect(() => {
    loadUserRolesCPAFirm(firm!.guid)
  }, [])

  const onSubmit = async ({
    members,
    staticField,
  }: {
    members: InviteMember[] | undefined
    staticField: InviteMember
  }) => {
    nextKey.current = 'continue'
    const { email, role } = staticField
    const params = [
      {
        email,
        role,
      },
      ...(members ?? []),
    ]

    const result = await inviteMembers(firm!.guid, params)
    if (response.ok) {
      browserSession.removeItem('form:addTeamMemberOnboarding')
      await updateCpaFirm(firm!.guid, { onboardingStatus: 'INVITE_CLIENTS' })
      if (updateResponse.ok) {
        setOnboardingStep('INVITE_CLIENTS')
        history.replace(getCPAOnboardingPath('INVITE_CLIENTS'))
      } else {
        form.resetFields()
        setErrors([])
      }
    } else {
      setErrors(result)
    }
  }

  const handleSkip = async () => {
    nextKey.current = 'skip'
    await updateCpaFirm(firm!.guid, { onboardingStatus: 'INVITE_CLIENTS' })
    if (updateResponse.ok) {
      setOnboardingStep('INVITE_CLIENTS')
      history.replace(getCPAOnboardingPath('INVITE_CLIENTS'))
    }
  }

  return (
    <Box mb={[3, 5]}>
      <Box my={[1, 4]}>
        <Box textAlign={['center']} fontSize={['24px']} fontWeight={[700]}>
          {t3('onboarding.inviteUsers.title')}
        </Box>
        <Box textAlign={['center']} fontStyle="italic" mt={[3]}>
          {t3('onboarding.inviteUsers.subtitle')}
        </Box>
      </Box>
      <Form
        onFinish={onSubmit}
        onFieldsChange={() => setContinueActive(true)}
        autoComplete="off"
        form={form}
        onValuesChange={(changedValues: any, allValues: any) =>
          saveFormToSession('form:addTeamMemberOnboarding', allValues)
        }
        initialValues={initForm('form:addTeamMemberOnboarding')}
      >
        <Flex flexDirection={['column']}>
          <Flex mb={['16px']} width={['100%']}>
            <Box flexGrow={[1]} flexShrink={[0]} flexBasis={['50%']}>
              <Box fontSize={['14px']} lineHeight={['17px']} mb={['14px']} fontWeight={[600]}>
                {t('email')}
              </Box>
              <Form.Item
                name={['staticField', 'email']}
                fieldKey={['staticField', 'email']}
                rules={[
                  {
                    required: true,
                    message: t2('form.requiredField', { field: t('email') }),
                  },
                  { type: 'email', message: t2('form.invalidField', { field: t('email') }) },
                ]}
                validateStatus={errors[0]?.email && 'error'}
                help={errors[0]?.email}
                style={{ margin: 0 }}
              >
                <TextField placeholder={t2('form.email')} />
              </Form.Item>
            </Box>
            <Box ml={[1, 2]} flexGrow={[1]} flexShrink={[0]} flexBasis={['50%']}>
              <Box fontSize={['14px']} lineHeight={['17px']} mb={['14px']} fontWeight={[600]}>
                {t('role')}
              </Box>
              <Box>
                <Form.Item
                  name={['staticField', 'role']}
                  fieldKey={['staticField', 'role']}
                  rules={[
                    {
                      required: true,
                      message: t2('form.requiredField', { field: t('role') }),
                    },
                  ]}
                  validateStatus={errors[0]?.role && 'error'}
                  help={errors[0]?.role}
                  style={{ margin: 0 }}
                >
                  <Select placeholder={t2('form.choose')} options={roleOptions} />
                </Form.Item>
              </Box>
            </Box>
          </Flex>

          <Form.List name="members">
            {(fields, { add, remove }) => (
              <Flex flexDirection={['column']}>
                {fields.map(({ key, name, fieldKey, ...restField }: any, index) => (
                  <Flex key={key} mb={['16px']} width={['100%']} alignItems={['flex-start']}>
                    <Box flexGrow={[1]} flexShrink={[0]} flexBasis={['50%']}>
                      <Form.Item
                        {...restField}
                        name={[name, 'email']}
                        fieldKey={[fieldKey, 'email']}
                        rules={[
                          {
                            required: true,
                            message: t2('form.requiredField', { field: t('email') }),
                          },
                          {
                            type: 'email',
                            message: t2('form.invalidField', { field: t('email') }),
                          },
                        ]}
                        validateStatus={errors[index + 1]?.email && 'error'}
                        help={errors[index + 1]?.email}
                        style={{ margin: 0 }}
                      >
                        <TextField placeholder={t2('form.email')} />
                      </Form.Item>
                    </Box>

                    <Flex
                      ml={[1, 2]}
                      alignItems={['flex-start']}
                      flexGrow={[1]}
                      flexShrink={[0]}
                      flexBasis={['50%']}
                    >
                      <Box width={['100%']}>
                        <Form.Item
                          {...restField}
                          name={[name, 'role']}
                          fieldKey={[fieldKey, 'role']}
                          rules={[
                            {
                              required: true,
                              message: t2('form.requiredField', { field: t('role') }),
                            },
                          ]}
                          validateStatus={errors[index + 1]?.role && 'error'}
                          help={errors[index + 1]?.role}
                          style={{ margin: 0 }}
                        >
                          <Select placeholder={t2('form.choose')} options={roleOptions} />
                        </Form.Item>
                      </Box>

                      <Box ml={[1, 2]} mt={[1]}>
                        <MinusCircleOutlined
                          onClick={() => {
                            remove(name)
                            setErrors([])
                          }}
                        />
                      </Box>
                    </Flex>
                  </Flex>
                ))}
                <Box width={['100%', 'auto']}>
                  <Button type="ghost" size="large" onClick={() => add()} icon={<PlusOutlined />}>
                    {t('add')}
                  </Button>
                </Box>
              </Flex>
            )}
          </Form.List>
        </Flex>
        <Flex
          width={['100%']}
          justifyContent={[continueIsActive ? 'space-between' : 'flex-end']}
          flexDirection={['column', 'row']}
          my={[4]}
        >
          <Box width={['100%', '190px']}>
            <Button
              type="ghost"
              size="large"
              block
              onClick={handleSkip}
              loading={updateLoading && nextKey.current === 'skip'}
              disabled={updateLoading && nextKey.current === 'skip'}
            >
              {t3('onboarding.button.skip')}
            </Button>
          </Box>
          {continueIsActive && (
            <Box width={['100%', '190px']} my={[2, 0]}>
              <Button
                type="primary"
                size="large"
                block
                htmlType="submit"
                disabled={loading && nextKey.current === 'continue'}
                loading={loading && nextKey.current === 'continue'}
              >
                {t3('onboarding.button.continue')}
              </Button>
            </Box>
          )}
        </Flex>
      </Form>
    </Box>
  )
}

export default Billing
