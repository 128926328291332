import { useState, useRef } from 'react'
import { Box, Flex } from 'reflexbox'
import { useTranslation } from 'react-i18next'

import { IThread } from 'context/Messages'

import { Button } from 'components'
import Thread from '../Thread'

import styles from '../../dashboard.sidebar.module.scss'

type TProps = {
  threads: IThread[]
  loading: boolean
  fetchMoreData: () => void
  resetData: () => void
  nextStep: boolean
}

const Threads = ({ threads, loading, fetchMoreData, resetData, nextStep }: TProps) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'sidebar.threads' })
  const scrollArea = useRef(null)

  const [activeThreadGuid, setActiveThreadGuid] = useState<string>('')

  const handleResetData = () => {
    resetData()
    // @ts-ignore
    scrollArea.current.scrollTo(0, 0)
  }

  return (
    <>
      <Box
        mt={['5px']}
        mb={['25px']}
        className={styles.scrollbox}
        id="scrollableSidebarBlock"
        ref={scrollArea}
      >
        {threads.map((thread: IThread) => {
          return (
            <Thread
              thread={thread}
              activeThreadGuid={activeThreadGuid}
              setActiveThreadGuid={setActiveThreadGuid}
              resetData={handleResetData}
              key={thread.guid}
            />
          )
        })}
        {nextStep && (
          <Flex justifyContent={['center']} mt={[3]}>
            <Button onClick={fetchMoreData} size="small" disabled={loading} loading={loading}>
              {t('more')}
            </Button>
          </Flex>
        )}
      </Box>
    </>
  )
}

export default Threads
