import { createContext, useContext, Dispatch, FC, useReducer } from 'react'

import { useReducerLogger } from 'utils'

import { reducer, initialState, State } from './reducer'
import { ActionTypes } from './action-types'

interface IContextProps {
  state: State
  dispatch: Dispatch<{ type: keyof typeof ActionTypes; payload: any }>
}

const QuestionsContext = createContext<IContextProps>({} as IContextProps)

const QuestionsProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(
    useReducerLogger<State, { type: keyof typeof ActionTypes; payload: any }>(reducer),
    initialState
  )

  return (
    <QuestionsContext.Provider value={{ state, dispatch }}>{children}</QuestionsContext.Provider>
  )
}

const useQuestions = () => {
  const context = useContext(QuestionsContext)
  if (context === undefined) {
    throw new Error('useQuestions must be used within a QuestionsProvider')
  }
  return context
}

export { QuestionsProvider, useQuestions, ActionTypes }
