import { Button, Modal, Section, SpinnerBackdrop } from 'components'

import { useEffect, useState } from 'react'
import { message, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { TEntity, useEntityActions } from 'context/Entity'
import { TEntityUserInvite } from 'context/Invites/types'
import styles from '../../dashboard.entity.management.module.scss'
import InviteTaxFirmForm from '../InviteTaxFirmForm'

const { Title, Paragraph } = Typography

type Props = {
  entity: TEntity
}

const InviteTaxFirm = ({ entity }: Props) => {
  const [popoverVisible, setPopoverVisible] = useState(false)
  const [invite, setInvite] = useState<TEntityUserInvite | undefined>()
  const { t } = useTranslation('entity')
  const { t: t2 } = useTranslation('dashboard')
  const { t: t3 } = useTranslation('common')
  const {
    resendEntityCpaInvite,
    loading: loadingResend,
    response: responseResend,
  } = useEntityActions()
  const {
    revokeEntityCpaInvite,
    loading: loadingRevoke,
    response: responseRevoke,
  } = useEntityActions()
  const {
    getEntityTaxFirmInvites,
    loading: loadingInvites,
    response: responseInvites,
  } = useEntityActions()
  const [reloadFlag, setReloadFlag] = useState(false)

  const loadTaxFirmInvite = async () => {
    const taxFirmInvite = await getEntityTaxFirmInvites(entity.guid)
    if (responseInvites.ok) {
      setInvite(taxFirmInvite)
    }
  }

  const revokeTaxFirmInvite = async () => {
    await revokeEntityCpaInvite(entity.guid, invite!.guid)
    if (responseRevoke.ok) {
      message.success(t3('invitations.invitationStatus.rev'))
    }
    loadTaxFirmInvite()
  }

  const resendTaxFirmInvite = async () => {
    await resendEntityCpaInvite(entity.guid, invite!.guid)
    if (responseResend.ok) {
      message.success(t3('invitations.invitationStatus.res'))
    }
    loadTaxFirmInvite()
  }

  useEffect(() => {
    loadTaxFirmInvite()
  }, [entity, reloadFlag])

  return (
    <Section className={styles.section}>
      {loadingInvites && <SpinnerBackdrop />}
      <Title level={4} className={styles.section_title}>
        {t('taxFirm.taxFirm')}
      </Title>
      {invite ? (
        <>
          <Paragraph className={styles.section_description}>
            {t('taxFirm.invitationSentTo')} {invite.email}
          </Paragraph>
          <div className={styles.section_header}>
            <Button
              onClick={resendTaxFirmInvite}
              type="primary"
              size="large"
              loading={loadingResend}
              disabled={loadingResend}
            >
              {t2('clients.table.resend')}
            </Button>
            <Button
              onClick={revokeTaxFirmInvite}
              type="primary"
              size="large"
              loading={loadingRevoke}
            >
              {t2('clients.table.revoke')}
            </Button>
          </div>
        </>
      ) : (
        <>
          <Paragraph className={styles.section_description}>{t('taxFirm.description')}</Paragraph>
          <div>
            <Modal
              overlayClassName={styles.modal_invite}
              placement="left"
              content={
                <InviteTaxFirmForm
                  entity={entity}
                  setVisible={setPopoverVisible}
                  setReloadFlag={setReloadFlag}
                />
              }
              destroyTooltipOnHide
              trigger="click"
              visible={popoverVisible}
              onVisibleChange={(visible) => setPopoverVisible(visible)}
            >
              <Button type="primary" size="large" htmlType="submit" loading={false}>
                {t('taxFirm.inviteTaxPreparer')}
              </Button>
            </Modal>
          </div>
        </>
      )}
    </Section>
  )
}

export default InviteTaxFirm
