import { useFetch } from 'use-http'
import axios from 'axios'
import qs from 'qs'
import { omit } from 'lodash'

import { DocumentTag } from 'context/Dashboard'
import { toSnake } from 'utils'

import { Key } from 'antd/lib/table/interface'
import { RcFile } from 'antd/lib/upload'
import { ActionTypes } from './action-types'
import {
  TLoadDocumentsParams,
  TUploadDocumentParams,
  TUploadDocumentResponse,
  UpdateDocumentParams,
  TFileUploadOverrideProps,
  TMultipleDocumentParams,
} from '../types'

import {
  BodyUploadInvites,
  Invites,
  RequestInvitees,
  SharedInviteesRequest,
  useTaxOrganizerDispatch,
} from '..'

const useDocumentsActions = ({
  uploadUrl,
  deleteUrl,
  updateUrl,
}: TFileUploadOverrideProps = {}) => {
  const options = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }
  const dispatch = useTaxOrganizerDispatch()
  const { get, post, del, patch, response, loading, error } = useFetch('', options)

  const loadDocuments = async (id: string, params?: TLoadDocumentsParams) => {
    const query = qs.stringify(toSnake({ ...params }), { addQueryPrefix: !!params })
    const documents = await get(`/tax-organizers/${id}/documents${query}`)
    if (response.ok) {
      dispatch({ type: ActionTypes.LOAD_DOCUMENTS, payload: documents })
    }
  }

  const loadDocumentsSummary = async (id: string) => {
    const documentsSummary = await get(`/tax-organizers/${id}/documents/summary`)

    if (response.ok) {
      dispatch({ type: ActionTypes.LOAD_DOCUMENTS_SUMMARY, payload: documentsSummary })
    }
  }

  const loadDocument = async (taxOrganizerId: string, documentGuid: string) => {
    const document = await get(`/tax-organizers/${taxOrganizerId}/documents/${documentGuid}`)
    if (response.ok) {
      return {
        ...document,
        tags: document.tags.map((tag: DocumentTag): TOption => {
          return {
            label: tag.name,
            value: tag.slug,
          }
        }),
      }
    }
    return undefined
  }

  const deleteDocument = async (id: string, documentGuid: string) => {
    const deleteDocumentUrl = deleteUrl
      ? deleteUrl.replace(':taxDocumentId', documentGuid)
      : `/tax-organizers/${id}/documents/${documentGuid}`
    await del(deleteDocumentUrl)
    if (response.ok) {
      dispatch({ type: ActionTypes.DELETE_DOCUMENT, payload: { documentGuid } })
    }
  }

  const bulkDeteleDocuments = async (id: string, documentGuids: string[]) => {
    const deleteDocumentUrl = `tax-organizers/${id}/documents/batch-delete`
    await del(deleteDocumentUrl, { guids: documentGuids })
    if (response.ok) {
      dispatch({ type: ActionTypes.BULK_DELETE_DOCUMENTS, payload: { documentGuids } })
    }
  }

  const uploadDocument = async (
    taxOrganizerId: string,
    params: TUploadDocumentParams,
    {
      file,
      onUploadProgress,
    }: { file: RcFile | undefined; onUploadProgress?: (event: ProgressEvent) => void },
    meta?: any
  ) => {
    const documentUploadUrl = uploadUrl || `/tax-organizers/${taxOrganizerId}/documents`
    const document: TUploadDocumentResponse = await post(documentUploadUrl, toSnake(params))

    if (response.ok) {
      try {
        const axiosResponse = await axios.put(document.uploadUrl, file, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress,
        })
        if (axiosResponse.status === 200) {
          dispatch({
            type: ActionTypes.UPLOAD_DOCUMENT,
            payload: {
              ...document,
              uid: meta.uid,
              responseOk: response.ok,
              loading,
            },
          })
        }
      } catch (err) {
        deleteDocument(taxOrganizerId, document.guid)
        return null
      }
    }
    return document
  }

  const updateDocument = async (id: string, documentGuid: string, params: UpdateDocumentParams) => {
    const updateDocumentUrl = updateUrl || `/tax-organizers/${id}/documents/${documentGuid}`
    const updated = await patch(updateDocumentUrl, params)
    return updated
  }

  const setSelectedTableDocumentIds = (documentGuids: Key[]) => {
    dispatch({ type: ActionTypes.SET_SELECTED_TABLE_DOCUMENT_IDS, payload: documentGuids })
  }

  const loadDocumentShareInvites = async (inviteId: string, token: string) => {
    const result = await get(`/tax-organizers/document-share-invites/${inviteId}?token=${token}`)
    return result
  }

  const resendShareInvites = async (invitesGuid: string) => {
    await post(`tax-organizers/document-share-invites/${invitesGuid}`)
  }

  const createDocumentShareInvites = async (
    taxOrganizerId: string,
    body: SharedInviteesRequest
  ) => {
    const responseSharedInvitees = await post(
      `/tax-organizers/${taxOrganizerId}/document-share-invites`,
      toSnake(body)
    )

    return responseSharedInvitees
  }

  const loadDocumentUploadInvites = async (params: Invites) => {
    const documentResponse = await get(
      `tax-organizers/document-upload-invites/${params.invitationGuid}?token=${params.token}`
    )

    return documentResponse
  }

  const uploadRequestDocument = async (
    invitationGuid: string,
    body: BodyUploadInvites,
    { file, onUploadProgress }: { file: File; onUploadProgress?: (event: ProgressEvent) => void }
  ) => {
    const documentUploadUrl = `tax-organizers/document-upload-invites/${invitationGuid}`
    const document: TUploadDocumentResponse = await post(documentUploadUrl, toSnake(body))
    if (response.ok) {
      try {
        await axios.put(document.uploadUrl, file, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress,
        })
      } catch (err) {
        return null
      }
    }
    return document
  }

  const resendDocumentUploadInvites = async (invitationGuid: string) => {
    await post(`tax-organizers/document-upload-invites/${invitationGuid}/resend`)
  }

  const createDocumentUploadInvites = async (taxOrganizerId: string, body: RequestInvitees) => {
    const responseRequestInvitees = await post(
      `/tax-organizers/${taxOrganizerId}/document-upload-invites`,
      toSnake(body)
    )

    return responseRequestInvitees
  }

  const setMulptipleDocumentParams = (params: TMultipleDocumentParams | null) => {
    dispatch({ type: ActionTypes.SET_MULTIPLE_DOCUMENT_PARAMS, payload: params })
  }

  const removeMulptipleDocumentParam = (documentGuid: string) => {
    dispatch({ type: ActionTypes.REMOVE_MULTIPLE_DOCUMENT_PARAM, payload: documentGuid })
  }

  const clearMulptipleDocumentParams = () => {
    dispatch({ type: ActionTypes.CLEAR_MULTIPLE_DOCUMENT_PARAMS, payload: [] })
  }

  const clearUploaded = () => {
    dispatch({ type: ActionTypes.CLEAR_UPLOADED, payload: [] })
  }

  const updateDocuments = (taxOrganizerGuid: string, params: TMultipleDocumentParams[]) => {
    return Promise.all([
      ...params.map((docParam: TMultipleDocumentParams) => {
        const updateDocumentParams: UpdateDocumentParams = omit(docParam, [
          'guid',
          'validateStatus',
        ])
        return patch(
          `/tax-organizers/${taxOrganizerGuid}/documents/${docParam.guid}`,
          updateDocumentParams
        )
      }),
    ])
  }

  return {
    loadDocuments,
    loadDocument,
    uploadDocument,
    updateDocument,
    updateDocuments,
    deleteDocument,
    bulkDeteleDocuments,
    loadDocumentUploadInvites,
    uploadRequestDocument,
    resendDocumentUploadInvites,
    loadDocumentsSummary,
    loadDocumentShareInvites,
    resendShareInvites,
    setSelectedTableDocumentIds,
    createDocumentShareInvites,
    createDocumentUploadInvites,
    setMulptipleDocumentParams,
    removeMulptipleDocumentParam,
    clearMulptipleDocumentParams,
    clearUploaded,
    loading,
    response,
    error,
  }
}

export default useDocumentsActions
export { ActionTypes }
