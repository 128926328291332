import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { ColumnsType } from 'antd/lib/table'
import { Avatar, Button, Dropdown, Menu, Typography } from 'antd'
import { getInitials } from 'utils'
import { TCPAContact } from 'context/TaxOrganizer'
import { DashOutlined, LoadingOutlined } from '@ant-design/icons'
import { useEntityActions, TEntity } from 'context/Entity'
import styles from 'pages/Dashboard/EntityManagement/dashboard.entity.management.module.scss'
import { DEFAULT_CONTACTS_PARAMS } from '../constants'

const { Text, Paragraph } = Typography

type Props = {
  cpaFirm: TCpaFirm
  entity: TEntity
  setReloadUsersFlag: (prev: boolean | ((prev: boolean) => boolean)) => void
  setReloadContactsFlag: (prev: boolean | ((prev: boolean) => boolean)) => void
}

export default ({ entity, cpaFirm, setReloadUsersFlag, setReloadContactsFlag }: Props) => {
  const { t } = useTranslation('entity')
  const { t: t2 } = useTranslation('common')
  const { assignCPAUser, loadEntityCPAContacts, loading: loadingEntityCPA } = useEntityActions()
  const { unassignCPAUser, response, loading: loadingUnassing } = useEntityActions()
  const currentUser = useRef<string | null>(null)

  const makeUserPrimary = async (userId: string) => {
    await assignCPAUser(entity.guid, cpaFirm.guid, userId, { isPrimary: true })
    loadEntityCPAContacts(entity.guid, cpaFirm.guid, DEFAULT_CONTACTS_PARAMS)
  }

  const columns: ColumnsType<TCPAContact> = [
    {
      title: t('common.firstName'),
      key: 'user.firstName',
      sortDirections: ['ascend', 'descend'],
      width: 120,
      render: ({ user }) => (
        <>
          {user.avatar ? (
            <Avatar size={24} src={user.avatar} />
          ) : (
            <Avatar size={24}>{getInitials(user)}</Avatar>
          )}
          <Text className={styles.section_name}>{user.firstName}</Text>
        </>
      ),
    },
    {
      title: t('common.lastName'),
      key: 'user.lastName',
      width: 120,
      render: ({ user }) => <Text>{user.lastName}</Text>,
    },
    {
      title: t('common.email'),
      key: 'user.email',
      ellipsis: true,
      width: 300,
      render: ({ user }) => (
        <Paragraph
          className="text-nowrap"
          ellipsis={{ rows: 1, expandable: true, symbol: 'more' }}
          title={user.email}
          style={{ margin: 0 }}
        >
          {user.email}
        </Paragraph>
      ),
    },
    {
      title: ' ',
      key: 'user.isPrimary',
      width: 100,
      render: ({ isPrimary }) => (
        <Text className={styles.users_primary}>{isPrimary && t('taxFirm.primary')}</Text>
      ),
    },
    {
      title: t2('buttons.actions'),
      key: 'user.isPrimary',
      align: 'center',
      width: 100,
      render: ({ user }) => (
        <Dropdown
          overlay={() => (
            <Menu theme="dark">
              <Menu.Item onClick={() => makeUserPrimary(user.guid)} key="settings">
                {t('taxFirm.makePrimary')}
              </Menu.Item>
              <Menu.Item
                onClick={async () => {
                  currentUser.current = user.guid
                  await unassignCPAUser(entity.guid, cpaFirm.guid, user.guid)
                  if (response.ok) {
                    setReloadUsersFlag((prevFlag: boolean) => !prevFlag)
                    setReloadContactsFlag((prevFlag: boolean) => !prevFlag)
                  }
                }}
                disabled={loadingUnassing}
                // icon={loadingUnassing && <LoadingOutlined />}
                key="profile"
              >
                {t('taxFirm.unassign')}
              </Menu.Item>
            </Menu>
          )}
          trigger={['click']}
          placement="bottomLeft"
        >
          <Button type="text">
            {loadingUnassing && currentUser.current === user.guid ? (
              <LoadingOutlined />
            ) : (
              <DashOutlined className={styles.actions} />
            )}
          </Button>
        </Dropdown>
      ),
    },
  ]

  return { columns, loadingEntityCPA }
}
