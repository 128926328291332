import { useEffect, useState, useCallback } from 'react'
import { useFetch } from 'use-http'
import qs from 'qs'
import { toSnake } from 'utils'
import { useReactToPrint } from 'react-to-print'
import { useTranslation } from 'react-i18next'

import { TQQuestionType, useQuestions } from 'context/Questions'
import { useTaxOrganizer } from 'context/TaxOrganizer'

import { TPrintResume } from '../types'

type TProps = {
  componentRef: { current: HTMLDivElement | null }
}

export default ({ componentRef }: TProps) => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'questionnaire' })
  const { get } = useFetch()
  const {
    taxOrganizer: {
      data: {
        guid,
        entity: { kind, title },
        taxYear,
      },
    },
  } = useTaxOrganizer()
  const {
    state: { categories },
  } = useQuestions()
  const [resume, setResume] = useState<TPrintResume>()
  const [loading, setLoading] = useState(false)

  const startPrinting = useCallback(async () => {
    setLoading(true)
    const questions = await Promise.all(
      categories.map(async (category) => {
        const params = {
          category: category.guid,
          taxOrganizer: guid,
          entityKind: kind.label,
          taxYear,
        }
        const query = qs.stringify(toSnake({ ...params }), { addQueryPrefix: !!params })
        const results = get(`tax-organizers/tq-questions${query}`)
        return results
      })
    )

    const questionsWithCategories = questions.map(
      (questionsWithoutCategory: TQQuestionType[], i: number) => {
        return {
          category: categories[i].name,
          questions: questionsWithoutCategory,
        }
      }
    )

    const dataToPrint: TPrintResume = {
      entityName: title,
      taxYear,
      questions: questionsWithCategories,
    }
    setResume(dataToPrint)
    setLoading(false)
  }, [categories, get, setResume])

  const handleAfterPrint = useCallback(() => {
    setResume(undefined)
  }, [])

  const reactToPrintContent = useCallback(() => {
    return componentRef.current
  }, [componentRef.current])

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: `${title}. ${t('questionnaire')}`,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
    copyStyles: false,
    pageStyle: `
    @page {
        /* Remove browser default header (title) and footer (url) */
        margin: 7mm;        
    }
    @media print {
        body {
            /* Tell browsers to print background colors */
            -webkit-print-color-adjust: exact; /* Chrome/Safari/Edge/Opera */
            color-adjust: exact; /* Firefox */
        }
       table { width: 100%; text-align: left; margin: 0 10px; } 
       thead { padding-bottom: 20px; } 
       tr { page-break-inside: avoid; page-break-after: auto; } 
       .print-header { font-size: 18px; font-weight: 700; } 
       .print-cat { font-size: 18px; font-weight: 300; } 
       .print-qst-title { font-size: 14px; font-weight: 700; margin: 20px 5px 5px 10px; } 
       .print-qst-checkboxes { font-size: 14px; font-weight: 400; margin: 20px 5px 10px 10px; } 
       .print-qst-response { font-size: 14px; font-weight: 400; margin: 7px 5px 10px 14px; } 
       .print-qst-documents { font-size: 12px; font-weight: 400; margin: 0px 5px 5px 14px; }
       .print-no-qst { font-size: 14px; font-weight: 400; margin: 20px 5px 5px 10px; color: lightgray }
       .print-no-responses { font-size: 14px; font-weight: 400; margin: 20px 5px 5px 24px; color: lightgray }
    }
`,
  })

  useEffect(() => {
    if (resume) handlePrint()
  }, [resume])

  return {
    startPrinting,
    loading,
    resume,
  }
}
