import { TTaxOrganizersParams, TTaxSummaryParams } from 'context/Dashboard'
import { useState } from 'react'
import { DEFAULT_TAX_PARAMS, DEFAULT_SUMMARY_PARAMS } from '../constants'

export default () => {
  const [summaryParams, setSummaryParams] = useState<TTaxSummaryParams>(DEFAULT_SUMMARY_PARAMS)
  const [taxParams, setTaxParams] = useState<TTaxOrganizersParams>(DEFAULT_TAX_PARAMS)
  const [showTable, setShowTable] = useState(false)

  return { summaryParams, setSummaryParams, taxParams, setTaxParams, showTable, setShowTable }
}
