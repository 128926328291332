import { Table } from 'components'
import { useEffect, useState } from 'react'

import { useAuth } from 'context/Auth'
import { useCPAFirm, useCPAFirmActions } from 'context/CPAFirm'

import { PAGE_SIZE } from 'constants/table'

import useColumns from './hooks/useColumns'

const DEFAULT_PARAMS: Readonly<TParams> = {
  page: 1,
  pageSize: PAGE_SIZE,
}

const Members = () => {
  const [currentPage, setCurrentPage] = useState<number | undefined>(DEFAULT_PARAMS.page)
  const [params, setParams] = useState(DEFAULT_PARAMS)
  const {
    members: { data, total },
  } = useCPAFirm()
  const {
    CPA: { cpaFirm },
  } = useAuth()
  const { loadCpaMembers, loadCpaJobTitles, loading } = useCPAFirmActions()
  const { columns } = useColumns()
  const dataSource = data.map((member) => ({ ...member, key: member.user.guid }))

  const handlePageChange = (page: number) => {
    setParams((prevParams: TParams) => {
      setCurrentPage(page)
      return { ...prevParams, page }
    })
  }

  useEffect(() => {
    loadCpaJobTitles()
  }, [])

  useEffect(() => {
    loadCpaMembers(cpaFirm!.guid, params)
  }, [params])

  return (
    <Table
      table={{
        dataSource,
        columns,
        loading,
      }}
      pagination={{ total, onChange: handlePageChange, current: currentPage }}
    />
  )
}

export default Members
