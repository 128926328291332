import { Form, Radio } from 'antd'
import { TextField, Button } from 'components'
import { useTranslation } from 'react-i18next'
import { Box } from 'reflexbox'
import { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input'
import { useState, FC } from 'react'

import { useEntityCPAsActions, useEntityCPAs, SendContactInformationForm } from 'context/EntityCPAs'

import styles from './contact.information.module.scss'

const ContactInformation: FC = () => {
  const { t } = useTranslation('common', { keyPrefix: 'form' })
  const { t: t2 } = useTranslation('common', { keyPrefix: 'request' })
  const [phoneRequired, setPhoneRequired] = useState(false)
  const [form] = Form.useForm()
  const { prospectiveClients } = useEntityCPAsActions()
  const {
    state: {
      CPAFirmProfile: { guid },
    },
  } = useEntityCPAs()

  const onSubmit = async (values: SendContactInformationForm) => {
    form.resetFields()
    setPhoneRequired(false)
    await prospectiveClients({
      cpaFirm: guid!,
      email: values.email,
      phone: values.phone,
      preferredContactMethod: values.preferredContactMethod,
    })
  }

  const handlePreferredContactMethod = (e: any) => {
    const preferredContactMethodValue = e.target.value
    if (preferredContactMethodValue === 'PHONE') {
      setPhoneRequired(true)
    } else if (preferredContactMethodValue === 'EMAIL') {
      setPhoneRequired(false)
    }
  }

  return (
    <>
      <Form
        className={styles.form}
        onFinish={onSubmit}
        form={form}
        initialValues={{ preferredContactMethod: 'EMAIL' }}
      >
        <Box textAlign={['center']} my={[4]} fontSize={['14px']} fontWeight={[500]}>
          {t('contactInformationT')}
        </Box>
        <Form.Item
          name="email"
          rules={[
            { required: true, message: t('requiredField', { field: t('email') }) },
            { type: 'email', message: t('invalidField', { field: t('email') }) },
          ]}
        >
          <TextField placeholder={t('enterEmail')} size="large" className={styles.input} />
        </Form.Item>
        <Form.Item
          name="phone"
          rules={[
            { required: phoneRequired, message: t('requiredField', { field: t('phone') }) },
            () => ({
              validator(_, value) {
                if (!value) {
                  return Promise.resolve()
                }
                if (value && isValidPhoneNumber(value) && isPossiblePhoneNumber(value)) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error(t('phoneInvalid')))
              },
            }),
          ]}
        >
          <TextField placeholder={t('enterPhone')} size="large" className={styles.input} />
        </Form.Item>
        <Box textAlign={['left']} my={[4]} fontSize={['14px']} fontWeight={[500]}>
          {t('PreferredContactMethod')}
        </Box>
        <Form.Item
          name="preferredContactMethod"
          rules={[{ required: true, message: t('requiredField', { field: t('userType') }) }]}
        >
          <Radio.Group
            buttonStyle="solid"
            size="large"
            className={styles.contact}
            onChange={handlePreferredContactMethod}
          >
            <Radio style={{ width: 'auto', textAlign: 'center' }} value="EMAIL">
              {t('email')}
            </Radio>
            <Radio style={{ width: 'auto', textAlign: 'center', marginLeft: '15%' }} value="PHONE">
              {t('phone')}
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="primary" size="large" block>
            {t2('buttonsText')}
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default ContactInformation
