import { useAuth } from 'context/Auth'
import { useTranslation } from 'react-i18next'

const HeaderUserInvitations = () => {
  const {
    invitationsFlow: { inviteInfo },
  } = useAuth()
  const { t } = useTranslation('common', { keyPrefix: 'register' })
  return (
    <>
      <div className="text-title">{inviteInfo?.cpaFirm.name}</div>
      <div className="text-subtitle my-3">{t('joinRepositax')}</div>
    </>
  )
}

export default HeaderUserInvitations
