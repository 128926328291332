import { SetStateAction, useCallback } from 'react'
import { Box, Flex } from 'reflexbox'
import { Form, FormInstance } from 'antd'
import { keys, omit } from 'lodash'
import { useTranslation } from 'react-i18next'

import { Button, Select } from 'components'

import { DEFAULT_FILTERS, DEFAULT_PARAMS, FILTERS } from '../constatnts'
import { TFilters } from '../types'

type TProps = {
  form: FormInstance
  setParams: (params: SetStateAction<TLoadEntitiesParams>) => void
  setModalFiltersVisible: (visible: boolean) => void
  disableButton: boolean
  entitiesPrimaryContactsOptions: TOption[]
  setCurrentPage: (page?: number) => void
  setFilters: (filters: SetStateAction<TFilters>) => void
  filters: TFilters
}

const FiltersModalContent = ({
  form,
  setParams,
  setModalFiltersVisible,
  disableButton,
  entitiesPrimaryContactsOptions,
  setCurrentPage,
  setFilters,
  filters,
}: TProps) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'clients' })
  const { t: t2 } = useTranslation('common', { keyPrefix: 'select' })

  const setFirstPageParams = useCallback(
    (prevParams: TLoadEntitiesParams) => ({ ...prevParams, page: DEFAULT_PARAMS.page }),
    [filters]
  )

  const handleSelect = (newFilter: TFilters) => {
    const key = keys(newFilter)[0]
    if (!newFilter[key as keyof TFilters]) return
    setFilters((prevFilters: TFilters) => ({
      ...prevFilters,
      ...newFilter,
    }))
  }

  const handleClearSelect = (key: string) => {
    setParams((prevParams: TLoadEntitiesParams) => omit(prevParams, [key]))
  }

  const handleApplyForm = () => {
    setModalFiltersVisible(false)
    setCurrentPage(DEFAULT_PARAMS.page)
    setParams((prevParams: TLoadEntitiesParams) => ({
      ...omit(setFirstPageParams(prevParams), FILTERS),
      ...filters,
    }))
  }

  const handleApplyClearForm = () => {
    form.resetFields()
    setFilters(DEFAULT_FILTERS)
    setCurrentPage(DEFAULT_PARAMS.page)
    setModalFiltersVisible(false)
    setParams((prevParams: TLoadEntitiesParams) => omit(setFirstPageParams(prevParams), FILTERS))
  }

  const handleValuesChange = (valueObj: { [key: string]: string }) => {
    const key = keys(valueObj)[0]
    if (!valueObj[key]) {
      setFilters((prevFilters: TFilters) => omit(prevFilters, [key]))
    }
  }

  return (
    <>
      <Form form={form} onValuesChange={handleValuesChange}>
        <Flex flexDirection={['column']} p={[2]} minWidth={['200px']}>
          <Box mb={[1]}>
            <Form.Item name="taxPreparer" key="taxPreparer" style={{ margin: 0 }}>
              <Select
                allowClearOption
                placeholder={t('head.taxPreparer')}
                options={[]}
                form={form}
                dropdownMatchSelectWidth={false}
                style={{ width: '100%' }}
                disabled
              />
            </Form.Item>
          </Box>

          <Box mb={[3]}>
            <Form.Item name="primaryContact" key="primaryContact" style={{ margin: 0 }}>
              <Select
                placeholder={t('table.primaryContact')}
                options={entitiesPrimaryContactsOptions}
                dropdownMatchSelectWidth={false}
                allowClearOption
                form={form}
                style={{ width: '100%' }}
                onSelect={(primaryContact: string) => handleSelect({ primaryContact })}
                onClear={() => handleClearSelect('primaryContact')}
              />
            </Form.Item>
          </Box>
          <Box width={['100%']} mb={[1]}>
            <Button block onClick={handleApplyForm} type="primary" disabled={disableButton}>
              {t2('apply')}
            </Button>
          </Box>
          <Box width={['100%']}>
            <Button block onClick={handleApplyClearForm} disabled={disableButton}>
              {t2('clearAll')}
            </Button>
          </Box>
        </Flex>
      </Form>
    </>
  )
}

export default FiltersModalContent
