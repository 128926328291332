import { useTranslation } from 'react-i18next'
import { Box } from 'reflexbox'
import { ColumnsType } from 'antd/lib/table'
import { Link } from 'react-router-dom'

import { TEntity } from 'context/Entity'

import { User } from 'components'

import { getEntityPathById } from 'utils/routes'

import colors from 'assets/styles/variables.module.scss'

export default () => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'clients.table' })

  const columns: ColumnsType<TEntity> = [
    {
      title: <Box fontWeight="bold">{t('entity')}</Box>,
      dataIndex: 'title',
      key: 'entity__title',
      sorter: true,
      width: '25%',
      ellipsis: true,
      render: (title: string, record: any) => (
        <Link to={getEntityPathById(record?.guid)} className="text-black text-bold">
          <div className="text-black">
            <Box fontWeight="bold">{title}</Box>
          </div>
        </Link>
      ),
    },
    {
      title: t('type'),
      dataIndex: 'kind',
      key: 'kind__title',
      sorter: true,
      render: (kind: { label: string; title: string }) => kind.label,
    },
    {
      title: t('taxPreparer'),
      dataIndex: 'cpaContact',
      key: 'primary_cpa_contact__first_name',
      sorter: true,
      render: (cpaContact: ICpaContact) => {
        if (!cpaContact) return null
        const {
          user: { firstName, lastName, avatar },
        } = cpaContact
        return (
          <User username={`${firstName} ${lastName}`} bgColor={colors.tag5} avatarLink={avatar} />
        )
      },
    },
  ]

  return { columns }
}
