import { PAGE_SIZE } from 'constants/table'
import { TFilters } from '../types'

export const FILTERS = ['primaryContact'] as const

export const DEFAULT_PARAMS: Readonly<TLoadEntitiesParams> = {
  page: 1,
  isActive: undefined,
  pageSize: PAGE_SIZE,
  ordering: undefined,
  search: undefined,
}

export const DEFAULT_FILTERS: Readonly<TFilters> = {}
export const DEFAULT_PARAM_FILTERS: Readonly<TFilters> = {
  primaryContact: undefined,
}
