import { useCallback, useEffect, useState } from 'react'
import { useStripe } from '@stripe/react-stripe-js'
import { Box, Flex } from 'reflexbox'
import { useTranslation } from 'react-i18next'

import { VerificationSession, VerificationStatus } from 'context/TaxReturns'
import { Button } from 'antd'

type TProps = {
  verficationSession: VerificationSession | null
  signerStatus: VerificationStatus | null
}

const Verify = ({ verficationSession, signerStatus }: TProps) => {
  const stripe = useStripe()

  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'taxReturns.verify' })
  const [errorMessage, setErrorMessage] = useState<string | undefined>('')

  const handleSubmit = useCallback(async () => {
    if (!stripe || !verficationSession) {
      // NOTE: This is mostly for TS checking as users cannot click submit in this state.
      return
    }

    // Show the verification modal.
    const { error } = await stripe.verifyIdentity(verficationSession.clientSecret)

    if (error) {
      console.log('[error]', error)
      // set verified to false (or errror?)
      setErrorMessage(error.message)
    } else {
      console.log('Verification submitted!')
    }
  }, [stripe])

  useEffect(() => {
    handleSubmit()
  }, [stripe])

  return (
    <Flex
      width={['100%', 'auto', 'auto', '720px']}
      flexDirection={['column', 'row']}
      alignItems={['start', 'start']}
      justifyContent={['space-between']}
      mt={[0, 0, 2, 0]}
    >
      <Box>
        {signerStatus === VerificationStatus.PROCESSING ||
        signerStatus === VerificationStatus.REQUIRES_INPUT ||
        signerStatus === VerificationStatus.CANCELED ||
        errorMessage ? (
          <>
            <h1>{t('title')}</h1>
            <p>
              {signerStatus === VerificationStatus.PROCESSING
                ? t('processing')
                : 'We could not verify your identiy, please contact your Tax Preparer'}
            </p>
          </>
        ) : (
          <>
            <h1>{t('verifyTitle')}</h1>
            <p>{t('waiting')}</p>
            <Button onClick={() => handleSubmit()}>Manually Trigger Verification</Button>
          </>
        )}
      </Box>
    </Flex>
  )
}

export default Verify
