import { Key } from 'react'
import { ActionTypes } from './action-types'

export enum EnumTinKind {
  EIN = 'EIN',
  SSN = 'SSN',
}

export type UnsentInvitationFilesType = {
  file: string
  guid: string
  updatedOn: string
  createdOn: string
}

export type TCPAEntity = {
  guid: string
  title: string
  kind: {
    label: string
    title: string
  }
  address: TAddress
  naicsCode: string | null
  businessEndedDate?: string | null
  businessStartedDate?: string | null
  primaryContact: IContact
  taxOrganizers: {
    guid: string
    taxYear: number
  }[]
}

export type TState = {
  entities: {
    data: TCPAEntity[]
    total: number | undefined
  }
  clientInvites: {
    data: IClientInvite[]
    total: number | undefined
    search: {
      data: IClientInvite[]
      total: number
    }
  }
  entitiesPrimaryContacts: IContact[]
  unsentInvitationFiles: UnsentInvitationFilesType[] | undefined
  clientSummary: {
    clientsActive: number
    clientsInactive: number
    invitationsPending: number
    invitationsUnsent: number
  }
  CPAFirmProfile: {
    address: TAddress | undefined
    guid: string | undefined
    letterheadImage: string | undefined
    name: string | undefined
    phone: string | undefined
  }
}

export type TAction = {
  type: keyof typeof ActionTypes
  payload?: any
}

export type TInviteParams = {
  email: string
  message: string
  entities: [
    {
      name: string
      tin: string
      kind: string
    }
  ]
}

export type TClientInviteStatus = 'acc' | 'pen' | 'rev' | 'uns'

export type TClientInviteParams = {
  ordering?: string
  page?: number
  pageSize?: number
  search?: string
  status?: TClientInviteStatus
}

export type SendUninvitedParamsType = { all?: boolean; guids?: Key[] }

export type SendContactInformationForm = {
  email: string
  phone?: string
  preferredContactMethod: 'EMAIL' | 'PHONE'
  additionalInfo?: string
}

export interface prospectiveClientsParams extends SendContactInformationForm {
  cpaFirm: string
}
