import { useTranslation } from 'react-i18next'
import { Box, Flex } from 'reflexbox'

type TProps = {
  password: string
}

const PasswordTooltipContent = ({ password }: TProps) => {
  const { t } = useTranslation('common', { keyPrefix: 'form' })
  const lengthStatus = !/^.{0,7}$/.test(password)
  const notOnlyNumbers = /^(?!^\d+$)^.+$/.test(password)

  return (
    <Flex flexDirection={['column']} py={['10px']} px={['5px']}>
      <Box
        mb={[1]}
        fontSize={['12px']}
        fontWeight={[600]}
        className={password.length ? (lengthStatus ? 'text-success' : 'text-error') : ''}
      >
        {t('passwordCheck.lengthStatus')}
      </Box>
      <Box
        fontSize={['12px']}
        fontWeight={[600]}
        className={password.length ? (notOnlyNumbers ? 'text-success' : 'text-error') : ''}
      >
        {t('passwordCheck.notOnlyNumbers')}
      </Box>
    </Flex>
  )
}

export default PasswordTooltipContent
