import { TClientInviteParams } from 'context/EntityCPAs'
import { useState } from 'react'

type PropsType<T> = {
  defaultParams: T
}

export default <T extends TClientInviteParams | TLoadEntitiesParams | TParams>({
  defaultParams,
}: PropsType<T>) => {
  const [params, setParams] = useState<T>(defaultParams)
  const [currentPage, setCurrentPage] = useState<number | undefined>(defaultParams.page)

  const handleSearch = (search: string | undefined) => {
    setParams((prevParams: T) => {
      setCurrentPage(defaultParams.page)
      return {
        ...prevParams,
        page: defaultParams.page,
        search,
      }
    })
  }

  const handlePageChange = (page: number) => {
    setParams((prevParams: T) => {
      setCurrentPage(page)
      return { ...prevParams, page }
    })
  }

  return { params, setParams, currentPage, setCurrentPage, handleSearch, handlePageChange }
}
