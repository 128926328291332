import { Switch, Route, Redirect } from 'react-router-dom'
import { Box } from 'reflexbox'

import List from '../../List'

const Router = () => {
  return (
    <>
      <Box mt={[1, 1, 3]}>
        <Switch>
          <Route path="/dashboard/entities" exact>
            <List />
          </Route>
          <Route path="*">
            <Redirect to="/dashboard" />
          </Route>
        </Switch>
      </Box>
    </>
  )
}

export default Router
