import { Flex } from 'reflexbox'

import { TChildCategories } from 'context/TaxReturns'
import SubCategoryContent from './SubCategoryContent'

type TProps = {
  state: string
  subCategories: TChildCategories[]
}

const ModalSubCategories = ({ state, subCategories }: TProps) => {
  return (
    <Flex mt={[0, 2, 0]} flexDirection={['column']} alignItems={['stretch']}>
      {subCategories.map((subCategory) => (
        <SubCategoryContent key={subCategory.slug} state={state} subCategory={subCategory} />
      ))}
    </Flex>
  )
}

export default ModalSubCategories
