import { SyntheticEvent, useEffect, useState } from 'react'
import { uniq } from 'lodash'
import { Box, Flex } from 'reflexbox'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'

import { Button, Modal } from 'components'

import {
  TTaxReturnCategory,
  TTaxReturnPage,
  TTaxReturn,
  useTaxReturns,
  useTaxReturnsActions,
} from 'context/TaxReturns'
import { getPagesRequestBodyForResetUncategorized } from 'utils/categorize'

import ModalContent from './components/ModalContent'
import StateSubCategory from './components/StateSubCategory'

import styles from '../../categorize.module.scss'

type TProps = {
  title: string
  documentsCount: number
  isShowTags: boolean
  states: { category: TTaxReturnCategory; categoryPages: TTaxReturnPage[] }
}

const StatesHeader = ({ title, states, documentsCount, isShowTags }: TProps) => {
  const { t } = useTranslation('taxOrganizer')
  const { updateTaxReturn, updateTaxReturnPages, setSelectedTags, setLoading } =
    useTaxReturnsActions()
  const {
    state: { selectedTags, taxReturns, pages, loading },
  } = useTaxReturns()
  const isTablet = useMediaQuery({ query: '(max-width: 52em)' })
  const [modalVisible, setModalVisible] = useState(false)
  const [hoverCurrentState, setHoverCurrentState] = useState<string>('')

  const handleVisibleChange = (visible: boolean) => {
    setModalVisible(visible)
  }

  const handelSelectedTags = async (tags: string[]) => {
    setModalVisible(false)

    if (taxReturns.data.length) {
      const patchTaxReturnBody: Partial<TTaxReturn> = {
        ...taxReturns.latest,
        taxableStates: tags,
      }

      const pagesNotTaxableStates = pages.filter(
        (page) => page.parentCategory === 'state' && !tags.includes(page.state)
      )
      setLoading(true)
      if (pagesNotTaxableStates.length) {
        await updateTaxReturnPages(getPagesRequestBodyForResetUncategorized(pagesNotTaxableStates))
      }

      await updateTaxReturn(taxReturns.latest!.guid, patchTaxReturnBody)
      setLoading(false)
    }
  }

  const handleAdd = (event: SyntheticEvent) => {
    event.stopPropagation()
  }

  useEffect(() => {
    const tags = uniq(states.categoryPages.map((state) => state.state.toUpperCase()))
    setSelectedTags(uniq(selectedTags.concat(tags)))
  }, [states])

  return (
    <>
      <Flex
        justifyContent={['flex-start', 'flex-start', 'space-between']}
        alignItems={['flex-start', 'flex-start', 'center']}
        width={['100%']}
        flexDirection={['column', 'column', 'row']}
      >
        {' '}
        <Flex alignItems={['center']} flexShrink={[0]}>
          <Box fontSize={['20px']} lineHeight={['24px']} fontWeight={[600]}>
            {title}
          </Box>
          {!!documentsCount && <span className={styles.label}>{documentsCount}</span>}
        </Flex>
        {!selectedTags.length && (
          <Box mt={[3, 3, 0]} fontSize={['14px']} lineHeight={['14px']}>
            {t('statesModal.selectState')}
          </Box>
        )}
        <Flex
          px={[0, 0, 2]}
          mt={[2, 2, 0]}
          flexShrink={[1]}
          flexDirection={['row']}
          alignItems={['flex-start', 'flex-start', 'center']}
          width={['100%', 'auto']}
          flexWrap={['wrap']}
        >
          {!!selectedTags.length && isShowTags && (
            <Flex flexWrap={['wrap']} mt={[1, 1, 0]}>
              {selectedTags.map((selectedTag: string) => (
                <StateSubCategory
                  key={selectedTag.toLowerCase()}
                  subCategory={states.category.childCategories}
                  state={selectedTag}
                  hoverCurrentState={hoverCurrentState}
                  setCurrentState={setHoverCurrentState}
                />
              ))}
            </Flex>
          )}
          <Flex
            mr={[2, 2, 0]}
            ml={[0, 2, 4]}
            mt={[2, 1, 0]}
            flexShrink={[0]}
            width={['100%', 'auto']}
          >
            <Modal
              placement={isTablet ? 'right' : 'left'}
              content={
                <ModalContent
                  states={selectedTags}
                  setSelectedTags={handelSelectedTags}
                  loading={loading}
                />
              }
              trigger="click"
              destroyTooltipOnHide
              visible={modalVisible}
              onVisibleChange={handleVisibleChange}
            >
              <Button type="primary" size="large" block ghost onClick={handleAdd}>
                {!selectedTags.length ? t('states.select') : t('states.edit')}
              </Button>
            </Modal>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}

export default StatesHeader
