import { useTranslation } from 'react-i18next'
import { Box } from 'reflexbox'
import { ColumnsType } from 'antd/lib/table'
import { message } from 'antd'

import { formatTin } from 'utils/entity'

import { TEntity } from 'context/Entity'
import { TEntityInvite } from 'context/Invites/types'
import { useInviteActions } from 'context/Invites'

import ActionsCell from '../../ActionsCell'

export default () => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'clients.table' })
  const { t: t2 } = useTranslation('common', { keyPrefix: 'invitations.invitationStatus' })
  const { acceptEntityInvitation, response: responseAccept } = useInviteActions()
  const { declineEntityInvitation, response: responseReject } = useInviteActions()

  const columns: ColumnsType<TEntity> = [
    {
      title: <Box fontWeight="bold">{t('inviteBy')}</Box>,
      dataIndex: 'cpaFirm',
      key: 'cpaFirm',
      sorter: (a: TEntity, b: TEntity) => a.title?.localeCompare(b.title),
      sortDirections: ['ascend'],
      ellipsis: true,
      render: ({ name }) => (
        <Box fontWeight="bold" className="text-black">
          {name}
        </Box>
      ),
    },
    {
      title: t('tin'),
      dataIndex: 'entity',
      key: 'tin',
      render: ({ tin, tinKind }) => (
        <div style={{ whiteSpace: 'nowrap' }}>{formatTin(tin, tinKind)}</div>
      ),
    },
    {
      title: t('extendedTo'),
      dataIndex: ['entity', 'name'],
      key: 'extended',
      render: (name: string) => <div style={{ whiteSpace: 'nowrap' }}>{name}</div>,
    },
    {
      title: t('actions'),
      key: 'actions',
      render: (invitation: TEntityInvite) => (
        <ActionsCell
          acceptAction={async () => {
            await acceptEntityInvitation(
              invitation.cpaFirm.guid,
              invitation.guid,
              invitation.entity.guid
            )
            if (responseAccept.ok) {
              message.success(t2('acc'))
            }
          }}
          declineAction={async () => {
            await declineEntityInvitation(
              invitation.cpaFirm.guid,
              invitation.guid,
              invitation.entity.guid
            )
            if (responseReject.ok) {
              message.success(t2('rev'))
            }
          }}
        />
      ),
    },
  ]

  return { columns }
}
