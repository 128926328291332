import { useTranslation } from 'react-i18next'

export default () => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'user.settings' })
  const totalOptions = [
    {
      label: t(`individual`),
      value: 'INDIVIDUAL',
    },
    {
      label: t(`hourly`),
      value: 'HOURLY',
    },
    {
      label: t(`daily`),
      value: 'DAILY',
    },
    {
      label: t(`none`),
      value: 'NONE',
    },
  ]
  return totalOptions
}
