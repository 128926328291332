import { useFetch } from 'use-http'
import { message } from 'antd'
import qs from 'qs'
import { useTranslation } from 'react-i18next'
import * as convert from 'convert-keys'

import { toSnake } from 'utils'

import { PAGE_SIZE } from 'constants/table'

import { getMessageFromResponse } from 'utils/http'
import { ActionTypes } from './action-types'
import {
  SendUninvitedParamsType,
  TClientInviteParams,
  TClientInviteStatus,
  prospectiveClientsParams,
} from './types'

import { useEntityCPAs } from './context'

import { TInviteParams } from '.'

const useEntityCPAsActions = () => {
  const { dispatch } = useEntityCPAs()
  const { loading, error, response, get, post, patch, del } = useFetch()
  const { t } = useTranslation('dashboard', { keyPrefix: 'clients.table' })

  const loadClientSummary = async (cpaFirmGuid: string) => {
    const summary = await get(`/cpas/cpa-firms/${cpaFirmGuid}/clients-summary`)
    if (response.ok) {
      dispatch({
        type: ActionTypes.LOAD_CLIENT_SUMMARY,
        payload: {
          clientsActive: summary.clientsActive,
          clientsInactive: summary.clientsInactive,
          invitationsPending: summary.invitationsPending,
          invitationsUnsent: summary.invitationsUnsent,
        },
      })
    }
  }

  const loadClients = async (cpaFirmGuid: string, params: TLoadEntitiesParams) => {
    const entities = await get(
      `/cpas/cpa-firms/${cpaFirmGuid}/entities${qs.stringify(toSnake(params), {
        addQueryPrefix: !!params,
      })}`
    )
    if (response.ok) {
      dispatch({
        type: ActionTypes.LOAD_CPA_ENTITIES,
        payload: { data: entities.results, total: entities.count },
      })
    }
  }

  const loadCpaClientInvites = async (cpaFirmGuid: string, params: TClientInviteParams) => {
    const clientInvites = await get(
      `/cpas/cpa-firms/${cpaFirmGuid}/client-invites?${qs.stringify(toSnake(params))}`
    )
    if (response.ok) {
      if (params?.search) {
        dispatch({
          type: ActionTypes.LOAD_CLIENT_INVITES_WITH_PARAMS,
          payload: clientInvites,
        })
      } else {
        dispatch({
          type: ActionTypes.LOAD_CLIENT_INVITES,
          payload: clientInvites,
        })
      }
    }
  }

  const sendInvitations = async (cpaFirmGuid: string, params: TInviteParams) => {
    const result = await post(`cpas/cpa-firms/${cpaFirmGuid}/client-invites`, {
      ...params,
    })
    return result
  }

  const reSendInvitation = async (cpaFirmGuid: string, inviteGuid: string) => {
    await post(`/cpas/cpa-firms/${cpaFirmGuid}/client-invites/${inviteGuid}/resend`)
    if (response.ok) {
      message.success(t('messageResend.success'))
    }
  }

  const revokeInvitation = async (
    cpaFirmGuid: string,
    inviteGuid: string,
    clientInviteEntityGuid: string
  ) => {
    await post(
      `/cpas/cpa-firms/${cpaFirmGuid}/client-invites/${inviteGuid}/client-invites-entity/${clientInviteEntityGuid}/revoke`
    )
    if (response.ok) {
      message.success(t('messageRevoke.success'))
    } else {
      message.error(getMessageFromResponse(response))
    }
  }

  const deactivateClient = async (cpaFirmGuid: string, entityGuid: string) => {
    await del(`/cpas/cpa-firms/${cpaFirmGuid}/entities/${entityGuid}`)
  }

  const reactivateClient = async (cpaFirmGuid: string, entityGuid: string) => {
    await post(`/cpas/cpa-firms/${cpaFirmGuid}/client-invites/re-invite`, toSnake({ entityGuid }))
  }

  const getEntitiesPrimaryContactsList = async (params?: TParams) => {
    const query = qs.stringify(toSnake({ ...params }), { addQueryPrefix: !!params })
    const result = await get(`/entities/primary-contacts${query}`)
    if (response.ok) {
      dispatch({
        type: ActionTypes.LOAD_ENTITIES_PRIMARY_CONTACTS,
        payload: result,
      })
    }
  }

  const uploadUnsentInvitationsFile = async (cpaFirmGuid: string, file: File) => {
    dispatch({
      type: ActionTypes.LOAD_UNSENT_INVITATION_FILES,
      payload: { results: undefined },
    })
    const data = new FormData()
    data.append('file', file)
    await post(`/cpas/cpa-firms/${cpaFirmGuid}/client-invites/upload-csv`, data)
  }

  const getUnsentInvitationFiles = async (
    cpaFirmGuid: string,
    params?: Pick<TParams, 'ordering' | 'search'>
  ) => {
    const query = qs.stringify(toSnake({ ...params }), { addQueryPrefix: !!params })
    const results = await get(`/cpas/cpa-firms/${cpaFirmGuid}/client-invites/upload-csv${query}`)
    dispatch({
      type: ActionTypes.LOAD_UNSENT_INVITATION_FILES,
      payload: { results },
    })
  }

  const editClientInvitation = async (
    cpaFirmGuid: string,
    inviteGuid: string,
    params: Partial<TInviteParams>,
    inviteStatus: TClientInviteStatus
  ) => {
    const result = await patch(
      `/cpas/cpa-firms/${cpaFirmGuid}/client-invites/${inviteGuid}/`,
      params
    )

    if (response.ok) {
      await loadCpaClientInvites(cpaFirmGuid, {
        status: inviteStatus,
        pageSize: PAGE_SIZE,
        page: 1,
      })
    }
    return result
  }

  const sendUninvited = async (cpaFirmGuid: string, params: SendUninvitedParamsType) => {
    await post(`/cpas/cpa-firms/${cpaFirmGuid}/client-invites/send-unsent`, params)
  }

  const deleteEntityDocument = async (cpaFirmGuid: string, inviteDocumentGuid: string) => {
    await del(`/cpas/cpa-firms/${cpaFirmGuid}/client-invites/documents/${inviteDocumentGuid}/`)

    if (response.ok) {
      message.success(t('invitationDocumentDelete.success'))
    }
  }

  const loadCPAFirmProfile = async (cpaFirmSlug: string) => {
    const CPAFirmProfile = await get(`/cpas/cpa-firms/${cpaFirmSlug}/profile`)
    if (response.ok) {
      dispatch({
        type: ActionTypes.LOAD_CPA_FIRM_PROFILE,
        payload: {
          address: CPAFirmProfile.address,
          letterheadImage: CPAFirmProfile.letterheadImage,
          guid: CPAFirmProfile.guid,
          name: CPAFirmProfile.name,
          phone: CPAFirmProfile.phone,
        },
      })
    }
  }

  const prospectiveClients = async (params: prospectiveClientsParams) => {
    await post(`cpas/prospective-clients`, convert.toSnake(params))
  }

  return {
    loadClientSummary,
    loadClients,
    loadCpaClientInvites,
    sendInvitations,
    reSendInvitation,
    revokeInvitation,
    deactivateClient,
    reactivateClient,
    getEntitiesPrimaryContactsList,
    uploadUnsentInvitationsFile,
    getUnsentInvitationFiles,
    editClientInvitation,
    sendUninvited,
    deleteEntityDocument,
    loadCPAFirmProfile,
    prospectiveClients,
    response,
    loading,
    error,
  }
}

export default useEntityCPAsActions
export { ActionTypes, useEntityCPAsActions }
