import { Collapse, Modal } from 'antd'
import { RightOutlined, CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import {
  useTaxOrganizer,
  useTaxOrganizerActions,
  Phase,
  EnumPhaseTypes,
  EnumPhaseStatus,
} from 'context/TaxOrganizer'

import { Section, Button } from 'components'

import Head from './components/Head'
import Actions from './components/Actions'

import styles from './tax.timeline.module.scss'

const { confirm } = Modal
const { Panel } = Collapse

const Timeline = () => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'overview' })
  const {
    taxOrganizer: {
      data: { phases, phase: activePhase, guid, isExtended, extendedOn },
    },
  } = useTaxOrganizer()
  const { requestExtension, cancelExtention, loading, loadTaxOrganizer } = useTaxOrganizerActions()

  const requestExtentionHandler = async () => {
    confirm({
      maskClosable: true,
      title: t('requestExtensionTitle'),
      icon: <ExclamationCircleOutlined />,
      okText: t('confirm'),
      cancelText: t('cancel'),
      async onOk() {
        await requestExtension(guid)
        loadTaxOrganizer(guid)
      },
    })
  }

  const cancelExtentionHandler = async () => {
    confirm({
      maskClosable: true,
      title: t('cancelExtensionTitle'),
      icon: <ExclamationCircleOutlined />,
      okText: t('confirm'),
      cancelText: t('cancel'),
      async onOk() {
        await cancelExtention(guid)
        loadTaxOrganizer(guid)
      },
    })
  }

  const getActivePhases = () => {
    const extendedPhase = phases.find((p) => p.name === EnumPhaseTypes.EXTENDED)
    return extendedPhase?.status === EnumPhaseStatus.ACTIVE
      ? [activePhase, EnumPhaseTypes.EXTENDED]
      : [activePhase]
  }

  return (
    <Section
      title={
        <div className={styles.section_head}>
          {t('timeline')}
          {!isExtended ? (
            <Button
              type="primary"
              ghost
              size="small"
              className={styles.request_extension}
              loading={loading}
              onClick={requestExtentionHandler}
            >
              {t('requestExtensionButton')}
              <RightOutlined />
            </Button>
          ) : (
            <Button
              type="primary"
              ghost
              size="small"
              className={styles.request_extension}
              loading={loading}
              onClick={cancelExtentionHandler}
            >
              {t('cancelExtensionButton')}
              <RightOutlined />
            </Button>
          )}
        </div>
      }
    >
      <div className={cn(styles.timeline, 'tax-organizer-timeline')}>
        <Collapse
          defaultActiveKey={getActivePhases()[0]}
          ghost
          expandIconPosition="right"
          expandIcon={({ isActive }) => (
            <RightOutlined className={cn(styles.expand, isActive ? styles.expand_active : '')} />
          )}
        >
          {phases.map((phase: Phase) => {
            const isActive = phase.status !== EnumPhaseStatus.FILED
            return (
              <Panel
                header={<Head phase={phase} extendedOn={extendedOn} />}
                key={phase.name}
                className={isActive ? styles.panel_active : styles.panel}
              >
                <Actions
                  actions={phase.actions}
                  isActive={isActive}
                  isActionable={phase.isActionable}
                  guid={guid}
                  phaseName={phase.name}
                />
              </Panel>
            )
          })}
        </Collapse>
        <div className={styles.step_date}>
          <CheckOutlined className={styles.check} />
        </div>
      </div>
    </Section>
  )
}

export default Timeline
