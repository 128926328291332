import { Button, Badge } from 'components'
import { Avatar, Typography } from 'antd'
import { Flex } from 'reflexbox'
import { getInitials } from 'utils'
import { useTranslation } from 'react-i18next'
import styles from '../../../dashboard.entity.management.module.scss'

import mockUsers from './mock'

const { Title, Text } = Typography

const CapitalHolders = () => {
  const { t } = useTranslation('entity')
  const { t: t2 } = useTranslation('common')

  return (
    <>
      <div className={styles.section_header}>
        <Title level={4} className={styles.section_title}>
          <Badge count={22} offset={[12, -3]} size="small" scheme="purple">
            {t('capitalHolders.capitalHolders')}
          </Badge>
        </Title>
      </div>
      {mockUsers.map((user) => (
        <div className={styles.section_row} key={user.guid}>
          {user?.avatar ? (
            <Avatar size={24} src={user?.avatar} />
          ) : (
            <Avatar size={24}>{getInitials(user)}</Avatar>
          )}
          <Text className={styles.section_name}>
            {user.firstName} {user.lastName}
          </Text>
          <Text className={styles.section_value}>25%</Text>
        </div>
      ))}
      <Flex justifyContent="flex-end" alignItems="center">
        <Button type="link" size="large" className={styles.section_action}>
          {t2('buttons.seeAll')}
        </Button>
      </Flex>
    </>
  )
}

export default CapitalHolders
