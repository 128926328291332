import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { Phase } from 'context/TaxOrganizer'

import useDate from 'hooks/useDate'
import useLocale from 'hooks/useLocale'

import styles from '../tax.timeline.module.scss'

type TProps = {
  phase: Phase
  extendedOn: string
}

const Head = ({ phase, extendedOn }: TProps) => {
  const { t: t0 } = useTranslation()
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'overview' })
  const { getDate } = useDate()
  const locale = useLocale()

  return (
    <div className={styles.head}>
      <div className={cn(styles.step_date, 'step-date')}>
        {phase.dueDate ? (
          <>
            <div className={styles.step_date_month}>{getDate(phase?.dueDate, 'MMM')}</div>
            <div className={styles.step_date_day}>{getDate(phase?.dueDate, 'dd')}</div>
          </>
        ) : (
          phase.name === 'EXTENDED' && (
            <>
              <div className={styles.step_date_month}>{getDate(extendedOn, 'MMM')}</div>
              <div className={styles.step_date_day}>{getDate(extendedOn, 'dd')}</div>
            </>
          )
        )}
      </div>
      <div className={styles.step_title}>
        {t(`phases.${phase.name.toLowerCase()}`)}{' '}
        {phase.dueDate && (
          <span className={styles.step_due}>
            {t0('date.due', {
              date: formatDistanceToNowStrict(new Date(phase.dueDate), {
                addSuffix: true,
                locale,
              }),
            })}
          </span>
        )}
      </div>
    </div>
  )
}

export default Head
