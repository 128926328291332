import { toggleMenuItem } from 'utils/taxReturn'
import { RightOutlined } from '@ant-design/icons'
import cn from 'classnames'
import { Collapse } from 'react-collapse'
import { TDocumentPageIdentifier, TTaxReturnMenuCategory } from 'context/TaxReturns'
import { STATES_BY_CODE } from 'constants/address'
import styles from '../../../dashboard.tax_return.module.scss'
import Previews from './Previews'

type TProps = {
  categoryList: TTaxReturnMenuCategory[]
  setMenuTreeStructure: (treeStructure: TTaxReturnMenuCategory[]) => void
  selectedPage: TDocumentPageIdentifier | null
  setSelectedPage: (pageIdentifier: TDocumentPageIdentifier) => void
  menuTreeStructure: TTaxReturnMenuCategory[]
  keyPath?: string[]
}

const RecursiveCategory = ({
  categoryList,
  setMenuTreeStructure,
  menuTreeStructure,
  selectedPage,
  setSelectedPage,
  keyPath = [],
}: TProps) => {
  const isRoot = keyPath.length === 0
  if (categoryList.length === 0) return null
  return (
    <>
      {categoryList.map((category, index) => (
        <div key={category.slug}>
          <div
            className={cn(styles.tax_return_category, { [styles.subcategory]: !isRoot })}
            onClick={() =>
              setMenuTreeStructure(toggleMenuItem(menuTreeStructure, [...keyPath, `${index}`]))
            }
          >
            {STATES_BY_CODE[category.slug.toUpperCase()] || category.label}
            <RightOutlined className={cn({ [styles.open]: category.expanded })} />
          </div>

          <Collapse isOpened={category.expanded}>
            {category.expanded && (
              <RecursiveCategory
                categoryList={category.childCategories || []}
                menuTreeStructure={menuTreeStructure}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
                setMenuTreeStructure={setMenuTreeStructure}
                keyPath={[...keyPath, `${index}`, 'childCategories']}
              />
            )}
            <Previews
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
              category={category}
            />
          </Collapse>
        </div>
      ))}
    </>
  )
}

export default RecursiveCategory
