import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col } from 'antd'
import { DASHBOARD_PATH } from 'utils/routes'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { useDashboardActions } from 'context/Dashboard'

import { Alert } from 'components'

import Info from '../components/Info'
import ActivityFeed from '../components/ActivityFeed'
import MostRecent from '../components/MostRecent'
import ProductionRate from '../components/ProductionRate'
import InvitationBanner from '../components/InvitationBanner'
import Filters from '../components/Filters'

import useBanner from './hooks/useBanner'
import useParams from '../hooks/useParams'
import { DEFAULT_TAX_DEADLINES_PARAMS } from '../constants'

import styles from '../dashboard.module.scss'

const Dashboard = () => {
  const { t } = useTranslation('dashboard')
  const prevYear = new Date().getFullYear() - 1
  const { summaryParams, setSummaryParams, taxParams, setTaxParams, showTable, setShowTable } =
    useParams()
  const { loadTaxDeadlines, loading: loadingDeadlines } = useDashboardActions()
  const { showBanner } = useBanner()

  useEffect(() => {
    loadTaxDeadlines({ ...DEFAULT_TAX_DEADLINES_PARAMS, taxYear: prevYear })
  }, [])

  return (
    <>
      <div className="container-xxl">
        <Filters
          setSummaryParams={setSummaryParams}
          setTaxParams={setTaxParams}
          setShowTable={setShowTable}
          loading={loadingDeadlines}
        />
      </div>
      <div className="container">
        <InvitationBanner />
        <div className={cn(['position-relative', styles.main_banner])}>
          {showBanner && (
            <Alert
              type="warning"
              message={
                <span className={styles.main_banner_body}>
                  {t('banner.titleStart')}{' '}
                  <Link to={`${DASHBOARD_PATH}/clients`}>{t('banner.link')}</Link>{' '}
                  {t('banner.titleEnd')}
                </span>
              }
            />
          )}
        </div>
        <Row gutter={[20, 20]}>
          <Col xs={24}>
            <Info
              taxParams={taxParams}
              showTable={showTable}
              summaryParams={summaryParams}
              setSummaryParams={setSummaryParams}
              setShowTable={setShowTable}
              setTaxParams={setTaxParams}
              loadingDeadlines={loadingDeadlines}
            />
          </Col>
          <Col xs={24} lg={14}>
            <MostRecent />
          </Col>
          <Col xs={24} lg={10}>
            <ActivityFeed />
          </Col>
          <Col xs={24} lg={14}>
            <ProductionRate />
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Dashboard
