import { Section } from 'components'

import Title from './components/Title'
import Threads from './components/Threads'

import styles from './tax.messages.module.scss'

const DashboardMessages = () => {
  return (
    <div className="container position-relative">
      <Section title={<Title />} titleIndent={0} className={styles.section}>
        <Threads />
      </Section>
    </div>
  )
}

export default DashboardMessages
