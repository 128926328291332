import { useEffect, useRef } from 'react'
import { range } from 'lodash'
import * as d3 from 'd3'
import { useMediaQuery } from 'react-responsive'
import { removeAllChildNodes } from 'utils'
import { useTranslation } from 'react-i18next'

import useLocale from 'hooks/useLocale'
import useDate from 'hooks/useDate'

import { TChartData, useDashboard } from 'context/Dashboard'

import { TData } from '../types'
import { extendData } from '../helpers'

import styles from '../../../dashboard.module.scss'

export default ({ chartData }: { chartData: TChartData[] }) => {
  const { t } = useTranslation('dashboard')
  const locale = useLocale()
  const { getDate } = useDate()
  const { deadline } = useDashboard()
  const isMobile = useMediaQuery({ query: '(max-width: 40em)' })
  const areaChart = useRef<null | HTMLDivElement>(null)

  removeAllChildNodes(areaChart.current)

  useEffect(() => {
    if (chartData.length && deadline?.selected) {
      const textLabelCoordinates: { cx: number; cy: number } = {
        cx: 0,
        cy: 0,
      }

      const data: TData[] = extendData(chartData, locale).map((d: any) => {
        return {
          ...d,
          date: d3.timeParse('%Y-%m-%d')(d.date),
        }
      })

      const monthsLabels: string[] = data.reduce((acc: string[], cur: TData) => {
        const month = getDate(cur?.date, 'MMM')
        if (!acc.includes(month)) {
          acc.push(month)
        }
        return acc
      }, [])

      const margin = { top: 10, right: 10, bottom: 30, left: 50 }
      const width = 650 - margin.left - margin.right
      const height = isMobile ? 180 : 215 - margin.top - margin.bottom

      const svg = d3
        .select(areaChart.current)
        .append('svg')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .attr(
          'viewBox',
          `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`
        )
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`)

      const x = d3
        .scaleTime()
        .domain([d3.min(data, (d: any) => d.date) as any, d3.max(data, (d: any) => d.date) as any])
        .range([0, width])

      svg
        .append('g')
        .attr('class', 'production-rate-axis')
        .attr('transform', `translate(0,${height + 5})`)
        .call(
          d3
            .axisBottom(x)
            .ticks(isMobile ? 2 : monthsLabels.length)
            .tickFormat((d, i) => monthsLabels[i])
            .tickSizeOuter(0)
        )

      const y = d3
        .scaleLinear()
        .domain([0, d3.max(data, (d: any) => d.count) as any])
        .range([height, 0])

      svg
        .append('g')
        .attr('class', 'production-rate-axis')
        .attr('transform', 'translate(-5,0)')
        .call(
          d3
            .axisLeft(y)
            .ticks(1)
            .tickValues(range((1 + d3.max(data, (d: any) => d.count)) as number))
            .tickSizeOuter(1)
        )

      svg
        .append('path')
        .datum(data)
        .attr('class', styles.rate_area)
        .attr(
          'd',
          d3
            .area()
            .x((d: any) => x(d.date))
            .y0(height)
            .y1((d: any) => y(d.count)) as any
        )

      svg
        .append('path')
        .datum(data.filter((_: any) => _.reper))
        .attr('class', styles.rate_line)
        .attr(
          'd',
          d3
            .line()
            .x((d: any) => x(d.date))
            .y((d: any) => y(d.count)) as any
        )

      svg
        .selectAll('myCircles')
        .data(data)
        .enter()
        .append('circle')
        .attr('class', styles.rate_point_1)
        .attr('cx', (d: any) => x(d.date))
        .attr('cy', (d: any) => y(d.count))
        .attr('r', (d: any) => {
          if (d3.timeFormat('%Y-%m-%d')(d.date) === getDate(new Date(), 'yyyy-MM-dd')) {
            return 12
          }
          return 0
        })

      svg
        .selectAll('myCircles')
        .data(data)
        .enter()
        .append('circle')
        .attr('class', styles.rate_point_2)
        .attr('cx', (d: any) => {
          if (d3.timeFormat('%Y-%m-%d')(d.date) === getDate(new Date(), 'yyyy-MM-dd')) {
            textLabelCoordinates.cx = x(d.date)
          }
          return x(d.date)
        })
        .attr('cy', (d: any) => {
          if (d3.timeFormat('%Y-%m-%d')(d.date) === getDate(new Date(), 'yyyy-MM-dd')) {
            textLabelCoordinates.cy = y(d.count)
          }
          return y(d.count)
        })
        .attr('r', (d: any) => {
          if (d3.timeFormat('%Y-%m-%d')(d.date) === getDate(new Date(), 'yyyy-MM-dd')) {
            return 9
          }
          return 0
        })

      svg
        .selectAll('myCircles')
        .data(data)
        .enter()
        .append('circle')
        .attr('class', styles.rate_point_3)
        .attr('cx', (d: any) => x(d.date))
        .attr('cy', (d: any) => y(d.count))
        .attr('r', (d: any) => {
          if (d3.timeFormat('%Y-%m-%d')(d.date) === getDate(new Date(), 'yyyy-MM-dd')) {
            return 4
          }
          return 0
        })

      svg
        .append('text')
        .attr('x', textLabelCoordinates.cx - 40)
        .attr('y', textLabelCoordinates.cy - 20)
        .attr('class', styles.production_rate_label_bg)
        .text(String(t('productionRate.point')))

      svg
        .append('text')
        .attr('x', textLabelCoordinates.cx - 40)
        .attr('y', textLabelCoordinates.cy - 20)
        .attr('class', styles.production_rate_label)
        .text(String(t('productionRate.point')))

      svg
        .append('text')
        .attr('class', styles.production_rate_axis)
        .attr('transform', 'rotate(-90)')
        .attr('y', 0 - margin.left)
        .attr('x', 0 - height / 2)
        .attr('dy', '1em')
        .style('text-anchor', 'middle')
        .text(String(t('productionRate.yAxis')))
    }
  }, [isMobile, chartData, deadline])

  return { areaChart }
}
