import { useMemo, useState } from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { Alert, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { getPhaseSubmitLabel } from 'utils/taxOrganizer'

import {
  PhaseAction,
  useTaxOrganizerActions,
  PhaseName,
  useTaxOrganizer,
} from 'context/TaxOrganizer'

import { Button, Checkbox } from 'components'

import useUserType from 'hooks/useUserType'
import useTimelineActions, { PhaseActionLink } from '../hooks/useTimelineActions'

import EngagementLetterSigningWidget from './EngagementLetterSigningWidget'

import styles from '../tax.timeline.module.scss'

const { Text } = Typography

const ActionsList = ({
  actions,
  handleChange,
  isActionable,
  isActive,
}: {
  actions: PhaseAction[]
  handleChange: (e: any) => void
  isActionable: boolean
  isActive: boolean
}) => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'overview.timelinePhases' })
  const history = useHistory()
  const { url } = useRouteMatch()
  const {
    taxOrganizer: { engagementLetterSigningInformation },
  } = useTaxOrganizer()
  const userType = useUserType()
  const [signingWidgetSource, setSigningWidgetSource] = useState<string | null>(null)
  const [signingWidgetError, setSigningWidgetError] = useState<string | null>(null)
  const letterSigned = useMemo(() => {
    if (
      engagementLetterSigningInformation?.signedByClientOn &&
      engagementLetterSigningInformation?.signedByPreparerOn
    ) {
      return false
    }
    if (userType === 'ENTITY' && engagementLetterSigningInformation?.signedByClientOn) {
      return true
    }
    if (userType === 'CPA' && engagementLetterSigningInformation?.signedByPreparerOn) {
      return true
    }
    return false
  }, [engagementLetterSigningInformation, userType])

  const { actionLinks } = useTimelineActions({
    setIframeSource: setSigningWidgetSource,
    setError: setSigningWidgetError,
  })

  const onAction = (actionLink: PhaseActionLink) => {
    if (!actionLink?.actionCallback) {
      history.push(`${url}/${actionLink.path}`)
      return
    }
    actionLink.actionCallback()
  }

  return (
    <>
      {signingWidgetError && <Alert type="error" message={signingWidgetError} banner closable />}
      {signingWidgetSource && (
        <EngagementLetterSigningWidget
          source={signingWidgetSource}
          setSource={setSigningWidgetSource}
          setError={setSigningWidgetError}
        />
      )}
      {actions.map((action: PhaseAction) => {
        const isCompleted = action.completed
        const actionLink = actionLinks[action.slug]
        const isDisabled = actionLink?.isDisabled
        const isActionLinkVisible = !actionLink?.actionHidden

        return (
          <div key={action.slug} className={styles.action}>
            <Checkbox
              value={action.slug}
              checked={isCompleted}
              disabled={!action.mutable || !isActionable || !isActive || isDisabled}
              onChange={handleChange}
              responsive
              className={styles.action_checkbox}
            >
              {action.slug === 'SIGN_ENGAGEMENT' && letterSigned ? (
                <div>
                  <Text delete>{t(action.slug)}</Text>
                  <br />
                  <Text type="secondary" style={{ fontSize: '12px' }}>
                    {t('letterSigned')}
                  </Text>
                </div>
              ) : (
                t(action.slug)
              )}
            </Checkbox>
            {actionLink && !isCompleted && isActionLinkVisible && (
              <div className={styles.action_button}>
                <Button
                  disabled={isDisabled}
                  onClick={() => onAction(actionLink)}
                  type="primary"
                  ghost
                  size="small"
                >
                  {actionLink.title}
                </Button>
              </div>
            )}
          </div>
        )
      })}
    </>
  )
}

const Actions = ({
  actions,
  isActive,
  guid,
  phaseName,
  isActionable,
}: {
  actions: PhaseAction[]
  isActive: boolean
  guid: string
  phaseName: PhaseName
  isActionable: boolean
}) => {
  const { checkPhaseAction, unCheckPhaseAction } = useTaxOrganizerActions()
  const { submitPhase, loading } = useTaxOrganizerActions()
  const { t } = useTranslation('taxOrganizer')

  const handleChange = async (e: any) => {
    const { value: actionSlug, checked } = e.target

    if (!isActionable) return

    if (checked) {
      await checkPhaseAction(guid, actionSlug)
    } else {
      await unCheckPhaseAction(guid, actionSlug)
    }
  }

  const isDisabled = (): boolean => actions.some((action: PhaseAction) => !action.completed)

  const isSubmitAllowed: boolean = isActive

  return (
    <div className={styles.actions}>
      <ActionsList
        isActive={isActive}
        isActionable={isActionable}
        actions={actions}
        handleChange={handleChange}
      />
      {isSubmitAllowed && (
        <Button
          type="primary"
          size="large"
          className={styles.action_submit}
          onClick={() => submitPhase(guid, phaseName)}
          disabled={isDisabled() || loading}
        >
          {getPhaseSubmitLabel(phaseName, t)}
        </Button>
      )}
    </div>
  )
}

export default Actions
