import { useState, useEffect, useCallback, useRef } from 'react'
import { CachePolicies, useFetch } from 'use-http'
import { TTaxReturnPage, useTaxReturns } from 'context/TaxReturns'

const REQUEST_INTERVAL = 2000

export default () => {
  const { get, response } = useFetch(process.env.REACT_APP_API_URL, {
    cachePolicy: CachePolicies.NO_CACHE,
  })
  const {
    state: {
      taxReturns: { latest: latestTaxReturn },
    },
  } = useTaxReturns()
  const [pendingDocumentsStatus, setPendingDocumentsStatus] = useState<
    | 'START'
    | 'PENDING_DOCUMENTS'
    | 'DOCUMENTS_PROCESSED'
    | 'PENDING_PAGES'
    | 'PAGES_PROCESSED'
    | 'END'
  >('START')
  const timeoutIdDocuments = useRef<NodeJS.Timeout>()
  const timeoutIdPages = useRef<NodeJS.Timeout>()
  const requestsCount = useRef(0)
  const noPendingDocuments = () => requestsCount.current === 1

  const getPendingDocuments = useCallback(async () => {
    const documents = await get(
      `tax-organizers/tax-return/${latestTaxReturn!.guid}/${
        latestTaxReturn!.draftVersion
      }/documents?status=PEN`
    )
    ++requestsCount.current
    if (response.ok && documents.count > 0) {
      setPendingDocumentsStatus('PENDING_DOCUMENTS')
      timeoutIdDocuments.current = setTimeout(() => {
        getPendingDocuments()
      }, REQUEST_INTERVAL)
    } else {
      setPendingDocumentsStatus('DOCUMENTS_PROCESSED')
    }
  }, [])

  const getPages = useCallback(async () => {
    setPendingDocumentsStatus('PENDING_PAGES')
    const pages: TTaxReturnPage[] = await get(
      `tax-organizers/tax-return/${latestTaxReturn!.guid}/pages`
    )
    const atLeastOneThumbnailIsNotProcessed = pages.filter((page) => !page.thumbnail).length > 0
    if (response.ok && atLeastOneThumbnailIsNotProcessed) {
      timeoutIdPages.current = setTimeout(() => {
        getPages()
      }, REQUEST_INTERVAL)
    } else {
      setPendingDocumentsStatus('END')
    }
  }, [])

  useEffect(() => {
    if (pendingDocumentsStatus === 'DOCUMENTS_PROCESSED') {
      if (noPendingDocuments()) {
        setPendingDocumentsStatus('END')
      } else {
        getPages()
      }
    }
  }, [pendingDocumentsStatus])

  useEffect(() => {
    getPendingDocuments()
    return () => {
      clearTimeout(timeoutIdDocuments.current as NodeJS.Timeout)
      clearTimeout(timeoutIdPages.current as NodeJS.Timeout)
    }
  }, [])

  return { pendingDocumentsStatus }
}
