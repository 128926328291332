import { useState } from 'react'
import { CachePolicies, useFetch } from 'use-http'
import axios from 'axios'
import { toSnake } from 'utils'
import qs from 'qs'
import { getLatestTaxReturn } from 'utils/taxReturn'
import { useHistory } from 'react-router-dom'

import { RcFile } from 'antd/lib/upload'
import { TUploadDocumentParams } from 'context/TaxOrganizer'
import { message } from 'antd'
import { getMessageFromResponse } from 'utils/http'
import {
  TTaxReturn,
  TTaxReturnMenuCategory,
  TPagesRequest,
  TDocumentQueryParams,
  TUploadTaxReturnDocumentParams,
  TDocumentPageIdentifier,
  TTaxReturnProcessStatus,
} from './types'
import { ActionTypes } from './action-types'

import { useTaxReturns } from './context'

import { steps } from './steps'

export const THREADS_PAGE_SIZE = 25

const useTaxReturnsActions = () => {
  const {
    state: { total, taxReturns },
    dispatch,
  } = useTaxReturns()
  const { loading, error, response, get, post, patch, put, del, abort } = useFetch(
    process.env.REACT_APP_API_URL,
    {
      cachePolicy: CachePolicies.NO_CACHE,
    }
  )
  const { response: response2, get: get2 } = useFetch(process.env.REACT_APP_API_URL, {
    cachePolicy: CachePolicies.NO_CACHE,
  })
  const [urlGeneratingLoading, setUrlGeneratingLoading] = useState(false)
  const [loadingDocuments, setLoadingDocuments] = useState(false)
  const history = useHistory()

  const loadThreads = async (params: any) => {
    const threads = await get(
      `tax-organizers/threads?${qs.stringify(toSnake({ ...params, pageSize: THREADS_PAGE_SIZE }))}`
    )
    if (response.ok) {
      dispatch({
        type: ActionTypes.LOAD_TAX_RETURN_THREADS,
        payload: threads,
      })
    }
    return threads
  }

  const setLoading = (isLoading: boolean) => {
    dispatch({
      type: ActionTypes.SET_LOADING,
      payload: isLoading,
    })
  }

  const loadTaxReturns = async (taxOrganizerGuid: string) => {
    const newTaxReturns = await get(`tax-organizers/${taxOrganizerGuid}/tax-returns`)
    if (response.ok) {
      dispatch({
        type: ActionTypes.LOAD_TAX_RETURNS,
        payload: { taxReturns: newTaxReturns, taxReturnsResponse: response.ok },
      })
    }
  }

  const loadLatestTaxReturn = async (taxReturnId: string) => {
    const newTaxReturns = await get(`tax-organizers/tax-return/${taxReturnId}`)
    if (response.ok) {
      dispatch({
        type: ActionTypes.LOAD_LATEST_TAX_RETURN,
        payload: newTaxReturns,
      })
    }
    return newTaxReturns
  }

  const loadFilingStatus = async () => {
    const filingStatus = await get('tax-organizers/tax-return/filing-status')
    dispatch({
      type: ActionTypes.LOAD_FILING_STATUS,
      payload: filingStatus === undefined ? [] : filingStatus,
    })
    return filingStatus
  }

  const assignAuthorizedSigner = async (
    email: string,
    taxreturnGuid: string,
    signingOrder: number
  ) => {
    await post(`tax-organizers/tax-return/${taxreturnGuid}/authorized-signer/`, {
      signer_email: email,
      signing_order: signingOrder,
    })
  }

  const loadEntityInviteList = async (entityGuid: string) => {
    const signersResponse = await get(`/entities/${entityGuid}/user-invites-by-entity`)
    dispatch({
      type: ActionTypes.LOAD_ENTITY_INVITES,
      payload: signersResponse.results || [],
    })
    return signersResponse.results
  }

  const loadEntitySigners = async (entityGuid: string, ignorePrimary: boolean = false) => {
    const signersResponse = await get(`/entities/${entityGuid}/users`)
    let signers = signersResponse.results
    if (ignorePrimary) {
      signers = signers.filter((signer: any) => signer.role !== 'OWN')
    }
    dispatch({
      type: ActionTypes.LOAD_ENTITY_SIGNERS,
      payload: signers || [],
    })
    return signers
  }

  const loadSteps = async () => {
    dispatch({
      type: ActionTypes.LOAD_STEPS,
      payload: { steps, total: steps.length },
    })
  }

  const setTaxReturnStatus = async (status: TTaxReturnProcessStatus) => {
    dispatch({
      type: ActionTypes.SET_TAX_RETURN_STATUS,
      payload: status,
    })
  }

  const createTaxReturn = async (guid: string) => {
    await post(`tax-organizers/tax-return`, toSnake({ taxOrganizer: guid }))
  }

  const updateTaxReturn = async (guid: string, taxReturn: Partial<TTaxReturn>) => {
    const updatedTaxReturn = await patch(`/tax-organizers/tax-return/${guid}`, toSnake(taxReturn))
    if (response.ok) {
      await loadTaxReturns(updatedTaxReturn.taxOrganizer.guid)
    }
  }

  const deleteTaxReturnDocument = async (latestTaxReturnGuid: string, documentGuid: string) => {
    await del(`/tax-organizers/tax-return/${latestTaxReturnGuid}/tax-document/${documentGuid}`)
    if (response.ok) {
      dispatch({
        type: ActionTypes.DELETE_TAX_RETURN_DOCUMENT,
        payload: documentGuid,
      })
    }
  }

  const createTaxReturnDocument = async (
    latestTaxReturn: TTaxReturn,
    params: TUploadTaxReturnDocumentParams,
    {
      file,
      onUploadProgress,
    }: { file: RcFile | undefined; onUploadProgress?: (event: ProgressEvent) => void }
  ) => {
    const document = await post(
      `/tax-organizers/tax-return/${latestTaxReturn?.guid}/documents`,
      toSnake(params)
    )
    if (response.ok) {
      try {
        return axios.put(document.uploadUrl, file, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress,
        })
      } catch (err) {
        return deleteTaxReturnDocument(latestTaxReturn.guid, document.guid)
        // return null
      }
    }
    return document
  }

  const loadTaxReturnDocuments = async (
    latestTaxReturn: TTaxReturn,
    params?: TDocumentQueryParams
  ) => {
    setLoadingDocuments(true)
    const query = qs.stringify(toSnake(params), { addQueryPrefix: !!params, arrayFormat: 'comma' })
    const documents = await get(
      `tax-organizers/tax-return/${latestTaxReturn.guid}/${latestTaxReturn.draftVersion}/documents${query}`
    )
    if (response.ok) {
      if (documents?.count > documents?.results?.length) {
        const params2 = { ...params, pageSize: documents.count }
        const query2 = qs.stringify(toSnake(params2), {
          addQueryPrefix: !!params2,
          arrayFormat: 'comma',
        })
        const documents2 = await get2(
          `tax-organizers/tax-return/${latestTaxReturn.guid}/${latestTaxReturn.draftVersion}/documents${query2}`
        )
        if (response2.ok) {
          dispatch({
            type: ActionTypes.LOAD_TAX_RETURN_DOCUMENTS,
            payload: { documents: documents2, response: response2.ok },
          })
        } else {
          message.error(getMessageFromResponse(response2))
        }
      } else {
        dispatch({
          type: ActionTypes.LOAD_TAX_RETURN_DOCUMENTS,
          payload: { documents, response: response.ok },
        })
      }
    } else {
      message.error(getMessageFromResponse(response))
    }
    setLoadingDocuments(false)
  }

  const loadTaxReturnCategories = async () => {
    const categories = await get('tax-organizers/tax-return/categories')

    if (response.ok) {
      dispatch({
        type: ActionTypes.LOAD_TAX_RETURN_CATEGORIES,
        payload: categories,
      })
    }
  }

  const loadTaxReturnPages = async (latestTaxReturn: TTaxReturn) => {
    const taxReturnPages = await get(`tax-organizers/tax-return/${latestTaxReturn?.guid}/pages`)
    if (response.ok) {
      dispatch({
        type: ActionTypes.LOAD_TAX_RETURN_PAGES,
        payload: taxReturnPages,
      })
    }
  }

  const filterTaxReturnPages = (menu: TTaxReturnMenuCategory[]) => {
    const collectPagesRecursively = (
      menuLevel: TTaxReturnMenuCategory[]
    ): TDocumentPageIdentifier[] => {
      return menuLevel.flatMap((category) => [
        ...(category.pages || []),
        ...(category.childCategories ? collectPagesRecursively(category.childCategories) : []),
      ])
    }
    dispatch({
      type: ActionTypes.FILTER_TAX_RETURN_PAGES,
      payload: collectPagesRecursively(menu),
    })
  }

  const loadDocument = async (taxOrganizerId: string, documentGuid: string) => {
    const document = await get(`/tax-organizers/${taxOrganizerId}/documents/${documentGuid}`)
    if (response.ok) {
      dispatch({ type: ActionTypes.LOAD_TAX_RETURN_DOCUMENT, payload: document })
    }
  }

  const setDragDropDocumentIds = (documentGuids: string[]) => {
    dispatch({
      type: ActionTypes.SET_DRAG_DROP_DOCUMENT_IDS,
      payload: documentGuids,
    })
  }

  const updateTaxReturnPages = async (pagesBody: TPagesRequest) => {
    await put('tax-organizers/tax-return/pages/categorize', {
      pages: pagesBody.pages.map((page) => toSnake({ ...page })),
    })

    if (response.ok) {
      setDragDropDocumentIds([])
      dispatch({ type: ActionTypes.UPDATE_TAX_RETURN_PAGES, payload: pagesBody })
    }
  }

  const generateEFileAuthorizationLetter = async (guid: string) => {
    const eFileResponse = await post(`tax-organizers/tax-return/${guid}/efile-authorization/create`)

    if (response.ok) {
      dispatch({ type: ActionTypes.SET_EFILE_AUTH_DOC, payload: eFileResponse })
    }
  }

  const eFileAuthPreCheck = async (guid: string) => {
    const eFileResponse = await get(
      `tax-organizers/tax-return/${guid}/efile-authorization/precheck`
    )

    if (response.ok) {
      return eFileResponse.isReady
    }
    return false
  }

  const checkSigned = async (taxReturnId: string) => {
    const document = await get(
      `/tax-organizers/tax-return/${taxReturnId}/efile-authorization/is-signed`
    )
    if (response.ok) {
      dispatch({ type: ActionTypes.LOAD_AUTH_SIGN_DETAILS, payload: document })
    }
  }

  const setSelectedTags = (selectedTags: string[]) => {
    dispatch({
      type: ActionTypes.SET_SELECTED_TAGS,
      payload: selectedTags,
    })
  }

  const setStep = async (current: number, navigate?: 'push' | 'replace') => {
    const next = current >= total - 1 ? null : current + 1
    const prev = current === 0 ? null : current - 1
    if (navigate && navigate === 'push') history.push({ search: qs.stringify({ step: current }) })
    if (navigate && navigate === 'replace')
      history.replace({ search: qs.stringify({ step: current }) })
    dispatch({
      type: ActionTypes.SET_STEP,
      payload: {
        step: {
          current,
          next,
          prev,
        },
        maxStep: current,
      },
    })
  }

  const setPressShift = (isPress: boolean) => {
    dispatch({
      type: ActionTypes.SET_PRESS_SHIFT,
      payload: isPress,
    })
  }

  const eFileEditUrl = async (taxReturnId: string) => {
    const editResponse = await get(
      `/tax-organizers/tax-return/${taxReturnId}/efile-authorization/edit-url`
    )
    return editResponse
  }

  const eFileSignUrl = async (taxReturnId: string) => {
    const editResponse = await get(
      `/tax-organizers/tax-return/${taxReturnId}/efile-authorization/signing-url`
    )
    if (!response.ok) {
      throw new Error(editResponse.msg)
    }
    return editResponse
  }

  const generateSignedUploadURL = async (
    params: TUploadDocumentParams,
    {
      file,
      onUploadProgress,
    }: { file: RcFile | undefined; onUploadProgress?: (event: ProgressEvent) => void }
  ) => {
    const latestTaxReturn = getLatestTaxReturn(taxReturns.data)
    if (latestTaxReturn) {
      setUrlGeneratingLoading(true)
      const document = await post(
        `/tax-organizers/tax-return/${latestTaxReturn.guid}/efile-authorization/upload`,
        toSnake(params)
      )

      if (response.ok) {
        try {
          await axios.put(document.uploadUrl, file, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress,
          })
        } catch (err) {
          console.log(err)
          return null
        }
      }
      setUrlGeneratingLoading(false)
      return document
    }
    return null
  }

  const verificationCheck = async (guid: string) => {
    await get(`id_verify/tax-return/${guid}/id-verification`)
    // dispatch({
    //   type: ActionTypes.SET_VERIFICATION_STATUS,
    //   payload: result.verificationStatus,
    // })
  }

  const createVerificationSession = async (guid: string) => {
    const config = await get('/billing/config')
    const result = await post(`id_verify/tax-return/${guid}/id-verification`)
    return { clientSecret: result.clientSecret, publicKey: config.publicKey }
  }

  return {
    loadSteps,
    setStep,
    setTaxReturnStatus,
    setPressShift,
    loadTaxReturns,
    loadLatestTaxReturn,
    loadFilingStatus,
    loadEntityInviteList,
    loadEntitySigners,
    assignAuthorizedSigner,
    createTaxReturn,
    updateTaxReturn,
    loadThreads,
    createTaxReturnDocument,
    deleteTaxReturnDocument,
    loadTaxReturnDocuments,
    loadTaxReturnPages,
    filterTaxReturnPages,
    loadTaxReturnCategories,
    setLoading,
    setSelectedTags,
    updateTaxReturnPages,
    loadDocument,
    setDragDropDocumentIds,
    generateEFileAuthorizationLetter,
    eFileAuthPreCheck,
    checkSigned,
    eFileEditUrl,
    eFileSignUrl,
    generateSignedUploadURL,
    urlGeneratingLoading,
    verificationCheck,
    createVerificationSession,
    response,
    loading,
    loadingDocuments,
    error,
    abort,
  }
}

export default useTaxReturnsActions
export { ActionTypes, useTaxReturnsActions }
