import Info from '../Tabs/Info'
import Upload from '../Tabs/Upload'
import ReviewTab from '../Tabs/Review'
import Categorize from '../Tabs/Categorize'
import FieldReturn from '../Tabs/FieldReturn'

export const switchContent = (current: number | undefined) => {
  switch (current) {
    case 0:
    case 6:
    case 8:
      return <Info />
    case 1:
      return <Upload />
    case 2:
      return <Categorize />
    case 3:
    case 4:
    case 7:
      return <ReviewTab />
    case 5:
    case 9:
      return <FieldReturn />
    default:
      return null
  }
}
