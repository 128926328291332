import { useState, useEffect } from 'react'
import PDFMerger from 'pdf-merger-js/browser'
import { detect } from 'detect-browser'

import { useTaxReturns } from 'context/TaxReturns'

import { getAllDocs } from '../helpers'

export default () => {
  const {
    state: {
      taxReturnDocuments: { data: taxReturnDocuments },
      filteredTaxReturnPages,
    },
  } = useTaxReturns()

  const [fileUrl, setFileUrl] = useState('')
  const [creatingPDF, setCreatingPDF] = useState(false)

  useEffect(() => {
    if (taxReturnDocuments.length > 0 && filteredTaxReturnPages.length > 0) {
      setCreatingPDF(true)
      const printableData = getAllDocs(filteredTaxReturnPages, taxReturnDocuments)
      const browser = detect()
      const render = async () => {
        const merger = new PDFMerger()

        // eslint-disable-next-line
        for (const document of printableData) {
          // eslint-disable-next-line
          await merger.add(document.document.file as string, [document.pages])
        }

        const mergedPdf = await merger.saveAsBlob()
        return window.URL.createObjectURL(mergedPdf)
      }

      render()
        .then((url) => {
          setFileUrl(url)
        })
        .catch((err) => {
          try {
            throw err
          } catch (error) {
            console.log(`[PDFMerger error for ${browser?.name}]: ${(error as any)?.message}`)
          }
        })
        .finally(() => {
          setCreatingPDF(false)
        })
    }
  }, [taxReturnDocuments, filteredTaxReturnPages])

  return { fileUrl, creatingPDF }
}
