export enum ActionTypes {
  SET_TAX_LIST = 'SET_TAX_LIST',
  LOAD_MOST_RECENT = 'LOAD_MOST_RECENT',
  LOAD_ACTIVITY_FEED = 'LOAD_ACTIVITY_FEED',
  LOAD_TAX_DEADLINES = 'LOAD_TAX_DEADLINES',
  LOAD_CPAS_MEMBERSHIPS = 'LOAD_CPAS_MEMBERSHIPS',
  LOAD_PRIMARY_CONTACTS = 'LOAD_PRIMARY_CONTACTS',
  LOAD_TAX_SUMMARY = 'LOAD_TAX_SUMMARY',
  LOAD_DOCUMENTS_TAGS = 'LOAD_DOCUMENTS_TAGS',
  LOAD_DOCUMENTS_CATEGORIES = 'LOAD_DOCUMENTS_CATEGORIES',
  SET_DEADLINE = 'SET_DEADLINE',
  SET_CPA_ASSOCIATED_USERS = 'SET_CPA_ASSOCIATED_USERS',
  SET_TAX_YEAR = 'SET_TAX_YEAR',
  LOAD_BURN_DOWN_CHART_DATA = 'LOAD_BURN_DOWN_CHART_DATA',
}
