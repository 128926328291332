import { Table } from 'components'
import { useEffect, useState } from 'react'

import { TEntityUserInvite } from 'context/Invites/types'
import { useEntity, useEntityActions, TEntity } from 'context/Entity'
import useEntityUserInviteColumns from './hooks/useEntityUserInviteColumns'

type Props = {
  entity: TEntity
}

const EntityUserInvitesList = ({ entity }: Props) => {
  const [page, setPage] = useState(1)
  const { columns } = useEntityUserInviteColumns({ entity })
  const { loadEntityUserInvites, loading } = useEntityActions()
  const {
    entity: {
      userInvites: { data: dataSource, total },
    },
  } = useEntity()

  useEffect(() => {
    loadEntityUserInvites(entity.guid, { page })
  }, [page])

  return (
    <Table
      table={{
        dataSource,
        columns,
        loading,
        rowKey: (record: TEntityUserInvite) => record.guid,
        tableLayout: 'auto',
        scroll: { x: '100%' },
        size: 'small',
      }}
      pagination={{ total, onChange: (newPage) => setPage(newPage) }}
    />
  )
}

export default EntityUserInvitesList
