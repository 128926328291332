import { useMemo, useState } from 'react'
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js'
import {
  PaymentMethodResult,
  StripeCardNumberElement,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js'
import { Alert, Button, SpinnerBackdrop } from 'components'
import { useTranslation } from 'react-i18next'

import { TPaymentStatus, useBillingActions } from 'context/Billing'
import { useAuth } from 'context/Auth'

import useAlert from 'hooks/useAlert'
import useOptions from '../../pages/Onboarding/Billing/hookes/useOptions'

import './styles.scss'
import styles from './billing.module.scss'

type TProps = {
  setPaymentStatus: (status: TPaymentStatus) => void
  handleSkip: () => void
  buttonTitles?: { [key: string]: string }
}

const BillingForm = ({ setPaymentStatus, handleSkip, buttonTitles }: TProps) => {
  const {
    CPA: { cpaFirm: firm },
  } = useAuth()
  const { subscribe, response } = useBillingActions()
  const { t } = useTranslation('dashboard', { keyPrefix: 'firm' })
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState<{
    [key: string]: StripeCardNumberElementChangeEvent | undefined
  }>({
    card: undefined,
    cvc: undefined,
    expire: undefined,
  })
  const stripe = useStripe()
  const elements = useElements()
  const options = useOptions({
    local: { placeholder: t('onboarding.billing.card') },
  })
  const { alert, setAlert, resetAlert } = useAlert({ status: false })
  const [ready, setReady] = useState<boolean[]>([])

  const cardDataIsValid = useMemo(
    () =>
      !form.card?.error &&
      form.card?.complete &&
      !form.cvc?.error &&
      form.cvc?.complete &&
      !form.expire?.error &&
      form.expire?.complete,
    [form]
  )

  const clearCard = async () => {
    ;[
      elements?.getElement(CardNumberElement),
      elements?.getElement(CardCvcElement),
      elements?.getElement(CardExpiryElement),
    ].forEach((el) => el?.clear())
  }

  const handleSubscribe = async (result: PaymentMethodResult) => {
    if (!result?.paymentMethod?.id) return

    await subscribe(firm!.guid, result.paymentMethod.id)

    if (response.ok) {
      setPaymentStatus('success')
    }
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    resetAlert()
    setLoading(true)

    if (!stripe || !elements) {
      return
    }

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement) as StripeCardNumberElement,
    })

    if (result.error) {
      setAlert({ status: true, message: result.error.message, type: 'error' })
      clearCard()
    } else {
      await handleSubscribe(result)
    }
    setLoading(false)
  }

  return (
    <>
      {!stripe && !ready.every((r) => r) && <SpinnerBackdrop transparent={false} mode="local" />}
      {alert.status && (
        <Alert
          message={alert.message}
          type={alert.type}
          className={styles.alert}
          banner
          sameColor
        />
      )}
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.el}>
          <span className={styles.label}>{t('onboarding.billing.card')}</span>
          <CardNumberElement
            options={options.card}
            onReady={() => setReady((prev: boolean[]) => [...prev, true])}
            onChange={(event) => {
              setForm((prev) => {
                return {
                  ...prev,
                  card: event,
                }
              })
            }}
          />
        </div>
        <div className={styles.block_expire}>
          {' '}
          <div className={styles.el}>
            <span className={styles.label}>{t('onboarding.billing.expiration')}</span>
            <CardExpiryElement
              options={options.expire}
              onReady={() => setReady((prev: boolean[]) => [...prev, true])}
              onChange={(event) => {
                setForm((prev: any) => {
                  return {
                    ...prev,
                    expire: event,
                  }
                })
              }}
            />
          </div>
          <div className={styles.el}>
            <span className={styles.label}>CVC</span>
            <CardCvcElement
              options={options.cvc}
              onReady={() => setReady((prev: boolean[]) => [...prev, true])}
              onChange={(event) => {
                setForm((prev: any) => {
                  return {
                    ...prev,
                    cvc: event,
                  }
                })
              }}
            />
          </div>
        </div>
        <div className={styles.buttons}>
          <div className={styles.button}>
            <Button onClick={handleSkip} disabled={loading} type="ghost">
              {buttonTitles?.skip ? buttonTitles?.skip : t('onboarding.button.skip')}
            </Button>
          </div>
          <div className={styles.button}>
            <Button
              htmlType="submit"
              disabled={!stripe || !cardDataIsValid || loading}
              type="primary"
              loading={loading}
            >
              {buttonTitles?.next ? buttonTitles?.next : t('onboarding.button.continue')}
            </Button>
          </div>
        </div>
      </form>
    </>
  )
}

export default BillingForm
