import { TEntityKind } from 'context/Entity'
import { TFunction } from 'react-i18next'

export const localizeEntityTitles = (entities: TEntityKind[], t: TFunction<'entity'>) => {
  return entities.map(({ title, label }: TEntityKind) => {
    return {
      label: t(title),
      value: label,
    }
  })
}
