import { Modal } from 'antd'
import { Box } from 'reflexbox'
import { useTranslation } from 'react-i18next'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import { TFile } from 'context/TaxOrganizer'

import { Button } from 'components'
import Title from 'components/UploadModal/components/Title'
import StatusBar from 'components/UploadModal/components/StatusBar'

import styles from 'components/UploadModal/uploadmodal.module.scss'
import 'components/UploadModal/style.scss'

import useUploadDocument from '../hooks/useUploadDocument'

const { confirm } = Modal

type TProps = {
  fileList: TFile[]
  onHide: () => void
}

const FilesReturnModal = ({ fileList, onHide }: TProps) => {
  const { t } = useTranslation('common', { keyPrefix: 'upload.modal' })
  const { t: t2 } = useTranslation('common', { keyPrefix: 'switcher' })
  const files = Array.from(fileList)
  const { progress } = useUploadDocument({
    files,
  })

  const handleCancell = () => {
    if (progress.isFinished) {
      onHide()
    } else {
      confirm({
        maskClosable: true,
        title: t('interruptionWarning'),
        icon: <ExclamationCircleOutlined />,
        okText: t2('yes'),
        okType: 'danger',
        cancelText: t2('no'),
        onOk: () => onHide(),
      })
    }
  }

  return (
    <Modal
      visible
      title={<Title progress={progress} />}
      className={styles.modal}
      width={1180}
      destroyOnClose
      closable={false}
      onCancel={handleCancell}
      footer={[
        <Box mr={[3]} mb={[1]} key="submit-block">
          <Button
            key="submit"
            type="primary"
            ghost
            size="large"
            onClick={onHide}
            disabled={!progress.isFinished}
          >
            {t('done')}
          </Button>
        </Box>,
      ]}
    >
      <StatusBar progress={progress} totalFiles={files?.length ?? 0} />
    </Modal>
  )
}

export default FilesReturnModal
