import { useTranslation } from 'react-i18next'
import { PlusOutlined } from '@ant-design/icons'

import { Button } from 'components'

import { useMessagesActions, useMessages } from 'context/Messages'

import styles from '../../tax.messages.module.scss'

const Title = () => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'messages' })
  const { addThread, setCurrentThreadStatus } = useMessagesActions()
  const { threads } = useMessages()

  const handleNewThread = () => {
    setCurrentThreadStatus(false)
    const existingNewThread = threads.find((thread) => thread.guid === '0')
    if (!existingNewThread) {
      addThread()
    }
  }

  return (
    <div className={styles.title}>
      <div className={styles.header_right}>
        <Button type="primary" size="large" onClick={handleNewThread}>
          <PlusOutlined />
          {t('newThread')}
        </Button>
      </div>
    </div>
  )
}

export default Title
