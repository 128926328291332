import { StrictMode, Suspense } from 'react'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { init } from '@chamaeleonidae/chmln'

import './index.scss'
import i18n from 'services/i18n'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { SpinnerBackdrop } from 'components'
import App from './App'
import reportWebVitals from './reportWebVitals'

if (process.env.NODE_ENV === 'production') {
  init('STjy4oOjyu2vOp2B3uP8ArH1RbYbC03AbSOnRCRnAsCREm-1P0NGr-E3SG2RHmBry73Yz5', {
    fastUrl: 'https://fast.chameleon.io/',
  })
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: Number(process.env.REACT_APP_SENTRY_SAMPLE_RATE),
    environment: process.env.REACT_APP_SENTRY_ENV,
  })
}

ReactDOM.render(
  <StrictMode>
    <Suspense fallback={<SpinnerBackdrop size="large" transparent={false} />}>
      <I18nextProvider i18n={i18n}>
        <DndProvider backend={HTML5Backend}>
          <App />
        </DndProvider>
      </I18nextProvider>
    </Suspense>
  </StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
