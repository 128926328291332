import { Box, Flex } from 'reflexbox'
import { formatDistance } from 'date-fns'

import { INotification } from 'context/Notifications'

import useLocale from 'hooks/useLocale'

import cn from 'classnames'
import styles from '../../dashboard.notifications.module.scss'

type TProps = {
  data: INotification
  onItemClick: () => void
}

const Notification = ({ data, onItemClick }: TProps) => {
  const locale = useLocale()

  return (
    <>
      <Flex
        flexDirection={['column']}
        className={cn(styles.notification, !data.readOn ? styles.notification_unread : '')}
        px={[3]}
        py={['14px']}
        fontWeight={[!data.readOn ? 'bold' : 'normal']}
        onClick={onItemClick}
      >
        {data.taxOrganizer?.entity.title && (
          <Flex
            lineHeight={['20px']}
            fontSize={['16px']}
            alignItems={['start']}
            justifyContent={['space-between']}
          >
            <Box>{data.taxOrganizer?.entity.title}</Box>
            <Flex alignItems={['center']} className={styles.notification_header_year}>
              <Box fontSize={['8px']} mx={[2]}>
                &#9679;
              </Box>
              <Box>{data.taxOrganizer?.taxYear}</Box>
            </Flex>
          </Flex>
        )}

        <Box mt={['2px']} mb={['2px']}>
          {data.message}
        </Box>
        <Box fontSize={['12px']} className={styles.text_gray}>
          {data?.notifiedOn &&
            formatDistance(new Date(data.notifiedOn), new Date(), {
              addSuffix: true,
              locale,
            })}
        </Box>
      </Flex>
    </>
  )
}

export default Notification
