import { Button, Modal } from 'components'
import { Flex } from 'reflexbox'
import { Avatar, Empty, Typography } from 'antd'
import { getInitials } from 'utils'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getEntityUserRole } from 'utils/entity'
import { UpOutlined, PlusOutlined } from '@ant-design/icons'

import { useEntity, useEntityActions, TEntity } from 'context/Entity'
import { EnumEntityUserRoles, getEntityUserRolesLocal } from 'context/Entity/types'

import useUserType from 'hooks/useUserType'

import styles from 'pages/Dashboard/EntityManagement/dashboard.entity.management.module.scss'
import useUserEntityPermissions from 'hooks/useUserEntityPermissions'
import FullUserList from './FullUserList'

const { Title, Text } = Typography

const DEFAULT_COUNT = 5

type Props = {
  entity: TEntity
}

const Users = ({ entity }: Props) => {
  const {
    entity: {
      users: { data: users },
    },
  } = useEntity()
  const { loadEntityUsers } = useEntityActions()
  const { t } = useTranslation('entity')
  const { t: t2 } = useTranslation('common')
  const [popoverVisible, setPopoverVisible] = useState(false)
  const [usersToShow, setUsersToShow] = useState<ICpaContact[]>(users.slice(0, DEFAULT_COUNT))
  const [showList, setShowList] = useState(true)
  const userType = useUserType()
  const entityUserRoles = getEntityUserRolesLocal(t)
  const { userEntityRole } = useUserEntityPermissions()

  useEffect(() => {
    loadEntityUsers(entity.guid)
  }, [])

  useEffect(() => {
    setUsersToShow(users.slice(0, DEFAULT_COUNT))
  }, [users])

  const showAll = () => {
    setShowList(false)
    setUsersToShow(users)
  }

  const hideAll = () => {
    setShowList(true)
    setUsersToShow(users.slice(0, DEFAULT_COUNT))
  }

  return (
    <>
      <Flex justifyContent={['space-between']}>
        <Title level={4}>
          {t('users.users')} ({users.length})
        </Title>
        {userType === 'ENTITY' && userEntityRole !== EnumEntityUserRoles.MEMBER && (
          <Modal
            overlayClassName={styles.users_modal}
            placement="left"
            content={<FullUserList entity={entity} users={users} />}
            destroyTooltipOnHide
            trigger="click"
            visible={popoverVisible}
            onVisibleChange={(visible) => setPopoverVisible(visible)}
          >
            <Button type="link" icon={<PlusOutlined />} style={{ paddingRight: 0 }}>
              {t2('buttons.add')}
            </Button>
          </Modal>
        )}
      </Flex>
      {!usersToShow.length && <Empty description={<>{t('common.emptyUsersMessage')}</>} />}
      {usersToShow.map((userWithRole) => (
        <div className={styles.section_row} key={userWithRole.user.guid}>
          {userWithRole.user.avatar ? (
            <Avatar size={24} src={userWithRole.user.avatar} />
          ) : (
            <Avatar size={24}>{getInitials(userWithRole.user)}</Avatar>
          )}
          <Text className={styles.section_name}>
            {userWithRole.user.firstName} {userWithRole.user.lastName}
          </Text>
          <Text className={styles.section_value}>
            {getEntityUserRole(entityUserRoles, userWithRole.role)?.label}
          </Text>
        </div>
      ))}
      {users.length > DEFAULT_COUNT && (
        <Flex justifyContent="flex-end" alignItems="center">
          {showList ? (
            <Button type="link" size="large" className={styles.section_action} onClick={showAll}>
              {t2('buttons.seeAll')}
            </Button>
          ) : (
            <Button type="link" size="large" className={styles.section_action} onClick={hideAll}>
              <UpOutlined />
            </Button>
          )}
        </Flex>
      )}
    </>
  )
}

export default Users
