import { Box, Flex } from 'reflexbox'
import { Dropdown, Menu } from 'antd'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { TTaxDocument } from 'context/TaxOrganizer'

import { ReactComponent as Pdf } from 'assets/images/pdf.svg'

import styles from '../../dashboard.financials.module.scss'

type TProps = {
  document: TTaxDocument
  loading: boolean
  handleDelete: (documentGuid: string) => void
}

const File = ({ document, loading, handleDelete }: TProps) => {
  const { t } = useTranslation('taxOrganizer', { keyPrefix: 'documents.columns.actions' })

  const menu = (
    <Menu>
      <Menu.Item key="download">
        <Link to={{ pathname: document.file! }} target="_blank" download>
          {t('download')}
        </Link>
      </Menu.Item>
      <Menu.Item onClick={() => handleDelete(document.guid)} key="delete">
        {t('delete')}
      </Menu.Item>
    </Menu>
  )

  return (
    <Dropdown.Button disabled={loading} overlay={menu} className={styles.system_file}>
      <Link to={{ pathname: document.file! }} target="_blank" download>
        <Flex justifyContent={['flex-start']} alignItems={['center']}>
          <Pdf />
          <Box mx={[2]}>{document.name}</Box>
        </Flex>
      </Link>
    </Dropdown.Button>
  )
}

export default File
