import { Empty, Skeleton } from 'antd'
import { Box, Flex } from 'reflexbox'
import { useTranslation } from 'react-i18next'

import styles from './dashboard.notifications.module.scss'
import useData from './hooks/useData'
import usePagination from './hooks/usePagination'
import NotificationsList from './components/NotificationsList'

type TProps = { sidebarCollapsed: boolean }

const NotificationsSidebar = ({ sidebarCollapsed }: TProps) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'sidebar' })
  const { loading, notifications: chunk, count, params, setParams } = useData(sidebarCollapsed)
  const { notifications, nextStep } = usePagination({
    sidebarCollapsed,
    params,
    chunk,
    count,
  })

  const fetchMoreData = () => {
    if (nextStep) {
      setParams((currentParams: any) => ({ ...currentParams, page: ++currentParams.page }))
    }
  }

  const isEmpty = notifications.length === 0

  return (
    <Flex
      flexDirection={['column']}
      width={['100%', 310]}
      className={styles.sidebar}
      style={{
        right: sidebarCollapsed ? '-110%' : '0px',
      }}
    >
      <Flex
        justifyContent={['space-between']}
        alignItems={['center']}
        pl={[3]}
        pr={[3]}
        pt={['20px']}
      >
        <Box fontSize="20px" fontWeight="bold">
          {t('notifications')}
        </Box>
      </Flex>
      {loading && params.page === 1 && (
        <>
          <Flex
            justifyContent={['flex-start']}
            alignItems={['center']}
            flexDirection={['column']}
            px={[3]}
          >
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </Flex>
        </>
      )}
      {isEmpty && !loading && (
        <Flex
          height={['300px']}
          width={['100%']}
          justifyContent={['center']}
          alignItems={['center']}
        >
          <Empty description={<>{t('emptyNotifications')}</>} />
        </Flex>
      )}
      {!isEmpty && (
        <NotificationsList
          notifications={notifications}
          loading={loading}
          fetchMoreData={fetchMoreData}
          nextStep={nextStep}
        />
      )}
    </Flex>
  )
}

export default NotificationsSidebar
