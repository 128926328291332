import { useEffect, useState } from 'react'
import { useFetch } from 'use-http'

import { message } from 'antd'
import { getMessageFromResponse } from 'utils/http'

import { TCPAFirmProfile } from '../types'

const useFirmProfile = (cpaFirmSlug: string) => {
  const { get, response } = useFetch()
  const [loading, setLoading] = useState(true)
  const [profile, setProfile] = useState<TCPAFirmProfile>({} as TCPAFirmProfile)

  useEffect(() => {
    const loadFirmProfile = async () => {
      const firmProfile = await get(`/cpas/cpa-firms/${cpaFirmSlug}/profile`)
      if (response.ok) {
        setProfile(firmProfile)
      } else {
        message.error(getMessageFromResponse(response))
      }
      setLoading(false)
    }
    loadFirmProfile()
  }, [])

  return {
    profile,
    loading,
  }
}

export default useFirmProfile
